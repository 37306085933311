<template>
  <div>
    <div v-if="siteTitle" class="eh-web-app-header-top">
      <b-container fluid>
        <b-row align-v="center" align-h="between">
          <b-col cols="12" sm="5">
            <label class="eh-web-app-header-upper-site-title">{{
              siteTitle
            }}</label>
          </b-col>
          <b-col cols="12" sm="3">
            <div>
              <fp-select
                class="eh-web-app-flag-selector"
                v-model="preferedLanguage"
                @change="changeLanguage"
                valueKey="Value"
                :items="Object.values($enums.Languages)"
              >
                <template #element="flag">
                  <img
                    class="eh-web-app-header-flag-img"
                    :src="getImgUrl(flag.flag)"
                  />
                  <span class="eh-web-app-header-flag-text">{{
                    flag.Text
                  }}</span>
                </template>
              </fp-select>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <b-container fluid class="eh-web-app-header-bottom">
        <b-row align-v="center">
          <!-- LOGO -->
          <b-col cols="12" lg="5">
            <div class="eh-web-app-header-logo" />
          </b-col>
          <!-- Bejelentkezett adatai -->
          <b-col cols="12" lg="7">
            <b-row v-if="signedUser" align-v="center" align-h="end">
              <!-- Bejelentkezett alap adatai -->
              <b-col cols="12" sm="6" order="3" order-sm="1">
                <!-- Belépve, mint ... -->
                <b-row>
                  <p class="eh-web-app-header-logged-in-text">
                    {{ $t("header.loggedWith", { name: signedUser.Name }) }}
                  </p>
                </b-row>
                <!-- Felület több szerepkör -->
                <b-row
                  v-if="signedUser.Roles && signedUser.Roles.length > 1"
                  align-v="center"
                >
                  <!-- Felület -->
                  <b-col
                    cols="12"
                    lg="2"
                    class="eh-web-app-header-selector-label"
                  >
                    {{ $t("header.usedRoleView") }}:
                  </b-col>
                  <!-- Felület választó -->
                  <b-col cols="12" lg="10">
                    <fp-select
                      class="eh-web-app-header-selector"
                      :items="signedUser.Roles"
                      @change="changeRole"
                      :value="signedUser.SelectedRole"
                    >
                      <template #element="role"
                        >{{
                          $t(roleList[role.SimpleArrayToObjectArrayValue].Text)
                        }}
                      </template>
                    </fp-select>
                  </b-col>
                </b-row>
                <!-- Felület egy szerepkör -->
                <b-row v-else class="eh-web-app-header-static-selector">
                  {{ $t("header.usedRoleView") }}:
                  {{
                    roleList[signedUser.SelectedRole]
                      ? $t(roleList[signedUser.SelectedRole].Text)
                      : null
                  }}
                </b-row>
                <!-- Projekt választó -->
                <b-row
                  align-v="center"
                  v-if="
                    signedUser.Projects &&
                      signedUser.Projects[signedUser.SelectedRole] &&
                      signedUser.Projects[signedUser.SelectedRole].length > 0 &&
                      signedUser.SelectedRole !=
                        this.$enums.UserRoleWithHome.Client.Value
                  "
                >
                  <!-- Projekt felirat -->
                  <b-col
                    cols="12"
                    lg="2"
                    class="eh-web-app-header-selector-label"
                  >
                    {{ $t("header.usedProject") }}:
                  </b-col>
                  <!-- Projekt választó lista -->
                  <!-- TODO: textKey objektum beálltása -->
                  <b-col cols="12" lg="10">
                    <fp-select
                      class="eh-web-app-header-selector"
                      valueKey="ProjectId"
                      :items="signedUser.Projects[signedUser.SelectedRole]"
                      :value="
                        signedUser.SelectedProject
                          ? signedUser.SelectedProject.ProjectId
                          : null
                      "
                      @change="changeProject"
                    >
                      <template #element="e">
                        {{
                          e.ProjectName
                            ? getTextByLanguage(e.ProjectName)
                            : e.ProjectName
                        }}
                      </template>
                    </fp-select>
                  </b-col>
                </b-row>
              </b-col>
              <!-- Profil kép -->
              <b-col cols="12" sm="3" order="2">
                <div
                  class="eh-web-app-header-profile-icon"
                  v-b-tooltip.hover="
                    profileFillingRate &&
                    signedUser.SelectedRole ==
                      $enums.UserRoleWithHome.Client.Value
                      ? $t('client.menu.profileFillingRate') +
                        ': \n' +
                        $t('client.menu.profilePersonalData') +
                        profileFillingRate[3].y +
                        '%\n' +
                        $t('client.menu.profileMedicalData') +
                        profileFillingRate[0].y +
                        '%'
                      : ''
                  "
                >
                  <router-link
                    style="position: relative; z-index: 1"
                    to="Profile"
                  >
                    <!-- <img id="client-profil-icon" :src="profileImg" /> -->
                    <img
                      v-if="profileImg"
                      class="client-profil-img"
                      :src="profileImg"
                    />
                    <div v-else class="client-profil-img empty-img">
                      <b-spinner class="profile-img-loading" />
                    </div>
                    <div
                      style="
                        position: absolute;
                        margin-top: -90px;
                        margin-left: -10px;
                        z-index: -10;
                      "
                    >
                      <ejs-accumulationchart
                        id="pie-filling-rate"
                        v-if="
                          profileFillingRate &&
                            profileFillingRate.length > 0 &&
                            signedUser.SelectedRole ==
                              $enums.UserRoleWithHome.Client.Value
                        "
                        ref="pieFillingRate"
                        align="center"
                        height="100px"
                        width="100px"
                        background="transparent"
                      >
                        <e-accumulation-series-collection>
                          <e-accumulation-series
                            :dataSource="profileFillingRate"
                            :pointColorMapping="'fill'"
                            radius="120%"
                            xName="x"
                            yName="y"
                            innerRadius="20%"
                          ></e-accumulation-series>
                        </e-accumulation-series-collection>
                      </ejs-accumulationchart>
                    </div>
                  </router-link>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { UserLogic } from "@/logic/backend/user";
import { UserProfileLogic } from "@/logic/backend/user-profile";
import { AttachmentLogic } from "@/logic/backend/attachment";
import { PieSeries, AccumulationChartPlugin } from "@syncfusion/ej2-vue-charts";
import { eventBus } from "../../main";

Vue.use(AccumulationChartPlugin);

export default {
  data() {
    return {
      siteTitleBackground: "#FBB913",
      signedUser: {},
      profileImg: null,
      /* Chart adatok */
      profileFillingRate: null,
      showRoleChangeModal: false,
      roleList: [],
      signedUserProjects: ["GOKVI"],
      signedUserSelectedProject: "GOKVI",
      reloadLocation: location,
      preferedLanguage: this.$i18n.locale,
    };
  },
  provide: {
    accumulationchart: [PieSeries],
  },
  props: {
    signedName: String,
    signedProfilePicture: String,
    siteTitle: String,
    siteColor: String,
    ChangedProfileFillingRate: Object,
  },
  watch: {
    //prop változás figyelő
    ChangedProfileFillingRate(fillingRates) {
      //TODO: kiemelés metódusba (getProfile metós használja majd együtt)
      //lenullázzuk a profil kitöltöttség jelző tárolót
      this.profileFillingRate = null;
      //feltöltjük az új adatokkal
      this.profileFillingRate = [
        {
          x: "medicalFilledRate",
          y: fillingRates.MedicalProfile,
          fill: "var(--eh-primary-5)",
        },
        {
          x: "medicalEmptyRate",
          y: 100 - fillingRates.MedicalProfile,
          fill: "var(--eh-primary-3)",
        },
        {
          x: "personalFilledRate",
          y: 100 - fillingRates.PersonalProfile,
          fill: "var(--eh-primary-3)",
        },
        {
          x: "personalEmptyRate",
          y: fillingRates.PersonalProfile,
          fill: "var(--eh-secondary-4)",
        },
      ];
      //van kitöltöttség jelző chart?
      if (this.$refs.pieFillingRate) {
        //igen, átadjuk a chartnak közvetlenül az új értékeit
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[0].y = this.profileFillingRate[0].y;
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[1].y = this.profileFillingRate[1].y;
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[2].y = this.profileFillingRate[2].y;
        this.$refs.pieFillingRate.ej2Instances.series[0].dataSource[3].y = this.profileFillingRate[3].y;
        //frissítésre kényszerítjük
        this.$refs.pieFillingRate.ej2Instances.refresh();
      }
    },
    signedName(input) {
      this.signedUser.Name = input;
    },
    "signedUser.Name": function() {
      UserLogic.saveLoginUser(this.signedUser);
    },
    signedProfilePicture(input) {
      this.profileImg = input;
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    getImgUrl(url) {
      return require("@/assets" + url.toString());
    },
    /* //bejelentkezett felhasználó szerepkörét adja meg
    getRoleText(role) {
      const temp = this.roleList[role];
      if (temp) {
        //van, ki keressük a keresett enum elemet és vissza adjuk a Text-jét
        return temp.Text;
      }
      //nincs, akkor vissza adjuk a kapott role-t
      return role;
    }, */
    //profilkép lekérdezés
    async getUserProfilePicture(profile) {
      //kérés indítás
      if (profile.PersonalProfile.ProfilePictureId) {
        const pictureDocuResponse = await AttachmentLogic.downloadAttachment(
          profile.PersonalProfile.ProfilePictureId
        );
        //sikeres kérés?
        if (!pictureDocuResponse.Code) {
          //kapott kép blobba mentés
          const imgBlob = await pictureDocuResponse.blob();
          //visszatérünk a blobból alakított url-lel
          return URL.createObjectURL(imgBlob);
        } else {
          this.$bvToast.toast(pictureDocuResponse.Message, {
            title: this.$t(
              "requestResponse.attachment.errorDownloadProfilePhoto"
            ),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
          return require("@/assets/annonym-profil-icon.png");
        }
      }
      //sikertelen kép kéréskor, null-lal térünk vissza
      return require("@/assets/annonym-profil-icon.png");
    },
    //profil kitöltöttség chart beállítása
    setProfileFillingRate(userProfileResponse) {
      //kapott profilhoz tartozik kitöltöttségi mutató?
      if (
        (userProfileResponse.PersonalProfile.FillingRate ||
          userProfileResponse.PersonalProfile.FillingRate == 0) &&
        userProfileResponse.MedicalProfile &&
        (userProfileResponse.MedicalProfile.FillingRate ||
          userProfileResponse.MedicalProfile.FillingRate == 0)
      ) {
        //igen, eltároljuk a chart megjelenéshez szükséges adatokat
        this.profileFillingRate = [
          {
            x: "medicalFilledRate",
            y: userProfileResponse.MedicalProfile.FillingRate,
            fill: "var(--eh-primary-5)",
          },
          {
            x: "medicalEmptyRate",
            y: 100 - userProfileResponse.MedicalProfile.FillingRate,
            fill: "var(--eh-primary-3)",
          },
          {
            x: "personalFilledRate",
            y: 100 - userProfileResponse.PersonalProfile.FillingRate,
            fill: "var(--eh-primary-3)",
          },
          {
            x: "personalEmptyRate",
            y: userProfileResponse.PersonalProfile.FillingRate,
            fill: "var(--eh-secondary-4)",
          },
        ];
      }
    },
    //profilkép beállítása
    async setPrfoilePicture(userProfileResponse) {
      //van profilhoz tartozó file id megadva?
      if (userProfileResponse.PersonalProfile.ProfilePictureId) {
        //igen, kép letöltés, file id alapján, és url tárolása
        this.profileImg = await this.getUserProfilePicture(
          userProfileResponse.PersonalProfile.ProfilePictureId
        );
        /* //a tárolt url tásolása a store-ba
        this.$store.dispatch("changeUser", {
          Url: imgUrl,
        }); */
      } else {
        this.profileImg = require("@/assets/annonym-profil-icon.png");
        /* //nem, default kép url tárolása storeba
        this.$store.dispatch("changeUser", {
          Url: require("@/assets/annonym-profil-icon.png"),
        }); */
      }
    },
    //profil adatok kérés
    async getProfile() {
      //kérés indítása
      const userProfileResponse = await UserProfileLogic.getUserProfile();
      //sikeres kérés?
      if (!userProfileResponse.Code) {
        if (userProfileResponse.PersonalProfile.PreferredLanguage) {
          this.preferedLanguage =
            userProfileResponse.PersonalProfile.PreferredLanguage;
        }
        //profil kitöltöttségi chart adatok megadása
        this.setProfileFillingRate(userProfileResponse);
        //profilkép megjelenés kérés
        this.profileImg = await this.getUserProfilePicture(userProfileResponse);
      } else {
        this.$bvToast.toast(userProfileResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("components.header.errorAccountName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    changeRole(role) {
      if (this.signedUser.Projects[role]) {
        this.signedUser.SelectedProject = this.signedUser.Projects[role][0];
      } else {
        this.signedUser.SelectedProject = null;
      }

      UserLogic.saveLoginUser(this.signedUser);
      this.routerPushRoleHome(role);
    },
    routerPushRoleHome(role) {
      const selectedRoleElement = this.roleList[role];
      const currentRoleElement = this.roleList[this.signedUser.SelectedRole];
      if (selectedRoleElement && selectedRoleElement.Home) {
        this.signedUser.SelectedRole = role;
        UserLogic.saveLoginUser(this.signedUser);
        if (currentRoleElement.Home != selectedRoleElement.Home) {
          this.$router.push({ name: selectedRoleElement.Home });
        }
      }
    },
    checkSignedUser() {
      this.signedUser = UserLogic.getSignedUser();
      if (this.signedUser.SelectedProject) {
        eventBus.$emit(
          "projectChanged",
          this.signedUser.SelectedProject.ProjectId
        );
      }
      if (this.signedUser.Roles.includes("Administrator")) {
        this.signedUser.Roles = Object.values(this.roleList).map(
          (role) => role.Value
        );
        UserLogic.saveLoginUser(this.signedUser);
      }
    },
    setSiteTitle() {
      //van oldal cím?
      if (this.siteTitle) {
        //igen, eltároljuk a megjelenítéshez
        document.title = this.siteTitle + " - " + this.$t("base.title");
      } else {
        document.title = this.siteTitle;
      }
    },
    changeProject(projectId) {
      const selectedProject = this.signedUser.Projects[
        this.signedUser.SelectedRole
      ].find((project) => project.ProjectId == projectId);
      this.signedUser.SelectedProject = selectedProject;
      UserLogic.saveLoginUser(this.signedUser);
      this.$emit("forceUpdate");
      eventBus.$emit("projectChanged", projectId);
    },
    async changeLanguage(lang) {
      sessionStorage.setItem("FOKUSZ_PROGRAM_locale_language", lang);
      if (this.$i18n.locale != lang) {
        this.$i18n.locale = lang;
        await UserProfileLogic.setUserProfile(
          { "PersonalProfile.PreferredLanguage": lang },
          this.$loggedUser.UserId
        );
      }
      this.$emit("forceUpdate");
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    this.roleList = this.$enums.UserRoleWithHome;
    this.checkSignedUser();

    //profil adatok betöltése
    await this.getProfile();

    this.setSiteTitle();
  },
  //minden frissíté előtt lefut
  beforeUpdate() {
    //this.checkSignedUser();
    //console.log(this.$loggedUser.SelectedRole);
    this.setSiteTitle();
  },
};
</script>
<style scoped>
.role-list-element.current-user-role {
  border-color: var(--eh-secondary-5);
  background: var(--eh-secondary-1);
}

.role-list-element:hover {
  opacity: 0.5;
}

.role-list-element {
  cursor: pointer;
  border: solid 3px;
  border-radius: 10px;
  border-color: var(--eh-primary-5);
  background: var(--eh-primary-2);
  margin: 10px;
  padding: 10px;
}

#header-above-menu-logo-box {
  padding: 12px 0px;
}

#header-above-menu-logo {
  height: 50px;
}

.client-profil-img {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: relative;
  z-index: 10;
}

.client-profil-img.empty-img {
  background: white;
  text-align: center;
}

.client-profil-img.empty-img .profile-img-loading {
  margin: 25px 0px;
}

.header-logged-user-text {
  /* text-align: end; */
  color: var(--eh-white);
  font-size: 20px;
  line-height: 20px;
}

.header-site-title {
  font-weight: 900;
  font-size: 20px;
  text-align: left;
  margin: 0px;
}

@media (max-width: 700px) {
  .header-logged-user-text {
    text-align: center;
  }

  .header-site-title {
    text-align: center;
  }
}
</style>
