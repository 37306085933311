<template>
  <!-- További almenü gombok -->
  <b-row style="text-align: center">
    <div style="margin: auto">
      <table class="programs-box">
        <a target="_blank" href="https://app.medalyse.hu/covidreg/#!report/3032:LC=HZySUPPnKyX7QujH">
          <td class="program-box">
            <ejs-button class="webapp-homepage-button">{{
                $t("partner.research.title")
              }}</ejs-button>
            <!-- TODO: syncfusion button cserék! -->
            <!-- <div class="webapp-homepage-button">{{ $t("client.home.badges") }}</div> -->
          </td>
        </a>
        <router-link to="Logs">
          <td class="program-box">
            <ejs-button class="webapp-homepage-button">{{
                $t("partner.logs.title")
              }}</ejs-button>
          </td>
        </router-link>
      </table>
    </div>
  </b-row>
</template>

<script>
export default {
  name: "WebAppPartnerFooter"
}
</script>

<style scoped>

</style>