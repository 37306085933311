<template>
  <div style="width: fit-content">
    <!-- Felhasználó - Felettes kapcsolat megszüntetése -->
    <fp-svg-icon
      v-if="$productSettings[managerType].canRemoveProjectRelation"
      v-b-tooltip.hover="$t('ProjectParticipant.removeProjectRelation')"
      icon="remove-user-relation"
      style="cursor: pointer; background: #dd0305"
      @click="removeProjectRelation"
    />
    <!-- Felhasználó törlése a projektről -->
    <fp-svg-icon
      v-if="$productSettings[managerType].canRemoveParticipantFromProject"
      v-b-tooltip.hover="$t('ProjectParticipant.removeFromProject')"
      icon="user-remove"
      style="cursor: pointer; background: #dd0305"
      @click="removeFromProject"
    />
    <!-- Felhasználó törlése -->
    <fp-svg-icon
      v-if="$productSettings[managerType].canDeleteParticipant"
      icon="user-delete"
      v-b-tooltip.hover="$t('ProjectParticipant.deleteParticipant')"
      style="cursor: pointer; background: #dd0305"
      @click="deleteUser"
    />
  </div>
</template>
<script>
import { ProjectLogic } from "../../Logic/Backend/project";
import { UserLogic } from "../../Logic/Backend/user";
export default {
  name: "DeleteParticipantFromList",
  props: {
    participant: Object,
    projectId: Number,
    managerType: String,
  },
  methods: {
    async removeProjectRelation() {
      if (
        confirm(
          "Biztosan eltávolítja a projekt kapcsolatot a " +
            this.participant.Name +
            " nevű résztvevővel?"
        )
      ) {
        const removeResponse = await ProjectLogic.deleteRelationship(
          [
            {
              SuperiorId: this.$loggedUser.UserId,
              SubordinateId: this.participant.UserId,
            },
          ],
          this.projectId
        );
        if (!removeResponse.Code || removeResponse.Code == 0) {
          this.$bvToast.toast(
            "Sikeresen eltávolította a részvevővel közös projekt kapcsolatot.",
            {
              title: "Sikeres eltávolítás",
              variant: "info",
              solid: true,
              AutoHideDelay: 10000,
            }
          );
          this.$emit("refreshList");
        } else {
          this.$bvToast.toast(removeResponse.Message, {
            title: "Sikertelen eltávolítás",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async removeFromProject() {
      if (
        confirm(
          "Biztosan eltávolítja a " +
            this.participant.Name +
            " nevű résztvevőt a projektről? (Ennek hatására a felhasználó fiókja megmarad, csak a projekten nem fog szerepelni.)"
        )
      ) {
        const removeResponse = await ProjectLogic.deleteParticipantFromProject(
          this.projectId,
          this.participant.UserId
        );
        if (!removeResponse.Code || removeResponse.Code == 0) {
          this.$bvToast.toast(
            "Sikeresen eltávolíotta a résztvevőt a projektről.",
            {
              title: "Sikeres eltávolítás",
              variant: "info",
              solid: true,
            }
          );
          this.$emit("refreshList");
        } else {
          this.$bvToast.toast(removeResponse.Message, {
            title: "Sikertelen eltávolítás",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async deleteUser() {
      if (
        confirm(
          "Ön a kiválasztott felhasználó fiókjának törlésére készül, amelynek során a felhasználó adatai véglegesen eltávolításra kerülnek a rendszerből. Biztos benne, hogy véglegesen törölni kívánja a fiókot?"
        )
      )
        if (confirm("Bitosan befejezi a felhasználó végleges törlését?")) {
          {
            const deleteResponse = await UserLogic.deleteUser(
              this.participant.UserId
            );
            if (!deleteResponse.Code || deleteResponse.Code == 0) {
              this.$bvToast.toast("Sikeresen törölte a felhasználó fiókot.", {
                title: "Sikeres törlés",
                variant: "info",
                solid: true,
              });
              this.$emit("refreshList");
            } else {
              this.$bvToast.toast(deleteResponse.Message, {
                title: "Sikertelen törlés",
                variant: "danger",
                solid: true,
                AutoHideDelay: 10000,
              });
            }
          }
        }
    },
  },
};
</script>
