<template>
  <fp-form-modal
    size="xl"
    :title="editable ? 'Foglalás szerkesztése' : 'Foglalás adatai'"
    v-model="visible"
    :closable="!editable"
  >
    <template #content>
      <div v-if="dispenseData">
        <fp-date-time-picker
          :disabled="true"
          label="Kiadás időpontja"
          v-model="dispenseData.DispenseTimestamp"
          @change="changeValue('DispenseTimestamp', $event)"
        />
        <!-- Beteg -->
        <div style="margin: 10px 0px" v-if="dispenseData.Patient">
          <fp-panel-card
            style="margin-top: 10px"
            :closedCard="true"
            title="Ellátott személy adatai"
          >
            <template #content>
              <patient
                v-model="dispenseData.Patient"
                @changeValue="changeValue('Patient', $event)"
                :disabled="!editable"
                :prescriptionTimestamp="prescriptionTimestamp"
              />
            </template>
          </fp-panel-card>
        </div>
      </div>
      <b-button v-if="editable" @click="editDispense">Mentés</b-button>
      <b-button v-if="editable" @click="visible = false">Mégsem</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import Patient from "../Components/form/Patient.vue";
export default {
  components: { Patient },
  name: "DispenseForm",
  model: {
    prop: "formVisible",
    event: "change",
  },
  props: {
    formVisible: Boolean,
    editable: Boolean,
    dispense: Object,
    patientId: Number,
    projectPatient: Array,
    prescriptionTimestamp: String,
  },
  data() {
    return {
      visible: this.formVisible,
      changedValues: {},
      dispenseData: JSON.parse(JSON.stringify(this.dispense)),
    };
  },
  watch: {
    formVisible(input) {
      this.visible = input;
      if (input) {
        this.dispenseData = JSON.parse(JSON.stringify(this.dispense));
      }
    },
    visible(input) {
      this.$emit("change", input);
      if (!input) {
        this.$emit("update:editable", false);
      }
    },
  },
  methods: {
    editDispense() {
      const body = this.getChangedPathValue();
      this.$emit("editDispense", body);
    },
    changeValue(key, value) {
      this.changedValues[key] = value;
    },
    getChangedPathValue() {
      var changedFields = {};
      Object.entries(this.changedValues).forEach(([key, value]) => {
        const changed = this.getPath(value, key);
        if (changed) {
          Object.assign(changedFields, changed);
        }
      });
      return changedFields;
    },
    getPath(obj, path) {
      if (typeof obj == "object" && obj != null && obj !== undefined) {
        const result = {};
        Object.entries(obj).forEach(([key, value]) => {
          const r = this.getPath(value, path + "." + key);
          if (r) {
            Object.assign(result, r);
          }
        });
        return result;
      } else {
        return { [path]: obj };
      }
    },
  },
};
</script>
