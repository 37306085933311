<template>
  <div id="medicine-plan-input-modal">
    <fp-form-modal
      :title="$t('module.plans.medicine.addNewPlan')"
      size="lg"
      hide-footer
      v-model="showInputModal"
    >
      <template #content>
        <b-form :class="theme">
          <!-- Gyógyszer neve -->
          <fp-select
            :searchable="true"
            :label="$t('module.plans.medicine.medicineName')"
            :placeholder="$t('module.plans.medicine.namePlaceholder')"
            :required="true"
            :items="items"
            @search="searchMedicineName"
            textKey="Name"
            valueKey="MedicineId"
            v-model="newMedicinePlanInput.MedicineId"
          >
              <template #element="item">
                  {{ $getLanguagedText(item.Name) }}
                  <br />
                  {{ item.Package }}
              </template>
          </fp-select>
          <!-- Szedési gyakoriság -->
          <fp-select
            :label="$t('module.plans.medicine.timing')"
            :required="true"
            :items="Object.values(medicineTimingType)"
            :state="
              !validation.hasError('newMedicinePlanInput.Timing.Recurrence')
            "
            :error="
              validation.firstError('newMedicinePlanInput.Timing.Recurrence')
            "
            valueKey="Value"
            v-model="newMedicinePlanInput.Timing.Recurrence"
          >
            <template #element="element">
              {{ $t(element.Text) }}
            </template>
          </fp-select>
          <!-- Szedési gyakoriság -->
          <fp-select
            v-if="
              newMedicinePlanInput.Timing.Recurrence ==
                medicineTimingType.OnDaysOfWeek.Value
            "
            :state="
              !validation.hasError('newMedicinePlanInput.Timing.DaysOfWeek')
            "
            :error="
              validation.firstError('newMedicinePlanInput.Timing.DaysOfWeek')
            "
            :label="$t('module.plans.medicine.daysOfWeek')"
            :required="true"
            :items="Object.values(daysOfWeek)"
            valueKey="Value"
            type="multi"
            :textKeyConverter="
              (day) => {
                return $t(day.Text);
              }
            "
            v-model="newMedicinePlanInput.Timing.DaysOfWeek"
          >
            <template #element="element">
              {{ $t(element.Text) }}
            </template>
          </fp-select>
          <!-- Szedési mennyiség -->
          <div>
            <div>
              <label style="color: #00000099">{{
                $t("module.plans.medicine.quantity")
              }}</label>
              <span class="text-danger">*</span>
            </div>
            <div
              v-if="validation.hasError('newMedicinePlanInput.Timing.Times')"
            >
              <label style="font-size: 13px; color: red">{{
                $t("module.plans.medicine.questityNotification")
              }}</label>
            </div>
            <b-row cols="1">
              <b-col
                v-for="planTime in newMedicinePlanInputTimes"
                :key="planTime.PartOfDay"
              >
                <b-row align-v="center">
                  <b-col>
                    {{
                      $t(
                        "module.plans.medicine." +
                          planTime.PartOfDay.toLowerCase()
                      )
                    }}
                  </b-col>
                  <b-col>
                    <fp-input
                      :state="
                        !validation.hasError(
                          'newMedicinePlanInput.Timing.Times'
                        )
                      "
                      v-model="planTime.Quantity"
                    />
                  </b-col>
                  <b-col>
                    <b-check v-model="planTime.Alert">{{
                      $t("module.plans.medicine.notification")
                    }}</b-check>
                  </b-col>
                  <b-col>
                    <fp-time-picker
                      style="margin-top:-25px"
                      v-model="planTime.Time"
                    />
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- <table style="width: 100%; overflow-x: auto; display: block">
              <tr
                style="vertical-align: middle"
                v-for="planTime in newMedicinePlanInputTimes"
                :key="planTime.PartOfDay"
              >
                <td class="medicine-plan-time-row">
                  {{
                    $t(
                      "module.plans.medicine." +
                        planTime.PartOfDay.toLowerCase()
                    )
                  }}
                </td>
                <td class="medicine-plan-time-row">
                  <fp-input
                    :state="
                      !validation.hasError('newMedicinePlanInput.Timing.Times')
                    "
                    v-model="planTime.Quantity"
                  />
                </td>
                <td class="medicine-plan-time-row">
                  {{ $t("module.plans.medicine.notification") }}
                </td>
                <td class="medicine-plan-time-row">
                  <b-check v-model="planTime.Alert" />
                </td>
                <td class="medicine-plan-time-row">
                  <fp-time-picker v-model="planTime.Time" />
                </td>
              </tr>
            </table> -->
          </div>
          <b-row cols="1" cols-sm="2">
            <!-- Szedés kezdete -->
            <div style="padding: 5px">
              <fp-date-picker
                :state="!validation.hasError('newMedicinePlanInput.StartDate')"
                :error="validation.firstError('newMedicinePlanInput.StartDate')"
                :label="$t('module.plans.medicine.startDate')"
                :required="true"
                v-model="newMedicinePlanInput.StartDate"
              />
            </div>
            <!-- Szedés vége -->
            <div style="padding: 5px">
              <fp-date-picker
                :state="!validation.hasError('newMedicinePlanInput.EndDate')"
                :error="validation.firstError('newMedicinePlanInput.EndDate')"
                :label="$t('module.plans.medicine.endDate')"
                :required="true"
                v-model="newMedicinePlanInput.EndDate"
              />
            </div>
          </b-row>
          <div style="text-align: right; margin-top: 10px">
            <b-button @click="saveNewMedicinePlan">{{
              $t("base.basic.save")
            }}</b-button>
          </div>
        </b-form>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import { BaseDataLogic } from "@/logic/backend/base-data";
import { MedicinePlanLogic } from "@/logic/backend/medicine-plan";
import moment from "moment";
export default {
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      newMedicinePlanInput: {
        Timing: {
          TimingType: null,
          DaysOfWeek: null,
          Recurrence: null,
        },
        MedicineId: null,
        StartDate: null,
        EndDate: null,
      },
      newMedicinePlanInputTimes: [
        { PartOfDay: "Morning", Quantity: 0, Time: "08:00" },
        { PartOfDay: "Noon", Quantity: 0, Time: "12:00" },
        { PartOfDay: "Evening", Quantity: 0, Time: "18:00" },
        { PartOfDay: "Night", Quantity: 0, Time: "22:00" },
      ],
      items: null,
      activeItem: null,
      medicineTimingType: this.$enums.MedicineTimingType,
      daysOfWeek: this.$enums.DaysOfWeek,
      isValidInputs: {},
      showInputModal: this.visible,
      alert: {
        Message: "",
        IsShow: false,
      },
      theme: sessionStorage.getItem("currentTheme") || "eh-fp",
    };
  },
  watch: {
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "newMedicinePlanInput.Timing.Times": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newMedicinePlanInput.Timing.Recurrence": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    /* "newMedicinePlanInput.MedicineId": function (value) {
      return this.$validator.value(value).required("Kötelező mező.");
    }, */
    "newMedicinePlanInput.StartDate": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredDate"));
    },
    "newMedicinePlanInput.EndDate, newMedicinePlanInput.StartDate": function(
      end,
      start
    ) {
      const that = this;
      if (this.validation.isTouched("newMedicinePlanInput.EndDate")) {
        const validator = this.$validator;
        return validator.custom(function() {
          if (!validator.isEmpty(start) && !validator.isEmpty(end)) {
            if (moment(start).isAfter(moment(end))) {
              return that.$t("validate.endDateNotBeforeStart");
            } else if (validator.isEmpty(end)) {
              return that.$t("validate.requiredDate");
            }
          }
        });
      } else if (this.$validator.isEmpty(end)) {
        return this.$validator.custom(function() {
          return that.$t("validate.requiredDate");
        });
      }
    },
    "newMedicinePlanInput.Timing.DaysOfWeek, newMedicinePlanInput.Timing.Recurrence": function(
      days,
      timing
    ) {
      if (timing == this.medicineTimingType.OnDaysOfWeek.Value) {
        return this.$validator
          .value(days)
          .minLength(1)
          .required(this.$t("validate.requiredField"));
      }
    },
  },
  /* computed: {
    validInput() {
      var inputsValid = Object.values(this.isValidInputs).some(
        (x) => !x && x != null
      );
      return (
        !inputsValid &&
        this.newMedicinePlanInputTimes.find((x) => x.Quantity > 0)
      );
    },
  }, */
  methods: {
    async saveNewMedicinePlan() {
      this.newMedicinePlanInput.Timing.Times = [];
      this.newMedicinePlanInputTimes.forEach((x) => {
        if (x.Quantity != 0) {
          if (!x.Alert) {
            x.Alert = false;
          }
          this.newMedicinePlanInput.Timing.Times.push(x);
        }
      });
      const valid = await this.$validate();
      if (valid) {
        const setPlanResponse = await MedicinePlanLogic.setMedicinePlan([
          this.newMedicinePlanInput,
        ]);
        if (!setPlanResponse.Code || setPlanResponse.Code == 0) {
          this.$bvToast.toast(
            this.$t("requestResponse.medicinePlan.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("change-medicine-plan-list", true);
          // TODO: Mentés után adatok visszaállítása alapértelmezetté
          this.newMedicinePlanInput = {
            Timing: {
              TimingType: null,
            },
          };
          this.newMedicinePlanInputTimes = [
            { PartOfDay: "Morning", Quantity: 0, Time: "08:00:00" },
            { PartOfDay: "Noon", Quantity: 0, Time: "12:00:00" },
            { PartOfDay: "Evening", Quantity: 0, Time: "18:00:00" },
            { PartOfDay: "Night", Quantity: 0, Time: "22:00:00" },
          ];
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(setPlanResponse.Message, {
            title: this.$t("requestResponse.medicinePlan.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async getMedicine(params) {
      const getBaseDataResponse = await BaseDataLogic.getBaseData(
        "medicine",
        params
      );
      if (!getBaseDataResponse.Code) {
        this.items = getBaseDataResponse;
      } else {
        this.$bvToast.toast(getBaseDataResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetMedicineList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async searchMedicineName(input) {
      if (input.length > 2) {
        await this.getMedicine({ ["Name." + this.$i18n.locale]: input });
      } else if (input) {
        this.items = null;
      } else {
        await this.getMedicine({ "-limit": 10 });
      }
    },
  },
  async mounted() {
    await this.getMedicine({ "-limit": 10 });
  },
};
</script>
<style scoped>
.medicine-plan-time-row {
  padding: 0px 5px;
}
</style>
