<template>
  <div class="eh-menu-content">
      <button @click="getTasks" class="eh-action-button">
        <b-icon icon="arrow-repeat"></b-icon>
        <!-- Frissítés -->
        {{$t("base.basic.update")}}
      </button>
      <task-table
        :taskList="taskList"
        :taskListFields="taskListFields"
        :hasFilter="true"
      />
  </div>
</template>
<script>
import { UserTaskLogic } from "@/logic/backend/user-task";
import TaskTable from "@/components/table/TaskTable.vue";
export default {
  components: { TaskTable },
  name: "SpecialistTasks",
  data() {
    return {
      taskList: [],
      taskListFields: [
        {
          key: "Status",
          label: this.$t("healthProfessional.tasks.status"),
          sortable: true,
        },
        { key: "Type", label: this.$t("healthProfessional.tasks.type") },
        {
          key: "StartTime",
          label: this.$t("healthProfessional.tasks.startDate"),
          sortable: true,
        },
        {
          key: "Deadline",
          label: this.$t("healthProfessional.tasks.deadline"),
          sortable: true,
        },
        {
          key: "Priority",
          label: this.$t("healthProfessional.tasks.priority"),
          sortable: true,
        },
        { key: "Title", label: this.$t("healthProfessional.tasks.task") },
        {
          key: "Description",
          label: this.$t("healthProfessional.tasks.description"),
        },
      ],
    };
  },
  methods: {
    async getTasks() {
      //TODO: projectId == null ?
      const getTasksResponse = await UserTaskLogic.getTasks({
        UserId: this.$loggedUser.UserId,
        "-orderby": "Deadline",
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      if (!getTasksResponse.Code) {
        this.taskList = getTasksResponse;
      } else {
        this.$bvToast.toast(getTasksResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getTasks();
    this.$store.dispatch(
      "setPageTitle",this.$t("healthProfessional.menu.tasks"));
  },
};
</script>
