<template>
  <div>
    <label class="eh-switch-input">
      <!-- 3 állapotú switch tervezés -->
      <!-- <input
        id="three-state-checkbox"
        :class="value == null ? 'null-state' : ''"
        @change="setValue"
        :value="value"
        type="checkbox"
      /> -->
      <input :disabled="disabled" v-model="tempValue" type="checkbox" />
      <span
        class="eh-switch-slider"
        :class="setClass"
      ></span>
    </label>
    <span class="eh-switch-desctiption">{{ description }}</span>
  </div>
</template>
<script>
export default {
  name: "SwitchComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    disabled: Boolean,
    description: String,
    value: Boolean,
    type: String, //round,square
  },
  data() {
    return {
      tempValue: this.value,
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
    value(input) {
      this.tempValue = input;
    },
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.disabled) {
        temp += " disabled-input ";
      } else {
        temp += " not-disabled-input ";
      }
      if (this.type == 'round') {
        temp += " round-slider ";
      } else {
        temp += " square-slider ";
      }
      return temp;
    },
  },
  mounted() {
    this.$emit("change", this.tempValue);
  },
};
</script>
<style scoped>
.switch-desctiprion {
  margin-left: 10px;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 27px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #cce1f0;
  border: solid 3px #627e8f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider.input-disabled {
  background-color: #cce1f088;
  border: solid 3px #627e8f88;
  cursor: default;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  border: solid 1px #627e8f99;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #84a9c1; */
  background-color: #febb40;
}

/* input.null-state + .slider {
  background-color: #9fcff5;
} */

input:focus + .slider {
  box-shadow: 0 0 1px #84a9c1;
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
input.null-state + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 35px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>