//TODO: nevek egységesítése WebApp tag törlése
import Vue from "vue";
import VueRouter from "vue-router";
import { UserLogic } from "@/logic/backend/user";
import { AuthLogic } from "@/logic/backend/auth";
/* Static */
import Static from '@/views/static/RouterView.vue'
import NotFound from '@/views/static/NotFound.vue'
import Home from '@/views/static/Home.vue'
import DPC from '@/views/static/DPC.vue'
import News from '@/views/static/News.vue'
import About from '@/views/static/About.vue'
/* import TMKK from '@/views/static/TMKK.vue' */
import Login from '@/views/static/Login.vue'
import ApplicationsActive from '@/views/static/applications/Active.vue'
import ApplicationsClosed from '@/views/static/applications/Closed.vue'
/* import ApplicationsOpened from '@/views/static/applications/Opened.vue' */
/* import ResearchReports from '@/views/static/research/Reports.vue' */
/* import ResearchKnowledge from '@/views/static/research/Knowledge.vue' */
import ResearchPartners from '@/views/static/research/Partners.vue'
/* Footer */
/* import DataProtection from '@/views/static/footermenu/DataProtection.vue' */
import SiteMap from '@/views/static/footermenu/SiteMap.vue'
/* import Impress from '@/views/static/footermenu/Impress.vue' */
/* Admin */
import Admin from "@/views/admin/RouterView.vue";
import WebAppAdminHome from "@/views/admin/Home.vue";
/* Admin - Main menu */
import WebAppAdminProfile from "@/views/admin/main-menu/Profile.vue";
import WebAppAdminProjectRole from "@/views/admin/main-menu/ProjectRole.vue";
import WebAppAdminNewProject from "@/views/admin/main-menu/NewProject";
import WebAppAdminProject from "@/views/admin/main-menu/Project";
/* Admin - Sub menu */
import WebAppAdminNewUser from "@/views/admin/sub-menu/NewUser.vue";
import WebAppAdminUserManager from "@/views/admin/sub-menu/UserManager.vue";
/* Client */
import Client from "@/views/client/RouterView.vue";
import WebAppClientHome from "@/views/client/Home.vue";
/* Client - Main menu */
import WebAppClientDevices from "@/views/client/main-menu/Devices.vue";
import WebAppClientFamily from "@/views/client/main-menu/Family.vue";
import WebAppClientProfile from "@/views/client/main-menu/Profile.vue";
import WebAppClientNotifications from "@/views/client/main-menu/Notifications.vue";
import WebAppClientDocuments from "@/views/client/main-menu/Documents.vue";
import WebAppClientPrograms from "@/views/client/main-menu/Programs.vue";
import WebAppClientQuestionnaireList from "@/views/client/main-menu/QuestionnaireList.vue";
import WebAppClientQuestionnaire from "@/views/client/input/Questionnaire.vue";
import WebAppClientTasks from "@/views/client/main-menu/Tasks.vue";
import WebAppClientAppointments from "@/views/client/main-menu/Appointments.vue";
/* Client - Sub menu */
import WebAppClientBadges from "@/views/client/sub-menu/Badges.vue";
import WebAppClientDiseaseGuide from "@/views/client/sub-menu/DiseaseGuide.vue";
import WebAppClientServiceFinder from "@/views/client/sub-menu/ServiceFinder.vue";
/* Client - Module */
import ClientCardiologyModule from "@/views/client/module/CardiologyModule.vue";
import ClientExerciseModule from "@/views/client/module/ExerciseModule.vue";
import ClientBodyWeightModule from "@/views/client/module/BodyWeightModule.vue";
import ClientMedicineModule from "@/views/client/module/MedicineModule.vue";
import ClientPulmonologyModule from "@/views/client/module/PulmonologyModule.vue";
import ClientDepressionModule from "@/views/client/module/DepressionModule.vue";
import ClientDiabetologyModule from "@/views/client/module/DiabetologyModule.vue";
import ClientNutritionModule from "@/views/client/module/NutritionModule.vue";
import ClientMedicinePlan from "@/views/client/module/MedicinePlan.vue";
import ClientHealthGoal from "@/views/client/module/HealthGoal.vue";
/* Client - Diary */
import ClientExerciseDiary from "@/views/client/diary/ExerciseDiary.vue";
import ClientBloodPressureDiary from "@/views/client/diary/BloodPressureDiary.vue";
import ClientEkgDiary from "@/views/client/diary/EkgDiary.vue";
import ClientPulseDiary from "@/views/client/diary/PulseDiary.vue";
import ClientBodyWeightDiary from "@/views/client/diary/BodyWeightDiary.vue";
import ClientMedicineDiary from "@/views/client/diary/MedicineDiary.vue";
import ClientOxygenSaturationDiary from "@/views/client/diary/OxygenSaturationDiary.vue";
import ClientMoodDiary from "@/views/client/diary/MoodDiary.vue";
import ClientSleepDiary from "@/views/client/diary/SleepDiary.vue";
import ClientBloodGlucoseDiary from "@/views/client/diary/BloodGlucoseDiary.vue";
import ClientInsulinDiary from "@/views/client/diary/InsulinDiary.vue";
import ClientNutritionDiary from "@/views/client/diary/NutritionDiary.vue";
/* Client - Settings */
import ModuleSettings from "@/views/client/settings/ModuleSettings.vue";
import FunctionSettings from "@/views/client/settings/FunctionSettings.vue";
/* Specialist */
import Specialist from "@/views/specialist/RouterView.vue";
import WebAppSpecialistHome from "@/views/specialist/Home.vue";
/* Specialist - Menu */
import WebAppSpecialistProfile from "@/views/specialist/menu/Profile.vue";
import WebAppSpecialistPatientList from "@/views/specialist/menu/PatientList.vue";
import WebAppSpecialistCalendar from "@/views/specialist/menu/Calendar.vue";
import WebAppSpecialistTasks from "@/views/specialist/menu/Tasks.vue";
import WebAppSpecialistDocuments from "@/views/specialist/menu/Documents.vue";
import WebAppSpecialistQuestionnaires from "@/views/specialist/menu/Questionnaires.vue";
import WebAppSpecialistProjects from "@/views/specialist/menu/Projects.vue";
import WebAppSpecialistPatientManager from "@/views/specialist/menu/PatientManager.vue";
import WebAppSpecialistWorkflow from "@/views/specialist/menu/Workflow.vue";
import WebAppSpecialistAppointments from "@/views/specialist/menu/Appointments.vue";
import WebAppSpecialistTemplates from "@/views/specialist/menu/Templates.vue";
/* Specialis - Questionnaire */
import WebAppSpecialistEvaluatingQuestionnaire from "@/views/specialist/questionnaire/EvaluatingQuestionnaire.vue";
import WebAppSpecialistQuestionnaire from "@/views/specialist/questionnaire/Questionnaire.vue";
import WebAppSpecialistForm from "@/views/specialist/questionnaire/Form.vue";

/* Partner */
import Partner from "@/views/partner/RouterView.vue";
import WebAppPartnerHome from "@/views/partner/Home.vue";

/* Partner - Menu */
import WebAppPartnerProfile from "@/views/partner/menu/Profile.vue";
import WebAppPartnerRegistrationParticipant from "@/views/partner/menu/RegistrationParticipant";
import WebAppPartnerParticipantList from "@/views/partner/menu/ParticipantList";
import WebAppPartnerTemplates from "@/views/partner/menu/Templates";
import WebAppPartnerProjects from "@/views/partner/menu/Projects";
import WebAppPartnerTasks from "@/views/partner/menu/Tasks";
import WebAppPartnerDocuments from "@/views/partner/menu/Documents";
import WebAppPartnerQuestionnaires from "@/views/partner/menu/Questionnaires";
import WebAppPartnerEvaluatingQuestionnaire from "@/views/partner/questionnaire-components/EvaluatingQuestionnaire";
import WebAppPartnerWorkflows from "@/views/partner/menu/Workflows";

/* Partner - Footer menu */
import WebAppPartnerResearch from "@/views/partner/footer-menu/Research";
import WebAppPartnerLogs from "@/views/partner/footer-menu/Logs";

// TODO: Lazy-load components if possible
// TODO: Extracting large parents to separate files might be a good idea, this is getting pretty huge

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Static",
    component: Static,
    children: [
      {
        path: '/',
        name: 'Home',
        components: {
          customStatic: Home,
        },
      },
      {
        path: '/Login',
        name: 'Login',
        components: {
          static: Login,
        },
      },
      {
        path: '/DPC',
        name: 'DPC',
        components: {
          static: DPC,
        },
      },
      {
        path: '/News',
        name: 'News',
        components: {
          static: News,
        },
      },
      {
        path: '/About',
        name: 'About',
        components: {
          static: About,
        },
      },
      /* {
        path: '/TMKK',
        name: 'TMKK',
        components: {
          static: TMKK,
        },
      }, */
      {
        path: '/ApplicationsActive',
        name: 'ApplicationsActive',
        components: {
          customStatic: ApplicationsActive,
        },
      },
      {
        path: '/ApplicationsClosed',
        name: 'ApplicationsClosed',
        components: {
          customStatic: ApplicationsClosed,
        },
      },
      /* {
        path: '/ApplicationsOpened',
        name: 'ApplicationsOpened',
        components: {
          static: ApplicationsOpened,
        },
      }, */
      /* {
        path: '/ResearchReports',
        name: 'ResearchReports',
        components: {
          static: ResearchReports,
        },
      }, */
      /* {
        path: '/ResearchKnowledge',
        name: 'ResearchKnowledge',
        components: {
          static: ResearchKnowledge,
        },
      }, */
      {
        path: '/ResearchPartners',
        name: 'ResearchPartners',
        components: {
          static: ResearchPartners,
        },
      },
      {
        path: '/SiteMap',
        name: 'SiteMap',
        components: {
          static: SiteMap,
        },
      },
      /* {
        path: '/Impress',
        name: 'Impress',
        components: {
          static: Impress,
        },
      }, */
      /* {
        path: '/DataProtection',
        name: 'DataProtection',
        components: {
          static: DataProtection,
        },
      }, */
    ],
  },
  {
    path: "/Admin",
    name: "Admin",
    component: Admin,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppAdminHome",
        components: {
          admin: WebAppAdminHome,
        },
      },
      {
        path: "Home",
        name: "WebAppAdminHome",
        components: {
          admin: WebAppAdminHome,
        },
      },
      {
        path: "Profile",
        name: "WebAppAdminProfile",
        components: {
          admin: WebAppAdminProfile,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "ProjectRole",
        name: "WebAppAdminProjectRole",
        components: {
          admin: WebAppAdminProjectRole,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "NewUser",
        name: "WebAppAdminNewUser",
        components: {
          admin: WebAppAdminNewUser,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "UserManager",
        name: "WebAppAdminUserManager",
        components: {
          admin: WebAppAdminUserManager,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "NewProject",
        name: "WebAppAdminNewProject",
        components: {
          admin: WebAppAdminNewProject,
        },
        props: {
          admin: true,
        },
      },
      {
        path: "Project",
        name: "WebAppAdminProject",
        components: {
          admin: WebAppAdminProject,
        },
        props: {
          admin: true,
        },
      },
    ],
    meta: ["Administrator"],
  },
  {
    path: "/Partner",
    name: "Partner",
    component: Partner,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppPartnerHome",
        components: {
          partner: WebAppPartnerHome,
        },
      },
      {
        path: "Home",
        name: "WebAppPartnerHome",
        components: {
          partner: WebAppPartnerHome,
        },
      },
      {
        path: "RegistrationParticipant",
        name: "WebAppPartnerRegistrationParticipant",
        components: {
          partner: WebAppPartnerRegistrationParticipant,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "ParticipantList",
        name: "WebAppPartnerParticipantList",
        components: {
          partner: WebAppPartnerParticipantList,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Projects",
        name: "WebAppPartnerProjects",
        components: {
          partner: WebAppPartnerProjects,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Documents",
        name: "WebAppPartnerDocuments",
        components: {
          partner: WebAppPartnerDocuments,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Tasks",
        name: "WebAppPartnerTasks",
        components: {
          partner: WebAppPartnerTasks,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Templates",
        name: "WebAppPartnerTemplates",
        components: {
          partner: WebAppPartnerTemplates,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Questionnaires",
        name: "WebAppPartnerQuestionnaires",
        components: {
          partner: WebAppPartnerQuestionnaires,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "EvaluatingQuestionnaire",
        name: "WebAppPartnerEvaluatingQuestionnaire",
        components: {
          partner: WebAppPartnerEvaluatingQuestionnaire,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Research",
        name: "WebAppPartnerResearch",
        components: {
          partner: WebAppPartnerResearch,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Logs",
        name: "WebAppPartnerLogs",
        components: {
          partner: WebAppPartnerLogs,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Workflows",
        name: "WebAppPartnerWorkflows",
        components: {
          partner: WebAppPartnerWorkflows,
        },
        props: {
          partner: true,
        },
      },
      {
        path: "Profile",
        name: "WebAppPartnerProfile",
        components: {
          partner: WebAppPartnerProfile,
        },
        props: {
          partner: true,
        },
      },
    ],
    meta: ["Partner", "Administrator", "Technical"],
  },
  {
    path: "/Specialist",
    name: "Specialist",
    component: Specialist,
    props: true,
    children: [
      {
        path: "",
        name: "WebAppSpecialistHome",
        components: {
          specialist: WebAppSpecialistHome,
        },
      },
      {
        path: "Home",
        name: "WebAppSpecialistHome",
        components: {
          specialist: WebAppSpecialistHome,
        },
      },
      {
        path: "PatientList",
        name: "WebAppSpecialistPatientList",
        components: {
          specialist: WebAppSpecialistPatientList,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "PatientManager",
        name: "WebAppSpecialistPatientManager",
        components: {
          specialist: WebAppSpecialistPatientManager,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Questionnaires",
        name: "WebAppSpecialistQuestionnaires",
        components: {
          specialist: WebAppSpecialistQuestionnaires,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Questionnaire",
        name: "WebAppSpecialistQuestionnaire",
        components: {
          specialist: WebAppSpecialistQuestionnaire,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Form",
        name: "WebAppSpecialistForm",
        components: {
          specialist: WebAppSpecialistForm,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "EvaluatingQuestionnaire",
        name: "WebAppSpecialistEvaluatingQuestionnaire",
        components: {
          specialist: WebAppSpecialistEvaluatingQuestionnaire,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Documents",
        name: "WebAppSpecialistDocuments",
        components: {
          specialist: WebAppSpecialistDocuments,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Calendar",
        name: "WebAppSpecialistCalendar",
        components: {
          specialist: WebAppSpecialistCalendar,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Tasks",
        name: "WebAppSpecialistTasks",
        components: {
          specialist: WebAppSpecialistTasks,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Project",
        name: "WebAppSpecialistProjects",
        components: {
          specialist: WebAppSpecialistProjects,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Profile",
        name: "WebAppSpecialistProfile",
        components: {
          specialist: WebAppSpecialistProfile,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Workflow",
        name: "WebAppSpecialistWorkflow",
        components: {
          specialist: WebAppSpecialistWorkflow,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Appointments",
        name: "WebAppSpecialistAppointments",
        components: {
          specialist: WebAppSpecialistAppointments,
        },
        props: {
          specialist: true,
        },
      },
      {
        path: "Templates",
        name: "WebAppSpecialistTemplates",
        components: {
          specialist: WebAppSpecialistTemplates,
        },
        props: {
          specialist: true,
        },
      },
    ],
    meta: ["Supervisor", "HealthProfessional", "Administrator", "Technical"],
  },
  {
    path: "/Client",
    name: "Client",
    component: Client,
    props: true,
    children: [
      {
        path: "Home",
        name: "WebAppClientHome",
        components: {
          client: WebAppClientHome,
        },
      },
      {
        path: "",
        name: "WebAppClientHome",
        components: {
          client: WebAppClientHome,
        },
      },
      {
        path: "Cardiology",
        name: "ClientCardiologyModule",
        components: {
          client: ClientCardiologyModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Exercise",
        name: "ClientExerciseModule",
        components: {
          client: ClientExerciseModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "BodyWeight",
        name: "ClientBodyWeightModule",
        components: {
          client: ClientBodyWeightModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Medicine",
        name: "ClientMedicineModule",
        components: {
          client: ClientMedicineModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Pulmonology",
        name: "ClientPulmonologyModule",
        components: {
          client: ClientPulmonologyModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Depression",
        name: "ClientDepressionModule",
        components: {
          client: ClientDepressionModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Diabetology",
        name: "ClientDiabetologyModule",
        components: {
          client: ClientDiabetologyModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "NutritionModule",
        name: "ClientNutritionModule",
        components: {
          client: ClientNutritionModule,
        },
        props: {
          client: true,
        },
      },
      {
        path: "ExerciseDiary",
        name: "ExerciseDiary",
        components: {
          client: ClientExerciseDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "EkgDiary",
        name: "EkgDiary",
        components: {
          client: ClientEkgDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "PulseDiary",
        name: "PulseDiary",
        components: {
          client: ClientPulseDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "BloodPressureDiary",
        name: "BloodPressureDiary",
        components: {
          client: ClientBloodPressureDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "MedicineDiary",
        name: "ClientMedicineDiary",
        components: {
          client: ClientMedicineDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "BodyWeightDiary",
        name: "ClientBodyWeightDiary",
        components: {
          client: ClientBodyWeightDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "OxygenSaturationDiary",
        name: "ClientOxygenSaturationDiary",
        components: {
          client: ClientOxygenSaturationDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "MoodDiary",
        name: "ClientMoodDiary",
        components: {
          client: ClientMoodDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "SleepDiary",
        name: "ClientSleepDiary",
        components: {
          client: ClientSleepDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "InsulinDiary",
        name: "ClientInsulinDiary",
        components: {
          client: ClientInsulinDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "BloodGlucoseDiary",
        name: "ClientBloodGlucoseDiary",
        components: {
          client: ClientBloodGlucoseDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "NutritionDiary",
        name: "ClientNutritionDiary",
        components: {
          client: ClientNutritionDiary,
        },
        props: {
          client: true,
        },
      },
      {
        path: "HealthGoal",
        name: "HealthGoal",
        components: {
          client: ClientHealthGoal,
        },
        props: {
          client: true,
        },
      },
      {
        path: "MedicinePlan",
        name: "ClientMedicinePlan",
        components: {
          client: ClientMedicinePlan,
        },
        props: {
          client: true,
        },
      },
      {
        path: "ModuleSettings",
        name: "ModuleSettings",
        components: {
          client: ModuleSettings,
        },
        props: {
          client: true,
        },
      },
      {
        path: "FunctionSettings",
        name: "FunctionSettings",
        components: {
          client: FunctionSettings,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Profile",
        name: "WebAppClientProfile",
        components: {
          client: WebAppClientProfile,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Notifications",
        name: "WebAppClientNotifications",
        components: {
          client: WebAppClientNotifications,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Devices",
        name: "WebAppClientDevices",
        components: {
          client: WebAppClientDevices,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Family",
        name: "WebAppClientFamily",
        components: {
          client: WebAppClientFamily,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Badges",
        name: "WebAppClientBadges",
        components: {
          client: WebAppClientBadges,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Appointments",
        name: "WebAppClientAppointments",
        components: {
          client: WebAppClientAppointments,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Programs",
        name: "WebAppClientPrograms",
        components: {
          client: WebAppClientPrograms,
        },
        props: {
          client: true,
        },
      },
      {
        path: "DiseaseGuide",
        name: "WebAppClientDiseaseGuide",
        components: {
          client: WebAppClientDiseaseGuide,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Documents",
        name: "WebAppClientDocuments",
        components: {
          client: WebAppClientDocuments,
        },
        props: {
          client: true,
        },
      },
      {
        path: "ServiceFinder",
        name: "WebAppClientServiceFinder",
        components: {
          client: WebAppClientServiceFinder,
        },
        props: {
          client: true,
        },
      },
      {
        path: "QuestionnaireList",
        name: "WebAppClientQuestionnaireList",
        components: {
          client: WebAppClientQuestionnaireList,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Questionnaire",
        name: "WebAppClientQuestionnaire",
        components: {
          client: WebAppClientQuestionnaire,
        },
        props: {
          client: true,
        },
      },
      {
        path: "Tasks",
        name: "WebAppClientTasks",
        components: {
          client: WebAppClientTasks,
        },
        props: {
          client: true,
        },
      },
    ],
    meta: ["Client", "Administrator", "Technical"],
  },
  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const toParentRoute = to.matched.find((m) => Array.isArray(m.meta));
  var authorize = null;
  if (Array.isArray(to.meta)) {
    authorize = to.meta;
  } else if (toParentRoute) {
    authorize = toParentRoute.meta;
  }
  const token = AuthLogic.canStartRequest();
  const loggedUserResponse = UserLogic.getSignedUser();
  //TODO: ha van token de valamiért nincs signedUser akkor az kell újra generálni
  if (!token || !loggedUserResponse) {
    const staticPage = to.matched.find((x) => x.name == "Static");
    //nincs érvényes token
    if (to.name != "Login" && !staticPage) {
      /* var url = to.path;
      const queriesArray = Object.keys(to.query);
      if (queriesArray.length > 0) {
        url = url + "?";
        queriesArray.forEach((key) => (url += "&" + key + "=" + to.query[key]));
      } */
      //ha nem a Login oldalon van, akkor érvénytelen token esetén a Login oldalra irányít
      AuthLogic.clearSessionStorage();
      return next({ name: "Login" /* , query: { returnUrl: url }  */ });
    }
  }
  if (Array.isArray(authorize)) {
    //ha van a megnyitni kívánt oldalnak a metá-ban szerepkörök
    if (
      authorize.length &&
      loggedUserResponse &&
      !authorize.some((auth) => loggedUserResponse.Roles.includes(auth))
    ) {
      //ha a szerepkörökben a bejelentkezett felhasználó szerepköre nem szerepel akkor vissza irányít a Menube
      const loggedUserHomePage =
        Vue.prototype.$enums.UserRoleWithHome[loggedUserResponse.SelectedRole];

      return next({ name: loggedUserHomePage.Home });
    }
  }
  if (!Vue.prototype.$loggedUser && loggedUserResponse) {
    Vue.prototype.$loggedUser = loggedUserResponse;
  }

  if (
    Vue.prototype.$loggedUser &&
    to.path.split("/")[1] != from.path.split("/")[1]
  ) {
    const newRole = Object.values(
      Vue.prototype.$enums.UserRoleWithHome
    ).find((role) => role.Home.includes(to.path.split("/")[1]));
    if (newRole) {
      Vue.prototype.$loggedUser.SelectedRole = newRole.Value;
      UserLogic.saveLoginUser(Vue.prototype.$loggedUser);
    }
  }
  //ha van token és nincs jogosultság korlátozás (metában nincs szerepkör)
  next();
});

export default router;
