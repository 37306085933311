<template>
  <div class="home">
    <div id="slide-main-buttons-box">
      <div id="slide-box">
        <img id="slide-img" src="@/assets/tmkk/slider/slide1.png" />
        <img id="slide-shape" src="@/assets/tmkk/backgrounds/slide1b.png" />
      </div>
      <b-row id="main-buttons-box" align-h="center">
        <div class="inactive-img main-buttons mobile-first-image">
          <img class="mobile-width" src="@/assets/tmkk/buttons/dpc_gomb.png" />
          <router-link to="/DPC"
            ><img
              class="active-img mobile-width mobile-first-image-active"
              src="@/assets/tmkk/buttons/dpc_gomb_aktiv.png"
          /></router-link>
        </div>
        
        <!-- <div class="inactive-img main-buttons">
          <img class="mobile-width" src="@/assets/tmkk/buttons/tmkk_gomb.png" />
          <router-link to="/TMKK"
            ><img
              @mousedown="closeSubMenu"
              class="active-img mobile-width"
              src="@/assets/tmkk/buttons/tmkk_gomb_aktiv.png"
          /></router-link>
        </div> -->
      </b-row>
    </div>
    <b-row cols="1" class="buttons-box" align-h="center">
      <b-col>
        <b-row align-h="center">
          <div class="inactive-img buttons">
            <img
              class="mobile-width"
              src="@/assets/tmkk/buttons/aktiv palyazatok_gomb.png"
            />
            <router-link to="/ApplicationsActive"
              ><img
                class="active-img mobile-width"
                src="@/assets/tmkk/buttons/aktiv palyazatok_gomb_aktiv.png"
            /></router-link>
          </div>
          <!-- <div class="inactive-img buttons">
            <img
              class="mobile-width"
              src="@/assets/tmkk/buttons/kutatasi jelentes_gomb.png"
            />
            <router-link to="/ResearchReports"
              ><img
                @mousedown="closeSubMenu"
                class="active-img mobile-width"
                src="@/assets/tmkk/buttons/kutatasi jelentes_gomb_aktiv.png"
            /></router-link>
          </div> -->
          <div class="inactive-img buttons">
            <img
              class="mobile-width"
              src="@/assets/tmkk/buttons/kutatasi partner_gomb.png"
            />
            <router-link to="/ResearchPartners"
              ><img
                class="active-img mobile-width"
                src="@/assets/tmkk/buttons/kutatasi partner_gomb_aktiv.png"
            /></router-link>
          </div>
        </b-row>
      </b-col>
      <b-col>
        <b-row align-h="center">
          <div class="inactive-img buttons">
            <img
              class="mobile-width"
              src="@/assets/tmkk/buttons/lezart palyazatok_gomb.png"
            />
            <router-link to="/ApplicationsClosed"
              ><img
                class="active-img mobile-width"
                src="@/assets/tmkk/buttons/lezart palyazatok_gomb_aktiv.png"
            /></router-link>
          </div>
          <div class="inactive-img buttons">
            <img
              class="mobile-width"
              src="@/assets/tmkk/buttons/placeholder.png"
            />
          </div>
          <!-- <div class="inactive-img buttons">
            <img
              class="mobile-width"
              src="@/assets/tmkk/buttons/tudasbazis_gomb.png"
            />
            <router-link to="/ResearchKnowledge"
              ><img
                @mousedown="closeSubMenu"
                class="active-img mobile-width"
                src="@/assets/tmkk/buttons/tudasbazis_gomb_aktiv.png"
            /></router-link>
          </div> -->
        </b-row>
      </b-col>
      <b-col>
        <b-row align-h="center">
          <!-- <div class="inactive-img buttons">
            <img
              class="mobile-width"
              src="@/assets/tmkk/buttons/nyitott palyazatok_gomb.png"
            />
            <router-link to="/ApplicationsOpened"
              ><img
                @mousedown="closeSubMenu"
                class="active-img mobile-width"
                src="@/assets/tmkk/buttons/nyitott palyazatok_gomb_aktiv.png"
            /></router-link>
          </div> -->
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Home",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
#slide-img {
  width: 100%;
  height: auto;
}
#slide-box {
  margin-top: -9px;
  position: relative;
  display: inline-block;
}
#slide-shape {
  opacity: 0.5;
  width: 100%;
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
#main-buttons-box {
  align-content: center;
  width: 100%;
  position: relative;
  margin-top: -166px;
  margin-left: 0px;
  top: 20;
  left: 0;
  z-index: 99;
}
.main-buttons {
  margin-left: 30px;
  margin-right: 30px;
}
.buttons-box {
  margin-left: 0px;
  margin-top: 70px;
  padding-bottom: 50px;
  padding-top: 50px;
  background: url(../../assets/tmkk/backgrounds/palyazat_kutatas_hatter.png);
  width: 100%;
  height: auto;
}
.buttons {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 30px;
  margin-right: 30px;
}
@media (max-width: 400px) {
  .mobile-width {
    width: 300px;
  }
  .mobile-first-image {
    margin-top: -93px;
    margin-bottom: 25px;
  }
  .mobile-first-image-active {
    margin-top: 0px;
  }
  #main-buttons-box {
    margin-top: 0px;
  }
}
@media (max-width: 900px) and (min-width: 500px) {
  .mobile-width {
    width: 400px;
  }
  .mobile-first-image {
    margin-top: 42px;
    margin-bottom: 25px;
  }
}
@media (min-width: 900px) {
  .mobile-first-image {
    margin-bottom: 25px;
  }
}
</style>