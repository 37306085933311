<template>
  <div class="page-background">
    <div>
      <div style="text-align: center" class="diary-title">
        {{ $t("module.diaries.nutrition.post") }}
      </div>
      <div style="margin: 20px 0px">
        <button class="eh-action-button" @click="goBack">
          {{ $t("base.basic.back") }}
        </button>
        <button
          class="eh-action-button"
          @click="showNutritionDiaryInputModal = true"
        >
          {{ $t("module.diaries.newPost") }}
        </button>
      </div>

      <client-module-diary baseName="nutrition" v-model="refreshList" />
      <NutritionDiaryInput
        @refreshList="refreshList = true"
        v-model="showNutritionDiaryInputModal"
      />
    </div>
  </div>
</template>

<script>
import NutritionDiaryInput from "@/views/client/input/NutritionDiaryInput.vue";
import ClientModuleDiary from "@/components/module/ClientModuleDiary.vue";

export default {
  name: "NutritionDiary",
  components: { NutritionDiaryInput, ClientModuleDiary },
  data() {
    return {
      refreshList: false,
      showNutritionDiaryInputModal: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t("module.diaries.nutrition.title"));
  },
};
</script>
<style>
.diary-title {
  font-size: 1.5em;
}
.e-grid .e-headercell.customcss {
  font-size: 20px;
  word-wrap: normal;
}
</style>
