<template>
<!-- TODO: magasság vizsgálat validálást csekkolni -->
  <fp-form-modal
    hide-footer
    id="body-weight-input-modal"
    :title="$t('module.diaries.bodyWeight.addNewPost')"
    v-model="showInputModal"
  >
    <template #content>
      <b-form :class="theme">
        <fp-date-time-picker
          :label="$t('module.diaries.measureDate')"
          v-model="newEntry.MeasuredAt"
          :required="true"
          :state="!validation.hasError('newEntry.MeasuredAt')"
          :error="validation.firstError('newEntry.MeasuredAt')"
        />
        <fp-input
          type="number"
          v-model="newEntry.BodyWeight"
          :label="$t('module.diaries.bodyWeight.weight')"
          :placeholder="$t('module.diaries.bodyWeight.weightPlaceholder')"
          :state="!validation.hasError('newEntry.BodyWeight')"
          :error="validation.firstError('newEntry.BodyWeight')"
          :required="true"
        />
        <fp-input
          type="number"
          v-model="newEntry.BodyHeight"
          :label="$t('module.diaries.bodyWeight.height')"
          :placeholder="$t('module.diaries.bodyWeight.heightPlaceholder')"
          :state="!validation.hasError('newEntry.BodyHeight')"
          :error="validation.firstError('newEntry.BodyHeight')"
          :required="true"
        />
        <fp-input
          type="number"
          v-model="newEntry.FatPercentage"
          :label="$t('module.diaries.bodyWeight.fat')"
          :placeholder="$t('module.diaries.bodyWeight.percentage')"
          :state="!validation.hasError('newEntry.FatPercentage')"
          :error="validation.firstError('newEntry.FatPercentage')"
        />
        <fp-input
          type="number"
          v-model="newEntry.WaterPercentage"
          :label="$t('module.diaries.bodyWeight.water')"
          :placeholder="$t('module.diaries.bodyWeight.percentage')"
          :state="!validation.hasError('newEntry.WaterPercentage')"
          :error="validation.firstError('newEntry.WaterPercentage')"
        />
        <fp-input
          type="number"
          v-model="newEntry.MusclePercentage"
          :label="$t('module.diaries.bodyWeight.muscle')"
          :placeholder="$t('module.diaries.bodyWeight.percentage')"
          :state="!validation.hasError('newEntry.MusclePercentage')"
          :error="validation.firstError('newEntry.MusclePercentage')"
        />
        <div style="text-align: right">
          <b-button @click="modalOk">{{ $t("base.basic.save") }}</b-button>
        </div>
      </b-form>
    </template>
  </fp-form-modal>
</template>
<script>
import { DiaryLogic } from "@/logic/backend/diary";
import moment from "moment";
export default {
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      newBodyWeightData: {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
      },
      newEntry: {
        MeasuredAt: moment().format(),
        MusclePercentage: null,
        WaterPercentage: null,
        FatPercentage: null,
        BodyHeight: null,
        BodyWeight: null,
      },
      isValidInputs: {},
      showInputModal: null,
      theme: sessionStorage.getItem("currentTheme") || "eh-fp",
    };
  },
  watch: {
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  computed: {
    validInput() {
      var inputsValid = Object.values(this.isValidInputs).some((x) => !x);
      return !inputsValid;
    },
  },
  validators: {
    "newEntry.MeasuredAt": function(value) {
      return this.$validator.value(value).required();
    },
    "newEntry.BodyWeight": function(value) {
      return this.$validator
        .value(value)
        .required()
        .integer()
        .greaterThan(0);
    },
    "newEntry.BodyHeight": function(value) {
      return this.$validator
        .value(value)
        .required()
        .float()
        .between(0, 3);
    },
    "newEntry.MusclePercentage": function(value) {
      return this.$validator
        .value(value)
        .integer()
        .between(0, 100);
    },
    "newEntry.FatPercentage": function(value) {
      return this.$validator
        .value(value)
        .integer()
        .between(0, 100);
    },
    "newEntry.WaterPercentage": function(value) {
      return this.$validator
        .value(value)
        .integer()
        .between(0, 100);
    },
  },
  methods: {
    async modalOk() {
      const valid = await this.$validate();
      if (valid) {
        const response = await DiaryLogic.addDiaryEntry("body_weight", [
          this.newEntry,
        ]);
        if (!response.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(response.Message, {
            title: this.$t("requestResponse.moduleDiary.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    /* async modalOk() {
      this.newBodyWeightData.MeasuredAt = moment(this.newBodyWeightData.date)
        .add(this.newBodyWeightData.time)
        .format();
      Object.assign(this.newBodyWeightData, { SourceName: "UserInput" });
      const response = await DiaryLogic.addDiaryEntry("body_weight", [
        this.newBodyWeightData,
      ]);
      if (!response.Code) {
        this.$bvToast.toast(
          this.$t("client.diaries.bodyWeight.form.successSave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("refreshList");
        this.showInputModal = false;
      } else {
        this.$bvToast.toast(response.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    }, */
  },
};
</script>
