<template>
    <div v-if="arrayData && groupedArrays" class="medical-profile-data">
        <div class="fp-boubles-title">{{ label }}</div>
        <div class="fp-empty" v-if="groupedArrays.length == 0 && disabled">
            {{ $t("base.noData") }}
        </div>
        <div v-else>
            <div class="fp-boubles" v-for="group in groupedArrays" :key="group.key">
                <fp-bouble-modal :title="group.key"
                                 :length="group.value.length"
                                 @openDetails="$set(group, 'OpenDetails', true)" />
                <!-- Lista megtekintés -->
                <fp-form-modal v-model="group.OpenDetails" :title="group.key">
                    <template #content>
                        <b-list-group v-if="group.value.length > 0"
                                      class="fp-list-box-group">
                            <b-list-group-item v-for="(element, index) in group.value"
                                               :key="index"
                                               class="fp-group-item"
                                               :class="
                  group.value.length == 1
                    ? ' single-element '
                    : (index == group.value.length - 1
                        ? ' last-element '
                        : ' not-last-element ') + ' multiple-element '
                ">
                                <div>
                                    <b>{{ $t("client.profile.medicalData.bnoCode") }}: </b>{{ getDisplayData(element.IcdCode) }}<br />
                                    <b>{{ $t("client.profile.medicalData.appearDate") }}: </b>{{
                    getDisplayData(
                      $convertDateToString(element.StartDate, "YYYY-MM-DD")
                    )
                                    }}<br />
                                    <b>{{ $t("client.profile.medicalData.cessationDate") }}: </b>{{
                    getDisplayData(
                      $convertDateToString(element.EndDate, "YYYY-MM-DD"),
                      element.StartDate
                        ? $t("client.profile.medicalData.stillLasts")
                        : null
                    )
                                    }}
                                    <!-- convert -->
                                </div>
                                <div class="item-delete-icon"
                                     v-b-tooltip.hover="$t('base.basic.delete')"
                                     :style="
                    disabled
                      ? 'display:none'
                      : 'vertical-align: bottom; margin: auto 0px auto 40px'
                  ">
                                    <b-icon icon="x-circle"
                                            style="cursor: pointer"
                                            @click="deleteElement(element)" />
                                </div>
                            </b-list-group-item>
                        </b-list-group>
                    </template>
                </fp-form-modal>
            </div>
            <div v-if="!disabled" class="fp-plus-icon">
                <b-icon @click="showCreateNew = true" icon="plus-circle-fill" />
            </div>
        </div>
        <!-- Új elem felvétele -->
        <fp-form-modal v-model="showCreateNew"
                       :title="$t('client.profile.medicalData.newDisease')">
            <template #content>
                <fp-select v-if="showNameSelectList"
                           :label="$t('client.profile.medicalData.disease')"
                           :items="list"
                           :description="$t('client.profile.medicalData.diseaseFromList')"
                           :searchable="true"
                           :state="!validation.hasError('newElement.Name')"
                           :error="validation.firstError('newElement.Name')"
                           @change="selectBaseElement"
                           @search="search($event)">
                    <template #element="element">
                        {{ $getLanguagedText(element.Name) }}
                        <br />
                        {{ $t("client.profile.medicalData.bnoCode") }}:
                        {{ element.IcdCode }}
                    </template>
                </fp-select>
                <fp-input v-if="showNameInput"
                          :label="$t('client.profile.medicalData.diseaseName')"
                          :state="!validation.hasError('newElement.Name')"
                          :error="validation.firstError('newElement.Name')"
                          :description="$t('client.profile.medicalData.diseaseBySelf')"
                          @change="addNewElementNameInput" />
                <fp-input :label="$t('client.profile.medicalData.appearDate')"
                          v-model="newElement.StartDate" />
                <fp-input :label="$t('client.profile.medicalData.cessationDate')"
                          v-model="newElement.EndDate" />
                <b-button @click="addNewElement">{{ $t("base.basic.save") }}</b-button>
            </template>
        </fp-form-modal>
    </div>
</template>
<script>
    //import { HelperMethods } from "@/logic/ui/helpers";
    import { BaseDataLogic } from "../../../Logic/Backend/base-data";
    export default {
        name: "DiseaseDiagnosis",
        model: {
            prop: "value",
            event: "change",
        },
        props: {
            value: Array,
            disabled: Boolean,
            label: String,
        },
        data() {
            return {
                arrayData: this.value,
                groupedArrays: [],
                showCreateNew: false,
                newElement: {
                    ProdedureId: null,
                    Name: null,
                    NameWriteInput: null,
                    Code: null,
                    StartDate: null,
                    EndDate: null,
                    Result: null,
                },
                defaultNewElement: {
                    ProdedureId: null,
                    Name: null,
                    Code: null,
                    StartDate: null,
                    EndDate: null,
                    Result: null,
                },
                selectedBaseElement: null,
                showNameSelectList: true,
                showNameInput: true,
                list: null,
            };
        },
        validators: {
            "newElement.Name": function (value) {
                return this.$validator.value(value).required();
            },
        },
        watch: {
            value(input) {
                this.arrayData = input;
                this.groupedArrays = this.checkDuplicateName();
            },
        },
        methods: {
            /* Ellenőrzi és rendezi a diplukált csoportokat a bouble nevek alapján.
               Ellátja az elemeke ElementId-val, ami a törléshez szükséges az elem beazonosításához. */
            //duplikáció vizsgálat
            checkDuplicateName() {
                //index alapból 0
                var index = 0;
                //végig megyünk a lista minden elemén és adunk nekik Element Id-t
                this.arrayData.forEach((x) => (x.ElementId = index++));
                //csoportosítjuk a lista elemeket megadott változó alapján
                var tempMap = this.$groupByMostEfficient(
                    this.arrayData,
                    (listE) => listE.Name /* + ' - ' + listE.IcdCode */
                );
                //a kapott csoportosított map átalakítása listává
                var tempArray = [];
                tempMap.forEach((value, key) => {
                    if (key) {
                        tempArray.push({ key: key, value: value });
                    }
                });
                //vissza érünk a generált listával
                return tempArray;
            },
            selectBaseElement(elem) {
                this.newElement.IcdCode = elem.IcdCode;
                this.newElement.Name = this.$getLanguagedText(elem.Name);
                this.newElement.DiseaseId = elem.DiseaseId;
            },
            addNewElementNameInput(input) {
                if (input && input.length > 0) {
                    this.showNameSelectList = false;
                    this.newElement.Name = input;
                    this.newElement.IcdCode = null;
                    this.newElement.DiseaseId = null;
                } else {
                    this.showNameSelectList = true;
                }
            },
            deleteElement(elem) {
                const index = this.arrayData.indexOf(elem);
                if (index >= 0) {
                    this.arrayData.splice(index, 1);
                    this.groupedArrays = this.checkDuplicateName();
                    this.$emit("change", this.arrayData);
                }
            },
            getDisplayData(data, emptyText) {
                if (data) {
                    return data;
                } else if (emptyText) {
                    return emptyText;
                }
                return this.$t("client.profile.medicalData.noData");
            },
            async addNewElement() {
                const valid = await this.$validate();
                if (valid) {
                    this.newElement.StartDate = this.$convertDateToString(
                        this.newElement.StartDate
                    );
                    this.newElement.EndDate = this.$convertDateToString(
                        this.newElement.EndDate
                    );
                    this.arrayData.push(this.newElement);
                    this.groupedArrays = this.checkDuplicateName();
                    this.$emit("change", this.arrayData);
                    this.showCreateNew = false;
                    this.newElement = JSON.parse(JSON.stringify(this.defaultNewElement));
                    this.validation.reset();
                }
            },
            async search(input) {
                if (input && input.length > 2) {
                    var temp = [];
                    const searchByName = await this.getList({
                        ["Name." + this.$i18n.locale]: input,
                    });
                    const searchByIcdCode = await this.getList({
                        "IcdCode-contains": input,
                    });
                    temp = searchByName.concat(searchByIcdCode);
                    this.list = temp;
                } else {
                    this.list = await this.getList({ "-limit": 10 });
                }
            },
            async getList(params) {
                const getListResponse = await await BaseDataLogic.getBaseData(
                    "disease",
                    params
                );
                if (!getListResponse.Code) {
                    return getListResponse;
                } else {
                    //hiba
                }
                return null;
            },
        },
        async beforeMount() {
            this.groupedArrays = this.checkDuplicateName();
            this.list = await this.getList({ "-limit": 10 });
        },
    };
</script>
