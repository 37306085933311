var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-container',{attrs:{"fluid":"true"}},[(_vm.isCreated && !_vm.isEditable)?_c('b-button',{on:{"click":function($event){_vm.isEditable = true}}},[_vm._v(_vm._s(_vm.$t('base.basic.edit'))+" ")]):_vm._e(),(_vm.isCreated && _vm.isEditable)?_c('b-button',{on:{"click":_vm.setProject}},[_vm._v(_vm._s(_vm.$t('base.basic.save'))+" ")]):_vm._e(),(_vm.isCreated && _vm.isEditable)?_c('b-button',{on:{"click":_vm.cancelProject}},[_vm._v(_vm._s(_vm.$t('base.basic.cancel'))+" ")]):_vm._e(),(_vm.projectForm && _vm.projectForm.Research)?_c('div',[_c('div',{staticStyle:{"width":"200px"}},[_c('fp-select',{attrs:{"disabled":_vm.isCreated && !_vm.isEditable,"type":"multi","label":"Nyelvek","items":Object.values(_vm.$enums.Languages),"valueKey":"Value","textKey":"Text"},on:{"change":function($event){return _vm.storeChangedProjectElements(
              'SupportedLanguages',
              _vm.projectForm.SupportedLanguages
            )}},scopedSlots:_vm._u([{key:"element",fn:function(e){return [_c('img',{staticStyle:{"margin":"0px 5px"},attrs:{"width":"20px","src":require('@/assets' + e.flag)}}),_vm._v(" "+_vm._s(e.Text)+" ")]}}],null,false,3670946469),model:{value:(_vm.projectForm.SupportedLanguages),callback:function ($$v) {_vm.$set(_vm.projectForm, "SupportedLanguages", $$v)},expression:"projectForm.SupportedLanguages"}})],1),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"2"}},[_vm._v(" Projekt típusa: ")]),_c('b-col',[_c('b-form-radio-group',{attrs:{"disabled":_vm.isCreated && !_vm.isEditable},on:{"change":function($event){return _vm.storeChangedProjectElements('Type', _vm.projectForm.Type)}},model:{value:(_vm.projectForm.Type),callback:function ($$v) {_vm.$set(_vm.projectForm, "Type", $$v)},expression:"projectForm.Type"}},[_c('b-form-radio',{attrs:{"value":"HealthProgram"}},[_vm._v(" Egészség program ")]),_c('b-form-radio',{attrs:{"value":"CareProgram"}},[_vm._v(" Gondozási program ")])],1)],1)],1),_c('b-row',{attrs:{"align-content":"center"}},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"6","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.newProject.testPatientCount"))+": ")]),_c('b-col',{staticClass:"col-6 col-md-auto"},[_c('fp-input',{staticClass:"input-margin",attrs:{"type":"number","disabled":_vm.isCreated && !_vm.isEditable},on:{"change":function($event){return _vm.storeChangedProjectElements(
                'Research.TreatmentParticipantCount',
                _vm.projectForm.Research.TreatmentParticipantCount
              )}},model:{value:(_vm.projectForm.Research.TreatmentParticipantCount),callback:function ($$v) {_vm.$set(_vm.projectForm.Research, "TreatmentParticipantCount", $$v)},expression:"projectForm.Research.TreatmentParticipantCount"}})],1)],1),_c('b-row',{attrs:{"align-content":"center"}},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"6","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.newProject.controlPatientCount"))+": ")]),_c('b-col',{staticClass:"col-6 col-md-auto"},[_c('fp-input',{attrs:{"type":"number","disabled":_vm.isCreated && !_vm.isEditable},on:{"change":function($event){return _vm.storeChangedProjectElements(
                'Research.ControlParticipantCount',
                _vm.projectForm.Research.ControlParticipantCount
              )}},model:{value:(_vm.projectForm.Research.ControlParticipantCount),callback:function ($$v) {_vm.$set(_vm.projectForm.Research, "ControlParticipantCount", $$v)},expression:"projectForm.Research.ControlParticipantCount"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.projectName"))+": ")]),_c('b-col',[_c('b-row',{attrs:{"cols":"1","cols-md":"3"}},[_vm._l((_vm.projectForm.SupportedLanguages),function(lang){return _c('b-col',{key:'ProjectName-' + lang},[_c('fp-input',{staticClass:"input-margin",attrs:{"label":_vm.$enums.Languages[lang].Text,"disabled":_vm.isCreated && !_vm.isEditable},on:{"change":function($event){return _vm.storeChangedProjectElements(
                    'Research.Name',
                    _vm.projectForm.Research.Name
                  )}},model:{value:(_vm.projectForm.Research.Name[lang]),callback:function ($$v) {_vm.$set(_vm.projectForm.Research.Name, lang, $$v)},expression:"projectForm.Research.Name[lang]"}})],1)}),(
                !_vm.projectForm.SupportedLanguages ||
                  _vm.projectForm.SupportedLanguages.length == 0
              )?_c('b-col',[_c('fp-input',{staticClass:"input-margin",attrs:{"label":"A megadáshoz válasszon nyelvet!","disabled":true}})],1):_vm._e()],2)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.projectTitle"))+": ")]),_c('b-col',[_c('b-row',{attrs:{"cols":"1","cols-md":"3"}},[_vm._l((_vm.projectForm.SupportedLanguages),function(lang){return _c('b-col',{key:'ProjectDescription-' + lang},[_c('fp-input',{staticClass:"input-margin",attrs:{"disabled":_vm.isCreated && !_vm.isEditable,"label":_vm.$enums.Languages[lang].Text},on:{"change":function($event){return _vm.storeChangedProjectElements(
                    'Research.Description',
                    _vm.projectForm.Research.Description
                  )}},model:{value:(_vm.projectForm.Research.Description[lang]),callback:function ($$v) {_vm.$set(_vm.projectForm.Research.Description, lang, $$v)},expression:"projectForm.Research.Description[lang]"}})],1)}),(
                !_vm.projectForm.SupportedLanguages ||
                  _vm.projectForm.SupportedLanguages.length == 0
              )?_c('b-col',[_c('fp-input',{staticClass:"input-margin",attrs:{"label":"A megadáshoz válasszon nyelvet!","disabled":true}})],1):_vm._e()],2)],1)],1),_c('b-row',{staticStyle:{"margin-top":"10px"}},[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.timestamp"))+": ")]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('date-picker',{attrs:{"disabled":_vm.isCreated && !_vm.isEditable,"label":"Kezdés"},on:{"change":function($event){return _vm.storeChangedProjectElements(
                'Research.StartDate',
                _vm.projectForm.Research.StartDate
              )}},model:{value:(_vm.projectForm.Research.StartDate),callback:function ($$v) {_vm.$set(_vm.projectForm.Research, "StartDate", $$v)},expression:"projectForm.Research.StartDate"}})],1),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('date-picker',{attrs:{"disabled":_vm.isCreated && !_vm.isEditable,"label":"Vége"},on:{"change":function($event){return _vm.storeChangedProjectElements(
                'Research.EndDate',
                _vm.projectForm.Research.EndDate
              )}},model:{value:(_vm.projectForm.Research.EndDate),callback:function ($$v) {_vm.$set(_vm.projectForm.Research, "EndDate", $$v)},expression:"projectForm.Research.EndDate"}})],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.privacy"))+": ")]),_c('b-col',[_c('b-form-radio-group',{attrs:{"disabled":_vm.isCreated && !_vm.isEditable,"id":"projectForm-radio","text-field":"Text","value-field":"Value"},model:{value:(_vm.privacy),callback:function ($$v) {_vm.privacy=$$v},expression:"privacy"}},_vm._l((_vm.privacyOptions),function(option,name){return _c('b-form-radio',{key:'projectForm-radio-' + name,attrs:{"value":option.Value}},[_vm._v(" "+_vm._s(_vm.$t(option.Text))+" ")])}),1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-top",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.projectGoal"))+": ")]),_c('b-col',[_c('b-row',{attrs:{"cols":"1"}},[_vm._l((_vm.projectForm.SupportedLanguages),function(lang){return _c('b-col',{key:'ResearchGoal-' + lang},[_c('div',{staticStyle:{"margin":"10px 0px"}},[_c('img',{staticStyle:{"margin":"0px 5px"},attrs:{"width":"20px","src":require('@/assets' + _vm.$enums.Languages[lang].flag)}}),_vm._v(" "+_vm._s(_vm.$enums.Languages[lang].Text)+" "),(!_vm.isCreated || _vm.isEditable)?_c('v-md-editor',{attrs:{"height":"400px","left-toolbar":"undo redo clear | h bold italic strikethrough quote | ul ol table hr | link","right-toolbar":"preview sync-scroll fullscreen"},on:{"change":function($event){return _vm.storeChangedProjectElements(
                      'Research.ResearchGoal',
                      _vm.projectForm.Research.ResearchGoal
                    )}},model:{value:(_vm.projectForm.Research.ResearchGoal[lang]),callback:function ($$v) {_vm.$set(_vm.projectForm.Research.ResearchGoal, lang, $$v)},expression:"projectForm.Research.ResearchGoal[lang]"}}):_c('v-md-preview',{staticClass:"no-margin-markdown",attrs:{"text":_vm.projectForm.Research.ResearchGoal[lang],"height":"400px"}})],1)])}),(
                !_vm.projectForm.SupportedLanguages ||
                  _vm.projectForm.SupportedLanguages.length == 0
              )?_c('b-col',[_c('fp-input',{staticClass:"input-margin",attrs:{"label":"A megadáshoz válasszon nyelvet!","disabled":true}})],1):_vm._e()],2)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-top",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.selectionCriteria"))+": ")]),_c('b-col',[_c('b-row',{attrs:{"cols":"1"}},[_vm._l((_vm.projectForm.SupportedLanguages),function(lang){return _c('b-col',{key:'SelectionCriteria-' + lang},[_c('div',{staticStyle:{"margin":"10px 0px"}},[_c('img',{staticStyle:{"margin":"0px 5px"},attrs:{"width":"20px","src":require('@/assets' + _vm.$enums.Languages[lang].flag)}}),_vm._v(" "+_vm._s(_vm.$enums.Languages[lang].Text)+" "),(!_vm.isCreated || _vm.isEditable)?_c('v-md-editor',{attrs:{"height":"400px","left-toolbar":"undo redo clear | h bold italic strikethrough quote | ul ol table hr | link","right-toolbar":"preview sync-scroll fullscreen"},on:{"change":function($event){return _vm.storeChangedProjectElements(
                      'Research.SelectionCriteria',
                      _vm.projectForm.Research.SelectionCriteria
                    )}},model:{value:(_vm.projectForm.Research.SelectionCriteria[lang]),callback:function ($$v) {_vm.$set(_vm.projectForm.Research.SelectionCriteria, lang, $$v)},expression:"projectForm.Research.SelectionCriteria[lang]"}}):_c('v-md-preview',{staticClass:"no-margin-markdown",attrs:{"text":_vm.projectForm.Research.SelectionCriteria[lang],"height":"400px"}})],1)])}),(
                !_vm.projectForm.SupportedLanguages ||
                  _vm.projectForm.SupportedLanguages.length == 0
              )?_c('b-col',[_c('fp-input',{staticClass:"input-margin",attrs:{"label":"A megadáshoz válasszon nyelvet!","disabled":true}})],1):_vm._e()],2)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-top",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.description"))+": ")]),_c('b-col',[_c('b-row',{attrs:{"cols":"1"}},[_vm._l((_vm.projectForm.SupportedLanguages),function(lang){return _c('b-col',{key:'ResearchPlan-' + lang},[_c('div',{staticStyle:{"margin":"10px 0px"}},[_c('img',{staticStyle:{"margin":"0px 5px"},attrs:{"width":"20px","src":require('@/assets' + _vm.$enums.Languages[lang].flag)}}),_vm._v(" "+_vm._s(_vm.$enums.Languages[lang].Text)+" "),(!_vm.isCreated || _vm.isEditable)?_c('v-md-editor',{attrs:{"height":"400px","left-toolbar":"undo redo clear | h bold italic strikethrough quote | ul ol table hr | link","right-toolbar":"preview sync-scroll fullscreen"},on:{"change":function($event){return _vm.storeChangedProjectElements(
                      'Research.ResearchPlan',
                      _vm.projectForm.Research.ResearchPlan
                    )}},model:{value:(_vm.projectForm.Research.ResearchPlan[lang]),callback:function ($$v) {_vm.$set(_vm.projectForm.Research.ResearchPlan, lang, $$v)},expression:"projectForm.Research.ResearchPlan[lang]"}}):_c('v-md-preview',{staticClass:"no-margin-markdown",attrs:{"text":_vm.projectForm.Research.ResearchPlan[lang],"height":"400px"}})],1)])}),(
                !_vm.projectForm.SupportedLanguages ||
                  _vm.projectForm.SupportedLanguages.length == 0
              )?_c('b-col',[_c('fp-input',{staticClass:"input-margin",attrs:{"label":"A megadáshoz válasszon nyelvet!","disabled":true}})],1):_vm._e()],2)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }