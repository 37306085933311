<template>
  <div>
    <b-button @click="showProjectItemModal = true">Új elem felvétele</b-button>
    {{ convertedItemList.length }}
    <fp-table-list :items="convertedItemList" :fields="convertedItemFields">
      <template #Name="row">
        {{ getTextByLanguage(row.item.Name) }}
      </template>
      <template #Description="row">
        {{ getTextByLanguage(row.item.Description) }}
      </template>
      <template #Operations="row">
        <div style="text-align: right">
          <b-icon
            class="h3"
            style="cursor: pointer"
            icon="trash"
            @click="removeItem(row.item)"
          />
        </div>
      </template>
    </fp-table-list>
    <project-item-form
      v-model="showProjectItemModal"
      :forms="forms"
      :tags="tags"
      :templates="templates"
      :workflows="workflows"
      @addItem="addItem"
      @getLists="getItemEntities"
    />
  </div>
</template>
<script>
import { TemplateLogic } from "@/logic/backend/template";
import { FormLogic } from "@/logic/backend/form";
import { TagLogic } from "@/logic/backend/tag";
import { WorkflowLogic } from "@/logic/backend/workflow";
import ProjectItemForm from "./ProjectItemForm";
import { ProjectLogic } from "@/logic/backend/project";

export default {
  name: "ProjectItems",
  components: { ProjectItemForm },
  props: {
    items: Array,
    isCreated: Boolean,
    createdProjectId: Number,
  },
  data() {
    return {
      templates: null,
      forms: null,
      tags: null,
      workflows: null,
      showProjectItemModal: false,
      convertedItemList: null,
      convertedItemFields: [
        { key: "EntityType", label: "Elem típusa" },
        { key: "Name", label: "Megnevezés" },
        { key: "Description", label: "Leírás" },
        { key: "Operations", label: "" },
        /*{key: 'Parameters', label: 'Projekt elem paraméterek'},*/
      ],
    };
  },
  watch: {
    items() {
      this.convertItemList();
    },
  },
  methods: {
    async addItem(item) {
      if (this.isCreated) {
        await this.addItemToProject(item);
      }
      this.$emit("update:items", [
        ...this.items,
        { EntityTypeName: item.EntityType, EntityId: item.EntityId },
      ]);
    },
    async addItemToProject(item) {
      const addRoleResponse = await ProjectLogic.setItem(
        [{ EntityTypeName: item.EntityType, EntityId: item.EntityId }],
        this.createdProjectId
      );
      if (!addRoleResponse.Code) {
        //TODO: sikeres
        this.$emit("getList");
      } else {
        //TODO: hiba
      }
    },
    async removeItem(item) {
      if (this.isCreated) {
        await this.removeItemFromProject(item);
      } else {
        const index = this.items
          .map((e) => e.EntityTypeName + "-" + e.EntityId)
          .indexOf(item.EntityType + "-" + item.EntityId);
        this.items.splice(index, 1);
      }
    },
    async removeItemFromProject(item) {
      const selectedItem = this.items.find(
        (i) =>
          i.EntityId === item.EntityId && i.EntityTypeName === item.EntityType
      );
      if (selectedItem && selectedItem.ItemId) {
        const addRoleResponse = await ProjectLogic.removeItem(
          this.createdProjectId,
          selectedItem.ItemId
        );
        if (!addRoleResponse.Code) {
          //TODO: sikeres
          this.$emit("getList");
        } else {
          //TODO: hiba
        }
      }
    },
    getTextByLanguage(textObject) {
      if (typeof textObject == "object") {
        const languages = Object.keys(textObject);
        if (languages.includes(this.$i18n.locale)) {
          return textObject[this.$i18n.locale];
        } else if (languages.includes("en")) {
          return textObject.en;
        } else {
          return textObject[languages[0]];
        }
      } else {
        return textObject;
      }
    },
    async getTemplates() {
      const getTemplatesResponse = await TemplateLogic.getTemplates();
      if (!getTemplatesResponse.Code) {
        this.templates = getTemplatesResponse;
      } else {
        //TODO: hiba
      }
    },
    async getForms() {
      const getFormsResponse = await FormLogic.getFormSchemaHeader();
      if (!getFormsResponse.Code) {
        this.forms = getFormsResponse;
      } else {
        //TODO: hiba
      }
    },
    async getTags() {
      const getTagsResponse = await TagLogic.getTags();
      if (!getTagsResponse.Code) {
        this.tags = getTagsResponse;
      } else {
        //TODO: hiba
      }
    },
    async getWorkflow() {
      const getWorkflowResponse = await WorkflowLogic.getWorkflowSchemaList();
      if (!getWorkflowResponse.Code) {
        this.workflows = getWorkflowResponse;
      } else {
        //TODO: hiba
      }
    },
    async getItemEntities() {
      await this.getTemplates();
      await this.getForms();
      await this.getTags();
      await this.getWorkflow();
    },
    convertItemList() {
      this.convertedItemList = [];
      if (this.items) {
        this.items.forEach((i) => {
          if (
            i.EntityTypeName ==
            this.$enums.ProjectItemsEntityTypeName.FpWorkflow
          ) {
            const w = this.workflows.find((wf) => wf.WorkflowId == i.EntityId);
            if (w) {
              this.convertedItemList.push({
                Name: w.Name,
                Description: w.Description,
                EntityType: i.EntityTypeName,
                EntityId: i.EntityId,
              });
            }
          } else if (i.EntityTypeName == this.$enums.ProjectItemsEntityTypeName.FormSchema) {
            const f = this.forms.find(
              (form) => form.FormSchemaId == i.EntityId
            );
            if (f) {
              this.convertedItemList.push({
                Name: f.Title,
                Description: f.Description,
                Category: f.FormCategory,
                EntityType: i.EntityTypeName,
                EntityId: i.EntityId,
              });
            }
          } else if (i.EntityTypeName == "FpTag") {
            const t = this.tags.find((tag) => tag.TagId == i.EntityId);
            if (t) {
              this.convertedItemList.push({
                Name: t.Name,
                EntityType: i.EntityTypeName,
                EntityId: i.EntityId,
              });
            }
          } else if (i.EntityTypeName == "NotificationTemplate") {
            const nt = this.templates.find(
              (template) => template.TemplateId == i.EntityId
            );
            if (nt) {
              this.convertedItemList.push({
                Name: nt.Name,
                NotiTypes: {
                  Email: nt.EmailTemplate ? true : false,
                  Push: nt.PushTemplate ? true : false,
                  Sms: nt.SmsTemplate ? true : false,
                },
                SupportedLanguages: nt.SupportedLanguages,
                EntityType: i.EntityTypeName,
                EntityId: i.EntityId,
              });
            }
          }
        });
      }
    },
  },
  async mounted() {
    await this.getItemEntities();
    this.convertItemList();
  },
};
</script>
