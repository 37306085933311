<template>
  <b-row v-if="statement" cols="1" cols-md="2">
    <b-col>
      <fp-text-area
        label="Állapot változás miatti felírás indoklása"
        description="Állapotváltozás miatt a kihordási időn belül új terméket kell felírni. Ténye, oka, mérték leírása."
        v-model="statement.StateChangeDescription"
        @change="changeValue('StateChangeDescription', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-text-area
        label="Méret változás miatti felírás indoklása"
        description="Méretváltozás miatt a kihordási időn belül új terméket kell felírni. Ténye, oka, mérték leírása."
        v-model="statement.SizeChangeDescription"
        @change="changeValue('SizeChangeDescription', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-select
        label="Beteg által tett nyilatkozat rendelkezésre áll?"
        v-model="statement.PatientStatementAvailable"
        @change="changeValue('PatientStatementAvailable', $event)"
        :items="threeStateList"
        valueKey="Value"
        textKey="Text"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-panel-card
        title="Előzetes engedélyezés"
        :error="errorPreliminaryApproval"
      >
        <template #content>
          <statement-preliminary-approval
            ref="statmentPreliminaryApproval"
            v-model="statement.PreliminaryApproval"
            @changeValue="changeValue('PreliminaryApproval', $event)"
            :disabled="disabled"
          />
        </template>
      </fp-panel-card>
    </b-col>
  </b-row>
</template>
<script>
import StatementPreliminaryApproval from "./StatementPreliminaryApproval.vue";
export default {
  components: { StatementPreliminaryApproval },
  name: "Statement",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      statement: null,
      changeStatementValue: {},
      defaultStatement: {
        StateChangeDescription: null,
        SizeChangeDescription: null,
        PatientStatementAvailable: null,
        PreliminaryApproval: null,
      },
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
      errorPreliminaryApproval: false,
    };
  },
  watch: {
    value() {
      this.setStatement();
    },
  },
  computed: {
    hasStatementData() {
      return Object.values(this.statement).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    setStatement() {
      if (this.value) {
        this.statement = this.value;
      } else {
        this.statement = JSON.parse(JSON.stringify(this.defaultStatement));
      }
    },
    changeValue(key, value) {
      if (this.hasStatementData) {
        this.changeStatementValue[key] = value;
        this.$emit("change", this.statement);
        this.$emit("changeValue", this.changeStatementValue);
      } else {
        this.$emit("change", null);
        this.$emit("changeValue", null);
      }
    },
    async checkValidation() {
      const statmentPreliminaryApprovalValid = await this.$refs[
        "statmentPreliminaryApproval"
      ].checkValidation();
      this.errorPreliminaryApproval = !statmentPreliminaryApprovalValid;

      return statmentPreliminaryApprovalValid;
    },
    resetValidation() {
      if (this.$refs["statmentPreliminaryApproval"]) {
        this.$refs["statmentPreliminaryApproval"].resetValidation();
      }
    },
  },
  mounted() {
    this.setStatement();
  },
};
</script>
