import BeverReportList from "./Components/ReportList/BeverReportList.vue";
import CreateBeverReport from "./Components/Create/CreateBeverReport.vue";
import CreateNeakReportForm from "./Components/Create/CreateNeakReportForm.vue";
import SelectPrescriptionList from "./Components/Common/SelectPrescriptionList.vue";
import ReportFormModal from "./Components/Report/ReportFormModal.vue";

const BeverView = () => import("./ViewBeverReportManager.vue");

export default {
  install(Vue) {
    if (!Vue.prototype.$activeModules) {
      Vue.prototype.$activeModules = [];
    }
    Vue.prototype.$activeModules.push("BeverReport");

    Vue.component("fp-bever-list", BeverReportList);
    Vue.component("fp-create-bever", CreateBeverReport);
    Vue.component("fp-bever-step-select-prescription", SelectPrescriptionList);
    Vue.component("fp-bever-step-neak-report", CreateNeakReportForm);
    Vue.component("fp-bever-report-modal", ReportFormModal);
    Vue.component("fp-bever-manager", BeverView);
  },
};
