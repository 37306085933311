const BodyWeightBMI = () => import("./BodyWeight/BMI.vue");
const BodyWeightBodyWeight = () => import("./BodyWeight/BodyWeight.vue");
const CardiologyBloodPressure = () => import("./Cardiology/BloodPressure.vue");
const CardiologyPulse = () => import("./Cardiology/Pulse.vue");
const DepressionMood = () => import("./Depression/Mood.vue");
const DepressionSleep = () => import("./Depression/Sleep.vue");
const DiabetologyBloodGlucose = () => import("./Diabetology/BloodGlucose.vue");
const DiabetologyInsulin = () => import("./Diabetology/Insulin.vue");
const ExerciseCalories = () => import("./Exercise/Calories.vue");
const ExerciseDistance = () => import("./Exercise/Distance.vue");
const ExerciseDuration = () => import("./Exercise/Duration.vue");
const ExerciseFloors = () => import("./Exercise/Floors.vue");
const ExerciseSteps = () => import("./Exercise/Steps.vue");
const MedicineDaily = () => import("./Medicine/Daily.vue");
const MedicineMonthly = () => import("./Medicine/Monthly.vue");
const MedicineWeekly = () => import("./Medicine/Weekly.vue");
const NutritionCalorieIntake = () => import("./Nutrition/CalorieIntake.vue");
const PulmonologyOxygenSaturation = () =>
  import("./Pulmonology/OxygenSaturation.vue");
const PulmonologyRespiration = () => import("./Pulmonology/Respiration.vue");
const PulmonologyRespirationMeasurement = () =>
  import("./Pulmonology/RespirationMeasurement.vue");
const ClientModuleDiary = () => import("./ClientModuleDiary.vue");
const Setting = () => import("./Setting.vue");

export default {
  install(Vue) {
    //Modul aktivitás beállítása
    if (!Vue.prototype.$activeModules) {
      Vue.prototype.$activeModules = [];
    }
    Vue.prototype.$activeModules.push("Chart");

    //Modul komponensek regisztrálása
    Vue.component("fp-bodyweight-bmi", BodyWeightBMI);
    Vue.component("fp-bodyweight-bodyweight", BodyWeightBodyWeight);
    Vue.component("fp-cardiology-bloodpressure", CardiologyBloodPressure);
    Vue.component("fp-cardiology-pulse", CardiologyPulse);
    Vue.component("fp-depression-mood", DepressionMood);
    Vue.component("fp-depression-sleep", DepressionSleep);
    Vue.component("fp-diabetology-bloodglucose", DiabetologyBloodGlucose);
    Vue.component("fp-diabetology-insulin", DiabetologyInsulin);
    Vue.component("fp-exercise-calories", ExerciseCalories);
    Vue.component("fp-exercise-distance", ExerciseDistance);
    Vue.component("fp-exercise-duration", ExerciseDuration);
    Vue.component("fp-exercise-floors", ExerciseFloors);
    Vue.component("fp-exercise-steps", ExerciseSteps);
    Vue.component("fp-medicine-daily", MedicineDaily);
    Vue.component("fp-medicine-monthly", MedicineMonthly);
    Vue.component("fp-medicine-weekly", MedicineWeekly);
    Vue.component("fp-nutrition-calprieintake", NutritionCalorieIntake);
    Vue.component(
      "fp-pulmonology-oxygensaturation",
      PulmonologyOxygenSaturation
    );
    Vue.component("fp-pulmonology-respiration", PulmonologyRespiration);
    Vue.component(
      "fp-pulmonology-respirationmeasurement",
      PulmonologyRespirationMeasurement
    );
    Vue.component("fp-client-module-diary", ClientModuleDiary);
    Vue.component("fp-client-function-setting", Setting);
  },
};
