import { EhssClient, RequestMethod } from '@/backend/ehss-client'
import { RequestResponseLogic } from '@/logic/ui/request-response'

const GET_ACQUIRED_BADGES = '/badge/acquired'
const GET_UNACQUIRED_BADGES = '/badge/unacquired'

export const BadgesLogic = {
    async getAcquiredBadges(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            GET_ACQUIRED_BADGES, params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async getUnacquiredBadges(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            GET_UNACQUIRED_BADGES, params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
}