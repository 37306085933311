<template>
  <b-container fluid class="nopadding eh-datetime-picker-container">
    <b-row cols="1" cols-sm="2" class="eh-datetimepiecker">
      <b-col style="padding-left: 0px">
        <fp-date-picker
          :state="state"
          :disabled="disabled"
          :isInvalidInput="!isValidDate"
          v-model="tempDate"
        />
      </b-col>
      <b-col style="padding-right: 0px">
        <fp-time-picker
          :state="state"
          :disabled="disabled"
          :isInvalidInput="!isValidTime"
          v-model="tempTime"
        />
      </b-col>
    </b-row>
    <span class="eh-datetime-label" v-if="label">
      {{ label }}
      <span v-if="required" class="text-danger">*</span>
    </span>
    <div class="eh-base-input-desc-error-box">
      <div v-if="description" class="eh-base-input-description">
        {{ description }}
      </div>
      <div v-if="!state && error" class="eh-base-input-error-text">
        {{ error }}
      </div>
    </div>
  </b-container>
</template>
<script>
import moment from "moment";
export default {
  name: "fp-date-time-picker",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: String,
    description: String,
    error: String,
    defaultValue: String,
    disabled: Boolean,
    state: { type: Boolean, default: true },
    label: String,
    required: Boolean,
  },
  data() {
    return {
      isValidInputs: null,
      tempDate: this.setDateTimeValue("YYYY-MM-DD"),
      tempTime: this.setDateTimeValue("HH:mm:ss"),
      isValidDate: true,
      isValidTime: true,
      isFocus:false,
    };
  },
  watch: {
    tempDate(input) {
      console.log('tempDate', input)
      const date = moment(
        moment(input).format("YYYY-MM-DD") + "T" + this.tempTime
      );
      if (!moment(date).isValid()) {
        this.isValidDate = false;
        this.$emit("change", "");
        this.tempTime = "";
      } else {
        this.isValidDate = true;
        this.$emit("change", date.format());
      }
    },
    tempTime(input) {
      console.log('tempTime', input)
      const time = moment(
        moment(this.tempDate).format("YYYY-MM-DD") + "T" + input
      );
      const temp = moment(moment().format("YYYY-MM-DD") + "T" + input);
      if (temp.isValid()) {
        this.isValidTime = true;
        this.$emit("change", time.format());
      } else {
        this.isValidTime = false;
        this.$emit("change", "");
      }
    },
    value() {
      this.tempDate = this.setDateTimeValue("YYYY-MM-DD");
      this.tempTime = this.setDateTimeValue("HH:mm:ss");
    },
  },
  methods: {
    setDateTimeValue(format) {
      const temp = this.value;
      if (temp && moment(temp).isValid()) {
        this.$emit("change", temp);
        return moment(temp).format(format);
      }
    },
  },
};
</script>
