<!-- Version: 1.0.1 -->
<template>
  <div class="eh-participant-component">
    <div>
      <b-button
        class="eh-action-button"
        @click="getPrescriptionListByCurrentPage"
        >Frissítés</b-button
      >
    </div>
    <div>
      <b-button class="eh-action-button" @click="showNewPrescriptionForm = true"
        >Recept rögzítése</b-button
      >
    </div>
    <eeszt-options
      :seletedAddablePrescriptionIds="seletedAddablePrescriptionIds"
      :seletedDeletablePrescriptionIds="seletedDeletablePrescriptionIds"
      :seletedAddableDispenseIds="seletedAddableDispenseIds"
      :seletedDetailsAddableDispenseIds="seletedDetailsAddableDispenseIds"
      :seletedUpdatableDispenseIds="seletedUpdatableDispenseIds"
      :seletedDeletableDispenseIds="seletedDeletableDispenseIds"
      :selectedReviewableDispenseIds="selectedReviewableDispenseIds"
      :participantId="participant ? participant.UserId : null"
      :projectPatient="projectPatient"
      :projectRoles="projectRoles"
      @refreshList="getPrescriptionListByCurrentPage"
      :prescriptionIdToDispenseAddToEeszt.sync="
        prescriptionIdToDispenseAddToEeszt
      "
    />
    <new-prescription-form
      v-model="showNewPrescriptionForm"
      @saveRecipe="saveRecipe"
      :projectPatient="projectPatient"
      :projectRoles="projectRoles"
      :userPatient="participant"
    />
    <div>
      <div class="eh-flex-container">
        <fp-input
          class="eh-margin-right eh-fix-filter-input-width"
          :label="$t('base.basic.filter')"
          @change="filterList"
          :state="validateFilterByType"
          error="Recept azonosító szűrésekor csak 999999999-nél kisebb számot lehet megadni!"
          v-model="filter"
        />
        <b-radio-group
          style="margin: 30px 0px auto"
          v-model="filterType"
          @change="filterList"
        >
          <b-radio value="PrescriptionId"> Recept azonosító </b-radio>
          <b-radio value="PrescriptionData.EesztId-contains">
            EESZT azonosító
          </b-radio>
          <b-radio value="Remark-contains"> Megjegyzés </b-radio>
        </b-radio-group>
      </div>
      <div class="eh-flex-container">
        <fp-select
          class="eh-margin-right eh-fix-filter-input-width"
          label="EESZT státusz"
          :items="eesztStatusList"
          @change="filterList"
          v-model="eesztStatusFilter"
          valueKey="Value"
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
        <fp-select
          class="eh-margin-right eh-fix-filter-input-width"
          label="Helyi státusz"
          :items="localStatusList"
          @change="filterList"
          v-model="localStatusFilter"
          valueKey="Value"
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
        <fp-select
          class="eh-margin-right eh-fix-filter-input-width"
          label="EESZT frissítés"
          :items="eesztUpdateNeededList"
          @change="filterList"
          v-model="eesztUpdateNeededFilter"
          valueKey="Value"
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
        <fp-select
          class="eh-margin-right eh-fix-filter-input-width"
          label="Recept állapot"
          :items="[
            { Text: 'Aktív', Value: false },
            { Text: 'Archivált', Value: true },
            { Text: 'Minden állapot', Value: null },
          ]"
          @change="filterList"
          v-model="closedFilter"
          valueKey="Value"
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
        <fp-date-range-button
          label="Felírás dátuma"
          v-model="filterPrescriptionTimestamp"
          @change="filterList"
        />
        <fp-date-range-button
          label="Kiadás dátuma"
          v-model="filterDispenseTimestamp"
          @change="filterList"
        />
      </div>
    </div>
    <fp-pagination
      v-if="recipeListSize > recipePerPage"
      :itemsPerPage.sync="recipePerPage"
      :listSize.sync="recipeListSize"
      :currentPageNumber.sync="currentPage"
    />
    <fp-table-list
      :bordered="true"
      :items="recipeList"
      :fields="prescriptionsFields"
      :selectable="true"
      selectMode="multi"
      :sortBy.sync="sortBy"
      :sortDesc.sync="sortDesc"
      @row-selected="selectPrescription"
    >
      <template #head(PrescriptionId)="row">
        {{ row.label }}
        <b-icon-question-circle-fill
          v-b-tooltip.hover="'Rendezés a recept azonosító alapján'"
        />
      </template>
      <template #PrescriptionId="row">
        <fp-prescription-list-cell-ids
          :prescriptionData="row.item.PrescriptionData"
          :prescriptionClosed="row.item.Closed"
          :prescriptionId="row.item.PrescriptionId"
        />
      </template>
      <template #head(PrescriptionData.PrescriptionTimestamp)="row">
        {{ row.label }}
        <b-icon-question-circle-fill
          v-b-tooltip.hover="'Rendezés a recept felírás dátuma alapján'"
        />
      </template>
      <template #PrescriptionData.PrescriptionTimestamp="row">
        <fp-prescription-list-cell-base
          :prescriptionData="row.item.PrescriptionData"
          :eesztStatus="row.item.EesztStatus"
          :prescriptionRemark="row.item.Remark"
          :showPatientData="showPatientData"
        />
      </template>
      <template #PrescriptionProducts="row">
        <fp-prescription-list-cell-products
          :products="row.item.PrescriptionData.Products"
        />
      </template>
      <template #head(DispenseData.DispenseTimestamp)="row">
        {{ row.label }}
        <b-icon-question-circle-fill
          v-b-tooltip.hover="'Rendezés a recept kiadás dátuma alapján'"
        />
      </template>
      <template #DispenseData.DispenseTimestamp="row">
        <fp-prescription-list-cell-dispense
          :dispenseData="row.item.DispenseData"
        />
      </template>
      <template #Operations="row">
        <div>
          <!-- Prescription -->
          <div>
            Recept műveletek:
            <!-- Művelet gombok -->
            <div>
              <!-- Megtekintés -->
              <b-icon-eye-fill
                v-b-tooltip.hover="'Recept megtekintés'"
                class="h4"
                @click="$set(row.item, 'ShowDetails', true)"
              ></b-icon-eye-fill>
              <span v-if="!row.item.Closed">
                <!-- Recept lezárása -->
                <b-icon-archive-fill
                  v-b-tooltip.hover="'Recept archiválása'"
                  class="h4"
                  @click="closePrescription(row.item.PrescriptionId)"
                />
                <!-- Szerkesztés -->
                <b-icon-pencil-fill
                  v-if="
                    !row.item.PrescriptionData.EesztId && !row.item.DispenseData
                  "
                  v-b-tooltip.hover="'Recept szerkesztés'"
                  class="h4"
                  @click="
                    $set(row.item, 'ShowDetails', true);
                    $set(row.item, 'EditableForm', true);
                  "
                />
                <!-- Kiadás -->
                <b-icon-box-arrow-right
                  v-if="!row.item.DispenseData"
                  @click="$set(row.item, 'showDispenseFormModal', true)"
                  v-b-tooltip.hover="'Recept lefoglalás'"
                  variant="success"
                  class="h4"
                />
              </span>
              <!-- Recept aktiválás -->
              <b-icon-archive
                v-if="row.item.Closed"
                v-b-tooltip.hover="'Recept aktiválás'"
                class="h4"
                @click="openPrescription(row.item.PrescriptionId)"
              />
              <!-- Törlés -->
              <b-icon-trash-fill
                v-if="!row.item.DispenseData"
                variant="danger"
                v-b-tooltip.hover="'Recept törlése'"
                class="h5"
                @click="deletePrescription(row.item.PrescriptionId)"
              />
              <!-- Megjegyzés szerkesztés -->
              <b-iconstack
                @click="startEditPrescriptionRemark(row)"
                v-b-tooltip.hover="'Megjegyzés szerkesztése'"
                font-scale="1.5"
                style="margin: 5px"
              >
                <b-icon stacked icon="chat-right-text" />
                <b-icon
                  stacked
                  icon="pencil-fill"
                  shift-v="3"
                  shift-h="8"
                  scale="0.7"
                />
              </b-iconstack>
              <fp-form-modal
                v-model="row.item._showRemarkEditor"
                title="Megjegyzés szerkesztése"
                :closable="false"
              >
                <template #content>
                  <fp-text-area
                    label="Megjegyzés"
                    v-model="editedPrescriptionRemark"
                  />
                  <b-button @click="editPrescriptionRemark(row)"
                    >Mentés</b-button
                  >
                  <b-button @click="$set(row.item, '_showRemarkEditor', false)"
                    >Mégsem</b-button
                  >
                </template>
              </fp-form-modal>
            </div>
            <!-- Form modalok -->
            <div>
              <!-- Recept szerkesztés form -->
              <prescription-form
                :editable.sync="row.item.EditableForm"
                :projectPatient="projectPatient"
                :projectRoles="projectRoles"
                :patientId="participant ? participant.UserId : null"
                :prescriptionId="row.item.PrescriptionId"
                :recipe="row.item.PrescriptionData"
                v-model="row.item.ShowDetails"
                @refreshList="getPrescriptionListByCurrentPage"
              />
              <!-- Új kiadás rögzítés -->
              <new-dispense-form
                v-model="row.item.showDispenseFormModal"
                :prescriptionPatient="row.item.PrescriptionData.Patient"
                @saveDispense="saveDispense(row.item.PrescriptionId, $event)"
              />
            </div>
          </div>
          <!-- Dispense -->
          <div v-if="row.item.DispenseData">
            <hr />
            Kiadás műveletek:
            <!-- Művelet gombok -->
            <div>
              <!-- Megtekintés -->
              <b-icon-eye-fill
                v-if="row.item.DispenseData"
                v-b-tooltip.hover="'Kiadás megtekintés'"
                class="h4"
                @click="
                  $set(row.item, 'showDispenseDetailsForm', true);
                  $set(row.item, 'editDispenseDetailsForm', false);
                "
              ></b-icon-eye-fill>
              <span v-if="!row.item.Closed">
                <!-- Szerkesztés -->
                <b-icon-pencil-fill
                  v-if="row.item.DispenseData && !row.item.DispenseDetailsAdded"
                  v-b-tooltip.hover="'Foglalás szerkesztés'"
                  class="h4"
                  @click="
                    $set(row.item, 'showDispenseForm', true);
                    $set(row.item, 'editabledDispenseForm', true);
                  "
                />
                <!-- Részletek -->
                <b-icon-pencil-square
                  v-if="
                    row.item.DispenseData
                      ? !row.item.DispenseDetailsAdded
                      : false
                  "
                  v-b-tooltip.hover.bottom="'Kiadás rögzítése'"
                  variant="success"
                  class="h4"
                  @click="$set(row.item, 'showNewDispenseDetailsForm', true)"
                />
                <!-- Kiadás szerekesztés -->
                <b-icon-pencil-fill
                  v-if="
                    row.item.DispenseData
                      ? row.item.DispenseDetailsAdded && !row.item.FinanceData
                      : false
                  "
                  v-b-tooltip.hover="'Kiadás szerkesztés'"
                  class="h4"
                  @click="
                    $set(row.item, 'showDispenseDetailsForm', true);
                    $set(row.item, 'editDispenseDetailsForm', true);
                  "
                />
                <!-- kiadás ellenőrzés -->
                <b-icon-check-circle
                  v-if="
                    row.item.DispenseData &&
                    row.item.DispenseData.EesztId &&
                    row.item.DispenseDetailsAddedToEeszt &&
                    !row.item.DispenseReviewAdded &&
                    !row.item.DispenseReviewAddedToEeszt
                  "
                  v-b-tooltip.hover="'Kiadási ellenőrzés'"
                  class="h4"
                  @click="$set(row.item, 'showDispenseReviewModal', true)"
                />
                <!-- Törlés -->
                <b-icon-trash-fill
                  v-if="
                    row.item.DispenseData
                      ? !row.item.DispenseData.EesztId && !row.item.FinanceData
                      : false
                  "
                  variant="danger"
                  v-b-tooltip.hover="'Kiadás törlése'"
                  class="h4"
                  @click="
                    deleteDispense(row.item.PrescriptionId);
                    $set(row.item, 'showDispenseReviewModal', false);
                  "
                />
              </span>
              <!-- Kiadás igazolás -->
              <b-icon-file-arrow-down
                v-if="
                  row.item.DispenseData &&
                  row.item.DispenseData.EesztId &&
                  row.item.DispenseDetailsAddedToEeszt
                "
                v-b-tooltip.hover="'Kiadási igazolás letöltése'"
                class="h4"
                @click="downloadPdf(row.item.PrescriptionId)"
              />
            </div>
            <!-- Form modalok -->
            <div>
              <new-dispense-details-form
                v-model="row.item.showNewDispenseDetailsForm"
                :prescription="row.item.PrescriptionData"
                @saveDetails="
                  saveDispenseDetails(row.item.PrescriptionId, $event)
                "
              />
              <dispense-form
                v-model="row.item.showDispenseForm"
                :editable="row.item.editabledDispenseForm"
                :dispense="row.item.DispenseData"
                @editDispense="editDispense(row.item.PrescriptionId, $event)"
                :projectPatient="projectPatient"
                :prescriptionTimestamp="
                  row.item.PrescriptionData.PrescriptionTimestamp
                "
                :projectRoles="projectRoles"
                :patientId="
                  participant
                    ? participant.UserId
                    : row.item.PrescriptionData.Patient.PatientId
                "
              />
              <dispense-details-form
                v-model="row.item.showDispenseDetailsForm"
                :editable.sync="row.item.editDispenseDetailsForm"
                :propDispense="row.item.DispenseData"
                @refreshList="getPrescriptionListByCurrentPage"
                :prescription="row.item.PrescriptionData"
                :prescriptionId="row.item.PrescriptionId"
                :patientId="
                  participant
                    ? participant.UserId
                    : row.item.PrescriptionData.Patient.PatientId
                "
                :canDelete="!row.item.FinanceData"
                :projectPatient="projectPatient"
                :projectRoles="projectRoles"
              />
              <fp-form-modal
                title="Kiadás ellenőrzés"
                v-model="row.item.showDispenseReviewModal"
              >
                <template #content>
                  <fp-input
                    label="Kiadás ellenőrzés leírása"
                    v-model="dispenseReviewDescription"
                  />
                  <b-button @click="dispenseReview(row.item.PrescriptionId)"
                    >Ellenőrzés</b-button
                  >
                </template>
              </fp-form-modal>
            </div>
          </div>
          <!-- Financial -->
          <div
            v-if="
              row.item.FinanceData ||
              (row.item.DispenseDetailsAdded &&
                hasNeakSubsidization(row.item.DispenseData.Products))
            "
          >
            <hr />
            Finanszírozási műveletek:
            <div>
              <b-icon-eye-fill
                v-if="row.item.FinanceData"
                v-b-tooltip.hover="'Finanszírozás megtekintse'"
                class="h4"
                @click="
                  $set(row.item, 'showFinanceDataForm', true);
                  $set(row.item, 'editFinanceDataForm', false);
                "
              />
              <b-icon-pencil-square
                v-if="!row.item.FinanceData"
                v-b-tooltip.hover="'Finanszírozás rögzítése'"
                class="h4"
                @click="$set(row.item, 'showCreateFinanceDataForm', true)"
              />
              <b-icon-pencil-fill
                v-if="row.item.FinanceData"
                v-b-tooltip.hover="'Finanszírozás szerkesztése'"
                class="h4"
                @click="
                  $set(row.item, 'showFinanceDataForm', true);
                  $set(row.item, 'editFinanceDataForm', true);
                "
              />
              <b-icon-trash-fill
                v-if="row.item.FinanceData"
                v-b-tooltip.hover="'Finanszírozás törlése'"
                class="h4"
                variant="danger"
                @click="deleteFinanceData(row.item.PrescriptionId)"
              />
            </div>
            <!-- Form modalok -->
            <div>
              <new-finance-data-form
                v-model="row.item.showCreateFinanceDataForm"
                :dispenseProducts="row.item.DispenseData.Products"
                :prescriptionProducts="row.item.PrescriptionData.Products"
                :prescriptionId="row.item.PrescriptionId"
                :prescribingDoctor="row.item.PrescriptionData.PrescribingDoctor"
                :dispensedPatient="row.item.DispenseData.Patient"
                @refreshList="getPrescriptionListByCurrentPage"
              />
              <finance-data-form
                v-model="row.item.showFinanceDataForm"
                :editable.sync="row.item.editFinanceDataForm"
                :financeData="row.item.FinanceData"
                :dispenseProducts="row.item.DispenseData.Products"
                :prescriptionProducts="row.item.PrescriptionData.Products"
                :prescribingDoctor="row.item.PrescriptionData.PrescribingDoctor"
                :dispensedPatient="row.item.DispenseData.Patient"
                :prescriptionId="row.item.PrescriptionId"
                @refreshList="getPrescriptionListByCurrentPage"
              />
            </div>
          </div>
        </div>
      </template>
    </fp-table-list>
  </div>
</template>
<script>
//Components
import NewPrescriptionForm from "../Forms/NewPrescriptionForm";
import NewDispenseForm from "../Forms/NewDispenseForm.vue";
import PrescriptionForm from "../Forms/PrescriptionForm.vue";
import EesztOptions from "../Eeszt/EesztOptions.vue";
import DispenseForm from "../Forms/DispenseForm.vue";
import DispenseDetailsForm from "../Forms/DispenseDetailsForm.vue";
import NewDispenseDetailsForm from "../Forms/NewDispenseDetailsForm.vue";
import NewFinanceDataForm from "../Forms/NewFinanceDataForm.vue";
import FinanceDataForm from "../Forms/FinanceDataForm.vue";

//Methods
import { MedicalDeviceLogic } from "../../../Logic/Backend/medical-device";

import moment from "moment";

export default {
  name: "PrescriptionList",
  components: {
    NewPrescriptionForm,
    PrescriptionForm,
    EesztOptions,
    NewDispenseForm,
    DispenseForm,
    DispenseDetailsForm,
    NewDispenseDetailsForm,
    NewFinanceDataForm,
    FinanceDataForm,
  },
  props: {
    showPatientData: Boolean,
    participant: Object,
    /* prescriptionList: Array, */
    /* prescriptionListFields: Array, */
    projectPatient: Array,
    projectRoles: Array,
  },
  data() {
    return {
      prescriptionsFields: [
        {
          key: "PrescriptionId",
          label: "Recept alapadatok",
          sortable: true,
        },
        {
          key: "PrescriptionProducts",
          label: "Termékek",
        },
        {
          key: "PrescriptionData.PrescriptionTimestamp",
          label: "Recept adatok",
          sortable: true,
        },
        {
          key: "DispenseData.DispenseTimestamp",
          label: "Recept kiadás adatok",
          sortable: true,
        },
        { key: "Operations", label: "Műveletek" },
      ],
      //recept lista
      recipeList: null,
      filteredRecipeList: null,
      // TODO: szűrés minden mezőre + termékek név, ttt, iso
      //recept szűrők
      eesztStatusList: [
        { Value: null, Text: "Minden kategória" },
        //TODO: backend-nél nem tudok szűrni a nullra
        //{ Value: false, Text: "EESZT-be nem feltöltött" },
        ...Object.values(this.$enums.PrescriptionEesztStatus),
      ],
      eesztStatusFilter: null,
      localStatusList: [
        { Value: null, Text: "Minden kategória" },
        ...Object.values(this.$enums.PrescriptionLocalStatus),
      ],
      localStatusFilter: null,
      eesztUpdateNeededList: [
        { Value: null, Text: "Minden kategória" },
        { Value: true, Text: "Frissítés szükséges" },
        { Value: false, Text: "EESZT-vel szinkronban" },
      ],
      eesztUpdateNeededFilter: null,
      closedFilter: false,
      filter: null,
      filterPrescriptionTimestamp: null,
      filterDispenseTimestamp: null,
      //formok megnyitási változók
      showNewPrescriptionForm: false,
      //Művelet szerinti kijelölt recept id-k
      seletedAddablePrescriptionIds: [],
      seletedDeletablePrescriptionIds: [],
      seletedAddableDispenseIds: [],
      seletedDetailsAddableDispenseIds: [],
      seletedUpdatableDispenseIds: [],
      seletedDeletableDispenseIds: [],
      selectedReviewableDispenseIds: [],
      dispenseReviewDescription: null,
      editedPrescriptionRemark: null,
      prescriptionIdToDispenseAddToEeszt: null,
      recipeListSize: null,
      recipePerPage: 10,
      currentPage: 1,
      filterType: "PrescriptionId",
      sortDesc: true,
      sortBy: "PrescriptionId",
    };
  },
  watch: {
    /* prescriptionList(input) {
      this.recipeList = this.filteredRecipeList = input;
      this.filterList();
    }, */
    async participant() {
      await this.getPrescriptionListByCurrentPage();
    },
    async currentPage() {
      if (this.recipeListSize && this.currentPage) {
        this.$router.replace({
          name: this.$route.name,
          query: { PerPage: this.recipePerPage, Page: this.currentPage },
        });
        await this.getPrescriptionListByCurrentPage();
      }
    },
    async recipePerPage() {
      if (this.recipeListSize && this.currentPage) {
        this.$router.replace({
          name: this.$route.name,
          query: { PerPage: this.recipePerPage, Page: this.currentPage },
        });
        await this.getPrescriptionListByCurrentPage();
      }
    },
    recipeListSize(input) {
      if (input > this.recipePerPage) {
        this.currentPage = 1;
      }
    },
    async sortDesc() {
      await this.getPrescriptionListByCurrentPage();
    },
    async sortBy() {
      await this.getPrescriptionListByCurrentPage();
    },
  },
  computed: {
    validateFilterByType() {
      if (this.filterType == "PrescriptionId") {
        return (
          (this.filter &&
            this.filter.length < 10 &&
            Number.isInteger(Number.parseInt(this.filter))) ||
          !this.filter
        );
      } else {
        return true;
      }
    },
  },
  methods: {
    getPatientAge(prescriptionTimestamp, patientBirthDate) {
      if (prescriptionTimestamp && patientBirthDate) {
        return moment(prescriptionTimestamp).diff(patientBirthDate, "years");
      } else {
        return "";
      }
    },
    hasNeakSubsidization(dispenseProducts) {
      const hasSubCat = dispenseProducts.find((e) => e.SubsidizationCategory);
      return hasSubCat ? true : false;
    },
    startEditPrescriptionRemark(row) {
      this.editedPrescriptionRemark = row.item.Remark;
      this.$set(row.item, "_showRemarkEditor", true);
    },
    async editPrescriptionRemark(row) {
      const editRemarkResponse = await MedicalDeviceLogic.editPrescriptionRemark(
        row.item.PrescriptionId,
        {
          String: this.editedPrescriptionRemark,
        }
      );
      if (!editRemarkResponse.Code) {
        //todo: sikeres
        this.editedPrescriptionRemark = null;
        this.$set(row.item, "_showRemarkEditor", false);
        this.getPrescriptionListByCurrentPage();
      } else {
        //TODO: hiba
      }
    },
    async closePrescription(prescriptionId) {
      const closeResponse = await MedicalDeviceLogic.closePrescription(
        prescriptionId
      );
      if (!closeResponse.Code) {
        this.getPrescriptionListByCurrentPage();
      } else {
        //TODO: hiba
      }
    },
    async openPrescription(prescriptionId) {
      const openResponse = await MedicalDeviceLogic.openPrescription(
        prescriptionId
      );
      if (!openResponse.Code) {
        this.getPrescriptionListByCurrentPage();
      } else {
        //TODO: hiba
      }
    },
    getPatientData(patientId, key) {
      if (this.projectPatient) {
        const patient = this.projectPatient.find((p) => p.UserId == patientId);
        if (patient && key) {
          return patient[key];
        }
      }
      return null;
    },
    async filterList() {
      await this.getPrescriptionListByCurrentPage();
    },
    selectPrescription(selectedElements) {
      this.$emit("selectRow", selectedElements);
      //recept feltöltés
      this.seletedAddablePrescriptionIds = selectedElements
        .filter((x) => x.PrescriptionData && !x.PrescriptionData.EesztId)
        .map((x) => x.PrescriptionId);
      //recept törlés
      this.seletedDeletablePrescriptionIds = selectedElements
        .filter(
          (x) =>
            x.PrescriptionData &&
            x.PrescriptionData.EesztId &&
            x.EesztStatus == "Open"
        )
        .map((x) => x.PrescriptionId);
      //kiadás feltöltés
      this.seletedAddableDispenseIds = selectedElements
        .filter(
          (x) =>
            x.DispenseData &&
            x.PrescriptionData.EesztId &&
            !x.DispenseData.EesztId
        )
        .map((x) => x.PrescriptionId);
      //kiadás részleteinek feltöltése
      this.seletedDetailsAddableDispenseIds = selectedElements
        .filter(
          (x) =>
            x.DispenseData &&
            x.DispenseData.EesztId &&
            x.DispenseDetailsAdded &&
            !x.DispenseDetailsAddedToEeszt
        )
        .map((x) => x.PrescriptionId);
      //kiadás frissítése
      this.seletedUpdatableDispenseIds = selectedElements
        .filter(
          (x) =>
            x.DispenseData &&
            x.DispenseData.EesztId &&
            x.DispenseDataModifiedFromEeszt &&
            x.DispenseDetailsAddedToEeszt
        )
        .map((x) => x.PrescriptionId);
      //kiadás törlése
      this.seletedDeletableDispenseIds = selectedElements
        .filter(
          (x) =>
            x.DispenseData &&
            x.DispenseData.EesztId &&
            x.EesztStatus == "Dispensed"
        )
        .map((x) => x.PrescriptionId);
      //kiadás ellenőrzése
      this.selectedReviewableDispenseIds = selectedElements
        .filter(
          (x) =>
            x.DispenseData &&
            x.DispenseData.EesztId &&
            !x.DispenseReviewAddedToEeszt &&
            x.DispenseReviewAdded
        )
        .map((x) => x.PrescriptionId);
    },
    async getPrescriptionListByCurrentPage() {
      const listCountParams = {
        [this.filterType]:
          this.filter != "" && this.validateFilterByType ? this.filter : null,
        ExtendedEesztStatus: this.eesztStatusFilter
          ? this.eesztStatusFilter
          : null,
        LocalStatus: this.localStatusFilter ? this.localStatusFilter : null,
        EesztUpdateNeeded: this.eesztUpdateNeededFilter,
        Closed: this.closedFilter,
        "DispenseData.DispenseTimestamp-from":
          this.filterDispenseTimestamp && this.filterDispenseTimestamp.from
            ? this.filterDispenseTimestamp.from
            : null,
        "DispenseData.DispenseTimestamp-to":
          this.filterDispenseTimestamp && this.filterDispenseTimestamp.to
            ? this.filterDispenseTimestamp.to
            : null,
        "PrescriptionData.PrescriptionTimestamp-from":
          this.filterPrescriptionTimestamp &&
          this.filterPrescriptionTimestamp.from
            ? this.filterPrescriptionTimestamp.from
            : null,
        "PrescriptionData.PrescriptionTimestamp-to":
          this.filterPrescriptionTimestamp &&
          this.filterPrescriptionTimestamp.to
            ? this.filterPrescriptionTimestamp.to
            : null,
        "PrescriptionData.Patient.PatientId": this.participant
          ? this.participant.UserId
          : null,
        ["-orderby" + (this.sortDesc ? "desc" : "")]: this.sortBy,
      };

      this.recipeListSize = await this.getPrescriptionCount(listCountParams);

      const listParams = {
        ...listCountParams,
        "-offset":
          this.currentPage &&
          this.recipeListSize &&
          this.recipeListSize > this.recipePerPage
            ? (this.currentPage - 1) * this.recipePerPage
            : null,
        "-limit": this.recipePerPage,
      };

      if (this.recipeListSize <= this.recipePerPage) {
        if (Object.keys(this.$route.query).length) {
          this.$router.replace({
            name: this.$route.name,
          });
        }
        this.currentPage = 1;
      }

      this.recipeList = await this.getPrescription(listParams);
    },
    async getPrescription(params) {
      const getRecipesResponse = await MedicalDeviceLogic.getPrescription(
        params
      );
      if (!getRecipesResponse.Code) {
        return getRecipesResponse;
      } else {
        //TODO: hiba
      }
    },
    async getPrescriptionCount(params) {
      const getCountResponse = await MedicalDeviceLogic.getPrescriptionCount(
        params
      );
      if (!getCountResponse.Code) {
        return getCountResponse.ElementCount;
      } else {
        //TODO: hiba
      }
    },
    /* async getNextRecipeStack() {
      const lastPrescriptionId =
        this.recipeList[this.recipeList.length - 1].PrescriptionId;
      const nextStack = await this.getPrescription({
        "PrescriptionId-to": lastPrescriptionId - 1,
      });
      const newList = this.recipeList.concat(nextStack);
      this.recipeList = newList;
    }, */
    async saveRecipe(newRecipe) {
      const saveRecipeResponse = await MedicalDeviceLogic.setPrescription(
        newRecipe
      );
      if (!saveRecipeResponse.Code) {
        this.$bvToast.toast("Sikeresen mentette a receptet!", {
          variant: "success",
          title: "Sikeres mentés!",
          solid: true,
        });
        this.getPrescriptionListByCurrentPage();
      } else {
        this.$bvToast.toast(saveRecipeResponse.Message, {
          variant: "danger",
          title: "Hiba történt a recept mentése során!",
          solid: true,
          noAutoHide: true,
        });
      }
    },
    async deletePrescription(prescriptionId) {
      if (confirm("Biztosan törölni szeretné a rendszerből a receptet?")) {
        const deleteResponse = await MedicalDeviceLogic.deletePrescription(
          prescriptionId
        );
        if (!deleteResponse.Code) {
          this.$bvToast.toast("Sikeresen törölte a receptet!", {
            variant: "info",
            title: "Sikeres törlés!",
            solid: true,
          });
          this.getPrescriptionListByCurrentPage();
        } else {
          this.$bvToast.toast(deleteResponse.Message, {
            variant: "danger",
            title: "Hiba történt a recept törlése során!",
            solid: true,
            noAutoHide: true,
          });
        }
      }
    },
    async deleteDispense(prescriptionId) {
      if (confirm("Biztosan törölni szeretné a rendszerből a kiadást?")) {
        const deleteResponse = await MedicalDeviceLogic.deleteDispense(
          prescriptionId
        );
        if (!deleteResponse.Code) {
          this.$bvToast.toast("Sikeresen törölte a kiadást!", {
            variant: "info",
            title: "Sikeres törlés!",
            solid: true,
          });
          this.getPrescriptionListByCurrentPage();
        } else {
          this.$bvToast.toast(deleteResponse.Message, {
            variant: "danger",
            title: "Hiba történt a kiadás törlése során!",
            solid: true,
            noAutoHide: true,
          });
        }
      }
    },
    async deleteFinanceData(prescriptionId) {
      if (
        confirm(
          "Biztosan törli a " +
            prescriptionId +
            " azonosatójú recepthez tartozó fizetési adatokat?"
        )
      ) {
        const deleteResponse = await MedicalDeviceLogic.deleteFinanceData(
          prescriptionId
        );
        if (!deleteResponse.Code || deleteResponse.Code == 0) {
          //TODO: sikeres
          await this.filterList();
        } else {
          //TODO: hiba
        }
      }
    },
    async saveDispense(prescriptionId, newDispense) {
      const saveDispenseResponse = await MedicalDeviceLogic.createDispense(
        prescriptionId,
        newDispense
      );
      if (!saveDispenseResponse.Code) {
        this.$bvToast.toast("Sikeresen mentette a kiadást!", {
          variant: "info",
          title: "Sikeres mentés!",
          solid: true,
        });
        //Ez félre értett megoldás a fogalás sajátrendszerbe val feltöltése és az eesztbe való feltöltésének automatizálására
        //this.prescriptionIdToDispenseAddToEeszt = prescriptionId;
        this.getPrescriptionListByCurrentPage();
      } else {
        this.$bvToast.toast(saveDispenseResponse.Message, {
          variant: "danger",
          title: "Hiba történt a kiadás mentése során!",
          solid: true,
          noAutoHide: true,
        });
      }
    },
    async editDispense(prescriptionId, editedDispense) {
      const saveDispenseResponse = await MedicalDeviceLogic.updateDispense(
        prescriptionId,
        editedDispense
      );
      if (!saveDispenseResponse.Code) {
        this.$bvToast.toast("Sikeresen módosította a kiadást!", {
          variant: "info",
          title: "Sikeres módosítás!",
          solid: true,
        });
        this.getPrescriptionListByCurrentPage();
      } else {
        this.$bvToast.toast(saveDispenseResponse.Message, {
          variant: "danger",
          title: "Hiba történt a kiadás módosítása során!",
          solid: true,
          noAutoHide: true,
        });
      }
    },
    async saveDispenseDetails(prescriptionId, newDispenseDetails) {
      const saveDispenseDetailsResponse = await MedicalDeviceLogic.setDispenseDetails(
        prescriptionId,
        newDispenseDetails
      );
      if (!saveDispenseDetailsResponse.Code) {
        this.$bvToast.toast("Sikeresen mentette a kiadás részleteit!", {
          variant: "info",
          title: "Sikeres mentés!",
          solid: true,
        });
        this.getPrescriptionListByCurrentPage();
      } else {
        this.$bvToast.toast(saveDispenseDetailsResponse.Message, {
          variant: "danger",
          title: "Hiba történt a kiadás részleteinak mentése során!",
          solid: true,
          noAutoHide: true,
        });
      }
    },
    async downloadPdf(prescriptionId) {
      const downloadPdfResponse = await MedicalDeviceLogic.downloadPdf(
        prescriptionId
      );
      if (!downloadPdfResponse.Code) {
        const blob = await downloadPdfResponse.blob();
        var file = new File(
          [blob],
          "KiadasiIgazolas_" + moment().format("YYYYMMDD")
        );
        var fileURL = URL.createObjectURL(file);
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.download =
          "KiadasiIgazolas_" + moment().format("YYYYMMDD") + ".pdf";
        fileLink.target = "_blank";
        fileLink.click();
      } else {
        this.$bvToast.toast(downloadPdfResponse.Message, {
          variant: "danger",
          title: "Hiba történt a kiadási igazolás letöltése során!",
          solid: true,
          noAutoHide: true,
        });
      }
    },
    async dispenseReview(prescriptionId) {
      const body = {
        /* DispenserId: this.$loggedUser.UserId,
        DispenserOrganizationNickname: this.$loggedUser.SelectedOrganization, */
        ReviewDescription: this.dispenseReviewDescription,
      };
      const saveDispenseReviewResponse = await MedicalDeviceLogic.reviewDispense(
        prescriptionId,
        body
      );
      if (!saveDispenseReviewResponse.Code) {
        this.$bvToast.toast("Sikeresen ellenőrizte a kiadást!", {
          variant: "info",
          title: "Sikeres ellenőrzés!",
          solid: true,
        });
        this.getPrescriptionListByCurrentPage();
      } else {
        this.$bvToast.toast(saveDispenseReviewResponse.Message, {
          variant: "danger",
          title: "Hiba történt a kiadási ellenőrzése során!",
          solid: true,
          noAutoHide: true,
        });
      }
    },
  },
  async mounted() {
    this.recipePerPage = this.$route.query.PerPage
      ? Number.parseInt(this.$route.query.PerPage)
      : 10;
    this.currentPage = this.$route.query.Page
      ? Number.parseInt(this.$route.query.Page)
      : 1;

    if (this.showPatientData && !this.participant) {
      await this.getPrescriptionListByCurrentPage();
    }
  },
};
</script>
