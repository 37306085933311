var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.siteTitle)?_c('div',{key:'components-header-footer-webappspecialistheader-' + _vm.forceUpdateKey,staticClass:"eh-header"},[_c('Header',{attrs:{"signedName":_vm.signedName,"siteTitle":_vm.siteTitle,"siteColor":_vm.siteColor,"signedProfilePicture":_vm.UserPicture},on:{"forceUpdate":_vm.forceUpdate}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"align-h":"around","align-v":"center"}},[_c('div',[_c('router-link',{attrs:{"to":"Home"}},[_c('button',{staticClass:"eh-header-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.home"))+" ")])])],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('router-link',{attrs:{"to":"PatientList"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.patientList"))+" ")])]),(
            _vm.$loggedUser.SelectedProject &&
              _vm.$loggedUser.SelectedProject.ProjectId
          )?_c('router-link',{attrs:{"to":"Project"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.project"))+" ")])]):_vm._e(),(
            _vm.visibleUiElement(_vm.$enums.UserInterfaceElement.Questionnaire.Value)
          )?_c('router-link',{attrs:{"to":"Questionnaires"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.questionnaires"))+" ")])]):_vm._e(),(_vm.visibleUiElement(_vm.$enums.UserInterfaceElement.Document.Value))?_c('router-link',{attrs:{"to":"Documents"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.documents"))+" ")])]):_vm._e(),(_vm.visibleUiElement(_vm.$enums.UserInterfaceElement.Workflow.Value))?_c('router-link',{attrs:{"to":"Workflow"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.workflow"))+" ")])]):_vm._e(),(_vm.visibleUiElement(_vm.$enums.UserInterfaceElement.UserTask.Value))?_c('router-link',{attrs:{"to":"Tasks"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.tasks"))+" ")])]):_vm._e(),(
            _vm.visibleUiElement(_vm.$enums.UserInterfaceElement.Appointment.Value)
          )?_c('router-link',{attrs:{"to":"Appointments"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.appointments"))+" ")])]):_vm._e(),(
            _vm.visibleUiElement(
              _vm.$enums.UserInterfaceElement.NotificationTemplate.Value
            )
          )?_c('router-link',{attrs:{"to":"Templates"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.templates"))+" ")])]):_vm._e()],1),_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('button',{staticClass:"eh-header-button",on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("base.basic.exit"))+" ")])])],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }