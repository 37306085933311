<!-- Version 1.0.1 -->
<template>
  <div v-if="emailTemplate">
    <fp-panel-card
      :closedCard="true"
      :closedPanelRender="false"
      style="margin: 10px 0px"
      :title="$t('components.templates.email')"
    >
      <!-- Sablon típusa -->
      <template #title>
        <b-icon style="margin: 0px 5px" icon="envelope-fill"></b-icon>
        {{ $t("components.templates.email") }}
      </template>
      <!-- Sablon adatok -->
      <template #content>
        <!-- Tárgy -->
        <fp-input
          :label="$t('components.templates.emailSubject')"
          :disabled="!editable"
          v-model="emailTemplate.Subject[lang]"
          @change="changeValue('EmailTemplate.Subject', emailTemplate.Subject)"
        />
        <!-- Email szöveg -->
        <ejs-richtexteditor
          ref="richTextEditor"
          v-model="emailTemplate.HtmlContent[lang]"
          @change="changedEmailContent"
          :toolbarSettings="editable ? toolbarSettings : disabledToolbarSettings"
          :insertImageSettings="insertImageSettings"
          :pasteCleanupSettings="pasteCleanupSettings"
          :readonly="!editable"
          :width="'100%'"
          :height="500"
        >
        </ejs-richtexteditor>
        <button
          v-if="editable"
          @click="removeEmailTemplate"
          class="eh-action-button"
        >
          {{ $t("components.templates.removeEmail") }}
        </button>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
import Vue from "vue";
import {
  RichTextEditorPlugin,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  PasteCleanup,
  Table,
} from "@syncfusion/ej2-vue-richtexteditor";

Vue.use(RichTextEditorPlugin);

export default {
  name: "EmailTemplate",
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      Image,
      Count,
      HtmlEditor,
      QuickToolbar,
      PasteCleanup,
      Table,
    ],
  },
  model: {
    prop: "emailTemplateProp",
    event: "change",
  },
  props: {
    emailTemplateProp: Object,
    editable: Boolean,
    lang: String,
    supportedLangs: Array,
  },
  data() {
    return {
      emailTemplate: null,
      changedValues: null,
      defaultEmailTemplate: {
        Subject: null,
        TextContent: null,
        HtmlContent: null,
      },
      //RichTextEditor settings
      toolbarSettings: {
        type: "MultiRow",
        enableFloating: false,
        enable: true, 
        items: [
          "Bold",
          "Italic",
          "Underline",
          "StrikeThrough",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "LowerCase",
          "UpperCase",
          "SuperScript",
          "SubScript",
          "|",
          "Formats",
          "Alignments",
          "OrderedList",
          "UnorderedList",
          "Outdent",
          "Indent",
          "|",
          "CreateTable",
          "CreateLink",
          "Image",
          "|",
          "ClearFormat",
          "Print",
          "SourceCode",
          "FullScreen",
          "|",
          "Undo",
          "Redo",
        ],
      },
      disabledToolbarSettings: {
        enableFloating: false,
        enable: false, 
      },
      insertImageSettings: {
        saveFormat: "Base64",
      },
      pasteCleanupSettings: {
        prompt: true,
        plainText: false,
        keepFormat: false,
      },
    };
  },
  watch: {
    supportedLangs() {
      const setEmail = this.setLanguagedEmailTemplate(this.emailTemplate);
      this.emailTemplate = JSON.parse(JSON.stringify(setEmail));
      //this.$emit("change", this.emailTemplate);
    },
    emailTemplateProp(input) {
      const setEmail = this.setLanguagedEmailTemplate(input);
      this.emailTemplate = JSON.parse(JSON.stringify(setEmail));
      this.changedValues = null;
    },
  },
  methods: {
    changeValue(path, value) {
      if (!this.changedValues) {
        this.changedValues = {};
      }
      this.$set(this.changedValues, path, value);
      this.$emit("change", this.emailTemplate);
      this.$emit("changedValues", this.changedValues);
    },
    //richtext editor toolbar kattintás readonly-ban hiba üzenet dobás
    toolbarClick(e) {
      if (!this.editable && e) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.errorRichTextToolbar"),
          {
            title: this.$t("requestResponse.basic.errorRichTextToolbarTitle"),
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    changedEmailContent() {
      this.emailTemplate.TextContent[this.lang] =
        this.$refs.richTextEditor.ej2Instances.getText();

      this.changeValue(
        "EmailTemplate.HtmlContent",
        this.emailTemplate.HtmlContent
      );
      this.changeValue(
        "EmailTemplate.TextContent",
        this.emailTemplate.TextContent
      );
    },
    removeEmailTemplate() {
      this.$emit("change", null);
      this.$emit("changedValues", { EmailTemplate: null });
    },
    //TODO: nyelv törlésre nem reagál jól
    setLanguagedEmailTemplate(emailTemplate) {
      const email = JSON.parse(JSON.stringify(this.defaultEmailTemplate));
      this.supportedLangs.forEach((sLang) => {
        if (!email.Subject) {
          email.Subject = {};
        }
        if (!email.TextContent) {
          email.TextContent = {};
        }
        if (!email.HtmlContent) {
          email.HtmlContent = {};
        }

        if (emailTemplate) {
          if (emailTemplate.Subject && emailTemplate.Subject[sLang]) {
            email.Subject[sLang] = emailTemplate.Subject[sLang];
          } else {
            email.Subject[sLang] = null;
          }
          if (emailTemplate.TextContent && emailTemplate.TextContent[sLang]) {
            email.TextContent[sLang] = emailTemplate.TextContent[sLang];
          } else {
            email.TextContent[sLang] = null;
          }
          if (emailTemplate.HtmlContent && emailTemplate.HtmlContent[sLang]) {
            email.HtmlContent[sLang] = emailTemplate.HtmlContent[sLang];
          } else {
            email.HtmlContent[sLang] = null;
          }
        } else {
          email.Subject[sLang] = null;
          email.TextContent[sLang] = null;
          email.HtmlContent[sLang] = null;
        }
      });
      return email;
    },
  },
  mounted() {
    this.emailTemplate = this.setLanguagedEmailTemplate(this.emailTemplateProp);
  },
};
</script>
