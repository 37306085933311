<template>
  <div v-if="project">
    <templates-manager :projectId="project.ProjectId" />
  </div>
</template>
<script>
import TemplatesManager from "@/components/table/TemplatesManager.vue";
export default {
  name: "SpecialistTemplates",
  components: { TemplatesManager },
  props: {
    project: Object,
  },
  mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t("healthProfessional.menu.templates"));
  },
};
</script>
