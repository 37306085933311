<template>
  <div>
    <list-box
      :items="items"
      :hasListHeaderButton="true"
      :listHeaderButtonTitle="$t('components.templates.newTemplate')"
      :emptyListText="$t('components.templates.noTemplate')"
      @headerButtonClick="$emit('createTemplate')"
      @selectingItem="$emit('selectingTemplate', $event)"
      :removeSelection.sync="isNewTemplate"
      :selectedItem="selectedTemplate"
    >
      <template #default="template">
        <div>
          {{ template.Name }}
          <!-- Sablon lehetséges ikonjai -->
          <div>
            <!-- Email ikon -->
            <b-icon
              v-if="template.EmailTemplate"
              style="margin: 5px"
              icon="envelope-fill"
            ></b-icon>
            <!-- Push ikon -->
            <b-icon
              v-if="template.PushTemplate"
              style="margin: 5px"
              icon="phone-vibrate"
            ></b-icon>
            <!-- SMS ikon -->
            <b-icon
              v-if="template.SmsTemplate"
              style="margin: 5px"
              icon="chat-square-text-fill"
            ></b-icon>
          </div>
          <!-- TODO: nyelvek megjelenítése? -->
        </div>
      </template>
    </list-box>
  </div>
</template>
<script>
import ListBox from "../../components/custom-components/ListBox.vue";
export default {
  components: { ListBox },
  name: "TempalteList",
  props: {
    items: Array,
    isNewTemplate: Boolean,
    selectedTemplate: Object,
  },
  watch: {
    isNewTemplate(input) {
      this.$emit("isNewTemplate:update", input);
    },
  },
};
</script>
