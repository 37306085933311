<template>
  <div class="page-background">
    <div>
      <div style="text-align: center" class="diary-title">
        {{ $t("module.diaries.pulse.post") }}
      </div>
      <div style="margin: 20px 0px">
        <button class="eh-action-button" @click="goBack">
          {{ $t("base.basic.back") }}
        </button>
        <button class="eh-action-button" @click="showPulseDiaryInputModal = true">
          {{ $t("module.diaries.newPost") }}
        </button>
      </div>

      <client-module-diary baseName="pulse" v-model="refreshList" />
      <PulseDiaryInput
        @refreshList="refreshList = true"
        v-model="showPulseDiaryInputModal"
      />
    </div>
  </div>
</template>

<script>
import PulseDiaryInput from "@/views/client/input/PulseDiaryInput.vue";
import ClientModuleDiary from "@/components/module/ClientModuleDiary.vue";

export default {
  name: "PulseDiary",
  components: { PulseDiaryInput, ClientModuleDiary },
  data() {
    return {
      refreshList: false,
      showPulseDiaryInputModal: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t("module.diaries.pulse.title"));
  },
};
</script>
<style>
.diary-title {
  font-size: 1.5em;
}
.e-grid .e-headercell.customcss {
  font-size: 20px;
  word-wrap: normal;
}
</style>
