<template>
  <div v-if="smsTemplate">
    <fp-panel-card
      :closedCard="true"
      style="margin: 10px 0px"
      :title="$t('components.templates.sms')"
    >
      <!-- Sablon típusa -->
      <template #title>
        <b-icon style="margin: 0px 5px" icon="chat-square-text-fill"></b-icon>
        {{ $t("components.templates.sms") }}
      </template>
      <!-- Sablon adatok -->
      <template #content>
        <!-- SMS üzenet -->
        <fp-text-area
          :label="$t('components.templates.smsMessage')"
          :disabled="!editable"
          v-model="smsTemplate.Text[lang]"
          @change="changeValue('SmsTemplate.Text', smsTemplate.Text)"
        />
        <button
          v-if="editable"
          @click="removeSmsTemplate"
          class="eh-action-button"
        >
          {{ $t("components.templates.removeSMS") }}
        </button>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
export default {
  name: "SmsTemplate",
  model: {
    prop: "smsTemplateProp",
    event: "change",
  },
  props: {
    smsTemplateProp: Object,
    editable: Boolean,
    lang: String,
    supportedLangs: Array,
  },
  data() {
    return {
      smsTemplate: null,
      changedValues: null,
      defaultSmsTemplate: {
        Text: null,
      },
    };
  },
  watch: {
    supportedLangs() {
      this.smsTemplate = this.setLanguagedSmsTemplate(this.smsTemplate);
      //this.$emit("change", this.smsTemplate);
    },
    smsTemplateProp(input) {
      this.smsTemplate = this.setLanguagedSmsTemplate(input);
    },
  },
  methods: {
    changeValue(path, value) {
      if (!this.changedValues) {
        this.changedValues = {};
      }
      this.$set(this.changedValues, path, value);
      this.$emit("change", this.smsTemplate);
      this.$emit("changedValues", this.changedValues);
    },
    removeSmsTemplate() {
      this.$emit("change", null);
      this.$emit("changedValues", { SmsTemplate: null });
    },
    setLanguagedSmsTemplate(smsTemplate) {
      const sms = JSON.parse(JSON.stringify(this.defaultSmsTemplate));
      this.supportedLangs.forEach((sLang) => {
        if (!sms.Text) {
          sms.Text = {};
        }

        if (smsTemplate) {
          if (smsTemplate.Text && smsTemplate.Text[sLang]) {
            sms.Text[sLang] = smsTemplate.Text[sLang];
          } else {
            sms.Text[sLang] = null;
          }
        } else {
          sms.Text[sLang] = null;
        }
      });
      return sms;
    },
  },
  mounted() {
    this.smsTemplate = this.setLanguagedSmsTemplate(this.smsTemplateProp);
  },
};
</script>
