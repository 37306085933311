<template>
  <div v-if="!params.Hidden">
    <span>{{ params.Title ? params.Title[language] : null }}</span>
    <span v-if="params.Required" class="text-danger">*</span>
    <div>
      <span class="input-table-description">{{
        params.Description ? params.Description[language] : null
      }}</span>
    </div>
    <div class="table-responsive">
      <table class="table-component" style="width: 100%">
        <tr
          v-for="rowIndex in params.RowNum"
          :key="'table-row-index-' + rowIndex"
          :class="params.RowHeader && rowIndex == 1 ? 'header' : ''"
        >
          <td
            v-for="columnIndex in params.ColumnNum"
            :key="
              'table-row-index-' + rowIndex + '-column-index-' + columnIndex
            "
            :class="params.ColumnHeader && columnIndex == 1 ? 'header' : ''"
            class="table-cell"
          >
            <div v-if="params.Cells[rowIndex - 1][columnIndex - 1]">
              {{ params.Cells[rowIndex - 1][columnIndex - 1][language] }}
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: "TableComponent",
  props: {
    params: Object,
    language: String,
  },
};
</script>
<style scoped>
.input-table-description {
  font-size: 14px;
  color: #00000099;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
}
.table-cell {
  border: solid 1px black;
  padding: 5px;
}

table {
  border: 3px solid #627e8f;
  padding: 0;
  border-collapse: separate;
  border-radius: 10px;
  border-spacing: 0;
}

td {
  background: white;
}

td:first-child.header {
  background: #cce1f0;
  border-left: none;
  font-weight: bold;
}
td:last-child {
  border-right: none;
}

tr:first-child.header td.table-cell {
  background: #cce1f0;
  border-top: none;
  font-weight: bold;
}

tr:last-child td.table-cell {
  border-bottom: none;
}

tr:first-child td:first-child {
  border-radius: 8px 0 0 0;
  border-left: none;
}
tr:first-child td:last-child {
  border-radius: 0 8px 0 0;
  border-right: none;
}
tr:last-child td:first-child {
  border-radius: 0 0 0 8px;
}
tr:last-child td:last-child {
  border-radius: 0 0 8px 0;
}
</style>