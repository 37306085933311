import { EhssClient, RequestMethod } from '@/backend/ehss-client'
import { RequestResponseLogic } from '@/logic/ui/request-response'

const INVITATION = '/invitation'
const ACCEPT = 'accept'
const REJECT = 'reject'

export const InvitationLogic = {
    async createInvitation(body, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.POST,
            INVITATION,
            params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getInvitations(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            INVITATION,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteInvitation(invitationId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.DELETE,
            INVITATION + '/' + invitationId,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async acceptInvitation(invitationId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.POST,
            INVITATION + '/' + invitationId + '/' + ACCEPT,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async rejectInvitation(invitationId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.POST,
            INVITATION + '/' + invitationId + '/' + REJECT,
            params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
}