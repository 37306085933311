<template>
  <div class="eh-client-programs">
    <fp-tabs :tabs="programTabs">
      <template #title="tab">
        {{ tab.Name }}
      </template>
      <template #content="tab">
        <component :is="tab.Component" />
      </template>
    </fp-tabs>
  </div>
</template>
<script>
import CarePrograms from "@/views/client/project-components/CarePrograms.vue";
import HealthPrograms from "@/views/client/project-components/HealthPrograms.vue";
export default {
  name: "Programs",
  data() {
    return {
      programTabs: [
        {
          Name: this.$t("client.programs.healthPrograms"),
          Component: HealthPrograms,
        },
        { Name: this.$t("client.programs.carePrograms"), Component: CarePrograms },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t('client.menu.programs'));
  },
};
</script>