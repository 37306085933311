<template>
  <div>
    <b-row id="header" align-h="around">
      <router-link to="/">
        <img
          @mousedown="(research = false), (applications = false)"
          class="header-img"
          src="@/assets/tmkk/header_elements/head_tmkk_logo.png"
        />
      </router-link>
      <!-- <div class="inactive-img">
        <img
          class="header-img"
          id="header-img-search"
          src="@/assets/tmkk/header_elements/head_search.png"
        />
        <img
          class="active-img header-img"
          src="@/assets/tmkk/header_elements/head_kereses_aktiv.png"
        />
      </div>
      <div class="inactive-img">
        <img
          class="header-img"
          src="@/assets/tmkk/header_elements/head_share.png"
        />
        <img
          class="active-img header-img"
          src="@/assets/tmkk/header_elements/head_share_aktiv.png"
        />
        <img
          class="active-img sub-active-img header-img"
          src="@/assets/tmkk/header_elements/head_share_aktiv2.png"
        />
      </div> -->
      <div>
        <router-link to="Login">
          <div class="inactive-img">
            <img
              class="header-img"
              src="@/assets/tmkk/header_elements/head_login.png"
            />
            <img
              class="active-img header-img"
              src="@/assets/tmkk/header_elements/head_login_aktiv.png"
            />
            <img
              class="active-img sub-active-img header-img"
              src="@/assets/tmkk/header_elements/head_login_aktiv2.png"
            />
          </div>
        </router-link>
        <!-- <div class="inactive-img">
        <img
          class="header-img"
          src="@/assets/tmkk/header_elements/head_hu.png"
        />
        <img
          class="active-img header-img"
          src="@/assets/tmkk/header_elements/head_hu_aktiv.png"
        />
        <img
          class="active-img sub-active-img header-img"
          src="@/assets/tmkk/header_elements/head_hu_aktiv2.png"
        />
      </div> -->
        <img
          class="header-img"
          src="@/assets/tmkk/header_elements/head_nkfia_logo.png"
        />
      </div>
    </b-row>
    <b-row style="margin-left: 0px" align-h="center" class="menubar">
      <router-link class="static-header-element" to="/DPC">
        Dél-Pesti Centrumkórház
      </router-link>
      <router-link class="static-header-element" to="/About">
        Bemutatkozás
      </router-link>

      <!-- <div class="inactive-img">
        <img src="@/assets/tmkk/menu_elements/menu_gomb_tmkk.png" />
        <router-link class="active-link" to="/TMKK">
          <img
            class="active-img"
            src="@/assets/tmkk/menu_elements/menu_gomb_tmkk_aktiv.png"
          />
        </router-link>
      </div> -->
      <b-nav-item-dropdown class="menu-with-submenu">
        <template v-slot:button-content>
          <div class="static-header-element">Pályázatok</div>
        </template>
        <!-- <b-dropdown-item to="/ApplicationsOpened">
            Nyitott pályázatok
          </b-dropdown-item> -->
        <b-dropdown-item to="/ApplicationsClosed">
          Lezárt pályázatok
        </b-dropdown-item>
        <b-dropdown-item to="/ApplicationsActive">
          Aktív pályázatok
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <b-nav-item-dropdown class="menu-with-submenu research-menu-img">
        <template v-slot:button-content>
          <div class="static-header-element">Kutatások</div>
        </template>
        <!-- <b-dropdown-item to="/ResearchKnowledge">
            Tudásbázis
          </b-dropdown-item>
          <b-dropdown-item to="/ResearchReports">
            Kutatási jelentések
          </b-dropdown-item> -->
        <b-dropdown-item to="/ResearchPartners">
          Kutatási partnereink
        </b-dropdown-item>
      </b-nav-item-dropdown>
      <router-link class="static-header-element" to="/News">
        Híreink
      </router-link>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      research: false,
      applications: false,
    };
  },
};
</script>
<style>
.menu-with-submenu .static-header-element {
  margin-bottom: 0px;
  padding-bottom: 10px;
  padding-top: 15px;
}

.static-header-element {
  display: flex;
  font-size: 18px;
  font-weight: 900;
  color: #4273b8;
  padding-top: 15px;
  border-bottom: solid 9px;
  border-color: #2bb685;
  margin-bottom: 8px;
  justify-content: center;
  padding: 15px 25px 0px 25px;
}

.static-header-element:hover,
.static-header-element.router-link-exact-active,
.menu-with-submenu .static-header-element:hover {
  background: #d3d3d356;
  border-color: #4273b8;
  text-decoration: none;
}

#header {
  width: 100%;
}
.header-img {
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
  height: 50px;
}
.menubar {
  border-top: solid;
  border-width: 1px;
  border-color: gainsboro;
  margin-top: 25px;
  background: url(../../assets/tmkk/menu_elements/menu.png);
  margin-left: 0px;
  width: 100%;
  height: auto;
}
.submenu-applications-elements {
  margin-left: 600px;
  margin-right: 285px;
}
.submenu-research-elements {
  margin-left: 885px;
}
.submenu-elements-box {
  width: 100%;
  align-content: center;
  position: absolute;
  margin-bottom: -186px;
  top: 1;
  z-index: 2;
}
.sub-active-img {
  margin-top: 75px;
  box-shadow: 2px 2px 5px grey;
}
.menu-with-submenu {
  margin-top: -8px;
  list-style-type: none;
}
.inactive-img {
  position: relative;
  display: inline-block;
}
.active-submenu-img {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}
.inactive-img:hover .active-submenu-img {
  cursor: pointer;
  display: inline;
  margin-top: 8px;
  margin-left: 0px;
}
.active-link.router-link-active .active-submenu-img,
.active-link.router-link-exact-active .active-submenu-img {
  display: inline;
}
li a.dropdown-item {
  padding: 23px 0px 23px 31px !important;
  background: white;
  opacity: 0.85;
  font-weight: 500;
  font-size: 19px;
  color: #4273b8;
}
li a.dropdown-item:hover {
  background: #4273b8;
  color: white;
  font-weight: bold;
  opacity: 1;
}
ul.dropdown-menu.show {
  margin-top: -16px;
  margin-left: 0px;
  width: 285px;
  background: none !important;
  border: none !important;
  border-radius: 0px !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.nav-link {
  padding: 8px 0px 8px 0px !important;
}
.dropdown-toggle:focus {
  outline: none !important;
}
/* @media (min-width: 855px) and (max-width: 1140px) {
  .research-menu-img {
    margin-top: -16px;
  }
}
@media (min-width: 300px) and (max-width: 570px) {
  .research-menu-img {
    margin-top: -16px;
  }
} */
</style>