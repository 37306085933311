<template>
  <div class="eh-client-appointments">
    <fp-tabs :tabs="appointmentTabs" :lazy="true">
      <template #title="tab">
        {{ tab.title }}
      </template>
      <template #content="tab">
        <component :is="tab.component" />
      </template>
    </fp-tabs>
  </div>
</template>
<script>
import AppliedAppointments from "../appointment-components/AppliedAppointments.vue";
import FreeAppointments from "../appointment-components/FreeAppointments.vue";

export default {
  components: { AppliedAppointments, FreeAppointments },
  name: "Appointments",
  data() {
    return {
      appointmentTabs: [
        {
          title: this.$t("client.appointment.own"),
          component: AppliedAppointments,
        },
        {
          title: this.$t("client.appointment.free"),
          component: FreeAppointments,
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t("client.menu.appointments"));
  },
};
</script>