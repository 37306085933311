<template>
  <div style="margin: 20px">
    <b-row>
      <fp-input
        class="eh-fix-filter-input-width"
        style="margin: 0px 5px"
        :label="$t('base.basic.filter')"
        @change="filterList"
        v-model="filter"
      />
      <fp-date-range-button
        :label="$t('healthProfessional.questionnaires.waitingTab.givenDate')"
        v-model="assignedDateFilter"
        @change="filterList"
      />
      <fp-date-range-button
        :label="$t('healthProfessional.questionnaires.waitingTab.filledDate')"
        v-model="filledDateFilter"
        @change="filterList"
      />
    </b-row>
    <fp-table-list
      class="questionnaires-to-patient-table"
      :bordered="true"
      :items="filteredFilledQuestionnaireListFields"
      :fields="filledQuestionnaireListFields"
    >
      <template #Title="row">
        {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
      </template>
      <template #ProgressPercentage="row">
        <b-progress :value="row.item.FillingRate" :max="100"></b-progress>
        <div style="text-align: center">
          {{ row.item.FillingRate ? row.item.FillingRate + "%" : "0%" }}
        </div>
      </template>
      <template #Evaluating="row">
        <router-link
          :to="{
            name: 'WebAppSpecialistEvaluatingQuestionnaire',
            query: { Id: row.item.FormInstanceId },
          }"
          ><b-button
            :disabled="!['Filled', 'UnderEvaluation'].includes(row.item.Status)"
            >{{
              $t("healthProfessional.questionnaires.waitingTab.evaulate")
            }}</b-button
          ></router-link
        >
      </template>
      <template #UserId="row">
        {{ row.item.Patient.Name }}
      </template>
      <template #StartDateTime="row">
        {{ $convertDateToString(row.item.AssignedDate, "YYYY-MM-DD HH:mm") }}
      </template>
      <template #StopDateTime="row">
        {{ $convertDateToString(row.item.FilledDate, "YYYY-MM-DD HH:mm") }}
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import moment from "moment";
import { ProjectLogic } from "@/logic/backend/project.js";
import { FormLogic } from "@/logic/backend/form";
export default {
  name: "WaitForEvaluate",
  data() {
    return {
      patientList: [],
      filledQuestionnaireList: [],
      filteredFilledQuestionnaireListFields: [],
      filledQuestionnaireListFields: [
        {
          key: "UserId",
          label: this.$t("partner.questionnaires.waitingTab.participant"),
        },
        {
          key: "Title",
          label: this.$t("partner.questionnaires.waitingTab.title"),
        },
        {
          key: "ProgressPercentage",
          label: this.$t("partner.questionnaires.waitingTab.progress"),
        },
        {
          key: "StartDateTime",
          label: this.$t("partner.questionnaires.waitingTab.givenDate"),
        },
        {
          key: "StopDateTime",
          label: this.$t("partner.questionnaires.waitingTab.filledDate"),
        },
        {
          key: "Deadline",
          label: this.$t("partner.questionnaires.waitingTab.deadline"),
        },
        { key: "Evaluating", label: "" },
      ],
      filter: null,
      assignedDateFilter: null,
      filledDateFilter: null,
    };
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    filterList() {
      this.filteredFilledQuestionnaireListFields = this.filledQuestionnaireList;
      if (this.filter) {
        this.filteredFilledQuestionnaireListFields = this.$filterList(
          this.filter,
          this.filledQuestionnaireList,
          ["Title", "Patient.Name", "Patient.Ssn", "Patient.PatientDateOfBirth"]
        );
      }
      if (this.assignedDateFilter) {
        this.filteredFilledQuestionnaireListFields = this.filteredFilledQuestionnaireListFields.filter(
          (task) => {
            if (this.assignedDateFilter.from && this.assignedDateFilter.to) {
              return moment(task.AssignedDate).isBetween(
                this.assignedDateFilter.from,
                this.assignedDateFilter.to,
                undefined,
                "[]"
              );
            } else if (this.assignedDateFilter.from) {
              return moment(task.AssignedDate).isSameOrAfter(
                moment(this.assignedDateFilter.from)
              );
            } else if (this.assignedDateFilter.to) {
              return moment(task.AssignedDate).isSameOrBefore(
                moment(this.assignedDateFilter.to)
              );
            }
            return true;
          }
        );
      }
      if (this.filledDateFilter) {
        this.filteredFilledQuestionnaireListFields = this.filteredFilledQuestionnaireListFields.filter(
          (task) => {
            if (this.filledDateFilter.from && this.filledDateFilter.to) {
              return moment(task.FilledDate).isBetween(
                this.filledDateFilter.from,
                this.filledDateFilter.to,
                undefined,
                "[]"
              );
            } else if (this.filledDateFilter.from) {
              return moment(task.FilledDate).isSameOrAfter(
                moment(this.filledDateFilter.from)
              );
            } else if (this.filledDateFilter.to) {
              return moment(task.FilledDate).isSameOrBefore(
                moment(this.filledDateFilter.to)
              );
            }
            return true;
          }
        );
      }
    },
    //beteg lista kérés
    async getPatientList() {
      //kérés indítás
      const patientListResult = await ProjectLogic.getProjectSubordinates(
        this.$loggedUser.SelectedProject.ProjectId
      );
      //sikeres kérés?
      if (!patientListResult.Code) {
        //beteg lista frissítés
        this.patientList = patientListResult;
      } else {
        this.$bvToast.toast(patientListResult.Message, {
          title: this.$t("requestResponse.project.errorGetProjectSubordinates"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //kiértékelésre váró kérdőívek listája
    async getAssignedFilledQuestionnaireList() {
      //beteg lista betöltés
      await this.getPatientList();
      //kérés indítás
      const questionnaireListResult = await FormLogic.getFormInstanceHeader({
        EvaluatorId: this.$loggedUser.UserId,
        FormCategory: "Questionnaire",
        EvaluationMethod: "Manual",
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      //sikers kérés?
      if (!questionnaireListResult.Code) {
        //kiértékelésre váró kérdőív lista frissítés
        questionnaireListResult.forEach((quest) => {
          const patient = this.patientList.find(
            (y) => y.UserId == quest.SubjectId
          );
          if (patient) {
            quest.Patient = {
              Name: patient.Name,
              Ssn: patient.Ssn,
              PatientDateOfBirth: patient.PatientDateOfBirth,
            };
          }
        });
        this.filledQuestionnaireList = this.filteredFilledQuestionnaireListFields = questionnaireListResult.filter(
          (form) =>
            form.Patient &&
            [
              this.$enums.QuestionnaireStatus.Filled.Value,
              this.$enums.QuestionnaireStatus.UnderEvaluation.Value,
            ].includes(form.Status)
        );
      } else {
        this.$bvToast.toast(questionnaireListResult.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
              "healthProfessional.questionnaires.waitingTab.errorWaitingName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kiértékelésre váró kérdőívek betöltése
    await this.getAssignedFilledQuestionnaireList();
  },
};
</script>
<style scoped>
@import "../../../styles/fp-table-style.css";
.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: -100px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
</style>
