<template>
  <div style="background:white">
    <Header />
    <router-view style="width: 95%; margin: auto" name="static" />
    <router-view name="customStatic" />
  </div>
</template>
<script>
import Header from "@/components/header-footer/StaticHeader.vue";
export default {
  components: {
    Header,
  },
};
</script>
