<template>
  <div>
    <fp-date-picker
      label="Jelentés kezdő dátuma"
      :required="true"
      v-model="startDate"
      :state="!validation.hasError('startDate')"
      :error="validation.firstError('startDate')"
    />
    <fp-date-picker
      label="Jelentés vége dátuma"
      :required="true"
      v-model="endDate"
      :state="!validation.hasError('endDate')"
      :error="validation.firstError('endDate')"
    />
    <fp-text-area label="Jelentés leírása" v-model="description" />
    <b-button @click="createReport">Mentés</b-button>
  </div>
</template>
<script>
import { MedicalDeviceLogic } from '../../../../Logic/Backend/medical-device';
export default {
  name: "CreateNeakReportForm",
  data() {
    return {
      description: null,
      endDate: null,
      startDate: null,
    };
  },
  validators: {
    endDate: function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    startDate: function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
  },
  methods: {
    async createReport() {
      const valid = await this.$validate();
      if (valid) {
        const presList = this.$store.state.bever.SelectedPrescriptions;
        if (presList && presList.length) {
          const body = {
            StartDate: this.startDate,
            EndDate: this.endDate,
            IncludedProducts: presList,
            Description: this.description,
          };
          const createResponse = await MedicalDeviceLogic.createReport(body);
          if (!createResponse.Code || createResponse.Code == 0) {
            this.$bvToast.toast("Sikeresen jelentés generálás!", {
              title: "Sikeres mentés!",
              variant: "success",
              solid: true,
            });
            this.$emit("savedReport");
          } else {
            this.$bvToast.toast(createResponse.Message, {
              title: "Hiba történt a jelentés generálás során!",
              variant: "danger",
              solid: true,
            });
          }
        }
      }
    },
  },
};
</script>
