<template>
  <div v-if="!params.Hidden">
    <span style="margin-left: 5px">{{
      params.Title ? params.Title[language] : null
    }}</span>
    <span v-if="params.Required && !params.Label" style="color: red">*</span>
    <div>
      <span class="input-text-description">{{
        params.Description ? params.Description[language] : null
      }}</span>
    </div>
    <div
      :class="
        params.DisplayType == $enums.DisplayType.Horizontal
          ? 'input-checkbox-box-horizontal'
          : ''
      "
    >
      <div
        class="input-checkbox-option"
        @click="clickItem(option)"
        v-for="(option, index) in tempList"
        :key="'checkbox-' + index"
      >
        <b-icon
          class="input-checkbox-option-icon"
          :class="
            (isValid ? ' input-valid ' : ' input-not-valid ') +
            (tempValue.length == 0
              ? ' input-value-empty '
              : ' input-value-not-empty ') +
            (option.Value ? ' input-checked ' : ' input-unchecked ') +
            (params.Enabled != false && !disabled ? '' : ' input-disabled ')
          "
          :icon="option.Value ? 'check-square-fill' : 'square'"
        ></b-icon>
        <span>{{ option.Label ? option.Label[language] : null }}</span>
      </div>
    </div>
    <span v-if="!isValid && tempValue.length > 0" class="checkbox-error-text">
      {{
        $t("validate.checkMinMax", { min: params.Minimum, max: params.Maximum })
      }}
    </span>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "CheckBoxComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Array],
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : [],
      tempList: null,
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
  computed: {
    isValid() {
      if (this.tempValue) {
        this.$emit("change", this.tempValue);
        return (
          (this.params.Maximum
            ? this.tempValue.length <= this.params.Maximum
            : true) &&
          (this.params.Minimum
            ? this.tempValue.length >= this.params.Minimum
            : true)
        );
      }
      return false;
    },
  },
  methods: {
    clickItem(option) {
      if (this.params.Enabled != false && !this.disabled) {
        Vue.set(option, "Value", !option.Value);
        const index = this.tempValue
          .map(function (e) {
            return e;
          })
          .indexOf(option.ItemId);
        if (index < 0) {
          this.tempValue.push(option.ItemId);
        } else {
          this.tempValue.splice(index, 1);
        }
      }
    },
    setCheckedItems() {
      if (this.value) {
        this.tempList.forEach((x) => {
          if (
            this.tempValue
              .map(function (e) {
                return e;
              })
              .includes(x.ItemId)
          ) {
            x.Value = true;
          }
        });
      } else {
        this.tempValue = [];
        this.tempList.forEach((x) => {
          if (x.Selected) {
            x.Value = true;
            this.tempValue.push(x.ItemId);
          }
        });
      }
    },
  },
  mounted() {
    this.tempList = JSON.parse(JSON.stringify(this.params.Items));
    this.setCheckedItems();
  },
};
</script>
<style scoped>
span.checkbox-error-text {
  color: red;
  font-size: 12px;
  padding-left: 5px;
  display: block;
  position: absolute;
}
.input-text-description {
  font-size: 14px;
  margin-left: 10px;
  color: #00000099;
}
.input-checkbox-box-horizontal {
  display: flex;
}
.input-checkbox-option-icon.input-not-valid.input-value-not-empty {
  color: #ff0000;
}
.input-checkbox-option-icon.input-checked {
  color: #ffa600;
}
.input-checkbox-option-icon.input-not-valid.input-value-not-empty.input-disabled {
  color: #ff0000aa;
}
.input-checkbox-option-icon.input-checked.input-disabled {
  color: #ffa600aa;
}
.input-checkbox-option-icon.input-unchecked.input-disabled {
  background: #00000033;
}
.input-checkbox-option-icon {
  margin: 0px 10px;
}
.input-checkbox-option {
  cursor: default;
  width: fit-content;
}
</style>