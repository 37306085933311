import { EhssClient, RequestMethod } from '@/backend/ehss-client'
import { RequestResponseLogic } from '@/logic/ui/request-response'

const TEMPLATE = '/notification_template'

export const TemplateLogic = {
    async getTemplates(params) {
        let result = await EhssClient.sendRequest(RequestMethod.GET, TEMPLATE, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async createTemplate(body, params) {
        let result = await EhssClient.sendRequestWithBody(RequestMethod.POST, TEMPLATE, params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async setTemplate(templateId, body, params) {
        let result = await EhssClient.sendRequestWithBody(RequestMethod.PATCH, TEMPLATE + '/' + templateId, params, body)
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteTemplate(templateId, params) {
        let result = await EhssClient.sendRequest(RequestMethod.DELETE, TEMPLATE + '/' + templateId, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    }
}