export const DePartner = {
  partner: {
    documents: {
      errorName: "dokumentieren",
    },
    home: {
      dailyTask: "Tägliche Aufgaben",
      partnerProgress: "Fortschritt des Teilnehmers im Projekt",
      progress: "Fortschritt",
      projectProgress: "Projektfortschritt",
      taskDescription: "Beschreibung",
      taskPriority: "Priorität",
      taskStatus: "Status",
      taskTitle: "Aufgaben",
      taskType: "Art",
      timeProgress: "Projektfortschritt im Zeitverlauf",
    },
    logs: {
      table: {
        date: "Datum",
        parameter: "Parameter",
        result: "Ergebnis",
        type: "Aktivität",
        user: "Nutzer",
      },
      title: "Betriebsprotokoll",
    },
    menu: {
      documents: "Dokumentbibliothek",
      home: "Persönliche Startseite",
      registrationParticipant: "Neuer Teilnehmer",
      templates: "Vorlagen",
    },
    newProject: {
      controlPatientCount: "Anzahl Kontrollpatienten",
      dataSheet: {
        title: "Datenblatt",
      },
      participants: {
        addParticipant: "Teilnehmer hinzufügen",
        deleteParticipants: "Teilnehmer löschen",
        table: {
          fields: {
            name: "Name des Teilnehmers",
            role: "Projektrolle",
            selected: "Ausgewählt",
          },
        },
        title: "Teilnehmer",
      },
      roles: {
        addRole: "Rolle hinzufügen",
        deleteRoles: "Rollen löschen",
        newRole: {
          baseRoles: {
            Doctor: "Arzt",
            Manager: "Manager",
            Patient: "Patient",
          },
          permissions: {
            BaseData: "Basisdaten",
            Doctor: "Arzt",
            DoctorProgress: "Fortschritt Arzt",
            DoctorRelation: "Beziehung Arzt",
            Doctors: "Ärzte",
            Item: "Punkt",
            Manager: "Manager",
            ManagerProgress: "Fortschritt Manager",
            ManagerRelation: "Beziehung Manager",
            Managers: "Manager",
            OwnProgress: "Eigener Fortschritt",
            Patient: "Patient",
            PatientProgress: "Fortschritt Patient",
            PatientRelation: "Beziehung Patient",
            Patients: "Patienten",
            Relation: "Beziehung",
            Research: "Forschung",
            Subordinate: "Nachrangig",
            SubordinateDoctor: "Nachrangiger Arzt",
            SubordinateDoctorRelation: "Beziehung nachrangiger Arzt",
            SubordinateManager: "Nachrangiger Manager",
            SubordinateManagerRelation: "Beziehung nachrangiger Manager",
            SubordinatePatient: "Nachrangiger Patient",
            SubordinatePatientRelation: "Beziehung nachrangiger Patientr",
            SubordinateProgress: "Nachrangiger Fortschritt",
            SubordinateRelation: "nachrangige Beziehung ",
          },
          permissionTypes: {
            add: "Hinzufügen",
            edit: "Bearbeiten",
            remove: "Entfernen",
            see: "Lesen",
          },
        },
        permissions: "Genehmigungen",
        table: {
          fields: {
            baseRoles: "Rollen",
            name: "Name",
            permissions: "Berechtigungen",
            selected: "Auswahl",
          },
        },
        title: "Rollen",
      },
      testPatientCount: "Anzahl Testpatienten",
      title: "Neues Projekt",
    },
    participantList: {
      communicationTab: {
        call: "Call",
        chat: "Chat",
        communication: "Kommunikation",
        communicationTypes: "Kommunikationsarten",
        email: "E-mail",
        emailAttachment: "Anhang",
        emailBody: "Nachrichtentext",
        emailNew: "New email",
        emailPriority: "Priorität",
        emailSubject: "Gegenstand",
        openButton: "Anzeige",
        operations: "Vorgänge",
        push: "Push",
        pushNew: "New push",
        pushPhoto: "Benachrichtigungsbild",
        pushText: "Benachrichtigungstext",
        pushTitle: "Benachrichtigungstitel",
        send: "Senden",
        sendMessages: "Eine Nachricht schicken",
        sendTime: "Zeitpunkt des Versands",
        sms: "SMS",
        smsNew: "New SMS",
        smsText: "SMS-Text",
        templateMessageTypes: "Verfügbare Vorlagen",
        templateName: "Vorlagenname",
        templateSupportedLanguages: "Verfügbare Sprachen",
        type: "Typ",
      },
      documentsTab: {
        documentType: "Art des Dokumentes",
      },
      formsTab: {
        assignment: "Zuordnung",
        description: "Beschreibung",
        endDate: "Datum der Fertigstellung",
        language: "Sprache",
        newForm: "Neues Formular hinzufügen",
        operations: "Vorgänge",
        state: "Formularstatus",
        title: "Formulartitel",
      },
      newParticipantForm: {
        note: "Bemerkungen",
        projectRole: "Projektrolle",
        requestResponseMessage: {
          successSave:
            "Sie haben den neuen Teilnehmer erfolgreich im Projekt gespeichert.",
        },
        tags: "Stichworte",
        username: "Teilnehmer-Benutzername",
      },
      participantList: {
        addNewParticipantToProject: "Teilnehmer zum Projekt hinzufügen",
        errorTagsName: "Stichworte",
        notBirthday: "Geburtsdatum nicht angegeben",
        notFoundParticipant: "Es können keine Teilnehmer angezeigt werden.",
        tagFilter: "Stichwortfilter",
      },
      participantTabs: {
        communication: "Kommunikation",
        documents: "Dokumente",
        forms: "Teilnehmerformulare",
        personalData: "Persönliches Profil",
        projects: "Projekte",
        questionnaires: "Teilnehmerfragebögen",
        workflow: "Prozesse",
      },
      personalDataTab: {
        deleteFromProject: "Aus dem Projekt löschen",
        editProfile: "Profil bearbeiten",
        editTag: "Änderung des Stichwortes",
        errorName: "medizinisches Profil",
      },
      questionnairesTab: {
        questionnaire: "Fragebögen",
        operations: "Vorgänge",
        cancelButton: "Widerruf",
        fromDate: "Veröffentlichungsdatum",
        deadline: "Frist",
        evaluatingButton: "Auswertung",
        giveAgain: "Neuausgabe",
        progress: "Fortschritt",
        requestResponseMessage: {
          successCancel: "Erfolgreiche Fragebogenrücknahme",
          successReassigned: "Erfolgreiche Neuausgabe des Fragebogens",
          successSave: "Erfolgreich gespeichert",
        },
        state: "Fragebogenstatus",
        warningButton: "Warnung",
      },
      tag: {
        edit: "Bearbeiten",
        editTag: "Stichworte ändern",
        language: "Sprache",
        newTag: "Neues Stichwort erstellen",
        noTags: "Bis jetzt kein Stichwort vorhanden.",
        own: "Besitzen",
        project: "Projekt",
        tagColor: "Stichwortefarbe",
        tagInfo: "Doppelklicken, um vorhandenes Stichwort zu bearbeiten",
        tagManager: "Stichwortmanager",
        tagName: "Bezeichnung des Stichwortes",
        tags: "Stichworte",
      },
      title: "Teilnehmer",
      workflowTab: {
        currentInProgress: "Schritt ausführen...",
        currentSteps: "Aktuell laufender Prozessschritt(e)",
        errorStartedName: "Prozess gestartet",
        progress: "Fortschritt",
        stepWaiting: "Prozess ausstehend",
        workflow: "Prozess",
      },
    },
    profile: {
      address: {
        building: "Hausnummer",
        city: "Stadt",
        latitude: "Breite",
        longitude: "Länge",
        street: "Name und Art des öffentlichen Raums",
        subBuilding: "Zusätzliche Titelergänzung",
        zip: "Postleitzahl",
      },
      chat: "Chat",
      communication: "Kommunikation",
      departmentName: "Name der Abteilung",
      email: "E-Mail",
      emailAddress: "E-Mail-Addresse",
      institutionName: "Name der Einrichtung",
      name: "Name",
      phone: "Telefonnummer",
      position: "Post",
      push: "Push-Nachricht",
      sms: "SMS",
      title: "Profil",
      username: "Name des Nutzers",
    },
    projects: {
      controllGroup: "Kontrollgruppe",
      controlPatientCount: "Davon Kontrollpatienten",
      dataSheet: "Datenblatt",
      description: "Beschreibung",
      dontProject: "Nicht mit Projekt verbunden",
      filter: "Filter",
      institution: "Institut",
      none: "Kein",
      participants: "Teilnehmer",
      patientIn: "Anzahl der beteiligten Patienten",
      privacy: "Experimentsform",
      progress: "Fortschritt",
      projectGoal: "Projektziel",
      projectName: "Projektname",
      projectTitle: "Projekttitel",
      roles: "Rollen",
      selectionCriteria: "Auswahlkriterium",
      specialistCount: "Anzahl der beteiligten Ärzte",
      specialistName: "Name des Arztes",
      specialists: "Ärzte",
      state: "Status",
      status: "Status",
      tasksProgress: "Aufgabenfortschritt",
      testGroup: "Testgruppe",
      testPatientCount: "Davon Testpatienten",
      timestamp: "Zeitraum",
      title: "Projekt",
    },
    questionnaires: {
      title: "Fragebögen",
      waitingForEvaluting: "Fragebögen zur Auswertung",
      waitingTab: {
        title: "Fragebogentitel",
        description: "Beschreibung",
        patientSsn: "Sozialversicherungsnummer des Patienten",
        patientBirth: "Geburtsdatum des Patienten",
        givenDate: "Zuweisungsdatum",
        filledDate: "Fertigstellungstermin",
        evaluating: "Auswertung",
        deadline: "Frist",
        patient: "Ausfüllender Kunde",
        progress: "Fortschritt",
        participant: "Teilnehmer ausfüllen",
        errorName: "verteilbarer Fragebögen",
      },
      assignable: "Zuweisbare Fragebögen",
      assignableTab: {
        createDate: "Datum erstellt",
        errorName: "zuweisbarer Fragebogen",
        fillTime: "Zeit zu vervollständigen",
        formCreatedDate: "Erstellungsdatum",
        newdeadline: "Termin",
        patientBirthday: "Geburtsdatum des Arztes",
        patientName: "Name des Arztes",
        noPatient: "Es sind keine Patienten zum Anzeigen vorhanden",
        patientNote: "Kommentar",
        patientSsn: "Sozialversicherungsnummer des Patienten",
        formDescription: "Beschreibung",
        formLang: "Sprachen",
        formTitle: "Titel",
        giveQuestionnaries:
          "Verteilung des ausgewählten Fragebogens an {count} ausgewählte Teilnehmer",
        newdeadlineUnit: "TAG.HH:MM:SS",
        newFormByTask: "Fragebögen als Aufgabe zuweisen",
        newFormHardDeadline: "Es schließt die Aufgabe ab, wenn sie abläuft",
        newSendDate: "Versanddatum",
        noParticipant: "Es sind keine Teilnehmer zum Anzeigen vorhanden",
        noTag: "Unbeschriftet",
        participantName: "Name des Teilnehmers",
        participantNote: "Kommentar",
        supportedLanguages: "Verfügbare Sprachen",
      },
      questionnaires: "Fragebögen",
      questionnairesTable: {
        title: "Titel",
        description: "Beschreibung",
        createdDate: "Erstellt",
        state: "Status",
      },
      patients: "Patienten",
      patientTable: {
        ssn: "Sozialversicherungsnummer des Patienten",
        name: "Patientenname",
        birthday: "Geburtsdatum des Patienten",
        note: "Kommentar",
        noPatient: "Keine Patienten vorhanden",
      },
      deadline: "Frist",
      giveQuestionnaries:
        "Verteilen Sie den ausgewählten Fragebögen an die ausgewählten {count} Patienten.",
      sendDate: "Absendedatum",
      form: {
        point: " Punkte",
        shortDescription: "Bewertung in Kürze",
        longDescription: "Lange Bewertung",
        summary: "Gesamtpunktzahl: ",
        note: "Kommentar",
      },
    },
    registrationParticipant: {
      accountData: "Kontoinformationen",
      email: "E-Mail-Addresse",
      flags: "Kontoeigenschaft",
      name: "Name",
      password: "Passwort",
      passwordAgain: "Wiederholen Sie das Passwort.",
      role: "Rolle",
      username: "Nutzername",
    },
    research: {
      title: "Virtueller Forschungsraum",
    },
    tasks: {
      deadline: "Frist",
      description: "Beschreibung",
      priority: "Priorität",
      startDate: "Anfangsdatum",
      status: "Status",
      task: "Aufgabe",
      title: "Aufgaben",
      type: "Art",
    },
    templates: {
      addEmail: "E-Mail-Vorlage hinzufügen",
      addPush: "PUSH-Vorlage hinzufügen",
      addSMS: "SMS-Vorlage hinzufügen",
      addToProject: "Vorlage zum Projekt hinzugefügt",
      email: "E-Mail-Vorlage",
      emailSubject: "Gegenstand",
      errorName: "Vorlage",
      lang: "Vorlagensprache",
      name: "Name der Vorlage",
      newTemplate: "Fügen Sie eine neue Vorlage hinzu",
      noTemplate: "Keine Vorlage zum Anzeigen",
      push: "Push-Vorlage",
      pushImg: "Symbolbild-URL",
      pushMessage: "Botschaft",
      pushTitle: "Titel",
      removeEmail: "E-Mail-Vorlage entfernen",
      removePush: "Push-Vorlage entfernen",
      removeSMS: "SMS-Vorlage entfernen",
      sms: "SMS-Vorlage",
      smsMessage: "Botschaft",
    },
    workflows: {
      title: "Prozessspeicher",
    },
  },
};
