<template>
  <fp-form-modal
    size="lg"
    :title="$t('healthProfessional.patientManager.manager.newPatientSignUp')"
    v-model="modalVisible"
    :closable="false"
  >
    <template #content>
      <!-- Fiók adatok -->
      <div style="margin-top: 10px">
        {{ $t("healthProfessional.patientManager.patientSignUp.accountData") }}
      </div>
      <b-row cols="1" cols-md="2">
        <div class="column-element">
          <fp-input
            v-model="userData.Username"
            :label="
              $t('healthProfessional.patientManager.patientSignUp.username')
            "
            :required="true"
            :state="!validation.hasError('userData.Username')"
            :error="validation.firstError('userData.Username')"
          />
        </div>
        <div class="column-element">
          <fp-input
            v-model="userData.PersonName"
            :label="$t('healthProfessional.patientManager.patientSignUp.name')"
            :required="true"
            :state="!validation.hasError('userData.PersonName')"
            :error="validation.firstError('userData.PersonName')"
          />
        </div>
        <!-- <div class="column-element">
          <fp-input
            :disabled="true"
            v-model="userData.Roles[0]"
            :label="$t('healthProfessional.patientManager.patientSignUp.role')"
          />
        </div> -->
        <div class="column-element">
          <fp-input
            v-model="userData.EmailAddress"
            :label="$t('healthProfessional.patientManager.patientSignUp.email')"
            type="email"
            :required="true"
            :state="!validation.hasError('userData.EmailAddress')"
            :error="validation.firstError('userData.EmailAddress')"
          />
        </div>
        <div class="column-element">
          <fp-select
            type="multi"
            :items="Object.values($enums.UserFlag)"
            valueKey="Value"
            v-model="userData.Flags"
            :label="$t('healthProfessional.patientManager.patientSignUp.flags')"
            :textKeyConverter="
              (element) => {
                return $t(element.Text);
              }
            "
          >
            <template #element="element">
              {{ $t(element.Text) }}
            </template>
          </fp-select>
        </div>
        <div class="column-element">
          <fp-input
            v-model="userData.Password"
            :label="
              $t('healthProfessional.patientManager.patientSignUp.password')
            "
            type="password"
            :required="true"
            :state="!validation.hasError('userData.Password')"
            :error="validation.firstError('userData.Password')"
          />
        </div>
        <div class="column-element">
          <fp-input
            :label="
              $t(
                'healthProfessional.patientManager.patientSignUp.passwordAgain'
              )
            "
            type="password"
            v-model="userData.PasswordAgain"
            :required="true"
            :state="!validation.hasError('userData.PasswordAgain')"
            :error="validation.firstError('userData.PasswordAgain')"
          />
        </div>
      </b-row>
      <!-- Beteg-orovs kapcsolat -->
      <!-- <div
        v-if="!userData.Flags.includes('Impersonated')"
        class="column-element"
      >
        <b-check v-model="isCreateRelationship">{{
          $t(
            "healthProfessional.patientManager.patientSignUp.newPatientDoctorRelationship"
          )
        }}</b-check>
      </div>
      <b-row
        v-if="isCreateRelationship || userData.Flags.includes('Impersonated')"
        cols="1"
        cols-md="2"
      >
        <div class="column-element">
          <fp-select
            :items="Object.values($enums.DoctorPatientRelationshipType)"
            valueKey="Value"
            :textKeyConverter="
              (e) => {
                return $t(e.Text);
              }
            "
            type="multi"
            v-model="createDoctorPatientRelationship.Types"
            :label="
              $t(
                'healthProfessional.patientManager.patientSignUp.relationshipType'
              )
            "
          >
            <template #element="e">
              {{ $t(e.Text) }}
            </template>
          </fp-select>
        </div>
        <div class="column-element">
          <fp-select
            :items="doctorTags"
            v-model="createDoctorPatientRelationship.TagIds"
            :label="$t('healthProfessional.patientManager.patientSignUp.tags')"
            type="multi"
            valueKey="TagId"
            :textKeyConverter="
              (e) => {
                return getTextByLanguage(e.Name);
              }
            "
          >
            <template #element="e">
              {{ getTextByLanguage(e.Name) }}
            </template>
          </fp-select>
        </div>
        <div class="column-element">
          <fp-input
            v-model="createDoctorPatientRelationship.DoctorsRemark"
            :label="
              $t(
                'healthProfessional.patientManager.patientSignUp.relationshipRemark'
              )
            "
          />
        </div>
      </b-row> -->
      <!-- Projekt -->
      <!-- <div v-if="addToProject.ProjectId" class="column-element">
        <b-check v-model="isAddToProject">{{
          $t(
            "healthProfessional.patientManager.patientSignUp.newPatientToProject"
          )
        }}</b-check>
      </div> -->
      <b-row v-if="addToProject.ProjectId" cols="1" cols-md="2">
        <div class="column-element">
          <fp-select
            v-if="project"
            :label="
              $t('healthProfessional.patientManager.patientSignUp.projectRole')
            "
            :items="
              project.Roles.filter((role) => role.BaseRoles.includes('Patient'))
            "
            textKey="RoleName"
            v-model="selectedRole"
            :required="true"
            :state="!validation.hasError('selectedRole')"
            :error="validation.firstError('selectedRole')"
          />
        </div>
        <!-- <div class="column-element">
          <fp-select
            :items="projectTags"
            v-model="addToProject.TagIds"
            :label="
              $t(
                'healthProfessional.patientManager.patientSignUp.tagsInProject'
              )
            "
            type="multi"
            valueKey="TagId"
            textKey="Name"
          />
        </div> -->
        <div class="column-element">
          <fp-input
            v-model="addToProject.Remark"
            :label="
              $t(
                'healthProfessional.patientManager.patientSignUp.projectRemark'
              )
            "
          />
        </div>
      </b-row>
      <participant-parameters
        :role="selectedRole"
        v-model="userProjectParameters"
      />
      <div style="text-align: center; margin: 10px 0px">
        <b-button @click="saveUser">{{ $t("base.basic.save") }}</b-button>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import { InvitationLogic } from "../../Logic/Backend/invitation";
import { TagLogic } from "../../Logic/Backend/tag";
import { UserLogic } from "../../Logic/Backend/user";
import ParticipantParameters from "./Components/ParticipantParameters.vue";
export default {
  components: { ParticipantParameters },
  name: "CreateUserToProject",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    project: Object,
  },
  data() {
    return {
      sexList: this.$enums.Sex,
      roleList: [],
      userData: {
        Username: null,
        Roles: ["Client"],
        Flags: [],
        PersonName: null,
        EmailAddress: null,
        Password: null,
        PasswordAgain: null,
      },
      defaultUserData: {
        Username: null,
        Roles: ["Client"],
        Flags: [],
        PersonName: null,
        EmailAddress: null,
        Password: null,
        PasswordAgain: null,
      },
      createDoctorPatientRelationship: {
        DoctorId: this.$loggedUser.UserId,
        Types: [],
        TagIds: [],
        DoctorsRemark: null,
      },
      addToProject: {
        ProjectId: this.$loggedUser.SelectedProject
          ? this.$loggedUser.SelectedProject.ProjectId
          : null,
        RoleName: null,
        SuperiorIds: [this.$loggedUser.UserId],
        TagIds: [],
        Parameters: {},
        Remark: null,
      },
      userProjectParameters: null,
      projectTags: null,
      doctorTags: null,
      isCreateRelationship: null,
      isAddToProject: null,
      modalVisible: this.value,
      selectedRole: null,
    };
  },
  watch: {
    modalVisible(input) {
      this.$emit("change", input);
    },
    value(input) {
      this.modalVisible = input;
      if (input) {
        this.userData = JSON.parse(JSON.stringify(this.defaultUserData));
        this.validation.reset();
      }
    },
  },
  validators: {
    "userData.Username": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "userData.EmailAddress": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"))
        .email();
    },
    "userData.PersonName": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "userData.Password": function (value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "userData.PasswordAgain, userData.Password": function (again, password) {
      if (this.validation.isTouched("userData.PasswordAgain")) {
        return this.$validator
          .value(again)
          .required(this.$t("validate.requiredField"))
          .match(password);
      }
    },
    selectedRole: function (value) {
      /* if (this.isAddToProject) { */
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
      /* } */
    },
  },
  methods: {
    /* flagChange(flags) {
      if (flags.includes("Impersonated")) {
        this.isAddToProject = true;
      }
    }, */
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    async saveUser() {
      const valid = await this.$validate();
      if (valid) {
        //this.userData.Roles = ["Client"];
        /* this.userData.UserName = this.userData.EmailAddress;
        this.userData.Password = "asd123asd";
        this.userData.Flags = ["NoLogin", "Impersonated"]; */
        this.userData.CreateDoctorPatientRelationship = {
          DoctorId: this.$loggedUser.UserId,
          Types: ["Project"],
          TagIds: [],
        };
        this.userData.AddToProject = {
          ProjectId: this.project.ProjectId,
          RoleName: this.selectedRole ? this.selectedRole.RoleName : null,
          Parameters: this.userProjectParameters,
          SuperiorIds: [this.$loggedUser.UserId],
          TagIds: [],
        };
        console.log(this.userData);
        /* if (
          this.isCreateRelationship ||
          this.userData.Flags.includes("Impersonated")
        ) {
          this.userData.CreateDoctorPatientRelationship =
            this.createDoctorPatientRelationship;
        }
        if (this.isAddToProject) {
          this.userData.AddToProject = this.addToProject;
        } */
        const saveUserResponse = await UserLogic.createUser(this.userData);
        if (!saveUserResponse.Code) {
          //sikeres felhasználó létrehozás
          this.$bvToast.toast(this.$t("requestResponse.basic.successSave"), {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          });
          /* if (!this.isCreateRelationship) {
            await this.addInvitation(saveUserResponse.UserId);
          } */
          this.$emit("selectParticipant", saveUserResponse);
          this.modalVisible = false;
        } else {
          //hiba
          this.$bvToast.toast(saveUserResponse.Message, {
            title: this.$t("requestResponse.basic.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async addInvitation(userId) {
      const body = {
        Type: "Patient",
        RequestedById: this.$loggedUser.UserId,
        TargetId: userId,
        Parameters: { RelationshipType: "Project" },
      };
      const addInvitationResponse = await InvitationLogic.createInvitation(
        body
      );
      if (!addInvitationResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.invitation.successSend"), {
          title: this.$t("requestResponse.invitation.successSendTitle"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(addInvitationResponse.Message, {
          title: this.$t("requestResponse.invitation.errorSend"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getTags() {
      const getTagsResponse = await TagLogic.getTags();
      if (!getTagsResponse.Code) {
        this.projectTags = getTagsResponse
          .filter((tag) => tag.Type == "ProjectTag")
          .filter((tag) =>
            this.project.Items.some(
              (item) =>
                item.EntityTypeName == "FpTag" && item.EntityId == tag.TagId
            )
          );
        this.doctorTags = getTagsResponse.filter(
          (tag) => tag.OwnerId == this.$loggedUser.UserId
        );
      } else {
        //TODO: hiba
      }
    },
  },
  async mounted() {
    await this.getTags();
    this.userData = JSON.parse(JSON.stringify(this.defaultUserData));
    this.validation.reset();
  },
};
</script>
<style scoped>
.column-element {
  padding: 0px 10px;
}
</style>
