<template>
  <div>
    <fp-select
      :items="params.Items"
      :label="params.Title ? params.Title[language] : null"
      :required="params.Required"
      :description="params.Description ? params.Description[language] : null"
      :disabled="!params.Enabled != false || disabled"
      :state="isValid"
      :error="
        'Minimum: ' +
        params.Minimum +
        ', maximum: ' +
        params.Maximum +
        ' lehet megjelölni'
      "
      :searchable="searchable"
      @search="$emit('search', $event)"
      type="multi"
      v-model="tempValue"
      valueKey="ItemId"
      :textKeyConverter="
        (element) => {
          return element.Label[language];
        }
      "
    >
      <template #element="element">
        {{ element.Label[language] }}
      </template>
    </fp-select>
  </div>
</template>
<script>
export default {
  name: "Multiple",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Array, String, Number, Boolean],
    disabled: Boolean,
    searchable: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : [],
    };
  },
  computed: {
    isValid() {
      if (this.tempValue) {
        //this.$emit("change", this.tempValue);
        return (
          this.tempValue.length <= this.params.Maximum &&
          this.tempValue.length >= this.params.Minimum
        );
      }
      return false;
    },
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
};
</script>