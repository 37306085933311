import EmailTemplate from "./EmailTemplate.vue";
import SmsTemplate from "./SmsTemplate.vue";
import PushTemplate from "./PushTemplate.vue";
import TemplateByLangs from "./TemplateByLangs.vue";

export default {
  install(Vue) {
    Vue.component("fp-email-template", EmailTemplate);
    Vue.component("fp-sms-template", SmsTemplate);
    Vue.component("fp-push-template", PushTemplate);
    Vue.component("fp-template-by-langs", TemplateByLangs);
  },
};
