<template>
  <fp-form-modal
    size="xl"
    :title="
      editable ? 'Finanszírozás szerkesztése' : 'Finanszírozás megtekintése'
    "
    v-model="visible"
    v-if="finance"
    :closable="!editable"
  >
    <template #content>
      <!-- <b-button @click="refreshData()">Frissített adatok behúzása</b-button> -->
      <fp-select
        label="Sűrgősségi recept felírás (CITO)?"
        :required="true"
        v-model="finance.ExpressPrescription"
        @change="changeValue('ExpressPrescription', $event)"
        :items="threeStateList"
        valueKey="Value"
        textKey="Text"
        :disabled="!editable"
        :state="!validation.hasError('finance.ExpressPrescription')"
        :error="validation.firstError('finance.ExpressPrescription')"
      />
      <fp-panel-card
        style="margin-top: 10px"
        title="Felíró orvos főbb adatai"
        :required="true"
      >
        <template #content>
          <b-row cols="1" cols-md="2">
            <b-col>
              <div>
                <fp-input
                  :disabled="
                    !editable ||
                    (prescribingDoctor &&
                      ((prescribingDoctor.StampNumber &&
                        (prescribingDoctor.StampNumber.length == 5 ||
                          prescribingDoctor.StampNumber.length == 7 ||
                          prescribingDoctor.StampNumber.length == 8)) ||
                        (prescribingDoctor.EnkkId &&
                          (prescribingDoctor.EnkkId.length == 5 ||
                            prescribingDoctor.EnkkId.length == 7 ||
                            prescribingDoctor.EnkkId.length == 8))))
                  "
                  label="Felíró orvos pecsétszáma"
                  :required="true"
                  v-model="finance.DoctorStampNumber"
                  @change="changeValue('DoctorStampNumber', $event)"
                  :state="!validation.hasError('finance.DoctorStampNumber')"
                  :error="validation.firstError('finance.DoctorStampNumber')"
                />
              </div>
            </b-col>
            <b-col>
              <div>
                <fp-input
                  :disabled="
                    !editable ||
                    (prescribingDoctor &&
                      prescribingDoctor.OrganizationUnitNnkId &&
                      prescribingDoctor.OrganizationUnitNnkId.length == 9)
                  "
                  :required="true"
                  label="Felíró orvos szervezeti egység NNK azonosítója"
                  v-model="finance.DoctorPraxisOrganizationCode"
                  @change="changeValue('DoctorPraxisOrganizationCode', $event)"
                  :state="
                    !validation.hasError('finance.DoctorPraxisOrganizationCode')
                  "
                  :error="
                    validation.firstError(
                      'finance.DoctorPraxisOrganizationCode'
                    )
                  "
                />
              </div>
            </b-col>
          </b-row>
        </template>
      </fp-panel-card>
      <fp-panel-card
        style="margin-top: 10px"
        title="Páciens főbb adatai"
        :required="true"
      >
        <template #content>
          <b-row cols="1" cols-md="2">
            <b-col>
              <fp-input
                :disabled="
                  !editable ||
                  (dispensedPatient &&
                    !!dispensedPatient.PatientIdentifierValue)
                "
                label="Páciens azonosító"
                :required="true"
                v-model="finance.PatientSsn"
                @change="changeValue('PatientSsn', $event)"
                :state="!validation.hasError('finance.PatientSsn')"
                :error="validation.firstError('finance.PatientSsn')"
              />
            </b-col>
            <b-col>
              <fp-select
                :disabled="
                  !editable ||
                  (dispensedPatient && !!dispensedPatient.PatientIdentifierType)
                "
                label="Páciens azonosító típusa"
                :required="true"
                v-model="finance.PatientDocumentCode"
                @change="changeValue('PatientDocumentCode', $event)"
                :items="eesztPatientIdentifierTypeList"
                valueKey="Code"
                textKey="Name"
                :state="!validation.hasError('finance.PatientDocumentCode')"
                :error="validation.firstError('finance.PatientDocumentCode')"
              />
            </b-col>
            <b-col>
              <fp-select
                :disabled="
                  !editable ||
                  (dispensedPatient && !!dispensedPatient.Nationality)
                "
                :required="true"
                label="Páciens állampolgársága"
                v-model="finance.PatientCitizenshipCode"
                @change="changeValue('PatientCitizenshipCode', $event)"
                :items="eesztCountryList"
                valueKey="Code"
                textKey="CitizenshipName"
                :searchable="true"
                @search="searchCountry"
                :state="!validation.hasError('finance.PatientCitizenshipCode')"
                :error="validation.firstError('finance.PatientCitizenshipCode')"
              />
            </b-col>
            <b-col>
              <fp-date-picker
                :disabled="
                  !editable ||
                  (dispensedPatient && !!dispensedPatient.BirthDate)
                "
                label="Páciens születési dátuma"
                :required="true"
                v-model="finance.PatientBirthDate"
                @change="changeValue('PatientBirthDate', $event)"
                :state="!validation.hasError('finance.BirthDate')"
                :error="validation.firstError('finance.BirthDate')"
              />
            </b-col>
          </b-row>
        </template>
      </fp-panel-card>
      <fp-panel-card
        style="margin-top: 10px"
        title="Kiváltott termékekhez tartozó finanszírozási adatok"
        :required="true"
      >
        <template #content>
          <fp-panel-card
            style="margin: 20px 0px"
            v-for="(product, index) in finance.Products"
            :key="'new-finance-product-' + index"
            :title="
              dispenseProductsForFinance[index].Ttt
                ? dispenseProductsForFinance[index].Ttt.Name +
                  ' (TTT: ' +
                  dispenseProductsForFinance[index].Ttt.Code +
                  ')'
                : index + 1 + '. termékhez tartozó adatok'
            "
            :closedCard="index != 0"
          >
            <template #content>
              <finance-product
                ref="financeProduct"
                v-model="finance.Products[index]"
                @changeValue="
                  changeValue(
                    'Products[' + index + '].' + $event.key,
                    $event.value
                  )
                "
                :editable="editable"
                :dispenseProduct="dispenseProductsForFinance[index]"
                :prescriptionProduct="
                  getPrescriptionData(dispenseProductsForFinance[index])
                "
                :refreshDataLoaded="refreshDataLoaded"
              />
            </template>
          </fp-panel-card>
        </template>
      </fp-panel-card>
      <div v-if="editable">
        <b-button @click="saveFinanceData">Mentés</b-button>
        <b-button @click="visible = false">Mégsem</b-button>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import { BaseDataLogic } from "../../../Logic/Backend/base-data";
import { MedicalDeviceLogic } from "../../../Logic/Backend/medical-device";
import FinanceProduct from "../Components/form/FinanceProduct.vue";
export default {
  components: { FinanceProduct },
  name: "FinanceDataForm",
  model: {
    prop: "modalVisibile",
    event: "change",
  },
  props: {
    modalVisibile: Boolean,
    financeData: Object,
    dispenseProducts: Array,
    prescriptionProducts: Array,
    prescribingDoctor: Object,
    dispensedPatient: Object,
    prescriptionId: Number,
    editable: Boolean,
  },
  data() {
    return {
      visible: this.modalVisibile,
      productsCount: 0,
      finance: null,
      dispenseProductsForFinance: null,
      defaultFinance: {
        ExpressPrescription: null,
        Products: [null],
        DoctorPraxisOrganizationCode: null,
        DoctorStampNumber: null,
        PatientCitizenshipCode: null,
        PatientSsn: null,
        PatientDocumentCode: null,
        PatientBirthDate: null,
      },
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
      changedProductValues: null,
      eesztPatientIdentifierTypeList: null,
      eesztCountryList: null,
      refreshDataLoaded: false,
    };
  },
  validators: {
    "finance.DoctorPraxisOrganizationCode": function (value) {
      return this.$validator
        .value(value)
        .required("Kötelező mező!")
        .length(
          9,
          "Az érték 9 karakterből kell állnia! Ellenőrizze, hogy megfelelő karakter mennyiséget írt be!"
        );
    },
    "finance.DoctorStampNumber": function (value) {
      return this.$validator
        .value(value)
        .required("Kötelező mező!")
        .maxLength(
          9,
          "Ellenőrizze, hogy megfelelő karakter mennyiséget írt be!"
        )
        .minLength(
          5,
          "Ellenőrizze, hogy megfelelő karakter mennyiséget írt be!"
        );
    },
    "finance.PatientCitizenshipCode": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.ExpressPrescription": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.PatientSsn": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.PatientDocumentCode": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "finance.PatientBirthDate": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  watch: {
    async modalVisibile(input) {
      this.visible = input;
      if (input) {
        await this.getLists();
        this.setFinanceData();
      } else {
        this.refreshDataLoaded = false;
      }
    },
    visible(input) {
      this.$emit("change", input);
      if (!input) {
        this.refreshDataLoaded = false;
      }
    },
    async financeData() {
      this.refreshDataLoaded = false;
      await this.getLists();
      this.setFinanceData();
    },
  },
  methods: {
    getPrescriptionData(dispense) {
      const perscriptionProd = this.prescriptionProducts.find(
        (p) => p.UniqueId == dispense.PrescriptionProductUniqueId
      );
      if (perscriptionProd) {
        return perscriptionProd;
      } else {
        return null;
      }
    },
    changeProductValue(productKey, changedObj) {
      Object.entries(changedObj).forEach(([key, value]) => {
        this.changeValue(productKey + "." + key, value);
      });
    },
    changeValue(key, value) {
      if (!this.changedProductValues) {
        this.changedProductValues = {};
      }
      this.changedProductValues[key] = value;
    },
    getDispenseData(productUniqueId) {
      return this.dispenseProducts.find((p) => p.UniqueId == productUniqueId);
    },
    async searchCountry(input) {
      var list;
      if (input.length > 3) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { ["CitizenshipName-contains"]: input },
          "CitizenshipName",
          "string"
        );
      } else if (input.length == 0) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { "-orderby": "CitizenshipName", ["-limit"]: 10 },
          "CitizenshipName",
          "string"
        );
      } else {
        list = [];
      }

      var selectedValue = [];
      if (this.financeData && this.financeData.PatientCitizenshipCode) {
        selectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            Code: this.financeData.PatientCitizenshipCode,
          }
        );
      }

      this.eesztCountryList = this.$checkDuplicates(
        [...list, ...selectedValue],
        "EntryId"
      );
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        Code: null,
      });
    },
    async getLists() {
      const eesztCountryDefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_citizenship.Value,
        { "-orderby": "CitizenshipName", ["-limit"]: 10 },
        "CitizenshipName",
        "string"
      );
      var eesztCountryListSelectedValue = [];
      if (this.financeData && this.financeData.PatientCitizenshipCode) {
        eesztCountryListSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            Code: this.financeData.PatientCitizenshipCode,
          }
        );
      }
      this.eesztCountryList = this.$checkDuplicates(
        [...eesztCountryDefaultList, ...eesztCountryListSelectedValue],
        "EntryId"
      );
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        Code: null,
      });

      this.eesztPatientIdentifierTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_patient_identifier_type.Value,
        this.validDateFilterParams,
        "Code",
        "number"
      );
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            Code: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
    async saveFinanceData() {
      const valid = await this.$validate();
      const validProds = await Promise.all(
        this.$refs["financeProduct"].map((p) => {
          return p.checkValidator();
        })
      );
      if (valid && !validProds.some((x) => !x)) {
        const body = this.getChangedPathValue();
        const saveResponse = await MedicalDeviceLogic.setFinance(
          body,
          this.prescriptionId
        );
        if (!saveResponse.Code) {
          this.visible = false;
          this.$emit("refreshList");
          this.$bvToast.toast(
            "Sikeresen elmentette a vény jelentéshz szükséges adataokat.",
            {
              title: "Sikeres mentés!",
              variant: "success",
              solid: true,
            }
          );
        } else {
          this.$bvToast.toast(saveResponse.Message, {
            title: "Hiba történt a mentés során!",
            variant: "danger",
            solid: true,
          });
        }
      } else {
        this.$bvToast.toast(
          "Nézze át az űrlapot, hogy minden adat helyesen lett kitöltve! A hiányzó vagy hibás mezők piros kerettel jelennek meg.",
          {
            title: "Hibás mező kitöltés",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    getChangedPathValue() {
      var changedFields = {};
      if (this.changedProductValues) {
        Object.entries(this.changedProductValues).forEach(([key, value]) => {
          const changed = this.getPath(value, key);
          if (changed) {
            Object.assign(changedFields, changed);
          }
        });
        return changedFields;
      } else {
        return null;
      }
    },
    getPath(obj, path) {
      if (typeof obj == "object" && obj != null && obj !== undefined) {
        const result = {};
        Object.entries(obj).forEach(([key, value]) => {
          const r = this.getPath(value, path + "." + key);
          if (r) {
            Object.assign(result, r);
          }
        });
        return result;
      } else {
        return { [path]: obj };
      }
    },
    refreshData() {
      if (this.prescribingDoctor) {
        if (
          this.finance.DoctorPraxisOrganizationCode !==
          this.prescribingDoctor.OrganizationUnitNnkId
        ) {
          this.changeValue(
            "DoctorPraxisOrganizationCode",
            this.prescribingDoctor.OrganizationUnitNnkId
          );
        }
        this.finance.DoctorPraxisOrganizationCode = this.prescribingDoctor.OrganizationUnitNnkId;
        const docStamp = this.prescribingDoctor.StampNumber;
        const docEnkk = this.prescribingDoctor.EnkkId;
        if (
          docStamp &&
          (docStamp.length == 5 || docStamp.length == 7 || docStamp.length == 8)
        ) {
          if (this.finance.DoctorStampNumber !== docStamp) {
            this.changeValue("DoctorStampNumber", docStamp);
          }
          this.finance.DoctorStampNumber = docStamp;
        } else if (
          docEnkk &&
          (docEnkk.length == 5 || docEnkk.length == 7 || docEnkk.length == 8)
        ) {
          if (this.finance.DoctorStampNumber !== docEnkk) {
            this.changeValue("DoctorStampNumber", docEnkk);
          }
          this.finance.DoctorStampNumber = docEnkk;

          this.$bvToast.toast(
            "Sikeresen behúzásra kerültek a módosíult recept, kiadás adatok. Kérem ellenőrizze az adatok helyességét, és javítsa egészítse ki a hiányzó adatmetőket.",
            {
              title: "Sikeres adatbehúzás",
              variant: "danger",
              solid: true,
            }
          );
        }
      }
      if (this.dispensedPatient) {
        const dispPatientNat = this.dispensedPatient.Nationality
          ? this.dispensedPatient.Nationality.Code
          : null;
        if (this.finance.PatientCitizenshipCode !== dispPatientNat) {
          this.changeValue("PatientCitizenshipCode", dispPatientNat);
        }
        this.finance.PatientCitizenshipCode = dispPatientNat;

        if (
          this.finance.PatientSsn !==
          this.dispensedPatient.PatientIdentifierValue
        ) {
          this.changeValue(
            "PatientSsn",
            this.dispensedPatient.PatientIdentifierValue
          );
        }
        this.finance.PatientSsn = this.dispensedPatient.PatientIdentifierValue;

        const dispPatientDocCode = this.dispensedPatient.PatientIdentifierType
          ? this.dispensedPatient.PatientIdentifierType.Code
          : null;
        if (this.finance.PatientDocumentCode !== dispPatientDocCode) {
          this.changeValue("PatientDocumentCode", dispPatientDocCode);
        }
        this.finance.PatientDocumentCode = dispPatientDocCode;

        if (this.finance.PatientBirthDate !== this.dispensedPatient.BirthDate) {
          this.changeValue("PatientBirthDate", this.dispensedPatient.BirthDate);
        }
        this.finance.PatientBirthDate = this.dispensedPatient.BirthDate;
      }
      console.log(this.changedProductValues);
      this.refreshDataLoaded = true;
    },
    setFinanceData() {
      if (this.financeData) {
        this.finance = JSON.parse(JSON.stringify(this.financeData));
      } else {
        this.finance = JSON.parse(JSON.stringify(this.defaultFinance));
      }
      const dispenseProductsWithSubCat = this.dispenseProducts.filter(
        (dp) => dp.SubsidizationCategory
      );
      this.dispenseProductsForFinance = dispenseProductsWithSubCat;
      this.finance.Products.length = dispenseProductsWithSubCat.length;
      this.changedProductValues = null;
    },
  },
  async mounted() {
    if (this.modalVisibile) {
      await this.getLists();
      this.setFinanceData();
    }
  },
  beforeDestroy() {
    this.refreshDataLoaded = false;
  },
};
</script>
