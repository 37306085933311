<template>
  <div
    :key="'components-header-footer-webappadminheader-' + forceUpdateKey"
    class="eh-header"
    v-if="siteTitle"
  >
    <Header
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :ChangedProfileFillingRate="ChangedProfileFillingRate"
      :signedProfilePicture="UserPicture"
      @forceUpdate="forceUpdate"
    />
    <b-container fluid>
      <b-row align-h="around" align-v="center">
        <div>
          <router-link to="/Partner/Home">
            <button class="eh-header-button">
              {{ $t("partner.menu.home") }}
            </button>
          </router-link>
        </div>
        <div style="text-align: center">
          <router-link to="RegistrationParticipant">
            <button class="eh-header-view-button">
              {{ $t("partner.menu.registrationParticipant") }}
            </button>
          </router-link>
          <router-link to="ParticipantList">
            <button class="eh-header-view-button">
              {{ $t("partner.participantList.title") }}
            </button>
          </router-link>
          <router-link
            v-if="
              $loggedUser.SelectedProject &&
                $loggedUser.SelectedProject.ProjectId
            "
            to="Projects"
          >
            <button class="eh-header-view-button">
              {{ $t("partner.projects.title") }}
            </button>
          </router-link>
          <router-link
            v-if="visibleUiElement($enums.UserInterfaceElement.Document.Value)"
            to="Documents"
          >
            <button class="eh-header-view-button">
              {{ $t("partner.menu.documents") }}
            </button>
          </router-link>
          <router-link
            v-if="visibleUiElement($enums.UserInterfaceElement.UserTask.Value)"
            to="Tasks"
          >
            <button class="eh-header-view-button">
              {{ $t("partner.tasks.title") }}
            </button>
          </router-link>
          <router-link
            v-if="visibleUiElement($enums.UserInterfaceElement.Workflow.Value)"
            to="Workflows"
          >
            <button class="eh-header-view-button">
              {{ $t("partner.workflows.title") }}
            </button>
          </router-link>
          <router-link
            v-if="
              visibleUiElement(
                $enums.UserInterfaceElement.NotificationTemplate.Value
              )
            "
            to="Templates"
          >
            <button class="eh-header-view-button">
              {{ $t("partner.menu.templates") }}
            </button>
          </router-link>
          <router-link
            v-if="
              visibleUiElement($enums.UserInterfaceElement.Questionnaire.Value)
            "
            to="Questionnaires"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.questionnaires") }}
            </button>
          </router-link>
        </div>
        <div>
          <router-link to="/">
            <button v-on:click="close" class="eh-header-button">Kilépés</button>
          </router-link>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Header from "@/components/header-footer/WebAppHeader.vue";
import { AuthLogic } from "@/logic/backend/auth.js";
export default {
  components: {
    Header,
  },
  props: {
    signedName: String,
    siteTitle: String,
    siteColor: String,
    UserPicture: [Object, String],
    ChangedProfileFillingRate: [Array, Object],
    projectEnabledUiElements: Object,
  },
  data() {
    return {
      forceUpdateKey: 0,
    };
  },
  methods: {
    forceUpdate() {
      ++this.forceUpdateKey;
      this.$emit("forceUpdate");
    },
    visibleUiElement(element) {
      if (!this.projectEnabledUiElements) {
        return true;
      } else {
        return (
          this.projectEnabledUiElements.Partner &&
          this.projectEnabledUiElements.Partner.includes(element)
        );
      }
    },
    close() {
      AuthLogic.clearSessionStorage();
    },
  },
};
</script>
<style></style>
