<template>
  <div v-if="!params.Hidden">
    <div>
      <span style="margin-left: 5px">{{
        params.Title ? params.Title[language] : null
      }}</span>
      <span v-if="params.Required" style="color: red">*</span>
    </div>
    <span class="input-text-description">{{
      params.Description ? params.Description[language] : null
    }}</span>
    <div
      :class="
        params.DisplayType == $enums.DisplayType.Horizontal
          ? 'input-radio-box-horizontal'
          : ''
      "
    >
      <div
        class="input-radio-option"
        @click="clickItem(option)"
        v-for="(option, index) in tempList"
        :key="'radio-' + index"
      >
        <b-icon
          class="input-radio-option-icon"
          :class="
            (tempValue ? ' input-value-empty ' : ' input-value-not-empty ') +
            (option.Active ? ' input-checked ' : ' input-unchecked ') +
            (params.Enabled != false && !disabled ? '' : ' input-disabled ')
          "
          :icon="option.Active ? 'record-circle-fill' : 'circle'"
        ></b-icon>
        <span>{{ option.Label ? option.Label[language] : null }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "RadioButtonComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Number, String],
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value,
      selectedValue: null,
      tempList: null,
    };
  },
  watch: {
    selectedValue(input) {
      this.tempValue = input.ItemId;
      this.$emit("change", this.tempValue);
    },
  },
  methods: {
    clickItem(option) {
      if (this.params.Enabled != false && !this.disabled) {
        if (this.selectedValue) {
          //Vue.set(this.selectedValue, "IsActive", false);
          Vue.set(this.selectedValue, "Active", false);
        }
        this.selectedValue = option;
        //Vue.set(this.selectedValue, "IsActive", true);
        Vue.set(this.selectedValue, "Active", true);
      }
    },
    setSelectedValue() {
      const selectV = this.tempList.find((x) => x.ItemId == this.tempValue);
      const defaultV = this.tempList.find((x) => x.Selected);
      if (selectV) {
        this.selectedValue = selectV;
        Vue.set(this.selectedValue, "Active", true);
      } else if (defaultV) {
        this.selectedValue = defaultV;
        Vue.set(this.selectedValue, "Active", true);
      } else {
        this.$emit("change", null);
      }
    },
  },
  mounted() {
    this.tempList = JSON.parse(JSON.stringify(this.params.Items));
    this.setSelectedValue();
  },
};
</script>
<style scoped>
span.radio-error-text {
  color: red;
  font-size: 12px;
  padding-left: 5px;
  display: block;
  position: absolute;
}
.input-text-description {
  font-size: 14px;
  margin-left: 5px;
  color: #00000099;
}
.input-radio-box-horizontal {
  display: flex;
}
.input-radio-option-icon {
  margin: 0px 10px;
}
/* .input-radio-option-icon.input-value-not-empty {
  color: #ff0000;
} */
.input-radio-option-icon.input-checked {
  color: #ffa600;
}
/* .input-radio-option-icon.input-value-not-empty.input-disabled {
  color: #ff0000aa;
} */
.input-radio-option-icon.input-checked.input-disabled {
  color: #ffa600aa;
}
.input-radio-option-icon.input-unchecked.input-disabled {
  background: #00000033;
  border-radius: 10px;
}
.input-radio-option {
  cursor: default;
  width: fit-content;
}
</style>