<template>
  <div>
    <div v-if="personalProfile && partnerProfile" style="margin-bottom: 10px">
      <b-row style="margin: 0px" cols="1" align-h="center">
        <!-- Profilkép kezelése -->
        <div v-if="tempClipperImg" style="text-align: center">
          <input style="display: none" type="file" id="profile-picture" />
          <img
            :class="disabled ? 'disbaled-img-profile' : 'img-profile'"
            style="width: 200px; height: 200px"
            id="profile-img"
            :src="tempClipperImg"
            @click="disabled ? '' : choosePhoto()"
          />
          <b-modal
            v-model="showClipperModal"
            ref="clipper-modal"
            hide-header
            hide-footer
          >
            <clipper-fixed
              id="profile-clipper-img"
              :round="true"
              ref="clipper"
              :area="90"
              :src="tempClipperImg"
            ></clipper-fixed>
            <b-row style="margin: 0px" align-h="between">
              <b-button style="margin-top: 10px" @click="closeDialog">{{
                $t("base.basic.cancel")
              }}</b-button>
              <b-button
                variant="warning"
                style="margin-top: 10px"
                @click="getUserPictureResult"
                >{{ $t("base.basic.select") }}</b-button
              >
            </b-row>
          </b-modal>
        </div>
        <!-- Kommunikációs csatorna -->
        <b-row style="margin-top: 20px" align-h="center" align-v="center">
          <label style="margin: 10px 10px"
            >{{ $t("client.profile.personalData.communication") }}:</label
          >
          <b-row align-h="center">
            <!-- Még hiányzik -->
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Email"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.email") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Push"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.push") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Sms"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.sms") }}</b-form-checkbox
            >
            <b-form-checkbox
              :disabled="disabled"
              v-model="personalProfile.NotificationSettings.Chat"
              style="margin: 5px"
              >{{ $t("client.profile.personalData.chat") }}</b-form-checkbox
            >
          </b-row>
        </b-row>
        <b-row cols="1" cols-lg="2">
          <!-- Felhasználónév -->
          <div v-if="username" class="client-profile-inputs">
            <fp-input
              :disabled="true"
              :label="$t('client.profile.personalData.username')"
              :value="username"
            />
          </div>
          <!-- Név -->
          <div class="client-profile-inputs">
            <fp-input
              :state="!validation.hasError('tempValue.Name')"
              :error="validation.firstError('tempValue.Name')"
              :disabled="disabled"
              :label="$t('client.profile.personalData.name')"
              v-model="personalProfile.Name"
            />
          </div>
          <!-- Email -->
          <div class="client-profile-inputs">
            <fp-input
              :state="!validation.hasError('tempValue.PersonalContact.Email')"
              :error="validation.firstError('tempValue.PersonalContact.Email')"
              :disabled="disabled"
              type="email"
              :label="$t('client.profile.personalData.emailAddress')"
              v-model="personalProfile.PersonalContact.Email"
            />
          </div>
          <!-- Telefonszám -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('client.profile.personalData.phone')"
              v-model="personalProfile.PersonalContact.Phone"
            />
          </div>
          <div>
            <!-- Beosztás -->
            <div class="client-profile-inputs">
              <fp-input
                :disabled="disabled"
                :label="$t('partner.profile.position')"
                v-model="partnerProfile.Position"
              />
            </div>
          </div>
          <!-- Intézmény név -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('partner.profile.companyName')"
              v-model="partnerProfile.CompanyName"
            />
          </div>
          <!-- Osztály -->
          <div class="client-profile-inputs">
            <fp-input
              :disabled="disabled"
              :label="$t('partner.profile.departmentName')"
              v-model="partnerProfile.DepartmentName"
            />
          </div>
          <!-- Intézmény cím -->
          <div class="client-profile-inputs" style="margin-top: 10px">
            <fp-profile-address
              :disabled="disabled"
              v-model="partnerProfile.Address"
            />
          </div>
        </b-row>
      </b-row>
    </div>
    <div v-else>
      <b-spinner />
    </div>
  </div>
</template>
<script>
import ProfileAddress from "./ProfileAddress.vue";
export default {
  components: { "fp-profile-address": ProfileAddress },
  name: "PartnerProfile",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
    clipperImg: String,
    username: String,
  },
  data() {
    return {
      partnerProfile:
        this.value && this.value.PartnerProfile
          ? this.value.PartnerProfile
          : {
              Position: null,
              CompanyName: null,
              DepartmentName: null,
              Address: null,
            },
      defaultPartner: {
        Position: null,
        CompanyName: null,
        DepartmentName: null,
        Address: null,
      },
      personalProfile:
        this.value && this.value.PersonalProfile
          ? this.value.PersonalProfile
          : {
              NotificationSettings: {},
              PersonalContact: {},
            },
      defaultPersonal: {
        NotificationSettings: {},
        PersonalContact: {},
      },
      tempClipperImg: this.clipperImg
        ? this.clipperImg
        : require("@/assets/annonym-profil-icon.png"),
      showClipperModal: false,
    };
  },
  validators: {
    //TODO: adat modelben nullára kell állítani a validálandó mezőket (különben hiba lesz)
    "personalProfile.Name": function(value) {
      return this.$validator
        .value(value)
        .minLength(
          5,
          "A 'Név' mezőbe legalább 15 karakter hosszú szöveget kell megadni"
        );
    },
    "personalProfile.PersonalContact.Email": function(value) {
      return this.$validator
        .value(value)
        .email("Az 'Email cím' mező nem valid email címet tartalmaz");
    },
  },
  watch: {
    clipperImg(input) {
      this.tempClipperImg = input;
    },
    value(input) {
      this.partnerProfile =
        input && input.PartnerProfile
          ? input.PartnerProfile
          : JSON.parse(JSON.stringify(this.defaultPartner));
      this.personalProfile =
        input && input.PersonalProfile
          ? input.PersonalProfile
          : JSON.parse(JSON.stringify(this.defaultPersonal));
    },
    partnerProfile(input) {
      this.$emit("change", {
        PartnerProfile: input,
        PersonalProfile: this.personalProfile,
      });
    },
    personalProfile(input) {
      this.$emit("change", {
        PartnerProfile: this.partnerProfile,
        PersonalProfile: input,
      });
    },
  },
  methods: {
    choosePhoto() {
      this.showClipperModal = true;
      const inputElement = document.getElementById("profile-picture");
      inputElement.click();
      inputElement.addEventListener("change", this.handleFiles, false);
    },
    handleFiles(args) {
      const fileList = args.target.files;
      if (fileList) {
        this.tempClipperImg = window.URL.createObjectURL(fileList[0]);
      }
    },
    async getUserPictureResult() {
      this.profileImgCanvas = this.$refs.clipper.clip();
      const img = document.getElementById("profile-img");
      img.src = this.profileImgCanvas.toDataURL("image/jpeg", 1);
      this.$refs["clipper-modal"].hide();
      this.$emit("setProfilePicture", this.profileImgCanvas);
    },
    closeDialog() {
      const imgComp = document.getElementById("client-profil-icon");
      if (imgComp) {
        this.tempClipperImg = imgComp.src;
      }
      this.$refs["clipper-modal"].hide();
    },
  },
};
</script>
<style scoped>
.client-profile-inputs {
  padding: 0px 10px;
}
.disbaled-img-profile {
  cursor: default;
}
.img-profile {
  cursor: pointer;
}
</style>
