export const DeClient = {
  client: {
    badges: {
      availableBadges: "Lob möglich",
      recievedBadges: "Lob verdient",
    },
    diseaseGuide: {
      diseaseBNOCode: "ICD-G Code",
      diseaseBodyPart: "Kann an einem Körperteil befestigt werden:",
      diseaseBodyPartList: "Körperteil",
      diseaseDescription: "Beschreibung:",
      diseaseDescriptionPart: "Beschreibungstext",
      diseaseList: "Liste der Krankheiten",
      diseaseName: "Bezeichnung der Krankheit",
      diseaseSex: "Bezogen auf das Geschlecht:",
      diseaseSexList: "Geschlecht",
      filteredFields: "Filterbare Felder",
    },
    documents: {
      ehrDocuments: "EHR DOKUMENTE",
      otherDocuments: "ANDERE DOKUMENTE",
      phrDocuments: "PHR DOKUMENTE",
      errorName: "Dokument",
      documentType: "Art des Dokumentes",
    },
    functionSettings: {
      chartOrder: "Reihenfolge der Graphiken",
      charts: "Graphiken",
      chartsSettingFields: {
        displayName: "Name",
        enabled: "An",
        interval: "Intervall",
        size: "Größe",
      },
      functionFields: {
        key: "Name",
        value: "An",
      },
      functions: "Funktionen",
      moduleSettings: "{name} Moduleinstellungen",
    },
    healthGoals: {
      completed: "Vervollständigt",
      completedNumberOfDays: "Anzahl der vervollständigten Tage: {count}",
      completedRelatedNumberOfDays:
        "Anzahl der relevanten vervollständigten Tage: {count}",
      emptyMessage: "Noch kein Ziel anzuzeigen",
      goalDefaultType: "Wählen Sie die Art des neuen Ziels aus.",
      goalType: "Zielart",
      moduleType: "Art des Modul",
      startDateErrorMessage: "Das Datum muss das Format JJJJ-MM-TT aufweisen.",
      targetDateErrorMessage:
        "Das Zieldatum muss das Format JJJJ-MM-TT aufweisen, das nicht vor dem Startdatum liegen darf.",
      timeProgressPercentage: "Fortschritt im Zeitraum",
      dailyNumberOfDays:
        "Leistung für aufeinander folgende Tage Innerhalb eines Intervalls ",
      day: "{count} Tage",
      daysCount: "Anzahl der Tage",
      goalDaily: "Tagesziel",
      goalDailyValue: "Tagesziel",
      goalDailyValuePlaceholder: "Tagesmindestziel",
      goalInterval: "Zwischenziel",
      goalProperty: "Erreichen des Tageswertes",
      goalValue: "Zielwert",
      goalValuePlaceholder: "Geplanter Wert ",
      healthGoals: "{name} Gesundheitsziele",
      intervalMinDayCount:
        "Minimale Anzahl der komplett abgeschlossenen Tage innerhalb eines Intervalls",
      moduleDefaultType: "Wählen Sie das neue Zielmodul aus.",
      newGoal: "Fügen Sie das Gesundheitsziel {name} ein ",
      numberOfDaysRemaining: "Verbleibende Tage: {count}",
      startDate: "Anfangsdatum",
      status: "Status",
      targetDate: "Zieldatum",
      targetValue: "Zielwert",
      timestamp: "Hinzugefügt",
      title: "Gesundheitsziele",
      valueInNormalInterval: "Wert im normalen Bereich",
      valueProgressPercentage: "Fortschritt beim Zielwert",
      startValue: "Ursprünglicher Wert",
      startValuePlaceholder: "Ursprünglicher Wert",
    },
    home: {
      enabledModules: "Aktivierte Module",
      settingModules: "Modul bearbeiten",
    },
    menu: {
      badges: "Lobe",
      diseaseGuide: "Krankheitsleitfaden",
      notifications: "Benachrichtigungen",
      documents: "Dokumente",
      homeButton: "Persönliche Startseite",
      profileFillingRate: "Profilvervollständigung",
      profileMedicalData: "Medizinische Angaben:",
      profilePersonalData: "Angaben zur Person:",
      programs: "Meine Programme",
      questionnaires: "Fragebögen",
      serviceFinder: "Servicesuche",
      tasks: "Meine Aufgaben",
      profile: "Mein Profil",
      appointments: "Termine",
    },
    moduleSettings: {
      availableModules: "Verfügbare Module",
      enabledModules: "Aktivierte Module",
      title: "Modul bearbeiten",
    },
    profile: {
      changePassword: "Passwort ändern",
      medicalData: {
        name: "Name",
        note: "Bemerkung",
        result: "Ergebnis",
        administrationDate: "Datum der Einreichung",
        agentName: "Bezeichnung des Wirkstoffes",
        allergy: "Allergie",
        appearDate: "Datum der Ausstellung",
        cessationDate: "Enddatum ",
        diseaseFromList: "Wählen Sie eine Krankheit aus einer Liste aus.",
        medicineBySelf: "Rezeptfreie Medikamente",
        medicineFromList: "Wählen Sie ein Medikament aus einer Liste aus.",
        medicineName: "Bezeichnung des Medikamentes",
        package: "Pakungsgröße",
        procedureBySelf: "Verabreichung einer rezeptfreien Behandlung",
        stillLasts: "noch fortlaufend ",
        takeEndDate: "Datum der letzten Einnahme",
        takeStartDate: "Datum des Beginns der Einnahme",
        bloodType: "Blutgruppe",
        bnoCode: "ICD-G Code",
        chilhoodDisease: "Kinderkrankheiten",
        chronicDisease: "Chronische Krankheiten",
        dieseaseName: "Bezeichnung der Krankheit",
        disease: "Krankheit",
        diseaseBySelf: "Manuelle Eingabe der Krankheit",
        diseaseName: "Bezeichnung der Krankheit",
        dose: "Dosis",
        drugSensitivity: "Arzneimittelempfindlichkeit",
        endDate: "Datum Ende",
        exceptionDate: "Datum der Streichung",
        familyAnamnesis: "Familienanamnese",
        implant: "Implantate",
        insertionDate: "Einfügedatum",
        longtermMedication: "Langzeitmedikation",
        medicine: "Medikament",
        newAllergy: "Hinzufügen einer neuen Allergie",
        newDisease: "Hinzufügen einer neuen Krankheit",
        newImplant: "Hinzufügen eines neuen Implantats",
        newMedicine: "Hinzufügen eines neuen Arzneimittels",
        newPregnancy: "Hinzufügen einer neuen Schwangerschaft",
        newPreviousProcedure: "Hinzufügen einer neuen Behandlung",
        newSensitivity: "Zugabe eines neuen Wirkstoffs",
        newVaccination: "Hinzufügen eines neuen Impfstoffs",
        noData: "Keine Daten verfügbar",
        oenoCode: "Behandlungscode",
        outcome: "Ergebnis",
        perceptionDate: "Datum der Feststellung",
        physicalActivity: "Tägliche körperliche Aktivität",
        pregnancy: "Schwangerschaft",
        previousDisease: "Vorerkrankungen",
        previousProcedure: "Vorherige Behandlungen",
        procedure: "Behandlung",
        procedureFromList: "Wählen Sie eine Behandlung aus einer Liste",
        procedureName: "Bezeichnung der Behandlung",
        relationshipType: "Verwandtschaftsgrad",
        sensitivity: "Aktive Substanz",
        sensitivityBySelf: "Manuelle Wirkstoffgabe",
        sensitivityFromList: "Auswahl eines Wirkstoffs aus einer Liste",
        sensitivityName: "Name des Wirkstoffs",
        skinType: "Hauttyp",
        startDate: "Datum Beginn",
        stillTaking: "Nimm immer noch",
        title: "Gesundheitsangaben",
        vaccination: "Impfungen",
      },
      personalData: {
        address: "Adressangabe",
        sex: "Geschlecht",
        birthday: "Geburtsdatum",
        birthplace: "Geburtsort",
        chat: "Chat",
        communication: "Kommunikation",
        email: "E-Mail",
        emailAddress: "E-Mail-Adresse",
        emergencyPerson: "Notfallkontakt",
        idCard: "Ausweisnummer",
        maritalStatus: "Familienstand",
        mother: "Name der Mutter",
        name: "Name",
        nationality: "Nationalität",
        phone: "Telefonnummer",
        push: "Push-Nachricht",
        relationshipType: "Beziehung",
        sms: "SMS",
        ssn: "Sozialversicherungsnummer",
        title: "Angaben zur Person",
        username: "Benutzername",
      },
      errorName: "Profil",
    },
    programs: {
      carePrograms: "Pflegeprogramme",
      healthPrograms: "Gesundheitsprogramme",
      period: "Zeitraum",
      projectName: "Projektname",
      projectTitle: "Projekttitel",
      state: "Status",
      taskList: "Liste der Erwartungen",
      close: "Schließen",
      details: "Einzelheiten",
      errorCareName: "Pflegeprogramm",
      projectGoal: "Projektziel",
      projectCriteria: "Auswahlkriterium",
      description: "Beschreibung",
      errorHealthName: "Gesundheitsprogramm",
    },
    questionnaires: {
      description: "Beschreibung",
      questionnaire: {
        longDescription: "Langfristige Auswertung",
        note: "Bemerkung",
        score: "Ergebnis",
        shortDescription: "Kurzfristige Auswertung",
        title: "Fragebögen",
        date: "Datum der Auswertung",
      },
      percentage: "Progress",
      startDateTime: "Datum hinzugefügt",
      status: "Status",
      title: "Titel",
      patient: "Patientendaten",
      errorName: "Fragebögen",
      requiredFieldsNotification:
        "Um den Fragebögen abzusenden, ist es erforderlich, die mit einem Stern gekennzeichneten Felder auszufülle.",
      stopDateTime: "Datum der Ausfüllung",
    },
    serviceFinder: {
      city: "Stadt",
      hospital: "Klinik",
      infoEmail: "E-Mail: ",
      infoName: "Name: ",
      infoOpeningHours: "Öffnungszeiten: ",
      infoPhone: "Telefon: ",
      information: "Information",
      infoWard: "Klasse: ",
      infoWebsite: "Website: ",
      pharmacy: "Apotheke",
      serviceType: "Art der Dienstleistung",
      ward: "Klasse",
      district: "Bezirk ",
      infoAddress: "Adresse",
    },
    task: {
      errorName: "Aufgabe",
    },
    appointment: {
      title: "Titel",
      description: "Beschreibung",
      startTime: "Anfangszeit",
      endTime: "Einsendeschluss",
      location: "Ort",
      filterDescription: "Suche nach Titel, Beschreibung.",
      date: "Datum",
      inProgress: "In Bearbeitung",
      cancellation: "Termin absagen",
      errorName: "Datum",
      own: "Meine Termine",
      free: "Freie Termine",
    },
    notification: {
      noti: "Benachrichtigungen",
      invitation: "Einladungen",
      requestedBy: "Absender",
      parameters: "Parameter",
      status: "Status",
      errorInvitationName: "Einladung",
    },
  },
};
