//Project
import ClinComSys from "../License/ClinComSys";
import ClinProSys from "../License/ClinProSys";
import ClinRecSys from "../License/ClinRecSys";
import ClinRegSys from "../License/ClinRegSys";
import ClinResSys from "../License/ClinResSys";
import ClinSurvSys from "../License/ClinSurvSys";
import ClinTriSys from "../License/ClinTriSys";
import ClinWorkFlowSys from "../License/ClinWorkFlowSys";

//Module
import Research from "../Modules/Research";
import Document from "../Modules/Document";
import Appointment from "../Modules/Appointment";
import Task from "../Modules/Task";
import Form from "../Modules/Form";
import Workflow from "../Modules/Workflow";
import Tag from "../Modules/Tag";
import Registration from "../Modules/Registration";

export default {
  install(Vue) {
    //Modul use
    Vue.use(Task);
    Vue.use(Form);
    Vue.use(Document);
    Vue.use(Appointment);
    Vue.use(Research);
    Vue.use(Workflow);
    Vue.use(Tag);
    Vue.use(Registration);
    //Project use
    Vue.use(ClinComSys);
    Vue.use(ClinProSys);
    Vue.use(ClinRecSys);
    Vue.use(ClinRegSys);
    Vue.use(ClinResSys);
    Vue.use(ClinSurvSys);
    Vue.use(ClinTriSys);
    Vue.use(ClinWorkFlowSys);

    //Beteglista betöltés
    Vue.prototype.$patientListLoadType =
      Vue.prototype.$enums.SystemParameters.ParticipantListLoadType.JustRelationship;

    Vue.prototype.$productSettings = {
      defaultLang: "hu",
      hasLangSwitch: false,
      hasRegistration: false,
      specialist: {
        participantListLoadType:
          Vue.prototype.$enums.SystemParameters.ParticipantListLoadType
            .JustRelationship.Value,
        canCreateProjectRelation: true,
        canRemoveProjectRelation: true,
        canAddParticipantToProject: true,
        canRemoveParticipantFromProject: true,
        canCreateParticipant: true,
        canDeleteParticipant: true,
        hasParticipantTag: true,
        canCreateTag: true,
        managedTagType:
          Vue.prototype.$enums.SystemParameters.TagManagedType.Both.Value,
        participantsBaseRoles: ["Patient"],
        participantShownDataKeys: ["Username", "Name", "Ssn"],
      },
      partner: {
        participantListLoadType:
          Vue.prototype.$enums.SystemParameters.ParticipantListLoadType
            .JustRelationship.Value,
        canCreateProjectRelation: true,
        canRemoveProjectRelation: true,
        canAddParticipantToProject: true,
        canRemoveParticipantFromProject: true,
        canCreateParticipant: true,
        canDeleteParticipant: true,
        hasParticipantTag: true,
        canCreateTag: false,
        managedTagType:
          Vue.prototype.$enums.SystemParameters.TagManagedType.Both.Value,
        participantsBaseRoles: ["Manager", "Doctor"],
        participantShownDataKeys: [
          "Name",
          "RoleName",
          "StampNumber",
          "InstitutionName",
        ],
      },
    };
  },
};
