<template>
  <div v-if="!params.Hidden">
    <div>
      <component
        style="padding: 0px"
        :is="params.VariableType"
        :params="params"
        v-model="tempValue"
        :disabled="disabled"
        :language="language"
      />
      <b-row class="score-inputs" v-if="scoreable && params.Score">
        <fp-input
          class="scoreInput"
          placeholder="pont"
          v-model="tempScore"
          type="number"
          :state="tempScore <= params.Score && tempScore >= 0"
        />
        <div class="scoreMaxLabel">/ {{ params.Score }} pont</div>
      </b-row>
    </div>
  </div>
</template>
<script>
import Integer from "@/components/form-schema/input-components/input/Integer";
import StringComponent from "@/components/form-schema/input-components/input/String";
import Float from "@/components/form-schema/input-components/input/Float";
export default {
  components: { Integer, String: StringComponent, Float },
  name: "InputComponent",
  data() {
    return {
      tempValue: this.value,
      tempScore: this.evaluateObjectModel,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [String, Number],
    disabled: Boolean,
    scoreable: Boolean,
    evaluateObjectModel: [String, Number],
    language: String,
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
    tempScore(input) {
      this.$emit("evaluating", Number.parseInt(input));
    },
    evaluateObjectModel(input) {
      this.tempScore = input;
    },
  },
};
</script>
<style scoped>
.score-inputs {
  margin-top: -20px;
}
.scoreInput {
  width: fit-content;
}
.scoreMaxLabel {
  margin-top: 30px;
  padding-left: 10px;
}
</style>