<template>
  <div>
    <b-row style="margin: 0px" cols="1" cols-lg="2" align-h="center">
      <!-- Napi fizikai aktivitás -->
      <fp-select
        class="column-padding"
        :label="$t('client.profile.medicalData.physicalActivity')"
        :disabled="disabled"
        :items="Object.values(physicalActivities)"
        valueKey="Value"
        textKey="Text"
        :value="tempValue.PhysicalActivity"
        @change="changeValue('PhysicalActivity', $event)"
      >
        <template #element="element">
          {{ $t(element.Text) }}
        </template>
      </fp-select>
      <!-- Vércsoport -->
      <fp-select
        class="column-padding"
        :label="$t('client.profile.medicalData.bloodType')"
        :disabled="disabled"
        :items="Object.values(bloodTypes)"
        valueKey="Value"
        textKey="Text"
        :value="tempValue.BloodType"
        @change="changeValue('BloodType', $event)"
      >
        <template #element="element">
          {{ $t(element.Text) }}
        </template>
      </fp-select>
      <!-- Bőrtípus -->
      <fp-select
        class="column-padding"
        :label="$t('client.profile.medicalData.skinType')"
        :disabled="disabled"
        :items="Object.values(skinTypes)"
        valueKey="Value"
        textKey="Text"
        :value="tempValue.SkinType"
        @change="changeValue('SkinType', $event)"
      >
        <template #element="element">
          {{ $t(element.Text) }}
        </template>
      </fp-select>
      <!-- Várandósság -->
      <pregnancy :disabled="disabled" v-model="tempValue.Pregnancy" />
      <!-- Krónikus megbetegedés -->
      <disease-diagnosis
        :label="$t('client.profile.medicalData.chronicDisease')"
        :disabled="disabled"
        v-model="tempValue.ChronicDisease"
      />
      <!-- Allergia -->
      <allergy :disabled="disabled" v-model="tempValue.Allergy" />
      <!-- Állandóan szedett gyógyszer -->
      <longterm-medication
        :disabled="disabled"
        v-model="tempValue.LongtermMedication"
      />
      <!-- Gyógyszer érzékenység -->
      <drug-sensitivity
        :disabled="disabled"
        v-model="tempValue.DrugSensitivity"
      />
      <!-- Implantátum -->
      <implant :disabled="disabled" v-model="tempValue.Implant" />
      <!-- Gyermekkori betegségek -->
      <disease-diagnosis
        :label="$t('client.profile.medicalData.chilhoodDisease')"
        :disabled="disabled"
        v-model="tempValue.ChilhoodDisease"
      />
      <!-- Korábbi betegségek -->
      <disease-diagnosis
        :label="$t('client.profile.medicalData.previousDisease')"
        :disabled="disabled"
        v-model="tempValue.PreviousDisease"
      />
      <!-- Védőoltások -->
      <vaccination :disabled="disabled" v-model="tempValue.Vaccination" />
      <!-- Korábbi kezelések -->
      <previous-procedure
        :disabled="disabled"
        v-model="tempValue.PreviousProcedure"
      />
      <!-- Családi anamnézis -->
      <family-anamnesis
        :disabled="disabled"
        v-model="tempValue.FamilyAnamnesis"
      />
    </b-row>
  </div>
</template>
<script>
import Allergy from "./medical-data/Allergy.vue";
import DiseaseDiagnosis from "./medical-data/DiseaseDiagnosis.vue";
import DrugSensitivity from "./medical-data/DrugSensitivity.vue";
import FamilyAnamnesis from "./medical-data/FamilyAnamnesis.vue";
import Implant from "./medical-data/Implant.vue";
import LongtermMedication from "./medical-data/LongtermMedication.vue";
import Pregnancy from "./medical-data/Pregnancy.vue";
import PreviousProcedure from "./medical-data/PreviousProcedure.vue";
import Vaccination from "./medical-data/Vaccination.vue";

export default {
  components: {
    Pregnancy,
    DiseaseDiagnosis,
    DrugSensitivity,
    Vaccination,
    FamilyAnamnesis,
    Implant,
    LongtermMedication,
    Allergy,
    PreviousProcedure,
  },
  name: "ClientMedicalProfile",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      tempValue: this.value,
      bloodTypes: this.$enums.BloodType,
      skinTypes: this.$enums.SkinType,
      physicalActivities: this.$enums.PhysicalActivity,
    };
  },
  watch: {
    value(input) {
      this.tempValue = input;
    },
  },
  methods: {
    changeValue(value, input) {
      this.$set(this.tempValue, value, input);
      this.$emit("change", this.tempValue);
    },
  },
};
</script>