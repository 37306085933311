import { ClientService, RequestMethod } from '../client-service'
import { RequestResponseLogic } from '../Ui/request-response'

const APPOINTMENT = '/appointment'
const APPOINTMENT_HEADER = '/appointment/header'
const VISITOR = 'visitor'
const CLEAR = 'clear'
const SET_PRESENT = 'set_present'

export const AppointmentLogic = {
    async createAppointment(body, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.POST,
            APPOINTMENT, params, body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getAppointments(params) {
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            APPOINTMENT, params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getAppointmentHeaders(params) {
        let result = await ClientService.sendRequest(
            RequestMethod.GET,
            APPOINTMENT_HEADER, params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async setAppointment(appointmentId, body, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.PATCH,
            APPOINTMENT + '/' + appointmentId, params, body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteAppointment(appointmentId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.DELETE,
            APPOINTMENT + '/' + appointmentId, params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async addVisitorToAppointment(appointmentId, body, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.POST,
            APPOINTMENT + '/' + appointmentId + '/' + VISITOR,
            params, body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteVisitorFromAppointment(appointmentId, body, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.DELETE,
            APPOINTMENT + '/' + appointmentId + '/' + VISITOR,
            params, body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async clearAppointment(appointmentId, params) {
        let result = await ClientService.sendRequest(
            RequestMethod.POST,
            APPOINTMENT + '/' + appointmentId + '/' + CLEAR,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async setPresentAppointment(appointmentId, body, params) {
        let result = await ClientService.sendRequestWithBody(
            RequestMethod.POST,
            APPOINTMENT + '/' + appointmentId + '/' + SET_PRESENT,
            params, body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
}