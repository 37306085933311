<template>
  <fp-form-modal
    :title="
      $t(
        'healthProfessional.patientManager.userInvitation.registedUserInvitation'
      )
    "
    v-model="tempValue"
  >
    <template #content>
      <fp-select
        :label="$t('healthProfessional.patientManager.userInvitation.user')"
        v-model="selectedUserId"
        :searchable="true"
        @search="searchUser"
        :items="userList"
        textKey="Username"
        valueKey="UserId"
      ></fp-select>
      <b-button @click="addInvitation">{{ $t("base.basic.save") }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { UserLogic } from "@/logic/backend/user";
import { InvitationLogic } from "@/logic/backend/invitation";
export default {
  name: "UserInvitation",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      tempValue: this.value,
      userList: null,
      selectedUserId: null,
    };
  },
  watch: {
    value(input) {
      this.tempValue = input;
    },
    tempValue(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    async addInvitation() {
      const body = {
        Type: "Patient",
        RequestedById: this.$loggedUser.UserId,
        TargetId: this.selectedUserId,
        Parameters: { RelationshipType: "Project" },
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      };
      const addInvitationResponse = await InvitationLogic.createInvitation(
        body
      );
      if (!addInvitationResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.invitation.successSend"), {
          title: this.$t("requestResponse.invitation.successSendTitle"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(addInvitationResponse.Message, {
          title: this.$t("requestResponse.invitation.errorSend"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.$emit("refreshList");
      this.tempValue = false;
    },
    async searchUser(input) {
      if (input) {
        if (input.length > 2) {
          await this.getUsers({ "Username-contains": input });
        } else {
          this.userList = [];
        }
      } else {
        await this.getUsers({ "-limit": 10 });
      }
    },
    async getUsers(params) {
      const getUsersResponse = await UserLogic.getUser(params);
      if (!getUsersResponse.Code) {
        this.userList = getUsersResponse;
      } else {
        this.$bvToast.toast(getUsersResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
              "healthProfessional.patientManager.userInvitation.errorName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getUsers({ "-limit": 10 });
  },
};
</script>
