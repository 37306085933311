<template>
  <!-- TODO mező nevek!! -->
  <div v-if="financialInfo">
    <fp-input
      label="Termék bruttó értéke"
      :required="true"
      v-model="financialInfo.TotalPrice"
      @change="changeValue('TotalPrice', $event)"
      :disabled="disabled || financialInfoDisabled"
      :state="!validation.hasError('financialInfo.TotalPrice')"
      :error="validation.firstError('financialInfo.TotalPrice')"
    />
    <fp-input
      label="Bruttó támogatás értéke"
      :required="true"
      v-model="financialInfo.Subsidization"
      @change="changeValue('Subsidization', $event)"
      :disabled="disabled || financialInfoDisabled"
      :state="!validation.hasError('financialInfo.Subsidization')"
      :error="validation.firstError('financialInfo.Subsidization')"
    />
    <fp-input
      label="Beteg által fizetett bruttó térítési díj"
      :required="true"
      v-model="financialInfo.ChargeFee"
      @change="changeValue('ChargeFee', $event)"
      :disabled="disabled || financialInfoDisabled"
      :state="!validation.hasError('financialInfo.ChargeFee')"
      :error="validation.firstError('financialInfo.ChargeFee')"
    />
    <fp-input
      label="Kvázi térítési díj bruttó összege"
      :required="true"
      v-model="financialInfo.QuasiChargeFee"
      @change="changeValue('QuasiChargeFee', $event)"
      :disabled="disabled || financialInfoDisabled"
      :state="!validation.hasError('financialInfo.QuasiChargeFee')"
      :error="validation.firstError('financialInfo.QuasiChargeFee')"
    />
  </div>
</template>
<script>
export default {
  name: "financialInfo",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
    subsidizationFinancialInfo: Object,
  },
  data() {
    return {
      financialInfo: null,
      financialInfoDisabled: false,
      changeFinancialInfoValue: {},
      defaultFinancialInfo: {
        SubsidizationCategoryId: null,
        TotalPrice: null,
        Subsidization: null,
        ChargeFee: null,
        QuasiChargeFee: null,
      },
    };
  },
  watch: {
    value() {
      this.setDefaultFinancialInfo(true);
    },
    subsidizationFinancialInfo(input) {
      this.selectSubsidization(input);
    },
  },
  validators: {
    "financialInfo.TotalPrice": function (value) {
      if (this.hasFinancialInfoData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "financialInfo.Subsidization": function (value) {
      if (this.hasFinancialInfoData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "financialInfo.ChargeFee": function (value) {
      if (this.hasFinancialInfoData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "financialInfo.QuasiChargeFee": function (value) {
      if (this.hasFinancialInfoData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
  },
  computed: {
    hasFinancialInfoData() {
      return Object.values(this.financialInfo).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    selectSubsidization(subCatFinancialInfo) {
      if (subCatFinancialInfo) {
        this.$set(
          this.financialInfo,
          "TotalPrice",
          subCatFinancialInfo.TotalPrice
        );
        this.$set(
          this.financialInfo,
          "Subsidization",
          subCatFinancialInfo.Subsidization
        );
        this.$set(
          this.financialInfo,
          "ChargeFee",
          subCatFinancialInfo.ChargeFee
        );
        this.$set(
          this.financialInfo,
          "QuasiChargeFee",
          subCatFinancialInfo.QuasiChargeFee
        );
        this.financialInfoDisabled = true;
      } else {
        this.financialInfoDisabled = false;
        this.$set(this.financialInfo, "TotalPrice", null);
        this.$set(this.financialInfo, "Subsidization", null);
        this.$set(this.financialInfo, "ChargeFee", null);
        this.$set(this.financialInfo, "QuasiChargeFee", null);
      }
    },
    setDefaultFinancialInfo(hasChangeValue) {
      if (this.value) {
        this.financialInfo = this.value;
        console.log(this.subsidizationFinancialInfo);
        this.financialInfoDisabled = !!this.subsidizationFinancialInfo;
      } else {
        this.financialInfo = JSON.parse(
          JSON.stringify(this.defaultFinancialInfo)
        );
      }
      if (!hasChangeValue) {
        this.changeFinancialInfoValue = null;
        this.resetValidation();
      }
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
    changeValue(key, value) {
      if (this.hasFinancialInfoData) {
        if (!this.changeFinancialInfoValue) {
          this.changeFinancialInfoValue = {};
        }
        this.changeFinancialInfoValue[key] = value;
        this.$emit("change", this.financialInfo);
        this.$emit("changeValue", this.changeFinancialInfoValue);
      } else {
        this.changeFinancialInfoValue = null;
        this.$emit("change", null);
        this.$emit("changeValue", null);
      }
      this.resetValidation();
    },
  },
  async mounted() {
    this.setDefaultFinancialInfo();
  },
};
</script>
