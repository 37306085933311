<template>
  <div v-if="!params.Hidden">
    <fp-panel-card
      :title="params.Title ? params.Title[language] : null"
      :description="params.Description ? params.Description[language] : null"
    >
      <template #content>
        <b-row cols="1" cols-sm="2" cols-md="3" cols-lg="5">
          <fp-input
            style="padding: 0px 10px"
            :disabled="disabled || !params.Enabled"
            :label="$t('base.basic.value')"
            v-model="tempValue.Value"
          />
          <fp-input
            style="padding: 0px 10px"
            :disabled="disabled || !params.Enabled"
            :label="$t('base.basic.unit')"
            v-model="tempValue.Unit"
          />
          <fp-input
            style="padding: 0px 10px"
            :disabled="disabled || !params.Enabled"
            :label="$t('components.labor.refMin')"
            v-model="tempValue.ReferenceRangeMin"
          />
          <fp-input
            style="padding: 0px 10px"
            :disabled="disabled || !params.Enabled"
            :label="$t('components.labor.refMax')"
            v-model="tempValue.ReferenceRangeMax"
          />
          <fp-input
            style="padding: 0px 10px"
            :disabled="disabled || !params.Enabled"
            :label="$t('components.labor.loinc')"
            v-model="tempValue.LoincCode"
          />
        </b-row>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
export default {
  name: "LaboratoryComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: Object,
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value
        ? this.value
        : {
            Value: null,
            Unit: null,
            ReferenceRangeMin: null,
            ReferenceRangeMax: null,
            LoincCode: null,
          },
    };
  },
  watch: {
    "value.Value": function(input) {
      this.tempValue.Value = input;
    },
    "value.Unit": function(input) {
      this.tempValue.Unit = input;
    },
    "value.ReferenceRangeMin": function(input) {
      this.tempValue.ReferenceRangeMin = input;
    },
    "value.ReferenceRangeMax": function(input) {
      this.tempValue.ReferenceRangeMax = input;
    },
    "value.LoincCode": function(input) {
      this.tempValue.LoincCode = input;
    },
    "tempValue.Value": function() {
      this.$emit("change", this.tempValue);
    },
    "tempValue.Unit": function() {
      this.$emit("change", this.tempValue);
    },
    "tempValue.ReferenceRangeMin": function() {
      this.$emit("change", this.tempValue);
    },
    "tempValue.ReferenceRangeMax": function() {
      this.$emit("change", this.tempValue);
    },
    "tempValue.LoincCode": function() {
      this.$emit("change", this.tempValue);
    },
  },
  mounted() {
    this.$emit("change", this.tempValue);
  },
};
</script>
