<template>
  <div class="page-background">
    <div v-if="module" class="body-width">
      <!-- <b-button style="background:orange;border:none;margin:20px;" @click="$router.go(-1)">Vissza</b-button> -->
      <button
        class="eh-action-button"
        style="margin: 20px 0px"
        @click="$router.go(-1)"
      >
        {{ $t("base.basic.back") }}
      </button>
      <p class="diary-title">
        {{
          $t("client.healthGoals.newGoal", {
            name: $t("module.modules." + module.Name),
          })
        }}
      </p>
      <b-row class="plan-form" align-h="center" cols="1">
        <fp-select
          :label="$t('client.healthGoals.goalType')"
          :placeholder="$t('client.healthGoals.goalDefaultType')"
          :items="goalTypes"
          valueKey="Value"
          textKey="Text"
          v-model="newPlan.PlanType"
        />
        <div v-if="newPlan.PlanType">
          <fp-select
            :label="$t('client.healthGoals.moduleType')"
            :placeholder="$t('client.healthGoals.moduleDefaultType')"
            :items="module.HealthPlans[newPlan.PlanType]"
            v-model="newPlan.TypeObject"
            textKey="Name"
            :required="true"
            :state="!validation.hasError('newPlan.TypeObject')"
            :error="validation.firstError('newPlan.TypeObject')"
          >
            <template #element="plan">
              {{
                $t("module.plans." + newPlan.PlanType + "." + plan.Name)
              }}</template
            >
          </fp-select>
          <fp-input
            v-if="module.Name == 'BodyWeight'"
            :placeholder="$t('client.healthGoals.startValuePlaceholder')"
            :label="$t('client.healthGoals.startValue')"
            v-model="newPlan.StartValue"
            :state="!validation.hasError('newPlan.StartValue')"
            :error="validation.firstError('newPlan.StartValue')"
          />
          <fp-input
            v-if="newPlan.PlanType == $enums.HealthGoalType.property"
            type="number"
            :required="true"
            :placeholder="$t('client.healthGoals.goalValuePlaceholder')"
            :label="$t('client.healthGoals.goalValue')"
            v-model="newPlan.TargetValue"
            :state="!validation.hasError('newPlan.TargetValue')"
            :error="validation.firstError('newPlan.TargetValue')"
          />
          <fp-input
            v-else-if="checkTargetNormal()"
            :disabled="true"
            :placeholder="$t('client.healthGoals.valueInNormalInterval')"
            :label="$t('client.healthGoals.goalDailyValue')"
          />
          <fp-input
            v-else
            type="number"
            :required="true"
            :placeholder="$t('client.healthGoals.goalDailyValuePlaceholder')"
            :label="$t('client.healthGoals.goalDailyValue')"
            v-model="newPlan.TargetValue"
            :state="!validation.hasError('newPlan.TargetValue')"
            :error="validation.firstError('newPlan.TargetValue')"
          />
          <fp-date-picker
            :required="true"
            :label="$t('client.healthGoals.startDate')"
            v-model="newPlan.StartDate"
            :state="!validation.hasError('newPlan.StartDate')"
            :error="validation.firstError('newPlan.StartDate')"
          />
          <fp-date-picker
            :required="true"
            :label="$t('client.healthGoals.targetDate')"
            v-model="newPlan.TargetDate"
            :state="!validation.hasError('newPlan.TargetDate')"
            :error="validation.firstError('newPlan.TargetDate')"
          />
          <fp-input
            v-if="newPlan.PlanType == $enums.HealthGoalType.interval"
            type="number"
            :required="true"
            :label="$t('client.healthGoals.daysCount')"
            :description="$t('client.healthGoals.intervalMinDayCount')"
            v-model="newPlan.MinDayCount"
            :state="!validation.hasError('newPlan.MinDayCount')"
            :error="validation.firstError('newPlan.MinDayCount')"
          />
          <fp-input
            v-if="newPlan.PlanType == $enums.HealthGoalType.daily"
            type="number"
            :required="true"
            :label="$t('client.healthGoals.daysCount')"
            :description="$t('client.healthGoals.dailyNumberOfDays')"
            v-model="newPlan.NumberOfDays"
            :state="!validation.hasError('newPlan.NumberOfDays')"
            :error="validation.firstError('newPlan.NumberOfDays')"
          />
          <b-button style="margin-top: 10px" @click="createNewPlan">{{
            $t("base.basic.add")
          }}</b-button>
        </div>
      </b-row>
      <p class="diary-title">
        {{
          $t("client.healthGoals.healthGoals", {
            name: $t("module.modules." + module.Name),
          })
        }}
      </p>
      <div>
        <fp-tabs
          class="eh-main-tab"
          v-if="Object.keys(module.HealthPlans).length > 1"
        >
          <fp-tab
            v-for="(value, key, index) in module.HealthPlans"
            :key="index"
            :title="$t('module.plans.types.' + key)"
            @click="selectPlanTab(value, key)"
          >
            <!-- Ha több egészségcél típusa és több egészségcélja van a modulnak -->
            <fp-tabs
              class="eh-sub-tab"
              v-if="
                module.HealthPlans[Object.keys(module.HealthPlans)[0]].length >
                  1
              "
            >
              <fp-tab
                @click="
                  activeTabs[key] = v;
                  getPlansByPlanType(v.Value, key);
                "
                :title="$t('module.plans.' + key + '.' + v.Name)"
                v-for="(v, index) in value"
                :key="index + '-' + v"
              >
                <HealthGoalTable
                  :items="items"
                  :planType="key"
                  @delete-item="deleteHealthGoal"
                />
              </fp-tab>
            </fp-tabs>
            <!-- Ha több egészségcél típsa, de azokban csak egy egészségcélja van a modulnak -->
            <fp-tabs class="eh-sub-tab" v-else>
              <fp-tab
                :title="
                  $t(
                    'module.plans.' +
                      key +
                      '.' +
                      module.HealthPlans[key][0].Name
                  )
                "
              >
                <HealthGoalTable
                  :items="items"
                  :planType="key"
                  @delete-item="deleteHealthGoal"
                />
              </fp-tab>
            </fp-tabs>
          </fp-tab>
        </fp-tabs>
        <div v-else>
          <!-- Ha csak egy egészségcél típusa, de több egészcégcélja van a modulnak -->
          <fp-tabs
            v-if="
              module.HealthPlans[Object.keys(module.HealthPlans)[0]].length > 1
            "
          >
            <fp-tab
              v-for="(value, index) in module.HealthPlans[
                Object.keys(module.HealthPlans)[0]
              ]"
              :key="index + '-' + value"
              :title="
                $t(
                  'module.plans.' +
                    Object.keys(module.HealthPlans)[0] +
                    '.' +
                    value.Name
                )
              "
              @click="
                getPlansByPlanType(
                  value.Value,
                  Object.keys(module.HealthPlans)[0]
                )
              "
            >
              <HealthGoalTable
                :items="items"
                :planType="Object.keys(module.HealthPlans)[0]"
                @delete-item="deleteHealthGoal"
              />
            </fp-tab>
          </fp-tabs>
          <!-- Ha csak egyetlen egészségcélja van a mondulnak -->
          <fp-tabs v-else>
            <fp-tab
              :title="
                $t(
                  'module.plans.' +
                    Object.keys(module.HealthPlans)[0] +
                    '.' +
                    module.HealthPlans[Object.keys(module.HealthPlans)[0]][0]
                      .Name
                )
              "
            >
              <HealthGoalTable
                :items="items"
                :planType="Object.keys(module.HealthPlans)[0]"
                @delete-item="deleteHealthGoal"
              />
            </fp-tab>
          </fp-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { HealthPlanLogic } from "@/logic/backend/health-plan.js";
import { UserPreferencesLogic } from "@/logic/backend/user-preferences";
import HealthGoalTable from "@/components/table/HealthGoalTable.vue";

export default {
  name: "HealthGoal",
  components: {
    HealthGoalTable,
  },
  data() {
    return {
      /* fields: [
        {
          key: "TargetValue",
          label: this.$t("client.healthGoals.table.fields.targetValue"),
        },
        {
          key: "ValueProgressPercentage",
          label: this.$t(
            "client.healthGoals.table.fields.valueProgressPercentage"
          ),
          sortable: true,
        },
        {
          key: "TargetDate",
          label: "Kitűzőtt dátum",
        },
        {
          key: "TimeProgressPercentage",
          label: this.$t(
            "client.healthGoals.table.fields.timeProgressPercentage"
          ),
        },
        {
          key: "Status",
          label: this.$t("client.healthGoals.table.fields.status"),
        },
        {
          key: "Timestamp",
          label: this.$t("client.healthGoals.table.fields.timestamp"),
          sortable: true,
        },
      ], */
      items: null,
      newPlan: {
        PlanType: null,
        TypeObject: null,
        StartDate: null,
        TargetDate: null,
        TargetValue: null,
        //property (body weight)
        StartValue: 0,
        //interval
        MinDayCount: null,
        //daily
        NumberOfDays: null,
      },
      planTypes: {
        [this.$enums.HealthGoalType.interval]: this.$t(
          "client.healthGoals.goalInterval"
        ),
        [this.$enums.HealthGoalType.daily]: this.$t(
          "client.healthGoals.goalDaily"
        ),
        [this.$enums.HealthGoalType.property]: this.$t(
          "client.healthGoals.goalProperty"
        ),
      },
      goalTypes: [],
      moduleType: null,
      selectedList: this.$enums.HealthGoalType.interval,
      module: null,
      activeTabs: {},
      activePlanTab: "",
      isValidInputs: {},
      moduleName: null,
    };
  },
  validators: {
    "newPlan.TypeObject": function(value) {
      return this.$validator.value(value).required();
    },
    "newPlan.StartValue": function(value) {
      const moduleName = this.module.Name;
      return this.$validator.custom(function() {
        if (moduleName == "BodyWeight") {
          if (value < 0) {
            return this.$t("validate.greaterThanZero");
          }
        }
      });
    },
    "newPlan.TargetValue": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newPlan.StartDate": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newPlan.TargetDate, newPlan.StartDate": function(end, start) {
      const validator = this.$validator;
      if (this.validation.isTouched("newPlan.TargetDate")) {
        return validator.custom(function() {
          if (!validator.isEmpty(start) && !validator.isEmpty(end)) {
            if (moment(start).isSameOrAfter(moment(end))) {
              return this.$t("validate.endDateNotBeforeEqualStart");
            }
          } else if (!validator.isEmpty(end)) {
            return this.$t("validate.requiredField");
          }
        });
      } else {
        return validator.custom(function() {
          if (validator.isEmpty(end)) {
            return this.$t("validate.requiredField");
          }
        });
      }
    },
    "newPlan.MinDayCount, newPlan.PlanType": function(value, type) {
      const validator = this.$validator;
      const validation = this.validation;
      return validator.custom(() => {
        if (type == this.$enums.HealthGoalType.interval) {
          if (validation.isPassed("newPlan.MinDayCount")) {
            if (validator.isEmpty(value)) {
              return this.$t("validate.requiredField");
            }
          } else {
            if (value < 0) {
              return this.$t("validate.greaterThanZero");
            }
          }
        }
      });
    },
    "newPlan.NumberOfDays, newPlan.PlanType": function(value, type) {
      const validator = this.$validator;
      return validator.custom(() => {
        if (type == this.$enums.HealthGoalType.daily) {
          if (validator.isEmpty(value)) {
            return this.$t("validate.requiredField");
          } else if (value < 0) {
            return this.$t("validate.greaterThanZero");
          }
        }
      });
    },
  },
  methods: {
    async selectPlanTab(value, key) {
      this.activePlanTab = key;
      this.activeTabs[key] = this.activeTabs[key]
        ? this.activeTabs[key]
        : value[0];
      await this.getPlansByPlanType(
        this.activeTabs[this.activePlanTab].Value,
        this.activePlanTab
      );
    },
    async createNewPlan() {
      this.convertMinDaysToPercentage();
      const valid = await this.$validate();
      //console.log(valid);
      //console.log(this.validation);
      if (valid) {
        //console.log(this.newPlan);
        this.newPlan.Type = this.newPlan.TypeObject.Value;
        const result = await HealthPlanLogic.setHealthPlan(
          this.newPlan.PlanType,
          [this.newPlan]
        );
        if (!result.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleHealthGoal.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.items = result;
          this.getPlansByPlanType(
            this.activeTabs[this.activePlanTab].Value,
            this.activePlanTab
          );
        } else {
          this.$bvToast.toast(result.Message, {
            title: this.$t("requestResponse.moduleHealthGoal.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    checkTargetNormal() {
      if (this.newPlan.TypeObject) {
        const selectedType = this.module.HealthPlans[this.selectedList].find(
          (x) => x.Value == this.newPlan.TypeObject.Value
        );
        if (selectedType) {
          if (selectedType.Normal) {
            this.newPlan.TargetValue = 0;
          }
          return selectedType.Normal;
        }
      }
      return false;
    },
    //interval
    convertMinDaysToPercentage() {
      this.newPlan.TargetPercentage =
        (this.newPlan.MinDayCount /
          (moment(this.newPlan.TargetDate).diff(
            moment(this.newPlan.StartDate),
            "days"
          ) +
            1)) *
        100;
    },
    convertPercentageToMinDays() {
      return (
        (moment(this.newPlan.TargetDate).diff(
          moment(this.newPlan.StartDate),
          "days"
        ) +
          1) *
        0.8
      );
    },
    selectPlanType(event) {
      this.newPlan = {
        PlanType: event,
        Type: null,
      };
      this.isValidInputs = {};
      this.activePlanTab = event;
      if (!this.activeTabs[event]) {
        this.activeTabs = { [event]: this.module.HealthPlans[event][0] };
      }
      this.getPlansByPlanType(
        this.activeTabs[this.activePlanTab].Value,
        this.activePlanTab
      );
    },
    changeSubType(event) {
      if (event != "null") {
        this.activeTabs[this.activePlanTab] = event;
        this.getPlansByPlanType(
          this.activeTabs[this.activePlanTab].Value,
          this.activePlanTab
        );
      }
    },
    async getPlansByPlanType(type, plan) {
      this.items = null;
      var getPlanResponse = null;
      if (plan == "all") {
        getPlanResponse = await HealthPlanLogic.getHealthPlanList(plan);
      } else {
        getPlanResponse = await HealthPlanLogic.getHealthPlanList(plan, {
          Type: type,
        });
      }
      if (!getPlanResponse.Code) {
        this.items = getPlanResponse;
      } else {
        this.$bvToast.toast(getPlanResponse.Message, {
          title: this.$t("requestResponse.moduleHealthGoal.errorGet", {
            name: this.$t("module.modules." + this.module.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async deleteHealthGoal(isDelete) {
      if (isDelete) {
        await this.getPlansByPlanType(
          this.activeTabs[this.activePlanTab].Value,
          this.activePlanTab
        );
      }
    },
    setGoalType() {
      const temp = {
        [this.$enums.HealthGoalType.interval]: this.module.HealthPlans.interval,
        [this.$enums.HealthGoalType.property]: this.module.HealthPlans.property,
        [this.$enums.HealthGoalType.daily]: this.module.HealthPlans.daily,
      };
      Object.keys(temp).forEach((key) => {
        if (temp[key]) {
          this.goalTypes.push({ Value: key, Text: this.planTypes[key] });
        }
      });
    },
    checkQuery() {
      this.moduleName = this.$route.query.module;
      if (!this.moduleName) {
        this.$router.push({ path: "Home" });
      }
    },
    async getModules() {
      this.checkQuery();
      const getUserPreferenceResponse = await UserPreferencesLogic.getUserPreferences();
      if (!getUserPreferenceResponse.Code) {
        this.module = getUserPreferenceResponse.Modules[this.moduleName];

        this.activeTabs = {
          [this.$enums.HealthGoalType.interval]: this.module.HealthPlans
            .interval
            ? this.module.HealthPlans.interval[0]
            : null,
          [this.$enums.HealthGoalType.daily]: this.module.HealthPlans.daily
            ? this.module.HealthPlans.daily[0]
            : null,
          [this.$enums.HealthGoalType.property]: this.module.HealthPlans
            .property
            ? this.module.HealthPlans.property[0]
            : null,
        };
        this.activePlanTab = Object.keys(this.module.HealthPlans)[0];
        await this.getPlansByPlanType(
          this.activeTabs[this.activePlanTab].Value,
          this.activePlanTab
        );
        this.setGoalType();
      } else {
        this.$bvToast.toast(getUserPreferenceResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.modules." + this.module.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.setHeaderbar();
    },
    setHeaderbar() {
      if (this.module) {
        this.$emit(
          "title-changed",
          this.$t("module.modules." + this.module.Name) +
            " - " +
            this.$t("client.healthGoals.title")
        );
        this.$emit("color-change", this.module.Color);
      } else {
        //TODO: elirányítsuk valahova? v-else ágba informáljuk a felhasználót, hogy rossz az url?
        this.$store.dispatch(
      "setPageTitle",this.$t("module.modules.noModule"));
      }
    },
  },
  async mounted() {
    await this.getModules();
  },
};
</script>
<style>
.row.plan-form {
  width: 30%;
  margin: auto;
}
.diary-title {
  margin-top: 20px;
  text-align: center;
}
.input-group
  > .input-group-append:not(:last-child)
  > .target-date-append-button.btn-group
  > .btn {
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}
@media (max-width: 870px) {
  .row.plan-form {
    width: 90%;
  }
}
</style>
