import { ClientService, RequestMethod } from "../client-service";
import {
  RequestResponseLogic,
  EesztRequestResponseLogic,
} from "../Ui/request-response";
import Vue from "vue";

/* PRESCRIPITON */
const PRESCRIPTION = "/medical_device/prescription";
const PRESCRIPTION_COUNT = "count";
const PRESCRIPTION_REMARK = "remark";
const PRESCRIPTION_CLOSE = "close";
const PRESCRIPTION_OPEN = "open";
/* DISPENSE */
const DISPENSE = "/medical_device/dispense";
const DISPENSE_PRODUCT = "dispenseproduct";
const DISPENSE_SUBSIDIZATION = "/medical_device/dispense/subsidization";
/* EESZT */
const EESZT_PRESCRIPTION = "/medical_device/eeszt/prescription";
const EESZT_DISPENSE = "/medical_device/eeszt/dispense";
const EESZT_VALIDATE = "/medical_device/eeszt/validate";
/* FINANCE */
const FINANCE = "/medical_device/finance";
const FINANCE_PRODUCT = "/medical_device/finance/financeproduct";
const FINANCE_PRODUCTS = "/medical_device/finance/financedproducts";
const FINANCE_PRODUCT_IDS = "/medical_device/finance/financedproductids";
/* REPORT */
const FINANCE_REPORT = "/medical_device/finance/report";
const REPORT = "/medical_device/report";
const REPORT_HEADER = "/Header";
const REPORT_BODY = "/Body";
const REPORT_PRESCRIPTION = "/medical_device/dispense/dispensedproducts";

export const MedicalDeviceLogic = {
  /* PRESCRIPTION */
  async getPrescription(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PRESCRIPTION,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getPrescriptionCount(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      PRESCRIPTION + "/" + PRESCRIPTION_COUNT,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setPrescription(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      PRESCRIPTION,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async updatePrescription(prescriptionId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      PRESCRIPTION + "/" + prescriptionId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deletePrescription(prescriptionId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      PRESCRIPTION + "/" + prescriptionId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async editPrescriptionRemark(prescriptionId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PUT,
      PRESCRIPTION + "/" + prescriptionId + "/" + PRESCRIPTION_REMARK,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async closePrescription(prescriptionId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      PRESCRIPTION + "/" + prescriptionId + "/" + PRESCRIPTION_CLOSE,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async openPrescription(prescriptionId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.POST,
      PRESCRIPTION + "/" + prescriptionId + "/" + PRESCRIPTION_OPEN,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  /* DISPENSE */
  async createDispense(dispenseId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      DISPENSE + "/" + dispenseId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setDispenseDetails(prescriptionId, body, params) {
    console.log(prescriptionId);
    console.log(body);
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      DISPENSE + "/details/" + prescriptionId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async updateDispense(prescriptionId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      DISPENSE + "/" + prescriptionId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async reviewDispense(prescriptionId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      DISPENSE + "/review/" + prescriptionId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteDispense(prescriptionId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      DISPENSE + "/" + prescriptionId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteDispenseProduct(prescriptionId, dispenseProductUniqueId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      DISPENSE +
        "/" +
        prescriptionId +
        "/" +
        DISPENSE_PRODUCT +
        "/" +
        dispenseProductUniqueId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async downloadPdf(prescriptionId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      DISPENSE + "/receipt/" + prescriptionId,
      params
    );
    const responseBody = await RequestResponseLogic.checkRequestResponse(
      result,
      false
    );
    return responseBody.Data ? responseBody.Data : responseBody;
  },
  async getDispenseSubsidization(productId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      DISPENSE_SUBSIDIZATION + "/" + productId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  /* EESZT */
  async listPrescriptionFromEeszt(body, patientId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_PRESCRIPTION + "/list/" + patientId,
      params,
      body
    );
    return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
  },
  async getPrescriptionFromEeszt(body, eesztId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_PRESCRIPTION + "/get/" + eesztId,
      params,
      body
    );
    return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
  },
  async importPrescriptionFromEeszt(body, eesztId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_PRESCRIPTION + "/import/" + eesztId,
      params,
      body
    );
    return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
  },
  async importPrescriptionFromEesztWithDispense(body, eesztId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_PRESCRIPTION + "/import_dispense/" + eesztId,
      params,
      body
    );
    return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
  },
  async validatePrescriptionToEeszt(body, prescriptionId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_VALIDATE + "/" + prescriptionId,
      params,
      body
    );
    return await EesztRequestResponseLogic.checkEesztValidateResponse(result);
  },
  async addPrescriptionToEeszt(body, prescriptionId, params) {
    const validate = await this.validatePrescriptionToEeszt(
      {
        OrganizationNickname: body.OrganizationNickname,
        Operation: "CreatePaperPrescription",
      },
      prescriptionId
    );
    if (!validate.Code) {
      let result = await ClientService.sendRequestWithBody(
        RequestMethod.POST,
        EESZT_PRESCRIPTION + "/add/" + prescriptionId,
        params,
        body
      );
      return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
    } else {
      return validate;
    }
  },
  async deletePrescriptionFromEeszt(description, prescriptionId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_PRESCRIPTION + "/delete/" + prescriptionId,
      params,
      {
        OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        DeleteDescription: description,
      }
    );
    return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
  },
  async addDispenseToEeszt(dispenseId, params) {
    const validate = await this.validatePrescriptionToEeszt(
      {
        OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        Operation: "CreateDispense",
      },
      dispenseId
    );
    if (!validate.Code) {
      let result = await ClientService.sendRequestWithBody(
        RequestMethod.POST,
        EESZT_DISPENSE + "/add/" + dispenseId,
        params,
        {
          OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        }
      );
      return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
    } else {
      return validate;
    }
  },
  async addDispenseDetailsToEeszt(dispenseId, params) {
    const validate = await this.validatePrescriptionToEeszt(
      {
        OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        Operation: "AddDispenseDetails",
      },
      dispenseId
    );
    if (!validate.Code) {
      let result = await ClientService.sendRequestWithBody(
        RequestMethod.POST,
        EESZT_DISPENSE + "/details/" + dispenseId,
        params,
        {
          OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        }
      );
      return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
    } else {
      return validate;
    }
  },
  async updateDispenseToEeszt(prescriptionId, params) {
    const validate = await this.validatePrescriptionToEeszt(
      {
        OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        Operation: "EditDispenseDetails",
      },
      prescriptionId
    );
    if (!validate.Code) {
      let result = await ClientService.sendRequestWithBody(
        RequestMethod.POST,
        EESZT_DISPENSE + "/update/" + prescriptionId,
        params,
        {
          OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        }
      );
      return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
    } else {
      return validate;
    }
  },
  async deleteDispenseFromEeszt(description, dispenseId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      EESZT_DISPENSE + "/delete/" + dispenseId,
      params,
      {
        OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        DeleteDescription: description,
      }
    );
    return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
  },
  async reviewDispenseToEeszt(prescriptionId, params) {
    //TODO: validatePrescriptionToEeszt
    const validate = await this.validatePrescriptionToEeszt(
      {
        OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        Operation: "ReviewDispense",
      },
      prescriptionId
    );
    if (!validate.Code) {
      let result = await ClientService.sendRequestWithBody(
        RequestMethod.POST,
        EESZT_DISPENSE + "/review/" + prescriptionId,
        params,
        {
          OrganizationNickname: Vue.prototype.$loggedUser.SelectedOrganization,
        }
      );
      return await EesztRequestResponseLogic.checkEesztRequestResponse(result);
    } else {
      return validate;
    }
  },

  /* FINANCE */
  async createFinance(body, prescriptionId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      FINANCE + "/" + prescriptionId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setFinance(body, prescriptionId, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PATCH,
      FINANCE + "/" + prescriptionId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteFinanceProduct(prescriptionId, financeProductUniqueId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      FINANCE_PRODUCT + "/" + prescriptionId + "/" + financeProductUniqueId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteFinanceData(prescriptionId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      FINANCE + "/" + prescriptionId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getFinanceProducts(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_PRODUCTS,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getInvalidFinanceProducts(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_PRODUCTS + "/invalid",
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getFinanceProductIds(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_PRODUCT_IDS,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getFinanceProductCount(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_PRODUCTS + "/" + PRESCRIPTION_COUNT,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },

  /* REPORT */
  async createReport(body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      FINANCE_REPORT,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getFinanceReport(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_REPORT,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async setFinanceReportHeader(reportId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.PUT,
      FINANCE_REPORT + "/" + reportId,
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async downloadFinanceReportHeader(reportId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_REPORT + "/" + reportId + REPORT_HEADER,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result, false);
  },
  async downloadFinanceReportBody(reportId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_REPORT + "/" + reportId + REPORT_BODY,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result, false);
  },
  async downloadFinanceReportExcel(reportId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_REPORT + "/" + reportId + "/excel",
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result, false);
  },
  async getReportWithPrescriptions(reportId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_REPORT + "/" + reportId + "/prescription",
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getReportWithPrescriptionsCount(reportId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_REPORT + "/" + reportId + "/prescription/count",
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getDispensedProductsByHasSubsidization(params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      REPORT_PRESCRIPTION,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async addPrescriptionToReport(reportId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      FINANCE_REPORT + "/" + reportId + "/prescription?prescriptionType=Normal",
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async addCancellationToReport(reportId, body, params) {
    let result = await ClientService.sendRequestWithBody(
      RequestMethod.POST,
      FINANCE_REPORT +
        "/" +
        reportId +
        "/prescription?prescriptionType=Cancellation",
      params,
      body
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteReport(reportId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      FINANCE_REPORT + "/" + reportId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deletePrescriptionFromReport(reportId, prescriptionId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.DELETE,
      FINANCE_REPORT + "/" + reportId + "/prescription/" + prescriptionId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async getReportValidate(reportId, params) {
    let result = await ClientService.sendRequest(
      RequestMethod.GET,
      FINANCE_REPORT + "/" + reportId + "/validate",
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
};
