<template>
  <div class="eh-client-home">
      <b-container fluid>
        <!-- Engedélyezett modulok -->
            <!-- Engedélyezett modulok fejléc (cím és gomb elhelyezés) -->
            <b-row align-h="between" align-v="center">
              <b-col cols="12" lg="8" class="eh-flex-center-container">
                <label class="citizen-home-modules-title">{{
                    $t("client.home.enabledModules")
                  }}</label>
              </b-col>
              <b-col cols="12" lg="4" class="eh-flex-center-container">
                <router-link :to="{ name: 'ModuleSettings' }">
                  <button
                      style="margin: 10px"
                      class="eh-action-button"
                  >{{ $t("client.home.settingModules") }}</button
                  >
                </router-link>
              </b-col>
            </b-row>
            <!-- Engedélyezett modulok elemeinek megjelenítése -->
            <table class="eh-client-home-table">
              <td
                  v-for="module in enabledModules"
                  :key="module.Name"
                  class="eh-client-home-module-box"
              >
                <!-- Engedélyezett modul felépítése -->
                <router-link
                    style="text-decoration-line: none"
                    :to="{ name: module.Route }"
                >
                  <tr>
                    <div class="module-header">
                      <div>
                        <!-- Engedélyezett modul ikonja -->
                        <img
                            :style="
                          'background:' +
                          module.Color +
                          '; border-radius: 50px;'
                        "
                            v-if="module.IconURL"
                            class="program-img"
                            :src="require('@/assets/modules/' + module.IconURL)"
                        />
                        <!-- Engedélyezett modul neve -->
                        <label class="module-title">{{
                            $t("module.modules." + module.Name)
                          }}</label>
                      </div>
                      <!-- Engedélyezett modul aktuális havi (esetenként heti) elsőként beállított chart megjelenése -->
                      <div>
                        <component
                            style="margin-top: -25px"
                            v-if="
                          module.Charts &&
                          Object.keys(module.Charts).length &&
                          getFirstChart(module)
                        "
                            :is="getFirstChart(module).ComponentName"
                            :IsDashboard="true"
                            :Interval="'Month'"
                            :ChartType="'chart'"
                            :Chart="getFirstChart(module)"
                        ></component>
                      </div>
                    </div>
                  </tr>
                </router-link>
              </td>
            </table>
        <!-- További almenü gombok -->
        <div class="eh-flex-center-container">
            <table>
              <!-- Jelvények almenüpont -->
              <!-- <router-link to="Badges">
                <td class="program-box">
                  <button class="eh-action-button">{{
                      $t("client.menu.badges")
                    }}</button>
                  //<fp-button style="padding:20px"
                  //  variant="secondary"
                  //  :outline="true"
                  //  :text="$t('client.home.badges')"
                 // />
                  //TODO: syncfusion button cserék!
                  //<div class="webapp-homepage-button">{{ $t("client.home.badges") }}</div>
                </td>
              </router-link> -->
              <!-- Gondozási programok almenüpont -->
              <!-- <router-link to="CarePrograms">
                <td class="program-box">
                  <ejs-button class="webapp-homepage-button">{{
                    $t("client.home.carePrograms")
                  }}</ejs-button>
                </td>
              </router-link> -->
              <!-- Egészség programok almenüpont -->
              <!-- <router-link to="HealthPrograms">
                <td class="program-box">
                  <ejs-button class="webapp-homepage-button">{{
                    $t("client.home.healthPrograms")
                  }}</ejs-button>
                </td>
              </router-link> -->
              <!-- Betegség kalauz almenüpont -->
              <!-- <router-link to="DiseaseGuide">
                <td class="program-box">
                  <button class="eh-action-button">{{
                      $t("client.menu.diseaseGuide")
                    }}</button>
                </td>
              </router-link> -->
              <!-- Dokumentum tár almenüpont -->
              <!-- <router-link to="Documents">
                <td class="program-box">
                  <ejs-button class="webapp-homepage-button">{{
                    $t("client.home.documents")
                  }}</ejs-button>
                </td>
              </router-link> -->
              <!-- Szolgáltatás kereső almenüpont -->
              <!-- <router-link to="ServiceFinder">
                <td class="program-box">
                  <button class="eh-action-button">{{
                      $t("client.menu.serviceFinder")
                    }}</button>
                </td>
              </router-link> -->
              <!-- Kérdőívek almenüpont -->
              <!-- <router-link to="QuestionnaireList">
                <td class="program-box">
                  <ejs-button class="webapp-homepage-button">{{
                    $t("client.home.questionnaires")
                  }}</ejs-button>
                </td>
              </router-link> -->
              <!-- Időpontok almenüpont -->
              <!-- <router-link to="Appointments">
                <td class="program-box">
                  <ejs-button class="webapp-homepage-button"
                    >Időpontok</ejs-button
                  >
                </td>
              </router-link> -->
            </table>
        </div>
      </b-container>
  </div>
</template>
<script>
import { UserPreferencesLogic } from "@/logic/backend/user-preferences";
/* dashboard charts */
import ExerciseCalories from "@/components/module/chart/ExerciseCalories";
import ExerciseDistance from "@/components/module/chart/ExerciseDistance";
import ExerciseDuration from "@/components/module/chart/ExerciseDuration";
import ExerciseFloors from "@/components/module/chart/ExerciseFloors";
import ExerciseSteps from "@/components/module/chart/ExerciseSteps";
import CardiologyBloodPressure from "@/components/module/chart/CardiologyBloodPressure";
import CardiologyPulse from "@/components/module/chart/CardiologyPulse";
import BodyWeightBMI from "@/components/module/chart/BodyWeightBMI";
import BodyWeightBodyWeight from "@/components/module/chart/BodyWeightBodyWeight";
import PulmonologyOxygenSaturation from "@/components/module/chart/PulmonologyOxygenSaturation";
import MedicineDaily from "@/components/module/chart/MedicineDaily";
import MedicineWeekly from "@/components/module/chart/MedicineWeekly";
import MedicineMonthly from "@/components/module/chart/MedicineMonthly";
import Mood from "@/components/module/chart/DepressionMood";
import Sleep from "@/components/module/chart/DepressionSleep";
import Insulin from "@/components/module/chart/DiabetologyInsulin";
import BloodGlucose from "@/components/module/chart/DiabetologyBloodGlucose";
import CalorieIntake from "@/components/module/chart/NutritionCalorieIntake";

export default {
  name: "Home",
  components: {
    ExerciseCalories,
    ExerciseDistance,
    ExerciseDuration,
    ExerciseFloors,
    ExerciseSteps,
    CardiologyBloodPressure,
    CardiologyPulse,
    BodyWeightBMI,
    BodyWeightBodyWeight,
    PulmonologyOxygenSaturation,
    MedicineDaily,
    MedicineWeekly,
    MedicineMonthly,
    Mood,
    Sleep,
    Insulin,
    BloodGlucose,
    CalorieIntake,
  },
  data() {
    return {
      enabledModules: [],
      siteTitle: this.$t("client.menu.homeButton"),
    };
  },
  props: {
    userName: {
      type: String,
    },
  },
  methods: {
    //modul ikon képek betöltése url alapján
    getImgUrl(url) {
      //url alapján url generálás
      var images = require.context("@/assets/modules/", false, /\.png$/);
      //kép url kiegészítve vissza küldés ami t az src kezel
      return images("./" + url + ".png");
    },
    async getEnabledModules() {
      const getUserPreferencesResponse =
        await UserPreferencesLogic.getUserPreferences();
      if (
        !getUserPreferencesResponse.Code &&
        getUserPreferencesResponse.Modules
      ) {
        this.enabledModules = UserPreferencesLogic.getEnabledModules(
          getUserPreferencesResponse.Modules
        );
      } else {
        this.$bvToast.toast(getUserPreferencesResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.modules.module"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    getFirstChart(module) {
      //segédváltozó definiálás
      var temp = [];
      //modul chart objektumokon végig futunk
      Object.values(module.Charts).forEach((value) => {
        //elérhető a chart?
        if (value.Enabled == true) {
          //igen, ideiglenes változóban tároljuk az elérhető chartokat
          temp.push(value);
        }
      });
      //a modulban elérhető chartokat sorrendezzük az OrderNumber alapján
      temp.sort((a, b) => a.OrderNumber - b.OrderNumber);
      //első chartot adjuk vissza
      return temp[0];
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //oldal cím beállítása
    this.$store.dispatch(
      "setPageTitle",this.siteTitle);
    //oldal szín beállítás
    this.$emit("color-change", "transparent");
    await this.getEnabledModules();
  },
};
</script>
<style scoped>
table > td {
  vertical-align: top;
}

.module-header {
  background: #cddbe5;
  height: 60px;
  width: 350px;
  text-align: left;
  color: #698393;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
}
.program-img {
  width: 70px;
  height: 70px;
  margin: 10px;
}
.module-title {
  margin-top: 20px;
  height: 60px;
  vertical-align: middle;
  cursor: pointer;
}

</style>
