<template>
  <div>
    <!-- TODO: szűrés dokira -->
    <div class="eh-flex-container">
      <fp-input
        class="eh-client-appointments-filter"
        :label="$t('base.basic.filter')"
        :description="$t('client.appointment.filterDescription')"
        @change="filterList"
        v-model="filter"
      />
      <fp-date-range-button
        class="eh-client-appointments-date-filter"
        :label="$t('client.appointment.date')"
        v-model="dateFilter"
        @change="filterList"
      />
    </div>
    <fp-table-list
      :hasPagination="true"
      :limitedDataLoad="true"
      @nextStackLoad="getNextStackLoad"
      :items="filteredAppointments"
      :fields="appointmentFields"
    >
      <template #Title="row">
        {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
      </template>
      <template #Description="row">
        {{ getTextByLanguage(row.item.Description, row.item.Languages) }}
      </template>
      <template #StartTime="row">
        <b-icon-clock-fill
          v-b-tooltip.hover="$t('client.appointment.inProgress')"
          v-if="isInProgress(row.item.StartTime, row.item.EndTime)"
        ></b-icon-clock-fill>
        {{ $convertDateToString(row.item.StartTime, "YYYY-MM-DD HH:mm") }}
      </template>
      <template #EndTime="row">
        {{ $convertDateToString(row.item.EndTime, "YYYY-MM-DD HH:mm") }}
      </template>
      <template #Operations="row">
        <b-iconstack
          class="h1"
          style="cursor: pointer"
          v-b-tooltip.hover="$t('client.appointment.cancellation')"
          variant="success"
          @click="applyToAppointment(row.item.AppointmentId)"
        >
          <b-icon stacked icon="person-plus-fill" scale="0.5"></b-icon>
          <b-icon stacked icon="circle"></b-icon>
        </b-iconstack>
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { AppointmentLogic } from "@/logic/backend/appointment";
import moment from "moment";

export default {
  name: "Appointments",
  data() {
    return {
      appointments: null,
      appointmentFields: [
        { key: "Title", label: this.$t("client.appointment.title") },
        {
          key: "Description",
          label: this.$t("client.appointment.description"),
        },
        { key: "StartTime", label: this.$t("client.appointment.startTime") },
        { key: "EndTime", label: this.$t("client.appointment.endTime") },
        { key: "Location", label: this.$t("client.appointment.location") },
        { key: "Operations", label: "" },
      ],
      filteredAppointments: null,
      dateFilter: null,
      filter: null,
    };
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (typeof textObject == "object") {
        const lang = languages ? languages : Object.keys(textObject);
        if (lang.includes(this.$i18n.locale)) {
          return textObject[this.$i18n.locale];
        } else if (lang.includes("en")) {
          return textObject.en;
        } else {
          return textObject[lang[0]];
        }
      } else {
        return textObject;
      }
    },
    filterList() {
      this.filteredAppointments = this.appointments;
      if (this.filter) {
        this.filteredAppointments = this.$filterList(
          this.filter,
          this.appointments,
          ["Title", "Description"]
        );
      }
      if (this.dateFilter) {
        this.filteredAppointments = this.filteredAppointments.filter((e) => {
          if (this.dateFilter.from && this.dateFilter.to) {
            return moment(e.StartTime).isBetween(
              this.dateFilter.from,
              moment(this.dateFilter.to).endOf("days"),
              undefined,
              "[]"
            );
          } else if (this.dateFilter.from) {
            return moment(e.StartTime).isSameOrAfter(
              moment(this.dateFilter.from)
            );
          } else if (this.dateFilter.to) {
            return moment(e.StartTime).isSameOrBefore(
              moment(this.dateFilter.to).endOf("days")
            );
          }
          return true;
        });
      }
    },
    isInProgress(start, end) {
      return moment(start).isBefore(this.now) && moment(end).isAfter(this.now);
    },
    async getAppointmentList() {
      this.appointments = this.filteredAppointments =
        await this.getAppointments({
          "EndTime-from": moment().format(),
        });
    },
    async getAppointments(params) {
      const getAppointmentsResponse =
        await AppointmentLogic.getAppointmentHeaders({
          /* CanApply: true, */
          ...params,
          "-orderby": "StartTime",
          "-limit": 101,
        });
      if (!getAppointmentsResponse.Code) {
        return getAppointmentsResponse.filter((a) => a.CanApply);
      } else {
        this.$bvToast.toast(getAppointmentsResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("client.appointment.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getNextStackLoad() {
      //TODO: átvezetés a naplózáshoz
      //ismétlődés nélküli léptetés, időpont kihagyás nélkül
      const temp = await this.getAppointments({
        "StartTime-from":
          this.appointments[this.appointments.length - 1].StartTime,
      });
      const duplicatableE = this.appointments.filter(
        (a) =>
          a.StartTime ==
          this.appointments[this.appointments.length - 1].StartTime
      );
      const deduplicateTemp = temp.filter(
        (t) => !duplicatableE.some((e) => e.AppointmentId == t.AppointmentId)
      );
      this.appointments = this.filteredAppointments =
        this.appointments.concat(deduplicateTemp);
      this.filterList();
    },
    async applyToAppointment(appointmentId) {
      const applyToAppointmentResponse =
        await AppointmentLogic.addVisitorToAppointment(appointmentId, {
          VisitorId: this.$loggedUser.UserId,
        });
      if (!applyToAppointmentResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.appointment.successApply"),
          {
            title: this.$t("requestResponse.appointment.successApplyMessage"),
            variant: "success",
            solid: true,
          }
        );
        await this.getAppointmentList();
      } else {
        this.$bvToast.toast(applyToAppointmentResponse.Message, {
          title: this.$t("requestResponse.appointment.errorApply"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getAppointmentList();
  },
};
</script>
