<template>
  <div v-if="form" style="margin: 10px 0px">
    <div class="h3" style="text-align: center">
      {{ form.Title ? form.Title[language] : null }}
    </div>
    <div style="text-align: center">
      {{ form.Description ? form.Description[language] : null }}
    </div>
    <div style="display: none">
      <input id="fp-form-import-input" type="file" @input="selectFile" />
    </div>
    <!-- TODO: kell a többi komponensnél megjeleníteni a pontokat? hogyan? -->
    <form-schema-inputs
      :key="'form-schema-input-' + forceUpdateCount"
      :component="form.UiSchema"
      v-model="form.ObjectModel"
      :evaluateObjectModel="evaluation.ScoreObjectModel"
      @evaluating="$set(evaluation, 'ScoreObjectModel', $event)"
      :disabled="disabled"
      :scoreable="scoreable"
      :language="language"
    />
    <form-schema-inputs />
    <div
      v-if="
        scoreable ||
          (form.EvaluationMethod == $enums.EvaluationMethod.Manual && disabled)
      "
    >
      {{ $t("components.formEvaulating.manualEvaluation") }}
      <fp-input
        :disabled="!scoreable"
        :label="$t('components.formEvaulating.short')"
        v-model="evaluation.ShortDescription"
      />
      <fp-text-area
        :disabled="!scoreable"
        :label="$t('components.formEvaulating.long')"
        v-model="evaluation.LongDescription"
      />
      <fp-text-area
        :disabled="!scoreable"
        :label="$t('components.formEvaulating.note')"
        v-model="evaluation.Note"
      />
    </div>
    <div
      v-if="
        disabled &&
          form.Evaluation &&
          form.EvaluationMethod == $enums.EvaluationMethod.Auto &&
          form.Evaluation.ScoreAchived &&
          form.Evaluation.ScoreRangeNotification
      "
    >
      <fp-text-area
        :disabled="true"
        :label="$t('components.formEvaulating.autoResult')"
        :value="getAutoEvaluation()"
      />
    </div>
    <div
      style="text-align: center"
      v-if="form.UiSchema.Buttons && (!disabled || (disabled && scoreable))"
    >
      <b-button
        :disabled="disabled && !scoreable"
        style="margin: 0px 10px"
        v-for="button in form.UiSchema.Buttons"
        :key="button.VariableName"
        @click="functionMethod(button.Function)"
        >{{ button.Label ? button.Label[language] : null }}</b-button
      >
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { FormLogic } from "@/logic/backend/form";
import FormSchemaInputs from "./FormSchemaInputs.vue";
export default {
  components: { FormSchemaInputs },
  name: "FormSchemaBuilder",
  data() {
    return {
      form: null,
      forceUpdateCount: 0,
      evaluation: {
        FormInstanceId: null,
        Status: null,
        ScoreObjectModel: null,
        ShortDescription: null,
        LongDescription: null,
        Note: null,
      },
      language: null,
    };
  },
  props: {
    //megjelenítendő form schema idja
    formSchemaId: Number,
    //megjeleníthető form idja
    formInstanceId: Number,
    //nem szerkeszthető
    disabled: Boolean,
    //kiértékelés
    scoreable: Boolean,
    //kérdőív
    instance: Object,
  },
  /* watch:{
    async formSchemaId(){
      await this.getFormSchema()
    }
  }, */
  methods: {
    //TODO elhelyezni a meghívást
    setLanguage() {
      if (this.form.Languages.includes(this.$i18n.locale)) {
        return this.$i18n.locale;
      } else if (this.form.Languages.includes("en")) {
        return "en";
      } else {
        return this.form.Languages[0];
      }
    },
    setEvaluation() {
      if (this.form) {
        this.evaluation.ScoreObjectModel = this.form.ScoreObjectModel;
        this.evaluation.FormInstanceId = this.form.FormInstanceId;
        if (this.form.Evaluation) {
          this.evaluation.ShortDescription = this.form.Evaluation.ShortDescription;
          this.evaluation.LongDescription = this.form.Evaluation.LongDescription;
          this.evaluation.Note = this.form.Evaluation.Note;
        }
      }
    },
    getAutoEvaluation() {
      const maxResult = this.form.Evaluation.ScoreAchived;
      return this.form.Evaluation.ScoreRangeNotification.find((range) => {
        return range.MinScore < maxResult && range.MaxScore > maxResult;
      }).Message;
    },
    async functionMethod(action) {
      //TODO: enum? vagy csak kiemelés?
      switch (action) {
        case "Cancel":
          this.$emit("Cancel");
          return this.$emit("closeForm");
        case "Send":
          return await this.sendFormInstance();
        case "Save":
          return await this.saveFormInstance();
        case "Import":
          return this.importObjModel();
        case "Export":
          return this.exportObjModel();
      }
    },
    importObjModel() {
      const input = document.getElementById("fp-form-import-input");
      input.click();
    },
    exportObjModel() {
      const title = this.form.Title + "_" + moment().format("YYYYMMDD_HHmm");
      const filename =
        title
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replaceAll(" ", "_") + ".json";
      const jsonStr = JSON.stringify(this.form.ObjectModel);

      let element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(jsonStr)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    async selectFile(args) {
      const files = args.target.files;
      if (files) {
        this.form.ObjectModel = null;
        const fileUrl = window.URL.createObjectURL(files[0]);
        const response = await fetch(fileUrl);
        const jsonRes = await response.json();
        this.form.ObjectModel = JSON.parse(JSON.stringify(jsonRes));
        this.$emit("change", this.form.ObjectModel);
        ++this.forceUpdateCount;
      }
    },
    async getFormSchema() {
      const getFormSchemaResponse = await FormLogic.getFormSchema({
        FormSchemaId: this.formSchemaId,
      });
      if (!getFormSchemaResponse.Code) {
        this.form = getFormSchemaResponse[0];
      } else {
        //TODO: hiba
      }
    },
    async getFormInstance() {
      const getFormInstanceResponse = await FormLogic.getFormInstance({
        FormInstanceId: this.formInstanceId,
      });
      if (!getFormInstanceResponse.Code) {
        this.form = getFormInstanceResponse[0];
      } else {
        //TODO: hiba
      }
    },
    async sendFormInstance() {
      if (this.scoreable) {
        await this.sendEvaluation();
      } else {
        await this.sendObjectModel();
      }
    },
    async sendEvaluation() {
      this.evaluation.Status = this.$enums.QuestionnaireStatus.Evaluated.Value;
      const saveEvaluationResponse = await FormLogic.evaluationFormInstance(
        this.evaluation
      );
      if (!saveEvaluationResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.questionnaire.successSendEvaluate"),
          {
            title: this.$t("base.basic.send"),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("closeForm");
      } else {
        this.$bvToast.toast(saveEvaluationResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorSendEvaluate"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.$emit("closeForm");
    },
    async sendObjectModel() {
      this.$emit("Send");
      const body = {
        FormInstanceId: this.form.FormInstanceId,
        Status: this.$enums.FormStatus.Filled.Value,
        ObjectModel: this.form.ObjectModel,
        FilledById: this.$loggedUser.UserId,
      };
      const saveFormResponse = await FormLogic.saveFormInstance(body);
      if (!saveFormResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.form.successSend"), {
          title: this.$t("base.basic.send"),
          variant: "success",
          solid: true,
        });
        this.$emit("closeForm");
      } else {
        this.$bvToast.toast(saveFormResponse.Message, {
          title: this.$t("requestResponse.form.errorSend"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async saveFormInstance() {
      if (this.scoreable) {
        await this.saveEvaluation();
      } else {
        await this.saveObjectModel();
      }
    },
    async saveEvaluation() {
      this.evaluation.Status = this.$enums.QuestionnaireStatus.UnderEvaluation.Value;
      const saveEvaluationResponse = await FormLogic.evaluationFormInstance(
        this.evaluation
      );
      if (!saveEvaluationResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.questionnaire.successSaveEvaluate"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("closeForm");
      } else {
        this.$bvToast.toast(saveEvaluationResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorSaveEvaluate"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.$emit("closeForm");
    },
    async saveObjectModel() {
      this.$emit("Save");
      const body = {
        FormInstanceId: this.form.FormInstanceId,
        Status: this.$enums.FormStatus.InProgress.Value,
        ObjectModel: this.form.ObjectModel,
        FilledById: this.$loggedUser.UserId,
      };
      const saveFormResponse = await FormLogic.saveFormInstance(body);
      if (!saveFormResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.form.successSave"), {
          title: this.$t("base.basic.save"),
          variant: "success",
          solid: true,
        });
        this.$emit("closeForm");
      } else {
        this.$bvToast.toast(saveFormResponse.Message, {
          title: this.$t("requestResponse.form.errorSave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    console.log(this.formSchemaId)
    if (this.instance) {
      this.form = this.instance;
      this.setEvaluation();
    }
    if (this.formSchemaId) {
      await this.getFormSchema();
    }
    if (this.formInstanceId) {
      await this.getFormInstance();
      this.setEvaluation();
    }
    this.language = this.setLanguage();
  },
};
</script>
