<template>
  <div id="fp-image-component" v-if="!params.Hidden">
    {{ params.Title ? params.Title[language] : null }}
    <div class="fp-description">
      {{ params.Description ? params.Description[language] : null }}
    </div>
    <div
      :style="disabled || !params.Enabled ? '' : 'cursor:pointer'"
      @click="changeValue"
      class="fp-image-container"
    >
      <img :src="imgUrl" class="fp-image" />
      <div
        :class="
          isSelected
            ? 'fp-image-selected'
            : disabled && params.Enabled
            ? ''
            : 'fp-image-unselected'
        "
      >
        <!-- <div style="margin: auto">
          <b-icon
            style="color: white; font-size: 60px"
            icon="check2-square"
          ></b-icon>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ImageComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: Boolean,
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      imgUrl: null,
      isSelected: this.value ? this.value : this.params.DefaultValue,
    };
  },
  watch: {
    isSelected(input) {
      //console.log(input);
      this.$emit("change", input);
    },
    value(input) {
      this.isSelected = input;
    },
  },
  methods: {
    async getImage() {
      //console.log();
      //TODO: img letöltés
      this.imgUrl = require("@/assets/test-image-component-img.jpg");
    },
    changeValue() {
      if (!(this.disabled && this.params.Enabled)) {
        this.isSelected = !this.isSelected;
      }
    },
  },
  async mounted() {
    await this.getImage();
    this.$emit("change", this.isSelected);
  },
};
</script>
<style scoped>
#fp-image-component .fp-image {
  width: 100%;
  border-radius: 10px;
}
#fp-image-component .fp-description {
  font-size: 14px;
  color: #00000099;
}
#fp-image-component .fp-image-container {
  position: relative;
}
#fp-image-component .fp-image-container .fp-image-unselected,
#fp-image-component .fp-image-container .fp-image-selected {
  position: absolute;
  top: 0;
  border-radius: 10px;
  opacity: 0;
  width: 100%;
  height: 100%;
}
#fp-image-component .fp-image-container:hover .fp-image-unselected,
#fp-image-component .fp-image-container:hover .fp-image-selected {
  /* background: #00000044; */
  background: var(--eh-secondary-4);
  opacity: 0.4;
}
#fp-image-component .fp-image-container .fp-image-selected {
  /* background: #00000088; */
  background: var(--eh-primary-5);
  opacity: 0.6;
}
</style>