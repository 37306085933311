<template>
  <fp-form-modal
    v-if="prescription"
    size="xl"
    title="ESSZT recept adatok"
    v-model="visible"
  >
    <!-- TODO: prescription-components -->
    <template #content>
      <div style="margin: 10px 0px" v-if="prescription.PrescribingDoctor">
        <fp-panel-card title="Felíró orvos" :closedCard="true">
          <template #content>
            <b-row cols="1" cols-md="2">
              <b-col>
                <div>
                  <fp-input
                    label="Felíró orvos azonosítója"
                    :disabled="true"
                    :value="prescription.PrescribingDoctor.UserId"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Felíró orvos felhasználóneve"
                    :disabled="true"
                    :value="prescription.PrescribingDoctor.UserName"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Intézmény neve"
                    description="Felíró orvos egészségügyi szolgáltatójának megnevezése"
                    :disabled="true"
                    :value="prescription.PrescribingDoctor.OrganizationName"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Intézmény azonosítója"
                    :disabled="true"
                    :value="prescription.PrescribingDoctor.OrganizationId"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Szervezeti egység neve"
                    :disabled="true"
                    :value="prescription.PrescribingDoctor.OrganizationUnitName"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Szervezeti egység azonosítója"
                    :disabled="true"
                    :value="prescription.PrescribingDoctor.OrganizationUnitId"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
        </fp-panel-card>
      </div>
      <div style="margin: 10px 0px" v-if="prescription.Submitter">
        <fp-panel-card title="Receptet rögzítő adatai" :closedCard="true">
          <template #content>
            <b-row cols="1" cols-md="2">
              <b-col>
                <div>
                  <fp-input
                    label="Vényt rögzítő felhasználó azonosítója"
                    :disabled="true"
                    :value="prescription.Submitter.UserId"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Vényt rögzítő felhasználóneve"
                    :disabled="true"
                    :value="prescription.Submitter.UserName"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Intézmény neve"
                    description="Vényt rögzítő felhasználó egészségügyi szolgáltatójának megnevezése"
                    :disabled="true"
                    :value="prescription.Submitter.OrganizationName"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Intézmény azonosítója"
                    :disabled="true"
                    :value="prescription.Submitter.OrganizationId"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Szervezeti egység neve"
                    :disabled="true"
                    :value="prescription.Submitter.OrganizationUnitName"
                  />
                </div>
              </b-col>
              <b-col>
                <div>
                  <fp-input
                    label="Szervezeti egység azonosítója"
                    :disabled="true"
                    :value="prescription.Submitter.OrganizationUnitId"
                  />
                </div>
              </b-col>
            </b-row>
          </template>
        </fp-panel-card>
      </div>
      <div style="margin: 10px 0px">
        <fp-panel-card title="Recept alap adatai" :closedCard="true">
          <template #content>
            <b-row cols="1" cols-md="2">
              <b-col v-if="true">
                <fp-input
                  label="EESZT azonosító"
                  :disabled="true"
                  :value="prescription.EesztId"
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Papíralapú NEAK vényazonosító"
                  :disabled="true"
                  :value="prescription.NeakId"
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Recept érvényesség kezdete"
                  :disabled="true"
                  :value="
                    $convertDateToString(prescription.ValidFrom, 'YYYY-MM-DD')
                  "
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Recept érvényesség vége"
                  :disabled="true"
                  :value="
                    $convertDateToString(prescription.ValidTo, 'YYYY-MM-DD')
                  "
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Forgalmi / ambuláns napló száma"
                  :disabled="true"
                  :value="prescription.OutpatientLogNumber"
                />
              </b-col>
              <b-col>
                <fp-input
                  label="BNO szerinti betegség kód"
                  :disabled="true"
                  :value="
                    prescription.Bno
                      ? prescription.Bno.Name +
                        ' (' +
                        prescription.Bno.Code +
                        ')'
                      : null
                  "
                />
              </b-col>
              <b-col>
                <fp-date-time-picker
                  label="Felirás időpontja"
                  :disabled="true"
                  :value="prescription.PrescriptionTimestamp"
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Vény típusa"
                  :disabled="true"
                  :value="
                    prescription.PrescriptionType
                      ? prescription.PrescriptionType.Name
                      : null
                  "
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Termék típusa"
                  :disabled="true"
                  :value="
                    prescription.ProductType
                      ? prescription.ProductType.Name
                      : null
                  "
                />
              </b-col>
            </b-row>
          </template>
        </fp-panel-card>
      </div>
      <div style="margin: 10px 0px" v-if="prescription.Products">
        <fp-panel-card title="Felírt termékek" :closedCard="true">
          <template #content>
            <fp-panel-card
              style="margin: 20px 0px"
              v-for="(product, index) in recipe.Products"
              :key="'eeszt-recipe-product-' + index"
              :title="index + 1 + '. termék'"
              :closedCard="index != 0"
            >
              <template #content>
                <!-- TODO: Nincs Id rendelve az EESZTs receptben a code-name-pair elemekhez -->
                <!-- <prescription-product :disabled="true" :value="product" /> -->
                <b-row v-if="product" cols="1" cols-md="2">
                  <b-col>
                    <div>
                      <fp-input
                        label="Felírás jogcíme"
                        :disabled="true"
                        :value="
                          product.PrescriptionTitle
                            ? product.PrescriptionTitle.Name
                            : null
                        "
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <fp-input
                      label="Termék neve"
                      :disabled="true"
                      :value="product.ProductName"
                    />
                  </b-col>
                  <b-col>
                    <div>
                      <fp-input
                        label="Felírt termék ISO 10 kód és név"
                        :disabled="true"
                        :value="
                          product.Iso
                            ? product.Iso.Code + ', ' + product.Iso.Name
                            : null
                        "
                        :items="eesztISOList"
                        valueKey="EntryId"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <fp-input
                      label="Felírt termék TTT kód és név"
                      :disabled="true"
                      :value="
                        product.Ttt
                          ? product.Ttt.Code + ', ' + product.Ttt.Name
                          : null
                      "
                    />
                  </b-col>
                  <b-col>
                    <fp-panel-card
                      style="margin-top: 10px"
                      title="Felírt termék mennyiség"
                    >
                      <template #content>
                        <fp-input
                          label="Felírt termék mennyiség"
                          :disabled="true"
                          :value="product.Amount ? product.Amount.Amount : null"
                        />
                        <fp-input
                          label="Felírt termék mennyiségi egység"
                          :disabled="true"
                          :value="product.Amount ? product.Amount.Unit : null"
                        />
                        <fp-input
                          label="Felírt mennyiség indoklás"
                          :disabled="true"
                          :value="
                            product.Amount ? product.Amount.Justification : null
                          "
                        />
                      </template>
                    </fp-panel-card>
                  </b-col>
                  <b-col>
                    <div>
                      <fp-input
                        label="Felírt termék kihordási idő"
                        :disabled="true"
                        :value="product.Lifespan"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <fp-panel-card
                      style="margin-top: 10px"
                      title="Felírt termék felhasználás"
                      :closedCard="true"
                    >
                      <template #content>
                        <fp-input
                          label="Felírt termék felhasználás típusa"
                          :disabled="true"
                          :value="
                            product.Usage && product.Usage.Type
                              ? product.Usage.Type.Name
                              : null
                          "
                        />
                        <!-- TODO: itt is kell a szűrés melyik típus van kiválasztva? szöveges / gyak. v. napsz. szerint? -->
                        <fp-input
                          label="Felírt termék felhasználási gyakoriság"
                          :disabled="true"
                          :value="
                            product.Usage ? product.Usage.Frequency : null
                          "
                        />
                        <fp-input
                          label="Felírt termék felhasználási mennyiség, gyakoriság szerint"
                          :disabled="true"
                          :value="product.Usage ? product.Usage.Amount : null"
                        />
                        <fp-input
                          label="Felírt termék felhasználási gyakoriság egység"
                          :disabled="true"
                          :value="product.Usage ? product.Usage.Unit : null"
                        />
                        <fp-text-area
                          label="Felírt termék felhasználása szövegesen"
                          :disabled="true"
                          :value="
                            product.Usage
                              ? product.Usage.UsageDescription
                              : null
                          "
                        />
                      </template>
                    </fp-panel-card>
                  </b-col>
                  <b-col>
                    <div>
                      <fp-input
                        label="Felírt termék méret"
                        :disabled="true"
                        :value="product.Size"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <fp-panel-card
                      style="margin-top: 10px"
                      title="Felírt termék helyettesíthetőség"
                      :closedCard="true"
                    >
                      <template #content>
                        <fp-input
                          label="Helyettesíthető-e a termék?"
                          :disabled="true"
                          :value="
                            product.Substitutable == null
                              ? null
                              : product.Substitutable
                              ? 'Igen'
                              : 'Nem'
                          "
                        />
                        <fp-input
                          v-if="product.Substitutable === false"
                          label="Felírt termék nem helyettesíthető indoklás"
                          :disabled="true"
                          :value="product.ReasonOfNonSubstitutability"
                        />
                      </template>
                    </fp-panel-card>
                  </b-col>
                  <b-col>
                    <fp-panel-card
                      style="margin-top: 10px"
                      title="Termék felhasználási területe"
                      :closedCard="true"
                    >
                      <template #content>
                        <fp-input
                          label="Oldaliság"
                          :disabled="true"
                          :value="product.ApplicationSide"
                        />
                        <fp-input
                          label="Testtáj"
                          :disabled="true"
                          :value="product.ApplicationBodyArea"
                        />
                      </template>
                    </fp-panel-card>
                  </b-col>
                  <b-col>
                    <fp-panel-card
                      style="margin-top: 10px"
                      title="Orvosi utasítások"
                      :closedCard="true"
                    >
                      <template #content>
                        <fp-text-area
                          label="Felíró orvos utasítása a GYSE kiadónak"
                          :disabled="true"
                          :value="product.DoctorsOrdersForDispenser"
                        />
                        <fp-text-area
                          label="Felíró orvos utasítása a páciensnek"
                          :disabled="true"
                          :value="product.DoctorsOrdersForPatient"
                        />
                      </template>
                    </fp-panel-card>
                  </b-col>
                  <b-col>
                    <fp-input
                      label="Egyedi méretvétel alapján készülő termék?"
                      :disabled="true"
                      :value="
                        product.CustomMade == null
                          ? null
                          : product.CustomMade
                          ? 'Igen'
                          : 'Nem'
                      "
                    />
                  </b-col>
                  <b-col>
                    <div>
                      <fp-input
                        label="Javítandó termék gyári száma"
                        :disabled="true"
                        :value="product.SerialNumber"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <fp-text-area
                        label="Hiba jellegének szöveges meghatározása"
                        :disabled="true"
                        :value="product.FaultDescription"
                      />
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <fp-input
                        label="Kölcsönzés időtartama napokban"
                        :disabled="true"
                        :value="product.RentalDuration"
                      />
                    </div>
                  </b-col>
                </b-row>
              </template>
            </fp-panel-card>
          </template>
        </fp-panel-card>
      </div>
      <div style="margin: 10px 0px">
        <fp-panel-card title="Szakorvosi javaslat" :closedCard="true">
          <template #content>
            <fp-input
              label="Szakorvosi javaslat azonosító"
              :disabled="true"
              :value="prescription.SpecialistRecommendationObjectId"
            />
            <b-row cols="1" cols-md="2">
              <b-col>
                <fp-input
                  label="Szakorvosi javaslat kiállítási dátuma"
                  :disabled="true"
                  :value="
                    prescription.SpecialistRecommendation
                      ? $convertDateToString(
                          prescription.SpecialistRecommendation
                            .RecommendationDate,
                          'YYYY-MM-DD'
                        )
                      : null
                  "
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Szakorvosi javaslat naplósorszáma"
                  :disabled="true"
                  :value="
                    prescription.SpecialistRecommendation
                      ? prescription.SpecialistRecommendation.LogNumber
                      : null
                  "
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Szakorvos pecsétszáma"
                  :disabled="true"
                  :value="
                    prescription.SpecialistRecommendation
                      ? prescription.SpecialistRecommendation.EnkkId
                      : null
                  "
                />
              </b-col>
              <b-col>
                <fp-input
                  label='Szakorvos pecsétszám "/" utáni része'
                  :disabled="true"
                  :value="
                    prescription.SpecialistRecommendation
                      ? prescription.SpecialistRecommendation.StampNumber
                      : null
                  "
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Szakorvosi javaslatot kiállító orvos neve"
                  :disabled="true"
                  :value="
                    prescription.SpecialistRecommendation
                      ? prescription.SpecialistRecommendation.SpecialistName
                      : null
                  "
                />
              </b-col>
            </b-row>
          </template>
        </fp-panel-card>
      </div>
      <!-- <div style="margin: 10px 0px">
        <fp-panel-card title="Szakorvosi javaslat" :closedCard="true">
          <template #content>
             TODO: kell? Milyen listát használ? 
            <fp-input
              label="Szakorvosi javaslat azonosító"
              :disabled="true"
              :value="
                prescription.SpecialistRecommendationObjectId
                  ? prescription.SpecialistRecommendationObjectId.Name +
                    ' (' +
                    prescription.SpecialistRecommendationObjectId.Code +
                    ')'
                  : null
              "
            />
            <specialist-recommendation
              :value="prescription.SpecialistRecommendation"
              :disabled="!editable"
            />
          </template>
        </fp-panel-card>
      </div> -->
      <!-- TODO: nem is kell? -->
      <div style="margin: 10px 0px" v-if="prescription.Statement">
        <fp-panel-card title="Állapot" :closedCard="true">
          <template #content>
            <b-row cols="1" cols-md="2">
              <b-col>
                <fp-text-area
                  label="Állapot változás miatti felírás indoklása"
                  description="Állapotváltozás miatt a kihordási időn belül új terméket kell felírni. Ténye, oka, mérték leírása."
                  :value="prescription.Statement.StateChangeDescription"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <fp-text-area
                  label="Méret változás miatti felírás indoklása"
                  description="Méretváltozás miatt a kihordási időn belül új terméket kell felírni. Ténye, oka, mérték leírása."
                  :value="prescription.Statement.SizeChangeDescription"
                  :disabled="true"
                />
              </b-col>
              <b-col>
                <fp-input
                  label="Beteg által tett nyilatkozat rendelkezésre áll?"
                  :disabled="true"
                  :value="
                    prescription.Statement.PatientStatementAvailable == null
                      ? null
                      : prescription.Statement.PatientStatementAvailable
                      ? 'Igen'
                      : 'Nem'
                  "
                />
              </b-col>
              <b-col>
                <fp-panel-card title="Előzetes engedélyezés">
                  <template #content>
                    <fp-input
                      label="Méltányossági kérelemre adott engedélyszám"
                      :value="
                        prescription.Statement.PreliminaryApproval
                          ? prescription.Statement.PreliminaryApproval
                              .NeakApprovalNumber
                          : null
                      "
                      :disabled="true"
                    />
                    <fp-date-picker
                      label="Engedély érvényességének kezdő dátuma"
                      :value="
                        prescription.Statement.PreliminaryApproval
                          ? prescription.Statement.PreliminaryApproval.ValidFrom
                          : null
                      "
                      :disabled="true"
                    />
                    <fp-date-picker
                      label="Engedély érvényességének végdátuma"
                      :value="
                        prescription.Statement.PreliminaryApproval
                          ? prescription.Statement.PreliminaryApproval.ValidTo
                          : null
                      "
                      :disabled="true"
                    />
                  </template>
                </fp-panel-card>
              </b-col>
            </b-row>
          </template>
        </fp-panel-card>
      </div>
      <fp-panel-card title="Dokumentum adatai" :closedCard="true">
        <template #content>
          <fp-input
            label="Dokumentum EHR azonosítója"
            description="Elbíráláshoz szükséges dokumentum EHR azonosítója"
            :disabled="true"
            :value="prescription.DocumentId"
          />
          <fp-text-area
            label="Dokumentum leírása"
            description="Elbíráláshoz szükséges dokumentum leírása, ha papíralapú"
            :disabled="true"
            :value="prescription.DocumentDescription"
          />
        </template>
      </fp-panel-card>
    </template>
  </fp-form-modal>
</template>
<script>
//import SpecialistRecommendation from "./prescription-components/common/SpecialistRecommendation.vue";
//import PrescriptionProduct from "./prescription-components/form/PrescriptionProduct.vue";
export default {
  //components: { PrescriptionProduct, SpecialistRecommendation },
  name: "EesztPrescriptionForm",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    recipe: Object,
    eesztSexList: Array,
    eesztProductTypeList: Array,
    eesztPrescriptionTypeList: Array,
    eesztPrescriptionCategoryList: Array,
    eesztPatientIdentifierTypeList: Array,
    eesztMedicalDeviceList: Array,
    eesztLegalTypeList: Array,
    eesztISOList: Array,
    eesztBNOList: Array,
    eesztCountryList: Array,
    eesztDosageTypeList: Array,
    editable: Boolean,
    prescriptionId: Number,
    patientId: Number,
  },
  data() {
    return {
      visible: this.value,
      //TODO: medical device
      prescription: this.recipe,
    };
  },
  watch: {
    value(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
    },
    recipe(input) {
      this.prescription = input;
    },
  },
};
</script>
