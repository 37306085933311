<template>
  <div @click="$emit('openDetails')" class="bouble-element">
    <span class="bouble-element-title">{{ title }}</span>
    <span class="bouble-element-count">
      <span class="bouble-element-count-text">
        <b>{{ length }}</b>
      </span>
    </span>
  </div>
</template>
<script>
export default {
  name: "BoubleModalView",
  props: {
    title: String,
    length: Number,
  },
};
</script>