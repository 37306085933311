<template>
  <fp-form-modal
    v-model="visible"
    size="xl"
    :title="
      prescriptionType == 'Cancellation'
        ? 'Sztornó hozzáadása a jelentéshez'
        : 'Recept hozzáadása a jelentéshez'
    "
  >
    <template #content>
      <b-button
        v-if="prescriptionType == 'Normal'"
        @click="addPrescriptionsToReport"
        >Hozzáadás a jelentéshez</b-button
      >
      <b-button
        v-if="prescriptionType == 'Cancellation'"
        @click="addCancellationToReport"
        >Sztornó receptek hozzáadása a jelentéshez</b-button
      >
      <select-prescription-list
        :isDisabledReportedProds="true"
        :canSelectProducts="prescriptionType == 'Normal'"
        :reportId="reportId"
      />
    </template>
  </fp-form-modal>
</template>
<script>
import SelectPrescriptionList from "../Common/SelectPrescriptionList.vue";
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
import { mapActions } from "vuex";
export default {
  components: { SelectPrescriptionList },
  name: "AddPrescription",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    reportId: Number,
    prescriptionType: String, //Normal, Cancellation
  },
  data() {
    return {
      visible: this.value,
    };
  },
  watch: {
    value(input) {
      this.visible = input;
    },
    visible(input) {
      this.$emit("change", input);
      if (!input) {
        this.setStoreData({
          key: "SelectedPrescriptions",
          value: [],
        });
      }
    },
  },
  methods: {
    ...mapActions({
      setStoreData: "setData",
    }),
    async addPrescriptionsToReport() {
      const selectedPrescriptionIds = await MedicalDeviceLogic.addPrescriptionToReport(
        this.reportId,
        this.$store.state.bever.SelectedPrescriptions
      );
      if (!selectedPrescriptionIds.Code || selectedPrescriptionIds.Code == 0) {
        this.visible = false;
        this.$emit("refreshList");
        this.$bvToast.toast(
          "Sikeresen hozzárendelt " +
            this.$store.state.bever.SelectedPrescriptions +
            " db receptet a " +
            this.reportId +
            " azonosítójú jelentéshez!",
          {
            variant: "success",
            title: "Sikeres hozzárendelés!",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(selectedPrescriptionIds.Message, {
          variant: "danger",
          title:
            "Hiba történt a jelentéshez való receptek hozzárendelés során!",
          solid: true,
        });
      }
    },
    async addCancellationToReport() {
      const selectedPrescriptionIds = await MedicalDeviceLogic.addCancellationToReport(
        this.reportId,
        this.$store.state.bever.SelectedPrescriptions
      );
      if (!selectedPrescriptionIds.Code || selectedPrescriptionIds.Code == 0) {
        this.visible = false;
        this.$emit("refreshList");
        this.$bvToast.toast(
          "Sikeresen hozzáadta a sztornó receptet a jelentéshez!",
          {
            variant: "success",
            title: "Sikeres hozzárendelés!",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(selectedPrescriptionIds.Message, {
          variant: "danger",
          title:
            "Hiba történt a jelentéshez való sztornó receptek hozzárendelés során!",
          solid: true,
        });
      }
    },
  },
};
</script>
