import { render, staticRenderFns } from "./BodyWeightModule.vue?vue&type=template&id=77967ece&scoped=true&"
import script from "./BodyWeightModule.vue?vue&type=script&lang=js&"
export * from "./BodyWeightModule.vue?vue&type=script&lang=js&"
import style0 from "./BodyWeightModule.vue?vue&type=style&index=0&id=77967ece&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77967ece",
  null
  
)

export default component.exports