<!-- Version 1.0.1 -->
<template>
  <div v-if="template">
    <fp-panel-card>
      <template #content>
        <!-- Nyelvválasztás, sablon megnevezés -->
        <b-container fluid="true">
          <b-row>
            <!-- Nyelvválasztás -->
            <b-col cols="12" lg="4">
              <fp-select
                type="multi"
                :label="$t('components.templates.lang')"
                :items="Object.values($enums.Languages)"
                :disabled="!(isNewTemplate || isEditTemplate)"
                valueKey="Value"
                v-model="template.SupportedLanguages"
                @change="
                  storeChangedTemplateElements('SupportedLanguages', $event)
                "
                :textKeyConverter="(t) => t.Text"
              >
                <template #element="element">
                  <img
                    style="margin: 0px 5px 0px 10px"
                    width="20px"
                    :src="require('@/assets' + element.flag)"
                  />
                  {{ element.Text }}
                </template>
              </fp-select>
            </b-col>
            <!-- Sablon megnevezés -->
            <b-col cols="12" lg="4">
              <fp-input
                :label="$t('components.templates.name')"
                :disabled="!(isNewTemplate || isEditTemplate)"
                v-model="template.Name"
                @change="storeChangedTemplateElements('Name', $event)"
              />
            </b-col>
          </b-row>
        </b-container>
        <!-- Sablon hozzáadó gombok -->
        <div
          v-if="
            template.SupportedLanguages && template.SupportedLanguages.length
          "
        >
          <button
            v-if="!template.EmailTemplate && (isNewTemplate || isEditTemplate)"
            @click="addEmailTemplate"
            class="eh-action-button"
          >
            <b-icon style="margin: 0px 5px" icon="envelope-fill"></b-icon>
            {{ $t("components.templates.addEmail") }}
          </button>
          <button
            v-if="!template.SmsTemplate && (isNewTemplate || isEditTemplate)"
            @click="addSMSTemplate"
            class="eh-action-button"
          >
            <b-icon
              style="margin: 0px 5px"
              icon="chat-square-text-fill"
            ></b-icon>
            {{ $t("components.templates.addSMS") }}
          </button>
          <button
            v-if="!template.PushTemplate && (isNewTemplate || isEditTemplate)"
            @click="addPUSHTemplate"
            class="eh-action-button"
          >
            <b-icon style="margin: 0px 5px" icon="phone-vibrate"></b-icon>
            {{ $t("components.templates.addPush") }}
          </button>
        </div>
        <!-- Sablonok nyelvek szerint -->
        <fp-template-by-langs
          :supportedLangs="template.SupportedLanguages"
          :emailTemplate.sync="template.EmailTemplate"
          :smsTemplate.sync="template.SmsTemplate"
          :pushTemplate.sync="template.PushTemplate"
          :editable="isNewTemplate || isEditTemplate"
          @changedTemplate="changedTemplate"
        />
        <!-- Sablon hozzárendelés projekthez -->
        <b-check
          v-if="hasPermissionAddToProject"
          :disabled="
            !(
              isNewTemplate ||
              (isEditTemplate && hasPermissionRemoveFromProject)
            )
          "
          v-model="addTemplateToProject"
          >{{ $t("components.templates.addToProject") }}</b-check
        >
      </template>
    </fp-panel-card>
    <!-- Szerkesztés -->
    <button
      class="eh-action-button"
      v-if="!isNewTemplate && !isEditTemplate"
      @click="$emit('update:isEditTemplate', true)"
    >
      {{ $t("base.basic.edit") }}
    </button>
    <!-- Törlés -->
    <button
      class="eh-action-button"
      v-if="!isNewTemplate && !isEditTemplate"
      @click="removeTemplate"
    >
      {{ $t("base.basic.delete") }}
    </button>
    <!-- Mentés/Létrehozás -->
    <button
      class="eh-action-button"
      v-if="isEditTemplate || isNewTemplate"
      @click="saveTemplate"
    >
      {{ $t("base.basic.save") }}
    </button>
    <!-- Mégsem -->
    <button
      class="eh-action-button"
      v-if="isEditTemplate || isNewTemplate"
      @click="cancelTemplate"
    >
      {{ $t("base.basic.cancel") }}
    </button>
  </div>
</template>
<script>
export default {
  name: "TemplateContent",
  props: {
    selectedTemplate: Object,
    isNewTemplate: Boolean,
    isEditTemplate: Boolean,
    hasPermissionAddToProject: Boolean,
    hasPermissionRemoveFromProject: Boolean,
    addedToProject: Boolean,
  },
  data() {
    return {
      template: JSON.parse(JSON.stringify(this.selectedTemplate)),
      changeTemplateElements: null,
      addTemplateToProject: this.addedToProject,
      defaultTemplate: {
        SupportedLanguages: null,
        Name: null,
        EmailTemplate: null,
        SmsTemplate: null,
        PushTemplate: null,
      },
    };
  },
  watch: {
    selectedTemplate(input) {
      this.template = JSON.parse(JSON.stringify(input));
      this.changeTemplateElements = null;
    },
    addedToProject(input) {
      this.addTemplateToProject = input;
    },
    isNewTemplate(input) {
      if (input) {
        this.addTemplateToProject = false;
        this.template = JSON.parse(JSON.stringify(this.defaultTemplate));
        this.changeTemplateElements = null;
      }
    },
  },
  methods: {
    changedTemplate(changedValues) {
      if (!this.changeTemplateElements) {
        this.changeTemplateElements = {};
      }
      if (
        Object.keys(changedValues).includes("EmailTemplate") &&
        !changedValues.EmailTemplate
      ) {
        Object.keys(this.changeTemplateElements).forEach((key) => {
          if (key.includes("EmailTemplate")) {
            delete this.changeTemplateElements[key];
          }
        });
      } else if (
        Object.keys(changedValues).includes("SmsTemplate") &&
        !changedValues.SmsTemplate
      ) {
        Object.keys(this.changeTemplateElements).forEach((key) => {
          if (key.includes("SmsTemplate")) {
            delete this.changeTemplateElements[key];
          }
        });
      } else if (
        Object.keys(changedValues).includes("PushTemplate") &&
        !changedValues.PushTemplate
      ) {
        Object.keys(this.changeTemplateElements).forEach((key) => {
          if (key.includes("PushTemplate")) {
            delete this.changeTemplateElements[key];
          }
        });
      }
      Object.assign(this.changeTemplateElements, changedValues);
    },
    storeChangedTemplateElements(path, value) {
      if (!this.changeTemplateElements) {
        this.changeTemplateElements = {};
      }
      this.$set(this.changeTemplateElements, path, value);
    },
    //sablonhoz email hozzáadása
    addEmailTemplate() {
      this.$set(this.template, "EmailTemplate", {});
    },
    //sablonhoz sms hozzáadása
    addSMSTemplate() {
      this.$set(this.template, "SmsTemplate", {});
    },
    //sablonhoz push hozzáadása
    addPUSHTemplate() {
      this.$set(this.template, "PushTemplate", {});
    },
    saveTemplate() {
      if (this.isNewTemplate) {
        this.$emit("createTemplate", {
          Template: this.template,
          AddToProject: this.addTemplateToProject,
        });
      } else if (this.isEditTemplate) {
        this.$emit("setTemplate", {
          Changes: this.changeTemplateElements,
          AddToProject: this.addTemplateToProject,
        });
      }
    },
    cancelTemplate() {
      this.addTemplateToProject = this.addedToProject;
      if (this.isEditTemplate) {
        this.$emit("update:isEditTemplate", false);
        this.template = JSON.parse(JSON.stringify(this.selectedTemplate));
      }
      if (this.isNewTemplate) {
        this.template = JSON.parse(JSON.stringify(this.defaultTemplate));
      }
      this.changeTemplateElements = null;
    },
    removeTemplate() {
      //TODO: globális szótár --> template.removeTemplateConfirm
      if (confirm("Biztosan törölni szeretné a sablont?")) {
        this.$emit("removeTemplate");
      }
    },
  },
};
</script>
