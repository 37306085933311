<template>
  <div class="profile page-background">
    <fp-table-list
        class="questionnaires-to-patient-table"
        :bordered="true"
        :hasInputFilter="true"
        :hasDateFilter="true"
        dateFilterStartDate="Date"
        dateFilterEndDate="Date"
        :inputFilterKeyList="[
          'Type',
          'Parameter',
          'User',
          'Result'
      ]"
        :items="processListItems"
        :fields="processListFields"
    />
  </div>
</template>

<script>
export default {
  name: "WebAppPartnerProcesses",
  data() {
    return {
      processListItems: [
      ],
      processListFields: [
        {
          key: "Date",
          label: this.$t("partner.logs.table.date"),
        },
        {
          key: "Type",
          label: this.$t("partner.logs.table.type"),
        },
        {
          key: "Parameter",
          label: this.$t("partner.logs.table.parameter"),
        },
        {
          key: "User",
          label: this.$t("partner.logs.table.user"),
        },
        {
          key: "Result",
          label: this.$t("partner.logs.table.result"),
        }
      ],
    }
  },
  mounted() {
    this.$emit('title-changed', this.$t("partner.logs.title"))
  }
}
</script>

<style scoped>

</style>