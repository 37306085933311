<template>
  <div>
    <component
      class="eh-select-component"
      :id="id"
      :is="type"
      :list="tempList"
      :valueKey="valueKey"
      :textKey="textKey ? textKey : null"
      :label="label"
      :description="description"
      :error="error"
      :required="required"
      :disabled="disabled"
      :state="state"
      :placeholder="placeholder"
      :searchable="searchable"
      :textStyle="textStyle"
      :textKeyConverter="textKeyConverter"
      @search="$emit('search', $event)"
      v-model="tempValue"
    >
      <template #element="element">
        <slot name="element" v-bind="element"></slot>
      </template>
    </component>
  </div>
</template>
<script>
import MultiSelect from "./MultiSelect.vue";
import SingleSelect from "./SingleSelect.vue";

export default {
  components: { single: SingleSelect, multi: MultiSelect },
  name: "fp-select",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    id: [Number, String, Boolean],
    type: { type: String, default: "single" }, //single,multi
    items: Array,
    value: [Object, String, Number, Boolean, Array],
    label: String,
    valueKey: String,
    required: Boolean,
    disabled: Boolean,
    state: { type: Boolean, default: true },
    description: String,
    error: String,
    textKey: String,
    placeholder: String,
    searchable: Boolean,
    textStyle: String, //dark, light
    textKeyConverter: Function,
  },
  data() {
    return {
      tempValue: this.value,
      tempList: this.items ? JSON.parse(JSON.stringify(this.items)) : null,
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
    value(input) {
      this.tempValue = input;
    },
    items(input) {
      this.tempList = input ? JSON.parse(JSON.stringify(input)) : null;
    },
  },
  beforeMount() {
    this.tempList = this.items ? JSON.parse(JSON.stringify(this.items)) : null;
  },
};
</script>

