<template>
  <div>
    <div v-if="prescriptionData">
      <b-iconstack
        v-b-tooltip.hover="'Recept állapota'"
        font-scale="1.8"
        style="margin: 0px 5px"
      >
        <b-icon
          stacked
          :icon="prescriptionClosed ? 'archive-fill' : 'archive'"
        />
      </b-iconstack>
      {{ prescriptionClosed ? "Archivált" : "Aktív" }}
    </div>
    <div v-if="prescriptionData">
      <b-iconstack
        v-b-tooltip.hover="'Recept azonosító'"
        font-scale="1.8"
        style="margin: 0px 5px"
      >
        <b-icon stacked icon="file-binary" />
        <b-icon stacked icon="text-left" scale="0.5" shift-v="3" />
      </b-iconstack>
      {{ prescriptionId }}
    </div>
    <div v-if="prescriptionData">
      <div style="display: flex; align-items: center">
        <fp-svg-icon
          v-b-tooltip.hover="'Recept EESZT azonosító'"
          icon="eeszt-logo"
          style="background: transparent; margin: 0px; padding: 0px"
          color="black"
        />
        {{ prescriptionData.EesztId }}
      </div>
    </div>
    <div v-if="prescriptionData">
      <div style="display: flex; align-items: center">
        <fp-svg-icon
          v-b-tooltip.hover="'Recept papíralapú NEAK vényazonosító'"
          icon="neak-logo"
          :size="27"
          style="background: transparent; margin: 0px; padding: 0px"
          color="black"
        />
        {{ prescriptionData.NeakId }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrescriptionIds",
  props: {
    prescriptionData: Object,
    prescriptionClosed: Boolean,
    prescriptionId: Number,
  },
};
</script>
