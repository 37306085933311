<template>
  <b-modal
    size="xl"
    id="dictate-text-input-modal"
    :title="$t('components.dictate.addNewDoc')"
    :hide-footer="true"
  >
    <b-container>
      <b-row class="align-items-center">
        <b-col cols="3 auto">
          <b-button @click="toggleRecording">{{ buttonText }}</b-button></b-col
        ><b-col cols="4" class="justify-content-middle">
          <b-button
            class="align-middle"
            :disabled="!audioSource"
            @click="uploadAudio()"
            >{{ $t("components.dictate.textGenerate") }}</b-button
          >
          <b-spinner
            :hidden="!uploadInProgress"
            class="align-middle ml-3"
            variant="primary"
            :label="$t('components.dictate.inProgress')"
          ></b-spinner>
        </b-col>
        <b-col cols="5 auto">
          <audio class="align-middle" controls ref="audio" style="width: 100%">
            {{ $t("components.dictate.notSupported") }}
          </audio>
        </b-col>
      </b-row>
      <b-row
        ><b-col class="mt-3">
          <b-form-group
            id="fieldset-1"
            :label="$t('components.dictate.writedText')"
            :description="$t('components.dictate.writedTextDescription')"
            label-for="input-1"
          >
            <b-form-textarea id="input-1" v-model="inputText" rows="6" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row
        ><b-col class="mt-3">
          <b-form-group
            id="fieldset-2"
            :description="$t('components.dictate.docName')"
            label-for="input-2"
          >
            <template v-slot:label>
              {{ $t("components.dictate.fileName") }}
              <span style="color: red">*</span>
            </template>
            <b-form-input id="input-2" v-model="fileName" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="align-items-center justify-content-md-center text-center">
        <b-col cols="2 auto">
          <b-button variant="primary" @click="saveToFile()">{{
            $t("components.dictate.saveFile")
          }}</b-button></b-col
        ><b-col cols="4 auto">
          <b-button variant="primary" @click="uploadDocument(6)">{{
            $t("components.dictate.uploadPHR")
          }}</b-button>
        </b-col>
        <b-col cols="4 auto">
          <b-button variant="primary" @click="uploadDocument(5)">{{
            $t("components.dictate.uploadOther")
          }}</b-button>
        </b-col>
        <b-col cols="2 auto">
          <b-button variant="dark" @click="closeModal()">{{
            $t("base.basic.close")
          }}</b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import { DocumentsLogic } from "@/logic/backend/documents";
import AudioRecorder from "audio-recorder-polyfill";
import toWav from "audiobuffer-to-wav";

export default {
  name: "DictateTextInput",
  data() {
    return {
      recorder: null,
      recording: false,
      audioSource: null,
      sampleRate: 8000,
      numberOfChannels: null,
      inputText: "",
      fileName: "",
      uploadInProgress: false,
      buttonText: this.$t("components.dictate.startRecord"),
    };
  },
  methods: {
    async toggleRecording() {
      if (this.recording) {
        this.recording = false;
        this.stopRecord();
        this.buttonText = this.$t("components.dictate.startRecord");
      } else {
        this.recording = true;
        await this.startRecord();
        this.buttonText = this.$t("components.dictate.stopRecord");
      }
    },

    async startRecord() {
      let stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.recorder = new MediaRecorder(stream);
      this.recorder.addEventListener("dataavailable", (event) => {
        this.processAudio(event.data);
      });
      this.audioSource = null;
      this.numberOfChannels = null;
      this.$refs.audio.src = null;
      this.recorder.start();
    },

    stopRecord() {
      this.recorder.stop();
      this.recorder.stream.getTracks().forEach((i) => i.stop());
    },

    processAudio(data) {
      // blob -> AudioBuffer conversion
      let fileReader = new FileReader();
      let audioContext = new AudioContext();
      // trick to access this from inside the lambda expressions
      let that = this;
      fileReader.onloadend = () => {
        // blob to ArrayBuffer
        let arrayBuffer = fileReader.result;

        // ArrayBuffer to AudioBuffer
        audioContext.decodeAudioData(arrayBuffer, (audioBuffer) => {
          const DESIRED_SAMPLE_RATE = 8000;
          const offlineCtx = new OfflineAudioContext(
            audioBuffer.numberOfChannels,
            audioBuffer.duration * DESIRED_SAMPLE_RATE,
            DESIRED_SAMPLE_RATE
          );
          const cloneBuffer = offlineCtx.createBuffer(
            audioBuffer.numberOfChannels,
            audioBuffer.length,
            audioBuffer.sampleRate
          );
          // Copy the source data into the offline AudioBuffer
          for (
            let channel = 0;
            channel < audioBuffer.numberOfChannels;
            channel++
          ) {
            cloneBuffer.copyToChannel(
              audioBuffer.getChannelData(channel),
              channel
            );
          }
          // Play it from the beginning.
          const source = offlineCtx.createBufferSource();
          source.buffer = cloneBuffer;
          source.connect(offlineCtx.destination);
          offlineCtx.oncomplete = function (e) {
            // `resampledAudioBuffer` contains an AudioBuffer resampled at 8 kHz.
            // use resampled.getChannelData(x) to get an Float32Array for channel x.
            const resampledAudioBuffer = e.renderedBuffer;
            that.audioSource = toWav(resampledAudioBuffer);
            that.$refs.audio.src = URL.createObjectURL(
              new Blob([that.audioSource])
            );
            that.numberOfChannels = resampledAudioBuffer.numberOfChannels;
          };
          offlineCtx.startRendering();
          source.start(0);
        });
      };
      fileReader.readAsArrayBuffer(data);
    },

    async uploadAudio() {
      // TODO: number of channels!
      //console.log(this.numberOfChannels)

      const jsonData = {
        job_type: "batch_transcription",
        operating_mode: "accurate",
        client_data: {
          PartnerGUID: process.env.VUE_APP_BELUX_PARTNER_GUID,
          CustomerGUID: process.env.VUE_APP_BELUX_CUSTOMER_GUID,
        },
        model: {
          sample_rate: this.sampleRate,
          name: process.env.VUE_APP_BELUX_DICTIONARY_KEY,
        },
        channels: {
          // TODO: make this dynamic, or the data single channel
          channel1: {
            format: "audio/wav",
            result_format: "transcript",
          },
        },
      };

      const audioData = new Blob([this.audioSource], { type: "audio/wav" });

      const formData = new FormData();
      formData.append("json", JSON.stringify(jsonData));
      formData.append("channel1", audioData);

      const response = await fetch(
        new URL(
          process.env.VUE_APP_BELUX_API_JOBS_PATH,
          process.env.VUE_APP_BELUX_API_URL
        ),
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        alert(
          this.$t("requestResponse.dictate.errorInCommunication", {
            response: response.status,
          })
        );
        return;
      }
      const content = await response.json();
      if (!content || !content.reference) {
        alert(this.$t("requestResponse.dictate.errorFormat"));
        return;
      }
      // starting the checking loop
      setTimeout(this.checkStatus, 2000, content.reference);

      // starting spinner
      this.uploadInProgress = true;
    },

    // checks the status of the transcription, calls itself if necessary
    async checkStatus(reference) {
      // creating url
      const url = new URL(
        process.env.VUE_APP_BELUX_API_JOBS_PATH.concat(
          "/" + reference + "/status"
        ),
        process.env.VUE_APP_BELUX_API_URL
      );
      // checking status
      const response = await fetch(url, { method: "GET" });
      // ### error checks
      if (!response.ok) {
        alert(
          this.$t("requestResponse.dictate.errorInCommunication", {
            response: response.status,
          })
        );
        // hiding spinner
        this.uploadInProgress = false;
        return;
      }
      const content = await response.json();
      if (!content || !content.status) {
        alert(this.$t("requestResponse.dictate.errorFormat"));
        // hiding spinner
        this.uploadInProgress = false;
        return;
      }
      // ### checking result
      // done
      if (content.status == "TRANSCRIBED" || content.status == "COMPLETE") {
        // ### loading result
        // creating url
        const url = new URL(
          process.env.VUE_APP_BELUX_API_JOBS_PATH.concat(
            "/" + reference + "/results"
          ),
          process.env.VUE_APP_BELUX_API_URL
        );
        // starting request
        const response2 = await fetch(url, { method: "GET" });
        // ### error checks
        if (!response2.ok) {
          alert(
            this.$t("requestResponse.dictate.errorInCommunication", {
              response: response2.status,
            })
          );
          // hiding spinner
          this.uploadInProgress = false;
          return;
        }
        const content2 = await response2.json();
        if (!content2 || !response2.status) {
          alert(this.$t("requestResponse.dictate.errorFormat"));
          // hiding spinner
          this.uploadInProgress = false;
          return;
        }
        //console.log('OK')
        //console.log(content2)
        // loading text
        content2.channels.channel1.transcript.forEach(
          (x) => (this.inputText += x.text + " ")
        );
        // hiding spinner
        this.uploadInProgress = false;
      }
      // error
      else if (content.status == "FAILED" || content.status == "ABORTED") {
        alert(
          this.$t("requestResponse.dictate.errorProcess", {
            response: content.status,
          })
        );
        // hiding spinner
        this.uploadInProgress = false;
      }
      // still in progress
      else {
        // calling ourselves again
        setTimeout(this.checkStatus, 2000, content.reference);
      }
    },

    async saveToFile() {
      if (this.fileName.length < 1) {
        alert(this.$t("requestResponse.dictate.pleaseFile"));
        return;
      }

      const link = document.createElement("a");
      const file = new Blob([this.inputText], { type: "text/plain" });

      link.href = URL.createObjectURL(file);
      link.download = this.fileName.concat(".txt");
      link.click();

      URL.revokeObjectURL(link.href);
    },

    async uploadDocument(documentType) {
      if (this.fileName.length < 1) {
        alert(this.$t("requestResponse.dictate.pleaseFile"));
        return;
      }
      const file = new Blob([this.inputText], { type: "text/plain" });
      // creating document
      const createdDocumentResult = await DocumentsLogic.createDocument({
        accessRights: "*",
        doclibNode: -1,
        documentType: documentType,
        fileSize: file.size,
        filename: this.fileName.concat(".txt"),
        name: this.fileName,
        owner: "ehss",
      });
      // error check
      if (!createdDocumentResult) {
        alert(this.$t("requestResponse.dictate.errorCreateDoc"));
        return;
      }
      // uploading document
      const uploadResult = await DocumentsLogic.uploadDocument(
        file,
        createdDocumentResult.documentId
      );
      //console.log(uploadResult)
      // error check
      if (uploadResult) {
        alert(this.$t("requestResponse.dictate.successUpload"));
      } else {
        alert(this.$t("requestResponse.dictate.errorUploadDoc"));
      }
    },

    async closeModal() {
      this.$bvModal.hide("dictate-text-input-modal");
      this.$emit("onClose");
    },
  },
  mounted() {
    if (!this.Window.MediaRecorder) {
      this.Window.MediaRecorder = AudioRecorder;
      console("Default media recorder not found, switching to polyfill");
    }
  },
};
</script>
