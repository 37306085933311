<template>
  <div v-if="arrayData && groupedArrays" class="medical-profile-data">
    <div class="fp-boubles-title">{{$t('client.profile.medicalData.previousProcedure')}}</div>
    <div class="fp-empty" v-if="groupedArrays.length == 0 && disabled">
      {{$t('base.noData')}}
    </div>
    <div v-else>
      <div class="fp-boubles" v-for="group in groupedArrays" :key="group.key">
        <fp-bouble-modal
          :title="group.key"
          :length="group.value.length"
          @openDetails="$set(group, 'OpenDetails', true)"
        />
        <!-- Lista megtekintés -->
        <fp-form-modal v-model="group.OpenDetails" :title="group.key">
          <template #content>
            <b-list-group
              v-if="group.value.length > 0"
              class="fp-list-box-group"
            >
              <b-list-group-item
                v-for="(element, index) in group.value"
                :key="index"
                class="fp-group-item"
                :class="
                  group.value.length == 1
                    ? ' single-element '
                    : (index == group.value.length - 1
                        ? ' last-element '
                        : ' not-last-element ') + ' multiple-element '
                "
              >
                <div>
                  <b>{{$t('client.profile.medicalData.oenoCode')}}: </b>{{ getDisplayData(element.Code) }}<br />
                  <b>{{$t('client.profile.medicalData.startDate')}}: </b
                  >{{
                    getDisplayData(
                      $convertDateToString(element.StartDate, "YYYY-MM-DD")
                    )
                  }}<br />
                  <b>{{$t('client.profile.medicalData.endDate')}}: </b
                  >{{
                    getDisplayData(
                      $convertDateToString(element.EndDate, "YYYY-MM-DD"),
                      element.StartDate ? $t('client.profile.medicalData.stillLasts') : null
                    )
                  }}<br />
                  <b>{{$t('client.profile.medicalData.result')}}: </b>{{ getDisplayData(element.Result) }}
                  <!-- convert -->
                </div>
                <div
                  class="item-delete-icon"
                  v-b-tooltip.hover="$t('base.basic.delete')"
                  :style="
                    disabled
                      ? 'display:none'
                      : 'vertical-align: bottom; margin: auto 0px auto 40px'
                  "
                >
                  <b-icon
                    icon="x-circle"
                    style="cursor: pointer"
                    @click="deleteElement(element)"
                  />
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
        </fp-form-modal>
      </div>
      <div v-if="!disabled" class="fp-plus-icon">
        <b-icon @click="showCreateNew = true" icon="plus-circle-fill" />
      </div>
    </div>
    <!-- Új elem felvétele -->
    <fp-form-modal v-model="showCreateNew" :title="$t('client.profile.medicalData.newPreviousProcedure')">
      <template #content>
        <fp-select
          v-if="showNameSelectList"
          :label="$t('client.profile.medicalData.procedure')"
          :items="list"
          :description="$t('client.profile.medicalData.procedureFromList')"
          :searchable="true"
          :state="!validation.hasError('newElement.Name')"
          :error="validation.firstError('newElement.Name')"
          v-model="selectedBaseElement"
          @change="selectBaseElement"
          @search="search($event)"
        >
          <template #element="element">
            {{ element.Name }}<br />
            {{$t('client.profile.medicalData.oenoCode')}}: {{ element.Code }}
          </template>
        </fp-select>
        <fp-input
          v-if="showNameInput"
          :label="$t('client.profile.medicalData.procedureName')"
          :description="$t('client.profile.medicalData.procedureBySelf')"
          :state="!validation.hasError('newElement.Name')"
          :error="validation.firstError('newElement.Name')"
          v-model="newElement.NameWriteInput"
          @change="addNewElementNameInput"
        />
        <fp-input
          :label="$t('client.profile.medicalData.startDate')"
          v-model="newElement.StartDate"
        />
        <fp-input :label="$t('client.profile.medicalData.endDate')" v-model="newElement.EndDate" />
        <fp-input :label="$t('client.profile.medicalData.result')" v-model="newElement.Result" />
        <b-button @click="addNewElement">{{$t('base.basic.save')}}</b-button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
//import { HelperMethods } from "@/logic/ui/helpers";
import { BaseDataLogic } from "@/logic/backend/base-data";
export default {
  name: "PreviousProcedure",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Array,
    disabled: Boolean,
  },
  data() {
    return {
      arrayData: this.value,
      groupedArrays: [],
      showCreateNew: false,
      newElement: {
        ProdedureId: null,
        Name: null,
        NameWriteInput: null,
        Code: null,
        StartDate: null,
        EndDate: null,
        Result: null,
      },
      defaultNewElement: {
        ProdedureId: null,
        Name: null,
        Code: null,
        StartDate: null,
        EndDate: null,
        Result: null,
      },
      selectedBaseElement: null,
      showNameSelectList: true,
      showNameInput: true,
      list: null,
    };
  },
  validators: {
    "newElement.Name": function (value) {
      return this.$validator.value(value).required();
    },
  },
  watch: {
    value(input) {
      this.arrayData = input;
      this.groupedArrays = this.checkDuplicateName();
    },
  },
  methods: {
    /* Ellenőrzi és rendezi a diplukált csoportokat a bouble nevek alapján.
       Ellátja az elemeke ElementId-val, ami a törléshez szükséges az elem beazonosításához. */
    //duplikáció vizsgálat
    checkDuplicateName() {
      //index alapból 0
      var index = 0;
      //végig megyünk a lista minden elemén és adunk nekik Element Id-t
      this.arrayData.forEach((x) => (x.ElementId = index++));
      //csoportosítjuk a lista elemeket megadott változó alapján
      var tempMap = this.$groupByMostEfficient(
        this.arrayData,
        (listE) => listE.Name
      );
      //a kapott csoportosított map átalakítása listává
      var tempArray = [];
      tempMap.forEach((value, key) => {
        if (key) {
          tempArray.push({ key: key, value: value });
        }
      });
      //vissza érünk a generált listával
      return tempArray;
    },
    selectBaseElement(elem) {
      this.newElement.Code = elem.Code;
      this.newElement.Name = elem.Name;
      this.newElement.ProdedureId = elem.ProdedureId;
    },
    addNewElementNameInput(input) {
      if (input && input.length > 0) {
        this.showNameSelectList = false;
        this.newElement.Name = input;
        this.newElement.Code = null;
        this.newElement.ProdedureId = null;
      } else {
        this.showNameSelectList = true;
      }
    },
    deleteElement(elem) {
      const index = this.arrayData.indexOf(elem);
      if (index >= 0) {
        this.arrayData.splice(index, 1);
        this.groupedArrays = this.checkDuplicateName();
        this.$emit("change", this.arrayData);
      }
    },
    getDisplayData(data, emptyText) {
      if (data) {
        return data;
      } else if (emptyText) {
        return emptyText;
      }
      return this.$t('client.profile.medicalData.noData');
    },
    async addNewElement() {
      const valid = await this.$validate();
      if (valid) {
        this.newElement.StartDate = this.$convertDateToString(
          this.newElement.StartDate
        );
        this.newElement.EndDate = this.$convertDateToString(
          this.newElement.EndDate
        );
        this.arrayData.push(this.newElement);
        this.groupedArrays = this.checkDuplicateName();
        this.$emit("change", this.arrayData);
        this.showCreateNew = false;
        this.newElement = JSON.parse(JSON.stringify(this.defaultNewElement));
        this.validation.reset();
      }
    },
    async search(input) {
      if (input && input.length > 2) {
        var temp = [];
        const searchByName = await this.getList({ ["Name." + this.$i18n.locale]: input });
        const searchByCode = await this.getList({
          "Code-contains": input,
        });
        temp = searchByName.concat(searchByCode);
        this.list = temp;
      } else {
        this.list = await this.getList({ "-limit": 10 });
      }
    },
    async getList(params) {
      const getListResponse = await await BaseDataLogic.getBaseData(
        "procedure",
        params
      );
      if (!getListResponse.Code) {
        return getListResponse;
      } else {
        //hiba
      }
      return null;
    },
  },
  async beforeMount() {
    this.groupedArrays = this.checkDuplicateName();
    this.list = await this.getList({ "-limit": 10 });
  },
};
</script>