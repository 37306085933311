<template>
  <div>
    <div v-if="dispenseData">
      <!-- Kiadás dátuma -->
      <div class="prescription-data-element">
        <b-iconstack
          v-b-tooltip.hover="'Recept kiadás dátuma'"
          font-scale="1.8"
          style="margin: 0px 5px"
        >
          <b-icon stacked icon="calendar" />
          <b-icon
            stacked
            icon="box-arrow-right"
            scale="0.6"
            shift-v="-1"
          ></b-icon>
        </b-iconstack>
        {{
          $convertDateToString(
            dispenseData.DispenseTimestamp,
            "YYYY-MM-DD HH:mm"
          )
        }}
      </div>
      <!-- Kiadó -->
      <div v-if="dispenseData && dispenseData.Dispenser">
        <b-iconstack v-b-tooltip.hover="'Kiadó'" font-scale="2.5">
          <b-icon
            stacked
            icon="person-fill"
            scale="0.5"
            shift-h="5"
            shift-v="-4.5"
          ></b-icon>
          <b-icon
            stacked
            icon="file-text"
            scale="0.35"
            shift-h="5.5"
            shift-v="4.5"
          ></b-icon>
          <b-icon
            stacked
            icon="box-arrow-right"
            scale="0.78"
            shift-h="-1.5"
          ></b-icon>
        </b-iconstack>
        {{
          dispenseData.Dispenser.FullName +
          " (" +
          dispenseData.Dispenser.EnkkId +
          ")"
        }}
      </div>
      <!-- Foglaló -->
      <div
        v-if="
          dispenseData && dispenseData.BookingUser && !dispenseData.Dispenser
        "
      >
        <b-iconstack v-b-tooltip.hover="'Foglaló'" font-scale="2.5">
          <b-icon
            stacked
            icon="person-fill"
            scale="0.5"
            shift-h="5"
            shift-v="-4.5"
          ></b-icon>
          <b-icon
            stacked
            icon="file-text"
            scale="0.35"
            shift-h="5.5"
            shift-v="4.5"
          ></b-icon>
          <b-icon stacked icon="lock" scale="0.78" shift-h="-2.5"></b-icon>
        </b-iconstack>
        {{
          dispenseData.BookingUser.FullName +
          " (" +
          dispenseData.BookingUser.EnkkId +
          ")"
        }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrescriptionDispenseData",
  props: {
    dispenseData: Object,
  },
};
</script>
