<template>
  <div>
    <fp-app-header
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :menuElements="adminMenuElements"
      @forceUpdate="forceUpdate"
    />
    <router-view
      :project="project"
      :key="'views-admin-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="admin"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
    />
  </div>
</template>
<script>
import { UserPreferencesLogic } from "@/logic/backend/user-preferences";
import { ProjectLogic } from "@/logic/backend/project";
export default {
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      forceUpdateKey: 0,
      ptoject: null,
      adminMenuElements: [
        {
          url: "NewProject",
          langKey: "partner.newProject.title",
          key: "new-project",
        },
        {
          url: "Project",
          langKey: "partner.projects.title",
          key: "project",
        },
      ],
    };
  },
  methods: {
    async forceUpdate() {
      ++this.forceUpdateKey;
      await this.getProject();
    },
    async getProject() {
      const selectedProject = this.$loggedUser.SelectedProject;
      if (selectedProject && selectedProject.ProjectId) {
        const getProjectResponse = await ProjectLogic.getProjectDetails({
          ProjectId: selectedProject.ProjectId,
        });
        if (!getProjectResponse.Code) {
          this.project = getProjectResponse[0];
        } else {
          this.$bvToast.toast(getProjectResponse.Message, {
            title: "Hiba",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
          this.project = null;
        }
      } else {
        this.project = null;
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      if (imgUrl) {
        this.userPicture = imgUrl;
      }
    },
  },
  async mounted() {
    await this.getProject();
  },
  beforeDestroy() {
    UserPreferencesLogic.deleteUserPreferencesFromStorage();
  },
};
</script>
