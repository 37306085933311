<template>
  <div
    :key="'components-header-footer-webappspecialistheader-' + forceUpdateKey"
    class="eh-header"
    v-if="siteTitle"
  >
    <Header
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="UserPicture"
      @forceUpdate="forceUpdate"
    />
    <b-container fluid>
      <b-row align-h="around" align-v="center">
        <div>
          <router-link to="Home">
            <button class="eh-header-button">
              {{ $t("healthProfessional.menu.home") }}
            </button>
          </router-link>
        </div>
        <div style="text-align: center">
          <!-- <router-link to="PatientManager">
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.patientInvitation") }}
            </button>
          </router-link> -->
          <router-link to="PatientList">
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.patientList") }}
            </button>
          </router-link>
          <router-link
            v-if="
              $loggedUser.SelectedProject &&
                $loggedUser.SelectedProject.ProjectId
            "
            to="Project"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.project") }}
            </button>
          </router-link>
          <router-link
            v-if="
              visibleUiElement($enums.UserInterfaceElement.Questionnaire.Value)
            "
            to="Questionnaires"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.questionnaires") }}
            </button>
          </router-link>
          <router-link
            v-if="visibleUiElement($enums.UserInterfaceElement.Document.Value)"
            to="Documents"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.documents") }}
            </button>
          </router-link>
          <router-link
            v-if="visibleUiElement($enums.UserInterfaceElement.Workflow.Value)"
            to="Workflow"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.workflow") }}
            </button>
          </router-link>
          <router-link
            v-if="visibleUiElement($enums.UserInterfaceElement.UserTask.Value)"
            to="Tasks"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.tasks") }}
            </button>
          </router-link>
          <router-link
            v-if="
              visibleUiElement($enums.UserInterfaceElement.Appointment.Value)
            "
            to="Appointments"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.appointments") }}
            </button>
          </router-link>
          <router-link
            v-if="
              visibleUiElement(
                $enums.UserInterfaceElement.NotificationTemplate.Value
              )
            "
            to="Templates"
          >
            <button class="eh-header-view-button">
              {{ $t("healthProfessional.menu.templates") }}
            </button>
          </router-link>
          <!-- <router-link to="RecipeManager">
            <button class="eh-header-view-button">
              TODO: szótár
              Recept adminisztráció
            </button>
          </router-link>
          <router-link to="RecipeReportManager">
            <button class="eh-header-view-button">
              TODO: szótár
              Vény jelentések
            </button>
          </router-link> -->
        </div>
        <div>
          <router-link to="/">
            <button @click="close" class="eh-header-button">
              {{ $t("base.basic.exit") }}
            </button>
          </router-link>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import Header from "@/components/header-footer/WebAppHeader.vue";
import { AuthLogic } from "@/logic/backend/auth.js";
export default {
  components: {
    Header,
  },
  props: {
    signedName: String,
    siteTitle: String,
    siteColor: String,
    UserPicture: [Object, String],
    projectEnabledUiElements: Object,
  },
  data() {
    return {
      forceUpdateKey: 0,
    };
  },
  methods: {
    forceUpdate() {
      ++this.forceUpdateKey;
      this.$emit("forceUpdate");
    },
    visibleUiElement(element) {
      if (!this.projectEnabledUiElements) {
        return true;
      } else {
        return (
          this.projectEnabledUiElements.HealthProfessional &&
          this.projectEnabledUiElements.HealthProfessional.includes(element)
        );
      }
    },
    close() {
      AuthLogic.clearSessionStorage();
    },
  },
};
</script>
<style></style>
