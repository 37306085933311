<template>
  <div v-if="amount">
    <fp-input
      label="Felírt termék mennyiség"
      :required="true"
      v-model="amount.Amount"
      @change="changeValue('Amount', $event)"
      :disabled="disabled"
      :state="!validation.hasError('amount.Amount')"
      :error="validation.firstError('amount.Amount')"
    />
    <fp-input
      label="Felírt termék mennyiségi egység"
      :required="true"
      v-model="amount.Unit"
      @change="changeValue('Unit', $event)"
      :disabled="disabled"
      :state="!validation.hasError('amount.Unit')"
      :error="validation.firstError('amount.Unit')"
    />
    <fp-text-area
      label="Felírt mennyiség indoklás"
      v-model="amount.Justification"
      @change="changeValue('Justification', $event)"
      :disabled="disabled"
    />
  </div>
</template>
<script>
export default {
  name: "amount",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      amount: null,
      changeAmountValue: null,
      defaultAmount: {
        Amount: null,
        Unit: null,
        Justification: null,
      },
    };
  },
  watch: {
    value() {
      this.setAmount();
    },
  },
  validators: {
    "amount.Amount": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "amount.Unit": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  methods: {
    setAmount() {
      if (this.value) {
        this.amount = this.value;
      } else {
        this.amount = JSON.parse(JSON.stringify(this.defaultAmount));
      }
      this.changeAmountValue = null;
      this.resetValidation();
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
    changeValue(key, value) {
      if (!this.changeAmountValue) {
        this.changeAmountValue = {};
      }
      this.changeAmountValue[key] = value;
      this.$emit("change", this.amount);
      this.$emit("changeValue", this.changeAmountValue);
    },
  },
  mounted() {
    this.setAmount();
  },
};
</script>
