<template>
  <div>Checkbox</div>
</template>
<script>
export default {
  name: "Checkbox",
  props: {
    tabs: Object,
  },
};
</script>