<template>
  <b-container :fluid="true">
    <b-row style="margin-top: 10px">
      {{ $t("partner.registrationParticipant.accountData") }}
    </b-row>
    <b-row cols="1" cols-md="2">
      <b-col>
        <fp-input
          v-model="userData.Username"
          :required="true"
          :label="$t('partner.registrationParticipant.username')"
        />
      </b-col>
      <b-col>
        <fp-input
          :disabled="true"
          :value="$t(this.$enums.UserRole.HealthProfessional.Text)"
          :label="$t('partner.registrationParticipant.role')"
        />
      </b-col>
      <b-col>
        <fp-input
          v-model="userData.Password"
          :label="$t('partner.registrationParticipant.password')"
          type="password"
          :required="true"
        />
      </b-col>
      <b-col>
        <fp-input
          :label="$t('partner.registrationParticipant.passwordAgain')"
          type="password"
          :required="true"
          v-model="userData.PasswordAgain"
        />
      </b-col>
      <b-col>
        <fp-input
          v-model="userData.PersonName"
          :label="$t('partner.registrationParticipant.name')"
          :required="true"
          :error="validation.firstError('userData.PersonName')"
          :state="!validation.hasError('userData.PersonName')"
        />
      </b-col>
      <!-- TODO: style alakítás -->
      <b-col>
        <fp-select
          v-model="userData.Flags"
          :label="$t('partner.registrationParticipant.flags')"
          valueKey="Value"
          :items="Object.values($enums.UserFlag)"
          class="sex-margin"
          type="multi"
          :textKeyConverter="
            (e) => {
              return $t(e.Text);
            }
          "
        >
          <template #element="element">
            {{ $t(element.Text) }}
          </template>
        </fp-select>
      </b-col>
      <b-col>
        <fp-input
          v-model="userData.EmailAddress"
          :label="$t('partner.registrationParticipant.email')"
          :required="true"
          :error="validation.firstError('userData.EmailAddress')"
          :state="!validation.hasError('userData.EmailAddress')"
        />
      </b-col>
    </b-row>
    <b-row style="text-align: center; margin: 10px 0">
      <b-col>
        <b-button @click="saveUser">{{ $t("base.basic.save") }}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { UserLogic } from "@/logic/backend/user";

export default {
  name: "RegistrationParticipant",
  data() {
    return {
      userData: {
        Roles: [this.$enums.UserRole.HealthProfessional.Value],
        Username: null,
        PersonName: null,
        EmailAddress: null,
        Password: null,
        Flags: [],
      },
    };
  },
  //TODO: szótár
  validators: {
    "userData.PersonName": function(value) {
      return this.$validator.value(value).required("Kötelező mező");
    },
    "userData.EmailAddress": function(value) {
      return this.$validator
        .value(value)
        .required("Kötelező mező")
        .email();
    },
  },
  methods: {
    async saveUser() {
      const valid = await this.$validate();
      if (valid) {
        const saveUserResponse = await UserLogic.createUser(this.userData);
        if (!saveUserResponse.Code) {
          //sikeres felhasználó létrehozás
          this.$bvToast.toast(this.$t("requestResponse.basic.successSave"), {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          });
          //TODO nullozni a userData-t?
        } else {
          //hiba
          this.$bvToast.toast(saveUserResponse.Message, {
            title: this.$t("requestResponse.basic.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch(
      "setPageTitle",
      this.$t("partner.menu.registrationParticipant")
    );
  },
};
</script>
<style scoped>
.sex-margin {
  margin-top: 20px;
}
</style>
