<template>
  <div v-if="!params.Hidden">
    <div style="margin-bottom: 5px" v-if="params.Title">
      <span>{{ params.Title ? params.Title[language] : null }}</span>
      <span v-if="params.Required" class="text-danger">*</span>
    </div>
    <div>
      <fp-toggle-switch
        :state="false"
        :description="params.Description ? params.Description[language] : null"
        :disabled="!params.Enabled != false || disabled"
        type="round"
        :value="tempValue"
        @change="changeValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "SwitchComponent",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Object, Boolean],
    disabled: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value ? this.value : this.params.DefaultValue,
    };
  },
  methods: {
    changeValue(input) {
      this.$emit("change", input);
    },
  },
};
</script>