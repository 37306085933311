<template>
  <div>
    <div ref="textSelectInput" class="eh-select-input-box">
      <div
        @click="isFocus = !isFocus"
        class="eh-select-input"
        :class="setClass"
      >
        <span class="eh-select-input-value">
          {{ setSelectedElementLabel() }}
        </span>
        <span class="select-input-open-icon"
          ><b-icon :icon="isFocus ? 'chevron-up' : 'chevron-down'"></b-icon
        ></span>
      </div>
      <span
        v-if="label"
        @click="isFocus = !isFocus"
        class="eh-select-input-floating-label"
      >
        {{ label }}
        <span v-if="required && label" class="text-danger">*</span>
      </span>
      <span
        @click="isFocus = !isFocus"
        v-if="isFocus && selectedValue && selectedValue.length == 0"
        class="eh-select-input-text-placeholder"
      >
        {{ placeholder ? placeholder : $t("base.pleaseSelect") }}
      </span>
      <div
        v-if="isFocus"
        :class="disabled ? ' disabled ' : ' not-disabled '"
        class="select-options-box"
      >
        <div v-if="searchable">
          <fp-input
            class="eh-select-search-input"
            :placeholder="$t('base.basic.search')"
            v-model="searchedText"
            @change="$emit('search', $event)"
          />
        </div>
        <div v-if="tempList && tempList.length > 0">
          <div
            :id="'option-' + index + '-' + id"
            v-for="(option, index) in tempList"
            :key="'option-' + index + '-' + id"
            @click="!disabled && !option.options ? selectItem(option) : ''"
          >
            <div v-if="option && option.options && option.options.length > 0">
              <b style="padding: 5px">{{ option.label }}</b>
              <div
                :id="'option-' + index + 'sub-option-' + subIndex + '-' + id"
                v-for="(subOption, subIndex) in option.options"
                :key="'option-' + index + 'sub-option-' + subIndex + '-' + id"
                class="eh-select-input-option"
                :class="subOption.Selected ? ' active-option ' : ''"
                @click="disabled ? '' : selectItem(subOption)"
              >
                <b-icon
                  :style="
                    subOption.Selected ? 'color:orange;background:white' : ''
                  "
                  :icon="subOption.Selected ? 'check-square-fill' : 'square'"
                ></b-icon>
                <slot name="element" v-bind="subOption">
                  {{ setListElementLabel(subOption) }}
                </slot>
              </div>
            </div>

            <div
              class="eh-select-input-option"
              :class="
                !option.options && option.Selected ? ' active-option ' : ''
              "
              v-else
            >
              <b-icon
                :style="option.Selected ? 'color:orange;background:white' : ''"
                :icon="option.Selected ? 'check-square-fill' : 'square'"
              ></b-icon>
              <slot name="element" v-bind="option">
                {{ setListElementLabel(option) }}
              </slot>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="eh-select-input-option" @click="selectItem(null)">
            {{ $t("base.noData") }}
          </div>
        </div>
      </div>
      <div class="eh-select-input-desc-error-box">
        <div v-if="description" class="eh-select-input-description">
          {{ description }}
        </div>
        <div v-if="!state && error" class="eh-select-input-error-text">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MultiSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    id: [Number, String, Boolean],
    value: [Object, String, Number, Boolean, Array],
    list: Array,
    label: String,
    valueKey: String,
    required: Boolean,
    disabled: Boolean,
    state: { type: Boolean, default: true },
    description: String,
    error: String,
    textKey: String,
    placeholder: String,
    searchable: Boolean,
    textKeyConverter: Function,
  },
  data() {
    return {
      isFocus: false,
      tempValue: this.value,
      tempList: this.checkListType(),
      tabindex: 0,
      searchedText: null,
      firstElement: null,
      selectedValue: [],
      selectedValueObjects: [],
    };
  },
  watch: {
    value(input) {
      this.tempValue = input;
      this.setSelectedValue();
    },
    list() {
      this.tempList = this.checkListType();
      this.setSelectedValue();
    },
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.label) {
        temp += " with-label ";
      } else {
        temp += " without-label ";
      }
      if (this.state) {
        temp += " valid-input ";
      } else {
        temp += " invalid-input ";
      }
      if (this.disabled) {
        temp += " disabled-input ";
      } else {
        temp += " not-disabled-input ";
      }
      if (this.isFocus) {
        temp += " focus-input ";
      } else {
        temp += " not-focus-input ";
      }
      if (this.tempValue && this.tempValue.length > 0) {
        temp += " not-empty-input ";
      } else {
        temp += " empty-input ";
      }
      return temp;
    },
  },
  methods: {
    setSelectedValue() {
      this.selectedValueObjects = [];
      if (this.tempValue && this.tempList) {
        this.tempList.forEach((x) => {
          if (this.valueKey && this.tempValue.includes(x[this.valueKey])) {
            x.Selected = true;
            this.selectedValueObjects.push(x);
          } else if (this.tempValue.includes(x.SimpleArrayToObjectArrayValue)) {
            x.Selected = true;
            this.selectedValueObjects.push(x);
          } else if (this.tempValue.includes(x)) {
            x.Selected = true;
            this.selectedValueObjects.push(x);
          }
        });
      }
      this.firstElement = this.selectedValueObjects[0];
    },
    selectItem(option) {
      var index = 0;
      const valueKey = this.valueKey;
      if (option) {
        this.$set(option, "Selected", !option.Selected);
        if (valueKey) {
          index = this.selectedValueObjects
            .map(function (e) {
              return e[valueKey];
            })
            .indexOf(option[valueKey]);
        } else {
          index = this.selectedValueObjects
            .map(function (e) {
              return e;
            })
            .indexOf(option);
        }
        if (index < 0) {
          this.selectedValueObjects.push(option);
        } else {
          this.selectedValueObjects.splice(index, 1);
        }
        this.changeSelectedValue();
        this.firstElement =
          this.selectedValueObjects && this.selectedValueObjects.length > 0
            ? this.selectedValueObjects[0]
            : null;
      } else {
        this.selectedValueObjects = [];
      }
    },
    setListElementLabel(option) {
      if (this.textKey) {
        return option[this.textKey];
      } else if (option.SimpleArrayToObjectArrayValue) {
        return option.SimpleArrayToObjectArrayValue;
      } else {
        return option;
      }
    },
    changeSelectedValue() {
      if (this.valueKey) {
        this.selectedValue = this.selectedValueObjects.map(
          (x) => x[this.valueKey]
        );
      } else if (
        this.selectedValueObjects &&
        this.selectedValueObjects.length &&
        this.selectedValueObjects[0].SimpleArrayToObjectArrayValue
      ) {
        this.selectedValue = this.selectedValueObjects.map(
          (x) => x.SimpleArrayToObjectArrayValue
        );
      } else {
        this.selectedValue = this.selectedValueObjects;
      }
      this.$emit("change", this.selectedValue);
    },
    setSelectedElementLabel() {
      if (this.textKey && this.firstElement && this.selectedValueObjects) {
        if (this.selectedValueObjects.length > 1) {
          return (
            this.firstElement[this.textKey] +
            " + " +
            this.$t("base.basic.selectSomeElement", {
              piece: this.selectedValueObjects.length - 1,
            })
          );
        } else {
          return this.firstElement[this.textKey];
        }
      } else if (
        this.textKeyConverter &&
        this.firstElement &&
        this.selectedValueObjects
      ) {
        if (this.selectedValueObjects.length > 1) {
          return (
            this.textKeyConverter(this.firstElement) +
            " + " +
            this.$t("base.basic.selectSomeElement", {
              piece: this.selectedValueObjects.length - 1,
            })
          );
        } else {
          return this.textKeyConverter(this.firstElement);
        }
      } else if (
        this.firstElement &&
        this.firstElement.SimpleArrayToObjectArrayValue &&
        this.selectedValueObjects
      ) {
        if (this.selectedValueObjects.length > 1) {
          return (
            this.firstElement.SimpleArrayToObjectArrayValue +
            " + " +
            this.$t("base.basic.selectSomeElement", {
              piece: this.selectedValueObjects.length - 1,
            })
          );
        } else {
          return this.firstElement.SimpleArrayToObjectArrayValue;
        }
      } else if (this.firstElement && this.selectedValueObjects) {
        if (this.selectedValueObjects.length > 1) {
          return (
            this.firstElement +
            " + " +
            this.$t("base.basic.selectSomeElement", {
              piece: this.selectedValueObjects.length - 1,
            }) /* (this.selectedValueObjects.length - 1) +
            " kiválasztott elem" */
          );
        } else {
          return this.firstElement;
        }
      } /* else if (
        (this.label && !this.isFocus) ||
        (this.placeholder && this.isFocus)
      ) {
        return "";
      }  */ else {
        return "";
      }
    },
    clickOutOfBoxEvent(e) {
      if (!this.$refs.textSelectInput.contains(e.target)) {
        this.isFocus = false;
      }
    },
    checkListType() {
      const temp = [];
      if (this.list) {
        this.list.forEach((x) => {
          if (typeof x != "object") {
            temp.push({ SimpleArrayToObjectArrayValue: x, Selected: false });
          } else {
            temp.push(Object.assign(x, { Selected: false }));
          }
        });
      }
      return temp;
    },
  },
  mounted() {
    window.addEventListener("click", this.clickOutOfBoxEvent);
    this.setSelectedValue();
  },
  beforeDestroy() {
    window.removeEventListener("click", this.clickOutOfBoxEvent);
  },
};
</script>
