<template>
  <!-- TODO: felvett betegek orvos rendelése? -->
  <div class="eh-menu-content">
    <div class="eh-flex-container">
      <fp-input
        class="eh-margin-right eh-fix-filter-input-width"
        label="Szűrő"
        @change="filterList"
        v-model="filter"
      />
      <fp-select
        :label="$t('partner.newProject.roles.table.fields.baseRoles')"
        :items="roleSelectOptions"
        valueKey="RoleName"
        @change="filterList"
        v-model="roleFilter"
        class="eh-filter-min-width eh-fix-filter-input-width"
      >
        <template #element="element">
          <span v-if="element.RoleName">{{ element.RoleName }}</span>
          <span v-else>{{ $t("enums.default") }}</span>
        </template>
      </fp-select>
      <button
        class="eh-action-button"
        style="margin-top: 15px"
        @click="showNewParticipantForm = true"
      >
        {{ $t("partner.newProject.participants.addParticipant") }}
      </button>
      <operation-buttons
        style="margin-top: 15px"
        :disableEESZT="true"
        :disableShare="true"
        :uploadByCategory="false"
        :disableDelete="true"
        :disableDictateText="true"
        :disableDownload="true"
        :disableUpload="true"
        @selectAll="selectAll"
        @clearSelected="clearSelected"
      />
    </div>
    <b-container fluid="true">
      <b-row>
        <b-col>
          <fp-table-list
            class="questionnaires-to-patient-table"
            :bordered="true"
            :items="filteredParticipantList"
            :fields="participantListFields"
            selectMode="multi"
            @row-selected="onRowSelected"
            :selectable="true"
            :isSelectAll.sync="isSelectAll"
            :isSelectClear.sync="isSelectClear"
          >
            <template #Selected="{ rowSelected }">
              <template v-if="rowSelected">
                <div style="margin: 0px">
                  <span aria-hidden="true">&check;</span>
                  <span class="sr-only">Selected</span>
                </div>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <template #Superiors="row">
              <div v-if="row.item.Superiors">
                <div v-for="sup in row.item.Superiors" :key="sup.Username">
                  <span>{{ sup.Username + " (" + sup.RoleName + ")" }}</span>
                </div>
              </div>
            </template>
            <template #Username="row">
              {{ row.item.Name ? row.item.Name : row.item.Username }}
            </template>
            <template #Operations="row">
              <b-iconstack
                v-if="hasPermissionToEdit"
                @click="showEditParticipant(row.item)"
                font-scale="1.5"
              >
                <b-icon stacked icon="pencil" />
              </b-iconstack>
              <b-iconstack
                @click="showEditRelationship(row.item)"
                font-scale="2"
              >
                <b-icon
                  stacked
                  icon="diagram3"
                  scale="0.8"
                  shift-v="-3"
                  shift-h="-2"
                ></b-icon>
                <b-icon
                  stacked
                  icon="pencil"
                  scale="0.5"
                  shift-v="4"
                  shift-h="4"
                ></b-icon>
              </b-iconstack>
            </template>
          </fp-table-list>
        </b-col>
      </b-row>
    </b-container>
    <div class="eh-default-first-row-container">
      <button class="eh-action-button" @click="removeSelected()">
        {{ $t("partner.newProject.participants.deleteParticipants") }}
        {{ selectedParticipantList.length }}
      </button>
    </div>
    <new-participant-form
      :projectRoleList="roleList"
      :superiorList="detailedParticipantList"
      v-model="showNewParticipantForm"
      @addParticipant="addParticipant"
    />
    <edit-participant-form
      :participant="editingParticipant"
      :projectRoleList="roleList"
      :projectId="createdProjectId"
      @refreshProject="$emit('getList')"
      v-model="showParticipantEditor"
    />
    <edit-participant-relationships-form
      :participant="editingParticipant"
      :projectRoleList="roleList"
      :participantList="detailedParticipantList"
      :projectId="createdProjectId"
      @refreshProject="$emit('getList')"
      v-model="showRelationshipEditor"
    />
  </div>
</template>

<script>
import { UserLogic } from "@/logic/backend/user";
import OperationButtons from "@/components/table/DocumentOperationButtons.vue";
import NewParticipantForm from "@/components/project-components/NewParticipantForm";
import { ProjectLogic } from "@/logic/backend/project";
import EditParticipantForm from "./EditParticipantForm.vue";
import EditParticipantRelationshipsForm from "./EditParticipantRelationshipsForm.vue";

export default {
  name: "Participants",
  components: {
    OperationButtons,
    NewParticipantForm,
    EditParticipantForm,
    EditParticipantRelationshipsForm,
  },
  props: {
    participantList: {
      type: Array,
      required: true,
    },
    roleList: {
      type: Array,
      required: true,
    },
    isCreated: Boolean,
    createdProjectId: Number,
  },
  data() {
    return {
      isSelectAll: false,
      isSelectClear: false,
      detailedParticipantList: [],
      filteredParticipantList: [],
      selectedParticipantList: [],
      participantListFields: [
        {
          key: "Selected",
          label: this.$t(
            "partner.newProject.participants.table.fields.selected"
          ),
          /*class: "eh-table-select-column-size"*/
        },
        {
          key: "Username",
          label: this.$t("partner.newProject.participants.table.fields.name"),
        },
        {
          key: "RoleName",
          label: this.$t("partner.newProject.participants.table.fields.role"),
        },
        {
          key: "Superiors",
          label: "Felettes",
        },
        {
          key: "Operations",
          label: "",
        },
      ],
      filter: null,
      roleFilter: null,
      assignedDateFilter: null,
      filledDateFilter: null,
      showNewParticipantForm: false,
      editingParticipant: null,
      showParticipantEditor: false,
      showRelationshipEditor: false,
    };
  },
  watch: {
    async participantList() {
      await this.converParticipants();
    },
  },
  computed: {
    roleSelectOptions() {
      let rolesWithDefault = JSON.parse(JSON.stringify(this.roleList));
      rolesWithDefault.push({ RoleName: null });
      return rolesWithDefault;
    },
    hasPermissionToEdit() {
      //TODO
      return true;
    },
  },
  methods: {
    showEditRelationship(participant) {
      this.showRelationshipEditor = true;
      this.editingParticipant = participant;
      console.log("EDITING_RELATIONSHIP", participant);
    },
    showEditParticipant(participant) {
      this.showParticipantEditor = true;
      this.editingParticipant = participant;
      console.log("EDITING_PARTICIPANT", participant);
    },
    async addParticipant(participant) {
      this.$emit("update:participantList", [
        ...this.participantList,
        participant,
      ]);
      if (this.isCreated) {
        await this.addParticipantToProject(participant);
      }
    },
    async addParticipantToProject(participant) {
      const addParticipantRes = await ProjectLogic.createParticipant(
        this.createdProjectId,
        [participant]
      );
      if (!addParticipantRes.Code) {
        //TODO: sikeres
        this.$emit("getList");
      } else {
        //TODO: hiba
      }
    },
    filterList() {
      this.filteredParticipantList = this.detailedParticipantList;
      if (this.filter) {
        this.filteredParticipantList = this.$filterList(
          this.filter,
          this.detailedParticipantList,
          ["Name", "Username"]
        );
      }
      if (this.roleFilter) {
        this.filteredParticipantList = this.filteredParticipantList.filter(
          (participant) => participant.RoleName === this.roleFilter
        );
      }
    },
    selectAll() {
      this.isSelectAll = true;
    },
    clearSelected() {
      this.isSelectClear = true;
    },
    onRowSelected(items) {
      this.selectedParticipantList = items;
    },
    async removeSelected() {
      let newParticipantList = [];
      const removedIds = [];
      this.participantList.forEach(async (participant) => {
        const foundMatch = this.selectedParticipantList.find(
          (selectedParticipant) =>
            selectedParticipant.UserId === participant.UserId
        );
        if (foundMatch === undefined) {
          newParticipantList.push(participant);
        } else {
          removedIds.push(foundMatch.UserId);
        }
      });
      this.$emit("update:participantList", newParticipantList);
      if (this.isCreated) {
        await this.removeParticipantFromProject(removedIds);
      }
    },
    async removeParticipantFromProject(deleteParticipantId) {
      const removeParticipantResponse =
        await ProjectLogic.deleteParticipantFromProject(
          this.createdProjectId,
          deleteParticipantId.toString()
        );
      if (!removeParticipantResponse.Code) {
        //TODO: sikeres
      } else {
        //TODO: hiba
      }
    },
    async converParticipants() {
      //TODO: filter Manager és Doctor baseRole szerepkörre (Patient-ek kihagyása)
      const userIds = this.participantList.map((p) => p.UserId);
      if (userIds && userIds.length) {
        const getUserBase = await UserLogic.getUserData({
          projectId: this.createdProjectId,
        });
        if (!getUserBase.Code) {
          const givenPatientList = JSON.parse(
            JSON.stringify(this.participantList)
          );
          //minden résztvevőhöz hozzárendeljük az elérhető adatait
          givenPatientList.forEach((p) => {
            const u = getUserBase.find((ub) => ub.UserId == p.UserId);
            if (u) {
              Object.assign(p, u);
            }
          });
          //minden résztvevő felettes adatainak kigyűjtése
          givenPatientList.forEach((p) => {
            p.Superiors = [];
            p.SuperiorIds.forEach((sup) => {
              const supD = givenPatientList.find((ub) => ub.UserId == sup);
              if (supD) {
                /*p.Superiors.push({Username: supD.Username, Role: supD.RoleName})*/
                p.Superiors.push({
                  Username: supD.Username,
                  Name: supD.Name,
                  RoleName: supD.RoleName,
                });
              }
            });
          });
          this.detailedParticipantList = this.filteredParticipantList =
            givenPatientList;
        } else {
          //TODO: hiba
        }
      }
    },
  },
  async mounted() {
    await this.converParticipants();
  },
};
</script>

<style scoped>
/* @import "../../styles/fp-table-style.css"; */

.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: -100px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
</style>
