<template>
  <div class="eh-menu-content">
    <div class="eh-flex-container">
      <fp-input
        class="eh-specialist-appointments-filter"
        :label="$t('base.basic.filter')"
        :description="$t('healthProfessional.appointment.filterDescription')"
        @change="filterList"
        v-model="filter"
      />
      <fp-date-range-button
        :label="$t('healthProfessional.appointment.date')"
        v-model="dateFilter"
        @change="filterList"
      />
    </div>
    <fp-table-list
      :items="filteredAppointments"
      :fields="appointmentFields"
      :hasPagination="true"
      :limitedDataLoad="true"
      @nextStackLoad="getNextStackLoad"
    >
      <template #Title="row">
        {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
      </template>
      <template #Description="row">
        {{ getTextByLanguage(row.item.Description, row.item.Languages) }}
      </template>
      <template #StartTime="row">
        <b-icon-clock-fill
          v-b-tooltip.hover="$t('healthProfessional.appointment.inProgress')"
          v-if="isInProgress(row.item.StartTime, row.item.EndTime)"
        ></b-icon-clock-fill>
        {{ $convertDateToString(row.item.StartTime, "YYYY-MM-DD HH:mm") }}
      </template>
      <template #EndTime="row">
        {{ $convertDateToString(row.item.EndTime, "YYYY-MM-DD HH:mm") }}
      </template>
      <template #MaxVisitorCount="row">
        {{ row.item.MaxVisitorCount - row.item.Visitors.length }} /
        {{
          $t("healthProfessional.appointment.person", {
            count: row.item.MaxVisitorCount,
          })
        }}
      </template>
      <template #Location="row">
        {{
          row.item.LocationType == "Online"
            ? row.item.LocationType
            : row.item.Location
        }}
      </template>
      <template #Operations="row">
        <!-- add visitor -->
        <b-iconstack
          class="h1"
          style="cursor: pointer"
          @click="
            showAddNewVisitorModal = true;
            selectedAppointment = row.item;
          "
          v-if="row.item.MaxVisitorCount - row.item.Visitors.length > 0"
          v-b-tooltip.hover="
            $t('healthProfessional.appointment.addParticipant')
          "
        >
          <b-icon stacked icon="person-plus-fill" scale="0.5"></b-icon>
          <b-icon stacked icon="circle"></b-icon>
        </b-iconstack>
        <!-- visitors -->
        <b-iconstack
          class="h1"
          style="cursor: pointer"
          @click="openAppointmentDetails(row)"
          v-if="row.item.Visitors.length > 0 && !row.item._showDetails"
          v-b-tooltip.hover="$t('healthProfessional.appointment.participants')"
        >
          <b-icon stacked icon="person-lines-fill" scale="0.5"></b-icon>
          <b-icon stacked icon="circle"></b-icon>
        </b-iconstack>
        <!-- close -->
        <b-icon
          @click="$set(row.item, '_showDetails', false)"
          v-if="row.item._showDetails"
          style="cursor: pointer"
          class="h1"
          icon="x-circle-fill"
          v-b-tooltip.hover="$t('base.basic.close')"
        ></b-icon>
        <!-- clear -->
        <b-iconstack
          @click="clearAppointment(row.item.AppointmentId)"
          v-if="row.item.Visitors.length > 0 && !row.item._showDetails"
          v-b-tooltip.hover="$t('healthProfessional.appointment.clear')"
          class="h2"
          style="
            color: #ff0000;
            cursor: pointer;
            margin-left: 10px;
            margin-bottom: 12px;
          "
          rotate="45"
        >
          <b-icon stacked icon="circle"></b-icon>
          <b-icon stacked icon="circle" scale="0.78"></b-icon>
          <b-icon stacked icon="circle" scale="0.88"></b-icon>
          <b-icon stacked icon="dash" scale="1.4" shift-v="-0.6"></b-icon>
          <b-icon stacked icon="dash" scale="1.35" shift-v="0.6"></b-icon>
        </b-iconstack>
      </template>
      <template #row-details="row">
        <b>{{ $t("healthProfessional.appointment.participants") }}:</b>
        <!-- <p>
          TODO: érkező API bekötés (userid tömb alapján vissza kapom a user alap
          adatokat)
        </p> -->
        <table>
          <tr>
            <td>{{ $t("healthProfessional.appointment.view") }}</td>
            <td>{{ $t("healthProfessional.appointment.participantsData") }}</td>
            <td></td>
          </tr>
          <tr
            v-for="visitor in openedAppointmentVisitors"
            :key="visitor.UserId"
          >
            <td style="text-align: center">
              <b-check v-model="visitor.Appeared"></b-check>
            </td>
            <td>
              {{
                visitor.Name +
                ", TAJ: " +
                visitor.Ssn +
                ", Születési dátum: " +
                $convertDateToString(visitor.DateOfBirth, "YYYY-MM-DD")
              }}
            </td>
            <td>
              <b-icon
                @click="deleteVisitor(row.item.AppointmentId, visitor.UserId)"
                style="cursor: pointer"
                v-b-tooltip.hover="
                  $t('healthProfessional.appointment.removeParticipant')
                "
                icon="x"
                class="h1"
                variant="danger"
              ></b-icon>
            </td>
          </tr>
        </table>
        <b-button
          @click="
            setPresentedVisitor(openedAppointmentVisitors, row.item.AppointmentId)
          "
          >{{ $t("healthProfessional.appointment.saveView") }}</b-button
        >
      </template>
    </fp-table-list>
    <!-- Időpontra feliratkoztatás -->
    <fp-form-modal
      :title="$t('healthProfessional.appointment.newParticipnat')"
      v-model="showAddNewVisitorModal"
    >
      <template #content>
        <fp-select
          style="margin: 0px 5px"
          :label="$t('healthProfessional.appointment.users')"
          :searchable="true"
          :items="userList"
          valueKey="UserId"
          textKey="Username"
          v-model="selectedUser"
          @search="searchUser"
        >
        </fp-select>
        <b-button @click="addVisitor">{{ $t("base.basic.save") }}</b-button>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import { AppointmentLogic } from "@/logic/backend/appointment";
import { UserLogic } from "@/logic/backend/user";
import moment from "moment";

export default {
  name: "SpecialistAppointments",
  data() {
    return {
      appointments: null,
      appointmentFields: [
        {
          key: "Title",
          label: this.$t("healthProfessional.appointment.title"),
        },
        {
          key: "Description",
          label: this.$t("healthProfessional.appointment.description"),
        },
        {
          key: "StartTime",
          label: this.$t("healthProfessional.appointment.startTime"),
        },
        {
          key: "EndTime",
          label: this.$t("healthProfessional.appointment.endTime"),
        },
        {
          key: "MaxVisitorCount",
          label: this.$t("healthProfessional.appointment.visitorsCount"),
        },
        {
          key: "Location",
          label: this.$t("healthProfessional.appointment.location"),
        },
        { key: "Operations", label: "" },
      ],
      userList: null,
      selectedUser: null,
      selectedAppointment: null,
      filteredAppointments: null,
      now: moment(),
      dateFilter: null,
      filter: null,
      showAddNewVisitorModal: false,
      openedAppointmentVisitors: null,
    };
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (typeof textObject == "object") {
        const lang = languages ? languages : Object.keys(textObject);
        if (lang.includes(this.$i18n.locale)) {
          return textObject[this.$i18n.locale];
        } else if (lang.includes("en")) {
          return textObject.en;
        } else {
          return textObject[lang[0]];
        }
      } else {
        return textObject;
      }
    },
    async openAppointmentDetails(row) {
      this.$set(row.item, "_showDetails", true);
      this.openedAppointmentVisitors = await this.getVisitorBaseData(
        row.item.Visitors
      );
    },
    async getVisitorBaseData(visitors) {
      const visitorsIds = visitors.map((v) => v.UserId);
      const getBaseResponse = await UserLogic.getUserData({
        userIds: visitorsIds,
      });
      if (!getBaseResponse.Code) {
        var baseData = [];
        visitors.forEach((v) => {
          const base = getBaseResponse.find((b) => b.UserId == v.UserId);
          baseData.push({ ...base, ...v });
        });
        return baseData;
      } else {
        //TODO: hiba
        return null;
      }
    },
    filterList() {
      this.filteredAppointments = this.appointments;
      if (this.filter) {
        this.filteredAppointments = this.$filterList(
          this.filter,
          this.appointments,
          ["Title", "Description", "MaxVisitorCount"]
        );
      }
      if (this.dateFilter) {
        this.filteredAppointments = this.filteredAppointments.filter((e) => {
          if (this.dateFilter.from && this.dateFilter.to) {
            return moment(e.StartTime).isBetween(
              this.dateFilter.from,
              moment(this.dateFilter.to).endOf("days"),
              undefined,
              "[]"
            );
          } else if (this.dateFilter.from) {
            return moment(e.StartTime).isSameOrAfter(
              moment(this.dateFilter.from)
            );
          } else if (this.dateFilter.to) {
            return moment(e.StartTime).isSameOrBefore(
              moment(this.dateFilter.to).endOf("days")
            );
          }
          return true;
        });
      }
    },
    isInProgress(start, end) {
      return moment(start).isBefore(this.now) && moment(end).isAfter(this.now);
    },
    async getAppointmentList() {
      this.appointments = this.filteredAppointments =
        await this.getAppointments({
          "-orderby": "StartTime",
          "-limit": 101,
        });
    },
    async getAppointments(params) {
      const getAppointmentsResponse = await AppointmentLogic.getAppointments({
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
        ...params,
      });
      if (!getAppointmentsResponse.Code) {
        return getAppointmentsResponse.filter((appoint) =>
          appoint.PersonnelIds.includes(this.$loggedUser.UserId)
        );
      } else {
        this.$bvToast.toast(getAppointmentsResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.appointment.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getNextStackLoad() {
      //TODO: átvezetés a naplózáshoz
      //ismétlődés nélküli léptetés, időpont kihagyás nélkül
      const temp = await this.getAppointments({
        "StartTime-from":
          this.appointments[this.appointments.length - 1].StartTime,
        "-orderby": "StartTime",
        "-limit": 101,
      });
      const duplicatableE = this.appointments.filter(
        (a) =>
          a.StartTime ==
          this.appointments[this.appointments.length - 1].StartTime
      );
      const deduplicateTemp = temp.filter(
        (t) => !duplicatableE.some((e) => e.AppointmentId == t.AppointmentId)
      );
      this.appointments = this.filteredAppointments =
        this.appointments.concat(deduplicateTemp);
      this.filterList();
    },
    async setPresentedVisitor(visitors, appointmentId) {
      const presentVisitors = visitors
        .filter((v) => v.Appeared)
        .map((v) => v.UserId);
        console.log(presentVisitors)
      const setPresentResponse = await AppointmentLogic.setPresentAppointment(
        appointmentId,
        { PresentVisitorIds: presentVisitors }
      );
      if (!setPresentResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(setPresentResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getUser(params) {
      const getUserResponse = await UserLogic.getUser(params);
      if (!getUserResponse.Code) {
        this.userList = getUserResponse.filter((user) =>
          user.Roles.includes(this.$enums.UserRole.Client.Value)
        );
      } else {
        this.$bvToast.toast(getUserResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.appointment.client"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async searchUser(input) {
      if (input) {
        if (input.length > 2) {
          await this.getUser({ "Username-contains": input });
        } else {
          this.userList = null;
        }
      } else {
        await this.getUser({ "-limit": 20 });
      }
    },
    async addVisitor() {
      const addVisitorResponse = await AppointmentLogic.addVisitorToAppointment(
        this.selectedAppointment.AppointmentId,
        { VisitorId: this.selectedUser }
      );
      if (!addVisitorResponse.Code) {
        await this.getAppointmentList();
        this.showAddNewVisitorModal = false;
      } else {
        this.$bvToast.toast(addVisitorResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async clearAppointment(appointmentId) {
      const clearAppointmentResponse = await AppointmentLogic.clearAppointment(
        appointmentId
      );
      if (!clearAppointmentResponse.Code) {
        await this.getAppointmentList();
        this.$bvToast.toast(
          this.$t("requestResponse.appointment.successClear"),
          {
            title: this.$t("requestResponse.basic.successSave"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(clearAppointmentResponse.Message, {
          title: this.$t("requestResponse.appointment.errorClear"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async deleteVisitor(appointmentId, visitorUserId) {
      const deleteVisitorResponse =
        await AppointmentLogic.deleteVisitorFromAppointment(appointmentId, {
          VisitorId: visitorUserId,
        });
      if (!deleteVisitorResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.appointment.successVisitorDelete"),
          {
            title: this.$t(
              "requestResponse.appointment.successVisitorDeleteMessage"
            ),
            variant: "info",
            solid: true,
          }
        );
        await this.getAppointmentList();
        this.filterList();
      } else {
        //hiba
        this.$bvToast.toast(deleteVisitorResponse.Message, {
          title: this.$t("requestResponse.appointment.errorVisitorDelete"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getAppointmentList();
    await this.getUser({ "-orderby": "Username", "-limit": 20 });
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.appointments")
    );
  },
};
</script>
<style scoped>
.sad {
  color: rgb(255, 197, 89);
}
</style>
