<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="interval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :id="'exercise-duration-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      :axisLabelRender="axisLabelRender"
      background="transparent"
      width="100%"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection>
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData"
          type="Line"
          width="2"
          :xName="Chart.DataProperties.chart.x"
          :yName="Chart.DataProperties.chart.y"
          :marker="IsDashboard ? {} : marker"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData"
          type="Line"
          :xName="Chart.DataProperties.diary.x"
          :yName="Chart.DataProperties.diary.y"
          :marker="marker"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import { DiaryLogic } from "@/logic/backend/diary";
import moment from "moment";
import {
  ChartPlugin,
  DateTime,
  Tooltip,
  StripLine,
  LineSeries,
} from "@syncfusion/ej2-vue-charts";
import { HealthPlanLogic } from "@/logic/backend/health-plan.js";
import { ChartUiLogic } from "@/logic/ui/chart-ui";
import { ChartBackendLogic } from "@/logic/backend/chart";
import IntervalManager from "@/components/module/chart/IntervalManager.vue";

Vue.use(ChartPlugin);

export default {
  name: "ExerciseCalories",
  components: {
    IntervalManager,
  },
  data() {
    return {
      chartData: [],
      currentDate: moment(),
      currentDateText: null,
      interval: null,
      chartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        title: this.IsDashboard
          ? null
          : this.$t("base.calendar.time") + " [" + this.Chart.DisplayUnit + "]",
        labelFormat: "",
        minimum: 0,
        interval: this.IsDashboard ? 3600 / 2 : null, //3600/4
      },
      palettes: ["var(--eh-secondary-5)"],
      marker: {
        visible: true,
      },
      //TODO: tooltip!
      /* tooltip: {
        enable: true,
        header: "Megtett távolság",
        format: "${point.x} : <b>${point.y}</b> m",
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      }, */
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    Interval: String,
    ChartType: String,
    PatientId: Number,
    IsDashboard: Boolean,
    Chart: Object,
    Id: [String, Number],
  },
  provide: {
    chart: [DateTime, Tooltip, StripLine, LineSeries],
  },
  methods: {
    //chart oszlop szöveg megjelenés
    axisLabelRender(args) {
      //vertikális az adott szöveg
      if (args.axis.orientation == this.$enums.DisplayType.Vertical) {
        //formázott szöveg beállítása
        args.text = moment
          .utc(moment.duration(args.value, "seconds").asMilliseconds())
          .format("HH:mm");
      }
    },
    //intervallum csere
    async intervalChanged(currentDate, interval) {
      //kiválasztott dátum tárolása
      this.currentDate = currentDate;
      //kiválasztott intervallum tárolása
      this.interval = interval;
      //intervallum napi?
      if (interval == this.$enums.ChartInterval.Day.Value) {
        //igen, chart típus napló
        this.chartType = this.$enums.DiagramType.diary;
      } else {
        //nem, kapott chart típus tárolása
        this.chartType = this.ChartType;
      }
      //adatok betöltése
      await this.getData();
      //intervallum beállítás
      this.setInterval();
    },
    //intervallum beállítás
    setInterval() {
      //intervallum objektum generálás megadott intervallum és dátum alapján
      const interval = ChartUiLogic.IntervalManager.setInterval(
        this.interval,
        this.xAxis,
        this.currentDate
      );
      //intrevallum beállítás
      this.currentDate = interval.currentDate;
      this.currentDateText = interval.currentDateString;
      this.xAxis = interval.xAxis;
    },
    //adataok betöltés
    async getData() {
      //chart típus chart?
      if (this.chartType == this.$enums.DiagramType.chart) {
        //igen, chart adatok betöltés
        await this.getChartData();
        //chart típusa napló?
      } else if (this.chartType == this.$enums.DiagramType.diary) {
        //igen napló, napló adatok betöltése
        await this.getDiaryData();
      }
      //terv adatok betöltése
      await this.getPlan();
    },
    //terv adatokkérés
    async getPlan() {
      //van beteg id?
      if (this.PatientId) {
        //van, beteg terv adatok betöltése
        await this.getPatientPlanData();
      } else {
        //nincs, saját tervadatok betöltése
        await this.getPlanData();
      }
    },
    //beteg terv betöltése
    async getPatientPlanData() {
      //console.log("Patient ExerciseDuration Plan");
    },
    //saját terv adatok betöltése
    async getPlanData() {
      //kérés indítása
      let getPlanDataResponse = await HealthPlanLogic.getHealthPlanList(
        this.Chart.DisplayPlan.planType,
        {
          Status: this.$enums.HealthGoalStatus.InProgress.Value,
          Type: this.Chart.DisplayPlan.planModuleType,
        }
      );
      //sikeres kérés?
      if (!getPlanDataResponse.Code) {
        //kapott lista nem nulla elemű?
        if (getPlanDataResponse.length > 0) {
          //kapott terv lista első elem tárolás
          var planValue = getPlanDataResponse[0].TargetValue;
          //terv megjelenítő tároló beállítása
          Vue.set(this.yAxis, "stripLines", [
            {
              start: planValue,
              end: 10000000000000000,
              isSegmented: true,
              visible: true,
              zIndex: "Behind",
              opacity: 0.25,
              color: "green",
            },
          ]);
        }
      } else {
        this.$bvToast.toast(getPlanDataResponse.Message, {
          title: this.$t("requestResponse.moduleHealthGoal.errorGet", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //chart adatok betöltése
    async getChartData() {
      //kérés válasz tároló
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //van, beteg chart adat kérés
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        //nincs, saját chart adatok kérés
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott adat tásolás
        this.chartData = this.convertChartData(responseChartData);
        //üres mezők feltöltés
        /* this.fillEmptyDays(this.Chart.DataProperties.chart.x, {
          [this.Chart.DataProperties.chart.y]: null,
        }); */
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //chart adatok konvertálása
    convertChartData(data) {
      //üres segéd változó
      var temp = [];
      //minden elemen végig megyünk
      for (let i = 0; i < data.length; i++) {
        //konvertált adat beillesztése a segéd változóba
        temp[i] = {
          [this.Chart.DataProperties.chart.x]:
            data[i][this.Chart.DataProperties.chart.x],
          [this.Chart.DataProperties.chart.y]: moment
            .duration(data[i][this.Chart.DataProperties.chart.y])
            .asSeconds(),
        };
      }
      //adatok sorrendezése dátum alapján
      temp.sort((a, b) =>
        moment(a[this.Chart.DataProperties.chart.x]).isBefore(
          moment(b[this.Chart.DataProperties.chart.x])
        )
          ? -1
          : 1
      );
      //visszatérünk az átkovertált adatokkal
      return temp;
    },
    //chart intervallum paraméter megadás
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    //naplózott adatok kérés
    async getDiaryData() {
      //kérés válasza
      var responseChartData = null;
      //van beteg?
      if (this.PatientId) {
        //igen, beteg napló dat betöltése
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToDiary()
        );
      } else {
        //nem, saját napló adatok betöltés
        responseChartData = await DiaryLogic.getDiary(
          this.Chart.DatabaseName,
          this.getIntervalParamsToDiary()
        );
      }
      //sikes kérés?
      if (!responseChartData.Code) {
        //kapott adatok tárolása
        this.chartData = this.convertDiaryData(responseChartData);
        //üresmező feltöltése
        /* this.fillEmptyDays(this.Chart.DataProperties.diary.x, {
          [this.Chart.DataProperties.diary.x]: null,
        }); */
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //chart adatok konvertálása
    convertDiaryData(data) {
      //üres segédváltozó
      var temp = [];
      //végig megyünk minden adaton
      for (let i = 0; i < data.length; i++) {
        //konvertált adatot elmentjük
        temp[i] = {
          [this.Chart.DataProperties.diary.x]:
            data[i][this.Chart.DataProperties.diary.x],
          [this.Chart.DataProperties.diary.y]: moment
            .duration(data[i][this.Chart.DataProperties.diary.y])
            .asSeconds(),
        };
      }
      //adatokat sorbarendezzük dátum szerint
      temp.sort((a, b) =>
        moment(a[this.Chart.DataProperties.diary.x]).isBefore(
          moment(b[this.Chart.DataProperties.diary.x])
        )
          ? -1
          : 1
      );
      //visszatérünk a konevertált adatokkal
      return temp;
    },
    //chart intervallum paraméter megadsa
    getIntervalParamsToDiary() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToDiary(
        this.interval,
        this.currentDate
      );
    },
    //üres mező feltöltése
    fillEmptyDays(datetimeName, value) {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          [datetimeName]: moment(lastElement[datetimeName])
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          ...value,
        });
      }
    },
  },
  //betölsékor egyszer lefut
  async mounted() {
    //kaott chart típus tárolás
    this.chartType = this.ChartType;
    //kapott intervall tárolás
    this.interval = this.Interval;
    //intervallum beállítása
    this.setInterval();
    //adatok betöltése
    await this.getData();
  },
};
</script>

<style scoped>
@import "../../../styles/syncfusion-diagrams.css";
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
</style>
