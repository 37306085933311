<template>
  <div>
    <fp-table-list
      :hasPagination="true"
      :items="items"
      :fields="fields"
      sortBy="Status"
      :sortDesc="true"
      sort-icon-left
    >
      <!-- Nincs megjelenő cél -->
      <!-- <template v-slot:empty>{{
        $t("client.healthGoals.table.emptyMessage")
      }}</template> -->
      <!-- Célérték formázás -->
      <template #TargetValue="row">{{
        convertThousandSeparator(row.item.TargetValue)
      }}</template>
      <!-- Célérték haladás fejléc formázás -->
      <template v-slot:head(ValueProgressPercentage="data">
        <div style="width: 250px">{{ data.label }}</div>
      </template>
      <!-- Időbeli haladás fejléc formázás -->
      <template v-slot:head(TimeProgressPercentage="data">
        <div style="width: 250px">{{ data.label }}</div>
      </template>
      <!-- Célérték haladás formázás -->
      <template #ValueProgressPercentage="row">
        <div>
          <!-- PropertyHealthPlanType -->
          <div v-if="planType == $enums.HealthGoalType.property">
            <div style="text-align: center">
              {{ $t("client.healthGoals.completed") }}:
              {{ convertThousandSeparator(countProgressNumber(row.item)) }}
            </div>
            <b-progress
              :value="row.item.ValueProgressPercentage"
              max="100"
              :variant="
                row.item.ValueProgressPercentage < 33
                  ? 'danger'
                  : row.item.ValueProgressPercentage < 66
                  ? 'warning'
                  : row.item.ValueProgressPercentage < 99
                  ? 'info'
                  : 'success'
              "
            ></b-progress>
            <b-row class="nopadding" align-h="between">
              <p>{{ convertThousandSeparator(row.item.StartValue) }}</p>
              <p>{{ parseInt(row.item.ValueProgressPercentage) }}%</p>
              <p>{{ convertThousandSeparator(row.item.TargetValue) }}</p>
            </b-row>
          </div>
          <!-- IntervalHealthPlanType -->
          <div v-else-if="planType == $enums.HealthGoalType.interval">
            <div style="text-align: center">
              {{
                $t("client.healthGoals.completedNumberOfDays", {
                  count: successDaysCount(row.item),
                })
              }}
            </div>
            <b-progress
              :value="row.item.ValueProgressPercentage"
              max="100"
              :variant="
                row.item.ValueProgressPercentage < 33
                  ? 'danger'
                  : row.item.ValueProgressPercentage < 66
                  ? 'warning'
                  : row.item.ValueProgressPercentage < 99
                  ? 'info'
                  : 'success'
              "
            ></b-progress>
            <b-row class="nopadding" align-h="between">
              <p>{{ $t("client.healthGoals.day", { count: 0 }) }}</p>
              <p>{{ parseInt(row.item.ValueProgressPercentage) }}%</p>
              <p>
                {{
                  $t("client.healthGoals.day", {
                    count: targetMinDaysCount(row.item),
                  })
                }}
              </p>
            </b-row>
          </div>
          <!-- DailyHealthPlanType -->
          <div v-else-if="planType == $enums.HealthGoalType.daily">
            <div style="text-align: center">
              {{
                $t("client.healthGoals.completedRelatedNumberOfDays", {
                  count: successNumberOfDaysCount(row.item),
                })
              }}
            </div>
            <b-progress
              :value="row.item.ValueProgressPercentage"
              max="100"
              :variant="
                row.item.ValueProgressPercentage < 33
                  ? 'danger'
                  : row.item.ValueProgressPercentage < 66
                  ? 'warning'
                  : row.item.ValueProgressPercentage < 99
                  ? 'info'
                  : 'success'
              "
            ></b-progress>
            <b-row class="nopadding" align-h="between">
              <p>{{ $t("client.healthGoals.day", { count: 0 }) }}</p>
              <p>{{ parseInt(row.item.ValueProgressPercentage) }}%</p>
              <p>
                {{
                  $t("client.healthGoals.day", {
                    count: row.item.NumberOfDays,
                  })
                }}
              </p>
            </b-row>
          </div>
        </div>
      </template>
      <!-- Időbeli haladás formázás -->
      <template #TimeProgressPercentage="row">
        <div style="text-align: center">
          {{
            $t("client.healthGoals.numberOfDaysRemaining", {
              count: convertThousandSeparator(
                leftDaysCount(row.item.TargetDate) > 0
                  ? leftDaysCount(row.item.TargetDate)
                  : 0
              ),
            })
          }}
        </div>
        <b-progress
          :value="row.item.TimeProgressPercentage"
          max="100"
        ></b-progress>
        <b-row class="nopadding" align-h="between">
          <p>{{ generateDateFormat(row.item.StartDate, "YYYY-MM-DD") }}</p>
          <p>{{ parseInt(row.item.TimeProgressPercentage) }}%</p>
          <p>{{ generateDateFormat(row.item.TargetDate, "YYYY-MM-DD") }}</p>
        </b-row>
      </template>
      <!-- Hozzáadva formázás -->
      <template #MeasuredAt="row">{{
        generateDateFormat(row.item.MeasuredAt, "YYYY-MM-DD")
      }}</template>
      <!-- Állapot formázás -->
      <template #Status="row">
        <img
          v-if="row.item.Status == $enums.HealthGoalStatus.Added.Value"
          style="width: 50px"
          :src="require('@/assets/' + $enums.HealthGoalStatus.Added.Icon)"
        />
        <img
          v-if="row.item.Status == $enums.HealthGoalStatus.InProgress.Value"
          style="width: 50px"
          :src="require('@/assets/' + $enums.HealthGoalStatus.InProgress.Icon)"
        />
        <img
          v-if="row.item.Status == $enums.HealthGoalStatus.EndedSuccess.Value"
          style="width: 50px"
          :src="
            require('@/assets/' + $enums.HealthGoalStatus.EndedSuccess.Icon)
          "
        />
        <img
          v-if="row.item.Status == $enums.HealthGoalStatus.EndedFailure.Value"
          style="width: 50px"
          :src="
            require('@/assets/' + $enums.HealthGoalStatus.EndedFailure.Icon)
          "
        />
      </template>
      <!-- Törlés icon -->
      <template #DeleteItem="row">
        <img
          @click="deleteItem(row.item.GoalId)"
          style="width: 30px; cursor: pointer"
          src="@/assets/settings-button/muvelet4.png"
        />
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import moment from "moment";
import { HealthPlanLogic } from "@/logic/backend/health-plan.js";
export default {
  data() {
    return {
      alert: {
        Message: "",
        IsShow: false,
      },
      fields: [
        {
          key: "TargetValue",
          label: this.$t("client.healthGoals.targetValue"),
        },
        {
          key: "ValueProgressPercentage",
          label: this.$t("client.healthGoals.valueProgressPercentage"),
          sortable: true,
        },
        /* {
          key: "TargetDate",
          label: "Kitűzőtt dátum",
        }, */
        {
          key: "TimeProgressPercentage",
          label: this.$t("client.healthGoals.timeProgressPercentage"),
        },
        {
          key: "Status",
          label: this.$t("client.healthGoals.status"),
        },
        {
          key: "MeasuredAt",
          label: this.$t("client.healthGoals.timestamp"),
          sortable: true,
        },
        {
          key: "DeleteItem",
          label: "",
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
    },
    planType: {
      String,
    },
  },
  methods: {
    countProgressNumber(plan) {
      if (plan.StartValue) {
        return parseInt(
          (parseFloat(plan.ValueProgressPercentage) / 100) *
            Math.abs(plan.TargetValue - plan.StartValue)
        );
      }
      return parseInt(
        (parseFloat(plan.ValueProgressPercentage) / 100) * plan.TargetValue
      );
    },
    convertThousandSeparator(number) {
      return Intl.NumberFormat("hu-HU").format(number);
    },
    generateDateFormat(dateTime, format) {
      return moment(dateTime).format(format);
    },
    leftDaysCount(targetDate) {
      return moment(targetDate).diff(moment(), "days");
    },
    targetMinDaysCount(item) {
      var intervalByDays =
        moment(item.TargetDate).diff(moment(item.StartDate), "days") + 1;
      return Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        parseFloat((intervalByDays / 100) * item.TargetPercentage)
      );
    },
    successDaysCount(item) {
      var intervalByDays = parseFloat(this.targetMinDaysCount(item));
      return Math.round(
        parseFloat((intervalByDays / 100) * item.ValueProgressPercentage)
      );
    },
    successNumberOfDaysCount(item) {
      return Math.round(
        parseFloat((item.NumberOfDays / 100) * item.ValueProgressPercentage)
      );
    },
    async deleteItem(GoalId) {
      if (
        confirm(this.$t("requestResponse.basic.deleteElementConfirmMessage"))
      ) {
        const result = await HealthPlanLogic.deleteHealthPlan(
          this.planType,
          GoalId
        );
        if (!result.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleHealthGoal.successDelete"),
            {
              title: this.$t("base.basic.delete"),
              variant: "info",
              solid: true,
            }
          );
          this.$emit("delete-item", true);
        } else {
          this.$bvToast.toast(result.Message, {
            title: this.$t("requestResponse.moduleHealthGoal.errorDelete"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
};
</script>
