<template>
  <fp-panel-card :title="$t('healthProfessional.home.dailyTask')">
    <template #content>
      <task-table
        :filterDisabled="true"
        :taskList="dailyTaskList"
        :taskListFields="dailyTaskListFields"
      />
    </template>
  </fp-panel-card>
</template>
<script>
import TaskTable from "@/components/table/TaskTable.vue";
import { UserTaskLogic } from "@/logic/backend/user-task";
import moment from "moment";
export default {
  name: "DailyTask",
  components: { TaskTable },
  data() {
    return {
      dailyTaskList: [],
      dailyTaskListFields: [
        { key: "Status", label: this.$t("healthProfessional.home.taskStatus") },
        { key: "Type", label: this.$t("healthProfessional.home.taskType") },
        {
          key: "Priority",
          label: this.$t("healthProfessional.home.taskPriority"),
          sortable: true,
        },
        { key: "Title", label: this.$t("healthProfessional.home.taskTitle") },
        {
          key: "Description",
          label: this.$t("healthProfessional.home.taskDescription"),
        },
        {
          key: "Deadline",
          label: this.$t("healthProfessional.tasks.deadline"),
          sortable: true,
        },
      ],
    };
  },
  methods: {
    async getDailyTasks() {
      var params = { UserId: this.$loggedUser.UserId };
      if (this.$loggedUser.SelectedProject) {
        Object.assign(params, {
          ProjectId: this.$loggedUser.SelectedProject.ProjectId,
        });
      }
      const getDailyTasksResponse = await UserTaskLogic.getTasks(params);
      if (!getDailyTasksResponse.Code) {
        this.dailyTaskList = getDailyTasksResponse.filter(
          (task) =>
            ![
              this.$enums.TaskStatus.Completed.Value,
              this.$enums.TaskStatus.Failed.Value,
            ].includes(task.Status) &&
            (task.Deadline == null ||
              moment(task.Deadline).isBefore(moment().endOf("days")))
        );
      } else {
        this.$bvToast.toast(getDailyTasksResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.home.dailyTask"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getDailyTasks();
  },
};
</script>
