import LoggedUser from "./loggedUser";
import PageTitle from "./pageTitle";
import Toast from "./toast";
import Bever from "./bever";

export default {
  modules: {
    loggedUser: LoggedUser,
    headerPageTitle: PageTitle,
    toast: Toast,
    bever: Bever,
  },
};
