<template>
  <div>
    <b-row v-if="patient" cols="1" cols-md="2">
      <b-col>
        <fp-input
          label="Páciens azonosító"
          :required="true"
          v-model="patient.PatientIdentifierValue"
          :state="!validation.hasError('patient.PatientIdentifierValue')"
          :error="validation.firstError('patient.PatientIdentifierValue')"
        />
      </b-col>
      <b-col>
        <fp-select
          label="Páciens azonosító típusa"
          :required="true"
          v-model="patient.PatientIdentifierTypeId"
          :items="eesztPatientIdentifierTypeList"
          valueKey="EntryId"
          textKey="Name"
          :state="!validation.hasError('patient.PatientIdentifierTypeId')"
          :error="validation.firstError('patient.PatientIdentifierTypeId')"
        />
      </b-col>
      <b-col>
        <fp-select
          label="Páciens állampolgársága"
          description="Vény jelentéshez kötelező mező!"
          v-model="patient.NationalityId"
          :items="eesztCountryList"
          valueKey="EntryId"
          textKey="CitizenshipName"
          :searchable="true"
          @search="searchCountry"
        />
      </b-col>
      <b-col>
        <fp-date-picker
          label="Páciens születési dátuma"
          :required="true"
          v-model="patient.BirthDate"
          :state="!validation.hasError('patient.BirthDate')"
          :error="validation.firstError('patient.BirthDate')"
        />
      </b-col>
      <b-col>
        <fp-select
          label="Páciens neme"
          :required="true"
          v-model="patient.GenderId"
          :items="eesztSexList"
          valueKey="EntryId"
          textKey="Name"
          :state="!validation.hasError('patient.GenderId')"
          :error="validation.firstError('patient.GenderId')"
        />
      </b-col>
      <b-col>
        <fp-input label="Páciens neve" v-model="patient.FullName" />
      </b-col>
      <b-col>
        <fp-panel-card
          style="margin-top: 10px"
          title="Páciens címe"
          description="EESZT felküldéshez kötelező mező! Vény jelentéshez kötelező mező!"
          :closedCard="true"
        >
          <template #content>
            <patient-address v-model="patient.Address" />
          </template>
        </fp-panel-card>
      </b-col>
      <b-col>
        <fp-input label="Páciens telefonszáma" v-model="patient.Phone" />
      </b-col>
      <b-col>
        <fp-input
          label="Közgyógyigazolvány"
          v-model="patient.PublicHealthIdentifier"
        />
      </b-col>
      <b-col>
        <fp-date-picker
          label="Közgyógyigazolvány érvényessége"
          v-model="patient.PublicHealthIdentifierValidBy"
        />
      </b-col>
      <b-col>
        <fp-input
          label="Páciens EU nyomtatvány kódja"
          v-model="patient.EuDocumentCode"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from "moment";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import PatientAddress from "./PatientAddress.vue";
export default {
  components: { PatientAddress },
  name: "CreatePatient",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
  },
  data() {
    return {
      patient: null,
      defaultPatient: {
        PatientId: null,
        PatientIdentifierValue: null,
        PatientIdentifierTypeId: null,
        NationalityId: null,
        BirthDate: null,
        GenderId: null,
        FullName: null,
        Address: null,
        Phone: null,
        EuDocumentCode: null,
        PublicHealthIdentifier: null,
      },
      eesztSexList: null,
      eesztCountryList: null,
      eesztPatientIdentifierTypeList: null,
      validDateFilterParams: {
        ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
        ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
      },
    };
  },
  watch: {
    async value() {
      this.setPatientData();
      if (
        this.eesztSexList == null &&
        this.eesztCountryList == null &&
        this.eesztPatientIdentifierTypeList == null
      ) {
        await this.getLists;
      }
    },
    patient(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "patient.PatientIdentifierValue": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "patient.PatientIdentifierTypeId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "patient.BirthDate": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "patient.GenderId": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  methods: {
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
    setPatientData() {
      if (this.value) {
        this.patient = this.value;
      } else {
        this.patient = JSON.parse(JSON.stringify(this.defaultPatient));
      }
      this.resetValidation();
    },
    async searchCountry(input) {
      var list;
      if (input.length > 3) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { ["CitizenshipName-contains"]: input },
          "CitizenshipName",
          "string"
        );
      } else if (input.length == 0) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { "-orderby": "CitizenshipName", ["-limit"]: 10 },
          "CitizenshipName",
          "string"
        );
      } else {
        list = [];
      }

      var selectedValue = [];
      if (this.patient.NationalityId) {
        selectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            EntryId: this.patient.NationalityId,
          }
        );
      }

      this.eesztCountryList = this.checkDuplicates([...list, ...selectedValue]);
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        EntryId: null,
      });
    },
    //TODO kiemelni globális metódussá
    checkDuplicates(things) {
      return things.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.EntryId === thing.EntryId)
      );
    },
    async getLists() {
      this.eesztSexList = await this.getBase(
        this.$enums.BaseName.eeszt_sex.Value,
        this.validDateFilterParams
      );

      const eesztCountryDefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_citizenship.Value,
        { "-orderby": "CitizenshipName", ["-limit"]: 10 },
        "CitizenshipName",
        "string"
      );
      var eesztCountryListSelectedValue = [];
      if (this.patient.NationalityId) {
        eesztCountryListSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            EntryId: this.patient.NationalityId,
          }
        );
      }
      this.eesztCountryList = this.checkDuplicates([
        ...eesztCountryDefaultList,
        ...eesztCountryListSelectedValue,
      ]);
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        EntryId: null,
      });

      this.eesztPatientIdentifierTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_patient_identifier_type.Value,
        this.validDateFilterParams,
        "Code",
        "number"
      );
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    this.setPatientData();
    await this.getLists();
  },
};
</script>
