<template>
  <div class="eh-menu-content">
    <div>
      <div v-if="projectDetails">
        <fp-tabs :tabs="projectCardComponents">
          <template #title="Component">{{ Component.Title }}</template>
          <template #content="Component">
            <component
              :is="Component.ComponentName"
              :research="projectDetails.Research"
              :patientCount="patients ? patients.length : 0"
              :patients="patients"
              :state="projectDetails.State"
              :ownProgress="ownProgress"
              :subordinatesProgress="subordinatesProgress"
            />
          </template>
        </fp-tabs>
      </div>
      <div v-else>{{ $t("healthProfessional.projects.dontProject") }}</div>
    </div>
  </div>
</template>
<script>
import { ProjectLogic } from "@/logic/backend/project";
/* components */
import SelectedProjectDataSheet from "@/views/specialist/project-components/DataSheet.vue";
import SelectedProjectPatients from "@/views/specialist/project-components/Patients.vue";

export default {
  components: {
    SelectedProjectDataSheet,
    SelectedProjectPatients,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      projectCardComponents: [
        {
          ComponentName: "fp-research-data-sheet",
          Title: this.$t("healthProfessional.projects.dataSheet"),
        },
        {
          ComponentName: "fp-research-patient-progress",
          Title: this.$t("healthProfessional.projects.patients"),
        },
      ],
      projectDetails: null,
      patients: null,
      ownParticipantId: null,
      subordinatesProgress: null,
      ownProgress: null,
    };
  },
  watch: {
    async project(value) {
      if (value) {
        await this.getSelectedProject();
        await this.getPatients();
        await this.getProjectProgress();
      }
    },
  },
  methods: {
    async getPatients() {
      //kérés indítás
      const patientListResponse = await ProjectLogic.getProjectSubordinates(
        this.$loggedUser.SelectedProject.ProjectId
      );
      //sikeres kérés?
      if (!patientListResponse.Code) {
        //kapott lista tárolása
        this.patients = patientListResponse.filter((patient) => {
          return this.projectDetails.Roles.find(
            (role) =>
              role.BaseRoles.includes("Patient") &&
              role.RoleName == patient.RoleName
          );
        });
      } else {
        this.$bvToast.toast(patientListResponse.Message, {
          title: this.$t(
            "requestResponse.projectDetails.errorGetProjectSubordinates"
          ),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getSelectedProject() {
      const getSelectedProjectResponse = await ProjectLogic.getProjectDetails({
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      if (!getSelectedProjectResponse.Code) {
        this.projectDetails = getSelectedProjectResponse[0];
        const ownParti = this.projectDetails.Participants.find(
          (parti) => parti.UserId == this.$loggedUser.UserId
        );
        if (ownParti) {
          this.ownParticipantId = ownParti.UserId;
        }
      } else {
        this.$bvToast.toast(getSelectedProjectResponse.Message, {
          title: this.$t("requestResponse.projectDetails.errorGet"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getProjectProgress() {
      const getProjectProgressResponse = await ProjectLogic.getProjectDoctorProgress(
        this.projectDetails.ProjectId,
        this.ownParticipantId
      );
      if (!getProjectProgressResponse.Code) {
        this.subordinatesProgress =
          getProjectProgressResponse.SubordinatePatientProgress;
        this.ownProgress = getProjectProgressResponse;
      } else {
        this.$bvToast.toast(getProjectProgressResponse.Message, {
          title: this.$t("requestResponse.projectDetails.errorGetProgress"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor lefut egyszer
  async mounted() {
    await this.getSelectedProject();
    await this.getPatients();
    await this.getProjectProgress();
    //oldal cím beállítása
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.project")
    );
  },
};
</script>
<style scoped>
#new-fp-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
#new-fp-modal .modal-content header {
  padding: 0px;
}
</style>
