<template>
  <div class="badges page-background">
    <b-row cols="1" cols-md="2">
      <!-- Megszerzett jelvények -->
      <div>
        <h3>{{ $t("client.badges.recievedBadges") }}</h3>
        <table
          v-if="badges && badges.length > 0"
          style="margin: 10px; vertical-align: top"
        >
          <td
            @click="selectActiveBadge(badge)"
            class="badge-field"
            v-for="badge in badges"
            :key="badge.Id"
          >
            <div>
              <img
                class="badge-icon deserved-badge-icon"
                src="@/assets/modules/inactive/icon-cup-deserved-inactive.png"
              />
              <div class="badge-active">
                <img
                  class="badge-icon deserved-badge-icon"
                  src="@/assets/modules/active/icon-cup-deserved-active.png"
                />
              </div>
            </div>
            <div @click="selectActiveBadge(badge)" class="badge-title">
              {{ getTextByLanguage(badge.AvailableBadge.Name) }}
            </div>
          </td>
        </table>
      </div>
      <!-- Megszerezhető jelvények -->
      <div>
        <h3>{{ $t("client.badges.availableBadges") }}</h3>
        <table
          v-if="allBadges && allBadges.length > 0"
          style="margin: 10px; vertical-align: top"
        >
          <td
            @click="selectAvailableBadge(badge)"
            class="badge-field"
            v-for="badge in allBadges"
            :key="badge.Id"
          >
            <div>
              <img
                class="badge-icon available-badge-icon"
                src="@/assets/modules/inactive/icon-cup-available-inactive.png"
              />
              <div class="badge-active">
                <img
                  class="badge-icon available-badge-icon"
                  src="@/assets/modules/active/icon-cup-available-active.png"
                />
              </div>
            </div>
            <div class="badge-title">{{ getTextByLanguage(badge.Name) }}</div>
          </td>
        </table>
      </div>
    </b-row>
    <!-- Kiválasztott megszerzett jelvény -->
    <fp-form-modal
      :title="getTextByLanguage(selectedBadge.AvailableBadge.Name)"
      v-if="selectedBadge"
      v-model="selectedBadge.IsActive"
    >
      <!-- <template #header>
        {{ getTextByLanguage(selectedBadge.AvailableBadge.Name) }}
      </template> -->
      <template #content>
        <div style="text-align: center">
          {{ convertDate(selectedBadge.AcquiredAt) }}
          <br />
          <img src="@/assets/modules/active/icon-cup-deserved-active.png" />
          <br />
          {{ selectedBadge.AvailableBadge.Description }}
        </div>
      </template>
    </fp-form-modal>
    <!-- Kiválasztott megszerezhető jelvény -->
    <fp-form-modal
      v-if="selectedAvailableBadge"
      v-model="selectedAvailableBadge.IsActive"
      :title="getTextByLanguage(selectedAvailableBadge.Name)"
    >
      <!-- <template #header>
        {{ getTextByLanguage(selectedAvailableBadge.Name) }}
      </template> -->
      <template #content>
        <div style="text-align: center">
          {{ getTextByLanguage(selectedAvailableBadge.Description) }}
          <br />
          <img src="@/assets/modules/active/icon-cup-available-active.png" />
          <br />
          <!-- <b-progress max="100" :value="selectedAvailableBadge.Value" :variant="selectedAvailableBadge.Value<33?'danger':selectedAvailableBadge.Value<66?'warning':selectedAvailableBadge.Value<99?'info':'success'"></b-progress>
          {{selectedAvailableBadge.Value}}%-->
        </div>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import moment from "moment";
import { BadgesLogic } from "@/logic/backend/badges";
export default {
  components: {},
  data() {
    return {
      siteTitle: this.$t("client.menu.badges"),
      selectedBadge: null,
      selectedAvailableBadge: null,
      badges: null,
      allBadges: null,
    };
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    selectActiveBadge(badge) {
      this.selectedBadge = {
        IsActive: true,
        ...badge,
      };
    },
    selectAvailableBadge(badge) {
      this.selectedAvailableBadge = {
        IsActive: true,
        ...badge,
      };
    },
    convertDate(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD, HH:mm");
    },
    async getBadges() {
      const responseAcquiredBadges = await BadgesLogic.getAcquiredBadges();
      const responseUnacquiredBadges = await BadgesLogic.getUnacquiredBadges();
      if (!responseAcquiredBadges.Code && !responseUnacquiredBadges.Code) {
        this.badges = responseAcquiredBadges;
        this.allBadges = responseUnacquiredBadges;
      } else {
        this.$bvToast.toast(responseAcquiredBadges.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.siteTitle,
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getBadges();
    this.$store.dispatch(
      "setPageTitle",this.siteTitle);
    var taskParams = this.$route.query.taskParams;
    if (taskParams) {
      taskParams = JSON.parse(taskParams);
      const taskBadge = this.allBadges.find(
        (badge) => badge.AvailableBadgeId == taskParams.BadgeId
      );
      if (taskBadge) {
        this.selectAvailableBadge(taskBadge);
      }
    }
  },
};
</script>
<style>
.badges {
  text-align: center;
  padding: 50px;
}
.badge-field {
  text-align: center;
  display: inline-table;
  margin: 10px;
}
.badge-icon,
.badge-title {
  cursor: pointer;
}
.badge-field,
.badge-icon {
  width: 100px;
  position: relative;
  display: inline-block;
}
.badge-icon {
  border: solid;
  border-radius: 50px;
  border-color: transparent;
}
.badge-title {
  padding-top: 10px;
  font-weight: 500;
}
.badge-active {
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 98;
}
.badge-field:hover .badge-active {
  cursor: pointer;
  display: inline;
}
.badge-icons {
  position: relative;
  display: inline-block;
}
.badge-active .deserved-badge-icon {
  border-color: #f7bb44;
}
.badge-active .available-badge-icon {
  border-color: #afc5d3;
}
.badge-field:hover .badge-title {
  font-weight: 900;
}
#active-badge-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
#active-badge-modal .modal-content header {
  padding: 0px;
}
</style>
