<template>
  <div v-if="selectedEntryId">
    <div style="text-align: center">
      <label class="eh-chart-title"
        >{{ $t("module.charts." + Chart.Name) }} -
        {{
          selectedMeasurement ? selectedMeasurement.MeasurementName : ""
        }}</label
      >
      <div v-if="selectedMeasurement">
        {{
          $convertDateToString(
            selectedMeasurement.MeasuredAt,
            "YYYY-MM-DD HH:mm"
          )
        }}
      </div>
    </div>
    <div style="margin: 0px 0px 60px">
      <b-button @click="saveToJson()" variant="success">{{
        $t("module.diaries.respiration.export")
      }}</b-button>
      <b-button @click="setDeleteModal(true)" variant="danger">{{
        $t("module.diaries.respiration.delete")
      }}</b-button>
      <fp-form-modal v-model="showDeleteModal">
        <template #content>
          <h5>{{ $t("module.diaries.respiration.confirm") }}</h5>
          <b-button @click="setDeleteModal(false)">{{
            $t("module.diaries.respiration.cancel")
          }}</b-button>
          <b-button @click="deleteMeasurement()" variant="danger">{{
            $t("module.diaries.respiration.delete")
          }}</b-button>
        </template>
      </fp-form-modal>
    </div>
    <ejs-chart
      v-if="chartData"
      ref="respiration-measurement-chart-component"
      :id="'respiration-measurement-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      :tooltip="tooltip"
      background="transparent"
      :width="'100%'"
      :height="'100%'"
      isResponsive="true"
    >
      <e-series-collection>
        <!-- INHALATION RECORDED DATA -->
        <e-series
          :dataSource="chartData['chest']"
          type="Line"
          xName="x"
          yName="y"
          :name="$t('module.diaries.respiration.chest')"
          :marker="marker"
        ></e-series>
        <e-series
          :dataSource="chartData['abdomen']"
          type="Line"
          xName="x"
          yName="y"
          :name="$t('module.diaries.respiration.abdomen')"
          :marker="marker"
        ></e-series>
        <e-series
          :dataSource="chartData['average']"
          type="Line"
          xName="x"
          yName="y"
          :name="$t('module.diaries.respiration.average')"
          :marker="marker"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import { Tooltip, LineSeries, Legend } from "@syncfusion/ej2-vue-charts";
import { DiaryLogic } from "@/logic/backend/diary";

export default {
  name: "PulmonologyRespirationMeasurement",
  data() {
    return {
      respirationDiaryName: "respiration",
      chartData: null,
      //Chart paraméterek
      xAxis: {
        title: this.$t("module.diaries.respiration.seconds"),
        valueType: "Double",
        labelIntersectAction: "Rotate45",
        labelFormat: "n0",
        minimum: 0,
      },
      yAxis: {
        title: this.$t("module.diaries.respiration.data"),
        valueType: "Double",
        labelFormat: "n0",
        minimum: 0,
      },
      /*palettes: ["var(--fp-dark-orange)", "var(--fp-dark-gray)"],*/
      palettes: ["#137d2f", "#c91016", "#0d1ca1", "#252926"],
      marker: {
        visible: false,
      },
      tooltip: {
        enable: true,
        header: this.$t("module.diaries.respiration.measurement"),
        format: "${point.x} : <b>${point.y}</b> ",
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      },
      showDeleteModal: false,
      widthh: "95%",
      changeDataList: false,
      selectedMeasurement: null,
    };
  },
  props: {
    ChartData: Object,
    UserId: Number,
    SamplingRate: Number,
    DiaryEntryId: Number,
    FullData: Object,

    Id: [Number, String],
    PatientId: Number,
    selectedEntryId: Number,
    Chart: Object,
    changeData: Boolean,
  },
  watch: {
    async selectedEntryId(id) {
      if (id) {
        await this.getData();
      }
    },
    async changeData(input) {
      this.changeDataList = input;
      if (input && this.selectedEntryId) {
        await this.getData();
      }
    },
    changeDataList(input) {
      this.$emit("update:changeData", input);
    },
  },
  provide: {
    chart: [Tooltip, LineSeries, Legend],
  },
  methods: {
    //intervallum beállítása
    setInterval() {
      Vue.set(this.xAxis, "plotOffsetRight", 10);
      Vue.set(this.xAxis, "plotOffsetLeft", 10);
      //TODO!!! chart csak kisebb méretbeli változásra lesz 100% width
      //this.$refs['respiration-measurement-chart-component'].ej2Instances.refresh();
    },
    // megkapott adatok chartba illesztése
    setData() {
      // chest, abdomen
      for (let [key, value] of Object.entries(this.ChartData)) {
        this.chartData[key] = new Array();
        let matrix = this.chartData[key];

        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          matrix[i] = {
            x: (i + 1) / this.SamplingRate,
            y: element,
          };
        }
      }
      // average
      this.chartData["average"] = new Array();
      let matrix = this.chartData["average"];
      for (let i = 0; i < this.FullData.AverageData.length; i++) {
        const element = this.FullData.AverageData[i];
        matrix[i] = {
          x: (i + 1) / this.SamplingRate,
          y: element,
        };
      }
    },
    setDeleteModal(value) {
      Vue.set(this, "showDeleteModal", value);
    },
    async deleteMeasurement() {
      const result = await DiaryLogic.deleteUserDiaryEntry(
        this.respirationDiaryName,
        this.PatientId,
        this.selectedEntryId
      );
      if (!result.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.moduleDiary.successDeleteElement"),
          {
            title: this.$t("base.basic.delete"),
            variant: "info",
            solid: true,
          }
        );
        this.setDeleteModal(false);
        this.$emit("delete-close");
      } else {
        this.$bvToast.toast(result.Message, {
          title: this.$t("requestResponse.moduleDiary.errorDeleteElement"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.setDeleteModal(false);
    },
    saveToJson() {
      const data = JSON.stringify([this.selectedMeasurement]);
      const blob = new Blob([data], { type: "text/plain" });
      const e = document.createEvent("MouseEvents"),
        a = document.createElement("a");
      a.download =
        this.selectedMeasurement.MeasurementName +
        this.selectedMeasurement.MeasuredAt +
        ".json";
      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
      e.initEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      a.dispatchEvent(e);
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    },
    async getData() {
      //igen napló, napló adatok kérés
      await this.getDiaryDataById();
      this.changeDataList = false;
    },
    //napló adat kérés
    async getDiaryDataById() {
      //kérés válasz
      var responseChartData = null;
      //van betegid?
      if (this.PatientId) {
        //igen, beteg napló adat kérés
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          { EntryId: this.selectedEntryId }
        );
      } else {
        //nem, saját beteg napló adat kérés
        responseChartData = await DiaryLogic.getDiary(this.Chart.DatabaseName, {
          EntryId: this.selectedEntryId,
        });
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        //kapott napló adat tárolás
        // this.chartData = responseChartData;
        this.chartData = null;
        this.selectedMeasurement = responseChartData[0];
        this.convertChartData(this.selectedMeasurement);
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    convertChartData(data) {
      if (!this.chartData) {
        this.chartData = {};
      }
      // chest, abdomen
      for (let [key, value] of Object.entries(data.MeasurementData)) {
        this.chartData[key] = new Array();
        let matrix = this.chartData[key];

        for (let i = 0; i < value.length; i++) {
          const element = value[i];
          matrix[i] = {
            x: (i + 1) / data.SamplingRate,
            y: element,
          };
        }
      }
      // average
      this.chartData["average"] = new Array();
      let matrix = this.chartData["average"];
      for (let i = 0; i < data.AverageData.length; i++) {
        const element = data.AverageData[i];
        matrix[i] = {
          x: (i + 1) / data.SamplingRate,
          y: element,
        };
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //intervallum beállítása
    //this.setInterval();
    //adatok betöltése
    //this.setData();
    // szélesség beállítás miatt
    /*  await this.sleep(5);
    this.widthh = "100%"; */
    if (this.selectedEntryId) {
      await this.getData();
    }
  },
};
</script>

<style scoped>
@import "../../../styles/syncfusion-diagrams.css";
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--fp-dark-orange);
  font-size: 23px;
}
</style>
