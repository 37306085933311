<template>
  <div v-if="role && role.Parameters && role.Parameters.length">
    <div v-if="role.Parameters.includes('PatientGroup')">
      <fp-select
        label="Kutatási csoport"
        :items="patientGroupCats"
        v-model="parametes.PatientGroup"
        textKey="text"
        valueKey="value"
        @change="changeValue"
      />
    </div>
    <div v-if="role.Parameters.includes('RequiredSubordinatePatientCount')">
      <fp-input
        label="RequiredSubordinatePatientCount"
        v-model="parametes.RequiredSubordinatePatientCount"
        @change="changeValue"
      />
    </div>
    <div
      v-if="
        role.Parameters.includes('RequiredSubordinateTreatmentPatientCount')
      "
    >
      <fp-input
        label="RequiredSubordinateTreatmentPatientCount"
        v-model="parametes.RequiredSubordinateTreatmentPatientCount"
        @change="changeValue"
      />
    </div>
    <div
      v-if="role.Parameters.includes('RequiredSubordinateControlPatientCount')"
    >
      <fp-input
        label="RequiredSubordinateControlPatientCount"
        v-model="parametes.RequiredSubordinateControlPatientCount"
        @change="changeValue"
      />
    </div>
    <div v-if="role.Parameters.includes('RequiredSubordinateDoctorCount')">
      <fp-input
        label="RequiredSubordinateDoctorCount"
        v-model="parametes.RequiredSubordinateDoctorCount"
        @change="changeValue"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "ParticipantParameters",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    role: Object,
    value: Object,
  },
  data() {
    return {
      parametes: this.value,
      defaultParameters: {
        PatientGroup: null,
        RequiredSubordinatePatientCount: null,
        RequiredSubordinateTreatmentPatientCount: null,
        RequiredSubordinateControlPatientCount: null,
        RequiredSubordinateDoctorCount: null,
      },
      patientGroupCats: [
        { value: "Control", text: "Kontroll csoport" },
        { value: "Treatment", text: "Kezelési csoport" },
      ],
    };
  },
  watch: {
    value() {
      this.setParametes();
    },
  },
  methods: {
    changeValue() {
      this.$emit("change", this.generateParameters());
    },
    generateParameters() {
      const paramters = {};
      if (this.parametes.PatientGroup != null) {
        paramters.PatientGroup = this.parametes.PatientGroup;
      }
      if (this.parametes.RequiredSubordinatePatientCount != null) {
        paramters.RequiredSubordinatePatientCount = this.parametes.RequiredSubordinatePatientCount;
      }
      if (this.parametes.RequiredSubordinateTreatmentPatientCount != null) {
        paramters.RequiredSubordinateTreatmentPatientCount = this.parametes.RequiredSubordinateTreatmentPatientCount;
      }
      if (this.parametes.RequiredSubordinateControlPatientCount != null) {
        paramters.RequiredSubordinateControlPatientCount = this.parametes.RequiredSubordinateControlPatientCount;
      }
      if (this.parametes.RequiredSubordinateDoctorCount != null) {
        paramters.RequiredSubordinateDoctorCount = this.parametes.RequiredSubordinateDoctorCount;
      }
      return paramters;
    },
    setParametes() {
      if (this.value) {
        this.parametes = JSON.parse(JSON.stringify(this.value));
      } else {
        this.parametes = JSON.parse(JSON.stringify(this.defaultParameters));
      }
    },
  },
  mounted() {
    this.setParametes();
  },
};
</script>
