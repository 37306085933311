<template>
  <fp-form-modal
    hide-footer
    id="blood-pressure-input-modal"
    :title="$t('module.diaries.bloodPressure.addNewPost')"
    v-model="showInputModal"
  >
    <template #content>
      <b-form :class="theme">
        <fp-date-time-picker
          :label="$t('module.diaries.measureDate')"
          v-model="newEntry.MeasuredAt"
        />
        <fp-input
          type="number"
          v-model="newEntry.SystolicPressure"
          :label="$t('module.diaries.bloodPressure.systole')"
          :placeholder="$t('module.diaries.bloodPressure.systolePlaceholder')"
          :state="!validation.hasError('newEntry.SystolicPressure')"
          :error="validation.firstError('newEntry.SystolicPressure')"
          :required="true"
        />
        <fp-input
          type="number"
          v-model="newEntry.DiastolicPressure"
          :label="$t('module.diaries.bloodPressure.diastole')"
          :placeholder="$t('module.diaries.bloodPressure.diastolePlaceholder')"
          :state="!validation.hasError('newEntry.DiastolicPressure')"
          :error="validation.firstError('newEntry.DiastolicPressure')"
          :required="true"
        />
        <fp-input
          type="number"
          v-model="newEntry.PulseValue"
          :label="$t('module.diaries.bloodPressure.pulse')"
          :placeholder="$t('module.diaries.bloodPressure.pulsePlaceholder')"
          :state="!validation.hasError('newEntry.PulseValue')"
          :error="validation.firstError('newEntry.PulseValue')"
        />
        <div style="text-align: right">
          <b-button @click="modalOk">{{ $t("base.basic.save") }}</b-button>
        </div>
      </b-form>
    </template>
  </fp-form-modal>
</template>

<script>
import moment from "moment";
import { DiaryLogic } from "@/logic/backend/diary";

export default {
  name: "BloodPressureDiaryInput",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      modalData: {
        date: moment().format("YYYY-MM-DD"),
        time: moment().format("HH:mm"),
      },
      newEntry: {
        PulseValue: null,
        SystolicPressure: null,
        DiastolicPressure: null,
        SourceName: "UserInput",
        MeasuredAt: moment().format(),
      },
      isValidInputs: {},
      showInputModal: null,
      alert: {
        Message: "",
        IsShow: false,
      },
      theme: sessionStorage.getItem("currentTheme") || "eh-fp",
    };
  },
  watch: {
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "newEntry.PulseValue": function(value) {
      //console.log(value);
      return this.$validator
        .value(value)
        .integer()
        .greaterThan(0);
    },
    "newEntry.SystolicPressure": function(value) {
      return this.$validator
        .value(value)
        .required()
        .integer()
        .greaterThan(0);
    },
    "newEntry.DiastolicPressure": function(value) {
      return this.$validator
        .value(value)
        .required()
        .integer()
        .greaterThan(0);
    },
  },
  computed: {
    validInput() {
      var inputsValid = Object.values(this.isValidInputs).some((x) => !x);
      return !inputsValid;
    },
  },
  methods: {
    async modalOk() {
      const valid = await this.$validate();
      if (valid) {
        const response = await DiaryLogic.addDiaryEntry("blood_pressure", [
          this.newEntry,
        ]);
        if (!response.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(response.Message, {
            title: this.$t("requestResponse.moduleDiary.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
};
</script>
<style></style>
