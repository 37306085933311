<template>
  <b-row class="site-body-box" align-h="center">
    <div class="site-body">
      <label class="title">Transzlációs Medicina Kutató Központról</label>
      <div style="text-align:justify">
        <p>
          A Dél-pesti Centrumkórház Országos Hematológiai és Infektológiai
          Intézet (DPC-OHII) a Dél-közép magyarországi régió mintegy 1,3 millió
          lakosát ellátó progresszív intézménye, centrumkórháza, amelybe
          integráltan működik az Országos Hematológiai és Infektológiai Intézet.
          Irányítása alatt működnek a Péterfy, a Bajcsy-Zsilinszky, a Jahn
          Ferenc és a ceglédi Toldy Ferenc kórházak, a Nagykőrösi Rehabilitációs
          Centrum, valamint a Szigetszentmiklósi Rendelőintézet. A kórházi
          klaszter ötezer betegágyon a 8200 alkalmazott évente 135 ezer fekvő és
          2,5 millió járóbeteget lát el.
        </p>
        <p>
          Nagyszámú beteg paramétereinek klinikai és adattudományi elemzések
          számára a hazai szabályozási, technológiai környezet egyre inkább
          megteremti a lehetőséget a nemzetközileg is versenyelőnyt biztosító
          kutatás-fejlesztési-innovációs együttműködéséknek, valamint az
          egészségipari szolgáltatás- és termékfejlesztéseknek. Magyarország 10
          milliós lakosságszáma áttekinthető elemszámú sokaságot jelent, az
          Európában is ritka egybiztosítós rendszer, az országos gyógyintézetek
          adatkezelési és elemzési jogosítványai, a Népegészségügyi Programok
          keretrendszere, az Elektronikus Egészségügyi Szolgáltatási Tér (EESZT)
          egyre bővülő funkciói megfelelő alapot teremtenek a betegközpontú
          innovatív adatgyűjtési, elemzési, termék- és szolgáltatásfejlesztési
          aktivitásnak. Ezen tevékenységek magvalósulása leghatékonyabban
          kutatás-fejlesztési és innovációs együttműködések mentén lehetséges.
        </p>
        <p>
          A DPC-OHII, országos gyógyintézetként alapfeladatainak ellátása
          mellett jelentős kutatás-fejlesztési és innovációs aktivitással
          rendelkezik. A 2017 óta folyamatosan egymásra épülő K+F projektek a
          Nemzeti Kutatási, Fejlesztési és Innovációs Hivatal (NKFIH) pályázati
          támogatásai keretében valósulnak meg. Ezen projektek eredményei,
          valamint az Intézetben működő két országos betegségregiszter
          (hematológiai és infektológiai) jelentősen hozzájárult ahhoz, hogy a
          DPC-OHII kutatás-fejlesztési ökoszisztémája kiépüljön és működésbe
          álljon.
        </p>
        <p>
          A 2017-ben indított Nemzeti Onkogenomikai Innovációs és Precíziós
          Onkoterápiás Program az onkogenomikai alapkutatást, alkalmazott
          kutatás-fejlesztést, betegirányító és döntéstámogató rendszerek,
          országos betegségregiszterek egymáshoz integrált kialakítását tűzte ki
          céljául. A kifejlesztett információtechnológiai rendszer egységes
          szemléletben közös ernyő alá rendezi az adatgyűjtést, elemzést és
          döntéstámogatást az egyes beteg szintjétől a beteg populációkon át az
          egészségbiztosítóig és az egészségpolitikáig. A projekt során
          széleskörű együttműködés alakult ki az alapkutatástól a klinikai
          kutatáson át az ipari fejlesztésig annak érdekében, hogy minél
          gyorsabb és teljesebb körű legyen a genomikán alapuló
          onko-hematológiai kutatási eredmények hasznosítása. A pályázat célja
          volt, hogy a már meglévő kutatási infrastruktúra és nemzetközi szinten
          egyedülálló transzlációs onko-hematológiai adatbankban rejlő
          tudományos és innovációs lehetőségeket a hazai kutatók és
          vállalkozások számára a lehető legjobban ki lehessen használni a
          következő években. Az ipari partnerekkel konzorciumi együttműködésben
          sikeresen megvalósított projekt alapot teremtett a következő évek
          nagyívű fejlesztéseinek.
        </p>
        <p>
          Az NKFIH Tématerületi Kiválósági Programja keretén belül 1,5 Mrd Ft
          támogatásból valósította meg az Intézet a Transzlációs
          onko-hematológiai kutatási, fejlesztési és innovációs tevékenység a
          Dél-pesti Centrumkórház – Országos Hematológiai és Infektológiai
          Intézet Kutatóintézetében (2019), valamint a Transzlációs Medicina
          Kutató Központ (TMKK) és Innovatív Sejtterápiás Központ létrehozása a
          Dél-pesti Centrumkórház – Országos Hematológiai és Infektológiai
          Intézetben (2020-2021) projekteket.
        </p>
        <p>
          A Transzlációs Medicina Kutató Központ (TMKK) melyet 2020-ban került
          létrehozásra, egy hatalmas mennyiségű strukturált klinikai, ellátási
          és genomikai adatot tartalmazó kutatói adatbázison működő
          kutatásmenedzsment szakrendszer, amely szabályozott együttműködést
          teremt a releváns K+F+I információt előállító és birtokló DPC-OHII,
          valamint a kutatási eredményeket felhasználni és megrendelni kívánó
          állami és magán tulajdonú szervezetek között. A folyamatosan fejlődő
          ökoszisztéma az egészségügyi és egészségipari szereplőket egy támogató
          platformon keresztül fogja össze egyedi kutatási, elemzési,
          adatszolgáltatási igényeik alapján. A TMKK lehető legtöbb tevékenysége
          a virtuális térben zajlik, mert a projekt eredménytermékeként
          fejlesztett Transzlációs Medicina Kutató Központ-Menedzsment Platform
          (TMKK-MP) integrált kutatásmenedzsment információtechnológiai rendszer
          automatizált megoldásokkal támogatja a kutatási projektek hatékony és
          transzparens megvalósítását. A TMKK döntéselőkészítő és -támogató
          elemzéseket készít az állami és kormányzati szervek számára, emellett
          összefogja a DPC-OHII összes kutatási projektjét egészen a Phd.
          kutatásoktól a gyógyszeripari és biotech klinikai vizsgálatokon
          keresztül a több intézményt érintő nemzeti programokig.
        </p>
        <p>
          A jelenleg megvalósítás alatt álló kutatási projektek közül kiemelendő
          az NKFIH „Befektetés a jövőbe Alap” pályázati felhívásán nyertes
          „Személyre szabott orvoslást támogató szakértői rendszer létrehozása
          járványos fertőző és kiemelt népegészségügyi betegségek ellátásának
          támogatására”, valamint a „Tématerületi Kiválósági Program 2021”
          keretében megvalósított „COVID-19 betegség diagnosztikája,
          kórlefolyása és terápiája kapcsán végzett klinikai kutatások a
          Dél-pesti Centrumkórház – Országos Hematológiai és Infektológiai
          Intézetben és az irányítása alatt álló intézményekben” projektek. A
          pályázati támogatásból a DPC-OHII olyan adatbankot, kapcsolódó
          betegség- és klinikai regisztereket hoz létre, amelyben gyűjtött és
          rendszerezett adatok a társított szakértői informatikai rendszerek
          segítségével hatékony elemző-értékelést és döntéstámogatást tesznek
          lehetővé az eljövendő járványhullámok és következményeik megelőzésére
          és megfelelő kezelésére. A kutatási környezet nem csak előrejelző és
          ellátási indikátorok, de az obszervált betegutak alapján célzott
          terápiás és betegség-menedzsment fejlesztésekhez is megfelelő alapot
          biztosít. Ennek érdekében multidiszciplináris munkacsoportok
          foglalkoznak a COVID-19 betegséggel kapcsolatos visszatekintő és
          tervezett nagy esetszámú prospektív klinikai kutatásokkal.
        </p>
        <p>
          Az előadások célja egyrészről átfogó képet adni a DPC-OHII hat éve
          folyó kiemelkedő kutatás-fejlesztési tevékenységéről, másrészről a
          TMKK fő célkitűzésének megfelelve tématerületenként kiterjedt
          együttműködések és partnerségek kialakítása hazai és nemzetközi
          kutatóhelyekkel, továbbá az országos gyógyintézetek, egyetemi
          központok és egészségipari szereplők bevonásával az egészségügyi
          ellátás technológiai hátterének, minőségének és hatékonyságának
          fejlesztése.
        </p>
      </div>
    </div>
  </b-row>
</template>
<script>
export default {
  name: "ResearchPartners",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.parag {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
