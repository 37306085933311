export const DeHealthProfessional = {
  healthProfessional: {
    home: {
      dailyTask: "Tägliche Aufgaben",
      progress: "Fortschritt",
      projectProgress: "Projektfortschritt",
      taskDescription: "Beschreibung",
      taskPriority: "Priorität",
      taskStatus: "Status",
      taskTitle: "Aufgaben",
      taskType: "Typ",
      timeProgress: "Fortschritt im Laufe der Zeit",
      doctorProgress: "Fortschritte des Arztes im Projekt",
    },
    menu: {
      appointments: "Termine",
      calendar: "Kalender",
      documents: "Dokumentenbibliothek",
      home: "Persönliche Startseite",
      messages: "Mitteilungen",
      patientList: "Patientenliste",
      patientInvitation: "Patienteneinladung",
      project: "Projekt",
      questionnaires: "Fragebögen",
      tasks: "Aufgabenliste",
      workflow: "Prozesse",
      profile: "Mein Profil",
      templates: "Vorlagenbibliothek",
    },
    patientList: {
      communicationTab: {
        call: "Anruf",
        chat: "Chat",
        email: "E-Mail",
        emailNew: "Neue E-Mail",
        emailSubject: "Betreff",
        emailBody: "E-Mail-Text",
        emailPriority: "Priorität",
        emailAttachment: "Anhang",
        smsNew: "Neue SMS",
        smsText: "SMS-Text",
        pushNew: "Neue Push-Nachricht",
        pushTitle: "Benachrichtigungsadresse",
        pushText: "Benachrichtigungstext",
        pushPhoto: "Benachrichtigungsbild",
        push: "Push-Nachricht",
        sms: "SMS",
        communication: "Kommunikation",
        openButton: "Anzeigen",
        operations: "Vorgänge",
        type: "Typ",
        sendTime: "Datum gesendet",
        communicationTypes: "Kommunikationsarten",
        sendMessages: "Eine Nachricht schicken",
        templateName: "Vorlagenname",
        templateSupportedLanguages: "Verfügbare Sprachen",
        templateMessageTypes: "Verfügbare Vorlagen",
        send: "Senden",
      },
      diagramsTab: {
        selectChart: "Wählen Sie einen Diagrammtyp aus",
      },
      formsTab: {
        assignment: "Zuordnung",
        description: "Beschreibung",
        endDate: "Fertigstellungstermin",
        language: "Sprache",
        newForm: "Neues Formular hinzufügen",
        operations: "Vorgänge",
        state: "Formularstatus",
        title: "Formulartitel",
        errorFormName: "bilden",
        errorAssignName: "verteilbare Formulare",
        filledDate: "Datum eingesetzt",
      },
      newPatientForm: {
        patient: "Patient",
        note: "Anmerkungen",
        projectRole: "Projektrolle",
        tags: "Stichworte",
        errorName: "Nutzer",
      },
      patientList: {
        addNewPatientToProject: "Patienten zum Projekt hinzufügen",
        notBirthday: "Geburtsdatum nicht angegeben",
        errorTagsName: "Stichworte",
        notFoundPatient: "Keine Patienten anzuzeigen",
        tagFilter: "Stichwort-Filter",
      },
      patientTabs: {
        charts: "Diagramme",
        communication: "Kommunikation",
        documents: "Dokumente",
        forms: "Medizinische Formulare",
        medicalData: "Medizinisches Profil",
        personalData: "Persönliches Profil",
        questionnaires: "Fragebögen",
        workflow: "Prozesse",
      },
      personalDataTab: {
        deleteFromProject: "Aus dem Projekt löschen",
        editTag: "Stichwort-Bearbeitung",
        editProfile: "Profil bearbeiten",
        errorName: "Persönliches Profil",
      },
      medicalDataTab: {
        editProfile: "Profil bearbeiten",
        errorName: "Medizinisches Profil",
      },
      questionnairesTab: {
        cancelButton: "Widerruf",
        deadline: "Endtermin",
        evaluatingButton: "Auswertung",
        fromDate: "Daten freigeben",
        operations: "Vorgänge",
        questionnaire: "Fragebögen",
        state: "Status des Fragebogens",
        warningButton: "Warnung",
        errorName: "Fragebögen",
        giveAgain: "Neuauflage",
        progress: "Progress",
      },
      tag: {
        edit: "Änderung",
        language: "Sprache",
        project: "Projekt",
        own: "Besitzen",
        editTag: "Stichwort ändern",
        newTag: "Neues Stichwort erstellen",
        noTags: "Es können keine Stichwörter angezeigt werden.",
        tagColor: "Farbe der Stichworte",
        tagInfo: "Doppelklicken, um vorhandenes Stichwort zu bearbeiten",
        tagManager: "Stichwortmanager",
        tagName: "Stichwortbezeichnung",
        tags: "Stickworte",
      },
      workflowTab: {
        workflow: "Prozess",
        progress: "Fortschritt",
        currentSteps: "Aktuell laufende(r) Prozessschritt(e)",
        currentInProgress: "Schritt ausführen...",
        stepWaiting: "Prozess ausstehend",
        errorStartedName: "Prozess gestartet",
      },
      userSetting: {
        relationshipType: "Verbindungstyp",
        relationshipRemark: "Kontakthinweis",
        projectRemark: "Projekthinweis",
      },
      documentsTab: {
        documentType: "Art des Dokumentes",
      },
    },
    patientManager: {
      patientSignUp: {
        accountData: "Kontoinformationen",
        birthday: "Geburtsdatum",
        birthplace: "Geburtsort",
        email: "E-Mail-Adresse",
        name: "Name",
        password: "Passwort",
        phone: "Telefonnummer",
        role: "Rolle",
        userBaseData: "Grundlegende Benutzerinformationen",
        username: "Benutzername",
        flags: "Kontoeigenschaft",
        relationshipType: "Verbindungstyp",
        projectRole: "Projektrolle",
        passwordAgain: "Wiederholen Sie das Passwort.",
        sex: "Geschlecht",
        newPatientDoctorRelationship:
          "Erstellen Sie eine neue Nutzer-Arzt-Beziehung.",
        tags: "Stichworte",
        relationshipRemark: "Anmerkung zur Beziehung",
        newPatientToProject:
          "Fügen Sie dem Projekt einen neuen Benutzer hinzu.",
        tagsInProject: "Etikette innerhalb eines Projekts",
        projectRemark: "Bemerkungen zum Projekt",
      },
      manager: {
        newPatientSignUp: "Aufnahme eines neuen Nutzer",
        patientInvitations: "Patienteneinladungen",
        status: "Status",
        parameters: "Parameter",
        timestamp: "Einladung senden",
        userInvitation: "Laden Sie einen Nutzer ein.",
        targetName: "Name des Aufgerufenen",
      },
      userInvitation: {
        registedUserInvitation:
          "Laden Sie einen registrierten Benutzer als Patienten ein.",
        user: "Nutzer",
        errorName: "Nutzer",
      },
    },
    profile: {
      antszCode: "Abteilungscode",
      chat: "Chat",
      communication: "Kommunikation",
      email: "E-Mail",
      emailAddress: "E-Mail-Adresse",
      institutionName: "Name der Institution",
      name: "Name",
      phone: "Telefonnummer",
      position: "Position",
      push: "Push-Nachricht",
      registrationNumber: "Registriernummer",
      sms: "SMS",
      wardName: "Name der Gemeinde",
      errorName: "Profil",
      username: "Name des Nutzers",
      profileDelete: "Nutzerkonto dauerhaft löschen",
    },
    projects: {
      controllPatientCount: "Davon Kontrollpatienten",
      dontProject: "Mit keinem Projekt verbunden",
      doubleBlind: "Doppelblindstudie",
      noneBlind: "Kein",
      patientSsn: "Sozialversicherungsnummer des Patienten",
      simpleBlind: "Einfacher Blindtest",
      testPatientCount: "Davon ​​Testpatienten",
      doctorTimeProgress: "Doktor, der das Projekt voranbringt",
      blind: "Einfachblindstudie",
      controllGroup: "Kontrollgruppe",
      dataSheet: "Datenblatt",
      description: "Beschreibung",
      patientIn: "Anzahl der beteiligten Patienten",
      patientName: "Patientenname",
      patientBirth: "Geburtsdatum des Patienten",
      patients: "Patienten",
      progress: "Fortschritt",
      projectGoal: "Projektziel",
      projectName: "Projektname",
      projectTitle: "Projekttitel",
      selectionCriteria: "Auswahlkriterium",
      state: "Status",
      tasksProgress: "Aufgabenfortschritt",
      testGroup: "Testgruppe",
      timestamp: "Zeitraum",
      projectTimeProgress: "Projektfortschritt im Zeitverlauf",
      currentProject: "derzeitiges Projekt",
      person: "Personen",
    },
    questionnaires: {
      own: "Meine Fragebögen",
      ownTab: {
        title: "Fragebogentitel",
        description: "Beschreibung",
        percentage: "Füllung",
        addDate: "Datum hinzugefügt",
        completedDate: "Datum eingesetzt",
        status: "Status des Fragebogens",
        errorFillableName: "Fragebögen auszufüllen",
      },
      assignable: "Zuweisbare Fragebögen",
      assignableTab: {
        newdeadline: "Endtermin",
        giveQuestionnaries:
          "{count} ausgewählten Fragebögen dem ausgewählten Patienten zuordnen",
        patientBirthday: "Geburtstag des Patienten",
        patientName: "Name des Patienten",
        patientNote: "Bemerkung",
        patientSsn: "Sozialversicherungsnummer des Patienten",
        formCreatedDate: "Erstellt",
        formDescription: "Beschreibung",
        formTitle: "Titel",
        newSendDate: "Tag der Absendung",
        newFormByTask: "Fragebögen als Aufgabe zuweisen",
        noTag: "Unbeschriftet",
        createDate: "Erstellungsdatum",
        newdeadlineUnit: "TAG.HH:MM:SS",
        supportedLanguages: "Verfügbare Sprachen",
        errorName: "zuweisbarer Fragebögen",
        noPatient: "Es können keine Patienten angezeigt werden.",
        newFormHardDeadline: "Schließen Sie die Aufgabe nach Ablauf der Zeit.",
        fillTime: "Zeit zur Vervollständigung",
      },
      waitingForEvaluting: "Auszuwertende Fragebögen",
      waitingTab: {
        deadline: "Endtermin",
        description: "Beschreibung",
        evaluating: "Bewertung",
        filledDate: "Datum eingesetzt",
        givenDate: "Zuweisungsdatum",
        patient: "Auszufüllende Patientendaten",
        patientSsn: "Sozialversicherungsnummer des Patienten",
        evaulate: "Auswertung",
        patientBirth: "Geburtstag des Patienten",
        progress: "Progress",
        title: "Titel des Fragebögens",
        errorWaitingName: "Auf Auswertung wartender Fragebögen",
        evaulating: {
          longDescription: "Langzeitbewertung",
          note: "Bemerkung",
          point: " Punkt",
          shortDescription: "Kurzbewertung",
          summary: "Gesamtergebnis: ",
          title: "Fragebogenauswertung",
        },
      },
      fillableForm: "Patientenbezogene Formulare",
      fillableFormTab: {
        patient: "Patientendaten",
        title: "Titel",
        description: "Beschreibung",
        percentage: "Fortschritt",
        startDateTime: "Datum hinzugefügt",
        stopDateTime: "Fertigstellungstermin",
        status: "Status",
        errorName: "Auszufüllender Fragebögen",
      },
      questionnaireFill: "Fragebögen ausfüllen",
      formFill: "Formular ausfüllen",
      ssn: "Sozialversicherungsnummer",
      birthday: "Geburtsdatum",
      address: "Adresse",
      errorPatientName: "Patient",
      userData: "Weist Patientendaten zu",
      name: "Name des Patienten",
    },
    tasks: {
      deadline: "Endtermin",
      description: "Beschreibung",
      priority: "Priorität",
      startDate: "Anfangsdatum",
      status: "Status",
      task: "Aufgabe",
      type: "Typ",
      errorUserName: "Nutzer",
      errorStartableName: "Prozess kann gestartet werden.",
    },
    workflow: {
      startWorkflow: "Starten eines Prozesses",
    },
    appointment: {
      date: "Datum",
      inProgress: "In Bearbeitung",
      person: "{count} Leute",
      addParticipant: "Teilnehmer hinzufügen",
      participants: "Teilnehmer",
      clear: "Termin absagen",
      view: "Aussehen",
      participantsData: "Teilnehmerdetails",
      removeParticipant: "Teilnehmer entfernen",
      saveView: "Aussehen speichern",
      newParticipnat: "Neuen Teilnehmer hinzufügen",
      title: "Titel",
      description: "Beschreibung",
      startTime: "Anfangszeit",
      endTime: "Endzeit",
      visitorsCount: "Anzahl der Plätze",
      location: "Ort",
      errorName: "Termin",
      client: "Kunde",
      filterDescription: "Suche in Titel, Beschreibung, max. Anzahl der Plätze",
      users: "Nutzer",
    },
    documents: {
      documentType: "Art des Dokumentes",
      errorName: "Dokument",
    },
    templates: {
      newTemplate: "Fügen Sie eine neue Vorlage hinzu.",
      noTemplate: "Keine Vorlage vorhanden",
      lang: "Sprache der Vorlage",
      name: "Bezeichnung der Vorlage",
      smsMessage: "Nachricht",
      pushMessage: "Nachricht",
      errorName: "Vorlage",
      addEmail: "E-Mail-Vorlage hinzufügen",
      addSMS: "SMS-Vorlage hinzufügen",
      addPush: "PUSH-Vorlage hinzufügen",
      email: "E-Mail-Vorlage",
      sms: "SMS-Vorlage",
      push: "Push-Vorlage",
      emailSubject: "Gegenstand",
      removeEmail: "E-Mail-Vorlage entfernen",
      removeSMS: "SMS-Vorlage entfernen",
      pushTitle: "Titel",
      pushImg: "Symbolbild-URL",
      removePush: "Push-Vorlage entfernen",
      addToProject: "Vorlage zum Projekt hinzugefügt",
    },
  },
};
