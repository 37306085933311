<template>
  <fp-form-modal
    hide-footer
    v-model="showInputModal"
    :title="$t('module.diaries.medicine.addNewPost')"
  >
    <template #content>
      <fp-date-time-picker
        :label="$t('module.diaries.medicine.takenDate')"
        :state="!validation.hasError('newEntry.MeasuredAt')"
        :error="validation.firstError('newEntry.MeasuredAt')"
        v-model="newEntry.MeasuredAt"
      />
      <fp-select
        :state="!validation.hasError('newEntry.MedicineId')"
        :error="validation.firstError('newEntry.MedicineId')"
        :label="$t('module.diaries.medicine.medicine')"
        :items="list"
        :searchable="true"
        valueKey="MedicineId"
        v-model="newEntry.MedicineId"
        @search="search($event)"
      >
          <template #element="element">
              {{ $getLanguagedText(element.Name) }}
              <br />
              {{ element.Package }}
          </template>
      </fp-select>
      <fp-input
        :label="$t('module.diaries.medicine.takenCount')"
        v-model="newEntry.Quantity"
      />
      <div style="text-align: right">
        <button class="eh-action-button" @click="modalOk">
          {{ $t("base.basic.save") }}
        </button>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import moment from "moment";
import { DiaryLogic } from "@/logic/backend/diary";
import { BaseDataLogic } from "@/logic/backend/base-data";

export default {
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    defaultParams: Object,
    visible: Boolean,
  },
  data() {
    return {
      showInputModal: this.visible,
      newEntry: {
        MedicineId: null,
        Quantity: null,
        MeasuredAt: moment().format(),
      },
      list: null,
    };
  },
  watch: {
    defaultParams(input) {
      this.newEntry.MedicineId = input.MedicineId ? input.MedicineId : null;
      this.newEntry.Quantity = input.Quantity ? input.Quantity : null;
    },
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "newEntry.MedicineId": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newEntry.MeasuredAt": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredDateTime"));
    },
  },
  methods: {
    async modalOk() {
      const valid = await this.$validate();
      if (valid) {
        const getDiaryResponse = await DiaryLogic.addDiaryEntry("medicine", [
          this.newEntry,
        ]);
        if (!getDiaryResponse.Code || getDiaryResponse.Code == 0) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(getDiaryResponse.Message, {
            title: this.$t("requestResponse.moduleDiary.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async search(input) {
      if (input) {
        if (input.length > 2) {
          this.list = await this.getMedicineList({ ["Name." + this.$i18n.locale]: input });
        } else {
          this.list = await this.getMedicineList();
        }
      } else {
        this.list = await this.getMedicineList({ "-limit": 20 });
      }
    },
    async getMedicineList(params) {
      var temp = [];
      if (params) {
        temp = await this.getData(params);
      }
      if (
        this.newEntry.MedicineId &&
        !temp.find((e) => e.MedicineId == this.newEntry.MedicineId)
      ) {
        const selected = await this.getData({
          MedicineId: this.newEntry.MedicineId,
        });
        temp.splice(0, 0, selected[0]);
      }
      //console.log(temp);
      return temp;
    },
    async getData(params) {
      const getMedicineListResponse = await BaseDataLogic.getBaseData(
        "medicine",
        params
      );
      if (!getMedicineListResponse.Code) {
        return getMedicineListResponse;
      } else {
        this.$bvToast.toast(getMedicineListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetMedicineList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      return null;
    },
  },
  async mounted() {
    this.list = await this.getMedicineList({ "-limit": 20 });
  },
};
</script>
