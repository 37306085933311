<template>
  <div>
    <span style="margin-left: 5px">{{
      params.Title ? params.Title[language] : null
    }}</span>
    <span v-if="params.Required && !params.Label" style="color: red">*</span>
    <fp-text-area
      :placeholder="params.PlaceHolder ? params.PlaceHolder[language] : null"
      :description="params.Description ? params.Description[language] : null"
      :required="params.Required"
      :disabled="!params.Enabled != false || disabled"
      textStyle="dark"
      v-model="inputValue"
    />
  </div>
</template>
<script>
export default {
  name: "Long",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [String, Number],
    disabled: Boolean,
    language: String,
  },
  computed: {
    inputValue: {
      get() {
        return this.value ? this.value : this.params.DefaultValue;
      },
      set(value) {
        this.$emit("change", value);
      },
    },
  },
};
</script>