<template>
  <div class="service-finder page-background">
    <div>
      <b-row cols-md="1" :cols-xl="isSelectedHospital ? 3 : 2" align-h="center">
        <div style="flex: 0 0 100%">
          <div id="map" ref="map"></div>
        </div>
        <div style="padding: 10px">
          <div style="margin: 20px 0px">
            <!-- <ejs-dropdownlist
              @change="selectService($event.value)"
              :dataSource="serviceTypes"
              :fields="checkFields"
              floatLabelType="Auto"
              cssClass="e-outline"
              :placeholder="$t('client.serviceFinder.serviceType')"
            ></ejs-dropdownlist> -->
            <fp-select
              style="text-align: left"
              :items="serviceTypes"
              valueKey="Code"
              textKey="Name"
              :label="$t('client.serviceFinder.serviceType')"
              @change="selectService"
            />
          </div>
          <div v-if="selectedService == 1" style="margin: 20px 0px">
            <!-- <ejs-dropdownlist
              @change="selectHospital"
              :fields="hospitalListFields"
              :dataSource="hospitalList"
              floatLabelType="Auto"
              cssClass="e-outline"
              :placeholder="$t('client.serviceFinder.hospital')"
            ></ejs-dropdownlist> -->
            <fp-select
              style="text-align: left"
              :items="hospitalList"
              textKey="Name"
              valueKey="HospitalId"
              :label="$t('client.serviceFinder.hospital')"
              @change="selectHospital"
            />
          </div>
          <div
            v-if="selectedService == 1 && isSelectedHospital"
            style="margin: 20px 0px"
          >
            <!-- <ejs-dropdownlist
              :fields="hospitalWardListFields"
              :dataSource="hospitalWardList"
              @change="selectHospitalWard"
              floatLabelType="Auto"
              cssClass="e-outline"
              :placeholder="$t('client.serviceFinder.ward')"
            ></ejs-dropdownlist> -->
            <!-- Searchable!! -->
            <fp-select
              style="text-align: left"
              :items="hospitalWardList"
              textKey="Name"
              valueKey="WardId"
              :label="$t('client.serviceFinder.ward')"
              @change="selectHospitalWard"
            />
          </div>
          <div
            class="city-dropdownlist"
            v-if="selectedService == 2"
            style="margin: 20px 0px"
          >
            <!-- <ejs-dropdownlist
              :fields="cityAreaListFields"
              :dataSource="cityListForPharmacy"
              :allowFiltering="true"
              :ignoreAccent="true"
              @change="selectCityForPharmacy"
              floatLabelType="Auto"
              cssClass="e-outline"
              :placeholder="$t('client.serviceFinder.city')"
            ></ejs-dropdownlist> -->
            <fp-select
              style="text-align: left"
              :items="filteredCityListForPharmacy"
              valueKey="key"
              textKey="key"
              :searchable="true"
              @search="filterCityListForPharmacy($event)"
              :label="$t('client.serviceFinder.city')"
              @change="selectCityForPharmacy"
            />
          </div>
          <div
            v-if="selectedService == 2 && isShowAreaField"
            style="margin: 20px 0px"
          >
            <!-- <ejs-dropdownlist
              :fields="cityAreaListFields"
              :dataSource="areaListForPharmacy"
              @change="selectAreaForPharmacy"
              floatLabelType="Auto"
              cssClass="e-outline"
              :placeholder="$t('client.serviceFinder.district')"
            ></ejs-dropdownlist> -->
            <fp-select
              style="text-align: left"
              :items="areaListForPharmacy"
              textKey="key"
              valueKey="key"
              :label="$t('client.serviceFinder.district')"
              @change="selectAreaForPharmacy"
            />
          </div>
          <div
            v-if="selectedService == 2 && isShowPharmacyList"
            style="margin: 20px 0px"
          >
            <!--  <ejs-dropdownlist
              :fields="pharmacyListFields"
              :dataSource="pharmacyList"
              @change="selectPharmacy"
              floatLabelType="Auto"
              cssClass="e-outline"
              :placeholder="$t('client.serviceFinder.pharmacy')"
            ></ejs-dropdownlist> -->
            <fp-select
              style="text-align: left"
              :items="pharmacyList"
              textKey="Name"
              valueKey="PharmacyId"
              :label="$t('client.serviceFinder.pharmacy')"
              @change="selectPharmacy"
            />
          </div>
          <!-- <div v-if="selectedService == 1 && isSelectedHospital">
            <ejs-button
              v-on:click="isShowAppointment = true"
              class="main-button"
              >Időpontfoglalás</ejs-button
            >
          </div> -->
          <div
            v-if="selectedElementInfo.Hospital"
            style="background: #fff; border-radius: 10px; margin: auto"
          >
            <div
              style="
                width: 100%;
                background: #f7b944;
                color: var(--eh-white);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                font-weight: bold;
                font-size: 25px;
              "
            >
              {{ $t("client.serviceFinder.information") }}
            </div>
            <table style="margin: 20px auto">
              <tr>
                {{
                  $t("client.serviceFinder.infoName")
                }}
                <b>{{ selectedElementInfo.Hospital.Name }}</b>
              </tr>
              <tr>
                {{
                  $t("client.serviceFinder.infoEmail")
                }}
                {{
                  selectedElementInfo.Hospital.Contacts.Email
                }}
              </tr>
              <tr>
                {{
                  $t("client.serviceFinder.infoPhone")
                }}
                {{
                  selectedElementInfo.Hospital.Contacts.Phone
                }}
              </tr>
              <tr>
                {{
                  $t("client.serviceFinder.infoWebsite")
                }}
                {{
                  selectedElementInfo.Hospital.Contacts.Website
                }}
              </tr>
              <tr v-if="selectedElementInfo.Ward">
                <br />{{
                  $t("client.serviceFinder.infoWard")
                }}
                <b>{{ selectedElementInfo.Ward.Name }}</b>
              </tr>
              <tr>
                {{
                  $t("client.serviceFinder.infoAddress")
                }}
                {{
                  selectedElementInfo.Hospital.Contacts.Address.ZipCode
                }}
                {{
                  selectedElementInfo.Hospital.Contacts.Address.City
                }},
                {{
                  selectedElementInfo.Hospital.Contacts.Address.Street
                }}
                {{
                  selectedElementInfo.Hospital.Contacts.Address.Building
                }}
                <div v-if="selectedElementInfo.Ward">
                  {{ selectedElementInfo.Ward.Contacts.Address.SubBuilding }}
                </div>
              </tr>
              <tr v-if="selectedElementInfo.Ward">
                {{
                  $t("client.serviceFinder.infoOpeningHours")
                }}
                {{
                  selectedElementInfo.Ward.Contacts.OpeningHours
                }}
              </tr>
            </table>
          </div>
          <div
            v-if="selectedElementInfo.Pharmacy"
            style="background: #fff; border-radius: 10px; margin: auto"
          >
            <div
              style="
                width: 100%;
                background: #f7b944;
                color: var(--eh-white);
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                font-weight: bold;
                font-size: 25px;
              "
            >
              {{ $t("client.serviceFinder.information") }}
            </div>
            <table style="margin: 20px auto">
              <tr>
                {{
                  $t("client.serviceFinder.infoName")
                }}
                {{
                  selectedElementInfo.Pharmacy.Name
                }}
              </tr>
              <tr>
                {{
                  $t("client.serviceFinder.infoAddress")
                }}
                {{
                  selectedElementInfo.Pharmacy.Contacts.Address.ZipCode
                }}
                {{
                  selectedElementInfo.Pharmacy.Contacts.Address.City
                }},
                {{
                  selectedElementInfo.Pharmacy.Contacts.Address.Street
                }}
                {{
                  selectedElementInfo.Pharmacy.Contacts.Address.Building
                }}
              </tr>
              <tr>
                {{
                  $t("client.serviceFinder.infoPhone")
                }}
                {{
                  selectedElementInfo.Pharmacy.Contacts.Phone
                }}
              </tr>
              <tr>
                {{
                  $t("client.serviceFinder.infoOpeningHours")
                }}
                <div
                  v-html="
                    selectedElementInfo.Pharmacy.Contacts.OpeningHours.replace(
                      /\n/gi,
                      '<br>'
                    )
                  "
                ></div>
              </tr>
            </table>
          </div>
        </div>
        <!--  <b-col
          v-if="isShowAppointment && selectedService == 1 && isSelectedHospital"
        >
          <b-card no-body style="background: #fff">
            <div style="text-align: end; margin: 5px">
              <b-button @click="isShowAppointment = false">Bezárás</b-button>
            </div>
            <b-card>
              <b-row cols-lg="3" cols="1">
                <b-col style="margin: 10px 0px">
                  <div id="control_wrapper" class="control_wrapper date-format">
                    <ejs-datepicker
                      :min="minDate"
                      placeholder="Intervallum kezdete"
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      format="yyyy-MM-dd"
                      v-model="fromDate"
                    ></ejs-datepicker>
                  </div>
                </b-col>
                <b-col style="margin: 10px 0px">
                  <div id="control_wrapper" class="control_wrapper date-format">
                    <ejs-datepicker
                      :min="minDate"
                      placeholder="Intervallum vége"
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      format="yyyy-MM-dd"
                      v-model="toDate"
                    ></ejs-datepicker>
                  </div>
                </b-col>
                <b-col style="margin: 10px 0px">
                  <b-button @click="getFreeAppointmentsByFilter"
                    >Szűrés</b-button
                  >
                </b-col>
              </b-row>
              <b-pagination
                style="margin-bottom: 20px"
                v-model="currentPage"
                :total-rows="freeAppointmentListCount"
                :per-page="5"
                align="fill"
                size="md"
                pills
              ></b-pagination>
              <fp-table-list
                :items="freeAppointmentList"
                :fields="freeAppointmentFields"
                responsive
                :per-page="5"
                :current-page="currentPage"
                sort-by="eventDate"
                striped
              >
                <template #eventDate="row">{{
                  generateDate(row.item.eventDate)
                }}</template>
                <template #eventEndDate="row">{{
                  generateDate(row.item.eventEndDate)
                }}</template>
                <template #reservation="row">
                  <b-spinner v-if="row.item.isReservatingLoad"></b-spinner>
                  <img
                    v-else
                    style="width: 30px; cursor: pointer"
                    src="@/assets/settings-button/muvelet8.png"
                    @click="reservatingElement(row.item)"
                  />
                </template>
              </fp-table-list>
            </b-card>
          </b-card>
        </b-col> -->
      </b-row>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
//import { HelperMethods } from "@/logic/ui/helpers.js";
import { BaseDataLogic } from "@/logic/backend/base-data.js";

export default {
  data() {
    return {
      siteTitle: this.$t("client.menu.serviceFinder"),
      alert: {
        Message: "",
        IsShow: false,
      },
      isShowAppointment: false,
      map: null,
      markers: [],
      minDate: moment().format(),
      fromDate: moment().format(),
      toDate: null,
      freeAppointmentList: [],
      freeAppointmentListCount: null,
      freeAppointmentFields: [
        {
          key: "eventPlace",
          label: "Kórház neve",
          sortable: true,
        },
        {
          key: "eventDescription",
          label: "Kórházi osztály neve",
          sortable: true,
        },
        {
          key: "organizer",
          label: "Orvos",
          sortable: true,
        },
        {
          key: "capacity",
          label: "Kapacitás",
          sortable: true,
        },
        {
          key: "eventDate",
          label: "Kezdő időpont",
          sortable: true,
        },
        {
          key: "eventEndDate",
          label: "Befejező időpont",
          sortable: true,
        },
        {
          key: "reservation",
          label: "",
        },
      ],
      currentPage: 1,
      dateFormat: "dd-MMM-yy",
      selectedElementInfo: {},
      isSelectedHospital: false,
      selectedHospital: null,
      hospitalList: [],
      hospitalListFields: { text: "Name", value: "HospitalId" },
      hospitalWardList: {},
      hospitalWardListFields: { text: "Name", value: "WardId" },
      selectedPharmacy: null,
      pharmacyListAll: {},
      pharmacyList: {},
      pharmacyListFields: { text: "Name", value: "PharmacyId" },
      checkFields: { text: "Name", value: "Code" },
      serviceTypes: [
        { Name: this.$t("client.serviceFinder.hospital"), Code: 1 },
        { Name: this.$t("client.serviceFinder.pharmacy"), Code: 2 },
      ],
      selectedService: null,
      cityListForPharmacy: [],
      filteredCityListForPharmacy: [],
      cityMapForPharmacy: null,
      cityAreaListFields: { text: "key", value: "key" },
      areaListForPharmacy: [],
      areaMapForPharmacy: null,
      isShowAreaField: false,
      isShowPharmacyList: null,
      myLocation: null,
      myLocationMarker: null,
    };
  },
  methods: {
    //térkép beállítása
    createMap() {
      //új térkép objektum készítése és beállítása
      this.map = new window.google.maps.Map(this.$refs["map"], {
        center: { lat: 47.4888892, lng: 19.0725988 },
        zoom: 7,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      });
      //saját hely gomb készítés
      this.createMyLocationButton();
    },
    //lista szűrés
    filterCityListForPharmacy(input) {
      //megadott input, és változó kulcsok alapján szűrt lista tárolása
      this.filteredCityListForPharmacy = this.$filterList(
        input,
        this.cityListForPharmacy,
        ["key"]
      );
    },
    //saját hely megjelenése
    getMyLocation() {
      //van saját hely jelölő?
      if (this.myLocationMarker) {
        //igen, a jelölőhez rendel térképét töröljük
        this.myLocationMarker.setMap(null);
      }
      //le tudjuk kérdezni a felhasználó helyzetét?
      if (navigator.geolocation) {
        //igen, lekérdezzük a felhasználó helyzetét
        navigator.geolocation.getCurrentPosition(
          (position) => {
            //a kapott pozíciót eltároljuk a saját helyzetként
            this.myLocation = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            //a térkép közepét beállítjuk a saját helyre
            this.map.setCenter(this.myLocation);
            //a térkép nagyítását beállítjuk (város nézet)
            this.map.setZoom(11);
            //készítünk egy saját helyet jelölőt, amit tárolunk
            this.myLocationMarker = new window.google.maps.Marker({
              position: {
                lat: this.myLocation.lat,
                lng: this.myLocation.lng,
              },
              map: this.map,
              icon: require("@/assets/custom-icons/loc.png"),
            });
          },
          //ha nem HTTPSből van megnyitva akkor nem tudja betölteni a saját helyet
          () => {
            this.$bvToast.toast(
              this.$t("requestResponse.location.errorCannotGetLocation"),
              {
                title: this.$t("requestResponse.location.errorGetLocation"),
                variant: "danger",
                solid: true,
                AutoHideDelay: 10000,
              }
            );
            //alert("Error: The Geolocation service failed.");
          }
        );
      } else {
        // a böngésző nem támogatja a geolocationt
        this.$bvToast.toast(
          this.$t("requestResponse.basic.errorLocationByBrowse"),
          {
            title: this.$t("requestResponse.location.errorLocationByBrowse"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          }
        );
        //alert("Error: Your browser doesn't support geolocation.");
      }
    },
    //saját hely gomb készítés
    createMyLocationButton() {
      //létrehozunk egy HTML elemet --> középre helyzés gomb tároló
      const centerControlDiv = document.createElement("div");
      // Set CSS for the control border
      centerControlDiv.style.clear = "both";
      //létehozunk egy HTML elemet --> sajátheéy középre helyezés
      const goCenterUI = document.createElement("div");
      //elem beállítások
      goCenterUI.id = "goCenterUI";
      goCenterUI.title = "Click to recenter the map";
      // Set CSS for the control interior
      centerControlDiv.appendChild(goCenterUI);
      //lértehozunk egy HTML elemnt --> középre helyezés felirat
      const goCenterText = document.createElement("div");
      //elem beállítása
      goCenterText.id = "goCenterText";
      goCenterText.innerHTML =
        "<img style='width:20px;margin-top:5px;margin-bottom:5px' src='https://www.materialui.co/materialIcons/maps/my_location_black_192x192.png'/>";
      goCenterUI.appendChild(goCenterText);
      //elemhez egy klikk esemény feliratkozás
      goCenterUI.addEventListener("click", () => {
        //kiváltákor lekérdezi a saját helyet
        this.getMyLocation();
        //a saját hely középre helyezése a térképen
        this.map.setCenter(this.myLocation);
      });
      //elem beállírása
      centerControlDiv.index = 1;
      centerControlDiv.style.paddingRight = "13px";
      //térkép gombok elhelyezése
      this.map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(
        centerControlDiv
      );
    },
    //térkép kamera kép beállítása
    async setMapCamera(lat, lng, element) {
      //kapott pozíció alapján kamera beállítás
      this.map.setCenter({ lat: lat, lng: lng });
      //nagyítás beállítás
      this.map.setZoom(16);
      //a kiválasztott szolgáltatás 1 (Kórház)
      if (this.selectedService == 1) {
        //igen, a kiválasztott kórház megkapja a kapott elem id-ját
        this.selectedHospital = element.HospitalId;
        //a kiválasztott elem infoját kiürítjük
        this.selectedElementInfo = {};
        //a kiválasztott element info gyógyszertárt nullal megadjuk
        Vue.set(this.selectedElementInfo, "Pharmacy", null);
        //a kiválasztott elem info kórházat a megadott elemmel megadjuk
        Vue.set(this.selectedElementInfo, "Hospital", element);
        //a kórház kiválasztó jelzőt true-ra állítjuk
        this.isSelectedHospital = true;
        //a kórházhoz tartozó osztály lista kérés
        await this.getHospitalWardList(element.HospitalId);
      } else {
        //nem (Gyógyszertár), a kiválasztott gyógyszertár megkapja az adott elem id-ját
        this.selectedPharmacy = element.PharmacyId;
        //a kiválasztott elem infokat kiürítjük
        this.selectedElementInfo = {};
        //a kiválasztott elem gyógyszertárt  a megadott elemmel megadjuk
        Vue.set(this.selectedElementInfo, "Pharmacy", element);
        //a kiválasztott elem kórházat nullal adjuk meg
        Vue.set(this.selectedElementInfo, "Hospital", null);
      }
    },
    //új jelölő felvétele
    addMarker(element) {
      //új marker létrehozása
      const marker = new window.google.maps.Marker({
        position: {
          lat: element.Contacts.Address.Latitude,
          lng: element.Contacts.Address.Longitude,
        },
        map: this.map,
      });
      //átadjuk a this-t egy változónak, metódus elérés végett
      const that = this;
      //hozzáadjuk a markerhez egy klikk feliratkoztatés
      window.google.maps.event.addListener(marker, "click", async (e) => {
        //adott markerre kattintva a térkép kamerát beállítjuk középre
        that.setMapCamera(e.latLng.lat(), e.latLng.lng(), element);
      });
      //hozzáadjuk az új markert a marker tárolóhoz
      this.markers.push(marker);
    },
    //kórház lista kérés
    async getHospitalList() {
      //paraméter tároló
      const params = {};
      //kérés indítás
      const getHospitalListResponse = await BaseDataLogic.getBaseData(
        "hospital",
        params
      );
      //sikeres kérés?
      if (!getHospitalListResponse.Code) {
        //kapott lista tárolás
        this.hospitalList = getHospitalListResponse;
        //lista sorrendezése név alapján
        this.hospitalList.sort((a, b) => a.Name.localeCompare(b.Name));
      } else {
        this.$bvToast.toast(getHospitalListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetHospitalList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //kórházi osztály lista kérés
    async getHospitalWardList(hospitalId) {
      //kérés paraméter megadás
      const params = { HospitalId: hospitalId };
      //kérés indítás
      const getHospitalWardListResponse = await BaseDataLogic.getBaseData(
        "hospital_ward",
        params
      );
      //sikeres kérés?
      if (!getHospitalWardListResponse.Code) {
        //kapott lista tárolás
        this.hospitalWardList = getHospitalWardListResponse;
        //lista sorrendezés név alapján
        this.hospitalWardList.sort((a, b) => a.Name.localeCompare(b.Name));
      } else {
        this.$bvToast.toast(getHospitalWardListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetHospitalWardList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //gyógyszertár lista kérés
    async getPharmacyList() {
      //kérés paraméter beállítás
      const params = {};
      //kérés indítás
      const getPharmacyListResponse = await BaseDataLogic.getBaseData(
        "pharmacy",
        params
      );
      //sikeres kérés?
      if (!getPharmacyListResponse.Code) {
        //kapott lista tárolása
        this.pharmacyListAll = getPharmacyListResponse;
      } else {
        this.$bvToast.toast(getPharmacyListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetPharmacyList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //szogáltatás választás
    async selectService(event) {
      //térkép nagyítás beállítás
      this.map.setZoom(11);
      //markerek törlése a térképről
      this.markers.forEach((x) => {
        x.setMap(null);
      });
      //város alapján csoportosított gyógyszertárak ürítése
      this.cityListForPharmacy = [];
      //kiválasztott szolgáltatás tárolása
      this.selectedService = event;
      //kiválasztott elem info ürítése
      this.selectedElementInfo = {};
      //a kiválasztott elem 1 (Kórház)?
      if (event == 1) {
        //igen, kórház lista kérés
        await this.getHospitalList();
        //tárolt kórházakhoz marker készítés
        this.hospitalList.forEach((x) => {
          this.addMarker(x);
        });
        //kiválasztott kórház jelölő false-ra állítjuk
        //TODO: check miért?
        this.isSelectedHospital = false;
        //a kiválasztott elem 2 (Gyógyszertár)?
      } else if (event == 2) {
        //igen 2, a gyógyszertár list megjelenő jelölő false-ra állírjuk
        //TODO: check, miért?
        this.isShowPharmacyList = false;
        //gyógyszertár lista kérés
        await this.getPharmacyList();
        //város alapján csoportosított gyógyszertárak tárolása
        this.cityMapForPharmacy = this.$groupByMostEfficient(
          this.pharmacyListAll,
          (pharmacy) => pharmacy.Contacts.Address.City.toUpperCase()
        );
        //kapott map konvertálása listává
        this.cityMapForPharmacy.forEach((value, key) =>
          this.cityListForPharmacy.push({ key: key, value: value })
        );
        //a csoportosított lista sorrendezése név alapján (város név)
        this.cityListForPharmacy.sort((a, b) => a.key.localeCompare(b.key));
        //szűrhető lista tárolónak átadjuk a kapott város listát
        this.filteredCityListForPharmacy = this.cityListForPharmacy;
        //csoportosítjuk a Budapesti gyógyszertárakat kerületek szerint, ennek tárolása
        this.areaMapForPharmacy = this.$groupByMostEfficient(
          this.cityMapForPharmacy.get("BUDAPEST"),
          (pharmacy) => pharmacy.Contacts.Address.ZipCode.substring(1, 3)
        );
        //map konvertálása listába
        this.areaMapForPharmacy.forEach((value, key) =>
          this.areaListForPharmacy.push({ key: key, value: value })
        );
        //kerület lista sorrendezése
        this.areaListForPharmacy.sort((a, b) => a.key - b.key);
        //a kiválasztott kórház jelző false-ra állítás
        this.isSelectedHospital = false;
      }
    },
    //kórház választás
    async selectHospital(event) {
      //kórházi osztályok lista kérés
      await this.getHospitalWardList(event);
      //végig megyünk a kórház lista minden elemén
      this.hospitalList.forEach((x) => {
        //a kiválasztott elem megyeszik az aktuális kórház id-val?
        if (x.HospitalId == event) {
          //igen, a kiválasztott elem info tároló osztályát null-ra állítjuk
          Vue.set(this.selectedElementInfo, "Ward", null);
          //a kiválasztott elem info tároló kórházat beállítjuk az kiválasztott kórházzal
          Vue.set(this.selectedElementInfo, "Hospital", x);
          //a kiválasztott kórház jelző true-ra állítás
          this.isSelectedHospital = true;
          //aktuális cím tárolása
          const address = x.Contacts.Address;
          //térkép kamera beállítása a torált címre
          this.setMapCamera(address.Latitude, address.Longitude, x);
          //TODO: időpontfoglalás? list kérés
        }
      });
    },
    //kórházi osztály választás
    selectHospitalWard(event) {
      //végig megyünk az összes kórházi osztályon
      this.hospitalWardList.forEach((x) => {
        //a kiválasztott elem megyegyezik az osztály idval?
        if (x.WardId == event) {
          //igen, a kiválasztott elem info osztály részének átadjuk a kiválasztott osztály elemet
          Vue.set(this.selectedElementInfo, "Ward", x);
        }
      });
    },
    //gyógyszertár kiválasztás
    selectPharmacy(event) {
      //végig megyünk az összes gyógyszertáron
      this.pharmacyList.forEach((x) => {
        //a kiválasztott elem megegyezik az aktuális gyógyszertár id-val?
        if (x.PharmacyId == event) {
          //igen, kiválasztott elem info gyógyszeertár részét beállítjuk a kiválasztott objektimra
          Vue.set(this.selectedElementInfo, "Pharmacy", x);
          //az aktuális gyógyszer tárcímet tároljuk
          const address = x.Contacts.Address;
          //térkép kamera bellítás az aktuális gyógyszertár címére
          this.setMapCamera(address.Latitude, address.Longitude);
        }
      });
    },
    //szöveg dátummá konvertálása
    generateDate(dateText) {
      return moment(dateText).format("YYYY-MM-DD HH:mm");
    },
    //város kiválasztás a gyóygszertárak szűréséhez
    selectCityForPharmacy(event) {
      //merkerek törlése
      this.markers.forEach((x) => {
        x.setMap(null);
      });
      //gyógyszertár megjelenítő jelző true-ra álítjuk
      this.isShowPharmacyList = true;
      //kiválasztott lista alapján szűr gyógyszertár lista tárolás
      this.pharmacyList = this.cityMapForPharmacy.get(event);
      //lista sorrendezés név alapján
      this.pharmacyList.sort((a, b) => a.Name.localeCompare(b.Name));
      //a kiválasztott város Budapest?
      if (event == "BUDAPEST") {
        //igen, akkor a kerületek megjelenítő jelző true
        this.isShowAreaField = true;
      } else {
        //nem, akkor a kerületeket megjelenítő jelző false
        this.isShowAreaField = false;
      }
      //a szűr gyógyszertár lista minden elméhez készítünk markert a térképre
      this.pharmacyList.forEach((x) => {
        this.addMarker(x);
      });
    },
    //kerület kiválasztásával gyógyszertár lista szűrés
    selectAreaForPharmacy(event) {
      //minden marker eltüntetése
      this.markers.forEach((x) => {
        x.setMap(null);
      });
      //kerület szerint szűrt gyógyszertár lista tárolás
      this.pharmacyList = this.areaMapForPharmacy.get(event);
      //szűrt gyógyszertár lista minden eleméhez renelünk markert a térképre
      this.pharmacyList.forEach((x) => {
        this.addMarker(x);
      });
    },
  },
  //betöltéskor egyszer lefut
  mounted() {
    //oldal cím beállítása
    this.$store.dispatch(
      "setPageTitle",this.siteTitle);
    //térkép készítés
    this.createMap();
    //saját hely lekérdezése
    this.getMyLocation();
  },
};
</script>
<style>
.service-finder {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
#map {
  height: 600px;
  background: gray;
}
/* .city-dropdownlist{
  border:solid
} */
div.e-control.e-popup.e-popup-full-page {
  position: fixed;
}
div.e-control.e-popup.e-popup-full-page .e-dropdownbase .e-list-item {
  padding-left: 20px;
}
#goCenterUI {
  background-color: var(--eh-white);
  border: 2px solid #fff;
  border-radius: 3px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  float: left;
  margin-bottom: 22px;
  text-align: center;
}
#goCenterText {
  color: #191919;
  font-family: Roboto, Arial, sans-serif;
  font-size: 15px;
  line-height: 25px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>