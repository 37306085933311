<template>
  <div>
    <b-tabs
      class="eh-tabs"
      active-nav-item-class="eh-active-tab"
      fill
      :lazy="lazy"
    >
      <b-tab
        v-for="(tab, tabIndex) in tabs"
        :key="'tab-' + tabIndex"
        title-item-class="eh-tabs-item"
        title-link-class="eh-tabs-link"
        class="eh-tab-content"
        :disabled="disabledTabs && !tab[noneDisabledKey]"
      >
        <template #title>
          <slot name="title" v-bind="tab"></slot>
        </template>
        <slot name="content" v-bind="tab"></slot>
      </b-tab>
      <slot></slot>
    </b-tabs>
  </div>
</template>
<script>
export default {
  name: "Tabs",
  props: {
    tabs: Array,
    lazy: {
      type: Boolean,
      default: true,
    },
    disabledTabs: Boolean,
    noneDisabledKey: String,
  },
};
</script>
