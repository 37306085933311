<template>
  <div v-if="research">
    <table style="width: 100%">
      <!-- <tr>
        <td>Projektnév:</td>
        <td>
          <b><div v-html="research.Name"></div></b>
        </td>
      </tr>
      <tr>
        <td>Projektcím:</td>
        <td>
          <div v-html="research.Description"></div>
        </td>
      </tr> -->
      <tr>
        <td>{{ $t("client.programs.period") }}:</td>
        <td>
          {{ $convertDateToString(research.StartDate, "YYYY-MM-DD") }} –
          {{ $convertDateToString(research.EndDate, "YYYY-MM-DD") }}
        </td>
      </tr>
      <tr>
        <td>{{ $t("client.programs.state") }}:</td>
        <td>{{ $t($enums.ProjectStatus[state].Text) }}</td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("client.programs.projectGoal") }}:</td>
        <td>
          <div v-html="getTextByLanguage(research.ResearchGoal)"></div>
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("client.programs.projectCriteria") }}:</td>
        <td>
          <div v-html="getTextByLanguage(research.SelectionCriteria)"></div>
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("client.programs.description") }}:</td>
        <td>
          <div v-html="getTextByLanguage(research.ResearchPlan)"></div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "DataSheet",
  props: {
    research: Object,
    state: String,
  },
  methods: {
    getTextByLanguage(textObject) {
      if (textObject) {
        const languages = Object.keys(textObject);
        if (languages.includes(this.$i18n.locale)) {
          return textObject[this.$i18n.locale];
        } else if (languages.includes("en")) {
          return textObject.en;
        } else {
          return textObject[languages[0]];
        }
      } else {
        return null;
      }
    },
  },
};
</script>
<style scoped>
table tr td {
  padding: 10px 50px 10px 10px;
}
</style>
