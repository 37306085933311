<template>
  <div v-if="!params.Hidden">
    {{ params.Title ? params.Title[language] : null }}
    <div class="label-description">
      {{ params.Description ? params.Description[language] : null }}
    </div>
  </div>
</template>
<script>
export default {
  name: "LabelComponent",
  props: {
    params: Object,
    language: String,
  },
};
</script>
<style scoped>
.label-description {
  font-size: 14px;
  color: #00000099;
}
</style>