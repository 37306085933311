var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.usage)?_c('div',[_c('fp-select',{attrs:{"label":"Felírt termék felhasználás típusa","required":true,"items":_vm.eesztDosageTypeList,"valueKey":"EntryId","textKey":"Name","state":!_vm.validation.hasError('usage.TypeId'),"error":_vm.validation.firstError('usage.TypeId')},on:{"change":_vm.changeValue},model:{value:(_vm.usage.TypeId),callback:function ($$v) {_vm.$set(_vm.usage, "TypeId", $$v)},expression:"usage.TypeId"}}),(
      _vm.usage.TypeId == _vm.$enums.EesztDosageCondition.ByPartOfDay ||
      _vm.usage.TypeId == _vm.$enums.EesztDosageCondition.ByFrequence
    )?_c('fp-input',{attrs:{"label":"Felírt termék felhasználási gyakoriság","description":"pl.: 3x1 esetén 3, vagy Reggel (R), Délben (D), Este (E), Lefekvés előtt (LE)"},on:{"change":_vm.changeValue},model:{value:(_vm.usage.Frequency),callback:function ($$v) {_vm.$set(_vm.usage, "Frequency", $$v)},expression:"usage.Frequency"}}):_vm._e(),(
      _vm.usage.TypeId == _vm.$enums.EesztDosageCondition.ByPartOfDay ||
      _vm.usage.TypeId == _vm.$enums.EesztDosageCondition.ByFrequence
    )?_c('fp-input',{attrs:{"label":"Felírt termék felhasználási mennyiség, gyakoriság szerint"},on:{"change":_vm.changeValue},model:{value:(_vm.usage.Amount),callback:function ($$v) {_vm.$set(_vm.usage, "Amount", $$v)},expression:"usage.Amount"}}):_vm._e(),(
      _vm.usage.TypeId == _vm.$enums.EesztDosageCondition.ByPartOfDay ||
      _vm.usage.TypeId == _vm.$enums.EesztDosageCondition.ByFrequence
    )?_c('fp-input',{attrs:{"label":"Felírt termék felhasználási gyakoriság egység"},on:{"change":_vm.changeValue},model:{value:(_vm.usage.Unit),callback:function ($$v) {_vm.$set(_vm.usage, "Unit", $$v)},expression:"usage.Unit"}}):_vm._e(),(_vm.usage.TypeId == _vm.$enums.EesztDosageCondition.ByText)?_c('fp-text-area',{attrs:{"label":"Felírt termék felhasználása szövegesen"},on:{"change":_vm.changeValue},model:{value:(_vm.usage.UsageDescription),callback:function ($$v) {_vm.$set(_vm.usage, "UsageDescription", $$v)},expression:"usage.UsageDescription"}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }