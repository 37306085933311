<template>
  <div v-if="preliminaryApproval">
    <fp-input
      label="Méltányossági kérelemre adott engedélyszám"
      v-model="preliminaryApproval.NeakApprovalNumber"
      @change="changeValue('NeakApprovalNumber', $event)"
      :disabled="disabled"
    />
    <fp-date-picker
      label="Engedély érvényességének kezdő dátuma"
      :required="true"
      v-model="preliminaryApproval.ValidFrom"
      @change="changeValue('ValidFrom', $event)"
      :disabled="disabled"
      :state="!validation.hasError('preliminaryApproval.ValidFrom')"
      :error="validation.firstError('preliminaryApproval.ValidFrom')"
    />
    <fp-date-picker
      label="Engedély érvényességének végdátuma"
      :required="true"
      v-model="preliminaryApproval.ValidTo"
      @change="changeValue('ValidTo', $event)"
      :disabled="disabled"
      :state="!validation.hasError('preliminaryApproval.ValidTo')"
      :error="validation.firstError('preliminaryApproval.ValidTo')"
    />
  </div>
</template>
<script>
export default {
  name: "StatementPreliminaryApproval",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      preliminaryApproval: null,
      changePreliminaryApprovalValue: {},
      defaultStatementPreliminaryApproval: {
        NeakApprovalNumber: null,
        ValidFrom: null,
        ValidTo: null,
      },
    };
  },
  watch: {
    value() {
      this.setPreliminaryApproval();
    },
  },
  validators: {
    "preliminaryApproval.ValidFrom": function(value) {
      if (this.hasPreliminaryApprovalData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "preliminaryApproval.ValidTo": function(value) {
      if (this.hasPreliminaryApprovalData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
  },
  computed: {
    hasPreliminaryApprovalData() {
      return Object.values(this.preliminaryApproval).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    setPreliminaryApproval() {
      if (this.value) {
        this.preliminaryApproval = this.value;
      } else {
        this.preliminaryApproval = JSON.parse(
          JSON.stringify(this.defaultStatementPreliminaryApproval)
        );
      }
      this.resetValidation();
    },
    changeValue(key, value) {
      if (this.hasPreliminaryApprovalData) {
        this.changePreliminaryApprovalValue[key] = value;
        this.$emit("change", this.preliminaryApproval);
        this.$emit("changeValue", this.changePreliminaryApprovalValue);
      } else {
        this.$emit("change", null);
        this.$emit("changeValue", null);
      }
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
  },
  mounted() {
    this.setPreliminaryApproval();
  },
};
</script>
