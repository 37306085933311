<template>
  <div>
    <b-modal
      :no-close-on-backdrop="!closable"
      :no-close-on-esc="!closable"
      @hidden="$emit('hidden')"
      id="fp-form-modal"
      :modal-class="'eh-main-form-modal eh-main ' + otherClass"
      header-class="eh-main-form-modal-header"
      hide-footer
      :size="size"
      v-model="showForm"      
    >
      <template #modal-header>
        <div>
          <slot name="modal-header">
            <div :style="headerColor ? 'background-color: ' + headerColor : ''">
              <label class="eh-main-module-title">
                <slot name="header">{{ title }}</slot>
              </label>
              <b-icon
                @click="closeModal"
                scale="3"
                class="eh-main-form-modal-header-x"
                icon="X"
              />
            </div>
          </slot>
        </div>
      </template>
      <div>
        <slot name="content"></slot>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "FormModal",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    title: String,
    headerColor: String,
    size: String,
    closable: { type: Boolean, default: true },
    otherClass: String,
  },
  data() {
    return {
      showForm: this.visible,
    };
  },
  watch: {
    visible(input) {
      this.showForm = input;
    },
    showForm(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    closeModal() {
      this.showForm = false;
      this.$emit("closed");
    },
  },
};
</script>
