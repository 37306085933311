<template>
  <b-row v-if="doctor" cols="1" cols-md="2">
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos ENKK azonosítója"
          :required="true"
          v-model="doctor.EnkkId"
          @change="changeValue"
          :state="!validation.hasError('doctor.EnkkId')"
          :error="validation.firstError('doctor.EnkkId')"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos pecsétszáma"
          description="EESZT felküldéshez kötelező mező! Vény jelentéshez kötelező mező!"
          v-model="doctor.StampNumber"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos neve"
          v-model="doctor.FullName"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-select
          label="Vényírási kategória"
          :required="true"
          v-model="doctor.PrescriptionCategoryId"
          @change="changeValue"
          :items="eesztPrescriptionCategoryList"
          valueKey="EntryId"
          :state="!validation.hasError('doctor.PrescriptionCategoryId')"
          :error="validation.firstError('doctor.PrescriptionCategoryId')"
        >
          <template #element="e">
            {{ e.Name + " (" + e.Code + ")" }}
          </template>
        </fp-select>
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Intézmény neve"
          description="Felíró orvos egészségügyi szolgáltatójának megnevezése"
          v-model="doctor.OrganizationName"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Intézmény NNK azonosítója"
          description="Felíró orvos egészségügyi szolgáltatójának 6jegyű NNK azonosítója."
          v-model="doctor.OrganizationNnkId"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Intézmény NEAK azonosítója"
          v-model="doctor.OrganizationNeakId"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Szervezeti egység neve"
          v-model="doctor.OrganizationUnitName"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Szervezeti egység NNK azonosítója"
          description="Felíró orvos egészségügyi szolgáltatójának 9 jegyű NNK azonosítója."
          :required="true"
          v-model="doctor.OrganizationUnitNnkId"
          @change="changeValue"
          :state="!validation.hasError('doctor.OrganizationUnitNnkId')"
          :error="validation.firstError('doctor.OrganizationUnitNnkId')"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Szervezeti egység NEAK azonosítója"
          description="Felíró orvos Szervezeti egységének NEAK azonosítója"
          v-model="doctor.OrganizationUnitNeakId"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos telefonszáma"
          v-model="doctor.Phone"
          @change="changeValue"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felíró orvos címe"
          v-model="doctor.FullAddress"
          @change="changeValue"
        />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import moment from "moment";
export default {
  name: "CreatePrescriptionDoctor",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
  },
  data() {
    return {
      doctor: null,
      defaultDoctor: {
        EnkkId: null,
        StampNumber: null,
        FullName: null,
        PrescriptionCategoryId: null,
        OrganizationNnkId: null,
        OrganizationNeakId: null,
        OrganizationName: null,
        OrganizationUnitNnkId: null,
        OrganizationUnitNeakId: null,
        OrganizationUnitName: null,
        Phone: null,
        FullAddress: null,
      },
      eesztPrescriptionCategoryList: null,
    };
  },
  watch: {
    value() {
      this.setDoctorData();
    },
  },
  validators: {
    "doctor.EnkkId": function (value) {
      if (this.hasDoctorData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "doctor.PrescriptionCategoryId": function (value) {
      if (this.hasDoctorData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
    "doctor.OrganizationUnitNnkId": function (value) {
      if (this.hasDoctorData) {
        return this.$validator.value(value).required("Kötelező mező!");
      }
    },
  },
  computed: {
    hasDoctorData() {
      return Object.values(this.doctor).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    changeValue() {
      if (this.hasDoctorData) {
        this.$emit("change", this.doctor);
      } else {
        this.$emit("change", null);
      }
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
    setDoctorData() {
      if (this.value) {
        this.doctor = this.value;
      } else {
        this.doctor = JSON.parse(JSON.stringify(this.defaultDoctor));
      }
      this.resetValidation();
    },
    async getBase() {
      const getBaseResponse = await BaseDataLogic.getBaseData(
        this.$enums.BaseName.eeszt_prescription_category.Value,
        {
          ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
          ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
        }
      );
      if (!getBaseResponse.Code) {
        getBaseResponse.sort((a, b) => a.Code - b.Code);
        this.eesztPrescriptionCategoryList = getBaseResponse;
      } else {
        this.eesztPrescriptionCategoryList = [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    await this.getBase();
    this.setDoctorData();
  },
};
</script>
