<template>
  <div class="disease-guide page-background">
    <div>
      <b-row cols="1" align-h="center">
        <div class="half-page-width">
          <fp-input
            :label="$t('client.diseaseGuide.diseaseName')"
            v-model="searchParams['Name-contains']"
          />
        </div>
        <div>{{ $t("client.diseaseGuide.filteredFields") }}</div>
        <div class="half-page-width" style="margin-top: 20px; text-align: left">
          <b-row cols="1" cols-md="2">
            <div style="padding: 0px 5px">
              <fp-select
                :label="$t('client.diseaseGuide.diseaseSexList')"
                v-model="searchParams['Sex']"
                :items="Object.values(sexListItems)"
                valueKey="Value"
              >
                <template #element="element">
                  {{ $t(element.Text) }}
                </template>
              </fp-select>
            </div>
            <div style="padding: 0px 5px">
              <fp-select
                :label="$t('client.diseaseGuide.diseaseBodyPartList')"
                v-model="searchParams['BodyPart']"
                :items="bodyPartListItems"
                valueKey="Code"
                textKey="Name"
              />
            </div>
          </b-row>
          <b-row cols="1" cols-md="2">
            <div style="padding: 0px 5px">
              <fp-input
                v-model="searchParams['IcdCode-contains']"
                :label="$t('client.diseaseGuide.diseaseBNOCode')"
              />
            </div>
            <div style="padding: 0px 5px">
              <fp-input
                v-model="searchParams['Description-contains']"
                :label="$t('client.diseaseGuide.diseaseDescriptionPart')"
              />
            </div>
            <div style="text-align: center">
              <ejs-button
                v-on:click="cleanFilter"
                style="margin-bottom: 20px; margin-top: 5px"
                class="sub-button"
                >{{ $t("base.basic.clearFilter") }}</ejs-button
              >
            </div>
            <div style="text-align: center">
              <ejs-button
                v-on:click="search"
                style="margin-bottom: 20px"
                class="main-button"
                >{{ $t("base.basic.search") }}</ejs-button
              >
            </div>
          </b-row>
        </div>
      </b-row>
      <b-list-group style="margin: 10px 0px">
        <b-list-group-item style="text-align: center; color: #000">{{
          $t("client.diseaseGuide.diseaseList")
        }}</b-list-group-item>
        <b-list-group-item
          v-for="data in diseaseList"
          :key="data.DiseaseId"
          href="#"
          @click="selectDisease(data)"
        >
          <b-row
            style="margin: 0px"
            cols="1"
            align-h="between"
            align-v="center"
          >
            <label>{{ data.Name }}</label>
          </b-row>
        </b-list-group-item>
      </b-list-group>
    </div>
    <b-modal
      size="xl"
      style="background: transparent"
      id="diseaseDetails-modal"
      v-model="selectedDisease.IsActive"
      hide-footer
    >
      <template v-slot:modal-header>
        <div style="width: 100%; background: #f7b944" class="module-header">
          <b-row style="margin: 0px" align-h="between">
            <label
              class="module-title"
              style="color: var(--eh-white); margin-left: 10px"
              >{{ selectedDisease.Name }}</label
            >
            <img
              @click="selectedDisease.IsActive = false"
              style="width: 20px; height: 20px; margin: 20px; cursor: pointer"
              src="@/assets/settings-button/muvelet9.png"
            />
          </b-row>
        </div>
      </template>
      <template v-slot:default>
        <div>
          <b>{{ $t("client.diseaseGuide.diseaseBNOCode") }}:</b>
          {{ selectedDisease.IcdCode }}
          <br />
          <b>{{ $t("client.diseaseGuide.diseaseBodyPart") }}</b>
          {{ getDescriptionName(bodyPartListItems, selectedDisease.BodyPart) }}
          <br />
          <b>{{ $t("client.diseaseGuide.diseaseSex") }}</b>
          {{ getDescriptionName(sexListItems, selectedDisease.Sex) }}
          <br /><b>{{ $t("client.diseaseGuide.diseaseDescription") }}</b>
          <label v-html="selectedDisease.Description"></label>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { BaseDataLogic } from "@/logic/backend/base-data.js";
export default {
  name: "DiseaseGuide",
  components: {},
  data() {
    return {
      siteTitle: this.$t("client.menu.diseaseGuide"),
      selectedDisease: {},
      diseaseList: [],
      checkFields: { text: "Name", value: "Code" },
      //TODO: enomsítani
      bodyPartListItems: [
        { Name: "Testrészhez nem köthető", Code: "Any", id: 0 },
        { Name: "Fej", Code: "Head", id: 1 },
        { Name: "Mellkas", Code: "Chest", id: 2 },
        { Name: "Has és ágyék", Code: "Abdomen", id: 3 },
        { Name: "Hát", Code: "Back", id: 4 },
        { Name: "Felső végtag", Code: "UpperLimbs", id: 5 },
        { Name: "Alsó végtag", Code: "LowerLimbs", id: 6 },
      ],
      /* Enum */
      sexListItems: this.$enums.DiseaseGuideSex,
      searchParams: {
        "Name-contains": "",
        Sex: "",
        BodyPart: "",
        "IcdCode-contains": "",
        "Description-contains": "",
      },
      alert: {
        Message: "",
        IsShow: false,
      },
    };
  },
  methods: {
    //
    getDescriptionName(list, data) {
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (element.Code == data) {
          return element.Name;
        }
      }
    },
    async search() {
      var params = [];
      for (var [key, value] of Object.entries(this.searchParams)) {
        if (value) {
          params[key] = value;
        }
      }
      await this.getDiseaseList(params);
    },
    async getDiseaseList(params) {
      const result = await BaseDataLogic.getBaseData("disease_guide", params);
      if (!result.Code) {
        this.diseaseList = result;
      } else {
        this.$bvToast.toast(result.Message, {
          title: this.$t("requestResponse.basic.errorGetDiseaseList"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    selectDisease(disease) {
      this.selectedDisease = {
        IsActive: true,
        Name: disease.Name,
        IcdCode: disease.IcdCode,
        BodyPart: disease.BodyPart,
        Sex: disease.Sex,
        Description: disease.Description,
      };
    },
    cleanFilter() {
      this.searchParams = {
        "Name-contains": null,
        Sex: null,
        BodyPart: null,
        "IcdCode-contains": null,
        "Description-contains": null,
      };
    },
  },
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",this.siteTitle);
    await this.getDiseaseList(null);
  },
};
</script>
<style>
.disease-guide {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
#diseaseDetails-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
#diseaseDetails-modal .modal-content header {
  padding: 0px;
}
div.half-page-width {
  flex: 0 0 50%;
}
@media (max-width: 600px) {
  div.half-page-width {
    flex: 0 0 100%;
  }
}
</style>