import BaseClinSys from "./BaseClinSys";
//import Modul
import Template from "../Modules/Template";

export default {
  install(Vue) {
    //Modul use
    Vue.use(Template);
     //Base use
     Vue.use(BaseClinSys);
  },
};
