<template>
  <div v-if="selectedQuestionnaire">
    <b-button style="margin-top: 10px" @click="$router.go(-1)">{{
      $t("base.basic.back")
    }}</b-button>
    <fp-panel-card
      v-if="patient"
      style="background: white; width: fit-content; margin: auto"
      :title="$t('healthProfessional.questionnaires.userData')"
    >
      <template #content>
        <div>
          <div style="margin-top: 15px; display: flex; flex-wrap: wrap">
            <div v-for="(tag, index) in patient.Tags" :key="'tag-' + index">
              <tag :tag="tag" />
            </div>
          </div>
          <div>
            <b>{{ $t("healthProfessional.questionnaires.name") }}:</b>
            {{ patient.Name }}
          </div>
          <div>
            <b>{{ $t("healthProfessional.questionnaires.ssn") }}:</b>
            {{ patient.Ssn }}
          </div>
          <div>
            <b>{{ $t("healthProfessional.questionnaires.birthday") }}:</b>
            {{ $convertDateToString(patient.PatientDateOfBirth, "YYYY-MM-DD") }}
          </div>
          <div>
            <b>{{ $t("healthProfessional.questionnaires.address") }}:</b>
            {{
              patient.Address.ZipCode +
                " " +
                patient.Address.City +
                ", " +
                patient.Address.Street +
                " " +
                patient.Address.Building +
                ". " +
                patient.Address.SubBuilding
            }}
          </div>
        </div>
      </template>
    </fp-panel-card>
    <form-schema-builder
      v-if="selectedQuestionnaire"
      :instance="selectedQuestionnaire"
      :disabled="isSelectedQuestionnaireIsFilled"
      @closeForm="closeForm"
    />
  </div>
</template>
<script>
import { FormLogic } from "@/logic/backend/form";
import { ProjectLogic } from "@/logic/backend/project";

export default {
  name: "Questionnaire",
  data() {
    return {
      selectedQuestionnaire: null,
      questionnaireId: null,
      isSelectedQuestionnaireIsFilled: false,
      patient: null,
    };
  },
  methods: {
    //mentés nélül visszalépés a kérdőív listába
    closeForm() {
      this.$router.go(-1);
    },
    //kiválasztott kérdőív adat kérés
    async getSelectesQuestionannaire() {
      //kérés indítás
      const questionnaireListResponse = await FormLogic.getFormInstance({
        FormInstanceId: this.questionnaireId,
      });
      //sikeres kérés?
      if (!questionnaireListResponse.Code) {
        //kapott lista első elemének tárolása
        this.selectedQuestionnaire = questionnaireListResponse[0];
        if (
          this.selectedQuestionnaire &&
          /* this.selectedQuestionnaire.SubjectId && */
          this.selectedQuestionnaire.SubjectId != this.$loggedUser.UserId
        ) {
          await this.getUserData();
        }
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorGet"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      if (this.selectedQuestionnaire) {
        //a kérdőív kitöltött érték tárolása
        this.isSelectedQuestionnaireIsFilled = [
          this.$enums.QuestionnaireStatus.Filled.Value,
          this.$enums.QuestionnaireStatus.UnderEvaluation.Value,
          this.$enums.QuestionnaireStatus.Evaluated.Value,
        ].includes(this.selectedQuestionnaire.Status);
      }
    },
    async getUserData() {
      const getUserDataResponse = await ProjectLogic.getProjectSubordinates(
        this.$loggedUser.SelectedProject.ProjectId
      );
      if (!getUserDataResponse.Code) {
        this.patient = getUserDataResponse.find(
          (p) => p.UserId == this.selectedQuestionnaire.SubjectId
        );
      } else {
        this.$bvToast.toast(getUserDataResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.questionnaires.errorPatientName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kérdőív id tárolás
    const taskParams = this.$route.query.taskParams;
    if (taskParams) {
      this.questionnaireId = JSON.parse(taskParams).FormInstanceId;
    } else {
      this.questionnaireId = this.$route.query.Id;
    }
    //nincs kérdőív id
    if (!this.questionnaireId) {
      //nincs, visszaküld a listába
      this.$router.go(-1);
    }
    //oldal címének beállítása
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.questionnaires.questionnaireFill")
    );
    //kiválasztott kérdőív betöltése
    await this.getSelectesQuestionannaire();
  },
};
</script>
