<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="interval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :id="'diabetology-bloodglucose-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      background="transparent"
      width="100%"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :tooltip="
        IsDashboard
          ? {}
          : chartType == $enums.DiagramType.chart
          ? chartTooltip
          : diaryTooltip
      "
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection v-if="chartType == $enums.DiagramType.chart">
        <e-series
          :dataSource="chartData"
          type="RangeColumn"
          :high="Chart.DataProperties.chart.high"
          :low="Chart.DataProperties.chart.low"
          :xName="Chart.DataProperties.chart.x"
          :marker="IsDashboard ? {} : marker"
        ></e-series>
      </e-series-collection>
      <e-series-collection v-else-if="chartType == $enums.DiagramType.diary">
        <e-series
          :dataSource="chartData"
          type="Column"
          :yName="Chart.DataProperties.diary.y"
          :xName="Chart.DataProperties.diary.x"
          :marker="IsDashboard ? {} : marker"
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import { DiaryLogic } from "@/logic/backend/diary";
import moment from "moment";
import {
  ChartPlugin,
  DateTime,
  Tooltip,
  StripLine,
  RangeColumnSeries,
  ColumnSeries,
} from "@syncfusion/ej2-vue-charts";
import { ChartUiLogic } from "@/logic/ui/chart-ui";
import { ChartBackendLogic } from "@/logic/backend/chart";
import IntervalManager from "@/components/module/chart/IntervalManager.vue";

Vue.use(ChartPlugin);

export default {
  name: "DiabetologyBloodGlucose",
  components: {
    IntervalManager,
  },
  data() {
    return {
      chartData: [],
      currentDate: moment(),
      currentDateText: null,
      interval: null,
      chartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        title: this.IsDashboard
          ? null
          : this.$t("module.charts." + this.Chart.Name) +
            " [" +
            this.Chart.DisplayUnit +
            "]",
        valueType: "Double",
        labelFormat: "n0",
        minimum: 0,
      },
      palettes: ["var(--eh-secondary-5)"],
      marker: {
        visible: true,
      },
      chartTooltip: {
        enable: true,
        header: "${point.x} ${series.name}",
        format:
          this.$t("module.charts.bloodGlucoseMax") +
          ": <b>${point.high}</b> " +
          this.Chart.DisplayUnit +
          "<br>" +
          this.$t("module.charts.bloodGlucoseMin") +
          ": <b>${point.low}</b> " +
          this.Chart.DisplayUnit,
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      },
      diaryTooltip: {
        enable: true,
        header: this.$t("module.charts.BloodGlucose"),
        format: "${point.x}: <b>${point.y}</b> " + this.Chart.DisplayUnit,
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        },
      },
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
    };
  },
  props: {
    Interval: String,
    ChartType: String,
    PatientId: Number,
    IsDashboard: Boolean,
    Chart: Object,
    Id: [String, Number],
  },
  provide: {
    chart: [DateTime, Tooltip, StripLine, RangeColumnSeries, ColumnSeries],
  },
  methods: {
    async intervalChanged(currentDate, interval) {
      this.currentDate = currentDate;
      this.interval = interval;
      if (interval == this.$enums.ChartInterval.Day.Value) {
        this.chartType = this.$enums.DiagramType.diary;
      } else {
        this.chartType = this.ChartType;
      }
      this.setInterval();
      await this.getData();
    },
    setInterval() {
      const result = ChartUiLogic.IntervalManager.setInterval(
        this.interval,
        this.xAxis,
        this.currentDate
      );
      this.currentDate = result.currentDate;
      this.currentDateText = result.currentDateString;
      this.xAxis = result.xAxis;
      //fillEmptyDays
    },
    async getData() {
      if (this.chartType == this.$enums.DiagramType.chart) {
        await this.getChartData();
      } else if (this.chartType == this.$enums.DiagramType.diary) {
        await this.getDiaryData();
      }
      //this.fillEmptyDays();
    },
    async getChartData() {
      var responseChartData = null;
      if (this.PatientId) {
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      if (!responseChartData.Code) {
        this.convertChartData(responseChartData);
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.fillEmptyDays(this.Chart.DataProperties.chart.x, {
        [this.Chart.DataProperties.chart.high]: null,
        [this.Chart.DataProperties.chart.low]: null,
      });
    },
    convertChartData(data) {
      this.chartData = [];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        this.chartData.push({
          [this.Chart.DataProperties.chart.low]:
            element[this.Chart.DataProperties.chart.low].BloodGlucoseValue,
          [this.Chart.DataProperties.chart.high]:
            element[this.Chart.DataProperties.chart.high].BloodGlucoseValue,
          [this.Chart.DataProperties.chart.x]:
            element[this.Chart.DataProperties.chart.x],
        });
      }
    },
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    async getDiaryData() {
      var responseChartData = null;
      if (this.PatientId) {
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToDiary()
        );
      } else {
        responseChartData = await DiaryLogic.getDiary(
          this.Chart.DatabaseName,
          this.getIntervalParamsToDiary()
        );
      }
      if (!responseChartData.Code) {
        this.chartData = responseChartData;
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.fillEmptyDays(this.Chart.DataProperties.diary.x, {
        [this.Chart.DataProperties.diary.y]: null,
      });
    },
    getIntervalParamsToDiary() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToDiary(
        this.interval,
        this.currentDate
      );
    },
    fillEmptyDays(datetimeName, value) {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          [datetimeName]: moment(lastElement[datetimeName])
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          ...value,
        });
      }
    },
  },
  async mounted() {
    this.chartType = this.ChartType;
    this.interval = this.Interval;
    this.setInterval();
    await this.getData();
  },
};
</script>