<!-- Version 1.0.1 -->
<template>
  <div v-if="pushTemplate">
    <fp-panel-card
      :closedCard="true"
      :title="$t('components.templates.push')"
      style="margin: 10px 0px"
    >
      <!-- Sablon típusa -->
      <template #title>
        <b-icon style="margin: 0px 5px" icon="phone-vibrate"></b-icon>
        {{ $t("components.templates.push") }}
      </template>
      <!-- Sablon adatok -->
      <template #content>
        <!-- Push címe -->
        <fp-input
          :label="$t('components.templates.pushTitle')"
          :disabled="!editable"
          v-model="pushTemplate.Title[lang]"
          @change="changeValue('PushTemplate.Title', pushTemplate.Title)"
        />
        <!-- Push üzenet -->
        <fp-text-area
          :label="$t('components.templates.pushMessage')"
          :disabled="!editable"
          v-model="pushTemplate.Body[lang]"
          @change="changeValue('PushTemplate.Body', pushTemplate.Body)"
        />
        <!-- Push képe -->
        <fp-input
          :disabled="!editable"
          :label="$t('components.templates.pushImg')"
          v-model="pushTemplate.ImageUrl[lang]"
          @change="changeValue('PushTemplate.ImageUrl', pushTemplate.ImageUrl)"
        />
        <div>
          <img
            width="200px"
            v-if="pushTemplate.ImageUrl"
            :src="pushTemplate.ImageUrl[lang]"
          />
        </div>
        <!-- <fp-input
          v-if="!pushImg[lang]"
          :disabled="!editable"
          :label="$t('components.templates.pushImg')"
          type="file"
          @change="selectImg($event, lang)"
        />
        <div v-else>
          <div>
            {{ $t("components.templates.pushImg") }}
          </div>
          <img width="200px" :src="pushImg[lang].URL" />
          <b-icon
            v-if="!editable"
            @click="$set(pushImg, lang, null)"
            scale="2"
            style="margin: 0px 15px; cursor: pointer"
            variant="danger"
            icon="x-circle-fill"
          ></b-icon>
        </div> -->
        <button
          v-if="editable"
          @click="removePushTemplate"
          class="eh-action-button"
        >
          {{ $t("components.templates.removePush") }}
        </button>
      </template>
    </fp-panel-card>
  </div>
</template>
<script>
export default {
  name: "PushTemplate",
  model: {
    prop: "pushTemplateProp",
    event: "change",
  },
  props: {
    pushTemplateProp: Object,
    editable: Boolean,
    lang: String,
    supportedLangs: Array,
  },
  data() {
    return {
      pushTemplate: null,
      changedValues: null,
      defaultPushTemplate: {
        Title: null,
        Body: null,
        ImageUrl: null,
      },
    };
  },
  watch: {
    supportedLangs() {
      this.pushTemplate = this.setLanguagedPushTemplate(this.pushTemplate);
      //this.$emit("change", this.pushTemplate);
    },
    pushTemplateProp(input) {
      this.pushTemplate = this.setLanguagedPushTemplate(input);
      this.changedValues = null
    },
  },
  methods: {
    //TODO: backendre vár, push img bekötés
    /* selectImg(input, lang) {
      const fileList = input.target.files;
      this.$set(this.pushImg, lang, { URL: null, Blob: null });
      if (fileList) {
        this.tempFile = fileList[0];
        var blob = new Blob([this.tempFile]);
        this.$set(this.pushImg[lang], "Blob", blob);
        this.$set(this.pushImg[lang], "URL", URL.createObjectURL(blob));
      }
    }, */

    changeValue(path, value) {
      if (!this.changedValues) {
        this.changedValues = {};
      }
      this.$set(this.changedValues, path, value);
      this.$emit("change", this.pushTemplate);
      this.$emit("changedValues", this.changedValues);
    },
    removePushTemplate() {
      this.$emit("change", null);
      this.$emit("changedValues", { PushTemplate: null });
    },
    setLanguagedPushTemplate(pushTemplate) {
      const push = JSON.parse(JSON.stringify(this.defaultPushTemplate));
      this.supportedLangs.forEach((sLang) => {
        if (!push.Title) {
          push.Title = {};
        }
        if (!push.Body) {
          push.Body = {};
        }
        if (!push.ImageUrl) {
          push.ImageUrl = {};
        }

        if (pushTemplate) {
          if (pushTemplate.Title && pushTemplate.Title[sLang]) {
            push.Title[sLang] = pushTemplate.Title[sLang];
          } else {
            push.Title[sLang] = null;
          }
          if (pushTemplate.Body && pushTemplate.Body[sLang]) {
            push.Body[sLang] = pushTemplate.Body[sLang];
          } else {
            push.Body[sLang] = null;
          }
          if (pushTemplate.ImageUrl && pushTemplate.ImageUrl[sLang]) {
            push.ImageUrl[sLang] = pushTemplate.ImageUrl[sLang];
          } else {
            push.ImageUrl[sLang] = null;
          }
        } else {
          push.Title[sLang] = null;
          push.Body[sLang] = null;
          push.ImageUrl[sLang] = null;
        }
      });
      return push;
    },
  },
  mounted() {
    this.pushTemplate = this.setLanguagedPushTemplate(this.pushTemplateProp);
  },
};
</script>
