<template>
  <div class="page-background">
    <div>
      <div style="text-align: center" class="diary-title">
        {{ $t("module.diaries.medicine.post") }}
      </div>
      <div style="margin: 20px 0px">
        <button class="eh-action-button" @click="goBack">
          {{ $t("base.basic.back") }}
        </button>
        <button class="eh-action-button" @click="showMedicineDiaryInput = true">
          {{ $t("module.diaries.medicine.logging") }}
        </button>
      </div>

      <fp-client-module-diary baseName="medicine" v-model="refreshList" />

      <MedicineDiaryInput
        v-model="showMedicineDiaryInput"
        :defaultParams="taskParams"
        @refreshList="refreshList = true"
      />
    </div>
  </div>
</template>

<script>
import MedicineDiaryInput from "@/views/client/input/MedicineDiaryInput.vue";

export default {
  name: "MedicineDiary",
  components: { MedicineDiaryInput },
  data() {
    return {
      refreshList: false,
      taskParams: null,
      showMedicineDiaryInput: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t("module.diaries.medicine.title"));
    if (this.$route.query && this.$route.query.taskParams) {
      this.taskParams = JSON.parse(this.$route.query.taskParams);
      if (this.taskParams) {
        this.$bvModal.show("medicine-input-modal");
      }
    }
  },
};
</script>
<style>
.diary-title {
  font-size: 1.5em;
}
.e-grid .e-headercell.customcss {
  font-size: 20px;
  word-wrap: normal;
}
</style>
