<template>
  <fp-form-modal
    size="xl"
    title="ESSZT recept adatok"
    v-model="visible"
    :closable="!editable"
  >
    <template #content>
      <div v-if="prescription">
        <!-- Felíró orvos -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Felíró orvos"
            description="EESZT felküldéshez kötelező mező!"
            :closedCard="true"
            :error="errorDoctor"
          >
            <template #content>
              <doctor
                ref="formPrescriptionDoctor"
                v-model="prescription.PrescribingDoctor"
                :disabled="!editable"
                @changeValue="changeValue('PrescribingDoctor', $event)"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- Vényt rögzító -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Vényt rögzítő felhasználó adatai"
            :required="true"
            :closedCard="true"
          >
            <template #content>
              <submitter
                ref="formPrescriptionSubmitter"
                v-model="prescription.Submitter"
                :disabled="!editable"
                @changeValue="changeValue('Submitter', $event)"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- páciens -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Páciens adatai"
            :closedCard="true"
            :required="true"
            :error="errorPatient"
          >
            <template #content>
              <div v-if="editable && !patientId" style="margin: 0px 10px">
                <fp-select
                  label="Páciens lista"
                  :required="true"
                  description="Keresés felhasználónév szerint"
                  v-model="prescription.Patient.PatientId"
                  valueKey="UserId"
                  @change="selectedPatient"
                  :items="filteredPatientList"
                  :searchable="true"
                  @search="searchUser"
                >
                  <template #element="e">
                    {{
                      e.Name +
                      " (" +
                      $convertDateToString(e.DateOfBirth, "YYYY-MM-DD") +
                      (e.Ssn ? ", " + e.Ssn : "") +
                      ")"
                    }}
                  </template>
                </fp-select>
              </div>
              <patient
                ref="formPrescriptionPatient"
                v-model="prescription.Patient"
                :disabled="!editable"
                @changeValue="changeValue('Patient', $event)"
                :prescriptionTimestamp="prescription.PrescriptionTimestamp"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- recept alap adatai -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Recept alap adatai"
            :closedCard="true"
            :required="true"
            :error="errorBaseData"
          >
            <template #content>
              <b-row cols="1" cols-md="2">
                <b-col v-if="!editable">
                  <fp-input
                    label="EESZT azonosító"
                    :disabled="true"
                    :value="prescription.EesztId"
                  />
                </b-col>
                <b-col>
                  <fp-input
                    label="Papíralapú NEAK vényazonosító"
                    description="EESZT felküldéshez kötelező mező!"
                    :disabled="!editable"
                    @change="changeValue('NeakId', $event)"
                    v-model="prescription.NeakId"
                  />
                </b-col>
                <b-col>
                  <fp-date-picker
                    label="Recept érvényesség kezdete"
                    :disabled="!editable"
                    @change="changeValue('PrescriptionValidFrom', $event)"
                    v-model="prescription.PrescriptionValidFrom"
                  />
                </b-col>
                <b-col>
                  <fp-date-picker
                    label="Recept érvényesség vége"
                    :disabled="!editable"
                    @change="changeValue('PrescriptionValidTo', $event)"
                    v-model="prescription.PrescriptionValidTo"
                  />
                </b-col>
                <b-col>
                  <fp-input
                    label="Forgalmi / ambuláns napló száma"
                    description="EESZT felküldéshez kötelező mező!"
                    :disabled="!editable"
                    @change="changeValue('OutpatientLogNumber', $event)"
                    v-model="prescription.OutpatientLogNumber"
                  />
                </b-col>
                <b-col>
                  <code-name-pair
                    label="BNO szerinti betegség kód"
                    :required="true"
                    :disabled="!editable"
                    @changeValue="changeValue('Bno', $event)"
                    v-model="prescription.Bno"
                    :items="eesztBNOList"
                    :searchable="true"
                    @search="searchBNO"
                    listValueKey="EntryId"
                  >
                    <template #element="e">
                      <div>{{ e.Name }}</div>
                      <div>({{ e.Code }})</div>
                    </template>
                  </code-name-pair>
                </b-col>
                <b-col>
                  <fp-date-picker
                    label="Felirás dátuma"
                    :disabled="!editable"
                    @change="changeValue('PrescriptionTimestamp', $event)"
                    v-model="prescription.PrescriptionTimestamp"
                    :required="true"
                    :state="
                      !validation.hasError('prescription.PrescriptionTimestamp')
                    "
                    :error="
                      validation.firstError(
                        'prescription.PrescriptionTimestamp'
                      )
                    "
                  />
                </b-col>
                <b-col>
                  <code-name-pair
                    label="Vény típusa"
                    :required="true"
                    :disabled="!editable"
                    @changeValue="changeValue('PrescriptionType', $event)"
                    v-model="prescription.PrescriptionType"
                    :items="eesztPrescriptionTypeList"
                    listValueKey="EntryId"
                    listTextKey="Name"
                  />
                </b-col>
                <b-col>
                  <code-name-pair
                    label="Termék típusa"
                    :required="true"
                    :disabled="!editable"
                    @changeValue="changeValue('ProductType', $event)"
                    v-model="prescription.ProductType"
                    :items="eesztProductTypeList"
                    listValueKey="EntryId"
                    listTextKey="Name"
                  />
                </b-col>
              </b-row>
            </template>
          </fp-panel-card>
        </div>
        <!-- termékek -->
        <div style="margin: 10px 0px" v-if="prescription.Products">
          <fp-panel-card
            title="Felírt termékek"
            :required="true"
            :closedCard="true"
            :error="errorProducts"
          >
            <template #content>
              <fp-panel-card
                style="margin: 20px 0px"
                v-for="(product, index) in prescription.Products"
                :key="'eeszt-recipe-product-' + productIds[index]"
                :title="index + 1 + '. termék'"
                :closedCard="index != 0"
                :isShowAddButton="editable"
                :isShowDeleteButton="
                  editable && prescription.Products.length > 1
                "
                :error="errorProduct.includes(index)"
                @addNewElement="addNewProductToRecipe(index)"
                @deleteElement="deleteProductFromRecipe(index)"
              >
                <template #content>
                  <prescription-product
                    ref="formPrescriptionProduct"
                    v-model="prescription.Products[index]"
                    :disabled="!editable"
                    @changeValue="
                      changeValue('Products[' + index + ']', $event)
                    "
                  />
                </template>
              </fp-panel-card>
            </template>
          </fp-panel-card>
        </div>
        <!-- ellenjegyzés -->
        <!-- <div style="margin: 10px 0px">
          <fp-panel-card
            title="Ellenjegyzés"
            description="EESZT felküldéshez kötelező mező!"
            :closedCard="true"
          >
            <template #content>
              <countersign
                ref="formPrescriptionCountersign"
                v-model="prescription.CounterSign"
                @changeValue="changeValue('CounterSign', $event)"
                :disabled="!editable"
              />
            </template>
          </fp-panel-card>
        </div> -->
        <!-- Szakorvosi javaslat -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Szakorvosi javaslat"
            :error="errorSpecialistRecommendation"
            :closedCard="true"
          >
            <template #content>
              <!-- TODO: milyen lista? -->
              <fp-input
                label="Szakorvosi javaslat azonosító"
                :disabled="!editable"
                @change="
                  changeValue('SpecialistRecommendationObjectId', $event)
                "
                v-model="prescription.SpecialistRecommendationObjectId"
              />
              <specialist-recommendation
                ref="formPrescriptionSpecialistRecommendation"
                v-model="prescription.SpecialistRecommendation"
                @changeValue="changeValue('SpecialistRecommendation', $event)"
                :disabled="!editable"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- Kihordási időn belüli változás -->
        <div style="margin: 10px 0px">
          <fp-panel-card
            title="Állapot"
            :error="errorStatement"
            :closedCard="true"
          >
            <template #content>
              <statement
                ref="formPrescriptionStatement"
                v-model="prescription.Statement"
                @changeValue="changeValue('Statement', $event)"
                :disabled="!editable"
              />
            </template>
          </fp-panel-card>
        </div>
        <!-- Dokumentum -->
        <fp-panel-card
          style="margin: 10px 0px"
          title="Dokumentum adatai"
          :closedCard="true"
        >
          <template #content>
            <fp-input
              label="Dokumentum EHR azonosítója"
              description="Elbíráláshoz szükséges dokumentum EHR azonosítója"
              :disabled="!editable"
              @change="changeValue('DocumentId', $event)"
              v-model="prescription.DocumentId"
            />
            <fp-text-area
              label="Dokumentum leírása"
              description="Elbíráláshoz szükséges dokumentum leírása, ha papíralapú"
              :disabled="!editable"
              @change="changeValue('DocumentDescription', $event)"
              v-model="prescription.DocumentDescription"
            />
          </template>
        </fp-panel-card>

        <b-button v-if="editable" @click="saveChangedValues">Mentés</b-button>
        <b-button v-if="editable" @click="visible = false">Mégsem</b-button>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
/* Components */
import Doctor from "../Components/form/Doctor.vue";
import Submitter from "../Components/form/Submitter.vue";
import Patient from "../Components/form/Patient.vue";
import CodeNamePair from "../Components/form/CodeNamePair.vue";
import PrescriptionProduct from "../Components/form/PrescriptionProduct.vue";
import SpecialistRecommendation from "../Components/common/SpecialistRecommendation.vue";
import Statement from "../Components/common/Statement.vue";
/* Logic */
import { MedicalDeviceLogic } from "../../../Logic/Backend/medical-device";
import { UserProfileLogic } from "../../../Logic/Backend/user-profile";
import { BaseDataLogic } from "../../../Logic/Backend/base-data";
import { UserLogic } from "../../../Logic/Backend/user";
import moment from "moment";
export default {
  components: {
    Doctor,
    Submitter,
    Patient,
    CodeNamePair,
    PrescriptionProduct,
    SpecialistRecommendation,
    Statement,
  },
  name: "PrescriptionForm",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    recipe: Object,
    editable: Boolean,
    prescriptionId: Number,
    patientId: Number,
    projectPatient: Array,
    projectRoles: Array,
  },
  data() {
    return {
      visible: null,
      prescription: JSON.parse(JSON.stringify(this.recipe)),
      defaultForm: {
        PrescriptionVersion: null,
        EesztId: null,
        NeakId: null,
        OutpatientLogNumber: null,
        PrescriptionValidFrom: null,
        PrescriptionValidTo: null,
        PrescriptionTimestamp: null,
        PrescriptionType: null,
        Patient: null,
        PrescribingDoctor: null,
        Submitter: null,
        CounterSign: null,
        Bno: null,
        Products: [null],
        ProductType: null,
        SpecialistRecommendationObjectId: null,
        SpecialistRecommendation: null,
        Statement: null,
        DocumentId: null,
        DocumentDescription: null,
      },
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
      productIds: null,
      productsCount: null,
      changePrescription: { Products: [] },
      filteredPatientList: null,
      eesztProductTypeList: null,
      eesztPrescriptionTypeList: null,
      eesztBNOList: null,
      validDateFilterParams: {
        ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
        ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
      },
      //error
      errorDoctor: false,
      errorPatient: false,
      errorBaseData: false,
      errorProducts: false,
      errorProduct: [],
      errorSpecialistRecommendation: false,
      errorStatement: false,
    };
  },
  validators: {
    "prescription.PrescriptionTimestamp": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  watch: {
    async value(input) {
      this.visible = input;
      this.setPrescription();
      await this.setSelectedListElement();
      await this.getLists();
    },
    visible(input) {
      this.$emit("change", input);
      if (!input) {
        this.$emit("update:editable", false);
      }
    },
    async recipe() {
      this.setPrescription();
      await this.setSelectedListElement();
      await this.getLists();
    },
    /* projectPatient(input) {
      if (input) {
        this.filteredPatientList = input.slice(0, 10);
      }
    }, */
  },
  methods: {
    /* selectedPatient(patientId) {
      const patient = this.projectPatient.find((p) => p.UserId == patientId);
      if (patient) {
        this.prescription.Patient = {};
        this.prescription.Patient.PatientId = patient.UserId;
        this.prescription.Patient.FullName = patient.Name;
        this.prescription.Patient.PatientIdentifierValue = patient.Ssn;
        //TODO: this.recipeData.Patient.GenderId = Sex
        //TODO: this.recipeData.Patient.PatientIdentifierTypeId = SSN
        this.prescription.Patient.Phone = patient.Contact.Phone;
        this.prescription.Patient.BirthDate = patient.DateOfBirth;
        this.$set(this.prescription.Patient, "Address", null);
        this.prescription.Patient.Address = {
          PostalCode: patient.Contact.Address.ZipCode,
          City: patient.Contact.Address.City,
          StreetAddress: patient.Contact.Address.Street
            ? patient.Contact.Address.Street
            : null + " " + patient.Contact.Address.Building
            ? patient.Contact.Address.Building
            : null + " " + patient.Contact.Address.SubBuilding
            ? patient.Contact.Address.SubBuilding
            : null,
        };
      }
      if (this.$refs["createPrescriptionPatient"]) {
        this.$refs["createPrescriptionPatient"].resetValidation();
      }
    }, */
    async selectedPatient(patientId) {
      if (patientId) {
        const patientProfile = await this.getPatientProfileData(patientId);
        if (patientProfile) {
          this.prescription.Patient = {};
          //user azonosító
          this.prescription.Patient.PatientId = patientProfile.UserId;
          this.changeValue("Patient.PatientId", patientProfile.UserId);
          //user neve
          this.prescription.Patient.FullName =
            patientProfile.PersonalProfile.Name;
          //user igazolvány azonosító - SSN / EUSSN
          if (!this.prescription.Patient.PatientIdentifierType) {
            this.prescription.Patient.PatientIdentifierType = {};
          }
          if (patientProfile.PersonalProfile.SocialSecurityNumber) {
            this.prescription.Patient.PatientIdentifierValue =
              patientProfile.PersonalProfile.SocialSecurityNumber;
            this.prescription.Patient.PatientIdentifierType.Id = this.$enums.EesztPatientTransfer.Ssn.EesztPatientEntryId;
          } else if (patientProfile.PersonalProfile.EuSocialSecurityNumber) {
            this.prescription.Patient.PatientIdentifierValue =
              patientProfile.PersonalProfile.EuSocialSecurityNumber;
            this.prescription.Patient.PatientIdentifierType.Id = this.$enums.EesztPatientTransfer.EuSsn.EesztPatientEntryId;
          }
          // user neme - Female / Male
          if (!this.prescription.Patient.Gender) {
            this.prescription.Patient.Gender = {};
          }
          if (
            patientProfile.PersonalProfile.Sex ==
            this.$enums.EesztPatientTransfer.GenderFemale.UserProfileId
          ) {
            this.prescription.Patient.Gender.Id = this.$enums.EesztPatientTransfer.GenderFemale.EesztPatientEntryId;
          } else if (
            patientProfile.PersonalProfile.Sex ==
            this.$enums.EesztPatientTransfer.GenderMale.UserProfileId
          ) {
            this.prescription.Patient.Gender.Id = this.$enums.EesztPatientTransfer.GenderMale.EesztPatientEntryId;
          }
          //user telefon
          this.prescription.Patient.Phone =
            patientProfile.PersonalProfile.PersonalContact.Phone;
          //user születésnap
          this.prescription.Patient.BirthDate =
            patientProfile.PersonalProfile.Birthday;
          //user cím
          this.$set(this.prescription.Patient, "Address", null);
          this.prescription.Patient.Address = {
            PostalCode:
              patientProfile.PersonalProfile.PersonalContact.Address.ZipCode,
            City: patientProfile.PersonalProfile.PersonalContact.Address.City,
            StreetAddress: patientProfile.PersonalProfile.PersonalContact
              .Address.Street
              ? patientProfile.PersonalProfile.PersonalContact.Address.Street
              : null +
                " " +
                patientProfile.PersonalProfile.PersonalContact.Address.Building
              ? patientProfile.PersonalProfile.PersonalContact.Address.Building
              : null +
                " " +
                patientProfile.PersonalProfile.PersonalContact.Address
                  .SubBuilding
              ? patientProfile.PersonalProfile.PersonalContact.Address
                  .SubBuilding
              : null,
          };
          //user ph
          this.prescription.Patient.PublicHealthIdentifier =
            patientProfile.PersonalProfile.PublicHealthcareCardNumber;
          this.prescription.Patient.PublicHealthIdentifierValidBy = moment(
            patientProfile.PersonalProfile.PublicHealthcareCardValidBy
          ).format("YYYY-MM-DD");
          //euDocCode
          this.prescription.Patient.EuDocumentCode =
            patientProfile.PersonalProfile.EuDocumentCode;
        }
      }
      if (this.$refs["createPrescriptionPatient"]) {
        this.$refs["createPrescriptionPatient"].resetValidation();
      }
    },
    async getPatientProfileData(userId) {
      const getProfileResponse = await UserProfileLogic.getOtherProfile(userId);
      if (!getProfileResponse.Code) {
        return getProfileResponse;
      } else {
        //TODO: hiba
      }
    },
    async searchUser(input) {
      /* this.filteredPatientList = this.$filterList(input, this.projectPatient, [
        "Username",
        "Name",
        "Ssn",
        "DateOfBirth",
      ]); */
      if (input.length > 2) {
        await this.getProjectPatientsData({
          personName: input,
        });
      } else if (input) {
        this.filteredPatientList = null;
      } else {
        await this.getProjectPatientsData({
          "-limit": 100,
        });
      }
    },
    changeValue(key, value) {
      if (!this.changePrescription) {
        this.changePrescription = {};
      }
      this.changePrescription[key] = value;
    },
    async checkValidation() {
      const valid = await this.$validate();
      this.errorBaseData = !valid;

      const doctorValid = await this.$refs[
        "formPrescriptionDoctor"
      ].checkValidation();
      this.errorDoctor = !doctorValid;

      const patientValid = await this.$refs[
        "formPrescriptionPatient"
      ].checkValidation();
      this.errorPatient = !patientValid;

      const productValidList = await Promise.all(
        this.$refs["formPrescriptionProduct"].map((p) => {
          return p.checkValidation();
        })
      );
      this.errorProduct = [];
      productValidList.forEach((p, index) => {
        if (!p) {
          this.errorProduct.push(index);
        }
      });
      const productValid = !productValidList.some((x) => !x);
      this.errorProducts = !productValid;

      /* const countersignValid =
        this.$refs["formPrescriptionCountersign"].checkValidation(); */

      const specialistRecommendatioValid = await this.$refs[
        "formPrescriptionSpecialistRecommendation"
      ].checkValidation();
      this.errorSpecialistRecommendation = !specialistRecommendatioValid;

      const statementValid = await this.$refs[
        "formPrescriptionStatement"
      ].checkValidation();
      this.errorStatement = !statementValid;

      return (
        valid &&
        doctorValid &&
        patientValid &&
        productValid &&
        /* countersignValid && */
        specialistRecommendatioValid &&
        statementValid
      );
    },
    resetValidation() {
      this.validation.reset();
      this.errorBaseData = false;

      if (this.$refs["formPrescriptionDoctor"]) {
        this.$refs["formPrescriptionDoctor"].resetValidation();
      }
      this.errorDoctor = false;

      if (this.$refs["formPrescriptionPatient"]) {
        this.$refs["formPrescriptionPatient"].resetValidation();
      }
      this.errorPatient = false;

      if (this.$refs["formPrescriptionProduct"]) {
        this.$refs["formPrescriptionProduct"].forEach((product) =>
          product.resetValidation()
        );
      }
      this.errorProduct = [];
      this.errorProducts = false;

      /* if (this.$refs["createPrescriptionCountersign"]) {
        this.$refs["createPrescriptionCountersign"].resetValidation();
      } */

      if (this.$refs["formPrescriptionSpecialistRecommendation"]) {
        this.$refs[
          "formPrescriptionSpecialistRecommendation"
        ].resetValidation();
      }
      this.errorSpecialistRecommendation = false;

      if (this.$refs["formPrescriptionStatement"]) {
        this.$refs["formPrescriptionStatement"].resetValidation();
      }
      this.errorStatement = false;
    },
    addNewProductToRecipe(index) {
      this.productsCount++;
      const arrayIndex = index + 1;
      this.productIds.splice(arrayIndex, 0, this.productsCount);
      this.prescription.Products.splice(arrayIndex, 0, null);
      this.changePrescription["Products[" + arrayIndex + "]"] = {};
    },
    deleteProductFromRecipe(index) {
      this.productIds.splice(index, 1);
      this.prescription.Products.splice(index, 1);
      this.changePrescription["Products[" + index + "]"] = null;
    },
    async saveChangedValues() {
      const valid = await this.checkValidation();
      const body = this.getChangedPathValue();
      //console.log(body);
      if (valid) {
        const saveResponse = await MedicalDeviceLogic.updatePrescription(
          this.prescriptionId,
          body
        );
        if (!saveResponse.Code) {
          this.$bvToast.toast("Sikeresen módosította a receptet!", {
            variant: "success",
            title: "Sikeres mentés!",
            solid: true,
          });
          this.changePrescription = { Products: [] };
          this.visible = false;
        } else {
          this.$bvToast.toast(saveResponse.Message, {
            variant: "danger",
            title: "Hiba történt a recept módosítása során!",
            solid: true,
            noAutoHide: true,
          });
        }
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(
          "Nézze át az űrlapot, hogy minden adat helyesen lett kitöltve!",
          {
            title: "Hibás mező kitöltés",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    getChangedPathValue() {
      var changedFields = {};
      Object.entries(this.changePrescription).forEach(([key, value]) => {
        const changed = this.getPath(value, key);
        if (changed) {
          Object.assign(changedFields, changed);
        }
      });
      return changedFields;
    },
    getPath(obj, path) {
      if (typeof obj == "object" && obj != null && obj !== undefined) {
        const result = {};
        Object.entries(obj).forEach(([key, value]) => {
          const r = this.getPath(value, path + "." + key);
          if (r) {
            Object.assign(result, r);
          }
        });
        return result;
      } /* else if (Array.isArray(obj)) {
        const result = {};
        for (var i = 0; i < obj.length; i++) {
          Object.entries(obj[i]).forEach(([key, value]) => {
            const r = this.getPath(value, path + "[" + i + "]." + key);
            if (r) {
              Object.assign(result, r);
            }
          });
        }
        return result;
      }  */ else {
        return { [path]: obj };
      }
    },
    setPrescription() {
      if (this.value) {
        this.productsCount = this.prescription.Products.length;
        this.productIds = Array.from(Array(this.productsCount).keys());
        if (this.recipe) {
          this.prescription = JSON.parse(JSON.stringify(this.recipe));
        } else {
          this.prescription = JSON.parse(JSON.stringify(this.defaultForm));
        }
      }
      this.changePrescription = { Products: [] };
      this.resetValidation();
    },
    async searchBNO(input) {
      if (input.length > 2) {
        const nameContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            ["Name-contains"]: input,
          }
        );
        const codeContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            ["Code-contains"]: input,
          }
        );
        this.eesztBNOList = this.checkDuplicates([
          ...nameContainsList,
          ...codeContainsList,
        ]);
      } else if (input.length == 0) {
        this.eesztBNOList = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            "-orderby": "Code",
            ["-limit"]: 10,
          }
        );
      } else {
        this.eesztBNOList = [];
      }
      await this.setSelectedListElement();
    },
    checkDuplicates(things) {
      return things.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.EntryId === thing.EntryId)
      );
    },
    async setSelectedListElement() {
      if (
        this.prescription.Bno &&
        this.prescription.Bno.Id &&
        this.eesztBNOList &&
        !this.eesztBNOList.find((e) => e.EntryId == this.prescription.Bno.Id)
      ) {
        const elements = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            EntryId: this.prescription.Bno.Id,
          }
        );
        if (elements) {
          this.eesztBNOList.push(elements[0]);
        }
      }
    },
    async getLists() {
      if (this.value) {
        this.eesztProductTypeList = await this.getBase(
          this.$enums.BaseName.eeszt_product_type.Value,
          {
            ...this.validDateFilterParams,
          }
        );

        this.eesztPrescriptionTypeList = await this.getBase(
          this.$enums.BaseName.eeszt_prescription_type.Value,
          this.validDateFilterParams
        );

        this.eesztBNOList = await this.getBase(
          this.$enums.BaseName.eeszt_bno10.Value,
          {
            "-orderby": "Code",
            ["-limit"]: 10,
          }
        );

        /* this.filteredPatientList = this.projectPatient
        ? this.projectPatient.slice(0, 10)
        : null; */

        await this.setSelectedListElement();

        await this.getProjectPatientsData({ "-limit": 100 });
      }
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
    async getProjectPatientsData(params) {
      if (this.projectRoles) {
        const patientRoles = this.projectRoles.filter((r) =>
          r.BaseRoles.includes("Patient")
        );
        //kérés indítás
        var tempPatientList = await Promise.all(
          patientRoles.map(async (r) => {
            const patientListResponse = await UserLogic.getUserData({
              projectId: this.$loggedUser.SelectedProject.ProjectId,
              projectRole: r.RoleName,
              "-orderby": "Username",
              ...params,
            });
            //sikeres kérés?
            if (!patientListResponse.Code) {
              //van projekt adat?
              //this.patientList = this.filteredPatientList = patientListResponse;
              return patientListResponse;
            } else {
              this.$bvToast.toast(patientListResponse.Message, {
                title: this.$t(
                  "requestResponse.project.errorGetProjectSubordinates"
                ),
                variant: "danger",
                solid: true,
                AutoHideDelay: 10000,
              });
            }
          })
        );
        const patientList = tempPatientList.flat();
        if (
          this.prescription.Patient.PatientId &&
          !patientList.find(
            (p) => p.UserId == this.prescription.Patient.PatientId
          )
        ) {
          const selectedPatient = await UserLogic.getUserData({
            userIds: this.prescription.Patient.PatientId,
          });
          if (!selectedPatient.Code) {
            patientList.push(selectedPatient);
          }
        }
        this.filteredPatientList = patientList;
      }
    },
  },
  async mounted() {
    this.setPrescription();
    await this.getLists();
  },
};
</script>
