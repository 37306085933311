export default {
  state: {
    UserId: null,
    Name: null,
    Roles: null,
    Projects: null,
    SelectedProject: null,
    SelectedRole: null,
    Token: null
  },
  mutations: {},
  actions: {},
};
