export default {
  state: {
    SelectedPrescriptions: [], //{"PrescriptionId":17,"FinancedProductUniqueIds":[1]}
    BeverLoginUsername: null,
    BeverLoginPassword: null,
    BeverLoginToken: null,
  },
  mutations: {
    setData(state, payload) {
      state[payload.key] = payload.value;
    },
    addPrescription(state, payload) {
      state.SelectedPrescriptions.push(payload);
    },
    addDispenseProduct(state, payload) {
      const sp = state.SelectedPrescriptions.find(
        (p) => p.PrescriptionId == payload.PrescriptionId
      );
      if (
        sp &&
        !sp.FinancedProductUniqueIds.find(
          (id) => id == payload.FinancedProductUniqueId
        )
      ) {
        sp.FinancedProductUniqueIds.push(payload.FinancedProductUniqueId);
      }
    },
    deletePrescription(state, payload) {
      state.SelectedPrescriptions = state.SelectedPrescriptions.filter(
        (p) => p.PrescriptionId != payload
      );
    },
    deleteDispenseProduct(state, payload) {
      const sp = state.SelectedPrescriptions.find(
        (p) => p.PrescriptionId == payload.PrescriptionId
      );
      if (sp) {
        sp.FinancedProductUniqueIds = sp.FinancedProductUniqueIds.filter(
          (p) => p != payload.FinancedProductUniqueId
        );
      }
    },
  },
  actions: {
    setData: ({ commit }, payload) => {
      commit("setData", payload);
    },
    addPrescription: ({ commit }, payload) => {
      commit("addPrescription", payload);
    },
    addDispenseProduct: ({ commit }, payload) => {
      commit("addDispenseProduct", payload);
    },
    deletePrescription: ({ commit }, payload) => {
      commit("deletePrescription", payload);
    },
    deleteDispenseProduct: ({ commit }, payload) => {
      commit("deleteDispenseProduct", payload);
    },
  },
};
