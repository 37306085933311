<template>
  <div v-if="managerType">
    <fp-new-participant-manager
      @selectOtherParticipant="addNewParticipantToList"
      :baseRoles="$productSettings[managerType].participantsBaseRoles"
      :project="project"
      :managerType="managerType"
    />
    <fp-tag-manager
      v-if="$productSettings[managerType].hasParticipantTag"
      :tagListType="$productSettings[managerType].managedTagType"
      :projectId="project ? project.ProjectId : null"
      defaultNewTagType="PatientTag"
    />
    <fp-input
      v-model="multivalueFilter"
      @change="filterParticipantList"
      :label="$t('base.basic.filter')"
    />
    <fp-participant-list
      :title="listTitle"
      :refreshedList.sync="refreshedList"
      :participantList="participantList"
      @selectedParticipant="selectParticipant"
      :shownData="$productSettings[managerType].participantShownDataKeys"
      :hasTag="$productSettings[managerType].hasParticipantTag"
      :forcedSelectUserId="forcedSelectUserId"
      :managerType="managerType"
    />
  </div>
</template>
<script>
import { ProjectLogic } from "../../Logic/Backend/project";
export default {
  name: "ParticipantManager",
  props: {
    project: Object,
    managerType: String,
    refreshingList: Boolean,
    listTitle: String,
  },
  data() {
    return {
      participantList: null,
      multivalueFilter: null,
      tagFilter: null,
      currentParticipant: null,
      refreshedList: false,
      forcedSelectUserId: null,
    };
  },
  watch: {
    async project(input) {
      if (input) {
        await this.setDefaultLists();
      }
    },
    async refreshingList(input) {
      if (input) {
        await this.filterParticipantList();
        const refreshedParticipant = this.currentParticipant
          ? this.participantList.find(
              (p) => p.UserId == this.currentParticipant.UserId
            )
          : null;
        if (refreshedParticipant) {
          this.$emit("changedSelectedParticipant", refreshedParticipant);
        } else {
          this.$emit("changedSelectedParticipant", this.participantList[0]);
        }
      }
      this.refreshedList = true;
      this.$emit("update:refreshingList", false);
    },
  },
  methods: {
    async addNewParticipantToList(participant) {
      this.forcedSelectUserId = null;
      await this.filterParticipantList();
      this.forcedSelectUserId = participant ? participant.UserId : null;
      console.log("addNewParticipantToList", participant);
      this.refreshedList = true;
    },
    async filterParticipantList() {
      var params = null;
      if (this.multivalueFilter) {
        if (this.multivalueFilter.length > 2) {
          if (!params) {
            params = {};
          }
          params.MultiValue = this.multivalueFilter;
        }
      }
      if (this.tagFilter && this.tagFilter.length) {
        if (!params) {
          params = {};
        }
        params.TagIds = this.tagFilter;
      }
      if (!this.multivalueFilter && !this.tagFilter) {
        await this.setDefaultLists();
      } else if (params) {
        this.participantList = await this.getParticipantList(params);
      } else {
        this.participantList = [];
      }
    },
    async getParticipantList(params) {
      const baseRoles = this.$productSettings[this.managerType]
        .participantsBaseRoles;
      const onlySubordinates =
        this.$productSettings[this.managerType].participantListLoadType ==
        this.$enums.SystemParameters.ParticipantListLoadType.JustRelationship
          .Value;
      const tempList = [];
      await Promise.all(
        baseRoles.map(async (role) => {
          const getResponse = await ProjectLogic.getParticipant(
            this.project.ProjectId,
            {
              BaseRole: role,
              OnlySubordinates: onlySubordinates,
              ...params,
            }
          );
          if (!getResponse.Code) {
            tempList.push(getResponse);
          } else {
            this.$bvToast.toast(getResponse.Message, {
              title:
                "Hiba történt a lista betöltése során! Előfordulhat, hogy a résztvevő lista egy részét nem sikerült betölteni.",
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        })
      );
      const fixedList = this.$checkDuplicates(tempList.flat(), "UserId");
      return fixedList;
    },
    async setDefaultLists() {
      if (this.project) {
        this.participantList = await this.getParticipantList({
          "-orderby": "Name",
          "-limit": 10,
        });
      }
    },
    selectParticipant(participant) {
      this.currentParticipant = participant;
      this.$emit("changedSelectedParticipant", participant);
    },
  },
  async mounted() {
    await this.setDefaultLists();
  },
};
</script>
