<template>
  <div class="eh-client-documents">
    <div>
      <fp-tabs>
        <fp-tab
          id="EHRdocuments"
          :title="$t('client.documents.ehrDocuments')"
          @click="
            isEHRActive = true;
            isPHRActive = false;
            isOtherActive = false;
          "
        >
          <b-container fluid class="eh-client-documents-tab-container">
            <b-row align-v="baseline">
              <b-col cols="12" sm="6">
                <fp-input
                  :label="$t('base.basic.filter')"
                  @change="searchEHRDocumentList"
                />
              </b-col>
              <b-col cols="12" sm="6">
                <document-operation-buttons
                  :disableShare="true"
                  :disableUpload="true"
                  :disableDictateText="true"
                  :EESZTuploaded="EESZTuploaded"
                  :isUploaded="isUploaded"
                  @selectAll="selectAll"
                  @clearSelected="clearSelected"
                  @downloadDocuments="downloadDocuments"
                  @deletedDocuments="deletedDocuments"
                  @eesztSync="eesztSync"
                  @onClose="getDocuments"
                />
              </b-col>
            </b-row>
          </b-container>

          <DocumentsTable
            :disabledCategory="true"
            @onRowSelected="onEHRRowSelected"
            :items="filteredEHRTableList"
            :fields="EHRPHRfields"
            :isSelectAll.sync="isSelectAllEHR"
            :isSelectClear.sync="isSelectClearEHR"
            :refId="'selectableEHRTable'"
            :isUploaded="isUploaded"
          />
        </fp-tab>
        <fp-tab
          :title="$t('client.documents.phrDocuments')"
          @click="
            isEHRActive = false;
            isPHRActive = true;
            isOtherActive = false;
          "
        >
          <b-container fluid class="eh-client-documents-tab-container">
            <b-row align-v="baseline">
              <b-col cols="12" sm="6">
                <fp-input
                  :label="$t('base.basic.filter')"
                  @change="searchPHRDocumentList"
                />
              </b-col>
              <b-col cols="12" sm="6">
                <document-operation-buttons
                  :disableEESZT="true"
                  :disableShare="true"
                  :disableDictateText="true"
                  :isUploaded="isUploaded"
                  @selectAll="selectAll"
                  @clearSelected="clearSelected"
                  @onUploadedDocumentList="onUploadedPHRDocumentList"
                  @downloadDocuments="downloadDocuments"
                  @deletedDocuments="deletedDocuments"
                  @onClose="getDocuments"
                />
              </b-col>
            </b-row>
          </b-container>
          <DocumentsTable
            :disabledCategory="true"
            @onRowSelected="onPHRRowSelected"
            :items="filteredPHRTableList"
            :fields="EHRPHRfields"
            :isSelectAll.sync="isSelectAllPHR"
            :isSelectClear.sync="isSelectClearPHR"
            :refId="'selectablePHRTable'"
            :isUploaded="isUploaded"
          />
        </fp-tab>
        <fp-tab
          :title="$t('client.documents.otherDocuments')"
          @click="
            isEHRActive = false;
            isPHRActive = false;
            isOtherActive = true;
          "
        >
          <b-container fluid class="eh-client-documents-tab-container">
            <b-row align-v="baseline">
              <b-col cols="12" sm="6" class="eh-flex-container">
                <fp-input
                  class="eh-client-documents-filter"
                  :label="$t('base.basic.filter')"
                  v-model="filterOtherDoc"
                  @change="searchOtherDocumentList"
                />
                <fp-select
                  class="eh-client-documents-type-filter"
                  :label="$t('client.documents.documentType')"
                  :items="Object.values($enums.OtherDocumentCategory)"
                  valueKey="Value"
                  v-model="typeFilterOtherDoc"
                  @change="searchOtherDocumentList"
                >
                  <template #element="element">
                    {{ $t(element.Text) }}
                  </template>
                </fp-select>
              </b-col>
              <b-col cols="12" sm="6">
                <document-operation-buttons
                  :disableEESZT="true"
                  :disableShare="true"
                  :disableDictateText="true"
                  :isUploaded="isUploaded"
                  :uploadByCategory="true"
                  @selectAll="selectAll"
                  @clearSelected="clearSelected"
                  @onUploadedDocumentList="onUploadedOtherDocumentList"
                  @downloadDocuments="downloadDocuments"
                  @deletedDocuments="deletedDocuments"
                  @onClose="getDocuments"
                />
              </b-col>
            </b-row>
          </b-container>
          <DocumentsTable
            :disabledCategory="true"
            @onRowSelected="onOtherRowSelected"
            :items="filteredOtherTableList"
            :fields="Otherfields"
            :isSelectAll.sync="isSelectAllOther"
            :isSelectClear.sync="isSelectClearOther"
            :refId="'selectableOtherTable'"
            :isUploaded="isUploaded"
            :hasCategoryFilter="true"
          />
        </fp-tab>
      </fp-tabs>
    </div>
  </div>
</template>
<script>
import { TableUiLogic } from "@/logic/ui/table";
import DocumentsTable from "@/components/table/DocumentsTable";
import DocumentOperationButtons from "@/components/table/DocumentOperationButtons.vue";

export default {
  name: "Documents",
  components: { DocumentsTable, DocumentOperationButtons },
  data() {
    return {
      siteTitle: this.$t("client.menu.documents"),
      tabIndex: 0,
      /* Dokumentum listák */
      PHRTableList: [],
      filteredPHRTableList: [],
      EHRTableList: [],
      filteredEHRTableList: [],
      OtherTableList: [],
      filteredOtherTableList: [],
      filterOtherDoc: null,
      typeFilterOtherDoc: null,
      /* Kiválasztott dokumentum listák */
      EHRSelectedItems: [],
      PHRSelectedItems: [],
      OtherSelectedItems: [],
      /* Teljes lista kijelölés */
      isSelectAllOther: null,
      isSelectClearOther: false,
      isSelectAllEHR: null,
      isSelectClearEHR: false,
      isSelectAllPHR: null,
      isSelectClearPHR: false,
      /* Aktív dokumentum típus */
      isEHRActive: true,
      isPHRActive: false,
      isOtherActive: false,
      /* Dokumentum feltöltés */
      isUploaded: true,
      uploadedDocumentList: null,
      EESZTuploaded: true,
      /* Táblázat oszlopok */
      EHRPHRfields: [
        {
          key: "Selected",
          label: this.$t("base.basic.select"),
        },
        {
          key: "DocumentName",
          label: this.$t("components.table.name"),
          /* sortable: true, */
        },
        {
          key: "FileUploadedAt",
          label: this.$t("components.table.uploadDate"),
          /* sortable: true, */
        },
        {
          key: "FileType",
          label: this.$t("components.table.format"),
          /* sortable: true, */
        },
      ],
      Otherfields: [
        {
          key: "Selected",
          label: this.$t("base.basic.select"),
        },
        {
          key: "DocumentName",
          label: this.$t("components.table.name"),
          /* sortable: true, */
        },
        {
          key: "FileUploadedAt",
          label: this.$t("components.table.uploadDate"),
          /* sortable: true, */
        },
        {
          key: "FileType",
          label: this.$t("components.table.format"),
          /* sortable: true, */
        },
        { key: "Category", label: this.$t("components.table.type") },
      ],
    };
  },
  methods: {
    async onUploadedOtherDocumentList(list, category) {
      this.uploadedDocumentList = list;
      this.isUploaded = false;
      const response = await TableUiLogic.uploadDocuments(
        category,
        this.uploadedDocumentList
      );
      if (response) {
        response.forEach((res) => {
          if (!res.Code || res.Code == 0) {
            this.$bvToast.toast(
              this.$t("requestResponse.document.successUpload"),
              {
                title: this.$t("base.upload"),
                variant: "success",
                solid: true,
              }
            );
          } else {
            //TODO: errorUploadByName (name:filename)
            this.$bvToast.toast(res.Message, {
              title: this.$t("requestResponse.document.errorUpload"),
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        });
      }
      this.isUploaded = true;
      await this.getDocuments();
    },
    async onUploadedPHRDocumentList(list) {
      this.uploadedDocumentList = list;
      this.isUploaded = false;
      const response = await TableUiLogic.uploadDocuments(
        this.$enums.DocumentCategory.Phr.Value,
        this.uploadedDocumentList
      );
      //TODO: response nem lista?
      if (!response.Code || response.Code == 0) {
        this.$bvToast.toast(this.$t("requestResponse.document.successUpload"), {
          title: this.$t("base.upload"),
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(response.Message, {
          title: this.$t("requestResponse.document.errorUpload"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      this.isUploaded = true;
      await this.getDocuments();
    },
    selectAll() {
      if (this.isEHRActive) {
        this.isSelectAllEHR = true;
      } else if (this.isPHRActive) {
        this.isSelectAllPHR = true;
      } else if (this.isOtherActive) {
        this.isSelectAllOther = true;
      }
    },
    clearSelected() {
      if (this.isEHRActive) {
        this.isSelectClearEHR = true;
      } else if (this.isPHRActive) {
        this.isSelectClearPHR = true;
      } else if (this.isOtherActive) {
        this.isSelectClearOther = true;
      }
    },
    onPHRRowSelected(items) {
      this.PHRSelectedItems = items;
    },
    onEHRRowSelected(items) {
      this.EHRSelectedItems = items;
    },
    onOtherRowSelected(items) {
      this.OtherSelectedItems = items;
    },
    async downloadDocuments() {
      var response = null;
      if (this.isEHRActive) {
        response = await TableUiLogic.downloadSelectedDocuments(
          this.EHRSelectedItems
        );
      } else if (this.isPHRActive) {
        response = await TableUiLogic.downloadSelectedDocuments(
          this.PHRSelectedItems
        );
      } else if (this.isOtherActive) {
        response = await TableUiLogic.downloadSelectedDocuments(
          this.OtherSelectedItems
        );
      }
      if (response) {
        //TODO errorDownloadByName (name:filename)
        response.forEach((res) => {
          if (res.Code != 0) {
            this.$bvToast.toast(res.Message, {
              title: this.$t("requestResponse.document.errorDownload"),
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        });
      }
    },
    async deletedDocuments() {
      var response = null;
      if (this.isEHRActive) {
        //this.deleteSelectedDocuments(this.EHRSelectedItems);
        response = await TableUiLogic.deleteSelectedDocuments(
          this.EHRSelectedItems
        );
        this.isSelectAllEHR = false;
      } else if (this.isPHRActive) {
        //this.deleteSelectedDocuments(this.PHRSelectedItems);
        response = await TableUiLogic.deleteSelectedDocuments(
          this.PHRSelectedItems
        );
        this.isSelectAllPHR = false;
      } else if (this.isOtherActive) {
        //this.deleteSelectedDocuments(this.OtherSelectedItems);
        response = await TableUiLogic.deleteSelectedDocuments(
          this.OtherSelectedItems
        );
        this.isSelectAllOther = false;
      }
      if (response) {
        //TODO: errorDeleteByName (name:filename)
        response.forEach((res) => {
          if (res.Code == 0) {
            this.$bvToast.toast(
              this.$t("requestResponse.document.successDelete"),
              {
                title: this.$t("base.basic.delete"),
                variant: "info",
                solid: true,
              }
            );
          } else {
            this.$bvToast.toast(res.Message, {
              title: this.$t("requestResponse.document.errorDelete"),
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        });
      }
      await this.getDocuments();
    },
    async eesztSync() {
      this.EESZTuploaded = false;
      const response = await TableUiLogic.eesztSync();
      if (!response.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.document.successEESZTsync", {
            count: response.DocumentCount,
          }),
          {
            title: this.$t("base.basic.sync"),
            variant: "success",
            solid: true,
          }
        );
        this.isSelectAllEHR = false;
      } else {
        this.$bvToast.toast(response.Message, {
          title: this.$t("requestResponse.document.errorEESZTsync"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      await this.getDocuments();
      this.EESZTuploaded = true;
    },
    async getDocuments() {
      this.OtherTableList = [];
      this.PHRTableList = [];
      this.EHRTableList = [];
      const response = await TableUiLogic.getDocuments();
      if (!response.Code) {
        this.PHRTableList = this.filteredPHRTableList = response.filter(
          (phr) => phr.Category == this.$enums.DocumentCategory.Phr.Value
        );
        this.EHRTableList = this.filteredEHRTableList = response.filter(
          (ehr) => ehr.Category == this.$enums.DocumentCategory.Ehr.Value
        );
        this.OtherTableList = this.filteredOtherTableList = response.filter(
          (other) =>
            Object.keys(this.$enums.OtherDocumentCategory).includes(
              other.Category
            )
        );
      } else {
        this.$bvToast.toast(response.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("client.documents.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    searchEHRDocumentList(input) {
      this.filteredEHRTableList = this.$filterList(input, this.EHRTableList, [
        "FileName",
        "DocumentName",
        "FileUploadedAt",
      ]);
    },
    searchPHRDocumentList(input) {
      this.filteredPHRTableList = this.$filterList(input, this.PHRTableList, [
        "FileName",
        "DocumentName",
        "FileUploadedAt",
      ]);
    },
    searchOtherDocumentList() {
      this.filteredOtherTableList = this.$filterList(
        this.filterOtherDoc,
        this.OtherTableList,
        ["FileName", "DocumentName", "FileUploadedAt"]
      );
      if (this.typeFilterOtherDoc) {
        this.filteredOtherTableList = this.filteredOtherTableList.filter(
          (doc) => doc.Category == this.typeFilterOtherDoc
        );
      }
    },
  },
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",this.siteTitle);
    await this.getDocuments();
  },
};
</script>
