import PrescriptionList from "./Lists/PrescriptionList.vue";
import PrescriptionComponents from "./Components";

export default {
  install(Vue) {
    //Modul aktivitás beállítása
    if (!Vue.prototype.$activeModules) {
      Vue.prototype.$activeModules = [];
    }
    Vue.prototype.$activeModules.push("Prescription");

    //Modul komponensek regisztrálása
    Vue.component("fp-prescription-list", PrescriptionList);

    Vue.use(PrescriptionComponents);
  },
};
