<template>
  <b-row class="site-body-box" align-h="center">
    <div class="site-body">
      <label class="title">Honlaptérkép</label>
      <b-row id="sitemap-box" cols="1">
        <router-link to="/">Főoldal</router-link>
        <router-link to="/DPC">Dél-Pesti Centrumkórház</router-link>
        <router-link to="/About">Bemutatkozás</router-link>
        <!-- <router-link to="/TMKK">Transzlációs Medicina Kutató Központ</router-link> -->
        <label>Pályázatok</label>
        <b-row class="submenu-box" cols="1">
          <router-link to="/ApplicationsActive">Aktív pályázatok</router-link>
          <router-link to="/ApplicationsClosed">Lezárt pályázatok</router-link>
          <!-- <router-link to="/ApplicationsOpened">Nyitott pályázatok - pályázati felhívások</router-link> -->
        </b-row>
        <label>Kutatások</label>
        <b-row class="submenu-box" cols="1">
          <!-- <router-link to="/ResearchReports">Kutatási jelentések</router-link>
          <router-link to="/ResearchKnowledge">Tudásbázis</router-link> -->
          <router-link to="/ResearchPartners">Kutatási partnereink</router-link>
        </b-row>
        <router-link to="/News">Híreink</router-link>
        <!-- <router-link to="/DataProtection">Adatvédelem</router-link> -->
        <label style="margin-bottom: -1px; color: black">Honlaptérkép</label>
        <!-- <router-link to="/Impress">Impresszum</router-link> -->
      </b-row>
    </div>
  </b-row>
</template>
<script>
export default {
  name: "SiteMap",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
#sitemap-box {
  font-size: larger;
}
.submenu-box {
  margin-left: 40px;
}
@media (max-width: 400px) {
  #sitemap-box {
    margin-left: 10px;
    font-size: larger;
  }
  .submenu-box {
    margin-left: 40px;
  }
}
</style>