<template>
  <div>
    <div v-if="taskList">
      <task-table
          :taskList="taskList"
          :taskListFields="taskListFields"
          :hasFilter="true"
      />
    </div>
  </div>
</template>

<script>
import TaskTable from "@/components/table/TaskTable.vue";

import { UserTaskLogic } from "@/logic/backend/user-task";

export default {
  name: "Tasks",
  components: {
    TaskTable
  },
  data() {
    return {
      taskList: [],
      taskListFields: [
        {
          key: "Status",
          label: this.$t("partner.tasks.status"),
          sortable: true,
        },
        {
          key: "Type",
          label: this.$t("partner.tasks.type") },
        {
          key: "StartTime",
          label: this.$t("partner.tasks.startDate"),
          sortable: true,
        },
        {
          key: "Deadline",
          label: this.$t("partner.tasks.deadline"),
          sortable: true,
        },
        {
          key: "Priority",
          label: this.$t("partner.tasks.priority"),
          sortable: true,
        },
        {
          key: "Title",
          label: this.$t("partner.tasks.task") },
        {
          key: "Description",
          label: this.$t("partner.tasks.description"),
        },
      ]
    };
  },
  methods: {
    async getTasks() {
      const getTasksResponse = await UserTaskLogic.getTasks({
        UserId: this.$loggedUser.UserId,
      });
      if (!getTasksResponse.Code) {
        this.taskList = getTasksResponse;
      } else {
        this.$bvToast.toast(getTasksResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getTasks();
    this.$store.dispatch(
      "setPageTitle",this.$t("partner.tasks.title"));
  }
}
</script>

<style scoped>

</style>