<template>
  <div class="eh-partner-project">
    <div>
      <div v-if="project">
        <fp-tabs :tabs="projectCardComponents">
          <template #title="Component">{{ Component.Title }}</template>
          <template #content="Component">
            <component
              :is="Component.ComponentName"
              :createdProjectId="project.ProjectId"
              :project.sync="project"
              :participantList.sync="project.Participants"
              :roleList.sync="project.Roles"
              :items.sync="project.Items"
              :isCreated="true"
              @getList="getSelectedProject"
              :roleReadonly="true"
            />
          </template>
        </fp-tabs>
      </div>
      <div v-else>{{ $t("partner.projects.dontProject") }}</div>
    </div>
  </div>
</template>
<script>
import { ProjectLogic } from "@/logic/backend/project";
import { ProjectUiLogic } from "@/logic/ui/project";
/* components */
import DataSheet from "@/components/project-components/DataSheet";
import Roles from "@/components/project-components/Roles";
import Participants from "@/components/project-components/Participants";
import ProjectItems from "@/components/project-components/ProjectItems";
import ParticipantProgress from "../../../components/project-components/ParticipantProgress.vue";
//TODO: style szerkesztés
/* import Style from "@/components/project-components/Style"; */

export default {
  name: "WebAppPartnerProjects",
  components: {
    DataSheet,
    Roles,
    Participants,
    ProjectItems,
    ParticipantProgress,
    /* Style */
  },
  data() {
    return {
      projectCardComponents: [
        {
          ComponentName: "DataSheet",
          Title: this.$t("partner.projects.dataSheet"),
        },
        {
          ComponentName: "Roles",
          Title: this.$t("partner.projects.roles"),
        },
        /* {
          ComponentName: "ProjectItems",
          Title: "Elemek",
        }, */
        {
          ComponentName: "Participants",
          Title: this.$t("partner.projects.participants"),
        },
        {
          ComponentName: "ParticipantProgress",
          Title: "Projekt előrehaladás",
        },
        /* {
          ComponentName: "Style",
          Title: 'Design stílus',
        }, */
      ],
      project: null,
    };
  },
  methods: {
    exportProject() {
      ProjectUiLogic.exportProject(this.project);
    },
    //TODO: majd lesz jogsultság kezelés, amikor a projekthez csak a saját subordinates participantok láthatóak (nem kell erre is szűrni)
    /*getSpecialists() {
      /!* const userParticipant = this.project.Participants.find(
        (participant) => participant.UserId == this.$loggedUser.UserId
      );
      if (userParticipant) { *!/
      //const subordinates = userParticipant.SubordinateIds;
      const specialists = [];
      let patients = [];
      const specialistRoles = this.project.Roles.filter((role) =>
          role.BaseRoles.includes("Doctor")
      );
      const patientRoles = this.project.Roles.filter((role) =>
          role.BaseRoles.includes("Patient")
      );
      this.project.Participants.forEach((participant) => {
        if (
            //a user alatti jogosultságú id között megtalálható a résztvevő?
            //subordinates.includes(participant.UserId) &&
            //a résztvevő patient szerepkörű?
            // TODO: Lowercase is needed because under BaseRoles the string is capitalized
            specialistRoles.find(
                (role) => role.RoleName === participant.RoleName.toLowerCase()
            )
        ) {
          specialists.push(participant);
        } else if (
            patientRoles.find(
                (role) => role.RoleName === participant.RoleName.toLowerCase()
            )
        ) {
          patients.push(participant);
        }
      });
      this.patients = patients;
      return specialists;
      /!* }
      return null; *!/
    },*/
    async getSelectedProject() {
      const getSelectedProjectResponse = await ProjectLogic.getProjectDetails({
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      if (!getSelectedProjectResponse.Code) {
        this.project = getSelectedProjectResponse[0];
        //this.specialists = this.getSpecialists();
      } else {
        this.$bvToast.toast(getSelectedProjectResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    /*async saveRoles(newProject) {
      const setProjectResponse = await ProjectLogic.setProject(
          newProject,
          this.project.ProjectId
      );
      if (!setProjectResponse.Code) {
        {
          await this.getSelectedProject();
          this.$bvToast.toast(
              "Sikeresen módosította a projekt szerepőköröket",
              {
                title: "Mentés",
                variant: "success",
                solid: true,
              }
          );
        }
      } else {
        this.$bvToast.toast(setProjectResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async saveData(newProject) {
      const setProjectResponse = await ProjectLogic.setProject(
          newProject,
          this.project.ProjectId
      );
      if (!setProjectResponse.Code) {
        {
          await this.getSelectedProject();
          this.$bvToast.toast("Sikeresen módosította a projekt adatlapot", {
            title: "Mentés",
            variant: "success",
            solid: true,
          });
        }
      } else {
        this.$bvToast.toast(setProjectResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async saveParticipants(newParticipantList) {
      let createParticipants = [];
      let deleteParticipants = [];
      for (const existingParticipant of this.project.Participants) {
        const foundNew = newParticipantList.find(
            (newParticipant) =>
                newParticipant.UserId === existingParticipant.UserId
        );
        if (foundNew === undefined) {
          deleteParticipants.push(existingParticipant);
        }
      }
      for (const newParticipant of newParticipantList) {
        const foundExisting = this.project.Participants.find(
            (projectParticipant) =>
                projectParticipant.UserId === newParticipant.UserId
        );
        if (foundExisting === undefined) {
          createParticipants.push(newParticipant);
        }
      }
      let deleteError = false;
      for (const participant of deleteParticipants) {
        const deleteParticipantResponse = await ProjectLogic.deleteParticipantFromProject(
            this.project.ProjectId,
            participant.UserId
        );
        if (deleteParticipantResponse.Code) {
          deleteError = true;
          this.$bvToast.toast(deleteParticipantResponse.Message, {
            title: "Hiba",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
      let addError = false;
      const addParticipantResponse = await ProjectLogic.addParticipantToProject(
          createParticipants,
          this.project.ProjectId
      );
      if (addParticipantResponse.Code) {
        addError = true;
        this.$bvToast.toast(addParticipantResponse.Message, {
          title: "Hiba",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      if (!(addError || deleteError)) {
        await this.getSelectedProject();
        this.$bvToast.toast("Sikeresen módosította a projekt résztvevőit", {
          title: "Mentés",
          variant: "success",
          solid: true,
        });
      }
    },*/
  },
  //betöltéskor lefut egyszer
  async mounted() {
    await this.getSelectedProject();
    //oldal cím beállítása
    this.$store.dispatch(
      "setPageTitle",this.$t("partner.projects.title"));
  },
};
</script>
<style scoped>
#new-fp-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}

#new-fp-modal .modal-content header {
  padding: 0px;
}
</style>
