<template>
  <b-row class="site-body-box" align-h="center">
    <div class="site-body">
      <label class="title">Kutatási partnereink</label>
      <!-- EHSS -->
      <div>
        <h4>eHealth Software Solutions Kft.</h4>
        <p>
          Az eHealth Software Solutions Kft.-t az egyik legdinamikusabban
          fejlődő iparágban az e-egészségügyben végez tanácsadási,
          kutatás-fejlesztési és szoftverfejlesztési tevékenységet. A cég több
          nagyobb értékű egyedi egészségügyi szoftvert fejlesztett versenyszféra
          megrendelésére, emellett az Állami Egészségügyi Ellátó Központ által
          Svájci Magyar Hozzájárulás keretében Alapellátási modellprogramban
          alapellátási szoftverfejlesztés tárgyában 2015-ben kiírt
          közbeszerzésen konzorciumvezetőként sikeresen nyert és a
          szoftverterméket 2016-ban leszállította a megrendelőnek. 2017-ben a
          cég a Dél-pesti Centrumkórház NVKP_16-1-2016-0005, “Kiemelkedő
          halálozási kockázattal járó betegségek gyógyításának eredményességét
          lényegesen javító nemzeti program: Nemzeti innovációs onkogenomikai és
          precíziós onkoterápiás program elindítása és kapcsolódó technológiák
          integrált fejlesztése” kutatás-fejlesztési pályázata keretén belül
          kifejlesztette az OncoGenomic onko-hematológiai kutatási-, eset-, és
          betegútmenedzsment rendszert, melynek K+F továbbfejlesztése és
          országos kiterjesztése jelenleg is zajlik.
        </p>
        <p>
          Referenciái körébe tartozik az Országos Hematológiai és Infektológiai
          Intézet által megrendelt országos Nemzeti Hematológiai Betegségek
          Regiszterének és Nemzeti Infektológiai Betegségek Regiszterének a
          fejlesztése, mely 94 hazai kórházból gyűjti a regiszterhez szükséges
          adatokat. A cég az Elektronikus Egészségügyi Szolgáltató Tér (EESZT)
          és a Nemzeti Egészségbiztosítási Alapkezelő (NEAK) akkreditált
          fejlesztő partnere.
        </p>
        <p>
          A vállalkozás több éve fejleszti saját integrált medikai rendszerét,
          melyet 2021-ben ClinCoreSys és Fókusz Program néven hozott forgalomba.
          A rendszereket és egyes moduljait a cég SaaS, PaaS, IaaS modellben
          értkesíti. Alkalmazásfejlesztés területen több multinacionális cég
          magyarországi leányvállalatának fejlesztenek egészségügyi
          rendszereket. Stratégiai, fejlesztéspolitikai és IT szaktanácsadási
          feladatokat lát el 2019-től alvállalkozóként az ÁEEK és jogutódja, az
          Országos Kórházi Főigazgatóság és az Egészséginformatikai Szolgáltató
          és Fejlesztő Központ számára. Részt vett a Nemzeti
          Egészséginformatikai Tanács (NEIT) felkérésére a magyar
          Egészséginformatikai (E-Health) Stratégia kialakításában, a magyar
          Nemzeti Digitalizációs Stratégia (NDS) egészségügyi részének
          megírásában, valamint az Egészséges Budapest Program Smart Hospital
          koncepciójának kidolgozásában.
        </p>
      </div>
      <div style="margin-top: 30px">
        <h4>Healthware Tanácsadó Kft.</h4>
        <p>
          A Healthware Tanácsadó Kft. 2004-ben alakult egészségbiztosítási
          tapasztalatokkal rendelkező orvosok, gyógyszerészek, közgazdászok és
          informatikusok közreműködésével. A cég több mint 15 éve tevékenykedik
          az egészség-finanszírozással és gazdaságtanával foglalkozó tanácsadás
          területén, mára a szakterület meghatározó hazai szereplőjévé vált. A
          cég mind az egyedi egészségügyi problémafelvetések, mind az
          egészségügyi részrendszerekhez kapcsolható feladatok és megoldások
          tervezési, elemzési és végrehajtási szakaszai kapcsán nagy
          tapasztalattal rendelkezik, különösen amennyiben azok kiterjedt és
          több célú adatfeldolgozást igényelnek.
        </p>
        <p>
          A Healthware ügyfélköre az egészségügyi ágazat szereplőinek többségét
          lefedi, de döntően gyógyszercégek, biotechnológiai vállalatok, gyártói
          szövetségek, szakmai kollégiumok, regiszterek, országos
          gyógyintézetek, betegszervezetek, valamint állami Nemzeti
          Egészségbiztosítási Alapkezelő (NEAK) alkotják. A cég legalább 17
          nemzetközi kutatásban vett részt, ami alapján közel 70 publikáció
          született. Az ún. ’Real World Evidencia’ alapú feldolgozásokat,
          általában gyógyszeripari fejlesztések használják fel II., III. fázisú
          klinikai vizsgálatok eredményeinek kiegészítésére az EMA, centrális
          törzskönyvezési eljárások eredményességi, vagy befogadást követő
          terápia biztonságossági adatgyűjtések és elemzések céljából (EMA-PAES,
          EMA-PASS). A Healthware Kft. munkatársai több kiemelt, uniós forrásból
          finanszírozott állami projektben (Állami Egészségügyi Ellátó Központ:
          TÁMOP 6.2.5, TÁMOP 6.2.3.) is részt vettek elemzőként, tanácsadóként,
          illetve fejlesztőként.
        </p>

        <p>
          A Healthware szoftveres megoldásai az ismeretek feltárására,
          rendszerezésére és az azon alapuló döntések hatásainak modellezésére
          szolgálnak. Az elemző-értékelő funkciók fejlesztésének alapvetései
          voltak, hogy azok megfelelő interfészen keresztül aktív kiegészítői
          lehessenek az egyes népegészségügyi és egészségügyi információs
          rendszereknek és regisztereknek. Bevezetett szoftveres megoldásaink
          alapja a saját fejlesztésű Medalyse IT keretrendszer dinamikusan
          konfigurálható adatbányász-, űrlap és riport komponenseivel. Az
          informatikai keretrendszer segítségével több szintű
          menedzserinformációs (MIS), döntéstámogató (DSS) és szakértői rendszer
          (ES) alakítható ki, az Oracle DB/Java/Eclipse IDE alapú felületi
          elemeket adott célra PL/SQL és RStudio kódban fejlesztett üzleti
          intelligencia réteg egészíti ki.
        </p>

        <p>A fentiek felhasználásával bevezetett alkalmazások:</p>
        <ul>
          <li>
            több forrású (IMS, OEP, klinikai, céges) adatokat kezelő, területi
            felhasználás és piaci aktivitást elemző kimutatások, Web-alapú
            információs rendszerek gyógyszergyártók részére;
          </li>
          <li>
            az egészségügyi ágazat indikátorfejlesztési ügyvitelét támogató
            elemző-értékelő monitoring alkalmazás az Állami Egészségügyi Ellátó
            Központ (AEEK) részére;
          </li>
          <li>
            egészségügyi intervenciók populációs modellezését támogató
            informatikai szakértői rendszer fejlesztése az AEEK részére;
          </li>
          <li>
            központi adatszolgáltatásokat támogató projekt-, illetve adatkezelő
            és elemző eszköz bevezetése az Nemzeti Egészségbiztosítási
            Alapkezelő (NEAK) közös adatfeldolgozások elvégzéséhez;
          </li>
        </ul>
        <p>
          Interaktív kutatási és elemzési web riportok kialakítása a Nemzeti
          Hematológiai Betegségek Regiszter, Nemzeti Rákregiszter, Országos
          Reumatológiai és Fizioterápiás Intézet részére.
        </p>
      </div>
      <div style="margin-top: 30px">
        <h4>Oncompass Medicine (OCM)</h4>
        <div>
          <p>
            Az OCM egy hazai orvosi vállalkozás, amely daganatos betegek
            személyre szabott célzott terápiás kezeléséhez szükséges
            diagnosztikai és döntéstámogató orvosi eszközök fejlesztésével
            foglalkozik. Az OCM-et 2003-ban az alapító kutatóorvosok, külföldről
            hazatérve azzal a hitvallással alapították, hogy a betegeknek joguk
            van minél hamarabb hozzáférni a nemzetközi és hazai orvosbiológiai
            kutatások eredményeihez. AZ OCM kutatói már 2003-ban, elsők között
            számoltak be az első sikeres célzott kezelési lehetőségről
            tüdődaganatos betegek kezelésében, itt Magyarországon. 2010-ben
            jelent meg összefoglaló közleményük a Nature Reviews tudományos
            folyóiratban a személyre szabott terápiák használatához szükséges
            diagnosztikai vizsgálatokról. Az OCM vezetője, Dr. Peták István
            2012-ben munkásságáért Gábor Dénes Díj-ban részesült. Az Oncompass
            kutatói ez után a molekuláris diagnosztikai vizsgálatok
            eredményeinek kiértékeléséhez, a hatékony terápia kiválasztásához
            szükséges informatikai eszközök kifejlesztésére fókuszált. A
            döntéstámogató eszközük első verzióját a Szilícium-völgyben mutatták
            be 2016-ban személyre szabott orvoslás világkonferenciáján. A cég
            által 2019-re teljesen kifejlesztett orvosi eljárás, az OncompassTM
            precíziós onkológiai döntéstámogató program magában foglalja a
            szükséges diagnosztikai vizsgálatokat és a döntéstámogató
            informatikai módszereket, amelyek segítségével az orvos több mint
            100 forgalomban lévő és több mint 1000 fejlesztés alatt álló
            hatóanyagból képes kiválasztani a megfelelő célzott kezelést minden
            egyes daganatos beteg számára a beteg daganatában lévő egyedi
            genetikai elváltozások alapján. Az eljárás lényege az OCM által
            kifejlesztett speciális orvosbiológiai módszer, és annak gyakorlati
            alkalmazását lehetővé tevő orvosi szoftver, a mesterséges
            intelligencia egy formája, amely több mint 20 ezer tudományos
            bizonyítékon alapuló szabályrendszer és egyedülálló matematikai
            algoritmus segítségével objektív és reprodukálható módon rangsorolja
            a beteg daganatában lévő genetikai elváltozásokat és az azokkal
            kapcsolatban álló terápiás lehetőségeket. Magyarországon eddig 26
            onkológiai centrum onkoteamje javasolta az OncompassTM eljárás
            alkalmazását ami évente több mint 1000 beteg számára nyújt
            segítséget. A cég gazdaságilag dinamikusan fejlődik, már közel 50
            munkavállalóval rendelkezik, ezek több mint fele biológus, orvos,
            bioinformatikus, informatikus. Az OCM évek óta képviseli
            Magyarországot az amerikai és európai onkológiai konferenciákon és
            technológiai kiállításokon. A számtalan nemzetközi elismerés mellett
            2019.10.11-én az Oncompass döntéstámogató rendszer az ASCO (American
            Society of Clinical Oncology) Bangkokban megrendezett, a mesterséges
            intelligencia onkológiai felhasználásával foglalkozó “Breakthrough
            Innovations in Oncology” nemzetközi konferenciájának legjobb
            absztrakt díját kapta.
          </p>

          <p>
            2020. május 29-én került megrendezésre az ASCO 2020-as éves
            konferenciája. Ezen a konferencián került bemutatásra a szakmai
            programban az a klinikai kutatási eredmény, amely bizonyítja, hogy
            az Oncompass Medicine mesterséges intelligenciát alkalmazó orvosi
            szoftvere nem csak megkönnyíti az orvos munkáját, hanem mint új
            orvosi módszer, digitális terápia javítja is a daganatos betegek
            kezelésének hatékonyságát. Ezt a nemzetközi tudományos áttörést a
            magyar kutatók a Dél-Pesti Centrumkórház Országos Hematológiai és
            Infektológiai Intézet részéről Prof. Dr. Vályi Nagy István
            főigazgatóval és a párizsi Marie Curie Intézet kutatóival közösen
            mutatták be a világ legmeghatározóbb onkológiai konferenciáján.
            Ezzel az Oncompass elsőként fejlesztett ki olyan mesterséges
            intelligencián alapuló orvosi eszközt, ami a daganatos betegek
            személyre szabott kezeléséhez szükséges terápiás döntést
            bizonyítottan javítani tudja.
          </p>
          <p>Referenciák:</p>
          <ol>
            <li>
              Az elmúlt 3 évben több mint 100 onkológus kért összesen több mint
              1000 beteg számára egyedi méltányossági eljárás keretében NEAK
              finanszírozott Oncompass-t.
            </li>
            <li>
              Az amerikai Forbes a mesterséges intelligencia (MI) 5
              legígéretesebb onkológiai felhasználási lehetőségei között
              említette az Oncompass Medicine megoldását.
            </li>
            <li>
              A cég alapítói és munkatársai a személyre szabott orvoslás
              területén végzett kutatásaikat folyamatosan nemzetközi tudományos
              folyóiratokban publikálják (többek között Journal of Clinical
              Oncology, Nature Reviews Drug Discovery), és nemzetközi tudományos
              konferenciákon mutatják be.
            </li>
            <li>
              2019.11.07-én az OCM nyerte meg a visegrádi országok GET IN THE
              RING V4! innovációs és start-up versenyét.
            </li>
            <li>
              2019.10.11-én az Oncompass döntéstámogató rendszere az ASCO
              (American Society of Clinical Oncology) Bangkokban megrendezett, a
              mesterséges intelligencia onkológiai felhasználásával foglalkozó
              "Breakthrough Innovations in Oncology" nemzetközi konferenciájának
              legjobb absztrakt díját kapta.
            </li>
            <li>
              2019.06.03-án az OCM lehetőséget kapott (6000 jelentkező közül),
              hogy Magyarországot képviselhesse a Global Entrepreneurship
              Summit-on Hágában.
            </li>
            <li>
              Az Oncompass Medicine magyar rákkutatói által kifejlesztett
              onkológiai szoftverrendszer nyerte a Magyar Innovációs Alapítvány
              2019. évi Informatikai Innovációs Díját.
            </li>
            <li>
              2020.05.29-én az Oncompass kutatói a Dél-Pesti Centrumkórház
              Országos Hematológiai és Infektológiai Intézet és a párizsi Marie
              Curie Intézet kutatóival közösen mutatták be az Oncompass által
              kifejlesztett digitális terápia terápiás hatékonyságát bizonyító
              tudományos eredményeket.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </b-row>
</template>
<script>
export default {
  name: "ResearchPartners",
  data() {
    return {
      paragraphs: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ultrices condimentum libero a mollis. Nunc tincidunt condimentum tellus mollis tincidunt. Nulla quam nulla, finibus et arcu id, laoreet volutpat purus. Aenean volutpat pellentesque facilisis. Cras posuere urna id diam scelerisque, tincidunt elementum tortor sodales. Etiam ut neque eget velit lobortis congue quis sed lacus. Donec vel arcu id dui lobortis aliquam et vel metus. Pellentesque iaculis erat est. Sed vitae eros sit amet magna porttitor accumsan sit amet mattis mi. Sed nec congue eros.",
        "Quisque eu feugiat erat. Morbi bibendum massa sit amet diam maximus posuere. Proin condimentum faucibus euismod. Morbi nunc elit, porttitor sit amet arcu at, volutpat placerat dui. Aenean porttitor, mauris a condimentum finibus, lacus quam ultrices erat, egestas malesuada felis metus non leo. Aenean porttitor lacinia diam id mattis. Proin scelerisque nulla sed risus finibus, sed tempor tortor congue. Nunc ut orci posuere est aliquam dapibus eget nec tortor. Nunc gravida tempor ex, vitae rutrum quam consequat finibus. Nulla non massa sit amet leo porta volutpat a et metus.",
        "Pellentesque malesuada, mauris ornare porttitor fermentum, ante nisl blandit libero, ac imperdiet urna massa sed eros. Proin nec metus posuere, condimentum justo eu, convallis tellus. Morbi efficitur lorem eu felis ullamcorper, non vehicula nibh efficitur. Sed sagittis ante molestie arcu sollicitudin, lacinia pretium dolor euismod. Donec lacinia justo massa, vel ornare sem vehicula sit amet. In eget odio vel dui accumsan luctus. Praesent mattis mauris quis velit semper viverra.",
        "Nulla id mi dolor. Sed sed odio arcu. Nunc vestibulum, ex at ultrices efficitur, elit ante maximus tellus, a pulvinar tellus erat et justo. Vivamus bibendum commodo lorem ut accumsan. Phasellus pharetra semper orci eget mattis. Vestibulum pellentesque eros felis, sit amet suscipit quam dignissim sed. Donec vulputate ac augue id luctus. Praesent sit amet convallis leo, eu aliquet libero.",
        "Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Morbi urna ante, lacinia in vehicula et, ornare vitae justo. Vivamus quis aliquet ligula, at dignissim lorem. Mauris porta, arcu vitae tincidunt finibus, odio leo tempus augue, vitae porttitor dolor leo vitae augue. Nulla tempus tincidunt urna ac facilisis. Aliquam non pulvinar lorem. Ut placerat ex sit amet neque dictum elementum. Fusce vitae lectus quam. Integer fringilla commodo dui, vel dapibus metus facilisis quis. Proin sodales congue mauris eget ullamcorper. Maecenas sit amet ligula sit amet ligula bibendum dignissim. Sed a fringilla lorem. Sed venenatis est vitae felis eleifend rutrum. Praesent dignissim egestas nibh non vestibulum. In malesuada tempus diam, sed tincidunt augue cursus ut. Praesent quis placerat nulla.",
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.parag {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
