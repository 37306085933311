var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.finance)?_c('fp-form-modal',{attrs:{"size":"xl","title":_vm.editable ? 'Finanszírozás szerkesztése' : 'Finanszírozás megtekintése',"closable":!_vm.editable},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('fp-select',{attrs:{"label":"Sűrgősségi recept felírás (CITO)?","required":true,"items":_vm.threeStateList,"valueKey":"Value","textKey":"Text","disabled":!_vm.editable,"state":!_vm.validation.hasError('finance.ExpressPrescription'),"error":_vm.validation.firstError('finance.ExpressPrescription')},on:{"change":function($event){return _vm.changeValue('ExpressPrescription', $event)}},model:{value:(_vm.finance.ExpressPrescription),callback:function ($$v) {_vm.$set(_vm.finance, "ExpressPrescription", $$v)},expression:"finance.ExpressPrescription"}}),_c('fp-panel-card',{staticStyle:{"margin-top":"10px"},attrs:{"title":"Felíró orvos főbb adatai","required":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-row',{attrs:{"cols":"1","cols-md":"2"}},[_c('b-col',[_c('div',[_c('fp-input',{attrs:{"disabled":!_vm.editable ||
                  (_vm.prescribingDoctor &&
                    ((_vm.prescribingDoctor.StampNumber &&
                      (_vm.prescribingDoctor.StampNumber.length == 5 ||
                        _vm.prescribingDoctor.StampNumber.length == 7 ||
                        _vm.prescribingDoctor.StampNumber.length == 8)) ||
                      (_vm.prescribingDoctor.EnkkId &&
                        (_vm.prescribingDoctor.EnkkId.length == 5 ||
                          _vm.prescribingDoctor.EnkkId.length == 7 ||
                          _vm.prescribingDoctor.EnkkId.length == 8)))),"label":"Felíró orvos pecsétszáma","required":true,"state":!_vm.validation.hasError('finance.DoctorStampNumber'),"error":_vm.validation.firstError('finance.DoctorStampNumber')},on:{"change":function($event){return _vm.changeValue('DoctorStampNumber', $event)}},model:{value:(_vm.finance.DoctorStampNumber),callback:function ($$v) {_vm.$set(_vm.finance, "DoctorStampNumber", $$v)},expression:"finance.DoctorStampNumber"}})],1)]),_c('b-col',[_c('div',[_c('fp-input',{attrs:{"disabled":!_vm.editable ||
                  (_vm.prescribingDoctor &&
                    _vm.prescribingDoctor.OrganizationUnitNnkId &&
                    _vm.prescribingDoctor.OrganizationUnitNnkId.length == 9),"required":true,"label":"Felíró orvos szervezeti egység NNK azonosítója","state":!_vm.validation.hasError('finance.DoctorPraxisOrganizationCode'),"error":_vm.validation.firstError(
                    'finance.DoctorPraxisOrganizationCode'
                  )},on:{"change":function($event){return _vm.changeValue('DoctorPraxisOrganizationCode', $event)}},model:{value:(_vm.finance.DoctorPraxisOrganizationCode),callback:function ($$v) {_vm.$set(_vm.finance, "DoctorPraxisOrganizationCode", $$v)},expression:"finance.DoctorPraxisOrganizationCode"}})],1)])],1)]},proxy:true}],null,false,3549812081)}),_c('fp-panel-card',{staticStyle:{"margin-top":"10px"},attrs:{"title":"Páciens főbb adatai","required":true},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('b-row',{attrs:{"cols":"1","cols-md":"2"}},[_c('b-col',[_c('fp-input',{attrs:{"disabled":!_vm.editable ||
                (_vm.dispensedPatient &&
                  !!_vm.dispensedPatient.PatientIdentifierValue),"label":"Páciens azonosító","required":true,"state":!_vm.validation.hasError('finance.PatientSsn'),"error":_vm.validation.firstError('finance.PatientSsn')},on:{"change":function($event){return _vm.changeValue('PatientSsn', $event)}},model:{value:(_vm.finance.PatientSsn),callback:function ($$v) {_vm.$set(_vm.finance, "PatientSsn", $$v)},expression:"finance.PatientSsn"}})],1),_c('b-col',[_c('fp-select',{attrs:{"disabled":!_vm.editable ||
                (_vm.dispensedPatient && !!_vm.dispensedPatient.PatientIdentifierType),"label":"Páciens azonosító típusa","required":true,"items":_vm.eesztPatientIdentifierTypeList,"valueKey":"Code","textKey":"Name","state":!_vm.validation.hasError('finance.PatientDocumentCode'),"error":_vm.validation.firstError('finance.PatientDocumentCode')},on:{"change":function($event){return _vm.changeValue('PatientDocumentCode', $event)}},model:{value:(_vm.finance.PatientDocumentCode),callback:function ($$v) {_vm.$set(_vm.finance, "PatientDocumentCode", $$v)},expression:"finance.PatientDocumentCode"}})],1),_c('b-col',[_c('fp-select',{attrs:{"disabled":!_vm.editable ||
                (_vm.dispensedPatient && !!_vm.dispensedPatient.Nationality),"required":true,"label":"Páciens állampolgársága","items":_vm.eesztCountryList,"valueKey":"Code","textKey":"CitizenshipName","searchable":true,"state":!_vm.validation.hasError('finance.PatientCitizenshipCode'),"error":_vm.validation.firstError('finance.PatientCitizenshipCode')},on:{"change":function($event){return _vm.changeValue('PatientCitizenshipCode', $event)},"search":_vm.searchCountry},model:{value:(_vm.finance.PatientCitizenshipCode),callback:function ($$v) {_vm.$set(_vm.finance, "PatientCitizenshipCode", $$v)},expression:"finance.PatientCitizenshipCode"}})],1),_c('b-col',[_c('fp-date-picker',{attrs:{"disabled":!_vm.editable ||
                (_vm.dispensedPatient && !!_vm.dispensedPatient.BirthDate),"label":"Páciens születési dátuma","required":true,"state":!_vm.validation.hasError('finance.BirthDate'),"error":_vm.validation.firstError('finance.BirthDate')},on:{"change":function($event){return _vm.changeValue('PatientBirthDate', $event)}},model:{value:(_vm.finance.PatientBirthDate),callback:function ($$v) {_vm.$set(_vm.finance, "PatientBirthDate", $$v)},expression:"finance.PatientBirthDate"}})],1)],1)]},proxy:true}],null,false,3715914980)}),_c('fp-panel-card',{staticStyle:{"margin-top":"10px"},attrs:{"title":"Kiváltott termékekhez tartozó finanszírozási adatok","required":true},scopedSlots:_vm._u([{key:"content",fn:function(){return _vm._l((_vm.finance.Products),function(product,index){return _c('fp-panel-card',{key:'new-finance-product-' + index,staticStyle:{"margin":"20px 0px"},attrs:{"title":_vm.dispenseProductsForFinance[index].Ttt
              ? _vm.dispenseProductsForFinance[index].Ttt.Name +
                ' (TTT: ' +
                _vm.dispenseProductsForFinance[index].Ttt.Code +
                ')'
              : index + 1 + '. termékhez tartozó adatok',"closedCard":index != 0},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('finance-product',{ref:"financeProduct",refInFor:true,attrs:{"editable":_vm.editable,"dispenseProduct":_vm.dispenseProductsForFinance[index],"prescriptionProduct":_vm.getPrescriptionData(_vm.dispenseProductsForFinance[index]),"refreshDataLoaded":_vm.refreshDataLoaded},on:{"changeValue":function($event){return _vm.changeValue(
                  'Products[' + index + '].' + $event.key,
                  $event.value
                )}},model:{value:(_vm.finance.Products[index]),callback:function ($$v) {_vm.$set(_vm.finance.Products, index, $$v)},expression:"finance.Products[index]"}})]},proxy:true}],null,true)})})},proxy:true}],null,false,2736245219)}),(_vm.editable)?_c('div',[_c('b-button',{on:{"click":_vm.saveFinanceData}},[_vm._v("Mentés")]),_c('b-button',{on:{"click":function($event){_vm.visible = false}}},[_vm._v("Mégsem")])],1):_vm._e()]},proxy:true}],null,false,3386243047),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }