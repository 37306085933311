<template>
  <div>
    <div style="margin: 10px 0px">
      <b-button @click="getTasks">
        <b-icon icon="arrow-repeat"></b-icon>
        {{ $t("base.basic.update") }}
      </b-button>
      <task-table
        :taskList="taskList"
        :taskListFields="taskListFields"
        :hasFilter="true"
      />
    </div>
  </div>
</template>
<script>
import TaskTable from "@/components/table/TaskTable.vue";
import { UserTaskLogic } from "@/logic/backend/user-task";
export default {
  components: { TaskTable },
  name: "ClientTasks",
  data() {
    return {
      taskList: [],
      taskListFields: [
        {
          key: "Status",
          label: this.$t("healthProfessional.tasks.status"),
          sortable: true,
        },
        { key: "Type", label: this.$t("healthProfessional.tasks.type") },
        {
          key: "StartTime",
          label: this.$t("healthProfessional.tasks.startDate"),
          sortable: true,
        },
        {
          key: "Deadline",
          label: this.$t("healthProfessional.tasks.deadline"),
          sortable: true,
        },
        {
          key: "Priority",
          label: this.$t("healthProfessional.tasks.priority"),
          sortable: true,
        },
        { key: "Title", label: this.$t("healthProfessional.tasks.task") },
        {
          key: "Description",
          label: this.$t("healthProfessional.tasks.description"),
        },
      ],
    };
  },
  methods: {
    async getTasks() {
      const getTasksResponse = await UserTaskLogic.getTasks({
        UserId: this.$loggedUser.UserId,
        "-orderby": "Deadline",
      });
      if (!getTasksResponse.Code) {
        this.taskList = getTasksResponse;
      } else {
        this.$bvToast.toast(getTasksResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("client.task.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getTasks();
    this.$store.dispatch(
      "setPageTitle",this.$t("client.menu.tasks"));
  },
};
</script>