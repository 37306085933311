<template>
  <div style="background: white">
    <div v-if="items && items.length > 0">
      <!-- Műveelti gombok -->
      <div
        class="h4"
        style="
          padding: 5px;
          margin: 0px;
          border-bottom: solid 1px;
          text-align: right;
        "
      >
        <b-iconstack
          v-b-tooltip.hover.top="$t('components.table.selectAll')"
          class="operation-icon-button"
          @click="selectAll()"
        >
          <b-icon stacked icon="check-all" scale="0.9"></b-icon>
          <b-icon stacked icon="square"></b-icon>
        </b-iconstack>
        <b-icon
          @click="clearSelect()"
          v-b-tooltip.hover.top="$t('components.table.unselectAll')"
          class="operation-icon-button"
          icon="square"
        ></b-icon>
        <b-icon
          @click="openAll()"
          v-b-tooltip.hover.top="$t('components.table.openAllGroup')"
          class="operation-icon-button"
          icon="plus-circle"
        ></b-icon>
        <b-icon
          @click="closeAll()"
          v-b-tooltip.hover.top="$t('components.table.closeAllGroup')"
          class="operation-icon-button"
          icon="x-circle"
        ></b-icon>
      </div>
      <!-- Grouppok megjelenítése -->
      <div v-for="(group, groupIndex) in items" :key="group.key">
        <!-- Group elem sor megjelenítése -->
        <div
          :class="
            'table-group-rows table-rows ' +
            ((groupIndex + 1) % 2 == 0 ? 'stripped-table-row ' : '') +
            (group.isActive ? 'table-group-rows-active ' : '')
          "
        >
          <!-- Group lenyitása gomb -->
          <b-icon-plus
            class="h2"
            v-if="!group.showItems"
            style="cursor: pointer"
            @click="setValue(group, 'showItems', true)"
          />
          <!-- Group bezárása gomb -->
          <b-icon-x
            class="h2"
            style="cursor: pointer"
            v-if="group.showItems"
            @click="setValue(group, 'showItems', false)"
          />
          <!-- Group megnevezése, checkbox megjelenése -->
          <div style="padding-top: 5px; padding-left: 10px; display: flex">
            <b-form-checkbox
              :checked="group.isActive"
              @change="
                setValue(group, 'isActive', $event);
                selectGroup(group, group.isActive);
              "
            >
            </b-form-checkbox>
            {{ group.key }}
          </div>
        </div>
        <!-- Group elem tartalom megjelenítése -->
        <div class="table-rows group-item-table-box" v-if="group.showItems">
          <!-- Group elemek táblája -->
          <table class="group-item-table">
            <!-- Tábla címsora -->
            <tr class="group-item-table-title">
              <!-- Checkbox címsora -->
              <td></td>
              <!-- Címsor generálás kapott mezők alapján -->
              <td v-for="columnTitle in fields" :key="columnTitle.key">
                {{ columnTitle.label }}
              </td>
            </tr>
            <!-- Tábla elemeinek megjelenése (stripped,active) -->
            <tr
              :class="
                ((itemIndex + 1) % 2 == 0 ? 'stripped-table-row ' : '') +
                (item.isActive ? 'group-item-table-group-rows-active ' : '')
              "
              v-for="(item, itemIndex) in group.value"
              :key="item.RelationshipId"
              style="cursor: pointer"
              @click="
                setValue(item, 'isActive', !item.isActive);
                checkItemsToSelectGroup();
              "
            >
              <!-- Elemhez tartozó checkbox -->
              <td>
                <b-form-checkbox
                  :checked="item.isActive"
                  @change="
                    setValue(item, 'isActive', !item.isActive);
                    checkItemsToSelectGroup();
                  "
                >
                </b-form-checkbox>
              </td>
              <!-- Eleme adatai a megadott mezők alapján -->
              <td v-for="itemKey in fields" :key="'content-' + itemKey.key">
                {{
                  itemKey.formatter && getColumnValue(itemKey.key, item)
                    ? itemKey.formatter(getColumnValue(itemKey.key, item))
                    : getColumnValue(itemKey.key, item)
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div
      v-else
      style="padding-left: 10px"
      class="table-group-rows table-rows stripped-table-row"
    >
      {{ emptyMessage }}
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  model: {
    event: "selectedItems",
  },
  props: {
    items: Array,
    fields: Array,
    emptyMessage: String,
  },
  methods: {
    setValue(item, valueName, value) {
      Vue.set(item, valueName, value);
    },
    selectAll() {
      this.items.forEach((group) => {
        this.selectGroup(group, true);
      });
    },
    clearSelect() {
      this.items.forEach((group) => {
        this.selectGroup(group, false);
      });
    },
    openAll() {
      this.items.forEach((group) => {
        this.setValue(group, "showItems", true);
      });
    },
    closeAll() {
      this.items.forEach((group) => {
        this.setValue(group, "showItems", false);
      });
    },
    getColumnValue(itemKey, item) {
      return item[itemKey];
    },
    selectGroup(group, value) {
      group.value.forEach((item) => {
        this.setValue(item, "isActive", value);
      });
      this.checkItemsToSelectGroup();
    },
    checkItemsToSelectGroup() {
      const selectedTemp = [];
      this.items.forEach((group) => {
        const temp = !group.value.some((x) => !x.isActive);
        this.setValue(group, "isActive", temp);
      });
      this.items.forEach((group) => {
        group.value.forEach((item) => {
          if (
            item.isActive &&
            !selectedTemp.some((t) => t.UserId == item.UserId)
          ) {
            selectedTemp.push(item);
          }
        });
      });
      this.$emit("selectedItems", selectedTemp);
    },
  },
};
</script>
<style>
.table-rows {
  padding: 5px 0px;
  overflow: auto;
  display: flex;
}
.table-rows.group-item-table-box {
  padding: 0px;
}
.stripped-table-row {
  background: #f0f0f0;
  border-top: solid 1px #dee2e6;
  border-bottom: solid 1px #dee2e6;
}
.group-item-stripped-table-row {
  background: #f1f1f1;
  border-top: solid 1px #f9f9f9;
  border-bottom: solid 1px #f9f9f9;
}
.table-group-rows {
  display: flex;
}
.table-group-rows:not(.table-group-rows-active):hover {
  background: #e9e9e9 !important;
}
.table-group-rows-active,
.group-item-table-group-rows-active {
  background: #dadada;
}
.table-group-rows-active.stripped-table-row,
.group-item-table-group-rows-active.stripped-table-row {
  background: #dadada;
  border-top: solid 1px #d2d2d2;
  border-bottom: solid 1px #d2d2d2;
}
.group-item-table {
  width: 100%;
}
.group-item-table tr td {
  padding: 10px;
}
.group-item-table-title {
  border-bottom: solid 3px #dee2de;
  border-top: solid 2px #dedede;
  font-weight: bold;
}
.group-item-table-box {
  border-left: solid 1px #0000002f;
  border-right: solid 1px #0000002f;
  border-bottom: solid 1px #0000002f;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.operation-icon-button {
  margin: 5px;
  cursor: pointer;
}
</style>