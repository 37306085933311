<template>
  <div>
    <fp-app-header
      :hasLangSwitch="true"
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :menuElements="partnerMenuElements"
      :projectEnabledUiElements="project ? project.EnabledUiElements : null"
      :enabledMenuElements="
        project && project.EnabledUiElements
          ? project.EnabledUiElements.HealthProfessional
          : null
      "
      @forceUpdate="forceUpdate"
    />
    <router-view
      :key="'views-partner-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="partner"
      :project="project"
      @title-changed="changeTitle"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
      @forceUpdate="forceUpdate"
    />
    <Footer
      v-if="
        currentRouteName === 'WebAppPartnerHome' ||
        currentRouteName === 'WebAppPartnerLogs' ||
        currentRouteName === 'WebAppPartnerResearch'
      "
    />
  </div>
</template>
<script>
import Header from "@/components/header-footer/WebAppPartnerHeader.vue";
import Footer from "@/components/header-footer/WebAppPartnerFooter.vue";
import { ProjectLogic } from "@/logic/backend/project";
export default {
  components: {
    Header,
    Footer,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      profileFillingRate: null,
      forceUpdateKey: 0,
      forceUpdateHeaderKey: 0,
      project: null,
      partnerMenuElements: [
        {
          url: "RegistrationParticipant",
          langKey: "partner.menu.registrationParticipant",
          key: "registration-participant",
        },
        {
          url: "ParticipantList",
          langKey: "partner.participantList.title",
          key: "participant-list",
        },
        {
          url: "Projects",
          langKey: "partner.projects.title",
          key: "project",
        },
        {
          url: "Documents",
          langKey: "partner.menu.documents",
          uiElementName: "Document",
          key: "document",
        },
        {
          url: "Tasks",
          langKey: "partner.tasks.title",
          uiElementName: "UserTask",
          key: "task",
        },
        {
          url: "Workflows",
          langKey: "partner.workflows.title",
          uiElementName: "Workflow",
          key: "workflow",
        },
        {
          url: "Templates",
          langKey: "partner.menu.templates",
          uiElementName: "NotificationTemplate",
          key: "template",
        },
        {
          url: "Questionnaires",
          langKey: "healthProfessional.menu.questionnaires",
          uiElementName: "Questionnaire",
          key: "questionnaire",
        },
      ],
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    async forceUpdate() {
      ++this.forceUpdateKey;
      await this.getProject();
    },
    async forceUpdateHeader() {
      ++this.forceUpdateHeaderKey;
      await this.getProject();
    },
    async getProject() {
      const selectedProject = this.$loggedUser.SelectedProject;
      if (selectedProject && selectedProject.ProjectId) {
        const getProjectResponse = await ProjectLogic.getProjectDetails({
          ProjectId: selectedProject.ProjectId,
        });
        if (!getProjectResponse.Code) {
          this.project = getProjectResponse[0];
        } else {
          this.$bvToast.toast(getProjectResponse.Message, {
            title: "Hiba",
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
          this.project = null;
        }
      } else {
        this.project = null;
      }
    },
    changeTitle(newTitle) {
      if (newTitle) {
        this.siteTitle = newTitle;
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      this.userPicture = imgUrl;
    },
  },
  async mounted() {
    await this.getProject();
  },
};
</script>
