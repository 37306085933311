<template>
  <div>
    <span style="margin-left: 5px">{{
      params.Title ? params.Title[language] : null
    }}</span>
    <span v-if="params.Required && !params.Label" style="color: red">*</span>
    <fp-select
      :items="params.Items"
      :required="params.Required"
      :description="params.Description ? params.Description[language] : null"
      :disabled="!params.Enabled != false || disabled"
      :searchable="searchable"
      @search="$emit('search', $event)"
      v-model="tempValue"
      valueKey="ItemId"
    >
      <template #element="element">
        {{ element.Label ? element.Label[language] : null }}
      </template>
    </fp-select>
  </div>
</template>
<script>
export default {
  name: "Single",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    params: Object,
    value: [Number, String, Object],
    disabled: Boolean,
    searchable: Boolean,
    language: String,
  },
  data() {
    return {
      tempValue: this.value,
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
  },
};
</script>