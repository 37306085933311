<template>
  <!-- Receptlista -->
  <div>
    <div style="display: flex; align-items: baseline">
      <fp-date-range-button
        style="padding-left: 0px"
        v-model="dispensedDateRange"
        :hideClearButton="true"
        @change="filterList"
      />
      <fp-input
        style="width: 200px"
        label="Recept azonosító"
        v-model="filterPrescriptionId"
        @change="filterList"
      />
      <fp-input
        label="Recept EESZT azonosító"
        style="margin: 0px 10px; width: 200px"
        v-model="filterEesztId"
        @change="filterList"
      />
      <fp-input
        style="width: 250px"
        label="Recept NEAK vényazonosító"
        v-model="filterNeakId"
        @change="filterList"
      />
    </div>
    <div
      v-if="!isDisabledReportedProds"
      style="display: flex; align-items: center"
    >
      <fp-svg-icon
        style="cursor: pointer"
        icon="check-all"
        v-b-tooltip.hover="'A lista összes elemének kiválasztása'"
        @click="selectAllPrescription"
      />
      <fp-svg-icon
        style="cursor: pointer"
        icon="uncheck-all"
        v-b-tooltip.hover="'A kiválasztott lista űrítése'"
        @click="unselectAllPrescription"
      />
      <!-- <b-button
        style="margin-left: auto"
        :disabled="selectedPrescriptionIds.length == 0"
        class="eh-action-button"
        @click="generateReport"
      >
        Jelentés generálása
      </b-button> -->
    </div>
    <div class="selected-pres-count-text">
      {{ selectedPrescriptionIds.length }} kiválasztott recept
    </div>
    <fp-tabs style="margin: 10px 0px">
      <fp-tab title="Lejelenthető receptek">
        <fp-pagination
          v-if="recipeListSize > 10"
          :itemsPerPage.sync="recipePerPage"
          :listSize.sync="recipeListSize"
          :currentPageNumber.sync="currentPage"
        />
        <fp-table-list :items="prescriptionList" :fields="prescriptionFields">
          <template #head(PrescriptionId)="row">
            {{ row.label }}
            <b-icon-question-circle-fill
              v-b-tooltip.hover="'Rendezés a recept azonosító alapján'"
            />
          </template>
          <template #PrescriptionId="row">
            <div>
              <b-iconstack
                v-b-tooltip.hover="'Recept azonosító'"
                font-scale="1.8"
                style="margin: 0px 5px"
              >
                <b-icon stacked icon="file-binary" />
                <b-icon stacked icon="text-left" scale="0.5" shift-v="3" />
              </b-iconstack>
              {{ row.item.PrescriptionId }}
            </div>
            <div>
              <div style="display: flex; align-items: center">
                <fp-svg-icon
                  v-b-tooltip.hover="'Recept EESZT azonosító'"
                  icon="eeszt-logo"
                  style="background: transparent; margin: 0px; padding: 0px"
                  color="black"
                />
                {{ row.item.EesztId }}
              </div>
            </div>
            <div>
              <div style="display: flex; align-items: center">
                <fp-svg-icon
                  v-b-tooltip.hover="'Recept papíralapú NEAK vényazonosító'"
                  icon="neak-logo"
                  :size="27"
                  style="background: transparent; margin: 0px; padding: 0px"
                  color="black"
                />
                {{ row.item.NeakId }}
              </div>
            </div>
          </template>
          <template #head(PrescriptionTimestamp)="row">
            {{ row.label }}
            <b-icon-question-circle-fill
              v-b-tooltip.hover="'Rendezés a recept felírás dátuma alapján'"
            />
          </template>
          <template #PrescriptionTimestamp="row">
            <div>
              <b-iconstack
                v-b-tooltip.hover="'Recept felírás dátuma'"
                font-scale="1.8"
                style="margin: 0px 5px"
              >
                <b-icon stacked icon="calendar" />
                <b-icon stacked icon="pencil-fill" scale="0.6" shift-v="-1.5" />
              </b-iconstack>
              {{
                $convertDateToString(
                  row.item.PrescriptionTimestamp,
                  "YYYY-MM-DD"
                )
              }}
            </div>
            <div class="prescription-data-element">
              <b-iconstack
                v-b-tooltip.hover="'Recept kiadás dátuma'"
                font-scale="1.8"
                style="margin: 0px 5px"
              >
                <b-icon stacked icon="calendar" />
                <b-icon
                  stacked
                  icon="box-arrow-right"
                  scale="0.6"
                  shift-v="-1"
                ></b-icon>
              </b-iconstack>
              {{
                $convertDateToString(
                  row.item.DispenseTimestamp,
                  "YYYY-MM-DD HH:mm"
                )
              }}
            </div>
            <div style="display: flex">
              <b-iconstack
                v-b-tooltip.hover="'Páciens'"
                font-scale="1.8"
                style="margin: 0px 5px"
              >
                <b-icon stacked icon="person-lines-fill" />
              </b-iconstack>
              <div class="m-1">
                {{ row.item.PatientFullName }}<br />
                {{
                  "(" +
                  $convertDateToString(
                    row.item.PatientBirthDate,
                    "YYYY-MM-DD"
                  ) +
                  ", " +
                  getPatientAge(
                    row.item.PrescriptionTimestamp,
                    row.item.PatientBirthDate
                  ) +
                  " év)"
                }}
              </div>
            </div>
          </template>
          <template #Products="row">
            <div
              v-for="(product, index) in row.item.Products"
              :key="'prescription-products' + index"
            >
              <div style="display: flex">
                <fp-svg-icon
                  icon="medical-product"
                  color="black"
                  style="background: transparent; margin: 0px"
                />
                <div class="m-1">
                  <div style="font-size: 20px">
                    {{
                      product.DispenseProduct.ProductName
                        ? product.DispenseProduct.ProductName
                        : product.DispenseProduct.Ttt
                        ? product.DispenseProduct.Ttt.Name
                        : product.DispenseProduct.Iso
                        ? product.DispenseProduct.Iso.Name
                        : null
                    }}
                    <div style="font-weight: 500">
                      {{
                        product.DispenseProduct.Amount.Amount +
                        " " +
                        product.DispenseProduct.Amount.Unit +
                        ", " +
                        (product.DispenseProduct.PrescriptionTitle
                          ? product.DispenseProduct.PrescriptionTitle.Name
                          : product.DispenseProduct.DispenseTitle
                          ? product.DispenseProduct.DispenseTitle.Name
                          : "")
                      }}
                    </div>
                  </div>
                  <div style="font-size: 15px">
                    {{
                      product.DispenseProduct.Ttt && product.DispenseProduct.Iso
                        ? "(TTT kód: " +
                          product.DispenseProduct.Ttt.Name +
                          ", ISO kód: " +
                          product.DispenseProduct.Iso.Name +
                          ")"
                        : product.DispenseProduct.Ttt
                        ? "(TTT kód: " + product.DispenseProduct.Ttt.Code + ")"
                        : product.DispenseProduct.Iso
                        ? "(ISO kód: " + product.DispenseProduct.Iso.Code + ")"
                        : ""
                    }}
                  </div>
                  <div
                    v-if="
                      product.FinanceProduct &&
                      product.FinanceProduct.FinanceReportIds
                    "
                  >
                    {{ product.FinanceProduct.FinanceReportIds.join(", ") }}
                    azonosítójú jelentés(ek)en szerepel
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #Details="row">
            <b-button
              v-if="!row.item._showDetails && row.item.Products"
              @click="openRowDetails(row)"
            >
              Nyit
            </b-button>
            <b-button
              v-if="row.item._showDetails && row.item.Products"
              @click="closeRowDetails(row)"
            >
              Zár
            </b-button>
          </template>
          <template #row-details="row">
            <fp-table-list
              :items="row.item.Products"
              :fields="dispenseProductsFields"
            >
              <template #Ttt="productItem">
                <div style="width: fit-content">
                  <div style="display: flex">
                    <fp-svg-icon
                      icon="medical-product"
                      color="black"
                      style="background: transparent; margin: 0px"
                    />
                    <div class="m-1">
                      <div style="font-size: 20px">
                        {{
                          productItem.item.DispenseProduct.ProductName
                            ? productItem.item.DispenseProduct.ProductName
                            : productItem.item.DispenseProduct.Ttt
                            ? productItem.item.DispenseProduct.Ttt.Name
                            : productItem.item.DispenseProduct.Iso
                            ? productItem.item.DispenseProduct.Iso.Name
                            : null
                        }}
                        <div style="font-weight: 500">
                          {{
                            productItem.item.DispenseProduct.Amount.Amount +
                            " " +
                            productItem.item.DispenseProduct.Amount.Unit +
                            ", " +
                            (productItem.item.DispenseProduct.PrescriptionTitle
                              ? productItem.item.DispenseProduct
                                  .PrescriptionTitle.Name
                              : productItem.item.DispenseProduct.DispenseTitle
                              ? productItem.item.DispenseProduct.DispenseTitle
                                  .Name
                              : "")
                          }}
                        </div>
                      </div>
                      <div style="font-size: 15px">
                        {{
                          productItem.item.DispenseProduct.Ttt &&
                          productItem.item.DispenseProduct.Iso
                            ? "(TTT kód: " +
                              productItem.item.DispenseProduct.Ttt.Name +
                              ", ISO kód: " +
                              productItem.item.DispenseProduct.Iso.Name +
                              ")"
                            : productItem.item.DispenseProduct.Ttt
                            ? "(TTT kód: " +
                              productItem.item.DispenseProduct.Ttt.Code +
                              ")"
                            : productItem.item.DispenseProduct.Iso
                            ? "(ISO kód: " +
                              productItem.item.DispenseProduct.Iso.Code +
                              ")"
                            : ""
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #DispenseData="productItem">
                <div>
                  Kiadás időpontja:
                  <b>
                    {{
                      $convertDateToString(
                        productItem.item.DispenseProduct.DispensedAt,
                        "YYYY-MM-DD HH:mm"
                      )
                    }}
                  </b>
                </div>
                <div>
                  Felírt termék finanszírozási kategóriája:
                  <b>
                    {{
                      productItem.item.DispenseProduct.SubsidizationCategory
                        ? productItem.item.DispenseProduct.SubsidizationCategory
                            .Name
                        : ""
                    }}
                  </b>
                </div>
                <div>
                  Felírt termék finanszírozási árai:
                  <div
                    style="margin-left: 10px"
                    v-if="productItem.item.DispenseProduct.FinancialInfo"
                  >
                    Teljes összeg:
                    <b>
                      {{
                        productItem.item.DispenseProduct.FinancialInfo
                          .TotalPrice
                      }}
                      Ft
                    </b>
                    <br />
                    Támogatott összeg:
                    <b>
                      {{
                        productItem.item.DispenseProduct.FinancialInfo
                          .Subsidization
                      }}
                      Ft
                    </b>
                  </div>
                </div>
              </template>
              <template #FinancialData="productItem">
                <div>
                  NEAK forgalmi kód:
                  <b>
                    {{
                      productItem.item.FinanceProduct.NeakFinancialCode
                        ? productItem.item.FinanceProduct.NeakFinancialCode.Name
                        : ""
                    }}
                  </b>
                </div>
                <div>
                  NEAK jogcím kód:
                  <b>
                    {{
                      productItem.item.FinanceProduct.NeakLegalType
                        ? productItem.item.FinanceProduct.NeakLegalType.Name
                        : ""
                    }}
                  </b>
                </div>
                <div>
                  NEAK magisztrális díjosztály:
                  <b>
                    {{
                      productItem.item.FinanceProduct.NeakMagistralFeeClass
                        ? productItem.item.FinanceProduct.NeakMagistralFeeClass
                            .Name
                        : ""
                    }}
                  </b>
                </div>
              </template>
              <template #Select="productItem">
                <div
                  v-if="
                    !(
                      isDisabledReportedProds &&
                      (productItem.item.FinanceProduct &&
                      productItem.item.FinanceProduct.FinanceReportIds &&
                      productItem.item.FinanceProduct.FinanceReportIds.length
                        ? productItem.item.FinanceProduct.FinanceReportIds.includes(
                            reportId
                          )
                        : false)
                    )
                  "
                  class="h2"
                >
                  <div v-if="canSelectProducts">
                    <b-icon
                      icon="check-square-fill"
                      v-if="
                        isPrescriptionSelectedProduct(
                          row.item,
                          productItem.item
                        )
                      "
                      @click="
                        unselectPrescriptionProduct(row.item, productItem.item)
                      "
                    />
                    <b-icon
                      icon="square"
                      v-else
                      @click="
                        selectPrescriptionProduct(row.item, productItem.item)
                      "
                    />
                  </div>
                </div>
                <div v-else>
                  <b-icon
                    class="h2"
                    icon="exclamation-circle-fill"
                    variant="warning"
                    v-b-tooltip.hover="
                      'A kiadott termék már szerepel ezen a jelentésen, így nem adhatja hozzá még egyszer'
                    "
                  />
                </div>
              </template>
            </fp-table-list>
            <fp-table-list
              v-if="hasErrorMessage(row.item.PrescriptionId)"
              :items="getInvalidPrescriptionProducts(row.item.PrescriptionId)"
              :fields="invalidPresProductsFields"
            >
              <template #Error>
                <b-icon
                  class="h2"
                  icon="exclamation-circle-fill"
                  variant="danger"
                />
              </template>
              <template #Data="row">
                <div>
                  <b-iconstack
                    v-b-tooltip.hover="'Termék kiadás dátuma'"
                    font-scale="1.8"
                    style="margin: 0px 5px"
                  >
                    <b-icon stacked icon="calendar" />
                    <b-icon
                      stacked
                      icon="box-arrow-right"
                      scale="0.6"
                      shift-v="-1"
                    ></b-icon>
                  </b-iconstack>
                  {{
                    $convertDateToString(
                      row.item.DispensedAt,
                      "YYYY-MM-DD HH:mm"
                    )
                  }}
                </div>
                <div style="width: fit-content">
                  <div style="display: flex">
                    <fp-svg-icon
                      icon="medical-product"
                      color="black"
                      style="background: transparent; margin: 0px"
                    />
                    <div class="m-1">
                      <div style="font-size: 20px">
                        {{
                          row.item.Ttt
                            ? row.item.Ttt.Name
                            : row.item.Iso
                            ? row.item.Iso.Name
                            : null
                        }}
                        <div style="font-weight: 500">
                          {{
                            row.item.Amount.Amount +
                            " " +
                            row.item.Amount.Unit +
                            ", " +
                            (row.item.PrescriptionTitle
                              ? row.item.PrescriptionTitle.Name
                              : row.item.DispenseTitle
                              ? row.item.DispenseTitle.Name
                              : "")
                          }}
                        </div>
                      </div>
                      <div style="font-size: 15px">
                        {{
                          row.item.Ttt
                            ? "(TTT kód: " + row.item.Ttt.Code + ")"
                            : ""
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #Messages="row">
                <ul>
                  <li
                    v-for="(msg, index) in row.item.Messages"
                    :key="'invalid-prescription-product-' + index"
                  >
                    {{ msg }}
                  </li>
                </ul>
              </template>
            </fp-table-list>
          </template>
          <template #Select="row">
            <div class="h2">
              <div
                v-if="
                  !(
                    isDisabledReportedProds &&
                    isAllReportedProduct(row.item.Products)
                  )
                "
              >
                <b-icon
                  icon="square"
                  v-if="isPrescriptionUnselectedAllProduct(row.item)"
                  @click="selectAllProduct(row.item)"
                />
                <b-icon
                  icon="check-square-fill"
                  v-if="isPrescriptionSelectedAllProduct(row.item)"
                  @click="unselectAllProduct(row.item)"
                />
                <b-icon
                  icon="dash-square-fill"
                  v-if="isPrescriptionHasSelectedProduct(row.item)"
                  @click="unselectAllProduct(row.item)"
                />
              </div>
              <div v-else>
                <b-icon
                  icon="exclamation-circle-fill"
                  variant="warning"
                  v-b-tooltip.hover.top="
                    'A recept már szerepel ezen a jelentésen, így nem adhatja hozzá még egyszer'
                  "
                />
              </div>
              <div v-if="hasErrorMessage(row.item.PrescriptionId)">
                <b-icon
                  style="cursor: pointer"
                  icon="exclamation-circle-fill"
                  variant="danger"
                  v-b-tooltip.hover.bottom="
                    'A recepten hibás vagy hiányzó adat található. Részletekért kattintson az ikonra.'
                  "
                  @click="
                    $set(
                      row.item,
                      '_showErrorMessages',
                      !row.item._showErrorMessages
                    )
                  "
                />
                <prescription-error-messages-modal
                  v-model="row.item._showErrorMessages"
                  :prescriptionErrors="
                    getPrescriptionErrorMessages(row.item.PrescriptionId)
                  "
                />
              </div>
            </div>
          </template>
        </fp-table-list>
      </fp-tab>
      <fp-tab v-if="fullPrescriptionInvalidList">
        <template #title>
          Hiányos receptek
          <b-badge pill variant="danger">
            {{ fullPrescriptionInvalidList.length }}
          </b-badge>
        </template>
        <fp-table-list
          :items="fullPrescriptionInvalidList"
          :fields="fullPrescriptionInvalidListFields"
        >
          <template #Messages="row">
            <li
              v-for="(msg, index) in row.item.Messages"
              :key="'presc-error-' + index"
            >
              {{ msg }}
            </li>
          </template>
          <template #InvalidProducts="row">
            <div
              v-if="row.item.InvalidProducts && row.item.InvalidProducts.length"
            >
              <li
                v-for="(prod, prodIndex) in row.item.InvalidProducts"
                :key="'presc-invalid-prod-' + prodIndex"
              >
                {{
                  prod.Ttt
                    ? prod.Ttt.Name +
                      " (TTT: " +
                      prod.Ttt.Code +
                      "), " +
                      (prod.Amount
                        ? prod.Amount.Amount + " " + prod.Amount.Unit
                        : "") +
                      ", " +
                      (prod.DispenseTitle ? prod.DispenseTitle.Name : "") +
                      ":"
                    : ""
                }}
                <ul>
                  <li
                    v-for="(prodError, msgIndex) in prod.Messages"
                    :key="'prescription-error-prod-error-' + msgIndex"
                  >
                    {{ prodError }}
                  </li>
                </ul>
              </li>
            </div>
            <div v-else></div>
          </template>
        </fp-table-list>
      </fp-tab>
    </fp-tabs>
  </div>
</template>
<script>
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
import { mapActions } from "vuex";
import moment from "moment";
import PrescriptionErrorMessagesModal from "./PrescriptionErrorMessagesModal.vue";

export default {
  components: { PrescriptionErrorMessagesModal },
  name: "SelectParticipantList",
  props: {
    isDisabledReportedProds: Boolean,
    reportId: Number,
    canSelectProducts: Boolean,
  },
  data() {
    return {
      prescriptionList: null,
      prescriptionIds: null,
      selectedPrescriptionList: [],
      selectedPrescriptionIds: [],
      recipeListSize: null,
      recipePerPage: 10,
      currentPage: 1,
      forceUpdateNum: 0,
      resetOpenDetails: false,
      prescriptionFields: [
        { key: "Select", label: "Kiválasztás" },
        {
          key: "PrescriptionId",
          label: "Recept alapadatok",
          sortable: true,
        },
        {
          key: "Products",
          label: "Termékek",
        },
        {
          key: "PrescriptionTimestamp",
          label: "Recept adatok",
          sortable: true,
        },
        { key: "Details", label: "" },
      ],
      dispenseProductsFields: [
        { key: "Select", label: this.canSelectProducts ? "Kiválasztás" : "" },
        { key: "Ttt", label: "Kiadott termék" },
        { key: "DispenseData", label: "Termék kiadás adatai" },
        { key: "FinancialData", label: "Termék finanszírozási adatai" },
      ],
      fullPrescriptionInvalidListFields: [
        { key: "PrescriptionId", label: "Recept azonosító" },
        { key: "Messages", label: "Recept hibák, hiányosságok" },
        {
          key: "InvalidProducts",
          label: "Recept termékek hibái, hiányosságai",
        },
      ],
      openedPrescriptionId: null,
      dispensedDateRange: {
        to: moment().format(),
        from: moment().subtract(7, "days").startOf("days").format(),
      },
      filterPrescriptionId: null,
      filterEesztId: null,
      filterNeakId: null,
      invalidPrescriptionList: null,
      invalidPresProductsFields: [
        { key: "Error", label: "" },
        { key: "Data", label: "Termék" },
        { key: "Messages", label: "Hiba üzenetek" },
      ],
    };
  },
  watch: {
    async currentPage() {
      if (this.recipeListSize && this.currentPage) {
        await this.filterList();
      }
    },
    async recipePerPage() {
      if (this.recipeListSize && this.currentPage) {
        await this.filterList();
      }
    },
    recipeListSize(input) {
      if (input > this.recipePerPage) {
        this.currentPage = 1;
      }
    },
  },
  computed: {
    fullPrescriptionInvalidList() {
      if (this.invalidPrescriptionList) {
        const filteredList = this.invalidPrescriptionList.filter(
          (ip) =>
            !this.prescriptionIds
              .map((p) => p.PrescriptionId)
              .includes(ip.PrescriptionId)
        );
        return filteredList;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      setStoreData: "setData",
      addPrescriptionToStore: "addPrescription",
      addDispenseProductToStore: "addDispenseProduct",
      deletePrescriptionToStore: "deletePrescription",
      deleteDispenseProductToStore: "deleteDispenseProduct",
    }),
    isAllReportedProduct(products) {
      const reportedProducts = products.filter((p) =>
        p.FinanceProduct &&
        p.FinanceProduct.FinanceReportIds &&
        p.FinanceProduct.FinanceReportIds.length
          ? p.FinanceProduct.FinanceReportIds.includes(this.reportId)
          : false
      );
      return reportedProducts.length == products.length;
    },
    getPatientAge(prescriptionTimestamp, patientBirthDate) {
      if (prescriptionTimestamp && patientBirthDate) {
        return moment(prescriptionTimestamp).diff(patientBirthDate, "years");
      } else {
        return "";
      }
    },
    generateMessageToHtml(prescriptionId) {
      if (this.invalidPrescriptionList) {
        var msgHtml = "";
        const invalidPres = this.invalidPrescriptionList.find(
          (p) => p.PrescriptionId == prescriptionId
        );
        invalidPres.Messages.forEach((m) => {
          msgHtml += "<li>" + m + "</li>";
        });
        return msgHtml;
      }
      return null;
    },
    getInvalidPrescriptionProducts(prescriptionId) {
      if (this.invalidPrescriptionList) {
        const invalidPres = this.invalidPrescriptionList.find(
          (p) => p.PrescriptionId == prescriptionId
        );
        return invalidPres.InvalidProducts;
      }
      return null;
    },
    hasErrorMessage(prescriptionId) {
      if (this.invalidPrescriptionList) {
        const invalidPress = this.invalidPrescriptionList.map(
          (p) => p.PrescriptionId
        );
        const hasError = invalidPress.includes(prescriptionId);
        return hasError;
      }
      return null;
    },
    getPrescriptionErrorMessages(prescriptionId) {
      if (this.invalidPrescriptionList) {
        const invalidPres = this.invalidPrescriptionList.find(
          (p) => p.PrescriptionId == prescriptionId
        );
        return invalidPres;
      }
      return null;
    },
    isPrescriptionUnselectedAllProduct(row) {
      const findRow = this.selectedPrescriptionIds.find(
        (spi) => spi.PrescriptionId == row.PrescriptionId
      );
      return !findRow;
    },
    isPrescriptionSelectedAllProduct(row) {
      const findSelectedIds = this.selectedPrescriptionIds.find(
        (spi) => spi.PrescriptionId == row.PrescriptionId
      );
      if (findSelectedIds) {
        const rowProdIds = row.Products.map((p) => p.FinanceProduct.UniqueId);
        const rowSelectedProdIdsSome = (element) =>
          !rowProdIds.includes(element);
        return (
          !findSelectedIds.FinancedProductUniqueIds.some(
            rowSelectedProdIdsSome
          ) &&
          rowProdIds.length == findSelectedIds.FinancedProductUniqueIds.length
        );
      }
      return false;
    },
    isPrescriptionHasSelectedProduct(row) {
      const findSelectedIds = this.selectedPrescriptionIds.find(
        (spi) => spi.PrescriptionId == row.PrescriptionId
      );
      if (findSelectedIds) {
        const rowProdIds = row.Products.map((p) => p.FinanceProduct.UniqueId);
        const rowSelectedProdIdsSome = (element) =>
          !rowProdIds.includes(element);
        return (
          !findSelectedIds.FinancedProductUniqueIds.some(
            rowSelectedProdIdsSome
          ) &&
          rowProdIds.length > findSelectedIds.FinancedProductUniqueIds.length
        );
      }
      return false;
    },
    selectAllProduct(row) {
      var prescription = this.selectedPrescriptionIds.find(
        (e) => e.PrescriptionId == row.PrescriptionId
      );
      var products = row.Products;
      if (this.isDisabledReportedProds) {
        products = products.filter((p) =>
          p.FinanceProduct &&
          p.FinanceProduct.FinanceReportIds &&
          p.FinanceProduct.FinanceReportIds.length
            ? !p.FinanceProduct.FinanceReportIds.includes(this.reportId)
            : true
        );
      }
      var rowProdIds = products.map((e) => e.DispenseProduct.UniqueId);
      if (!prescription) {
        const element = {
          PrescriptionId: row.PrescriptionId,
          FinancedProductUniqueIds: rowProdIds,
        };
        this.selectedPrescriptionIds.push(element);
        this.addPrescriptionToStore(element);
      } else {
        prescription = {
          PrescriptionId: row.PrescriptionId,
          FinancedProductUniqueIds: rowProdIds,
        };
        rowProdIds.forEach((prod) => {
          this.addDispenseProductToStore({
            PrescriptionId: row.PrescriptionId,
            FinancedProductUniqueId: prod,
          });
        });
      }
    },
    unselectAllProduct(row) {
      const newSelectedIdList = this.selectedPrescriptionIds.filter(
        (spi) => spi.PrescriptionId != row.PrescriptionId
      );
      this.selectedPrescriptionIds = newSelectedIdList;
      this.deletePrescriptionToStore(row.PrescriptionId);
    },
    isPrescriptionSelectedProduct(presRow, prodRow) {
      const findSelectedPres = this.selectedPrescriptionIds.find(
        (spi) => spi.PrescriptionId == presRow.PrescriptionId
      );
      if (findSelectedPres) {
        return findSelectedPres.FinancedProductUniqueIds.includes(
          prodRow.FinanceProduct.UniqueId
        );
      }
      return false;
    },
    selectPrescriptionProduct(presRow, prodRow) {
      var selectedPresIds = this.selectedPrescriptionIds.find(
        (spi) => spi.PrescriptionId == presRow.PrescriptionId
      );
      if (selectedPresIds) {
        selectedPresIds.FinancedProductUniqueIds.push(
          prodRow.FinanceProduct.UniqueId
        );
        this.addDispenseProductToStore({
          PrescriptionId: presRow.PrescriptionId,
          FinancedProductUniqueId: prodRow.FinanceProduct.UniqueId,
        });
      } else {
        const element = {
          PrescriptionId: presRow.PrescriptionId,
          FinancedProductUniqueIds: [prodRow.FinanceProduct.UniqueId],
        };
        this.selectedPrescriptionIds.push(element);
        this.addPrescriptionToStore(element);
      }
    },
    unselectPrescriptionProduct(presRow, prodRow) {
      var tempList = this.selectedPrescriptionIds;
      const selectedPresIds = this.selectedPrescriptionIds.find(
        (spi) => spi.PrescriptionId == presRow.PrescriptionId
      );
      if (
        selectedPresIds &&
        selectedPresIds.FinancedProductUniqueIds &&
        selectedPresIds.FinancedProductUniqueIds.length > 1
      ) {
        if (selectedPresIds.PrescriptionId == presRow.PrescriptionId) {
          selectedPresIds.FinancedProductUniqueIds = selectedPresIds.FinancedProductUniqueIds.filter(
            (id) => id != prodRow.FinanceProduct.UniqueId
          );
          this.deleteDispenseProductToStore({
            PrescriptionId: presRow.PrescriptionId,
            FinancedProductUniqueId: prodRow.FinanceProduct.UniqueId,
          });
        }
      } else {
        tempList = tempList.filter(
          (t) => t.PrescriptionId != presRow.PrescriptionId
        );
        this.deletePrescriptionToStore(presRow.PrescriptionId);
      }
      this.selectedPrescriptionIds = tempList;
    },
    selectAllPrescription() {
      const tempList = this.prescriptionIds.concat(
        this.selectedPrescriptionIds
      );

      this.selectedPrescriptionIds = this.$checkDuplicates(
        tempList,
        "PrescriptionId"
      );
      this.setStoreData({
        key: "SelectedPrescriptions",
        value: this.selectedPrescriptionIds,
      });

      const invalidPrescIds = this.invalidPrescriptionList.map(
        (ip) => ip.PrescriptionId
      );
      const invalidSelectedPresc = this.selectedPrescriptionIds.filter((p) =>
        invalidPrescIds.includes(p.PrescriptionId)
      );
      if (invalidSelectedPresc && invalidSelectedPresc.length) {
        this.$bvToast.toast(
          "A " +
            invalidSelectedPresc.map((p) => p.PrescriptionId).join(", ") +
            " azonosítójú receptekhez tartozó egyes termékeken hibás vagy hiányzó adatok találhatók, ezért azon termékek nem kerülnek a jelentésbe. A hiba leírása megjelenik a listában a receptnél.",
          {
            title: "Figyelmeztetés!",
            variant: "warning",
            solid: true,
            noAutoHide: true,
          }
        );
      }
    },
    unselectAllPrescription() {
      this.selectedPrescriptionIds = [];
      this.setStoreData({
        key: "SelectedPrescriptions",
        value: [],
      });
    },
    closeRowDetails(row) {
      this.$set(row.item, "_showDetails", false);
      this.openedPrescriptionId = null;
    },
    openRowDetails(row) {
      this.$set(row.item, "_showDetails", true);
      this.openedPrescriptionId = row.item.PrescriptionId;
    },
    async filterList() {
      await this.getPrescriptionList({
        dispenseStartDate: this.dispensedDateRange.from,
        dispenseEndDate: this.dispensedDateRange.to,
        PrescriptionId: this.filterPrescriptionId
          ? this.filterPrescriptionId
          : null,
        "PrescriptionData.EesztId-contains": this.filterEesztId
          ? this.filterEesztId
          : null,
        "PrescriptionData.NeakId": this.filterNeakId ? this.filterNeakId : null,
      });
    },
    async getPrescriptionList(params) {
      await this.getPrescriptionsCount(params);
      await this.getPrescriptionIds(params);
      await this.getPrescriptions(params);
      await this.getInvalidPrescriptions(params);
    },
    async getPrescriptions(params) {
      const getResponse = await MedicalDeviceLogic.getFinanceProducts({
        ...params,
        "-orderbydesc": "PrescriptionId",
        "-offset":
          this.currentPage &&
          this.recipeListSize &&
          this.recipeListSize > this.recipePerPage
            ? (this.currentPage - 1) * this.recipePerPage
            : null,
        "-limit": this.recipePerPage,
      });
      if (!getResponse.Code) {
        this.prescriptionList = getResponse;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          title: "Hiba történt a recept lista betöltése során!",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getPrescriptionsCount(params) {
      const getResponse = await MedicalDeviceLogic.getFinanceProductCount(
        params
      );
      if (!getResponse.Code) {
        this.recipeListSize = getResponse.ElementCount;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          title: "Hiba történt a recept lista léptető betöltése során!",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getPrescriptionIds(params) {
      const getResponse = await MedicalDeviceLogic.getFinanceProductIds(params);
      if (!getResponse.Code) {
        this.prescriptionIds = getResponse;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          title: "Hiba történt a recept lista betöltése során!",
          variant: "danger",
          solid: true,
        });
      }
    },
    async getInvalidPrescriptions(params) {
      const getResponse = await MedicalDeviceLogic.getInvalidFinanceProducts({
        ...params,
        "-orderbydesc": "PrescriptionId",
      });
      if (!getResponse.Code) {
        this.invalidPrescriptionList = getResponse;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          title: "Hiba történt a hibás recept lista betöltése során!",
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  async mounted() {
    await this.getPrescriptionList({
      dispenseStartDate: this.dispensedDateRange.from,
      dispenseEndDate: this.dispensedDateRange.to,
    });
    if (this.$store.state.bever.SelectedPrescriptions) {
      this.selectedPrescriptionIds = JSON.parse(
        JSON.stringify(this.$store.state.bever.SelectedPrescriptions)
      );
    }
  },
};
</script>
<style scoped>
.selected-pres-count-text {
  background: var(--eh-primary-5);
  padding: 5px 10px;
  width: fit-content;
  border-radius: 10px;
  color: white;
  margin: auto;
}
</style>
