<template>
  <div class="">
    <div id="login-box">
      <b-row align-h="center" cols="1">
        <div style="margin-top:100px" @keydown.enter="login" class="eh-login-fields">
          <fp-input
            style="margin: 20px 0px 10px"
            :label="$t('base.usernameEmail')"
            textStyle="light"
            v-model="email"
          />
          <fp-input
            style="margin: 20px 0px 10px"
            :label="$t('base.password')"
            type="password"
            textStyle="light"
            v-model="password"
          />
        </div>
        <button @click="login" class="eh-header-button">
          {{ $t("base.basic.login") }}
        </button>
      </b-row>
    </div>
    <GDPR v-model="showGDPR" @acceptGDPR="acceptGDPR" :legals="legals" />
  </div>
</template>
<script>
/* Logic */
import { AuthLogic } from "@/logic/backend/auth";
import { UserLogic } from "@/logic/backend/user"; /*
import { UserProfileLogic } from "@/logic/backend/user-profile"; */
import { ProjectLogic } from "@/logic/backend/project";
import { LegalLogic } from "@/logic/backend/legal";
/* Components */
import GDPR from "@/components/form/GDPR";

export default {
  name: "Login",
  components: { GDPR },
  data() {
    return {
      email: "",
      password: "",
      selectedRole: "",
      showGDPR: false,
      loggedUserId: null,
      loggedUser: null,
      legals: null,
    };
  },
  methods: {
    //bejelentkezés username és password segítségével
    async login() {
      //token kérés username-password alapján
      const getTokenResponse = await AuthLogic.getTokenByUsernamePassword(
        this.email,
        this.password
      );
      //bejelentkezés sikeres?
      if (!getTokenResponse.Code) {
        this.loggedUserId = getTokenResponse.UserId;
        //TODO: kiemelés külön js filbe, újrahasználás céljából?
        await this.setSignedUser();
        this.getGDPR();
      } else {
        //hibás token kérés hiba üzenete
        alert(
          getTokenResponse.Message
            ? getTokenResponse.Message
            : JSON.stringify(getTokenResponse)
        );
      }
    },
    async getGDPR() {
      const legals = [];
      const globalLegal = await this.getLegals({
        Scope: "Global",
        /* "Roles-contains": this.loggedUser.SelectedRole, */
      });
      if (globalLegal) {
        legals.push(
          globalLegal.filter((l) =>
            l.Roles.includes(this.loggedUser.SelectedRole)
          )
        );
      }
      
      if (this.loggedUser.SelectedProject) {
        const projectLegal = await this.getLegals({
          Scope: "Project",
          ScopeIdentifier: this.loggedUser.SelectedProject.ProjectId,
        });
        if (projectLegal) {
          legals.push(projectLegal);
        }
      }

      this.legals = legals.flat();
      if (this.legals.length) {
        this.showGDPR = true;
      } else {
        UserLogic.saveLoginUser(this.loggedUser);
        this.pushToWebAppModule(this.loggedUser.SelectedRole);
      }
    },
    async getLegals(params) {
      const getLegalResponse = await LegalLogic.getAcceptableStatementLegal(
        params
      );
      if (!getLegalResponse.Code) {
        return getLegalResponse;
      } else {
        //TODO: hiba
      }
    },
    //bejelentkezés után, átirányítás előtti gdpr elfogadása
    async acceptGDPR() {
      UserLogic.saveLoginUser(this.loggedUser);
      this.pushToWebAppModule(this.loggedUser.SelectedRole);
    },
    //bejelentkezett felhasználó beállítása
    async setSignedUser() {
      const baseUserData = await this.getBaseUserData();
      const projects = await this.getProjects();
      /* const eesztProfile = await this.getEesztProfile(); */
      this.loggedUser = {
        Name: baseUserData.Name,
        Roles: baseUserData.Roles,
        Username: baseUserData.Username,
        UserId: baseUserData.UserId,
        Projects: projects,
        /* EesztProfile: eesztProfile, */
      };
      this.selectRole();
      this.selectProject();
    },
    //felhasználó alap adatainak lementése
    async getBaseUserData() {
      const getDataResponse = await UserLogic.getUserData({
        userIds: this.loggedUserId,
      });
      if (!getDataResponse.Code) {
        return getDataResponse[0];
      } else {
        //TODO: hiba
      }
    },
    //felhaználó projektjeinek lementése
    async getProjects() {
      const getProjectsResponse = await ProjectLogic.getProjectDetails({
        UserId: this.loggedUserId,
      });
      if (!getProjectsResponse.Code) {
        return this.getProjectsByRole(getProjectsResponse);
      } else {
        //TODO: hiba
      }
    },
    /* async getEesztProfile() {
      const getProfileResponse = await UserProfileLogic.getUserProfile({
        UserId: this.loggedUserId,
      });
      if (!getProfileResponse.Code) {
        return getProfileResponse.EesztProfile;
      } else {
        //TODO: hiba
      }
      return null;
    }, */
    //felhasználó projektjeinek csoportosítása felület(szepekör) szerint
    getProjectsByRole(projects) {
      const projectsBase = this.getProjectBase(projects);
      const projectByRole = {
        HealthProfessional: [],
        Client: [],
        Partner: [],
        Administrator: projectsBase,
      };
      projectsBase.forEach((p) => {
        if (p.Roles.includes("Doctor")) {
          projectByRole.HealthProfessional.push(p);
        }
        if (p.Roles.includes("Patient")) {
          projectByRole.Client.push(p);
        }
        if (p.Roles.includes("Manager")) {
          projectByRole.Partner.push(p);
        }
      });
      return projectByRole;
    },
    //projektek alap adataira szűrése
    getProjectBase(projects) {
      return projects.map((p) => {
        const own = p.Participants.find((p) => p.UserId == this.loggedUserId);
        console.log("PROJECT", p.ProjectId, "ROLE", own);
        if (own) {
          return {
            ProjectName: p.Research ? p.Research.Name : null,
            ProjectId: p.ProjectId,
            Roles: p.Roles.find((r) => r.RoleName == own.RoleName).BaseRoles,
            StyleSheetId: p.StyleSheetId,
          };
        } else {
          return {
            ProjectName: p.Research ? p.Research.Name : null,
            ProjectId: p.ProjectId,
            Roles: p.Roles,
            StyleSheetId: p.StyleSheetId,
          };
        }
      });
    },
    //felület (szerepkör) kiválasztása
    //TODO: utoljára használt felület választása
    selectRole() {
      console.log(this.loggedUser);
      this.loggedUser.SelectedRole = this.loggedUser.Roles[0];
    },
    //projekt kiválasztása
    //TODO: utoljára megnyitott projekt választása
    selectProject() {
      this.loggedUser.SelectedProject =
        this.loggedUser.Projects[this.loggedUser.SelectedRole][0];
    },
    //tovább léptetés a felület főoldalára
    pushToWebAppModule(role) {
      const homeRouteName = this.$enums.UserRoleWithHome[role].Home;
      this.$router.push({ name: homeRouteName });
    },
  },
  mounted() {
    if (this.$route.name == "Login" && this.$loggedUser) {
      this.pushToWebAppModule(this.$loggedUser.SelectedRole);
    }
  },
};
</script>
<style>
@import "../../styles/syncfusion-radio-button.css";
@import "../../styles/syncfusion-input.css";

#login-box {
  width: 400px;
  margin: auto;
}

@media screen and (max-width: 400px) {
  #login-box {
    width: 90%;
  }

  #login-logo-img {
    width: 90%;
    height: auto !important;
  }
}

#login-logo-img {
  left: 50%;
  position: relative;
  transform: translate(-50%, 0);
  margin: 50px 0px;
  height: 150px;
}

.login-textbox {
  margin-bottom: 15px;
}

.login-textbox-input {
  background: #fff;
}
</style>