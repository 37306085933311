<template>
  <div>
    <fp-app-header
      :hasLangSwitch="true"
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :menuElements="healthMenuElements"
      :projectEnabledUiElements="project ? project.EnabledUiElements : null"
      :enabledMenuElements="
        project && project.EnabledUiElements
          ? project.EnabledUiElements.HealthProfessional
          : null
      "
      @forceUpdate="forceUpdate"
    />
    <router-view
      :key="'views-specialist-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="specialist"
      :project="project"
      @title-changed="changeTitle"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
    />
    <!-- <fp-form-modal
      v-if="$loggedUser.EesztProfile"
      v-model="showEesztOrganizationSelectModal"
      title="Eeszt bejelentkezés"
    >
      <template #content>
        <fp-select
          label="Szevezeti egység"
          :items="$loggedUser.EesztProfile.OrganizationUnits"
          textKey="Nickname"
          valueKey="Nickname"
          v-model="eesztOrganizationNickname"
        />
      </template>
    </fp-form-modal> -->
  </div>
</template>
<script>
/* import { AuthLogic } from "@/logic/backend/auth"; */
import { ProjectLogic } from "@/logic/backend/project";
import Header from "@/components/header-footer/WebAppSpecialistHeader.vue";
export default {
  components: {
    Header,
  },
  props: {
    user: {
      type: Object,
    },
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      profileFillingRate: null,
      forceUpdateKey: 0,
      project: null,
      showEesztOrganizationSelectModal: false,
      eesztOrganizationNickname: null,
      healthMenuElements: [
        {
          url: "PatientList",
          langKey: "healthProfessional.menu.patientList",
          key: "patient-list",
        },
        {
          url: "Project",
          langKey: "healthProfessional.menu.project",
          key: "project",
        },
        {
          url: "Questionnaires",
          langKey: "healthProfessional.menu.questionnaires",
          uiElementName: "Questionnaire",
          key: "questionnaire",
        },
        {
          url: "Documents",
          langKey: "healthProfessional.menu.documents",
          uiElementName: "Document",
          key: "document",
        },
        {
          url: "Workflow",
          langKey: "healthProfessional.menu.workflow",
          uiElementName: "Workflow",
          key: "workflow",
        },
        {
          url: "Tasks",
          langKey: "healthProfessional.menu.tasks",
          uiElementName: "UserTask",
          key: "task",
        },
        {
          url: "Appointments",
          langKey: "healthProfessional.menu.appointments",
          uiElementName: "Appointment",
          key: "appointment",
        },
        {
          url: "Templates",
          langKey: "healthProfessional.menu.templates",
          uiElementName: "NotificationTemplate",
          key: "template",
        },
      ],
    };
  },
  methods: {
    async forceUpdate() {
      ++this.forceUpdateKey;
      await this.getProject();
    },
    async getProject() {
      const selectedProject = this.$loggedUser.SelectedProject;
      if (selectedProject && selectedProject.ProjectId) {
        const getProjectResponse = await ProjectLogic.getProjectDetails({
          ProjectId: selectedProject.ProjectId,
        });
        if (!getProjectResponse.Code) {
          this.project = getProjectResponse[0];
        } else {
          this.$bvToast.toast(getProjectResponse.Message, {
            title: this.$t("requestResponse.basic.errorGetNamedData", {
              name: this.$t("healthProfessional.projects.currentProject"),
            }),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
          this.project = null;
        }
      } else {
        this.project = null;
      }
    },
    changeTitle(newTitle) {
      if (newTitle) {
        this.siteTitle = newTitle;
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      this.userPicture = imgUrl;
    },
    /* async loginEeszt() {
      const body = { OrganizationNickname: this.eesztOrganizationNickname };
      const loginEesztResponse = await AuthLogic.initEeszt(body);
      if (!loginEesztResponse.Code) {
        window.open(
          "http://127.0.0.1:19750" + loginEesztResponse.RidRequestString,
          "_self"
        );
      } else {
        //TODO: hiba
      }
    }, */
  },
  async mounted() {
    await this.getProject();
    //TODO: stored eeszt token check
    /* if (this.$loggedUser.EesztProfile && !this.$eesztToken) {
      if (this.$loggedUser.EesztProfile.OrganizationUnits.length == 1) {
        this.eesztOrganizationNickname = this.$loggedUser.EesztProfile.OrganizationUnits[0].Nickname;
        //await this.loginEeszt();
      } else {
        this.showEesztOrganizationSelectModal = true;
      }
    } */
  },
};
</script>
