import BaseClinSys from "./BaseClinSys";
//import Modul
import Chart from "../Modules/Chart";

export default {
  install(Vue) {
    //Modul use
    Vue.use(Chart);
     //Base use
     Vue.use(BaseClinSys);
  },
};
