<template>
  <b-row v-if="patient" cols="1" cols-md="2">
    <!-- TODO betegválasztó -->
    <b-col>
      <fp-input
        label="Páciens azonosító"
        :required="true"
        v-model="patient.PatientIdentifierValue"
        @change="changeValue('PatientIdentifierValue', $event)"
        :disabled="disabled"
        :state="!validation.hasError('patient.PatientIdentifierValue')"
        :error="validation.firstError('patient.PatientIdentifierValue')"
      />
    </b-col>
    <b-col>
      <code-name-pair
        label="Páciens azonosító típusa"
        :required="true"
        v-model="patient.PatientIdentifierType"
        @changeValue="changeValue('PatientIdentifierType', $event)"
        :disabled="disabled"
        :items="eesztPatientIdentifierTypeList"
        listValueKey="EntryId"
        listTextKey="Name"
      />
    </b-col>
    <b-col>
      <code-name-pair
        label="Páciens állampolgársága"
        v-model="patient.Nationality"
        @changeValue="changeValue('Nationality', $event)"
        :disabled="disabled"
        :items="eesztCountryList"
        listValueKey="EntryId"
        listTextKey="CitizenshipName"
        :searchable="true"
        @search="searchCountry"
      />
    </b-col>
    <b-col>
      <b-row align-v="center">
        <b-col style="margin: 0px; padding: 0px" class="col-auto">
          <fp-date-picker
            label="Páciens születési dátuma"
            description="Vény jelentéshez kötelező mező!"
            :required="true"
            v-model="patient.BirthDate"
            @change="changeValue('BirthDate', $event)"
            :disabled="disabled"
            :state="!validation.hasError('patient.BirthDate')"
            :error="validation.firstError('patient.BirthDate')"
          />
        </b-col>
        <b-col class="col-auto">
          <span style="margin-top: 50px"
            >{{ getPatientAge(prescriptionTimestamp, patient.BirthDate) }}
          </span>
        </b-col>
      </b-row>
    </b-col>
    <b-col>
      <code-name-pair
        label="Páciens neme"
        :required="true"
        v-model="patient.Gender"
        @changeValue="changeValue('Gender', $event)"
        :disabled="disabled"
        :items="eesztSexList"
        listValueKey="EntryId"
        listTextKey="Name"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Páciens neve"
        v-model="patient.FullName"
        @change="changeValue('FullName', $event)"
        :disabled="disabled"
      />
    </b-col>
    <b-col>
      <fp-panel-card
        title="Páciens címe"
        description="EESZT felküldéshez kötelező mező!"
      >
        <template #content>
          <patient-address
            :disabled="disabled"
            v-model="patient.Address"
            @changeValue="changeValue('Address', $event)"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-input
        label="Páciens telefonszáma"
        :disabled="disabled"
        v-model="patient.Phone"
        @change="changeValue('Phone', $event)"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Közgyógyigazolvány"
        :disabled="disabled"
        v-model="patient.PublicHealthIdentifier"
        @change="changeValue('PublicHealthIdentifier', $event)"
      />
    </b-col>
    <b-col>
      <fp-date-picker
        label="Közgyógyigazolvány érvényessége"
        :disabled="disabled"
        v-model="patient.PublicHealthIdentifierValidBy"
        @change="changeValue('PublicHealthIdentifierValidBy', $event)"
      />
    </b-col>
    <b-col>
      <fp-input
        label="Páciens EU nyomtatvány kódja"
        :disabled="disabled"
        v-model="patient.EuDocumentCode"
        @change="changeValue('EuDocumentCode', $event)"
      />
    </b-col>
  </b-row>
</template>
<script>
import moment from "moment";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import CodeNamePair from "./CodeNamePair.vue";
import PatientAddress from "./PatientAddress.vue";
export default {
  name: "FormPatient",
  components: { CodeNamePair, PatientAddress },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
    prescriptionTimestamp: String,
  },
  data() {
    return {
      patient: null,
      changedPatientValue: {},
      defaultPatient: {
        PatientIdentifierValue: null,
        PatientIdentifierType: null,
        Nationality: null,
        BirthDate: null,
        Gender: null,
        FullName: null,
        Address: null,
        Phone: null,
        EuDocumentCode: null,
        PublicHealthIdentifier: null,
      },
      eesztPatientIdentifierTypeList: null,
      eesztCountryList: null,
      eesztSexList: null,
      validDateFilterParams: {
        ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
        ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
      },
    };
  },
  watch: {
    async value() {
      this.setPatient();
      await this.getLists();
    },
    /* patient(input) {
      this.$emit("change", input);
    }, */
  },
  validators: {
    "patient.PatientIdentifierValue": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "patient.PatientIdentifierType": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "patient.BirthDate": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  computed: {
    hasPatientValues() {
      return Object.values(this.patient).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    getPatientAge(prescriptionTimestamp, patientBirthDate) {
      if (prescriptionTimestamp && patientBirthDate) {
        return (
          moment(prescriptionTimestamp).diff(patientBirthDate, "years") +
          " éves"
        );
      } else {
        return "";
      }
    },
    async checkValidation() {
      return await this.$validate();
    },
    resetValidation() {
      this.validation.reset();
    },
    setPatient() {
      /* if (this.value) {
        this.patient = this.value;
      } else {
        this.patient = JSON.parse(JSON.stringify(this.defaultPatient));
      } */
      this.patient = JSON.parse(JSON.stringify(this.defaultPatient));
      if (this.value) {
        Object.assign(this.patient, this.value);
      }
      this.changedPatientValue = null;
      this.resetValidation();
    },
    async searchCountry(input) {
      var list;
      if (input.length > 3) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { ["CitizenshipName-contains"]: input },
          "CitizenshipName",
          "string"
        );
      } else if (input.length == 0) {
        list = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          { "-orderby": "CitizenshipName", ["-limit"]: 10 },
          "CitizenshipName",
          "string"
        );
      } else {
        list = [];
      }

      var selectedValue = [];
      if (this.patient.NationalityId) {
        selectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            EntryId: this.patient.NationalityId,
          }
        );
      }

      this.eesztCountryList = this.checkDuplicates([...list, ...selectedValue]);
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        EntryId: null,
      });
    },
    checkDuplicates(things) {
      return things.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.EntryId === thing.EntryId)
      );
    },
    changeValue(key, value) {
      if (this.hasPatientValues) {
        if (!this.changedPatientValue) {
          this.changedPatientValue = {};
        }
        this.changedPatientValue[key] = value;
        this.$emit("changeValue", this.changedPatientValue);
      } else {
        this.$emit("changeValue", null);
      }
    },
    async getLists() {
      this.eesztSexList = await this.getBase(
        this.$enums.BaseName.eeszt_sex.Value,
        this.validDateFilterParams
      );

      const eesztCountryDefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_citizenship.Value,
        { "-orderby": "CitizenshipName", ["-limit"]: 10 },
        "CitizenshipName",
        "string"
      );
      var eesztCountryListSelectedValue = [];
      if (this.patient.NationalityId) {
        eesztCountryListSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_citizenship.Value,
          {
            EntryId: this.patient.NationalityId,
          }
        );
      }
      this.eesztCountryList = this.checkDuplicates([
        ...eesztCountryDefaultList,
        ...eesztCountryListSelectedValue,
      ]);
      this.eesztCountryList.splice(0, 0, {
        CitizenshipName: "Nincs megadva",
        EntryId: null,
      });

      this.eesztPatientIdentifierTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_patient_identifier_type.Value,
        this.validDateFilterParams,
        "Code",
        "number"
      );
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    this.setPatient();
    await this.getLists();
  },
};
</script>
