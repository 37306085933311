<template>
  <div v-if="research">
    <div v-if="ownProgress" style="margin: 10px">
      <div style="margin:10px 0px">
        {{ $t("healthProfessional.projects.projectTimeProgress") }}:
        <!-- {{ ownProgress }} -->
        <b-progress>
          <b-progress-bar
            :value="ownProgress.DaysPassed"
            :max="ownProgress.TotalDays"
          >
            {{
              ownProgress.DaysPassed > ownProgress.TotalDays
                ? 100
                : (
                    (ownProgress.DaysPassed / ownProgress.TotalDays) *
                    100
                  ).toFixed(0)
            }}%
          </b-progress-bar>
        </b-progress>
      </div>
      <div style="margin:10px 0px">
        <div v-if="ownProgress.OwnProgress">
          {{ $t("healthProfessional.projects.doctorTimeProgress") }}:
          <b-progress>
            <b-progress-bar :value="ownProgress.OwnProgress" :max="1">
              {{ (ownProgress.OwnProgress * 100).toFixed(0) }}%
            </b-progress-bar>
          </b-progress>
        </div>
      </div>
      <div v-if="ownProgress">
        <!-- Bevont betegek -->
        <div style="margin:10px 0px">
          {{ $t("healthProfessional.projects.patientIn") }}:
          <span>{{ ownProgress.SubordinatePatientCount }}</span>
          <span
            v-if="
              ownProgress.SubordinatePatientGoal != null &&
                ownProgress.SubordinatePatientGoal != undefined
            "
          >
            / {{ ownProgress.SubordinatePatientGoal }}</span
          >
          <div v-if="ownProgress.SubordinatePatientGoal">
            <b-progress>
              <b-progress-bar
                :value="ownProgress.SubordinatePatientCount"
                :max="ownProgress.SubordinatePatientGoal"
              >
                {{
                  (
                    (ownProgress.SubordinatePatientCount /
                      ownProgress.SubordinatePatientGoal) *
                    100
                  ).toFixed(0)
                }}%
              </b-progress-bar>
            </b-progress>
          </div>
        </div>
        <!-- Teszt és kontroll -->
        <div v-if="!research.DoctorBlind">
          <!-- Tesztbetegek -->
          <div style="margin:10px 0px">
            {{ $t("healthProfessional.projects.testPatientCount") }}:
            <span>{{ ownProgress.SubordinateTreatmentPatientCount }}</span>
            <span
              v-if="
                ownProgress.SubordinateTreatmentPatientGoal != null &&
                  ownProgress.SubordinateTreatmentPatientGoal != undefined
              "
            >
              / {{ ownProgress.SubordinateTreatmentPatientGoal }}</span
            >
            <div v-if="ownProgress.SubordinateTreatmentPatientGoal">
              <b-progress>
                <b-progress-bar
                  :value="ownProgress.SubordinateTreatmentPatientCount"
                  :max="ownProgress.SubordinateTreatmentPatientGoal"
                >
                  {{
                    (
                      (ownProgress.SubordinateTreatmentPatientCount /
                        ownProgress.SubordinateTreatmentPatientGoal) *
                      100
                    ).toFixed(0)
                  }}%
                </b-progress-bar>
              </b-progress>
            </div>
          </div>
          <!-- Kontroll betegek -->
          <div style="margin:10px 0px">
            <p>
              {{ $t("healthProfessional.projects.controllPatientCount") }}:
              <span>{{ ownProgress.SubordinateControlPatientCount }}</span>
              <span
                v-if="
                  ownProgress.SubordinateControlPatientGoal != null &&
                    ownProgress.SubordinateControlPatientGoal != undefined
                "
              >
                / {{ ownProgress.SubordinateControlPatientGoal }}</span
              >
            </p>
            <div v-if="ownProgress.SubordinateControlPatientGoal">
              <b-progress>
                <b-progress-bar
                  :value="ownProgress.SubordinateControlPatientCount"
                  :max="ownProgress.SubordinateControlPatientGoal"
                >
                  {{
                    (
                      (ownProgress.SubordinateControlPatientCount /
                        ownProgress.SubordinateControlPatientGoal) *
                      100
                    ).toFixed(0)
                  }}%
                </b-progress-bar>
              </b-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table style="width: 100%">
      <tr>
        <td>{{ $t("healthProfessional.projects.projectName") }}:</td>
        <td>
          <b><div v-html="getTextByLanguage(research.Name)"></div></b>
        </td>
      </tr>
      <tr>
        <td>{{ $t("healthProfessional.projects.projectTitle") }}:</td>
        <td>
          <div v-html="getTextByLanguage(research.Description)"></div>
        </td>
      </tr>
      <tr>
        <td>{{ $t("healthProfessional.projects.timestamp") }}:</td>
        <td>
          {{ $convertDateToString(research.StartDate, "YYYY-MM-DD") }} –
          {{ $convertDateToString(research.EndDate, "YYYY-MM-DD") }}
        </td>
      </tr>
      <tr>
        <td>{{ $t("healthProfessional.projects.state") }}:</td>
        <td>{{ $t($enums.ProjectStatus[state].Text) }}</td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("healthProfessional.projects.projectGoal") }}:</td>
        <td>
          <div v-html="getTextByLanguage(research.ResearchGoal)"></div>
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("healthProfessional.projects.blind") }}:</td>
        <td>
          {{
            research.DoctorBlind
              ? $t("healthProfessional.projects.doubleBlind")
              : research.PatientBlind
              ? $t("healthProfessional.projects.simpleBlind")
              : $t("healthProfessional.projects.noneBlind")
          }}
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("healthProfessional.projects.patientIn") }}:</td>
        <td>
          {{ $t("healthProfessional.projects.testGroup") }}:
          {{
            research.TreatmentParticipantCount +
              " " +
              $t("healthProfessional.projects.person")
          }}<br />
          {{ $t("healthProfessional.projects.controllGroup") }}:
          {{
            research.ControlParticipantCount +
              " " +
              $t("healthProfessional.projects.person")
          }}
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("healthProfessional.projects.selectionCriteria") }}:</td>
        <td>
          <div v-html="getTextByLanguage(research.SelectionCriteria)"></div>
        </td>
      </tr>
      <tr style="border-top: solid">
        <td>{{ $t("healthProfessional.projects.description") }}:</td>
        <td>
          <div v-html="getTextByLanguage(research.ResearchPlan)"></div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "DataSheet",
  props: {
    research: Object,
    state: String,
    patientCount: Number,
    ownProgress: Object,
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
  },
};
</script>
<style scoped>
table tr td {
  padding: 10px 50px 10px 10px;
}
</style>
