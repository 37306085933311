<!-- Version 2.0.0 -->
<template>
  <fp-table-list
    :items="items"
    :fields="itemFields ? itemFields : appointmentFields"
    :hasPagination="true"
    :limitedDataLoad="true"
    @nextStackLoad="$emit('nextStackLoad')"
    :sortBy.sync="listOrderBy"
    :sortDesc.sync="listOrderDesc"
  >
    <template #Title="row">
      {{ $getLanguagedText(row.item.Title, row.item.Languages) }}
    </template>
    <template #Description="row">
      {{ $getLanguagedText(row.item.Description, row.item.Languages) }}
    </template>
    <template #StartTime="row">
      <b-icon-clock-fill
        v-b-tooltip.hover="$t('healthProfessional.appointment.inProgress')"
        v-if="isInProgress(row.item.StartTime, row.item.EndTime)"
      ></b-icon-clock-fill>
      {{ $convertDateToString(row.item.StartTime, "YYYY-MM-DD HH:mm") }}
    </template>
    <template #EndTime="row">
      {{ $convertDateToString(row.item.EndTime, "YYYY-MM-DD HH:mm") }}
    </template>
    <template #MaxVisitorCount="row">
      {{ row.item.MaxVisitorCount - row.item.Visitors.length }} /
      {{
        $t("healthProfessional.appointment.person", {
          count: row.item.MaxVisitorCount,
        })
      }}
    </template>
    <template #Location="row">
      {{
        row.item.LocationType == "Online"
          ? row.item.LocationType
          : row.item.Location
      }}
    </template>
    <template #ApplyToAppointment="row">
      <b-iconstack
        class="h1"
        style="cursor: pointer"
        v-b-tooltip.hover="$t('client.appointment.cancellation')"
        variant="success"
        @click="applyToAppointment(row.item.AppointmentId)"
      >
        <b-icon stacked icon="person-plus-fill" scale="0.5"></b-icon>
        <b-icon stacked icon="circle"></b-icon>
      </b-iconstack>
    </template>
    <template #RemoveFromAppointment="row">
      <b-iconstack
        class="h1"
        style="cursor: pointer"
        v-b-tooltip.hover="$t('client.appointment.cancellation')"
        variant="danger"
        @click="removeFromAppointment(row.item.AppointmentId)"
      >
        <b-icon stacked icon="person-x-fill" scale="0.5"></b-icon>
        <b-icon stacked icon="circle"></b-icon>
      </b-iconstack>
    </template>
    <template #VisitorManager="row">
      <!-- add visitor -->
      <b-iconstack
        class="h1"
        style="cursor: pointer"
        @click="$emit('addVisitor', row.item)"
        v-if="row.item.MaxVisitorCount - row.item.Visitors.length > 0"
        v-b-tooltip.hover="$t('healthProfessional.appointment.addParticipant')"
      >
        <b-icon stacked icon="person-plus-fill" scale="0.5"></b-icon>
        <b-icon stacked icon="circle"></b-icon>
      </b-iconstack>
      <!-- visitors -->
      <b-iconstack
        class="h1"
        style="cursor: pointer"
        @click="openAppointmentDetails(row)"
        v-if="row.item.Visitors.length > 0 && !row.item._showDetails"
        v-b-tooltip.hover="$t('healthProfessional.appointment.participants')"
      >
        <b-icon stacked icon="person-lines-fill" scale="0.5"></b-icon>
        <b-icon stacked icon="circle"></b-icon>
      </b-iconstack>
      <!-- close -->
      <b-icon
        @click="$set(row.item, '_showDetails', false)"
        v-if="row.item._showDetails"
        style="cursor: pointer"
        class="h1"
        icon="x-circle-fill"
        v-b-tooltip.hover="$t('base.basic.close')"
      ></b-icon>
      <!-- clear -->
      <b-iconstack
        @click="clearAppointment(row.item.AppointmentId)"
        v-if="row.item.Visitors.length > 0 && !row.item._showDetails"
        v-b-tooltip.hover="$t('healthProfessional.appointment.clear')"
        class="h2"
        style="
          color: #ff0000;
          cursor: pointer;
          margin-left: 10px;
          margin-bottom: 12px;
        "
        rotate="45"
      >
        <b-icon stacked icon="circle"></b-icon>
        <b-icon stacked icon="circle" scale="0.78"></b-icon>
        <b-icon stacked icon="circle" scale="0.88"></b-icon>
        <b-icon stacked icon="dash" scale="1.4" shift-v="-0.6"></b-icon>
        <b-icon stacked icon="dash" scale="1.35" shift-v="0.6"></b-icon>
      </b-iconstack>
    </template>
    <template #row-details="row">
      <b>{{ $t("healthProfessional.appointment.participants") }}:</b>
      <!-- <p>
          TODO: érkező API bekötés (userid tömb alapján vissza kapom a user alap
          adatokat)
        </p> -->
      <table>
        <tr>
          <td>{{ $t("healthProfessional.appointment.view") }}</td>
          <td>{{ $t("healthProfessional.appointment.participantsData") }}</td>
          <td></td>
        </tr>
        <tr v-for="visitor in row.item.Visitors" :key="visitor.UserId">
          <td style="text-align: center">
            <b-check v-model="visitor.Appeared"></b-check>
          </td>
          <td>
            {{
              visitor.Name +
              ", TAJ: " +
              visitor.Ssn +
              ", Születési dátum: " +
              $convertDateToString(visitor.DateOfBirth, "YYYY-MM-DD")
            }}
          </td>
          <td>
            <b-icon
              @click="deleteVisitor(row.item.AppointmentId, visitor)"
              style="cursor: pointer"
              v-b-tooltip.hover="
                $t('healthProfessional.appointment.removeParticipant')
              "
              icon="x"
              class="h1"
              variant="danger"
            ></b-icon>
          </td>
        </tr>
      </table>
      <b-button
        @click="setPresentedVisitor(row.item.Visitors, row.item.AppointmentId)"
        >{{ $t("healthProfessional.appointment.saveView") }}</b-button
      >
    </template>
  </fp-table-list>
</template>
<script>
import moment from "moment";
import { AppointmentLogic } from "../../Logic/Backend/appointment";
import { UserLogic } from "../../Logic/Backend/user";
export default {
  name: "AppointmentList",
  props: {
    items: Array,
    itemFields: Array,
    itemsOrderBy: String,
    itemsOrderDesc: Boolean,
  },
  data() {
    return {
      listOrderBy: this.itemsOrderBy,
      listOrderDesc: this.itemsOrderDesc,
      appointmentFields: [
        {
          key: "Title",
          label: this.$t("healthProfessional.appointment.title"),
          /* sortable: true, */
        },
        {
          key: "Description",
          label: this.$t("healthProfessional.appointment.description"),
        },
        {
          key: "StartTime",
          label: this.$t("healthProfessional.appointment.startTime"),
          /* sortable: true, */
        },
        {
          key: "EndTime",
          label: this.$t("healthProfessional.appointment.endTime"),
          /* sortable: true, */
        },
        {
          key: "MaxVisitorCount",
          label: this.$t("healthProfessional.appointment.visitorsCount"),
          /* sortable: true, */
        },
        {
          key: "Location",
          label: this.$t("healthProfessional.appointment.location"),
        },
        { key: "VisitorManager", label: "" },
      ],
    };
  },
  watch: {
    listOrderBy(input) {
      this.$emit("update:itemsOrderBy", input);
    },
    itemsOrderBy(input) {
      this.listOrderBy = input;
    },
    listOrderDesc(input) {
      this.$emit("update:itemsOrderDesc", input);
    },
    itemsOrderDesc(input) {
      this.listOrderDesc = input;
    },
  },
  methods: {
    isInProgress(start, end) {
      return moment(start).isBefore(this.now) && moment(end).isAfter(this.now);
    },
    async openAppointmentDetails(row) {
      this.$set(row.item, "_showDetails", true);
      const assignedVisitorsData = await this.getVisitorBaseData(
        row.item.Visitors
      );
      this.$set(row.item, "Visitors", assignedVisitorsData);
    },
    async getVisitorBaseData(visitors) {
      const visitorsIds = visitors.map((v) => v.UserId);
      const getBaseResponse = await UserLogic.getUserData({
        userIds: visitorsIds,
      });
      if (!getBaseResponse.Code) {
        var baseData = [];
        visitors.forEach((v) => {
          const base = getBaseResponse.find((b) => b.UserId == v.UserId);
          baseData.push({ ...base, ...v });
        });
        return baseData;
      } else {
        this.$bvToast.toast(getBaseResponse.Message, {
          title: 'Hiba történt a felhasználó lista betöltése során!',
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
        return null;
      }
    },
    async setPresentedVisitor(visitors, appointmentId) {
      const presentVisitors = visitors
        .filter((v) => v.Appeared)
        .map((v) => v.UserId);
      const setPresentResponse = await AppointmentLogic.setPresentAppointment(
        appointmentId,
        { PresentVisitorIds: presentVisitors }
      );
      if (!setPresentResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(setPresentResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async clearAppointment(appointmentId) {
      //TODO: globális szótár bevezetés --> appointment.clearAppointmentConfirm
      if (
        confirm("Biztosan törölni szeretné az összes résztvevőt az időpontról?")
      ) {
        const clearAppointmentResponse = await AppointmentLogic.clearAppointment(
          appointmentId
        );
        if (!clearAppointmentResponse.Code) {
          this.$emit("refreshList");
          this.$bvToast.toast(
            this.$t("requestResponse.appointment.successClear"),
            {
              title: this.$t("requestResponse.basic.successSave"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
        } else {
          this.$bvToast.toast(clearAppointmentResponse.Message, {
            title: this.$t("requestResponse.appointment.errorClear"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async deleteVisitor(appointmentId, visitor) {
      //TODO: globális szótár bevezetés --> appointment.deleteVisitorConfirm
      if (
        confirm(
          "Biztosan törölni szeretné " +
            visitor.Name +
            " nevű résztvevőt az időpontról?"
        )
      ) {
        const deleteVisitorResponse = await AppointmentLogic.deleteVisitorFromAppointment(
          appointmentId,
          {
            VisitorId: visitor.UserId,
          }
        );
        if (!deleteVisitorResponse.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.appointment.successVisitorDelete"),
            {
              title: this.$t(
                "requestResponse.appointment.successVisitorDeleteMessage"
              ),
              variant: "info",
              solid: true,
            }
          );
          this.$emit("refreshList");
        } else {
          //hiba
          this.$bvToast.toast(deleteVisitorResponse.Message, {
            title: this.$t("requestResponse.appointment.errorVisitorDelete"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async applyToAppointment(appointmentId) {
      const applyToAppointmentResponse = await AppointmentLogic.addVisitorToAppointment(
        appointmentId,
        {
          VisitorId: this.$loggedUser.UserId,
        }
      );
      if (!applyToAppointmentResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.appointment.successApply"),
          {
            title: this.$t("requestResponse.appointment.successApplyMessage"),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(applyToAppointmentResponse.Message, {
          title: this.$t("requestResponse.appointment.errorApply"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async removeFromAppointment(appointmentId) {
      const applyToAppointmentResponse = await AppointmentLogic.deleteVisitorFromAppointment(
        appointmentId,
        {
          VisitorId: this.$loggedUser.UserId,
        }
      );
      if (!applyToAppointmentResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.appointment.successCancellation"),
          {
            title: this.$t(
              "requestResponse.appointment.successCancellationMessage"
            ),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(applyToAppointmentResponse.Message, {
          title: this.$t("requestResponse.appointment.errorCancellation"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
};
</script>
