<template>
  <div class="eh-menu-content">
    <div>
      <b-row cols="1" cols-md="2">
        <!-- Résztvevő kezelő sáv -->
        <div class="col-xl-3 col-md-4" style="margin: 0px 0px 25px">
          <fp-participant-manager
            :project="project"
            :managerType="
              $enums.SystemParameters.ParticipantManagerType.Partner
            "
            :refreshingList.sync="refreshingList"
            @changedSelectedParticipant="selectParticipant"
          />
        </div>
        <!-- Kiválasztott beteg adatai -->
        <div class="col-xl-9 col-md-8" v-if="selectedParticipant">
          <fp-tabs :tabs="participantCardComponents">
            <template #title="Component">{{ Component.Title }}</template>
            <template #content="Component">
              <component
                v-if="
                  Component.ComponentName == 'profile' &&
                  selectedParticipant.BaseRoles.includes('Manager')
                "
                :is="'fp-participant-partner-profile'"
                :participant="selectedParticipant"
                :project="project"
                @refreshList="refreshList"
              />
              <component
                v-else-if="
                  Component.ComponentName == 'profile' &&
                  selectedParticipant.BaseRoles.includes('Doctor')
                "
                :is="'fp-participant-health-prof-profile'"
                :participant="selectedParticipant"
                :project="project"
                @refreshList="refreshList"
              />
              <component
                v-else
                :is="Component.ComponentName"
                :participant="selectedParticipant"
                :project="project"
                @refreshList="refreshList"
              />
            </template>
          </fp-tabs>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    project: Object,
  },
  data() {
    return {
      participantCardComponents: [
        {
          ComponentName: "profile",
          Title: this.$t(
            "partner.participantList.participantTabs.personalData"
          ),
        },
        {
          ComponentName: "fp-participant-workflow",
          Title: this.$t("partner.participantList.participantTabs.workflow"),
        },
        {
          ComponentName: "fp-participant-document",
          Title: this.$t("partner.participantList.participantTabs.documents"),
        },
        {
          ComponentName: "fp-participant-questionnaire",
          Title: this.$t(
            "partner.participantList.participantTabs.questionnaires"
          ),
        },
        {
          ComponentName: "fp-participant-communication",
          Title: this.$t(
            "partner.participantList.participantTabs.communication"
          ),
        },
        /*{
          ComponentName: "SelectedParticipantProjects",
          Title: this.$t("partner.participantList.participantTabs.projects"),
        },*/
      ],
      selectedParticipant: null,
      refreshingList: false,
    };
  },
  watch: {
    async project() {
      this.refreshingList = true;
    },
  },
  methods: {
    selectParticipant(newParticipant) {
      this.selectedParticipant = newParticipant;
    },
    refreshList() {
      this.refreshingList = true;
    },
  },
  //első betöltés kör fut le
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.patientList")
    );
  },
};
</script>
