<template>
  <div>
    <fp-app-header
      :hasLangSwitch="true"
      :signedName="signedName"
      :siteTitle="siteTitle"
      :siteColor="siteColor"
      :signedProfilePicture="userPicture"
      :changedProfileFillingRate="profileFillingRate"
      :menuElements="clientMenuElements"
      :enabledMenuElements="enabledMenuElements"
    />
    <router-view
      :key="'views-client-routerview-' + forceUpdateKey"
      style="width: 95%; margin: auto"
      name="client"
      @forceUpdate="forceUpdate"
      @title-changed="changeTitle"
      @color-change="colorChange"
      @signed-user-name="signedUserNameChange"
      @picture-changed="changeUserPicture"
      @profile-filling-rate-changed="changeProfileFillingRate"
    />
  </div>
</template>
<script>
import { ProjectLogic } from "@/logic/backend/project";
import { UserPreferencesLogic } from "@/logic/backend/user-preferences";
export default {
  props: {
    user: Object,
  },
  data() {
    return {
      signedName: null,
      siteTitle: null,
      siteColor: null,
      userPicture: null,
      profileFillingRate: null,
      forceUpdateKey: 0,
      enabledMenuElements: null,
      clientMenuElements: [
        //TODO: notification jelző beállítása (notificationCount)
        {
          url: "Notifications",
          langKey: "client.menu.notifications",
          uiElementName: "NotificationTemplate",
          key: "notification",
          //badge:notificationCount
        },
        {
          url: "Programs",
          langKey: "client.menu.programs",
          key: "program",
        },
        {
          url: "Documents",
          langKey: "client.menu.documents",
          uiElementName: "Document",
          key: "document",
        },
        {
          url: "QuestionnaireList",
          langKey: "client.menu.questionnaires",
          uiElementName: "Questionnaire",
          key: "questionnaire",
        },
        {
          url: "Tasks",
          langKey: "client.menu.tasks",
          uiElementName: "UserTask",
          key: "task",
        },
        {
          url: "Appointments",
          langKey: "client.menu.appointments",
          uiElementName: "Appointment",
          key: "appointment",
        },
      ],
    };
  },
  methods: {
    forceUpdate() {
      ++this.forceUpdateKey;
    },
    changeTitle(newTitle) {
      if (newTitle) {
        this.siteTitle = newTitle;
      }
    },
    colorChange(newColor) {
      this.siteColor = null;
      if (newColor) {
        this.siteColor = newColor;
      }
    },
    signedUserNameChange(newName) {
      if (newName) {
        this.signedName = newName;
      }
    },
    changeUserPicture(imgUrl) {
      this.userPicture = imgUrl;
    },
    changeProfileFillingRate(fillingRate) {
      //console.log("RouterView.vue")
      //console.log(fillingRate)
      if (fillingRate) {
        this.profileFillingRate = fillingRate;
      }
    },
    async getProjects() {
      const getProjectsResponse = await ProjectLogic.getProjectDetails({
        UserId: this.$loggedUser.UserId,
      });
      if (!getProjectsResponse.Code) {
        this.enabledMenuElements = [];
        getProjectsResponse.forEach((p) => {
          this.enabledMenuElements = this.enabledMenuElements.concat(
            p.EnabledUiElements.Client
          );
        });
      }
    },
  },
  async mounted() {
    await this.getProjects();
  },
  beforeDestroy() {
    UserPreferencesLogic.deleteUserPreferencesFromStorage();
  },
};
</script>
