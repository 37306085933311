import PrescriptionDataVue from "./list-cells/PrescriptionData.vue";
import PrescriptionDispenseDataVue from "./list-cells/PrescriptionDispenseData.vue";
import PrescriptionIdsVue from "./list-cells/PrescriptionIds.vue";
import PrescriptionProductsVue from "./list-cells/PrescriptionProducts.vue";

export default {
  install(Vue) {
    Vue.component("fp-prescription-list-cell-base", PrescriptionDataVue);
    Vue.component("fp-prescription-list-cell-dispense", PrescriptionDispenseDataVue);
    Vue.component("fp-prescription-list-cell-ids", PrescriptionIdsVue);
    Vue.component("fp-prescription-list-cell-products", PrescriptionProductsVue);
  },
};
