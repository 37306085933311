<template>
  <fp-form-modal
    size="xl"
    title="ESSZT recept kiadás rögzítés"
    v-model="visible"
    v-if="dispense"
    :closable="false"
  >
    <template #content>
      <fp-input
        label="Az átadás-átvétel elektronikus bizonylat"
        v-model="dispense.HandoverReceipt"
      />
      <fp-panel-card
        style="margin-top: 10px"
        title="Kiváltott termékek"
        :required="true"
      >
        <template #content>
          <fp-panel-card
            style="margin: 20px 0px"
            v-for="(product, index) in dispense.Products"
            :key="'new-dispnese-details-product-' + index"
            :title="index + 1 + '. termék'"
            :closedCard="index != 0"
            :isShowAddButton="true"
            :isShowDeleteButton="dispense.Products.length > 1"
            @addNewElement="addNewProductToRecipe(index)"
            @deleteElement="deleteProductFromRecipe(index)"
          >
            <template #content>
              <dispense-product
                ref="dispenseProduct"
                v-model="dispense.Products[index]"
                :prescriptionProducts="prescription.Products"
                :prescriptionProduct="prescription.Products[index]"
                :patientAge="patientAge"
              />
            </template>
          </fp-panel-card>
        </template>
      </fp-panel-card>
      <b-button @click="saveDispenseDetails">Mentés</b-button>
      <b-button @click="visible = false">Mégsem</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
//TODO: felesleges változók eltüntetése
import moment from "moment";
import DispenseProduct from "../Components/create/DispenseProduct";
export default {
  components: { DispenseProduct },
  name: "NewDispenseDetailsForm",
  model: {
    prop: "modalVisibile",
    event: "change",
  },
  props: {
    modalVisibile: Boolean,
    prescription: Object,
  },
  data() {
    return {
      visible: this.modalVisibile,
      productsCount: 0,
      productIds: [0],
      dispense: null,
      defaultDispense: {
        HandoverReceipt: null,
        Products: [null],
      },
      patientAge: null,
    };
  },
  watch: {
    modalVisibile(input) {
      this.visible = input;
      if (input) {
        this.setNewDispenseDetailsData();
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    addNewProductToRecipe(index) {
      this.productsCount++;
      this.productIds.splice(index + 1, 0, this.productsCount);
      this.dispense.Products.splice(index + 1, 0, null);
    },
    deleteProductFromRecipe(index) {
      this.productIds.splice(index, 1);
      this.dispense.Products.splice(index, 1);
    },
    async saveDispenseDetails() {
      const valid = await Promise.all(
        this.$refs["dispenseProduct"].map((p) => {
          return p.checkValidator();
        })
      );
      if (!valid.some((x) => !x)) {
        this.$emit("saveDetails", this.dispense);
      }
    },
    setNewDispenseDetailsData() {
      this.dispense = JSON.parse(JSON.stringify(this.defaultDispense));
      this.dispense.Products.length = this.prescription.Products.length;
      this.patientAge = this.getPatientAge(
        this.prescription.PrescriptionTimestamp,
        this.prescription.Patient.BirthDate
      );
    },
    getPatientAge(prescriptionTimestamp, patientBirthDate) {
      if (prescriptionTimestamp && patientBirthDate) {
        return moment(prescriptionTimestamp).diff(patientBirthDate, "years");
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.modalVisibile) {
      this.setNewDispenseDetailsData();
    }
  },
};
</script>
