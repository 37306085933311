var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fp-form-modal',{attrs:{"title":_vm.$t('partner.participantList.tag.newTag')},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('fp-select',{attrs:{"type":"multi","label":_vm.$t('partner.participantList.tag.language'),"items":Object.values(_vm.$enums.Languages),"valueKey":"Value","textKey":"Text"},scopedSlots:_vm._u([{key:"element",fn:function(e){return [_c('img',{staticStyle:{"margin":"0px 10px"},attrs:{"src":require('@/assets' + e.flag),"width":"20px"}}),_vm._v(" "+_vm._s(e.Text)+" ")]}}]),model:{value:(_vm.tagLanguages),callback:function ($$v) {_vm.tagLanguages=$$v},expression:"tagLanguages"}}),_vm._l((_vm.tagLanguages),function(lang){return _c('fp-input',{key:lang,attrs:{"label":_vm.$t('partner.participantList.tag.tagName') +
        ' (' +
        _vm.$enums.Languages[lang].Text +
        ')',"required":true,"error":_vm.validation.firstError('newTag.Name'),"state":!_vm.validation.hasError('newTag.Name')},model:{value:(_vm.newTag.Name[lang]),callback:function ($$v) {_vm.$set(_vm.newTag.Name, lang, $$v)},expression:"newTag.Name[lang]"}})}),_c('fp-input',{attrs:{"label":_vm.$t('partner.participantList.tag.tagColor'),"type":"color"},model:{value:(_vm.newTag.ColorCode),callback:function ($$v) {_vm.$set(_vm.newTag, "ColorCode", $$v)},expression:"newTag.ColorCode"}}),(
        !_vm.newTagType &&
        (_vm.$loggedUser.SelectedRole == 'Partner' ||
          _vm.$loggedUser.SelectedRole == 'Administrator')
      )?_c('b-form-radio-group',{staticStyle:{"margin-bottom":"20px"},attrs:{"id":"radio-group-tag","name":"radio-sub-component"},model:{value:(_vm.newTag.Type),callback:function ($$v) {_vm.$set(_vm.newTag, "Type", $$v)},expression:"newTag.Type"}},[_c('b-form-radio',{attrs:{"value":"ProjectTag"}},[_vm._v(_vm._s(_vm.$t("partner.participantList.tag.project")))]),_c('b-form-radio',{attrs:{"value":"PatientTag"}},[_vm._v(_vm._s(_vm.$t("partner.participantList.tag.own")))])],1):_vm._e(),_c('b-button',{on:{"click":_vm.saveNewTag}},[_vm._v(_vm._s(_vm.$t("base.basic.save")))])]},proxy:true}]),model:{value:(_vm.showNewTagForm),callback:function ($$v) {_vm.showNewTagForm=$$v},expression:"showNewTagForm"}})}
var staticRenderFns = []

export { render, staticRenderFns }