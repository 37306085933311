<template>
  <fp-form-modal v-model="visible" size="xl" title="Jelentés megtekintése">
    <template #content>
      <div v-if="report">
        <fp-panel-card title="Jelentés alapadatai">
          <template #content>
            <fp-date-picker
              :disabled="!reportEditable"
              label="Kezdő dátum"
              v-model="report.ReportHeader.StartDate"
            />
            <fp-date-picker
              :disabled="!reportEditable"
              label="Vége dátum"
              v-model="report.ReportHeader.EndDate"
            />
            <fp-text-area
              :disabled="!reportEditable"
              label="Leírás"
              v-model="report.ReportHeader.Description"
            />
            <fp-select
              :disabled="!reportEditable"
              label="Állapot"
              v-model="report.ReportHeader.State"
              :items="reportStatus"
              valueKey="Value"
              textKey="Text"
            />
            <fp-input
              :disabled="!reportEditable"
              label="Tranzakciós azonosító"
              v-model="report.ReportHeader.TransactionId"
            />
            <b-button v-if="!reportEditable" @click="reportEditable = true">
              Szerkesztés
            </b-button>
            <b-button v-if="reportEditable" @click="saveReportHeader">
              Mentés
            </b-button>
            <b-button v-if="reportEditable" @click="cancelReportHeader">
              Mégsem
            </b-button>
          </template>
        </fp-panel-card>
        <div style="margin: 20px 0px; font-weight: 500; font-size: 20px">
          Teljes összeg:
          {{ report.ReportHeader.ReportSumFinancialInfo.TotalPrice }} Ft,
          Támogatás összege:
          {{ report.ReportHeader.ReportSumFinancialInfo.Subsidization }} Ft,
          Térítésidíj:
          {{ report.ReportHeader.ReportSumFinancialInfo.ChargeFee }} Ft, Kvázi
          térítésidíj:
          {{ report.ReportHeader.ReportSumFinancialInfo.QuasiChargeFee }} Ft
        </div>
        <fp-panel-card style="margin-top: 20px" title="Lejelentett receptek">
          <template #content>
            <div>
              <b-icon
                class="h2"
                style="cursor: pointer"
                v-b-tooltip.hover="'Recept hozzáadása a jelentéshez'"
                icon="plus-circle-fill"
                @click="showPrescriptionAddToReportModal = true"
              />
              <b-icon
                class="h2"
                style="cursor: pointer"
                v-b-tooltip.hover="'Sztornó hozzáadása a jelentéshez'"
                icon="plus-circle-fill"
                variant="danger"
                @click="showCancellationAddToReportModal = true"
              />
              <add-prescription
                v-model="showPrescriptionAddToReportModal"
                :reportId="reportId"
                @refreshList="filterList"
                prescriptionType="Normal"
              />
              <add-prescription
                v-model="showCancellationAddToReportModal"
                :reportId="reportId"
                @refreshList="filterList"
                prescriptionType="Cancellation"
              />
            </div>
            <div style="display: flex">
              <fp-select
                style="width: 250px; margin-right: 15px"
                label="NEAK jogcím kód"
                :items="neakLegalTypeList"
                v-model="filterNeakLegalType"
                textKey="Name"
                valueKey="EntryId"
                @change="filterList"
              />
              <fp-select
                style="width: 250px"
                label="NEAK forgalmi kód"
                :items="neakFinancialCodeList"
                v-model="filterNeakFinancialCode"
                textKey="Name"
                valueKey="EntryId"
                @change="filterList"
              />
            </div>
            <fp-pagination
              v-if="recipeListSize > 10"
              :itemsPerPage.sync="recipePerPage"
              :listSize.sync="recipeListSize"
              :currentPageNumber.sync="currentPage"
            />
            <fp-table-list
              :items="report.Prescriptions"
              :fields="reportPrescripitonFields"
            >
              <template #Data="row">
                <div>
                  <b-iconstack
                    font-scale="2"
                    style="margin: 5px"
                    v-b-tooltip.hover="'Orvos pecsétszám'"
                  >
                    <b-icon stacked icon="person-fill" scale="1.2"></b-icon>
                    <b-icon
                      stacked
                      icon="plus"
                      style="color: white"
                      scale="0.7"
                      shift-v="-4"
                    ></b-icon>
                  </b-iconstack>
                  {{ row.item.DoctorStampNumber }}
                </div>
                <div>
                  <b-iconstack
                    font-scale="2"
                    style="margin: 5px"
                    v-b-tooltip.hover="'Páciens azonosítószáma'"
                  >
                    <b-icon stacked icon="person-lines-fill"></b-icon>
                  </b-iconstack>
                  {{ row.item.PatientSsn }}
                </div>
                <div>
                  <b-iconstack
                    font-scale="2"
                    style="margin: 5px"
                    v-b-tooltip.hover="'Páciens születési dátuma'"
                  >
                    <b-icon
                      stacked
                      icon="person-lines-fill"
                      scale="0.7"
                      shift-v="-1.5"
                    ></b-icon>
                    <b-icon stacked icon="calendar"></b-icon>
                  </b-iconstack>
                  {{
                    $convertDateToString(
                      row.item.PatientBirthDate,
                      "YYYY-MM-DD"
                    )
                  }}
                </div>
                <div>
                  <b-iconstack
                    font-scale="2"
                    style="margin: 5px"
                    v-b-tooltip.hover="'Recept felírás dátuma'"
                  >
                    <b-icon
                      stacked
                      icon="pencil-fill"
                      scale="0.5"
                      shift-v="-1.5"
                    ></b-icon>
                    <b-icon stacked icon="calendar"></b-icon>
                  </b-iconstack>
                  {{
                    $convertDateToString(
                      row.item.PrescriptionCreateDate,
                      "YYYY-MM-DD"
                    )
                  }}
                </div>
                <div>
                  <b-iconstack
                    font-scale="2"
                    style="margin: 5px"
                    v-b-tooltip.hover="'Recept kiváltás dátuma'"
                  >
                    <b-icon
                      stacked
                      icon="box-arrow-right"
                      scale="0.5"
                      shift-v="-1.5"
                    ></b-icon>
                    <b-icon stacked icon="calendar"></b-icon>
                  </b-iconstack>
                  {{
                    $convertDateToString(
                      row.item.PrescriptionRedeemDate,
                      "YYYY-MM-DD HH:mm"
                    )
                  }}
                </div>
              </template>
              <template #Products="row">
                <div
                  v-for="(product, index) in row.item.Products"
                  :key="'prescription-products' + index"
                >
                  <div style="display: flex">
                    <fp-svg-icon
                      icon="medical-product"
                      color="black"
                      style="background: transparent; margin: 0px"
                    />
                    <div class="m-1">
                      <div style="font-size: 20px">
                        {{
                          product.DispensedTtt
                            ? product.DispensedTtt.Name
                            : product.PrescribedTtt
                            ? product.PrescribedTtt.Name
                            : null
                        }}
                        <div style="font-weight: 500">
                          {{
                            product.DispensedQuantity + " egységnyi mennyiség"
                          }}
                        </div>
                      </div>
                      <div style="font-size: 15px">
                        {{
                          product.DispensedTtt
                            ? "(TTT kód: " + product.DispensedTtt.Code + ")"
                            : product.PrescribedTtt
                            ? "(TTT kód: " + product.PrescribedTtt.Code + ")"
                            : ""
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template #Details="row">
                <b-button
                  v-if="!row.item._showDetails"
                  @click="$set(row.item, '_showDetails', true)"
                >
                  Nyit
                </b-button>
                <b-button
                  v-if="row.item._showDetails"
                  @click="$set(row.item, '_showDetails', false)"
                >
                  Zár
                </b-button>
                <b-icon-trash-fill
                  variant="danger"
                  class="h4"
                  @click="deletePrescription(row.item.PrescriptionEntryId)"
                />
              </template>
              <template #row-details="row">
                <fp-table-list
                  :items="row.item.Products"
                  :fields="reportPrescriptionProductFields"
                >
                  <template #BaseData="product">
                    <div style="display: flex">
                      <fp-svg-icon
                        icon="medical-product"
                        color="black"
                        style="background: transparent; margin: 0px"
                      />
                      <div class="m-1">
                        <div style="font-size: 20px">
                          {{
                            product.item.DispensedTtt
                              ? product.item.DispensedTtt.Name
                              : product.item.PrescribedTtt
                              ? product.item.PrescribedTtt.Name
                              : null
                          }}
                          <div style="font-weight: 500">
                            {{
                              product.item.DispensedQuantity +
                              " egységnyi mennyiség"
                            }}
                          </div>
                        </div>
                        <div style="font-size: 15px">
                          {{
                            product.item.DispensedTtt
                              ? "(TTT kód: " +
                                product.item.DispensedTtt.Code +
                                ")"
                              : product.item.PrescribedTtt
                              ? "(TTT kód: " +
                                product.item.PrescribedTtt.Code +
                                ")"
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template #FinanceData="product">
                    <div>
                      <b-iconstack font-scale="2" style="margin-right: 5px">
                        <b-icon stacked icon="cash"></b-icon>
                      </b-iconstack>
                      <u>Finanszírozási adatok:</u>
                    </div>
                    <div>
                      {{
                        product.item.MagistralFeeClass
                          ? product.item.MagistralFeeClass.Name
                          : ""
                      }}
                    </div>
                    <div>
                      {{
                        product.item.FinancialCode
                          ? product.item.FinancialCode.Name
                          : ""
                      }}
                    </div>
                    <div>
                      {{
                        product.item.LegalType
                          ? product.item.LegalType.Name
                          : ""
                      }}
                    </div>
                    <div>
                      <div>
                        <b-iconstack font-scale="2" style="margin-right: 5px">
                          <b-icon stacked icon="cash"></b-icon>
                        </b-iconstack>
                        <u>Finanszírozási árak:</u>
                      </div>
                      <div>
                        Termék bruttó értéke:
                        <b>{{ product.item.GrossProductPrice }} Ft</b>
                      </div>
                      <div>
                        Termék NEAK bruttó értéke:
                        <b>{{ product.item.NeakGrossProductPrice }} Ft</b>
                      </div>
                      <div>
                        Termék NEAK bruttó támogatási értéke:
                        <b>{{ product.item.NeakGrossSubsidization }} Ft</b>
                      </div>
                      <div>
                        Termék térírésidíja:
                        <b>{{ product.item.PayablePrice }} Ft</b>
                      </div>
                      <div>
                        Termék kvázi térírésidíja:
                        <b>{{ product.item.QuasiPayablePrice }} Ft</b>
                      </div>
                    </div>
                  </template>
                </fp-table-list>
              </template>
            </fp-table-list>
          </template>
        </fp-panel-card>
      </div>
    </template>
  </fp-form-modal>
</template>
<script>
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";
import AddPrescription from "./AddPrescription.vue";

export default {
  components: { AddPrescription },
  name: "ReportFormModal",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    reportId: Number,
  },
  data() {
    return {
      visible: this.value,
      report: null,
      reportPrescripitonFields: [
        { key: "PrescriptionEntryId", label: "Recept azonosító" },
        { key: "Data", label: "Recept finanszírozás alapadatai" },
        { key: "Products", label: "Kiadott termékek" },
        { key: "Details", label: "" },
      ],
      reportPrescriptionProductFields: [
        { key: "BaseData", label: "Alap adatok" },
        { key: "FinanceData", label: "Fizetési adatok" },
      ],
      reportStatus: Object.values(this.$enums.Prescription.NeakReportStatus),
      reportEditable: false,
      showPrescriptionAddToReportModal: false,
      showCancellationAddToReportModal: false,
      recipeListSize: null,
      recipePerPage: 10,
      currentPage: 1,
      filterNeakLegalType: null,
      filterNeakFinancialCode: null,
      neakFinancialCodeList: null,
      neakLegalTypeList: null,
    };
  },
  watch: {
    visible(input) {
      this.$emit("change", input);
    },
    async value(input) {
      this.visible = input;
      if (input) {
        await this.getReportWithCount();
        await this.getFilterLists();
      }
    },
    async currentPage() {
      await this.filterList();
    },
    async recipePerPage() {
      await this.filterList();
    },
    recipeListSize(input) {
      if (input > this.recipePerPage) {
        this.currentPage = 1;
      }
    },
  },
  methods: {
    async filterList() {
      var params = {};
      if (this.filterNeakLegalType) {
        params.neakLegalTypeId = this.filterNeakLegalType;
      }
      if (this.filterNeakFinancialCode) {
        params.neakFinancialCodeId = this.filterNeakFinancialCode;
      }
      await this.getReportWithCount(params);
    },
    async cancelReportHeader() {
      await this.getReportWithCount();
      this.reportEditable = false;
    },
    async saveReportHeader() {
      const body = {
        Description: this.report.ReportHeader.Description,
        State: this.report.ReportHeader.State,
        TransactionId: this.report.ReportHeader.TransactionId,
        StartDate: this.report.ReportHeader.StartDate,
        EndDate: this.report.ReportHeader.EndDate,
      };
      const putResponse = await MedicalDeviceLogic.setFinanceReportHeader(
        this.reportId,
        body
      );
      if (!putResponse.Code || putResponse.Code == 0) {
        this.$bvToast.toast("Sikeresen mentette a változásokat!", {
          variant: "success",
          title: "Sikeres mentés!",
          solid: true,
        });
        await this.getReportWithCount();
        this.reportEditable = false;
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(putResponse.Message, {
          variant: "danger",
          title: "Hiba történt a változások mentése során!",
          solid: true,
        });
      }
    },
    async getReportWithCount(params) {
      await this.getReportCount(params);
      await this.getReport({
        ...params,
        "-orderby": "PrescriptionRedeemDate",
        "-offset":
          this.currentPage &&
          this.recipeListSize &&
          this.recipeListSize > this.recipePerPage
            ? (this.currentPage - 1) * this.recipePerPage
            : null,
        "-limit": this.recipePerPage,
      });
    },
    async getReport(params) {
      if (this.reportId) {
        const getResponse = await MedicalDeviceLogic.getReportWithPrescriptions(
          this.reportId,
          params
        );
        if (!getResponse.Code) {
          this.report = getResponse;
        } else {
          this.$bvToast.toast(getResponse.Message, {
            variant: "danger",
            title: "Hiba történt a jelentés betöltése során!",
            solid: true,
          });
        }
      }
    },
    async getReportCount(params) {
      if (this.reportId) {
        const getResponse = await MedicalDeviceLogic.getReportWithPrescriptionsCount(
          this.reportId,
          params
        );
        if (!getResponse.Code) {
          this.recipeListSize = getResponse.ElementCount;
        } else {
          this.$bvToast.toast(getResponse.Message, {
            variant: "danger",
            title: "Hiba történt a jelentés lapozójának a betöltése során!",
            solid: true,
          });
        }
      }
    },
    async deletePrescription(prescriptionId) {
      if (confirm("Biztosan eltávolítja a receptet a jelentésről?")) {
        const deleteResponse = await MedicalDeviceLogic.deletePrescriptionFromReport(
          this.reportId,
          prescriptionId
        );
        if (!deleteResponse.Code || deleteResponse.Code == 0) {
          this.$bvToast.toast(
            "Sikeresen törölte a " +
              prescriptionId +
              " azonosítójú receptet a " +
              this.reportId +
              " azonosítójú jelentésről!",
            {
              variant: "info",
              title: "Sikeres törlés!",
              solid: true,
            }
          );
          await this.filterList();
        } else {
          this.$bvToast.toast(deleteResponse.Message, {
            variant: "danger",
            title:
              "Hiba történt a " +
              prescriptionId +
              " azonosítójú recept törlése során!",
            solid: true,
          });
        }
      }
    },
    async getFilterLists() {
      const neakLegalType = await this.getBase("neak_legal_type");
      if (neakLegalType) {
        this.neakLegalTypeList = [
          { EntryId: null, Name: "Nincs megadva" },
          ...neakLegalType,
        ];
      } else {
        this.neakLegalTypeList = [
          { EntryId: null, Name: "Hiba történt a lista betöltése során!" },
        ];
      }

      const neakFinancialCode = await this.getBase("neak_financial_code");
      if (neakFinancialCode) {
        this.neakFinancialCodeList = [
          { EntryId: null, Name: "Nincs megadva" },
          ...neakFinancialCode,
        ];
      } else {
        this.neakFinancialCodeList = [
          { EntryId: null, Name: "Hiba történt a lista betöltése során!" },
        ];
      }
    },
    async getBase(baseName, params) {
      const getResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getResponse.Code) {
        return getResponse;
      } else {
        return false;
      }
    },
  },
  async mounted() {
    if (this.visible) {
      await this.getReportWithCount();
      await this.getFilterLists();
    }
  },
  beforeDestroy() {
    this.currentPage = 1;
  },
};
</script>
