<template>
  <div class="patients page-background">
    <div>
      <b-row cols="1" cols-md="2">
        <div class="col-xl-3 col-md-4" style="margin: 12px 0px 25px">
          <b-list-group
            v-if="projectRoles"
            style="
              max-height: 500px;
              overflow: auto;
              margin-top: 10px;
              position: relative;
            "
          >
            <b-list-group-item
              style="
                background: orange;
                text-align: center;
                color: white;
                position: sticky;
                top: 0;
                z-index: 100;
              "
              @click="createNewRole"
              href="#"
              >Új szerepkör felvétele</b-list-group-item
            >
            <b-list-group-item v-if="projectRoles && projectRoles.length == 0"
              >Nincs megjeleníthető szerepkör</b-list-group-item
            >
            <b-list-group-item
              href="#"
              @click="selectRole(role)"
              v-for="(role, index) in projectRoles"
              :key="index"
              :active="role.Active"
            >
              <template>
                {{ role.RoleData.RoleName }}
                <div style="font-size: 13px; opacity: 0.8">
                  {{
                    "(" +
                      role.RoleData.BaseRoles.map((role) => {
                        return $t($enums.ProjectBaseRole[role].Text);
                      }) +
                      ")"
                  }}
                </div>
              </template>
            </b-list-group-item>
          </b-list-group>
        </div>
        <!-- Kiválasztott role adatai -->
        <div
          class="col-xl-9 col-md-8"
          style="margin-top: 25px"
          v-if="editableSelectedRole"
        >
          <fp-panel-card style="background: white">
            <template #content>
              <fp-input
                label="Szerepkör név kulcs"
                :disabled="!isNewRole"
                v-model="editableSelectedRole.RoleData.RoleName"
              />
              <fp-select
                label="Szerepkör"
                :disabled="!isEditor"
                type="multi"
                valueKey="Value"
                :textKeyConverter="
                  (e) => {
                    return $t(e.Text);
                  }
                "
                v-model="editableSelectedRole.RoleData.BaseRoles"
                :items="Object.values($enums.ProjectBaseRole)"
              >
                <template #element="element">
                  {{ $t(element.Text) }}
                </template>
              </fp-select>
              <fp-select
                label="Jogosultságok"
                :disabled="!isEditor"
                type="multi"
                valueKey="Value"
                v-model="editableSelectedRole.RoleData.Permissions"
                :items="Object.values($enums.ProjectPermission)"
                :textKeyConverter="
                  (e) => {
                    return $t(e.Text);
                  }
                "
              >
                <template #element="element">
                  {{ $t(element.Text) }}
                </template>
              </fp-select>
              <fp-select
                label="Feladatok"
                :disabled="!isEditor"
                type="multi"
                valueKey="Value"
                v-model="editableSelectedRole.RoleData.Parameters"
                :items="Object.values($enums.ProjectParameter)"
                :textKeyConverter="
                  (e) => {
                    return $t(e.Text);
                  }
                "
              >
                <template #element="element">
                  {{ $t(element.Text) }}
                </template>
              </fp-select>
              <!-- <fp-panel-card title="Feladatok">
                <template #content>
                  <div
                    v-for="(task, index) in editableSelectedRole.RoleData.Tasks"
                    :key="task.TaskId"
                    style="margin: 10px 0px"
                  >
                    <fp-panel-card
                      :isShowAddButton="
                        isEditor &&
                        editableSelectedRole.RoleData.Tasks.length <
                          Object.values($enums.ProjectTaskType).length
                      "
                      :isShowDeleteButton="isEditor"
                      @addNewElement="addRoleTask(index)"
                      @deleteElement="deleteRoleTask(index)"
                    >
                      <template #content>
                        <fp-select
                          label="Feladat típusa"
                          :disabled="!isEditor"
                          v-model="task.TaskData.Type"
                          valueKey="Value"
                          :items="Object.values($enums.ProjectTaskType)"
                          :textKeyConverter="
                            (e) => {
                              return $t(e.Text);
                            }
                          "
                        >
                          <template #element="element">
                            {{ $t(element.Text) }}
                          </template>
                        </fp-select>
                        <fp-input
                          label="Feladat teljesítés száma"
                          :disabled="!isEditor"
                          v-model="task.TaskData.TargetValue"
                        />
                      </template>
                    </fp-panel-card>
                  </div>
                  <div
                    v-if="
                      isEditor &&
                      editableSelectedRole.RoleData.Tasks &&
                      !editableSelectedRole.RoleData.Tasks.length
                    "
                    @click="addRoleTask(0)"
                    class="add-button"
                  >
                    <b-icon
                      style="margin: 0px"
                      class="h2"
                      icon="plus-circle"
                    ></b-icon>
                  </div>
                  <div
                    v-else-if="
                      !isEditor &&
                      editableSelectedRole.RoleData.Tasks &&
                      !editableSelectedRole.RoleData.Tasks.length
                    "
                  >
                    Nincs megjeleníthető feladat a szerepkörhöz
                  </div>
                </template>
              </fp-panel-card> -->
              <div style="margin-top: 10px">
                <b-button v-if="!isEditor" @click="isEditor = true"
                  >Szerkesztés</b-button
                >
                <b-button v-if="isEditor" @click="saveEditRole">
                  Mentés
                </b-button>
                <b-button v-if="isEditor" @click="cancelEditRole"
                  >Mégsem</b-button
                >
              </div>
            </template>
          </fp-panel-card>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
import { ProjectLogic } from "@/logic/backend/project";
export default {
  name: "ProjectRole",
  data() {
    return {
      projectRoles: null,
      showNewRole: null,
      selectedProjectRole: null,
      editableSelectedRole: null,
      tempIndex: 0,
      newRole: {
        RoleData: {
          RoleName: null,
          BaseRoles: null,
          Permissions: null,
          Parameters: null,
          /* Tasks: [], */
        },
      },
      isNewRole: false,
      emptyTaskCard: {
        TaskData: {
          Type: null,
          TargetValue: null,
        },
        TaskId: null,
      },
      isEditor: false,
    };
  },
  /* watch: {
    selectedProjectRole() {
      this.tempIndex = 0;
    },
  }, */
  methods: {
    async getProject() {
      if (this.$loggedUser.SelectedProject) {
        const getProjectResponse = await ProjectLogic.getProjectDetails({
          ProjectId: this.$loggedUser.SelectedProject.ProjectId,
        });
        if (!getProjectResponse.Code) {
          this.projectRoles = getProjectResponse[0].Roles.map((role, index) => {
            return {
              RoleData: role,
              Active: false,
              Route: "Roles[" + index + "]",
            };
          });
        } else {
          this.$bvToast.toast(getProjectResponse.Message, {
            title: this.$t("requestResponse.project.errorGet"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    createNewRole() {
      this.newRole.TaskId = ++this.tempIndex;
      this.newRole.Route = "Roles[" + this.projectRoles.length + "]";
      this.selectRole(this.newRole);
      this.isEditor = true;
      this.isNewRole = true;
    },
    selectRole(role) {
      this.isEditor = false;
      this.isNewRole = false;
      //volt előzőleg kiválasztott szerepkör?
      if (this.selectedProjectRole) {
        //előzőleg kiválasztott szerepkör Active jelzőjét false-ra állítjuk
        this.$set(this.selectedProjectRole, "Active", false);
      }
      //az újonnan kiválasztott szerepkör tárolása
      this.selectedProjectRole = role;

      this.editableSelectedRole = JSON.parse(
        JSON.stringify(this.selectedProjectRole)
      );
      /* this.editableSelectedRole.RoleData.Tasks = role.RoleData.Tasks.map(
        (task) => {
          return { TaskData: task, TaskId: ++this.tempIndex };
        }
      ); */
      //újonnan kiválasztott szerepkör Active jelzőjét true-ra állítjuk
      this.$set(this.selectedProjectRole, "Active", true);
    },
    addRoleTask(index) {
      const temp = JSON.parse(JSON.stringify(this.emptyTaskCard));
      temp.TaskId = ++this.tempIndex;
      this.editableSelectedRole.RoleData.Tasks.splice(index + 1, 0, temp);
    },
    deleteRoleTask(index) {
      this.editableSelectedRole.RoleData.Tasks.splice(index, 1);
    },
    async saveEditRole() {
      /* const tasks = this.editableSelectedRole.RoleData.Tasks.map((task) => {
        return task.TaskData;
      }); */
      const edittedRole = JSON.parse(
        JSON.stringify(this.editableSelectedRole.RoleData)
      );
      /* edittedRole.Tasks = tasks; */
      const body = {
        [this.editableSelectedRole.Route]: edittedRole,
      };
      const saveEditRoleResponse = await ProjectLogic.setProject(
        body,
        this.$loggedUser.SelectedProject.ProjectId
      );
      if (!saveEditRoleResponse.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("requestResponse.basic.successSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          }
        );
        this.isEditor = false;
        await this.getProject();
        var temp = this.projectRoles.find(
          (role) =>
            role.RoleData.RoleName == this.selectedProjectRole.RoleData.RoleName
        );
        if (temp) {
          this.selectRole(temp);
        }
      } else {
        this.$bvToast.toast(saveEditRoleResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    cancelEditRole() {
      this.editableSelectedRole = JSON.parse(
        JSON.stringify(this.selectedProjectRole)
      );
      /* this.editableSelectedRole.RoleData.Tasks =
        this.selectedProjectRole.RoleData.Tasks.map((task) => {
          return { TaskData: task, TaskId: ++this.tempIndex };
        }); */
      if (!this.isNewRole) {
        this.isEditor = false;
      }
    },
  },
  async mounted() {
    await this.getProject();
    this.$store.dispatch(
      "setPageTitle","Projekt szerepkörök");
  },
};
</script>
<style scoped>
.add-button {
  background: var(--eh-primary-2);
  color: var(--eh-primary-5);
  border: solid 3px var(--eh-primary-5);
  border-radius: 6px;
  padding: 10px;
  width: fit-content;
  cursor: pointer;
}
</style>
