import { EhssClient, RequestMethod } from "@/backend/ehss-client";
import { RequestResponseLogic } from "@/logic/ui/request-response";

const ATTACHMENT = "/document/attachment";

export const AttachmentLogic = {
  async downloadAttachment(attachmentId, params) {
    let result = await EhssClient.sendRequest(
      RequestMethod.GET,
      ATTACHMENT + "/" + attachmentId,
      params
    );
    const responseBody = await RequestResponseLogic.checkRequestResponse(
      result,
      false
    );
    return responseBody.Data;
  },
  async uploadAttachment(file, typeName, entityId, params) {
    let result = await EhssClient.sendRequestWithFile(
      RequestMethod.POST,
      ATTACHMENT + "/" + typeName + "/" + entityId,
      params,
      file
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
  async deleteAttachment(attachmentId, params) {
    let result = await EhssClient.sendRequest(
      RequestMethod.DELETE,
      ATTACHMENT + "/" + attachmentId,
      params
    );
    return await RequestResponseLogic.checkRequestResponse(result);
  },
};
