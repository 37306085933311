<template>
  <fp-form-modal v-model="visible" title="Felhasználó felvétele a projektbe">
    <template #content>
      <fp-select
        label="Felhasználó"
        :items="userList"
        v-model="projectUser"
        :searchable="true"
        @search="filterUserList"
      >
        <template #element="e">
          <div>Név: {{ e.Name ? e.Name : "Nincs jogosulsága lekérdezni" }}</div>
          <div>Felhasználó név: {{ e.Username }}</div>
        </template>
      </fp-select>
      <fp-select
        label="Projekt szerepkör"
        :items="projectSpecificRoles"
        v-model="participantRole"
        textKey="RoleName"
      />
      <participant-parameters
        :role="participantRole"
        v-model="participantParameters"
      />
      <b-button @click="createParticipant">Kapcsolat létrehozása</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { ProjectLogic } from "../../Logic/Backend/project";
import { UserLogic } from "../../Logic/Backend/user";
import ParticipantParameters from "./Components/ParticipantParameters.vue";
export default {
  components: { ParticipantParameters },
  name: "CreateProjectParticipant",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    project: Object,
    baseRoles: Array,
  },
  data() {
    return {
      userList: null,
      visible: this.value,
      projectUser: null,
      projectRoles: null,
      participantRole: null,
      participantParameters: null,
    };
  },
  computed: {
    projectSpecificRoles() {
      if (this.baseRoles && this.project && this.project.Roles) {
        return this.project.Roles.filter((e) => {
          var isFiltered = false;
          this.baseRoles.forEach((role) => {
            if (e.BaseRoles.includes(role)) {
              isFiltered = true;
            }
          });
          return isFiltered;
        });
      } else {
        return null;
      }
    },
  },
  watch: {
    async value(input) {
      this.visible = input;
      if (input) {
        this.projectUser = null;
        this.participantRole = null;
        this.userList = await this.getUserList({
          "-orderby": "Username",
          "-limit": 10,
        });
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    async filterUserList(input) {
      if (input) {
        if (input.length > 2) {
          this.userList = await this.getUserList({
            "-orderby": "Username",
            "-limit": 10,
            multiValue: input,
          });
        } else {
          this.userList = [];
        }
      } else {
        this.userList = await this.getUserList({
          "-orderby": "Username",
          "-limit": 10,
        });
      }
    },
    async getUserList(params) {
      const getResponse = await UserLogic.getUserData(params);
      if (!getResponse.Code) {
        return getResponse;
      } else {
        //TODO: hiba
      }
    },
    async createParticipant() {
      const body = [
        {
          UserId: this.projectUser.UserId,
          RoleName: this.participantRole ? this.participantRole.RoleName : null,
          SuperiorIds: [this.$loggedUser.UserId],
          TagIds: [],
          Parameters: this.participantParameters,
          Remark: "",
        },
      ];

      const createResponse = await ProjectLogic.createParticipant(
        this.project.ProjectId,
        body
      );
      if (!createResponse.Code) {
        this.$emit("selectParticipant", this.projectUser);
        this.visible = false;
        this.projectUser = null;
        this.$bvToast.toast("Sikeresen felvette a felhasználót a projektbe!", {
          title: "Sikeres felvétel",
          variant: "success",
          solid: true,
        });
      } else {
        this.$bvToast.toast(createResponse.Message, {
          title: "Sikertelen felvétel",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    if (this.visible) {
      this.userList = await this.getUserList({
        "-orderby": "Username",
        "-limit": 10,
      });
    }
  },
};
</script>
