import Vue from "vue";
import App from "@/App.vue";
import router from "@/router.js";
import store from "@/store.js";
//import i18n from "@/locale/index.js";

//import { Enums as FPenums } from "@/logic/ui/enums";
import i18n from "../../../Plugins/Locale";
import Tmkk from "../../../Plugins/Products/Tmkk";

Vue.use(Tmkk);

import { TextBoxPlugin } from "@syncfusion/ej2-vue-inputs";
import { ButtonPlugin, RadioButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import {
  DropDownListComponent,
  DropDownListPlugin,
} from "@syncfusion/ej2-vue-dropdowns";
import { RichTextEditorPlugin } from "@syncfusion/ej2-vue-richtexteditor";
import { GridPlugin } from "@syncfusion/ej2-vue-grids";
import { AccumulationChartPlugin } from "@/../node_modules/@syncfusion/ej2-vue-charts/accumulation-chart.d.ts";
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { CheckBoxPlugin } from "@syncfusion/ej2-vue-buttons";
import VuejsClipper from "vuejs-clipper";
import { enableRipple } from "@syncfusion/ej2-base";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { SplitButtonPlugin } from "@syncfusion/ej2-vue-splitbuttons";
import { SwitchPlugin } from "@syncfusion/ej2-vue-buttons";

import FormSchemaBuilder from "@/components/form-schema/FormSchemaBuilder.vue";
import FormSchemaInputs from "@/components/form-schema/FormSchemaInputs.vue";

import Input from "@/components/custom-components/form/Input.vue";
import Select from "@/components/custom-components/form/Select.vue";
import Slider from "@/components/custom-components/form/Slider.vue";
import DatePicker from "@/components/custom-components/form/DatePicker.vue";
import TimePicker from "@/components/custom-components/form/TimePicker.vue";
import DateTimePicker from "@/components/custom-components/form/DateTimePicker.vue";
import DateRangeButton from "@/components/custom-components/form/DateRangeButton.vue";
import ToggleSwitch from "@/components/custom-components/form/ToggleSwitch.vue";
import TextArea from "@/components/custom-components/form/TextArea.vue";
import Checkbox from "@/components/custom-components/form/Checkbox.vue";
import Button from "@/components/custom-components/form/Button.vue";
import Tabs from "@/components/custom-components/Tabs.vue";
import Tab from "@/components/custom-components/Tab.vue";
import PanelCard from "@/components/custom-components/PanelCard.vue";
import BoubleModalView from "@/components/custom-components/BoubleModalView.vue";
import FormModal from "@/components/custom-components/FormModal";
import TableList from "@/components/custom-components/TableList";
import ConfirmNotification from "@/components/custom-components/ConfirmNotification";

// import '@/styles/bootstrap.css'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import { BootstrapVueIcons } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

import SimpleVueValidation from "simple-vue-validator";

// Markdown Editor
import VueMarkdownEditor from "@kangc/v-md-editor";
import VMdPreview from "@kangc/v-md-editor/lib/preview";
import "@kangc/v-md-editor/lib/style/preview.css";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";
import enUS from "@kangc/v-md-editor/lib/lang/en-US";

Vue.use(SimpleVueValidation);

Vue.use(BootstrapVueIcons);
Vue.config.productionTip = false;
Vue.prototype.Window = window;

// Markdown Editor Config
// Documentation: https://code-farmer-i.github.io/vue-markdown-editor/
VueMarkdownEditor.use(vuepressTheme);
VueMarkdownEditor.lang.use("en-US", enUS);
VMdPreview.use(vuepressTheme);

Vue.use(VueMarkdownEditor);
Vue.use(VMdPreview);
Vue.use(SwitchPlugin);
Vue.use(SplitButtonPlugin);
Vue.use(ButtonPlugin);
Vue.use(DatePickerPlugin);
Vue.use(AccumulationChartPlugin);
Vue.use(GridPlugin);
Vue.use(RadioButtonPlugin);
Vue.use(TextBoxPlugin);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(RichTextEditorPlugin);
Vue.use(VuejsClipper);
Vue.use(MultiSelectPlugin);
Vue.use(CheckBoxPlugin);

Vue.component(DropDownListPlugin.name, DropDownListComponent);

Vue.component("form-schema-inputs", FormSchemaInputs);
Vue.component("form-schema-builder", FormSchemaBuilder);

Vue.component("fp-input", Input);
Vue.component("fp-select", Select);
Vue.component("fp-text-area", TextArea);
Vue.component("fp-checkbox", Checkbox);

Vue.component("fp-button", Button);
Vue.component("fp-date-picker", DatePicker);
Vue.component("fp-time-picker", TimePicker);
Vue.component("fp-date-time-picker", DateTimePicker);
Vue.component("fp-date-range-button", DateRangeButton);
Vue.component("fp-toggle-switch", ToggleSwitch);
Vue.component("fp-slider", Slider);
Vue.component("fp-tabs", Tabs);
Vue.component("fp-tab", Tab);
Vue.component("fp-panel-card", PanelCard);
Vue.component("fp-bouble-modal", BoubleModalView);
Vue.component("fp-form-modal", FormModal);
Vue.component("fp-table-list", TableList);
Vue.component("fp-confirm-notification", ConfirmNotification);

Vue.prototype.$validator = SimpleVueValidation.Validator;
Vue.prototype.$loggedUser;
//Vue.prototype.$enums = FPenums;
Vue.prototype.$eesztToken;

enableRipple(true);

export const eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
