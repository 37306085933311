<template>
  <div>
    <b-button style="margin-top: 10px" @click="$router.go(-1)">{{
      $t("base.basic.back")
    }}</b-button>
    <form-schema-builder
      v-if="selectedQuestionnaire"
      :instance="selectedQuestionnaire"
      :disabled="isSelectedQuestionnaireIsFilled"
      @closeForm="closeQuestionaire"
    />
  </div>
</template>
<script>
import { FormLogic } from "@/logic/backend/form";
export default {
  name: "Questionnaire",
  data() {
    return {
      questionnaireId: null,
      selectedQuestionnaire: null,
      isSelectedQuestionnaireIsFilled: false,
    };
  },
  methods: {
    //mentés nélül visszalépés a kérdőív listába
    closeQuestionaire() {
      this.$router.push({ name: "WebAppClientQuestionnaireList" });
    },
    //kiválasztott kérdőív adat kérés
    async getSelectesQuestionannaire() {
      //kérés indítás
      const questionnaireListResponse = await FormLogic.getFormInstance({
        FormInstanceId: this.questionnaireId,
      });
      //sikeres kérés?
      if (!questionnaireListResponse.Code) {
        //kapott lista első elemének tárolása
        this.selectedQuestionnaire = questionnaireListResponse[0];
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: this.$t("requestResponse.questionnaire.errorGet"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      if (this.selectedQuestionnaire) {
        //a kérdőív kitöltött érték tárolása
        this.isSelectedQuestionnaireIsFilled = ![
          this.$enums.QuestionnaireStatus.InProgress.Value,
        ].includes(this.selectedQuestionnaire.Status);
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kérdőív id tárolás
    const taskParams = this.$route.query.taskParams;
    if (taskParams) {
      this.questionnaireId = JSON.parse(taskParams).FormInstanceId;
    } else {
      this.questionnaireId = this.$route.query.Id;
    }
    //nincs kérdőív id
    if (!this.questionnaireId) {
      //nincs, visszaküld a listába
      this.$router.push({ name: "WebAppClientQuestionnaireList" });
    }
    //oldal címének beállítása
    this.$store.dispatch(
      "setPageTitle",
      this.$t("client.questionnaires.questionnaire.title")
    );
    //kiválasztott kérdőív betöltése
    await this.getSelectesQuestionannaire();
  },
};
</script>
