<template>
  <fp-form-modal
    :title="$t('partner.participantList.tag.newTag')"
    v-model="showNewTagForm"
  >
    <template #content>
      <fp-select
        type="multi"
        :label="$t('partner.participantList.tag.language')"
        :items="Object.values($enums.Languages)"
        v-model="tagLanguages"
        valueKey="Value"
        textKey="Text"
      >
        <template #element="e">
          <img
            :src="require('@/assets' + e.flag)"
            style="margin: 0px 10px"
            width="20px"
          />
          {{ e.Text }}
        </template>
      </fp-select>
      <fp-input
        v-for="lang in tagLanguages"
        :key="lang"
        :label="
          $t('partner.participantList.tag.tagName') +
          ' (' +
          $enums.Languages[lang].Text +
          ')'
        "
        :required="true"
        v-model="newTag.Name[lang]"
        :error="validation.firstError('newTag.Name')"
        :state="!validation.hasError('newTag.Name')"
      />
      <fp-input
        :label="$t('partner.participantList.tag.tagColor')"
        type="color"
        v-model="newTag.ColorCode"
      />
      <b-form-radio-group
        v-if="
          !newTagType &&
          ($loggedUser.SelectedRole == 'Partner' ||
            $loggedUser.SelectedRole == 'Administrator')
        "
        style="margin-bottom: 20px"
        id="radio-group-tag"
        v-model="newTag.Type"
        name="radio-sub-component"
      >
        <b-form-radio value="ProjectTag">{{
          $t("partner.participantList.tag.project")
        }}</b-form-radio>
        <b-form-radio value="PatientTag">{{
          $t("partner.participantList.tag.own")
        }}</b-form-radio>
      </b-form-radio-group>
      <b-button @click="saveNewTag">{{ $t("base.basic.save") }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { TagLogic } from "@/logic/backend/tag";
import { ProjectLogic } from "@/logic/backend/project";
export default {
  name: "NewTagForm",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
    projectId: Number,
    newTagType: String,
  },
  data() {
    return {
      showNewTagForm: this.visible,
      newTag: {
        Type: this.newTagType ? this.newTagType : "PatientTag",
        Name: {},
        ColorCode: "#00FF00",
        OwnerId: this.$loggedUser.UserId,
      },
      defaultTag: {
        Type: this.newTagType ? this.newTagType : "PatientTag",
        Name: {},
        ColorCode: "#00FF00",
        OwnerId: this.$loggedUser.UserId,
      },
      tagLanguages: [],
    };
  },
  validators: {
    "newTag.Name": function (value) {
      return this.$validator.value(value).required();
    },
  },
  watch: {
    visible(input) {
      this.showNewTagForm = input;
    },
    showNewTagForm(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    getTextByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    async saveNewTag() {
      const valid = await this.$validate();
      if (valid) {
        const saveNewTagResponse = await TagLogic.createTag(this.newTag);
        if (!saveNewTagResponse.Code) {
          //sikeres
          this.$emit("getList");
          this.newTag = this.defaultTag;
          this.validation.reset();
          this.showNewTagForm = false;
          this.$bvToast.toast(this.$t("requestResponse.basic.successSave"), {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          });
          if (this.projectId) {
            await this.addToProject(saveNewTagResponse.TagId);
          }
        } else {
          //hiba
          this.$bvToast.toast(saveNewTagResponse.Message, {
            title: this.$t("requestResponse.basic.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    async addToProject(tagId) {
      const body = [
        {
          EntityTypeName: "FpTag",
          EntityId: tagId,
        },
      ];
      const addToProjectResponse = await ProjectLogic.setItem(
        body,
        this.projectId
      );
      if (!addToProjectResponse.Code) {
        //sikeres üzi
        // TODO: szótár
        this.$bvToast.toast("Sikeresen hozzárendelte a projekthez a címkét!", {
          title: "Sikeres hozzárendelés!",
          variant: "success",
          solid: true,
        });
      } else {
        //hiba
        // TODO: szótár
        this.$bvToast.toast(addToProjectResponse.Message, {
          title: "Hiba történt a címke projekthez rendelése során!",
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
};
</script>
