<template>
  <div class="ekg-diary page-background">
    <h1>{{ $t("base.basic.underDevelopment") }}</h1>
  </div>
</template>
<script>
export default {
  mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t("module.diaries.ekg.title"));
  },
};
</script>
<style>
.ekg-diary {
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>