<template>
  <div class="family page-background">
    <h1>Fejlesztés alatt</h1>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
      siteTitle: 'Család'
    }
  },
  mounted() {
    this.$emit('title-changed', this.siteTitle)
  }
}
</script>
<style>
.family{
  text-align: center;
  padding: 50px 0px 100px 0px;
}
</style>