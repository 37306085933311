<template>
  <b-row v-if="product" cols="1" cols-md="2">
    <b-col>
      <div>
        <code-name-pair
          label="Felírás jogcíme"
          :required="true"
          :disabled="disabled"
          @changeValue="changeValue('PrescriptionTitle', $event)"
          v-model="product.PrescriptionTitle"
          :items="eesztLegalTypeList"
          listValueKey="EntryId"
          listTextKey="Name"
          :state="!validation.hasError('product.PrescriptionTitle')"
          :error="validation.firstError('product.PrescriptionTitle')"
        />
      </div>
    </b-col>
    <b-col>
      <code-name-pair
        label="Felírt termék TTT kód és név"
        description="A TTT kód vagy az ISO kód megadása kötelező!"
        :disabled="disabled"
        v-model="product.Ttt"
        :state="!validation.hasError('product.Ttt')"
        :error="validation.firstError('product.Ttt')"
        @changeValue="
          changeProductTtt($event);
          changeValue('Ttt', $event);
          $event && $event.Id
            ? changeValue(
                'ProductName',
                eesztMedicalDeviceList.find((x) => x.EntryId == $event.Id).Name
              )
            : changeValue('ProductName', null);
        "
        :items="eesztMedicalDeviceList"
        listValueKey="EntryId"
        :searchable="true"
        @search="searchMedicalDevice"
      >
        <template #element="e">
          <div>{{ e.Name }}</div>
          <div>
            {{
              (e.Ttt ? "TTT kód: " + e.Ttt : "") +
              (e.IsoCode ? ", ISO kód: " + e.IsoCode : "")
            }}
          </div>
        </template>
      </code-name-pair>
    </b-col>
    <b-col>
      <div>
        <code-name-pair
          label="Felírt termék ISO 10 kód és név"
          description="A TTT kód vagy az ISO kód megadása kötelező!"
          :disabled="disabled"
          @changeValue="changeValue('Iso', $event)"
          v-model="product.Iso"
          :items="eesztISOList"
          listValueKey="EntryId"
          :state="!validation.hasError('product.Iso')"
          :error="validation.firstError('product.Iso')"
          :searchable="true"
          @search="searchIso"
        >
          <template #element="e">
            <div>{{ e.Name }}</div>
            <div>{{ e.Code ? "(" + e.Code + ")" : null }}</div>
          </template>
        </code-name-pair>
      </div>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Felírt termék mennyiség"
        :error="errorAmount"
        :required="true"
      >
        <template #content>
          <product-amount
            ref="prescriptionProductAmount"
            v-model="product.Amount"
            :disabled="disabled"
            @changeValue="changeValue('Amount', $event)"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felírt termék kihordási idő"
          :disabled="disabled"
          :required="true"
          @change="changeValue('Lifespan', $event)"
          v-model="product.Lifespan"
          :state="!validation.hasError('product.Lifespan')"
          :error="validation.firstError('product.Lifespan')"
        />
      </div>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Felírt termék felhasználás"
        :closedCard="true"
      >
        <template #content>
          <product-usage
            v-model="product.Usage"
            @changeValue="changeValue('Usage', $event)"
            :disabled="disabled"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Felírt termék méret"
          :disabled="disabled"
          @change="changeValue('Size', $event)"
          v-model="product.Size"
        />
      </div>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Felírt termék helyettesíthetőség"
        description="Vény jelentéshez kötelező mező!"
        :closedCard="true"
      >
        <template #content>
          <fp-select
            label="Helyettesíthető-e a termék?"
            description="Vény jelentéshez kötelező mező!"
            v-model="product.Substitutable"
            @change="changeValue('Substitutable', $event)"
            :disabled="disabled"
            :items="threeStateList"
            valueKey="Value"
            textKey="Text"
          />
          <fp-input
            v-if="product.Substitutable == false"
            label="Felírt termék nem helyettesíthető indoklás"
            :disabled="disabled"
            @change="changeValue('ReasonOfNonSubstitutability', $event)"
            v-model="product.ReasonOfNonSubstitutability"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Termék felhasználási területe"
        :closedCard="true"
      >
        <template #content>
          <fp-input
            label="Oldaliság"
            :disabled="disabled"
            @change="changeValue('ApplicationSide', $event)"
            v-model="product.ApplicationSide"
          />
          <fp-input
            label="Testtáj"
            :disabled="disabled"
            @change="changeValue('ApplicationBodyArea', $event)"
            v-model="product.ApplicationBodyArea"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <fp-panel-card
        style="margin-top: 10px"
        title="Orvosi utasítások"
        :closedCard="true"
      >
        <template #content>
          <fp-text-area
            label="Felíró orvos utasítása a GYSE kiadónak"
            :disabled="disabled"
            @change="changeValue('DoctorsOrdersForDispenser', $event)"
            v-model="product.DoctorsOrdersForDispenser"
          />
          <fp-text-area
            label="Felíró orvos utasítása a páciensnek"
            :disabled="disabled"
            @change="changeValue('DoctorsOrdersForPatient', $event)"
            v-model="product.DoctorsOrdersForPatient"
          />
        </template>
      </fp-panel-card>
    </b-col>
    <b-col>
      <div style="margin-top: 25px; margin-left: 5px">
        <fp-select
          label="Egyedi méretvétel alapján készülő termék?"
          v-model="product.CustomMade"
          @change="changeValue('CustomMade', $event)"
          :disabled="disabled"
          :items="threeStateList"
          valueKey="Value"
          textKey="Text"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Javítandó termék gyári száma"
          :disabled="disabled"
          @change="changeValue('SerialNumber', $event)"
          v-model="product.SerialNumber"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-text-area
          label="Hiba jellegének szöveges meghatározása"
          :disabled="disabled"
          @change="changeValue('FaultDescription', $event)"
          v-model="product.FaultDescription"
        />
      </div>
    </b-col>
    <b-col>
      <div>
        <fp-input
          label="Kölcsönzés időtartama napokban"
          :disabled="disabled"
          @change="changeValue('RentalDuration', $event)"
          v-model="product.RentalDuration"
        />
      </div>
    </b-col>
  </b-row>
</template>
<script>
import ProductAmount from "../common/ProductAmount.vue";
import CodeNamePair from "./CodeNamePair.vue";
import ProductUsage from "./ProductUsage.vue";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import moment from "moment";
export default {
  components: { CodeNamePair, ProductUsage, ProductAmount },
  name: "FormPrescriptionProduct",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      product: null,
      changeProductValue: {},
      defaultProduct: {
        PrescriptionTitle: null,
        Iso: null,
        Ttt: null,
        Amount: null,
        Lifespan: null,
        Usage: null,
        Size: null,
        Substitutable: null,
        ReasonOfNonSubstitutability: null,
        ApplicationSide: null,
        ApplicationBodyArea: null,
        DoctorsOrdersForDispenser: null,
        DoctorsOrdersForPatient: null,
        CustomMade: null,
        SerialNumber: null,
        FaultDescription: null,
        RentalDuration: null,
      },
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
      eesztLegalTypeList: null,
      eesztMedicalDeviceList: null,
      eesztISOList: null,
      validDateFilterParams: {
        ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
        ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
      },
      //error
      errorAmount: false,
    };
  },
  watch: {
    async value() {
      this.setProduct();
      await this.getLists();
    },
    product(input) {
      this.$emit("change", input);
    },
  },
  //TODO: csekkolni
  validators: {
    "product.Lifespan": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.Ttt,product.Iso": function (tttValue, isoValue) {
      const validator = this.$validator;
      return validator.custom(function () {
        if (
          (!validator.isEmpty(tttValue) ||
            (tttValue && !validator.isEmpty(tttValue.Id))) &&
          (!validator.isEmpty(isoValue) ||
            (isoValue && !validator.isEmpty(isoValue.Id)))
        ) {
          return "Az ISO vagy a TTT alapján kell megadni a terméket! Egyszerre a kettőt nem lehet!";
        }
      });
    },
    "product.Iso,product.Ttt": function (isoValue, tttValue) {
      const validator = this.$validator;
      return validator.custom(function () {
        if (
          (!validator.isEmpty(tttValue) ||
            (tttValue && !validator.isEmpty(tttValue.Id))) &&
          (!validator.isEmpty(isoValue) ||
            (isoValue && !validator.isEmpty(isoValue.Id)))
        ) {
          return "Az ISO vagy a TTT alapján kell megadni a terméket! Egyszerre a kettőt nem lehet!";
        }
      });
    },
    "product.Ttt": function (value) {
      if (this.product.Iso && !this.product.Iso.Id) {
        return this.$validator
          .value(value.Id)
          .required("Az ISO vagy a TTT alapján meg kell adni a terméket!");
      } else if (!this.product.Iso) {
        return this.$validator
          .value(value)
          .required("Az ISO vagy a TTT alapján meg kell adni a terméket!");
      }
    },
    "product.Iso": function (value) {
      if (this.product.Ttt && !this.product.Ttt.Id) {
        return this.$validator
          .value(value.Id)
          .required("Az ISO vagy a TTT alapján meg kell adni a terméket!");
      } else if (!this.product.Ttt) {
        return this.$validator
          .value(value)
          .required("Az ISO vagy a TTT alapján meg kell adni a terméket!");
      }
    },
    "product.PrescriptionTitle": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  methods: {
    changeProductTtt(ttt) {
      if (ttt) {
        const productTtt = this.eesztMedicalDeviceList.find(
          (e) => e.EntryId == ttt.Id
        );
        if (productTtt) {
          if (!this.product.Amount) {
            this.product.Amount = {
              Amount: null,
              Unit: null,
              Justification: null,
            };
          }
          this.product.Amount.Unit = productTtt.PackagingName;
        }
      }
    },
    setProduct() {
      if (this.value) {
        this.product = this.value;
      } else {
        this.product = JSON.parse(JSON.stringify(this.defaultProduct));
      }
      this.resetValidation();
    },
    changeValue(key, value) {
      this.changeProductValue[key] = value;
      this.$emit("changeValue", this.changeProductValue);
    },
    async checkValidation() {
      const valid = await this.$validate();

      const amountValid = await this.$refs[
        "prescriptionProductAmount"
      ].checkValidation();
      this.errorAmount = !amountValid;

      return valid && amountValid;
    },
    resetValidation() {
      this.validation.reset();

      if (this.$refs["prescriptionProductAmount"]) {
        this.$refs["prescriptionProductAmount"].resetValidation();
      }
      this.errorAmount = false;
    },
    async searchIso(input) {
      var list;
      if (input.length > 3) {
        const nameContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_iso.Value,
          {
            ["Name-contains"]: input,
          }
        );
        const codeContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_iso.Value,
          {
            ["Code-contains"]: input,
          }
        );
        list = [...nameContainsList, ...codeContainsList];
      } else if (input.length == 0) {
        list = await this.getBase(this.$enums.BaseName.eeszt_iso.Value, {
          "-orderby": "Code",
          ["-limit"]: 10,
        });
      } else {
        list = [];
      }

      var selectedValue = [];
      if (this.product.Iso && this.product.Iso.Id) {
        selectedValue = await this.getBase(this.$enums.BaseName.eeszt_iso.Value, {
          EntryId: this.product.Iso.Id,
        });
      }

      this.eesztISOList = this.checkDuplicates([...list, ...selectedValue]);
      this.eesztISOList.splice(0, 0, { Name: "Nincs megadva", EntryId: null });
    },
    async searchMedicalDevice(input) {
      var list;
      if (input.length > 3) {
        const nameContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["Name-contains"]: input,
          }
        );
        const tttContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["Ttt-contains"]: input,
          }
        );
        const isoContainsList = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            ...this.validDateFilterParams,
            ["IsoCode-contains"]: input,
          }
        );
        list = [...nameContainsList, ...tttContainsList, ...isoContainsList];
      } else if (input.length == 0) {
        list = await this.getBase(this.$enums.BaseName.eeszt_medical_device.Value, {
          ...this.validDateFilterParams,
          "-orderby": "Code",
          ["-limit"]: 10,
        });
      } else {
        list = [];
      }

      var selectedValue = [];
      if (this.product.Ttt && this.product.Ttt.Id) {
        selectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            EntryId: this.product.Ttt.Id,
          }
        );
      }

      this.eesztMedicalDeviceList = this.checkDuplicates([
        ...list,
        ...selectedValue,
      ]);
      this.eesztMedicalDeviceList.splice(0, 0, {
        Name: "Nincs megadva",
        EntryId: null,
      });
    },
    checkDuplicates(things) {
      return things.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.EntryId === thing.EntryId)
      );
    },
    async getLists() {
      const eesztMedicalDeviceDefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_medical_device.Value,
        {
          ...this.validDateFilterParams,
          "-orderby": "Code",
          ["-limit"]: 10,
        }
      );
      var eesztMedicalDeviceSelectedValue = [];
      if (this.product.Ttt && this.product.Ttt.Id) {
        eesztMedicalDeviceSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_medical_device.Value,
          {
            EntryId: this.product.Ttt.Id,
          }
        );
      }
      this.eesztMedicalDeviceList = this.checkDuplicates([
        ...eesztMedicalDeviceDefaultList,
        ...eesztMedicalDeviceSelectedValue,
      ]);
      this.eesztMedicalDeviceList.splice(0, 0, {
        Name: "Nincs megadva",
        EntryId: null,
      });

      this.eesztLegalTypeList = await this.getBase(
        this.$enums.BaseName.eeszt_legal_type.Value,
        this.validDateFilterParams
      );

      const eesztISODefaultList = await this.getBase(
        this.$enums.BaseName.eeszt_iso.Value,
        {
          "-orderby": "Code",
          ["-limit"]: 10,
        }
      );
      var eesztISOSelectedValue = [];
      if (this.product.Iso && this.product.Iso.Id) {
        eesztISOSelectedValue = await this.getBase(
          this.$enums.BaseName.eeszt_iso.Value,
          {
            EntryId: this.product.Iso.Id,
          }
        );
      }
      this.eesztISOList = this.checkDuplicates([
        ...eesztISODefaultList,
        ...eesztISOSelectedValue,
      ]);
      this.eesztISOList.splice(0, 0, { Name: "Nincs megadva", EntryId: null });
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    this.setProduct();
    await this.getLists();
  },
};
</script>
