import { EhssClient, RequestMethod } from '@/backend/ehss-client'
import { RequestResponseLogic } from '@/logic/ui/request-response'

const CHART_API_PATH = '/chart'

export const ChartBackendLogic = {
    async getChart(diaryName, params) {
        let result = await EhssClient.sendRequest(RequestMethod.GET, CHART_API_PATH + '/' + diaryName, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

    async getPatientChart(diaryName, patientId, params) {
        let result = await EhssClient.sendRequest(RequestMethod.GET, CHART_API_PATH + '/' + diaryName + '/' + patientId, params)
        return await RequestResponseLogic.checkRequestResponse(result)
    },

}