export const Enums = {
  Languages: {
    hu: { Value: "hu", Text: "Magyar", flag: "/flags/hu.svg" },
    en: { Value: "en", Text: "English", flag: "/flags/en.svg" },
    de: { Value: "de", Text: "Deutsch", flag: "/flags/de.svg" },
  },
  RelationshipType: {
    default: { Value: null, Text: "enums.default" },
    Mother: { Value: "Mother", Text: "enums.RelationshipType.Mother" },
    GrandMother: {
      Value: "GrandMother",
      Text: "enums.RelationshipType.GrandMother",
    },
    Father: { Value: "Father", Text: "enums.RelationshipType.Father" },
    GrandFather: {
      Value: "GrandFather,",
      Text: "enums.RelationshipType.GrandFather",
    },
    Sister: { Value: "Sister", Text: "enums.RelationshipType.Sister" },
    Brother: { Value: "Brother", Text: "enums.RelationshipType.Brother" },
  },
  UserRoleWithHome: {
    Client: {
      Value: "Client",
      Text: "enums.UserRole.Client",
      Home: "WebAppClientHome",
    },
    HealthProfessional: {
      Value: "HealthProfessional",
      Text: "enums.UserRole.HealthProfessional",
      Home: "WebAppSpecialistHome",
    },
    Partner: {
      Value: "Partner",
      Text: "enums.UserRole.Partner",
      Home: "WebAppPartnerHome",
    },
    /* Supervisor: { Value: "Supervisor", Text: 'enums.UserRole.Supervisor', Home: 'WebAppSpecialistHome' }, */
    Administrator: {
      Value: "Administrator",
      Text: "enums.UserRole.Administrator",
      Home: "WebAppAdminHome",
    },
    /* Technical: { Value: "Technical", Text: 'enums.UserRole.Technical', Home: 'WebAppClientHome' } */
  },
  UserRole: {
    default: { Value: null, Text: "enums.default" },
    Client: { Value: "Client", Text: "enums.UserRole.Client" },
    HealthProfessional: {
      Value: "HealthProfessional",
      Text: "enums.UserRole.HealthProfessional",
    },
    Partner: { Value: "Partner", Text: "enums.UserRole.Partner" },
    Administrator: {
      Value: "Administrator",
      Text: "enums.UserRole.Administrator",
    },
  },
  UserFlag: {
    //Inactive: { Value: "Inactive", Text: 'enums.UserFlag.Inactive' },
    MustChangePassword: {
      Value: "MustChangePassword",
      Text: "enums.UserFlag.MustChangePassword",
    },
    NoLogin: { Value: "NoLogin", Text: "enums.UserFlag.NoLogin" },
    Impersonated: {
      Value: "Impersonated",
      Text: "enums.UserFlag.Impersonated",
    },
  },
  CareType: {
    Inpatient: { Value: "Inpatient", Text: "enums.CareType.Inpatient" },
    Outpatient: { Value: "Outpatient", Text: "enums.CareType.Outpatient" },
  },
  FormCategory: {
    General: { Value: "General", Text: "enums.FormCategory.General" },
    Project: { Value: "Project", Text: "enums.FormCategory.Project" },
  },
  DocumentStatus: {
    Created: { Value: "Created", Text: "enums.DocumentStatus.Created" },
    Uploaded: { Value: "Uploaded", Text: "enums.DocumentStatus.Uploaded" },
  },
  DocumentCategory: {
    default: { Value: null, Text: "enums.default" },
    Ehr: { Value: "Ehr", Text: "enums.DocumentCategory.Ehr" },
    Phr: { Value: "Phr", Text: "enums.DocumentCategory.Phr" },
    Personal: {
      Value: "Personal",
      Text: "enums.DocumentCategory.PersonalDocument",
    },
    Contract: { Value: "Contract", Text: "enums.DocumentCategory.Contract" },
    Permit: { Value: "Permit", Text: "enums.DocumentCategory.Permit" },
    /* Technical: { Value: "Technical", Text: 'enums.DocumentCategory.Technical' }, */
    Other: { Value: "Other", Text: "enums.DocumentCategory.Other" },
  },
  OtherDocumentCategory: {
    default: { Value: null, Text: "enums.default" },
    Personal: {
      Value: "Personal",
      Text: "enums.DocumentCategory.PersonalDocument",
    },
    Contract: { Value: "Contract", Text: "enums.DocumentCategory.Contract" },
    Permit: { Value: "Permit", Text: "enums.DocumentCategory.Permit" },
    /* Technical: { Value: "Technical", Text: 'enums.DocumentCategory.Technical' }, */
    Other: { Value: "Other", Text: "enums.DocumentCategory.Other" },
  },
  FormStatus: {
    default: { Value: null, Text: "enums.default" },
    Active: { Value: "Active", Text: "enums.FormStatus.Active" },
    Inactive: { Value: "Inactive", Text: "enums.FormStatus.Inactive" },
    InProgress: { Value: "InProgress", Text: "enums.FormStatus.InProgress" },
    Filled: { Value: "Filled", Text: "enums.FormStatus.Filled" },
  },
  QuestionnaireStatus: {
    default: { Value: null, Text: "enums.default" },
    Active: { Value: "Active", Text: "enums.FormStatus.Active" },
    Inactive: { Value: "Inactive", Text: "enums.FormStatus.Inactive" },
    InProgress: { Value: "InProgress", Text: "enums.FormStatus.InProgress" },
    UnderEvaluation: {
      Value: "UnderEvaluation",
      Text: "enums.FormStatus.UnderEvaluation",
    },
    Filled: { Value: "Filled", Text: "enums.FormStatus.Filled" },
    Evaluated: { Value: "Evaluated", Text: "enums.FormStatus.Evaluated" },
  },
  AuthenticationResult: {
    Success: { Value: "Success", Text: "enums.AuthenticationResult.Success" },
    Failure: { Value: "Failure", Text: "enums.AuthenticationResult.Failure" },
  },
  DataOperation: {
    Create: { Value: "Create", Text: "enums.DataOperation.Create" },
    Read: { Value: "Read", Text: "enums.DataOperation.Read" },
    Update: { Value: "Update", Text: "enums.DataOperation.Update" },
    LogicalDelete: {
      Value: "LogicalDelete",
      Text: "enums.DataOperation.LogicalDelete",
    },
    PhisicalDelete: {
      Value: "PhisicalDelete",
      Text: "enums.DataOperation.PhisicalDelete",
    },
  },
  Sex: {
    default: { Value: null, Text: "enums.default" },
    Male: { Value: "Male", Text: "enums.Sex.Male" },
    Female: { Value: "Female", Text: "enums.Sex.Female" },
    Both: { Value: "Both", Text: "enums.Sex.Both" },
    Unknown: { Value: "Unknown", Text: "enums.Sex.Unknown" },
  },
  DiseaseGuideSex: {
    default: { Value: null, Text: "enums.default" },
    Male: { Value: "Male", Text: "enums.Sex.Male" },
    Female: { Value: "Female", Text: "enums.Sex.Female" },
    Both: { Value: "Both", Text: "enums.Sex.Both" },
  },
  MaritalStatus: {
    default: { Value: null, Text: "enums.default" },
    Married: { Value: "Married", Text: "enums.MaritalStatus.Married" },
    Single: { Value: "Single", Text: "enums.MaritalStatus.Single" },
    Divorced: { Value: "Divorced", Text: "enums.MaritalStatus.Divorced" },
    Widowed: { Value: "Widowed", Text: "enums.MaritalStatus.Widowed" },
  },
  DataSource: {
    //TODO: képekhez hiányzó képek csatolása
    FocusProgram: {
      Value: "FocusProgram",
      Text: "enums.DataSource.FocusProgram",
      Path: "source-icons/fpIcon.png",
    },
    HealthKit: {
      Value: "HealthKit",
      Text: "enums.DataSource.HealthKit",
      Path: "source-icons/hKit.png",
    },
    GoogleFit: {
      Value: "GoogleFit",
      Text: "enums.DataSource.GoogleFit",
      Path: "source-icons/gFit.png",
    },
    UserInput: {
      Value: "UserInput",
      Text: "enums.DataSource.UserInput",
      Path: "source-icons/fpIcon.png",
    },
    Vitadock: { Value: "Vitadock", Text: "enums.DataSource.Vitadock" },
    Ogyei: { Value: "Ogyei", Text: "enums.DataSource.Ogyei" },
    Eeszt: { Value: "Eeszt", Text: "enums.DataSource.Eeszt" },
    Aeek: { Value: "Aeek", Text: "enums.DataSource.Aeek" },
  },
  BloodType: {
    default: { Value: null, Text: "enums.default" },
    Zp: { Value: "Zp", Text: "enums.BloodType.Zp" },
    Zm: { Value: "Zm", Text: "enums.BloodType.Zm" },
    Ap: { Value: "Ap", Text: "enums.BloodType.Ap" },
    Am: { Value: "Am", Text: "enums.BloodType.Am" },
    Bp: { Value: "Bp", Text: "enums.BloodType.Bp" },
    Bm: { Value: "Bm", Text: "enums.BloodType.Bm" },
    ABp: { Value: "ABp", Text: "enums.BloodType.ABp" },
    ABm: { Value: "ABm", Text: "enums.BloodType.ABm" },
  },
  Circumstances: {
    default: { Value: null, Text: "enums.default" },
    Rest: { Value: "Rest", Text: "enums.Circumstances.Rest" },
    LightExercise: {
      Value: "LightExercise",
      Text: "enums.Circumstances.LightExercise",
    },
    HeavyExercise: {
      Value: "HeavyExercise",
      Text: "enums.Circumstances.HeavyExercise",
    },
    Unknown: { Value: "Unknown", Text: "enums.Circumstances.Unknown" },
  },
  SkinType: {
    default: { Value: null, Text: "enums.default" },
    TypeI: { Value: "TypeI", Text: "enums.SkinType.TypeI" },
    TypeII: { Value: "TypeII", Text: "enums.SkinType.TypeII" },
    TypeIII: { Value: "TypeIII", Text: "enums.SkinType.TypeIII" },
    TypeIV: { Value: "TypeIV", Text: "enums.SkinType.TypeIV" },
    TypeV: { Value: "TypeV", Text: "enums.SkinType.TypeV" },
    TypeVI: { Value: "TypeVI", Text: "enums.SkinType.TypeVI" },
  },
  PhysicalActivity: {
    default: { Value: null, Text: "enums.default" },
    ExtremelyInactive: {
      Value: "ExtremelyInactive",
      Text: "enums.PhysicalActivity.ExtremelyInactive",
    },
    Sedentary: { Value: "Sedentary", Text: "enums.PhysicalActivity.Sedentary" },
    ModeratelyActive: {
      Value: "ModeratelyActive",
      Text: "enums.PhysicalActivity.ModeratelyActive",
    },
    VigorouslyActive: {
      Value: "VigorouslyActive",
      Text: "enums.PhysicalActivity.VigorouslyActive",
    },
    ExtremelyActive: {
      Value: "ExtremelyActive",
      Text: "enums.PhysicalActivity.ExtremelyActive",
    },
  },
  ChartInterval: {
    Day: { Value: "Day", Text: "enums.ChartInterval.Day" },
    Week: { Value: "Week", Text: "enums.ChartInterval.Week" },
    Month: { Value: "Month", Text: "enums.ChartInterval.Month" },
    Year: { Value: "Year", Text: "enums.ChartInterval.Year" },
  },
  ChartSize: {
    Small: {
      Value: "Small",
      Text: "enums.ChartSize.Small",
      className: "chart-small",
    },
    Medium: {
      Value: "Medium",
      Text: "enums.ChartSize.Medium",
      className: "chart-medium",
    },
    Large: {
      Value: "Large",
      Text: "enums.ChartSize.Large",
      className: "chart-large",
    },
  },
  MedicineTimingType: {
    default: { Value: null, Text: "enums.default" },
    Once: { Value: "Once", Text: "enums.MedicineTimingType.Once" },
    OnDaysOfWeek: {
      Value: "OnDaysOfWeek",
      Text: "enums.MedicineTimingType.OnDaysOfWeek",
    },
    Daily: { Value: "Daily", Text: "enums.MedicineTimingType.Daily" },
    EveryTwoDays: {
      Value: "EveryTwoDays",
      Text: "enums.MedicineTimingType.EveryTwoDays",
    },
    EveryThreeDays: {
      Value: "EveryThreeDays",
      Text: "enums.MedicineTimingType.EveryThreeDays",
    },
    EveryFourDays: {
      Value: "EveryFourDays",
      Text: "enums.MedicineTimingType.EveryFourDays",
    },
    EveryFiveDays: {
      Value: "EveryFiveDays",
      Text: "enums.MedicineTimingType.EveryFiveDays",
    },
    EverySixDays: {
      Value: "EverySixDays",
      Text: "enums.MedicineTimingType.EverySixDays",
    },
    Weekly: { Value: "Weekly", Text: "enums.MedicineTimingType.Weekly" },
    Biweekly: { Value: "Biweekly", Text: "enums.MedicineTimingType.Biweekly" },
    Monthly: { Value: "Monthly", Text: "enums.MedicineTimingType.Monthly" },
  },
  DaysOfWeek: {
    Monday: { Value: "Monday", Text: "enums.DaysOfWeek.Monday" },
    Tuesday: { Value: "Tuesday", Text: "enums.DaysOfWeek.Tuesday" },
    Wednesday: { Value: "Wednesday", Text: "enums.DaysOfWeek.Wednesday" },
    Thursday: { Value: "Thursday", Text: "enums.DaysOfWeek.Thursday" },
    Friday: { Value: "Friday", Text: "enums.DaysOfWeek.Friday" },
    Saturday: { Value: "Saturday", Text: "enums.DaysOfWeek.Saturday" },
    Sunday: { Value: "Sunday", Text: "enums.DaysOfWeek.Sunday" },
  },
  MealRelation: {
    default: { Value: null, Text: "enums.default" },
    BeforeMeal: { Value: "BeforeMeal", Text: "enums.MealRelation.BeforeMeal" },
    AfterMeal: { Value: "AfterMeal", Text: "enums.MealRelation.AfterMeal" },
    Unknown: { Value: "Unknown", Text: "enums.MealRelation.Unknown" },
  },
  MealQuantity: {
    default: { Value: null, Text: "enums.default" },
    Low: { Value: "Low", Text: "enums.MealQuantity.Low" },
    Medium: { Value: "Medium", Text: "enums.MealQuantity.Medium" },
    High: { Value: "High", Text: "enums.MealQuantity.High" },
    Unknown: { Value: "Unknown", Text: "enums.MealQuantity.Unknown" },
  },
  MealName: {
    default: { Value: null, Text: "enums.default" },
    Breakfast: { Value: "Breakfast", Text: "enums.MealName.Breakfast" },
    Elevenses: { Value: "Elevenses", Text: "enums.MealName.Elevenses" },
    Lunch: { Value: "Lunch", Text: "enums.MealName.Lunch" },
    Dinner: { Value: "Dinner", Text: "enums.MealName.Dinner" },
    Snack: { Value: "Snack", Text: "enums.MealName.Snack" },
  },
  Unit: {
    default: { Value: null, Text: "enums.default" },
    Pieces: { Value: "Pieces", Text: "enums.Unit.Pieces" },
    Gram: { Value: "Gram", Text: "enums.Unit.Gram" },
    Kilogram: { Value: "Kilogram", Text: "enums.Unit.Kilogram" },
    Milliliter: { Value: "Milliliter", Text: "enums.Unit.Milliliter" },
    Deciliter: { Value: "Deciliter", Text: "enums.Unit.Deciliter" },
    Liter: { Value: "Liter", Text: "enums.Unit.Liter" },
  },
  AttachmentTypeName: {
    FormSchema: { TypeName: "FormSchema", EntityIdValueKey: "FormSchemaId" },
    FormInstance: {
      TypeName: "FormInstance",
      EntityIdValueKey: "FormInstanceId",
    },
    UserProfile: { TypeName: "UserProfile", EntityIdValueKey: "UserId" },
    ProjectStyleSheet: {
      TypeName: "FpProject",
      EntityIdValueKey: "ProjectId",
    },
  },
  //TODO: typeName, EntityIdKey formátum használata?
  ProjectItemsEntityTypeName: {
    FpWorkflow: "WorkflowTemplate",
    FpAppointment: "FpAppointment",
    AvailableBadge: "AvailableBadge",
    UserDocument: "UserDocument",
    FormSchema: "FormSchema",
    FpMeeting: "FpMeeting",
    FpTag: "FpTag",
    EmailTemplate: "EmailTemplate",
  },
  WorkflowResult: {
    Success: "Success",
    Failed: "Failed",
  },
  WorkflowStatus: {
    InProgress: "InProgress",
    Ended: "Ended",
  },
  TaskStatus: {
    default: { Value: null, Text: "enums.default" },
    Added: { Value: "Added", Text: "enums.TaskStatus.Added", Weight: 0 },
    InProgress: {
      Value: "InProgress",
      Text: "enums.TaskStatus.InProgress",
      Weight: 1,
    },
    Overdue: { Value: "Overdue", Text: "enums.TaskStatus.Overdue", Weight: 2 },
    Completed: {
      Value: "Completed",
      Text: "enums.TaskStatus.Completed",
      Weight: 3,
    },
    Failed: { Value: "Failed", Text: "enums.TaskStatus.Failed", Weight: 4 },
  },
  TaskPriority: {
    default: { Value: null, Text: "enums.default" },
    Low: { Value: "Low", Text: "enums.TaskPriority.Low", Weight: 1 },
    Medium: { Value: "Medium", Text: "enums.TaskPriority.Medium", Weight: 2 },
    High: { Value: "High", Text: "enums.TaskPriority.High", Weight: 3 },
    Urgent: { Value: "Urgent", Text: "enums.TaskPriority.Urgent", Weight: 4 },
  },
  TaskType: {
    default: { Value: null, Text: "enums.default" },
    TakeMedicine: {
      Value: "TakeMedicine",
      Text: "enums.TaskType.TakeMedicine",
      Route: "/Client/MedicineDiary",
    },
    FillForm: {
      Value: "FillForm",
      Text: "enums.TaskType.FillForm",
      Route: "/Specialist/Form",
    }, //TODO: Orvos, Partner
    FillQuestionnaire: {
      Value: "FillForm",
      Text: "enums.TaskType.FillQuestionnaire",
      Route: "Questionnaire",
    }, //TODO: Orvos, Beteg
    EvaluateForm: {
      Value: "EvaluateForm",
      Text: "enums.TaskType.EvaluateForm",
      Route: "/Specialist/EvaluatingQuestionnaire",
    }, //TODO: Orvos, Partner
    DoManual: { Value: "DoManual", Text: "enums.TaskType.DoManual" },
    AddToDiary: {
      Value: "AddToDiary",
      Text: "enums.TaskType.AddToDiary",
      Routes: [
        "/Client/BodyWeightDiary",
        "/Client/SleepDiary",
        "/Client/MoodDiary",
        "/Client/InsulinDiary",
        "/Client/BloodGlucoseDiary",
        "/Client/NutritionDiary",
        "/Client/BloodPressureDiary",
        "/Client/PulseDiary",
        "/Client/ExerciseDiary",
        "/Client/OxygenSaturationDiary",
        "/Client/MedicineDiary",
      ],
    },
    AcquireBadge: {
      Value: "AcquireBadge",
      Text: "enums.TaskType.AcquireBadge",
      Route: "/Client/Badges",
    },
  },
  EvaluationMethod: {
    Manual: "Manual",
    Auto: "Auto",
  },
  ProjectStatus: {
    default: { Value: null, Text: "enums.default" },
    Planned: { Value: "Planned", Text: "enums.ProjectStatus.Planned" },
    Running: { Value: "Running", Text: "enums.ProjectStatus.Running" },
    Finished: { Value: "Finished", Text: "enums.ProjectStatus.Finished" },
    InProgress: { Value: "InProgress", Text: "enums.ProjectStatus.InProgress" },
  },
  Privacy: {
    None: { Value: "None", Text: "enums.ProjectPrivacy.None" },
    Blind: { Value: "Blind", Text: "enums.ProjectPrivacy.Blind" },
    DoubleBlind: {
      Value: "DoubleBlind",
      Text: "enums.ProjectPrivacy.DoubleBlind",
    },
  },
  ListBoxComponentSourceType: {
    Base: "Base",
    AdHoc: "AdHoc",
  },
  ListBoxComponentDisplaySequence: {
    Random: "Random",
    Linear: "Linear",
  },
  BaseName: {
    //TODO: szótár alapú lekérdezés (jelenleg csak magyarul jelennek meg az elemek)
    medicine: { Value: "medicine", Id: "MedicineId", Text: "Name" },
    medicine_agent: {
      Value: "medicine_agent",
      Id: "AgentId",
      Text: "HungarianName",
    },
    disease: { Value: "disease", Id: "DiseaseId", Text: "Name" },
    disease_guide: { Value: "disease_guide", Id: "DiseaseId", Text: "Name" },
    hospital: { Value: "hospital", Id: "HospitalId", Text: "Name" },
    hospital_ward: { Value: "hospital_ward", Id: "WardId", Text: "Name" },
    pharmacy: { Value: "pharmacy", Id: "PharmacyId", Text: "Name" },
    procedure: { Value: "procedure", Id: "ProcedureId", Text: "Name" },
    eeszt_bno10: "eeszt_bno10",
    eeszt_country: "eeszt_country",
    eeszt_dosage_type: "eeszt_dosage_type",
    eeszt_iso: "eeszt_iso",
    eeszt_legal_type: "eeszt_legal_type",
    eeszt_medical_device: "eeszt_medical_device",
    eeszt_patient_identifier_type: "eeszt_patient_identifier_type",
    eeszt_prescription_category: "eeszt_prescription_category",
    eeszt_prescription_type: "eeszt_prescription_type",
    eeszt_product_type: "eeszt_product_type",
    eeszt_sex: "eeszt_sex",
  },
  HealthGoalType: {
    interval: "interval",
    daily: "daily",
    property: "property",
  },
  HealthGoalStatus: {
    Added: { Value: "Added", Icon: "status-images/added-open.png" },
    InProgress: {
      Value: "InProgress",
      Icon: "status-images/inProgress-open.png",
    },
    EndedSuccess: {
      Value: "EndedSuccess",
      Icon: "status-images/endedSuccess-closed.png",
    },
    EndedFailure: {
      Value: "EndedFailure",
      Icon: "status-images/endedFailed-closed.png",
    },
  },
  DisplayType: {
    Vertical: "Vertical",
    Horizontal: "Horizontal",
  },
  PickerComponentFormat: {
    Date: "Date",
    Time: "Time",
    DateTime: "DateTime",
  },
  SectionDisplayFormat: {
    OnePage: "OnePage",
    Tab: "Tab",
  },
  DiagramType: {
    chart: "chart",
    diary: "diary",
  },
  InputTextStyle: {
    light: "light",
    dark: "dark",
  },
  InputType: {
    text: "text",
    number: "number",
    color: "color",
    password: "password",
    file: "file",
    email: "email",
  },
  WorkflowStep: {
    //steps
    StartStepInstance: {
      Value: "StartStepInstance",
      Text: "enums.WorkflowStep.StartStep",
    },
    StartStep: { Value: "StartStep", Text: "enums.WorkflowStep.StartStep" },
    EndStepInstance: {
      Value: "EndStepInstance",
      Text: "enums.WorkflowStep.EndStep",
    },
    EndStep: { Value: "EndStep", Text: "enums.WorkflowStep.EndStep" },
    NotificationStepInstance: {
      Value: "NotificationStepInstance",
      Text: "enums.WorkflowStep.NotificationStep",
    },
    NotificationTemplateStepInstance: {
      Value: "NotificationTemplateStepInstance",
      Text: "enums.WorkflowStep.NotificationTemplateStep",
    },
    NotificationStep: {
      Value: "NotificationStep",
      Text: "enums.WorkflowStep.NotificationStep",
    },
    /* EmailStepInstance: {
      Value: "EmailStepInstance",
      Text: "enums.WorkflowStep.NotificationTemplateStep",
    },
    NotificationTemplateStep: {
      Value: "NotificationTemplateStep",
      Text: "enums.WorkflowStep.NotificationTemplateStep",
    }, */
    JoinControlInstance: {
      Value: "JoinControlInstance",
      Text: "enums.WorkflowStep.JoinControlInstance",
    },
    ForkControlInstance: {
      Value: "ForkControlInstance",
      Text: "enums.WorkflowStep.ForkControlInstance",
    },
    //tasks
    DiaryTaskInstance: {
      Value: "DiaryTaskInstance",
      Text: "enums.WorkflowTask.DiaryTask",
    },
    DiaryTask: { Value: "DiaryTask", Text: "enums.WorkflowTask.DiaryTask" },
    BadgeTaskInstance: {
      Value: "BadgeTaskInstance",
      Text: "enums.WorkflowTask.BadgeTask",
    },
    BadgeTask: { Value: "BadgeTask", Text: "enums.WorkflowTask.BadgeTask" },
    SubWorkflowTaskInstance: {
      Value: "SubWorkflowTaskInstance",
      Text: "enums.WorkflowTask.SubWorkflowTask",
    },
    SubWorkflowTask: {
      Value: "SubWorkflowTask",
      Text: "enums.WorkflowTask.SubWorkflowTask",
    },
    FillFormTaskInstance: {
      Value: "FillFormTaskInstance",
      Text: "enums.WorkflowTask.FillFormTask",
    },
    FillFormTask: {
      Value: "FillFormTask",
      Text: "enums.WorkflowTask.FillFormTask",
    },
    ManualTaskInstance: {
      Value: "ManualTaskInstance",
      Text: "enums.WorkflowTask.ManualTask",
    },
    ManualTask: { Value: "ManualTask", Text: "enums.WorkflowTask.ManualTask" },
  },
  PartOfDay: {
    Morning: {
      Value: "Morning",
      Text: "enums.PartOfDay.Morning",
      defaultTime: 8,
    },
  },
  ProjectBaseRole: {
    Manager: { Value: "Manager", Text: "enums.ProjectBaseRole.Manager" },
    Doctor: { Value: "Doctor", Text: "enums.ProjectBaseRole.Doctor" },
    Patient: { Value: "Patient", Text: "enums.ProjectBaseRole.Patient" },
  },
  ProjectPermission: {
    SeeResearch: {
      Value: "SeeResearch",
      Text: "enums.ProjectPermission.SeeResearch",
      Type: "SeeOption",
    },
    SeeManagers: {
      Value: "SeeManagers",
      Text: "enums.ProjectPermission.SeeManagers",
      Type: "SeeOption",
    },
    SeeDoctors: {
      Value: "SeeDoctors",
      Text: "enums.ProjectPermission.SeeDoctors",
      Type: "SeeOption",
    },
    SeePatients: {
      Value: "SeePatients",
      Text: "enums.ProjectPermission.SeePatients",
      Type: "SeeOption",
    },
    SeeItems: {
      Value: "SeeItems",
      Text: "enums.ProjectPermission.SeeItems",
      Type: "SeeOption",
    },
    SeeOwnProgress: {
      Value: "SeeOwnProgress",
      Text: "enums.ProjectPermission.SeeOwnProgress",
      Type: "SeeOption",
    },
    SeeSubordinateProgress: {
      Value: "SeeSubordinateProgress",
      Text: "enums.ProjectPermission.SeeSubordinateProgress",
      Type: "SeeOption",
    },
    SeeManagerProgress: {
      Value: "SeeManagerProgress",
      Text: "enums.ProjectPermission.SeeManagerProgress",
      Type: "SeeOption",
    },
    SeeDoctorProgress: {
      Value: "SeeDoctorProgress",
      Text: "enums.ProjectPermission.SeeDoctorProgress",
      Type: "SeeOption",
    },
    SeePatientProgress: {
      Value: "SeePatientProgress",
      Text: "enums.ProjectPermission.SeePatientProgress",
      Type: "SeeOption",
    },
    AddManager: {
      Value: "AddManager",
      Text: "enums.ProjectPermission.AddManager",
      Type: "AddOption",
    },
    AddDoctor: {
      Value: "AddDoctor",
      Text: "enums.ProjectPermission.AddDoctor",
      Type: "AddOption",
    },
    AddPatient: {
      Value: "AddPatient",
      Text: "enums.ProjectPermission.AddPatient",
      Type: "AddOption",
    },
    AddSubordinateManager: {
      Value: "AddSubordinateManager",
      Text: "enums.ProjectPermission.AddSubordinateManager",
      Type: "AddOption",
    },
    AddSubordinateDoctor: {
      Value: "AddSubordinateDoctor",
      Text: "enums.ProjectPermission.AddSubordinateDoctor",
      Type: "AddOption",
    },
    AddSubordinatePatient: {
      Value: "AddSubordinatePatient",
      Text: "enums.ProjectPermission.AddSubordinatePatient",
      Type: "AddOption",
    },
    AddManagerRelation: {
      Value: "AddManagerRelation",
      Text: "enums.ProjectPermission.AddManagerRelation",
      Type: "AddOption",
    },
    AddDoctorRelation: {
      Value: "AddDoctorRelation",
      Text: "enums.ProjectPermission.AddDoctorRelation",
      Type: "AddOption",
    },
    AddPatientRelation: {
      Value: "AddPatientRelation",
      Text: "enums.ProjectPermission.AddPatientRelation",
      Type: "AddOption",
    },
    AddSubordinateManagerRelation: {
      Value: "AddSubordinateManagerRelation",
      Text: "enums.ProjectPermission.AddSubordinateManagerRelation",
      Type: "AddOption",
    },
    AddSubordinateDoctorRelation: {
      Value: "AddSubordinateDoctorRelation",
      Text: "enums.ProjectPermission.AddSubordinateDoctorRelation",
      Type: "AddOption",
    },
    AddSubordinatePatientRelation: {
      Value: "AddSubordinatePatientRelation",
      Text: "enums.ProjectPermission.AddSubordinatePatientRelation",
      Type: "AddOption",
    },
    AddItem: {
      Value: "AddItem",
      Text: "enums.ProjectPermission.AddItem",
      Type: "AddOption",
    },
    RemoveManager: {
      Value: "RemoveManager",
      Text: "enums.ProjectPermission.RemoveManager",
      Type: "RemoveOption",
    },
    RemoveDoctor: {
      Value: "RemoveDoctor",
      Text: "enums.ProjectPermission.RemoveDoctor",
      Type: "RemoveOption",
    },
    RemovePatient: {
      Value: "RemovePatient",
      Text: "enums.ProjectPermission.RemovePatient",
      Type: "RemoveOption",
    },
    RemoveSubordinate: {
      Value: "RemoveSubordinate",
      Text: "enums.ProjectPermission.RemoveSubordinate",
      Type: "RemoveOption",
    },
    RemoveRelation: {
      Value: "RemoveRelation",
      Text: "enums.ProjectPermission.RemoveRelation",
      Type: "RemoveOption",
    },
    RemoveSubordinateRelation: {
      Value: "RemoveSubordinateRelation",
      Text: "enums.ProjectPermission.RemoveSubordinateRelation",
      Type: "RemoveOption",
    },
    RemoveItem: {
      Value: "RemoveItem",
      Text: "enums.ProjectPermission.RemoveItem",
      Type: "RemoveOption",
    },
    EditBaseData: {
      Value: "EditBaseData",
      Text: "enums.ProjectPermission.EditBaseData",
      Type: "EditOption",
    },
    EditResearch: {
      Value: "EditResearch",
      Text: "enums.ProjectPermission.EditResearch",
      Type: "EditOption",
    },
    EditSubordinate: {
      Value: "EditSubordinate",
      Text: "enums.ProjectPermission.EditSubordinate",
      Type: "EditOption",
    },
    EditManager: {
      Value: "EditManager",
      Text: "enums.ProjectPermission.EditManager",
      Type: "EditOption",
    },
    EditDoctor: {
      Value: "EditDoctor",
      Text: "enums.ProjectPermission.EditDoctor",
      Type: "EditOption",
    },
    EditPatient: {
      Value: "EditPatient",
      Text: "enums.ProjectPermission.EditPatient",
      Type: "EditOption",
    },
  },
  //TODO: szótár
  ProjectParameter: {
    PatientGroup: { Value: "PatientGroup", Text: "PatientGroup" },
    RequiredSubordinatePatientCount: {
      Value: "RequiredSubordinatePatientCount",
      Text: "RequiredSubordinatePatientCount",
    },
    RequiredSubordinateTreatmentPatientCount: {
      Value: "RequiredSubordinateTreatmentPatientCount",
      Text: "RequiredSubordinateTreatmentPatientCount",
    },
    RequiredSubordinateControlPatientCount: {
      Value: "RequiredSubordinateControlPatientCount",
      Text: "RequiredSubordinateControlPatientCount",
    },
    RequiredSubordinateDoctorCount: {
      Value: "RequiredSubordinateDoctorCount",
      Text: "RequiredSubordinateDoctorCount",
    },
  },
  ProjectTaskType: {
    CompleteWorkflow: {
      Value: "CompleteWorkflow",
      Text: "enums.ProjectTaskType.CompleteWorkflow",
    },
    HavePatientCount: {
      Value: "HavePatientCount",
      Text: "enums.ProjectTaskType.HavePatientCount",
    },
    HaveDoctorCount: {
      Value: "HaveDoctorCount",
      Text: "enums.ProjectTaskType.HaveDoctorCount",
    },
    HaveSubordinateCount: {
      Value: "HaveSubordinateCount",
      Text: "enums.ProjectTaskType.HaveSubordinateCount",
    },
  },
  InvitationStatus: {
    Pending: { Value: "Pending", Text: "enums.InvitationStatus.Pending" },
    Accepted: { Value: "Accepted", Text: "enums.InvitationStatus.Accepted" },
    Rejected: { Value: "Rejected", Text: "enums.InvitationStatus.Rejected" },
  },
  DoctorPatientRelationshipType: {
    GeneralPracticioner: {
      Value: "GeneralPracticioner",
      Text: "enums.DoctorPatientRelationshipType.GeneralPracticioner",
    },
    Chosen: {
      Value: "Chosen",
      Text: "enums.DoctorPatientRelationshipType.Chosen",
    },
    Project: {
      Value: "Project",
      Text: "enums.DoctorPatientRelationshipType.Project",
    },
  },
  EmailPriority: {
    NonUrgent: { Value: "NonUrgent", Text: "enums.EmailPriority.NonUrgent" },
    Normal: { Value: "Normal", Text: "enums.EmailPriority.Normal" },
    Urgent: { Value: "Urgent", Text: "enums.EmailPriority.Urgent" },
  },
  InvitationRelationshipType: {
    GeneralPracticioner: {
      Value: "GeneralPracticioner",
      Text: "enums.InvitationRelationshipType.GeneralPracticioner",
    },
    Chosen: {
      Value: "Chosen",
      Text: "enums.InvitationRelationshipType.Chosen",
    },
    Project: {
      Value: "Project",
      Text: "enums.InvitationRelationshipType.Project",
    },
  },
  UserInterfaceElement: {
    Questionnaire: { Value: "Questionnaire", Text: "Questionnaire" },
    NotificationTemplate: {
      Value: "NotificationTemplate",
      Text: "NotificationTemplate",
    },
    Workflow: { Value: "Workflow", Text: "Workflow" },
    UserTask: { Value: "UserTask", Text: "UserTask" },
    Document: { Value: "Document", Text: "Document" },
    Appointment: { Value: "Appointment", Text: "Appointment" },
    Meeting: { Value: "Meeting", Text: "Meeting" },
    ServiceSearch: { Value: "ServiceSearch", Text: "ServiceSearch" },
    Covid19Pass: { Value: "Covid19Pass", Text: "Covid19Pass" },
    TravelGuide: { Value: "TravelGuide", Text: "TravelGuide" },
    HealthGuide: { Value: "HealthGuide", Text: "HealthGuide" },
  },
  ModuleName: {
    BodyWeight: { Value: "BodyWeight", Text: "BodyWeight" },
    Nutrition: { Value: "Nutrition", Text: "Nutrition" },
    Exercise: { Value: "Exercise", Text: "Exercise" },
    Cardiology: { Value: "Cardiology", Text: "Cardiology" },
    Diabetology: { Value: "Diabetology", Text: "Diabetology" },
    Oncology: { Value: "Oncology", Text: "Oncology" },
    Pulmonology: { Value: "Pulmonology", Text: "Pulmonology" },
    Depression: { Value: "Depression", Text: "Depression" },
    Asthma: { Value: "Asthma", Text: "Asthma" },
    Medicine: { Value: "Medicine", Text: "Medicine" },
  },
  EesztStatus: {
    Open: { Value: "Open", Text: "Nyitott" },
    Dispensed: { Value: "Dispensed", Text: "Kiadott" },
    Withdrawn: { Value: "Withdrawn", Text: "Visszavont" },
    Expired: { Value: "Expired", Text: "Lejárt" },
    WaitingForCountersign: {
      Value: "WaitingForCountersign",
      Text: "Ellenjegyzésre vár",
    },
  },
  EesztPatientTransfer: {
    Ssn: {
      UserProfileKey: "SocialSecurityNumber",
      EesztPatientKey: "PatientIdentifierTypeId",
      EesztPatientEntryId: 8,
    },
    EuSsn: {
      UserProfileKey: "EuSocialSecurityNumber",
      EesztPatientKey: "PatientIdentifierTypeId",
      EesztPatientEntryId: 14,
    },
    GenderFemale: {
      UserProfileKey: "Sex",
      UserProfileId: "Female",
      EesztPatientKey: "GenderId",
      EesztPatientEntryId: 5,
    },
    GenderMale: {
      UserProfileKey: "Sex",
      UserProfileId: "Male",
      EesztPatientKey: "GenderId",
      EesztPatientEntryId: 4,
    },
  },
  EesztDosageCondition: {
    ByPartOfDay: 1,
    ByFrequence: 2,
    ByText: 3,
  },
  FunctionName: {
    BloodGlucose: { Value: "BloodGlucose", Text: "Vércukor napló" },
    BloodPressure: { Value: "BloodPressure", Text: "Vérnyomás napló" },
    BodyWeight: { Value: "BodyWeight", Text: "Testtömeg napló" },
    Ekg: { Value: "Ekg", Text: "EKG napló" },
    Exercise: { Value: "Exercise", Text: "Mozgás napló" },
    Insulin: { Value: "Insulin", Text: "Inzulin napló" },
    Medicine: { Value: "Medicine", Text: "Gyógyszer napló" },
    Mood: { Value: "Mood", Text: "Kedély napló" },
    Nutrition: { Value: "Nutrition", Text: "Táplálkozás napló" },
    OxygenSaturation: { Value: "OxygenSaturation", Text: "Véroxigénszint napló" },
    Pulse: { Value: "Pulse", Text: "Pulzus napló" },
    RibcageMeter: { Value: "RibcageMeter", Text: "RibcageMeter" },
    Sleep: { Value: "Sleep", Text: "Alvás napló" },
  },
};
