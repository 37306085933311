<template>
  <div class="profile page-background" style="padding: 20px 0px 100px 0px">
    <div style="text-align: right">
      <b-button @click="showChangePasswordForm = true">{{
        $t("client.profile.changePassword")
      }}</b-button>
      <change-user-password v-model="showChangePasswordForm" />
    </div>
    <div>
      <fp-tabs v-if="personalityData">
        <fp-tab v-if="personalityData.PersonalProfile">
          <template #title>
            {{ $t("client.profile.personalData.title") }}
            <div style="margin: 5px">
              <b-progress max="100" height="1.5rem">
                <b-progress-bar
                  :variant="
                    userPersonalProfile.FillingRate > 80
                      ? 'success'
                      : userPersonalProfile.FillingRate > 50
                      ? 'info'
                      : userPersonalProfile.FillingRate > 20
                      ? 'warning'
                      : 'danger'
                  "
                  :value="userPersonalProfile.FillingRate"
                >
                  <span> {{ userPersonalProfile.FillingRate }}% </span>
                </b-progress-bar>
              </b-progress>
              <span
                v-if="userPersonalProfile.FillingRate == 0"
                style="
                  position: absolute;
                  margin-top: -23px;
                  color: #627e8f !important;
                "
                >0%</span
              >
            </div>
          </template>
          <div class="profile-tab-content">
            <client-personal-profile
              ref="personalProfile"
              v-if="clipperImg"
              :clipperImg="clipperImg"
              :username="$loggedUser.Username"
              v-model="personalityData.PersonalProfile"
              @setProfilePicture="changeProfilePicture"
            />
            <div v-if="!isSaveMethodRun" class="profile-save-button-box">
              <b-button
                @click="saveUserPersonalProfile()"
                class="eh-action-button"
                id="save-button"
                >{{ $t("base.basic.save") }}</b-button
              >
            </div>
            <div v-else style="text-align: center; margin-bottom: 60px">
              <b-spinner></b-spinner>
            </div>
          </div>
        </fp-tab>
        <fp-tab
          title-item-class="fp-tabs-item"
          title-link-class="fp-tabs-link"
          v-if="personalityData.MedicalProfile"
        >
          <template #title>
            {{ $t("client.profile.medicalData.title") }}
            <div style="margin: 5px">
              <b-progress max="100" height="1.5rem">
                <b-progress-bar
                  :variant="
                    personalityData.MedicalProfile.FillingRate > 80
                      ? 'success'
                      : personalityData.MedicalProfile.FillingRate > 50
                      ? 'info'
                      : personalityData.MedicalProfile.FillingRate > 20
                      ? 'warning'
                      : 'danger'
                  "
                  :value="personalityData.MedicalProfile.FillingRate"
                >
                  <span>
                    {{ personalityData.MedicalProfile.FillingRate }}%
                  </span>
                </b-progress-bar>
              </b-progress>
              <span
                v-if="personalityData.MedicalProfile.FillingRate == 0"
                style="
                  position: absolute;
                  margin-top: -23px;
                  color: #627e8f !important;
                "
                >0%</span
              >
            </div>
          </template>
          <div class="profile-tab-content">
            <fp-client-medical-profile v-model="personalityData.MedicalProfile" />
            <div class="profile-save-button-box">
              <b-button
                @click="saveUserMedicalProfile()"
                class="eh-action-button"
                id="save-button"
                >{{ $t("base.basic.save") }}</b-button
              >
            </div>
          </div>
        </fp-tab>
      </fp-tabs>
    </div>
    <!-- validation errors -->
    <b-toast id="validationErrors" variant="danger" solid no-auto-hide>
      <template #toast-title> Beviteli hiba </template>
      <li v-for="error in validationErrors" :key="error.field">
        {{ error.message }}
      </li>
    </b-toast>
  </div>
</template>
<script>
import { UserProfileLogic } from "@/logic/backend/user-profile";
import { AttachmentLogic } from "@/logic/backend/attachment";
import ClientPersonalProfile from "@/components/form/ClientPersonalProfile.vue";
import ClientMedicalProfile from "@/components/form/ClientMedicalProfile.vue";
import ChangeUserPassword from "@/components/form/ChangeUserPassword.vue";

export default {
  name: "ClientUserProfile",
  components: {
    ClientPersonalProfile,
    ClientMedicalProfile,
    ChangeUserPassword,
  },
  data() {
    return {
      tabIndex: 0,
      /* User profil listák */
      userPersonalProfile: {},
      userMedicalProfile: {},
      /* Profilkép */
      imgInfosData: null,
      profileImgCanvas: null,
      clipperImg: null,
      personalityData: {},
      isSaveMethodRun: false,
      validationErrors: [],
      showChangePasswordForm: false,
    };
  },
  methods: {
    //profile adatok kérés
    async getAllProfile() {
      //kérés indítása
      const userProfileResponse = await UserProfileLogic.getUserProfile();
      //sikeres kérés?
      if (!userProfileResponse.Code) {
        //profile adatok tárolása
        this.personalityData = userProfileResponse;
        //profil adatok mediaki része tárolása külön tárolóba
        this.userMedicalProfile = this.personalityData.MedicalProfile;
        //profil adatok személyes része tárolása külön tárolóba
        this.userPersonalProfile = this.personalityData.PersonalProfile;
        //default profil kép beállítása
        this.clipperImg = require("@/assets/annonym-profil-icon.png");
        //profilkép betöltése
        this.getProfilePicture();
        //van kitöltöttségi adat?
        if (
          (this.userPersonalProfile.FillingRate ||
            this.userPersonalProfile.FillingRate.Value == 0) &&
          (this.userMedicalProfile.FillingRate ||
            this.userMedicalProfile.FillingRate.Value == 0)
        ) {
          //igaz, ha a személyes és medikai kitöltöttség létezik vagy nulla az értéke?
          //fejlécnek átadjuk a kitöltöttségi adatokat
          this.$emit("profile-filling-rate-changed", {
            PersonalProfile: this.userPersonalProfile.FillingRate,
            MedicalProfile: this.userMedicalProfile.FillingRate,
          });
          //a kitöltöttségi adatokat eltároljuk a storeban
          /* this.$store.dispatch("changeUser", {
            FillingRate: {
              PersonalProfile: this.userPersonalProfile.FillingRate,
              MedicalProfile: this.userMedicalProfile.FillingRate,
            },
          }); */
        }
      } else {
        this.$bvToast.toast(userProfileResponse.Message, {
          //TODO: name szótárazása
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("client.profile.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //változott a profilkép
    changeProfilePicture(canvas) {
      //az új profilkép canvas
      this.profileImgCanvas = canvas;
      //az új profilkép file adatai
      //this.imgInfosData = info;
    },
    //profil személyes adatok, profilkép mentése
    async saveUserPersonalProfile() {
      const validation = await this.$refs.personalProfile.$validate();
      if (validation) {
        //kép feltöltési válasz
        var uploadResult = null;
        //van új profilkép canvas?
        if (this.profileImgCanvas) {
          //va, canvas előkészítése a blob készítésre
          const blobImg = await new Promise((resolve) =>
            this.profileImgCanvas.toBlob(resolve)
          );
          //képet blobbá alakítjuk
          var blob = new Blob([blobImg]);
          //sikeres blob?
          if (blob) {
            //kép feltöltés kérés
            uploadResult = await AttachmentLogic.uploadAttachment(
              blob,
              this.$enums.AttachmentTypeName.UserProfile.TypeName,
              this.personalityData[
                this.$enums.AttachmentTypeName.UserProfile.EntityIdValueKey
              ]
            );
          }
          //sikeres kép feltöltés kérés?
          if (uploadResult && !uploadResult.Code) {
            //előző profilkép file id tárolása szövegként
            const previousProfilePictureId =
              this.userPersonalProfile.ProfilePictureId + "";
            //új profilkép file id beállítás a profilban
            this.userPersonalProfile.ProfilePictureId =
              uploadResult.AttachmentId;
            //profil mentés kérés
            await this.savePersonalProfileData();
            //van előző profilkép file id?
            if (previousProfilePictureId) {
              //előző profilkép file törlés kérés
              await AttachmentLogic.deleteAttachment(previousProfilePictureId);
            }
          } else {
            this.$bvToast.toast(uploadResult.Message, {
              title: this.$t(
                "requestResponse.attachment.errorUploadProfilePhoto"
              ),
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        } else {
          //nincs, profil adatok mentés kérés
          await this.savePersonalProfileData();
        }
      } else {
        this.validationErrors = this.$refs.personalProfile.validation.errors;
        this.$bvToast.show("validationErrors");
      }
    },
    //profil  személyes adatok mentése
    async savePersonalProfileData() {
      //kérés indítás
      const savePersonalProfileDataResponse = await UserProfileLogic.setUserProfile(
        { PersonalProfile: this.userPersonalProfile },
        this.$loggedUser.UserId
      );
      //sikeres kérés?
      if (
        !savePersonalProfileDataResponse.Code ||
        savePersonalProfileDataResponse.Code == 0
      ) {
        //sikeres üzenet
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
        this.$emit("signed-user-name", this.userPersonalProfile.Name);
      } else {
        this.$bvToast.toast(savePersonalProfileDataResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      //profil adatok frissítés
      this.getAllProfile();
    },
    //profil mediaki adatok mentése
    async saveUserMedicalProfile() {
      //kérés indítása
      const saveUserMedicalProfileResponse = await UserProfileLogic.setUserProfile(
        { MedicalProfile: this.userMedicalProfile },
        this.$loggedUser.UserId
      );
      //sikeres kérés?
      if (
        !saveUserMedicalProfileResponse.Code ||
        saveUserMedicalProfileResponse.Code == 0
      ) {
        //sikeres üzenet
        this.$bvToast.toast(
          this.$t("requestResponse.basic.successModifySave"),
          {
            title: this.$t("base.basic.save"),
            variant: "success",
            solid: true,
          }
        );
      } else {
        this.$bvToast.toast(saveUserMedicalProfileResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      //profil adatok frissítése
      this.getAllProfile();
    },
    //profil kép megjelnítése
    async getProfilePicture() {
      //default kép beállítása
      this.clipperImg = require(`@/assets/annonym-profil-icon.png`);
      //van profilkép a felhasználónak?
      if (this.personalityData.PersonalProfile.ProfilePictureId) {
        //profilkép letöltése és tárolása
        this.clipperImg = await this.getUserProfilePicture(
          this.personalityData.PersonalProfile.ProfilePictureId
        );
        //van tárolt profilkép?
        if (this.clipperImg) {
          //van, profilkép küldés a fejlécnek
          this.$emit("picture-changed", this.clipperImg);
          //profilkép url-jének mentése storageba
          /* this.$store.dispatch("changeUser", {
            Url: this.clipperImg,
          }); */
        } else {
          //nincs, default kép tárolás
          this.clipperImg = require(`@/assets/annonym-profil-icon.png`);
        }
      } else {
        //nincs, default kép tárolás
        this.clipperImg = require(`@/assets/annonym-profil-icon.png`);
      }
    },
    //profilkép letöltése
    async getUserProfilePicture(pictureId) {
      //kérés indítás
      const pictureDocuResponse = await AttachmentLogic.downloadAttachment(
        pictureId
      );
      //sikeres kérés?
      if (!pictureDocuResponse.Code) {
        //kapott kép blobbá alakítás
        const imgBlob = await pictureDocuResponse.blob();
        //visszaadjuk a kapott blob kép urljét
        return URL.createObjectURL(imgBlob);
      } else {
        this.$bvToast.toast(pictureDocuResponse.Message, {
          title: this.$t(
            "requestResponse.attachment.errorDownloadProfilePhoto"
          ),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
      //nincs kapott kép, nullal térünk vissza
      return null;
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //oldal címnek beállítása
    this.$store.dispatch(
      "setPageTitle",this.$t("client.menu.profile"));
    //profil adatok betöltése
    await this.getAllProfile();
  },
};
</script>
<style>
@import "../../../styles/syncfusion-input.css";
@import "../../../styles/syncfusion-dropdown-list.css";
@import "../../../styles/syncfusion-checkbox.css";
.column-padding {
  padding: 5px;
}
.profile .tab-content {
  background: white;
  padding-top: 20px;
  border-radius: 0px 0px 10px 10px;
  border-color: rgb(163, 163, 163) !important;
  border: 1px solid;
}
.profile .custom-control-input:checked ~ .custom-control-label::before {
  color: var(--eh-white);
  border-color: orange !important;
  background-color: orange !important;
}
.profile .custom-control-input:focus {
  color: orange !important;
  outline: none;
}
.profile .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
  border: #adb5bd solid 1px !important;
}
.profile #profile-img {
  border-radius: 50%;
}
.profile #profile-img:focus {
  outline: none;
}
.profile .profile-save-button-box {
  text-align: center;
}
.profile .profile-tab-content {
  width: 90%;
  margin: auto;
}
.profile #active-address-creator-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
.profile #active-address-creator-modal .modal-content header {
  padding: 0px;
}
.profile
  .profile-tabs
  .e-float-input:not(.e-error)
  input:valid
  ~ label.e-float-text,
.profile
  .profile-tabs
  .e-float-input:not(.e-error)
  input:focus
  ~ label.e-float-text {
  color: black;
  opacity: 0.6;
}
@media (max-width: 700px) {
  .profile .profile-tab-content {
    width: 90%;
  }
}
</style>
