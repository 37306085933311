import BaseClinSys from "./BaseClinSys";
import Prescription from "../Modules/Prescription";
import BeverReport from "../Modules/BeverReport";

export default {
  install(Vue) {
    //Modul use
    Vue.use(Prescription);
    Vue.use(BeverReport);
    //Base use
    Vue.use(BaseClinSys);
  },
};
