export default {
  SystemParameters: {
    ParticipantListLoadType: {
      AllUser: { Value: "AllUser" },
      AllProjectUser: { Value: "AllProjectUser" },
      JustRelationship: { Value: "JustRelationship" },
    },
    TagManagedType: {
      Both: { Value: "Both" },
      ProjectTag: { Value: "ProjectTag" },
      PatientTag: { Value: "PatientTag" },
    },
    ParticipantManagerType: {
      Specialist: "specialist",
      Partner: "partner",
    },
  },
};
