<template>
  <!-- TODO: szótár -->
  <div>
    <b-container fluid="true">
      <b-button v-if="isCreated && !isEditable" @click="isEditable = true"
        >{{$t('base.basic.edit')}}
      </b-button>
      <b-button v-if="isCreated && isEditable" @click="setProject"
        >{{$t('base.basic.save')}}
      </b-button>
      <b-button v-if="isCreated && isEditable" @click="cancelProject"
        >{{$t('base.basic.cancel')}}
      </b-button>
      <!-- Projekt -->
      <div v-if="projectForm && projectForm.Research">
        <!-- Nyelvek -->
        <div style="width:200px">
          <fp-select
            :disabled="isCreated && !isEditable"
            type="multi"
            label="Nyelvek"
            :items="Object.values($enums.Languages)"
            valueKey="Value"
            textKey="Text"
            v-model="projectForm.SupportedLanguages"
            @change="
              storeChangedProjectElements(
                'SupportedLanguages',
                projectForm.SupportedLanguages
              )
            "
          >
            <template #element="e">
              <img
                style="margin:0px 5px"
                width="20px"
                :src="require('@/assets' + e.flag)"
              />
              {{ e.Text }}
            </template>
          </fp-select>
        </div>
        <!-- Projekt típusa -->
        <b-row>
          <b-col cols="12" md="2" class="d-flex align-items-center">
            Projekt típusa:
          </b-col>
          <b-col>
            <b-form-radio-group
              :disabled="isCreated && !isEditable"
              v-model="projectForm.Type"
              @change="storeChangedProjectElements('Type', projectForm.Type)"
            >
              <b-form-radio value="HealthProgram">
                Egészség program
              </b-form-radio>
              <b-form-radio value="CareProgram">
                Gondozási program
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <!-- Tesztbeteg -->
        <b-row align-content="center">
          <b-col cols="6" md="2" class="d-flex align-items-center">
            {{ $t("partner.newProject.testPatientCount") }}:
          </b-col>
          <b-col class="col-6 col-md-auto">
            <fp-input
              type="number"
              :disabled="isCreated && !isEditable"
              class="input-margin"
              v-model="projectForm.Research.TreatmentParticipantCount"
              @change="
                storeChangedProjectElements(
                  'Research.TreatmentParticipantCount',
                  projectForm.Research.TreatmentParticipantCount
                )
              "
            />
          </b-col>
        </b-row>
        <!-- Kontrollbeteg -->
        <b-row align-content="center">
          <b-col cols="6" md="2" class="d-flex align-items-center">
            {{ $t("partner.newProject.controlPatientCount") }}:
          </b-col>
          <b-col class="col-6 col-md-auto">
            <fp-input
              type="number"
              :disabled="isCreated && !isEditable"
              v-model="projectForm.Research.ControlParticipantCount"
              @change="
                storeChangedProjectElements(
                  'Research.ControlParticipantCount',
                  projectForm.Research.ControlParticipantCount
                )
              "
            />
          </b-col>
        </b-row>
        <!-- Projektnév -->
        <b-row>
          <b-col cols="12" md="2" class="d-flex align-items-center">
            {{ $t("partner.projects.projectName") }}:
          </b-col>
          <b-col>
            <b-row cols="1" cols-md="3">
              <b-col
                v-for="lang in projectForm.SupportedLanguages"
                :key="'ProjectName-' + lang"
              >
                <fp-input
                  :label="$enums.Languages[lang].Text"
                  :disabled="isCreated && !isEditable"
                  class="input-margin"
                  v-model="projectForm.Research.Name[lang]"
                  @change="
                    storeChangedProjectElements(
                      'Research.Name',
                      projectForm.Research.Name
                    )
                  "
                />
              </b-col>
              <b-col
                v-if="
                  !projectForm.SupportedLanguages ||
                    projectForm.SupportedLanguages.length == 0
                "
              >
                <!-- TODO: szótár -->
                <fp-input
                  label="A megadáshoz válasszon nyelvet!"
                  class="input-margin"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Projektcím -->
        <b-row>
          <b-col cols="12" md="2" class="d-flex align-items-center">
            {{ $t("partner.projects.projectTitle") }}:
          </b-col>
          <b-col>
            <b-row cols="1" cols-md="3">
              <b-col
                v-for="lang in projectForm.SupportedLanguages"
                :key="'ProjectDescription-' + lang"
              >
                <fp-input
                  :disabled="isCreated && !isEditable"
                  :label="$enums.Languages[lang].Text"
                  class="input-margin"
                  v-model="projectForm.Research.Description[lang]"
                  @change="
                    storeChangedProjectElements(
                      'Research.Description',
                      projectForm.Research.Description
                    )
                  "
                />
              </b-col>
              <b-col
                v-if="
                  !projectForm.SupportedLanguages ||
                    projectForm.SupportedLanguages.length == 0
                "
              >
                <!-- TODO: szótár -->
                <fp-input
                  label="A megadáshoz válasszon nyelvet!"
                  class="input-margin"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- Időtartam -->
        <b-row style="margin-top: 10px">
          <b-col cols="12" md="2" class="d-flex align-items-center">
            {{ $t("partner.projects.timestamp") }}:
          </b-col>
          <b-col cols="6" md="3">
            <date-picker
              :disabled="isCreated && !isEditable"
              label="Kezdés"
              v-model="projectForm.Research.StartDate"
              @change="
                storeChangedProjectElements(
                  'Research.StartDate',
                  projectForm.Research.StartDate
                )
              "
            />
          </b-col>
          <b-col cols="6" md="3">
            <date-picker
              :disabled="isCreated && !isEditable"
              label="Vége"
              v-model="projectForm.Research.EndDate"
              @change="
                storeChangedProjectElements(
                  'Research.EndDate',
                  projectForm.Research.EndDate
                )
              "
            />
          </b-col>
        </b-row>
        <hr />
        <!-- Titkosság -->
        <b-row>
          <b-col cols="12" md="2" class="d-flex align-items-center">
            {{ $t("partner.projects.privacy") }}:
          </b-col>
          <b-col>
            <b-form-radio-group
              :disabled="isCreated && !isEditable"
              id="projectForm-radio"
              v-model="privacy"
              text-field="Text"
              value-field="Value"
            >
              <b-form-radio
                v-for="(option, name) in privacyOptions"
                :key="'projectForm-radio-' + name"
                :value="option.Value"
              >
                {{ $t(option.Text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <hr />
        <!-- Projektcél -->
        <b-row>
          <b-col cols="12" md="2" class="d-flex align-items-top">
            {{ $t("partner.projects.projectGoal") }}:
          </b-col>
          <b-col>
            <b-row cols="1">
              <b-col
                v-for="lang in projectForm.SupportedLanguages"
                :key="'ResearchGoal-' + lang"
              >
                <div style="margin:10px 0px">
                  <img
                    style="margin:0px 5px"
                    width="20px"
                    :src="require('@/assets' + $enums.Languages[lang].flag)"
                  />
                  {{ $enums.Languages[lang].Text }}
                  <v-md-editor
                    v-if="!isCreated || isEditable"
                    v-model="projectForm.Research.ResearchGoal[lang]"
                    @change="
                      storeChangedProjectElements(
                        'Research.ResearchGoal',
                        projectForm.Research.ResearchGoal
                      )
                    "
                    height="400px"
                    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link"
                    right-toolbar="preview sync-scroll fullscreen"
                  >
                  </v-md-editor>
                  <v-md-preview
                    v-else
                    :text="projectForm.Research.ResearchGoal[lang]"
                    height="400px"
                    class="no-margin-markdown"
                  >
                  </v-md-preview>
                </div>
              </b-col>
              <b-col
                v-if="
                  !projectForm.SupportedLanguages ||
                    projectForm.SupportedLanguages.length == 0
                "
              >
                <!-- TODO: szótár -->
                <fp-input
                  label="A megadáshoz válasszon nyelvet!"
                  class="input-margin"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <!-- Kritériumok -->
        <b-row>
          <b-col cols="12" md="2" class="d-flex align-items-top">
            {{ $t("partner.projects.selectionCriteria") }}:
          </b-col>
          <b-col>
            <b-row cols="1">
              <b-col
                v-for="lang in projectForm.SupportedLanguages"
                :key="'SelectionCriteria-' + lang"
              >
                <div style="margin:10px 0px">
                  <img
                    style="margin:0px 5px"
                    width="20px"
                    :src="require('@/assets' + $enums.Languages[lang].flag)"
                  />
                  {{ $enums.Languages[lang].Text }}
                  <v-md-editor
                    v-if="!isCreated || isEditable"
                    v-model="projectForm.Research.SelectionCriteria[lang]"
                    @change="
                      storeChangedProjectElements(
                        'Research.SelectionCriteria',
                        projectForm.Research.SelectionCriteria
                      )
                    "
                    height="400px"
                    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link"
                    right-toolbar="preview sync-scroll fullscreen"
                  >
                  </v-md-editor>
                  <v-md-preview
                    v-else
                    :text="projectForm.Research.SelectionCriteria[lang]"
                    height="400px"
                    class="no-margin-markdown"
                  >
                  </v-md-preview>
                </div>
              </b-col>
              <b-col
                v-if="
                  !projectForm.SupportedLanguages ||
                    projectForm.SupportedLanguages.length == 0
                "
              >
                <!-- TODO: szótár -->
                <fp-input
                  label="A megadáshoz válasszon nyelvet!"
                  class="input-margin"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr />
        <!-- Leírás -->
        <b-row>
          <b-col cols="12" md="2" class="d-flex align-items-top">
            {{ $t("partner.projects.description") }}:
          </b-col>
          <b-col>
            <b-row cols="1">
              <b-col
                v-for="lang in projectForm.SupportedLanguages"
                :key="'ResearchPlan-' + lang"
              >
                <div style="margin:10px 0px">
                  <img
                    style="margin:0px 5px"
                    width="20px"
                    :src="require('@/assets' + $enums.Languages[lang].flag)"
                  />
                  {{ $enums.Languages[lang].Text }}
                  <v-md-editor
                    v-if="!isCreated || isEditable"
                    v-model="projectForm.Research.ResearchPlan[lang]"
                    @change="
                      storeChangedProjectElements(
                        'Research.ResearchPlan',
                        projectForm.Research.ResearchPlan
                      )
                    "
                    height="400px"
                    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link"
                    right-toolbar="preview sync-scroll fullscreen"
                  >
                  </v-md-editor>
                  <v-md-preview
                    v-else
                    :text="projectForm.Research.ResearchPlan[lang]"
                    height="400px"
                    class="no-margin-markdown"
                  >
                  </v-md-preview>
                </div>
              </b-col>
              <b-col
                v-if="
                  !projectForm.SupportedLanguages ||
                    projectForm.SupportedLanguages.length == 0
                "
              >
                <!-- TODO: szótár -->
                <fp-input
                  label="A megadáshoz válasszon nyelvet!"
                  class="input-margin"
                  :disabled="true"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { ProjectLogic } from "@/logic/backend/project";
import DatePicker from "@/components/custom-components/form/DatePicker";

export default {
  name: "DataSheet",
  components: {
    DatePicker,
  },
  props: {
    project: { type: Object, required: true },
    uploading: Boolean,
    isCreated: Boolean,
    createdProjectId: Number,
  },
  data() {
    return {
      privacyOptions: this.$enums.Privacy,
      isEditable: false,
      changeProjectElements: null,
      defaultProject: JSON.parse(JSON.stringify(this.project)),
      projectForm: JSON.parse(JSON.stringify(this.project)),
    };
  },
  computed: {
    privacy: {
      // getter
      get: function() {
        return this.projectForm.Research.DoctorBlind
          ? this.$enums.Privacy.DoubleBlind.Value
          : this.projectForm.Research.PatientBlind
          ? this.$enums.Privacy.Blind.Value
          : this.$enums.Privacy.None.Value;
      },
      // setter
      set: function(newValue) {
        switch (newValue) {
          case this.$enums.Privacy.DoubleBlind.Value:
            this.projectForm.Research.DoctorBlind = true;
            this.projectForm.Research.PatientBlind = true;
            break;
          case this.$enums.Privacy.Blind.Value:
            this.projectForm.Research.DoctorBlind = false;
            this.projectForm.Research.PatientBlind = true;
            break;
          case this.$enums.Privacy.None.Value:
            this.projectForm.Research.DoctorBlind = false;
            this.projectForm.Research.PatientBlind = false;
            break;
        }
        this.storeChangedProjectElements(
          "Research.DoctorBlind",
          this.projectForm.Research.DoctorBlind
        );
        this.storeChangedProjectElements(
          "Research.PatientBlind",
          this.projectForm.Research.PatientBlind
        );
      },
    },
  },
  watch: {
    isCreated(input) {
      if (input) {
        this.defaultProject = JSON.parse(JSON.stringify(this.project));
        this.changeProjectElements = null;
      }
    },
    isEditable(input) {
      if (input) {
        this.$emit("editingTab", true);
      } else {
        this.changeProjectElements = null;
        this.$emit("editingTab", false);
      }
    },
    /*  project(input) {
      this.projectForm = JSON.parse(JSON.stringify(input));
    }, */
  },
  methods: {
    getTextKeyByLanguage(textObject) {
      const languages = Object.keys(textObject);
      if (languages.includes(this.$i18n.locale)) {
        return this.$i18n.locale;
      } else if (languages.includes("en")) {
        return "en";
      } else {
        return languages[0];
      }
    },
    //módosult elemek tárolása
    storeChangedProjectElements(path, value) {
      if (!this.changeProjectElements) {
        this.changeProjectElements = {};
      }
      this.$set(this.changeProjectElements, path, value);
      this.$emit("update:project", this.projectForm);
    },
    cancelProject() {
      this.projectForm = JSON.parse(JSON.stringify(this.defaultProject));
      this.$emit("update:project", this.projectForm);
      this.isEditable = false;
    },
    async setProject() {
      const setProjectResponse = await ProjectLogic.setProject(
        this.changeProjectElements,
        this.createdProjectId
      );
      if (!setProjectResponse.Code) {
        //TODO: sikeres
        this.isEditable = false;
        this.changeProjectElements = null;
      } else {
        //TODO: hiba
      }
    },
  },
};
</script>
