<template>
  <div v-if="usage">
    <code-name-pair
      label="Felírt termék felhasználás típusa"
      :disabled="disabled"
      @changeValue="changeDosageType"
      v-model="usage.Type"
      :items="eesztDosageTypeList"
      listValueKey="EntryId"
      listTextKey="Name"
      :required="true"
    />
    <fp-input
      v-if="
        (usage.Type &&
          (usage.Type.Id == $enums.EesztDosageCondition.ByPartOfDay ||
            usage.Type.Id == $enums.EesztDosageCondition.ByFrequence)) ||
        showUsageFrequency
      "
      label="Felírt termék felhasználási gyakoriság"
      description="pl.: 3x1 esetén 3, vagy Reggel (R), Délben (D), Este (E), Lefekvés előtt (LE)"
      :disabled="disabled"
      @change="changeValue('Frequency', $event)"
      v-model="usage.Frequency"
    />
    <fp-input
      v-if="
        (usage.Type &&
          (usage.Type.Id == $enums.EesztDosageCondition.ByPartOfDay ||
            usage.Type.Id == $enums.EesztDosageCondition.ByFrequence)) ||
        showUsageAmount
      "
      label="Felírt termék felhasználási mennyiség, gyakoriság szerint"
      :disabled="disabled"
      @change="changeValue('Amount', $event)"
      v-model="usage.Amount"
    />
    <fp-input
      v-if="
        (usage.Type &&
          (usage.Type.Id == $enums.EesztDosageCondition.ByPartOfDay ||
            usage.Type.Id == $enums.EesztDosageCondition.ByFrequence)) ||
        showUsageUnit
      "
      label="Felírt termék felhasználási gyakoriság egység"
      :disabled="disabled"
      @change="changeValue('Unit', $event)"
      v-model="usage.Unit"
    />
    <fp-text-area
      v-if="
        (usage.Type && usage.Type.Id == $enums.EesztDosageCondition.ByText) ||
        showUsageDescription
      "
      label="Felírt termék felhasználása szövegesen"
      :disabled="disabled"
      @change="changeValue('UsageDescription', $event)"
      v-model="usage.UsageDescription"
    />
  </div>
</template>
<script>
import CodeNamePair from "./CodeNamePair.vue";
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import moment from "moment";

export default {
  name: "ProductUsage",
  components: { CodeNamePair },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      usage: null,
      changeUsageValue: {},
      defaultUsage: {
        Type: null,
        Frequency: null,
        Amount: null,
        Unit: null,
        UsageDescription: null,
      },
      showUsageFrequency: false,
      showUsageAmount: false,
      showUsageUnit: false,
      showUsageDescription: false,
      eesztDosageTypeList: null,
    };
  },
  watch: {
    value() {
      this.setUsage();
    },
  },
  computed: {
    hasUsageData() {
      return Object.values(this.usage).some(
        (x) => x !== null && x !== undefined && x !== ""
      );
    },
  },
  methods: {
    changeDosageType(input) {
      if (input && input.Id) {
        this.$set(this.usage.Type, "Id", input.Id);
        if (
          input.Id == this.$enums.EesztDosageCondition.ByPartOfDay ||
          input.Id == this.$enums.EesztDosageCondition.ByFrequence
        ) {
          this.showUsageFrequency = true;
          this.showUsageAmount = true;
          this.showUsageUnit = true;
          this.showUsageDescription = false;
          this.usage.UsageDescription = null;
          this.changeValue("UsageDescription", null);
        } else if (input.Id == this.$enums.EesztDosageCondition.ByText) {
          this.showUsageFrequency = false;
          this.showUsageAmount = false;
          this.showUsageUnit = false;
          this.showUsageDescription = true;
          this.usage.Frequency = null;
          this.changeValue("Frequency", null);
          this.usage.Amount = null;
          this.changeValue("Amount", null);
          this.usage.Unit = null;
          this.changeValue("Unit", null);
        }
      }
      this.changeValue("Type", input);
    },
    setUsage() {
      if (this.value) {
        this.usage = this.value;
      } else {
        this.usage = JSON.parse(JSON.stringify(this.defaultUsage));
      }
    },
    changeValue(key, value) {
      if (this.hasUsageData) {
        this.changeUsageValue[key] = value;
        this.$emit("change", this.usage);
        this.$emit("changeValue", this.changeUsageValue);
      } else {
        this.$emit("change", null);
        this.$emit("changeValue", null);
      }
    },
    async getBase() {
      const getBaseResponse = await BaseDataLogic.getBaseData(
        this.$enums.BaseName.eeszt_dosage_type.Value,
        {
          ["ValidFrom-to"]: moment().format("YYYY-MM-DD"),
          ["ValidTo-from"]: moment().format("YYYY-MM-DD"),
        }
      );
      if (!getBaseResponse.Code) {
        this.eesztDosageTypeList = getBaseResponse;
        this.eesztDosageTypeList.splice(0, 0, {
          Name: "Nincs megadva",
          EntryId: null,
        });
      } else {
        this.eesztDosageTypeList = [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
  },
  async mounted() {
    await this.getBase();
    this.setUsage();
  },
};
</script>
