<template>
  <div>
    <fp-table-list
      :bordered="true"
      :hasPagination="true"
      :items="reportList"
      :fields="reportListField"
      sortBy="ReportId"
      :sortDesc="true"
    >
      <template #Data="row">
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Tranzakció azonosító'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="file-binary" />
            <b-icon stacked icon="text-left" scale="0.5" shift-v="3" />
          </b-iconstack>
          {{ row.item.ReportId }}
        </div>
        <!-- DateRange -->
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Jelentés időszak'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="calendar3-range" />
          </b-iconstack>
          {{ $convertDateToString(row.item.StartDate, "YYYY-MM-DD") }} -
          {{ $convertDateToString(row.item.EndDate, "YYYY-MM-DD") }}
        </div>
        <!-- CreatedAt -->
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Jelentés létrehozás dátuma'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="calendar" />
            <b-icon stacked icon="pencil-fill" scale="0.6" shift-v="-1.5" />
          </b-iconstack>
          {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
        </div>
        <!-- State -->
        <div class="report-element-data">
          <b-iconstack v-b-tooltip.hover="'Jelentés állapota'" font-scale="2.5">
            <b-icon stacked icon="arrow-clockwise"></b-icon>
            <b-icon
              stacked
              icon="x"
              scale="0.5"
              shift-v="1.5"
              shift-h="-1"
            ></b-icon>
            <b-icon
              stacked
              icon="check"
              scale="0.6"
              shift-v="-2"
              shift-h="1.2"
            ></b-icon>
          </b-iconstack>
          {{
            $enums.Prescription.NeakReportStatus[row.item.State]
              ? $enums.Prescription.NeakReportStatus[row.item.State].Text
              : row.item.State
          }}
        </div>
        <!-- Transaction -->
        <div class="report-element-data">
          <b-iconstack
            v-b-tooltip.hover="'Tranzakció azonosító'"
            font-scale="1.8"
            style="margin: 0px 5px"
          >
            <b-icon stacked icon="file-font" />
          </b-iconstack>
          {{ row.item.TransactionId }}
        </div>
      </template>
      <template #DateRange="row">
        {{ $convertDateToString(row.item.StartDate, "YYYY-MM-DD") }} -
        {{ $convertDateToString(row.item.EndDate, "YYYY-MM-DD") }}
      </template>
      <template #CreatedAt="row">
        {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
      </template>
      <template #Operations="row">
        Jelentés műveletek:
        <div>
          <b-icon-eye-fill
            class="h4"
            style="cursor: pointer"
            v-b-tooltip.hover="'Megtekintés'"
            @click="$set(row.item, 'showFormModal', true)"
          />
          <b-icon-trash-fill
            style="cursor: pointer"
            v-b-tooltip.hover="'Jelentés törlése'"
            class="h4"
            variant="danger"
            @click="deleteReport(row.item.ReportId)"
          />
          <b-icon
            style="cursor: pointer"
            v-b-tooltip.hover="'Jelentés ellenőrzése'"
            class="h4"
            icon="check2-circle"
            @click="checkReport(row.item.ReportId)"
          />
          <report-form-modal
            v-model="row.item.showFormModal"
            :reportId="row.item.ReportId"
            @refreshList="getReportList"
          />
        </div>
        <hr />
        Fájl előállítások:
        <div>
          <b-iconstack
            @click="downloadHeader(row.item)"
            font-scale="2"
            style="margin: 5px; cursor: pointer"
            v-b-tooltip.hover="'Fejrész letöltése'"
          >
            <b-icon
              stacked
              icon="card-heading"
              scale="0.35"
              shift-h="-8"
              shift-v="4"
            ></b-icon>
            <b-icon stacked icon="download" shift-h="-2"></b-icon>
          </b-iconstack>
          <b-iconstack
            @click="downloadBody(row.item)"
            font-scale="2"
            style="margin: 5px; cursor: pointer"
            v-b-tooltip.hover="'Törzsrész letöltése'"
          >
            <b-icon
              stacked
              icon="file-post"
              scale="0.5"
              shift-h="4"
              shift-v="5"
            ></b-icon>
            <b-icon stacked icon="download" shift-h="-2"></b-icon>
          </b-iconstack>
          <b-iconstack
            @click="excelExport(row.item)"
            font-scale="2"
            style="margin: 5px; cursor: pointer"
            v-b-tooltip.hover="'Excel export'"
          >
            <b-icon
              stacked
              icon="file-ruled"
              scale="0.5"
              shift-h="4"
              shift-v="5"
            ></b-icon>
            <b-icon stacked icon="download" shift-h="-2"></b-icon>
          </b-iconstack>
        </div>
      </template>
    </fp-table-list>
    <fp-form-modal
      title="Jelentés hiányosságok"
      v-model="showReportValidateErrors"
    >
      <template #content>
        <li
          v-for="(error, index) in reportValidateErrors"
          :key="'report-validate-error' + index"
        >
          {{ error }}
        </li>
      </template>
    </fp-form-modal>
  </div>
</template>
<script>
import moment from "moment";
import ReportFormModal from "../Report/ReportFormModal.vue";
import { MedicalDeviceLogic } from "../../../../Logic/Backend/medical-device";

export default {
  name: "BeverReportList",
  components: { ReportFormModal },
  props: {
    refreshing: Boolean,
  },
  data() {
    return {
      reportList: null,
      reportListField: [
        { key: "Data", label: "Jelentés adatok" },
        { key: "Description", label: "Leírás" },
        { key: "Operations", label: "Műveletek" },
      ],
      showReportValidateErrors: false,
      reportValidateErrors: null,
    };
  },
  watch: {
    async refreshing(input) {
      if (input) {
        await this.getReportList();
        this.$emit("update:refreshing", false);
      }
    },
  },
  methods: {
    async downloadHeader(report) {
      document.getElementById("app").style.cursor = "wait !important";
      const headerResponse = await MedicalDeviceLogic.downloadFinanceReportHeader(
        report.ReportId
      );
      if (!headerResponse.Code) {
        const blob = await headerResponse.Data.blob();
        const contentDisposition = headerResponse.Headers.get(
          "content-disposition"
        );
        const filenameParam = contentDisposition
          .split(";")
          .find((x) => x.includes("filename="));
        const filename = filenameParam
          ? filenameParam.split("=")[1]
          : "Jelentes_Fej_" +
            moment(report.From).format("YYYYMMDD") +
            "_" +
            moment(report.To).format("YYYYMMDD");
        this.downloadFile(blob, filename);
      } else {
        this.$bvToast.toast(headerResponse.Message, {
          variant: "danger",
          title: "Hiba történt a jelentés fejrészének letöltése során!",
          solid: true,
        });
      }
      document.getElementById("app").style.cursor = "default";
    },
    async downloadBody(report) {
      document.getElementById("app").style.cursor = "wait !important";
      const bodyResponse = await MedicalDeviceLogic.downloadFinanceReportBody(
        report.ReportId
      );
      if (!bodyResponse.Code) {
        const blob = await bodyResponse.Data.blob();
        const contentDisposition = bodyResponse.Headers.get(
          "content-disposition"
        );
        const filenameParam = contentDisposition
          .split(";")
          .find((x) => x.includes("filename="));
        const filename = filenameParam
          ? filenameParam.split("=")[1]
          : "Jelentes_Torzs_" +
            moment(report.From).format("YYYYMMDD") +
            "_" +
            moment(report.To).format("YYYYMMDD");
        this.downloadFile(blob, filename);
      } else {
        this.$bvToast.toast(bodyResponse.Message, {
          variant: "danger",
          title: "Hiba történt a jelentés törzsrészének letöltése során!",
          solid: true,
        });
      }
      document.getElementById("app").style.cursor = "default";
    },
    async excelExport(report) {
      document.getElementById("app").style.cursor = "wait !important";
      const getResponse = await MedicalDeviceLogic.downloadFinanceReportExcel(
        report.ReportId
      );
      if (!getResponse.Code || getResponse.Code == 0) {
        const blob = await getResponse.Data.blob();
        const contentDisposition = getResponse.Headers.get(
          "content-disposition"
        );
        const filenameParam = contentDisposition
          .split(";")
          .find((x) => x.includes("filename="));
        const filename = filenameParam
          ? filenameParam.split("=")[1]
          : "Jelentes_osszesito_" +
            moment(report.From).format("YYYYMMDD") +
            "_" +
            moment(report.To).format("YYYYMMDD");
        this.downloadFile(blob, filename);
      } else {
        this.$bvToast.toast(getResponse.Message, {
          variant: "danger",
          title: "Hiba történt a excel importálása során!",
          solid: true,
        });
      }
      document.getElementById("app").style.cursor = "default";
    },
    downloadFile(blob, filename) {
      var file = new File([blob], filename);
      var fileURL = URL.createObjectURL(file);
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.download = filename;
      fileLink.target = "_blank";
      fileLink.click();
    },
    async getReportList(params) {
      const getResponse = await MedicalDeviceLogic.getFinanceReport(params);
      if (!getResponse.Code) {
        this.reportList = getResponse;
      } else {
        this.$bvToast.toast(getResponse.Message, {
          variant: "danger",
          title: "Hiba történt a jelentés lista betöltése!",
          solid: true,
        });
      }
    },
    async deleteReport(reportId) {
      if (
        confirm(
          "Bitosan törölni szeretné a " + reportId + " azonosítójú jelentést?"
        )
      ) {
        const deleteResponse = await MedicalDeviceLogic.deleteReport(reportId);
        if (!deleteResponse.Code || deleteResponse.Code == 0) {
          this.$bvToast.toast(
            "Sikeresen törölte a " + reportId + " azonosítójú jelentést!",
            {
              variant: "info",
              title: "Sikeres törlés!",
              solid: true,
            }
          );
          await this.getReportList();
        } else {
          this.$bvToast.toast(deleteResponse.Message, {
            variant: "danger",
            title:
              "Hiba történt a " +
              reportId +
              " azonosítójú jelentés törlése során!",
            solid: true,
          });
        }
      }
    },
    async checkReport(reportId) {
      const checkResponse = await MedicalDeviceLogic.getReportValidate(
        reportId
      );
      if (!checkResponse.Code) {
        if (checkResponse && checkResponse.length) {
          this.showReportValidateErrors = true;
          this.reportValidateErrors = checkResponse.map((v) => v.ErrorMessage);
        } else {
          this.$bvToast.toast(
            "A " + reportId + " azonosítójú jelentés adatai helyesek!",
            {
              variant: "success",
              title: "Sikeres ellenőrzés!",
              solid: true,
            }
          );
        }
      } else {
        this.$bvToast.toast(deleteResponse.Message, {
          variant: "danger",
          title:
            "Hiba történt a " +
            reportId +
            " azonosítójú jelentés ellenőrzése során!",
          solid: true,
        });
      }
    },
  },
  async mounted() {
    await this.getReportList();
  },
};
</script>
<style scoped>
.report-element-data {
  display: flex;
  align-items: center;
  margin: 5px 0px;
}
</style>
