export const HuBase = {
  "base": {
    "address": {
      "address": "Cím",
      "building": "Házszám",
      "city": "Város",
      "latitude": "Szélesség",
      "longitude": "Hosszúság",
      "street": "Közterület neve és jellege",
      "subBuilding": "További cím kiegészítés",
      "zip": "Irányítószám"
    },
    basic: {
      "back": "Vissza",
      "add": "Hozzáadás",
      "cancel": "Mégsem",
      "cancellation": "Visszavonás",
      "clear": "Visszaállítás",
      "clearFilter": "Szűrő ürítése",
      "close": "Bezár",
      "current": "Aktuális",
      "delete": "Törlés",
      "download": "Letöltés",
      "edit": "Szerkesztés",
      "error": "Hiba",
      "exit": "Kilépés",
      "export": "Exportálás",
      "filling": "Kitöltés",
      "filter": "Szűrő",
      "import": "Importálás",
      "loading": "Betöltés...",
      "next": "Tovább",
      "underDevelopment": "Fejlesztés alatt",
      "unit": "Mértékegység",
      "upload": "Feltöltés",
      "value": "Érték",
      "view": "Megtekintés",
      "previous": "Előző",
      "save": "Mentés",
      "search": "Keresés",
      "select": "Kiválaszt",
      "selectSomeElement": "{piece} kiválasztott elem",
      "send": "Beküldés",
      "share": "Megosztás",
      "speach": "Diktálás",
      "stop": "Leállítás",
      "sync": "Szinkronizáció",
      update: 'Frissítés',
      login:'Bejelentkezés'
    },
    "calendar": {
      "daily": "Napi",
      "date": "Dátum",
      "day": "Nap",
      "from": "{date}-tól",
      "month": "Hónap",
      "monthly": "Havi",
      "time": "Idő",
      "to": "{date}-ig",
      "today": "Ma",
      "week": "Hét",
      "weekly": "Heti",
      "year": "Év",
      "yearly": "Éves",
      bootstrap: {
        labelPrevDecade: "Előző évtized",
        labelPrevYear: "Előző év",
        labelPrevMonth: "Előző hónap",
        labelCurrentMonth: "Aktuális hónap",
        labelNextMonth: "Következő hónap",
        labelNextYear: "Következő év",
        labelNextDecade: "Következő évtized",
        labelToday: "Ma",
        labelSelected: "Kiválasztott dátum",
        labelNoDateSelected: "Nincs kiválasztott dátum",
        labelCalendar: "Naptár",
        labelNav: "Naptár navigáció",
        labelHelp: "A kurzor billentyűkkel navigálhat a naptárban",
      },
    },
    "module": "Modul",
    "noData": "Nincs megjeleníthető elem",
    "pleaseSelect": "Kérem válasszon",
    "title": "TMKK",
    usernameEmail:'Felhasználói fiók (email)',
    password:'Jelszó'
  },
}