<template>
  <b-row cols="1" cols-md="2">
    <div
      v-if="visibleDashboardElement($enums.UserInterfaceElement.UserTask.Value)"
      class="eh-panel"
    >
      <daily-task />
    </div>
    <div
      v-if="
        hasSelectedProject() &&
        visibleDashboardElement($enums.UserInterfaceElement.UserTask.Value)
      "
      class="eh-panel"
    >
      <project-progress :project="project" />
    </div>
  </b-row>
</template>
<script>
import DailyTask from "@/components/dashboard/DailyTask.vue";
import ProjectProgress from "@/components/dashboard/ProjectProgress.vue";
export default {
  components: { DailyTask, ProjectProgress },
  props: {
    project: Object,
  },
  methods: {
    visibleDashboardElement(element) {
      if (!this.project || !this.project.EnabledUiElements) {
        return true;
      } else {
        return (
          this.project.EnabledUiElements.HealthProfessional &&
          this.project.EnabledUiElements.HealthProfessional.includes(element)
        );
      }
    },
    hasSelectedProject() {
      return (
        this.$loggedUser.SelectedProject &&
        this.$loggedUser.SelectedProject.ProjectId
      );
    },
  },
  mounted() {
    this.$store.dispatch(
      "setPageTitle",this.$t("healthProfessional.menu.home"));
  },
};
</script>
