<template>
  <div v-if="codeNamePair">
    <fp-select
      :label="label"
      :description="description"
      v-model="codeNamePair.Id"
      @change="changeValue"
      :disabled="disabled"
      :items="items"
      :valueKey="listValueKey"
      :textKey="listTextKey"
      :required="required"
      :state="state"
      :error="error"
      :searchable="searchable"
      @search="$emit('search', $event)"
    >
      <template #element="e">
        <slot name="element" v-bind="e">
          {{ e[listTextKey] }}
        </slot>
      </template>
    </fp-select>
  </div>
</template>
<script>
export default {
  name: "CodeNamePair",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    label: String,
    description: String,
    required: Boolean,
    disabled: Boolean,
    state: { type: Boolean, default: true },
    error: String,
    items: Array,
    listValueKey: String,
    listTextKey: String,
    value: Object,
    searchable: Boolean,
  },
  data() {
    return {
      codeNamePair: null,
      filterList: null,
      defaultCodeName: {
        Id: null,
        Code: null,
        Name: null,
      },
    };
  },
  watch: {
    value() {
      this.setCodeNamePair();
    },
  },
  methods: {
    setCodeNamePair() {
      if (this.value) {
        this.codeNamePair = this.value;
      } else {
        this.codeNamePair = JSON.parse(JSON.stringify(this.defaultCodeName));
      }
    },
    changeValue(value) {
      if (value != null && value !== undefined) {
        this.$emit("change", { Id: value });
        this.$emit("changeValue", { Id: value });
      } else {
        this.$emit("change", null);
        this.$emit("changeValue", null);
      }
    },
  },
  mounted() {
    this.setCodeNamePair();
  },
};
</script>
