<template>
  <b-row class="site-body-box" style="max-width:800px; text-align:justify" align-h="center">
    <div class="site-body">
      <label class="title">Híreink</label>
      <div>
          <div style="text-align:center;margin-bottom:50px">
        <h4>SAJTÓTÁJÉKOZTATÓVAL EGYBEKÖTÖTT PROJEKTBEMUTATÓ RENDEZVÉNY</h4>
        <h5>
          Bemutatjuk a Transzlációs Medicina Kutató Központ munkáját,
          együttműködési lehetőségeit és eddig elvégzett eredményeit.
        </h5>
          </div>
        <p>
          <u>Helyszín:</u> Dél-Pesti Centrumkórház Országos Hematológiai és
          Infektológiai Intézet, Szent László telephelye 19-es épület Díszterem,
          1097 Budapest Albert Flórián út 5-7.
        </p>
        <p><u>Időpont:</u> 2022. december 8. 9 óra</p>
        <p><u>Program</u></p>
        <p>8.45 Sajtó és a vendégek fogadása</p>
        <p>
          9.00 Köszöntő: Dr. Birkner Zoltán Nemzeti Kutatási, Fejlesztési és
          Innovációs Hivatal elnöke
        </p>
        <p>
          9.15 Nyitó előadás: Dr. Boér Gábor Belügyminisztérium egészségügy
          finanszírozásért és fejlesztésért felelős helyettes államtitkár
        </p>
        <p>
          9.30 Transzlációs Medicina Kutató Központ bemutatása: Prof. Dr.
          Vályi-Nagy István Dél-pesti Centrumkórház Országos Hematológiai és
          Infektológiai Intézet főigazgatója
        </p>
        <p>9.45. Sajtó kérdései</p>
        <p>10.00 Sajtó távozik, kávé szünet</p>
        <p>
          10.15 Transzlációs Medicina Kutató Központ informatikai megoldásai:
          Dr. Máté Attila ügyvezető, eHealth Software Solutions Kft.
        </p>
        <p>
          10.30 Transzlációs Medicina Kutató Központ adattudományi eredményei:
          Dr. Bacskai Miklós ügyvezető, Healthware Tanácsadó Kft.
        </p>
      </div>
    </div>
  </b-row>
</template>
<script>
export default {
  name: "ResearchPartners",
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.parag {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
