<template>
  <fp-form-modal
    hide-footer
    id="mood-input-modal"
    v-model="showInputModal"
    :title="$t('module.diaries.mood.addNewPost')"
  >
    <template #content>
      <b-form :class="theme">
        <div>
          <fp-slider
            :state="!validation.hasError('newEntry.MoodValue')"
            :error="validation.firstError('newEntry.MoodValue')"
            :min="0"
            :max="5"
            :step="0.5"
            v-model="newEntry.MoodValue"
            :description="$t('module.diaries.mood.moodValueInputDescription')"
            :title="$t('module.diaries.mood.moodValueInput')"
          />
          <fp-slider
            :state="!validation.hasError('newEntry.EnergyLevel')"
            :error="validation.firstError('newEntry.EnergyLevel')"
            :min="0"
            :max="5"
            :step="0.5"
            v-model="newEntry.EnergyLevel"
            :description="$t('module.diaries.mood.energyLevelInputDescription')"
            :title="$t('module.diaries.mood.energyLevelInput')"
          />
          <fp-slider
            :state="!validation.hasError('newEntry.StressLevel')"
            :error="validation.firstError('newEntry.StressLevel')"
            :min="0"
            :max="5"
            :step="0.5"
            v-model="newEntry.StressLevel"
            :description="$t('module.diaries.mood.stressLevelInputDescription')"
            :title="$t('module.diaries.mood.stressLevelInput')"
          />
          <fp-slider
            :state="!validation.hasError('newEntry.BalanceLevel')"
            :error="validation.firstError('newEntry.BalanceLevel')"
            :min="0"
            :max="5"
            :step="0.5"
            v-model="newEntry.BalanceLevel"
            :description="
              $t('module.diaries.mood.balanceLevelInputDescription')
            "
            :title="$t('module.diaries.mood.balanceLevelInput')"
          />
          <fp-date-time-picker
            :state="!validation.hasError('newEntry.MeasuredAt')"
            :error="validation.firstError('newEntry.MeasuredAt')"
            :requited="true"
            :label="$t('base.calendar.date')"
            v-model="newEntry.MeasuredAt"
          />
          <b-button @click="modalOk">{{ $t("base.basic.save") }}</b-button>
        </div>
      </b-form>
    </template>
  </fp-form-modal>
</template>

<script>
import moment from "moment";
import { DiaryLogic } from "@/logic/backend/diary";

export default {
  name: "MoodDiaryInput",
  model: {
    prop: "visible",
    event: "change",
  },
  props: {
    visible: Boolean,
  },
  data() {
    return {
      newEntry: {
        MoodValue: 0,
        EnergyLevel: null,
        StressLevel: null,
        BalanceLevel: null,
        SourceName: "UserInput",
        MeasuredAt: moment().format(),
      },
      showInputModal: null,
      theme: sessionStorage.getItem("currentTheme") || "eh-fp",
    };
  },
  watch: {
    visible(input) {
      this.showInputModal = input;
    },
    showInputModal(input) {
      this.$emit("change", input);
    },
  },
  validators: {
    "newEntry.MoodValue": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredField"));
    },
    "newEntry.MeasuredAt": function(value) {
      return this.$validator
        .value(value)
        .required(this.$t("validate.requiredDateTime"));
    },
  },
  methods: {
    async modalOk() {
      const valid = await this.$validate();
      if (valid) {
        const getDiaryResponse = await DiaryLogic.addDiaryEntry("mood", [
          this.newEntry,
        ]);
        if (!getDiaryResponse.Code || getDiaryResponse.Code == 0) {
          this.$bvToast.toast(
            this.$t("requestResponse.moduleDiary.successSave"),
            {
              title: this.$t("base.basic.save"),
              variant: "success",
              solid: true,
            }
          );
          this.$emit("refreshList");
          this.showInputModal = false;
        } else {
          this.$bvToast.toast(getDiaryResponse.Message, {
            title: this.$t("requestResponse.moduleDiary.errorSave"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
  },
};
</script>
