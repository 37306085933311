<template>
  <!-- TODO: ha módosítja a dispense, prescription adatokat akkor figyelmeztetni, hogy a financialdatat is módosítani kell -->
  <div>
    <!-- TODO legyen itt egy külön frissítése? -->
    <!-- <b-button @click="refreshData()"> Frissítése</b-button> -->
    <b-row v-if="product" cols="1" cols-md="2">
      <b-col v-if="dispenseProduct">
        <fp-panel-card
          style="margin: 20px 0px"
          title="Kiváltott termék mennyisége"
        >
          <template #content>
            <product-amount :disabled="true" :value="dispenseProduct.Amount" />
          </template>
        </fp-panel-card>
      </b-col>
      <b-col v-if="dispenseProduct">
        <fp-panel-card
          style="margin: 20px 0px"
          title="Kiváltott termék finanszírozási árai"
        >
          <template #content>
            <financial-info
              :disabled="!editable"
              v-model="product.FinancialInfo"
              @change="changeValue('FinancialInfo', $event)"
            />
          </template>
        </fp-panel-card>
      </b-col>
      <b-col>
        <fp-select
          :disabled="true"
          :items="threeStateList"
          valueKey="Value"
          textKey="Text"
          label="Egyedi engedélyezésű a termék?"
          v-model="product.UniqueLicensedProduct"
        />
      </b-col>
      <b-col>
        <fp-input
          label="Egyedi engedélyszám"
          description="Megadása kötelező ha a kiadott termék egyedi engedélyezésű"
          v-model="product.ProductLicenseNumber"
          :disabled="!editable"
          @change="changeValue('ProductLicenseNumber', $event)"
        />
      </b-col>
      <b-col>
        <code-name-pair
          label="NEAK forgalmi kód"
          :required="true"
          v-model="product.NeakFinancialCode"
          @changeValue="changeValue('NeakFinancialCode', $event)"
          :items="neakFinancialCode"
          listTextKey="Name"
          listValueKey="EntryId"
          :disabled="!editable"
          :state="!validation.hasError('product.NeakFinancialCode')"
          :error="validation.firstError('product.NeakFinancialCode')"
        />
      </b-col>
      <b-col>
        <code-name-pair
          label="NEAK jogcím kód"
          :required="true"
          v-model="product.NeakLegalType"
          @changeValue="changeValue('NeakLegalType', $event)"
          :items="neakLegalType"
          listTextKey="Name"
          listValueKey="EntryId"
          :disabled="!editable"
          :state="!validation.hasError('product.NeakLegalType')"
          :error="validation.firstError('product.NeakLegalType')"
        />
      </b-col>
      <b-col>
        <code-name-pair
          label="NEAK magisztrális díjosztály"
          :required="true"
          v-model="product.NeakMagistralFeeClass"
          @changeValue="changeValue('NeakMagistralFeeClass', $event)"
          :items="neakMagistralfeeClass"
          listTextKey="Name"
          listValueKey="EntryId"
          :disabled="!editable"
          :state="!validation.hasError('product.NeakMagistralFeeClass')"
          :error="validation.firstError('product.NeakMagistralFeeClass')"
        />
      </b-col>
      <b-col>
        <fp-select
          :disabled="prescriptionProduct && !!prescriptionProduct.Substitutable"
          label="Helyettesíthető a termék?"
          :required="true"
          v-model="product.Substituitable"
          :items="threeStateList"
          valueKey="Value"
          textKey="Text"
          :state="!validation.hasError('product.Substituitable')"
          :error="validation.firstError('product.Substituitable')"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BaseDataLogic } from "../../../../Logic/Backend/base-data";
import FinancialInfo from "../common/FinancialInfo.vue";
import ProductAmount from "../common/ProductAmount.vue";
import CodeNamePair from "./CodeNamePair.vue";

export default {
  components: { ProductAmount, FinancialInfo, CodeNamePair },
  name: "FinanceProduct",
  model: {
    prop: "financeProduct",
    event: "change",
  },
  props: {
    financeProduct: Object,
    dispenseProduct: Object,
    prescriptionProduct: Object,
    editable: Boolean,
    refreshDataLoaded: Boolean,
  },
  data() {
    return {
      product: null,
      neakLegalType: null,
      neakFinancialCode: null,
      neakMagistralfeeClass: null,
      defaultProduct: {
        NeakFinancialCode: { Id: 1 },
        NeakLegalType: null,
        NeakMagistralFeeClass: { Id: 2 },
        ProductLicenseNumber: null,
        Substituitable: null,
        UniqueLicensedProduct: null,
      },
      changeProductValue: null,
      threeStateList: [
        { Value: null, Text: "Nem ismert" },
        { Value: true, Text: "Igen" },
        { Value: false, Text: "Nem" },
      ],
    };
  },
  validators: {
    "product.NeakFinancialCode": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.NeakLegalType": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.NeakMagistralFeeClass": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
    "product.Substituitable": function (value) {
      return this.$validator.value(value).required("Kötelező mező!");
    },
  },
  watch: {
    async refreshDataLoaded(input) {
      if (input) {
        await this.refreshData();
      }
    },
  },
  methods: {
    async refreshData() {
      this.product.Substituitable = this.prescriptionProduct
        ? this.prescriptionProduct.Substitutable
        : null;
      this.product.UniqueLicensedProduct = await this.isUniqueLicensedProduct();

      this.changeValue("Substituitable", this.product.Substituitable);
      this.changeValue(
        "UniqueLicensedProduct",
        this.product.UniqueLicensedProduct
      );
    },
    changeValue(key, value) {
      if (!this.changeProductValue) {
        this.changeProductValue = {};
      }
      this.changeProductValue[key] = value;
      this.$emit("changeValue", { key: key, value: value });
      console.log({ [key]: value });
    },
    async getLists() {
      this.neakLegalType = await this.getBase(
        this.$enums.BaseName.neak_legal_type.Value
      );
      this.neakFinancialCode = await this.getBase(
        this.$enums.BaseName.neak_financial_code.Value
      );
      this.neakFinancialCode = this.neakFinancialCode.filter(
        (e) => e.Code != "CorrectionStorno"
      );
      this.neakMagistralfeeClass = await this.getBase(
        this.$enums.BaseName.neak_magistralfee_class.Value
      );
    },
    async getBase(baseName, params, sortName, sortType) {
      const getBaseResponse = await BaseDataLogic.getBaseData(baseName, params);
      if (!getBaseResponse.Code) {
        if (sortName && sortType == "string") {
          getBaseResponse.sort((a, b) =>
            a[sortName].localeCompare(b[sortName])
          );
        } else if (sortName && sortType == "number") {
          getBaseResponse.sort((a, b) => a[sortName] - b[sortName]);
        }
        return getBaseResponse;
      } else {
        return [
          {
            EntryId: null,
            Name: "Hiba történt a lista lekérdezése során",
          },
        ];
      }
    },
    async isUniqueLicensedProduct() {
      const tttId = this.dispenseProduct.Ttt.Id;
      const tttList = await this.getBase(
        this.$enums.BaseName.eeszt_medical_device.Value,
        { EntryId: tttId }
      );
      if (tttList && tttList.length) {
        const tttObj = tttList[0];
        return tttObj.UniqueLicensedProduct;
      }
      return null;
    },
    setFinanceData() {
      if (this.financeProduct) {
        this.product = this.financeProduct;
        this.changeProductValue = null;
      } else {
        this.product = JSON.parse(JSON.stringify(this.defaultProduct));
        this.changeValue("NeakFinancialCode", this.product.NeakFinancialCode);
        this.changeValue(
          "NeakMagistralFeeClass",
          this.product.NeakMagistralFeeClass
        );
      }
      this.product.UniqueId = this.dispenseProduct.UniqueId;
      this.changeValue("UniqueId", this.dispenseProduct.UniqueId);
      this.validation.reset();
    },
    async checkValidator() {
      return await this.$validate();
    },
  },
  async mounted() {
    this.setFinanceData();
    await this.getLists();
  },
};
</script>
