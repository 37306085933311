<template>
  <fp-form-modal title="Hiba jellege" v-model="visible" size="lg">
    <template #content>
      A {{ prescriptionErrors.PrescriptionId }} azonosítójú recept hibái,
      hiányosságai:
      <ul>
        <li
          v-for="(presError, index) in prescriptionErrors.Messages"
          :key="'prescription-error-msg-' + index"
        >
          {{ presError }}
        </li>
      </ul>
      A {{ prescriptionErrors.PrescriptionId }} azonosítójú recept termékei és azok hibái, hiányosságai:
      <ul>
        <li
          v-for="(invalidProd, index) in prescriptionErrors.InvalidProducts"
          :key="'prescription-error-prod-' + index"
        >
          {{
            invalidProd.Ttt
              ? invalidProd.Ttt.Name +
                " (TTT: " +
                invalidProd.Ttt.Code +
                "), " +
                (invalidProd.Amount
                  ? invalidProd.Amount.Amount + " " + invalidProd.Amount.Unit
                  : "") +
                ", " +
                (invalidProd.DispenseTitle
                  ? invalidProd.DispenseTitle.Name
                  : "") +
                ":"
              : ""
          }}
          <ul>
            <li
              v-for="(prodError, msgIndex) in invalidProd.Messages"
              :key="'prescription-error-prod-error-' + msgIndex"
            >
              {{ prodError }}
            </li>
          </ul>
        </li>
      </ul>
    </template>
  </fp-form-modal>
</template>
<script>
export default {
  name: "PresceiptionErrorMessagesModal",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    prescriptionErrors: Object,
  },
  data() {
    return {
      visible: this.value,
    };
  },
  watch: {
    visible(input) {
      this.$emit("change", input);
    },
    value(input) {
      this.visible = input;
    },
  },
};
</script>
