<template>
  <div id="" class="eh-date-range-button">
    <div class="eh-date-range-button-label">
      {{ label }}
    </div>
    <b-container fluid>
      <b-row>
        <div class="eh-date-range-button-box">
          <button
            class="eh-button"
            @click="
              showToCalendar = false;
              showFromCalendar = !showFromCalendar;
            "
          >
            {{ $t("base.calendar.from", { date: tempValue.from }) }}
          </button>
          <div v-if="showFromCalendar" class="eh-date-picker-calendar-quest">
            <b-calendar
              v-model="tempValue.from"
              :locale="$i18n.locale"
              v-bind="labels[$i18n.locale] || {}"
              :start-weekday="1"
            >
              <div v-if="!hideClearButton" style="padding: 10px" class="d-flex" dir="ltr">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  v-if="tempValue.from"
                  @click="tempValue.from = ''"
                >
                  {{ $t("base.basic.clear") }}
                </b-button>
              </div>
            </b-calendar>
          </div>
        </div>
        <div class="eh-date-range-button-box">
          <button
            class="eh-button"
            @click="
              showFromCalendar = false;
              showToCalendar = !showToCalendar;
            "
          >
            {{ $t("base.calendar.to", { date: tempValue.to }) }}
          </button>
          <div v-if="showToCalendar" class="eh-date-picker-calendar-quest">
            <b-calendar
              :date-disabled-fn="beforeFromCalendarDisabled"
              v-model="tempValue.to"
              :locale="$i18n.locale"
              v-bind="labels[$i18n.locale] || {}"
              :start-weekday="1"
            >
              <div v-if="!hideClearButton" style="padding: 10px" class="d-flex" dir="ltr">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  v-if="tempValue.to"
                  @click="tempValue.to = ''"
                >
                  {{ $t("base.basic.clear") }}
                </b-button>
              </div>
            </b-calendar>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "DateRangeButton",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Object, //formátum: {from,to}
    label: String,
    hideClearButton: Boolean,
  },
  data() {
    return {
      tempValue: this.value ? this.value : { from: null, to: null },
      showFromCalendar: false,
      showToCalendar: false,
      labels: { [this.$i18n.locale]: this.$t("base.calendar.bootstrap") },
    };
  },
  watch: {
    "tempValue.from": function () {
      this.$emit("change", this.tempValue);
    },
    "tempValue.to": function () {
      this.$emit("change", this.tempValue);
    },
    "value.from": function (from) {
      this.tempValue.from = from;
    },
    "value.to": function (to) {
      this.tempValue.to = to;
    },
  },
  methods: {
    beforeFromCalendarDisabled(ymd) {
      const day = moment(ymd);
      const from = moment(this.tempValue.from);
      if (this.tempValue.from) {
        return day.isBefore(from);
      }
      return false;
    },
    closeCalendar() {
      console.log("CLICK AZ OLDALRA");
      if (this.showToCalendar) {
        this.showToCalendar = false;
      }
      if (this.showFromCalendar) {
        this.showFromCalendar = false;
      }
    },
  },
  /* mounted() {
    console.log("START");
    addEventListener("click", this.closeCalendar);
  },
  beforeDestroy() {
    console.log("END");
    removeEventListener("click", this.closeCalendar);
  }, */
};
</script>
