<template>
  <div>
    <div v-if="!IsDashboard" style="text-align: center">
      <interval-manager
        :title="$t('module.charts.' + Chart.Name)"
        :interval="interval"
        :currentDate="currentDate"
        :currentDateText="currentDateText"
        @intervalChanged="intervalChanged"
      />
    </div>
    <ejs-chart
      :id="'cardiology-pulse-chart-component' + Id"
      :primaryXAxis="xAxis"
      :primaryYAxis="yAxis"
      :palettes="palettes"
      :zoomSettings="zoom"
      :tooltip="IsDashboard ? {} : tooltip"
      background="transparent"
      width="100%"
      :title="IsDashboard ? $t('module.charts.' + Chart.Name) : ''"
      :titleStyle="IsDashboard ? titleStyle : {}"
      :height="IsDashboard ? '185px' : '90%'"
      isResponsive="true"
    >
      <e-series-collection>
        <e-series
          v-if="chartType == $enums.DiagramType.chart"
          :dataSource="chartData"
          :xName="Chart.DataProperties.chart.x"
          type="RangeColumn"
          :high="Chart.DataProperties.chart.high"
          :low="Chart.DataProperties.chart.low"
          :tooltip="IsDashboard ? {} : tooltip"
          :marker="IsDashboard ? {} : marker"
        ></e-series>
        <e-series
          v-else-if="chartType == $enums.DiagramType.diary"
          :dataSource="chartData"
          :segments="segments"
          segmentAxis="Y"
          type="MultiColoredLine"
          :xName="Chart.DataProperties.diary.x"
          :yName="Chart.DataProperties.diary.y"
          :tooltip="IsDashboard ? {} : tooltip"
          :marker="
            IsDashboard || (!IsDashboard && chartData.length > 50) ? {} : marker
          "
        ></e-series>
      </e-series-collection>
    </ejs-chart>
  </div>
</template>

<script>
import Vue from "vue";
import { DiaryLogic } from "@/logic/backend/diary";
import moment from "moment";
import {
  ChartPlugin,
  DateTime,
  Tooltip,
  Zoom,
  StripLine,
  RangeColumnSeries,
  MultiColoredLineSeries,
  ChartAnnotation,
} from "@syncfusion/ej2-vue-charts";
import { ChartUiLogic } from "@/logic/ui/chart-ui";
import { ChartBackendLogic } from "@/logic/backend/chart";
import IntervalManager from "@/components/module/chart/IntervalManager.vue";

Vue.use(ChartPlugin);

export default {
  components: { IntervalManager },
  name: "CariologyPulse",
  data() {
    return {
      chartData: [],
      chartLoaded: false,
      currentDate: moment(),
      currentDateText: null,
      interval: null,
      chartType: null,
      //Chart paraméterek
      xAxis: {
        title: this.IsDashboard ? null : this.$t("base.calendar.date"),
        valueType: "DateTime",
        interval: this.IsDashboard ? 5 : null,
        intervalType: "Days",
        labelFormat: "MM-dd",
        labelIntersectAction: "Rotate45",
      },
      yAxis: {
        title: this.IsDashboard
          ? null
          : this.$t("module.charts." + this.Chart.Name) +
            " [" +
            this.Chart.DisplayUnit +
            "]",
        valueType: "Double",
        labelFormat: "n0",
        minimum: 0,
      },
      zoom: {
        mode: "X",
        enablePinchZooming: true,
        enableMouseWheelZooming: true,
        enablePan: true,
      },
      palettes: ["var(--eh-secondary-5)"],
      marker: {
        visible: true,
      },
      tooltip: {
        enable: true,
        textStyle: {
          fontFamily: "Avenir, Helvetica, Arial, sans-serif",
        },
        shared: this.ChartType == this.$enums.DiagramType.diary,
      },
      titleStyle: {
        fontFamily: "Avenir, Helvetica, Arial, sans-seri",
        fontWeight: "bold",
        color: "var(--eh-secondary-5)",
      },
      segments: [
        {
          value: 60,
          color: "#3F9BFF",
        },
        {
          value: 130,
          color: "#00B400",
        },
        {
          value: 250,
          color: "#FF4741",
        },
      ],
    };
  },
  props: {
    Interval: String,
    ChartType: String,
    PatientId: Number,
    IsDashboard: Boolean,
    Chart: Object,
    Id: [String, Number],
  },
  provide: {
    chart: [
      RangeColumnSeries,
      DateTime,
      Tooltip,
      MultiColoredLineSeries,
      ChartAnnotation,
      StripLine,
      Zoom,
    ],
  },
  methods: {
    /* loadedChart(args) {
      console.log("CHART_LOADED", args);
      this.chartLoaded = true;
    }, */
    //intervall beállítás
    setInterval() {
      //interval objektum generálás dátum és intervallum alapján
      const interval = ChartUiLogic.IntervalManager.setInterval(
        this.interval,
        this.xAxis,
        this.currentDate
      );
      //kapott intervallum objektum tárolása
      this.currentDate = interval.currentDate;
      this.currentDateText = interval.currentDateString;
      this.xAxis = interval.xAxis;
    },
    //intervallum csere
    async intervalChanged(currentDate, interval) {
      //kiválasztott dátum tárolása
      this.currentDate = currentDate;
      //kiválasztott intevallum tárolása
      this.interval = interval;
      //intervallum napi?
      if (interval == this.$enums.ChartInterval.Day.Value) {
        //igen, chart típus napló
        this.chartType = this.$enums.DiagramType.diary;
      } else {
        //nem, kapott chart típus tárolása
        this.chartType = this.ChartType;
      }
      //adatok betöltése
      await this.getData();
      //intervallum beállítás
      this.setInterval();
    },
    //adat kérés
    async getData() {
      //chart típus chart?
      if (this.chartType == this.$enums.DiagramType.chart) {
        //igen, chart adat kérés
        await this.getChartData();
        //chart tíőus napló
      } else if (this.chartType == this.$enums.DiagramType.diary) {
        //igen napló, napló adat kérés
        await this.getDiaryData();
      }
    },
    //chart data kérés
    async getChartData() {
      //kérés válasz
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //igen, beteg chart adat kérés
        responseChartData = await ChartBackendLogic.getPatientChart(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToChart()
        );
      } else {
        //nem, saját chart adat kérés
        responseChartData = await ChartBackendLogic.getChart(
          this.Chart.DatabaseName,
          this.getIntervalParamsToChart()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        this.chartLoaded = false;
        //kapott chart adat tárolás
        this.chartData = this.convertChartData(responseChartData);
        //chart adatok sorrenezése
        this.chartData.sort((a, b) =>
          moment(a[this.Chart.DataProperties.chart.x]).isBefore(
            moment(b[this.Chart.DataProperties.chart.x])
          )
            ? -1
            : 1
        );
        //üres mezők feltöltése
        this.fillEmptyDays([this.Chart.DataProperties.chart.x], {
          [this.Chart.DataProperties.chart.high]: null,
          [this.Chart.DataProperties.chart.low]: null,
        });
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("module.charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //chart adat konvertálás
    convertChartData(data) {
      //üres segédváltozó
      var temp = [];
      //végig megyünk minden elemen
      for (let i = 0; i < data.length; i++) {
        //elem kiemelés
        const element = data[i];
        //konvertált elem tárolás (key: data[key] formátum)
        temp[i] = {
          //Datapropery chart x tengelyen megjelenő értéke
          [this.Chart.DataProperties.chart.x]:
            element[this.Chart.DataProperties.chart.x],
          //Datapropery chart y tengelyen megjelenő high értéke
          [this.Chart.DataProperties.chart.high]:
            element[this.Chart.DataProperties.chart.high][
              this.Chart.DataProperties.diary.y
            ],
          //Datapropery chart y tengelyen megjelenő low értéke
          [this.Chart.DataProperties.chart.low]:
            element[this.Chart.DataProperties.chart.low][
              this.Chart.DataProperties.diary.y
            ],
        };
      }
      //vissza térünk a konvertált adatokkal
      return temp;
    },
    //napló adat kérés
    async getDiaryData() {
      //kérés válasz
      var responseChartData = null;
      //van beteg id?
      if (this.PatientId) {
        //igen, beteg napló adat kérés
        responseChartData = await DiaryLogic.getPatientDiary(
          this.Chart.DatabaseName,
          this.PatientId,
          this.getIntervalParamsToDiary()
        );
      } else {
        //nem, saját napló adat kérés
        responseChartData = await DiaryLogic.getDiary(
          this.Chart.DatabaseName,
          this.getIntervalParamsToDiary()
        );
      }
      //sikeres kérés?
      if (!responseChartData.Code) {
        this.chartLoaded = false;
        //kapott a napló adatok tárolása
        this.chartData = responseChartData;
        //adatok sorrendezése dátum szerint
        this.chartData.sort((a, b) =>
          moment(a[this.Chart.DataProperties.diary.x]).isBefore(
            moment(b[this.Chart.DataProperties.diary.x])
          )
            ? -1
            : 1
        );
        /* //üres mezők feltöltése
        this.fillEmptyDays([this.Chart.DataProperties.diary.x], {
          [this.Chart.DataProperties.diary.y]: null,
        }); */
      } else {
        this.$bvToast.toast(responseChartData.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("charts." + this.Chart.Name),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //napló adatok konvertálása
    getIntervalParamsToDiary() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToDiary(
        this.interval,
        this.currentDate
      );
    },
    //chart adatok konvertálása
    getIntervalParamsToChart() {
      return ChartUiLogic.IntervalManager.getIntervalParamsToChart(
        this.interval,
        this.currentDate
      );
    },
    //üres mezők feltöltése
    fillEmptyDays(datetimeName, value) {
      var lastElement = this.chartData[this.chartData.length - 1];
      if (lastElement) {
        this.chartData.push({
          [datetimeName]: moment(lastElement[datetimeName])
            .add(
              1,
              this.interval == this.$enums.ChartInterval.Day.Value
                ? "hours"
                : "days"
            )
            .format(),
          ...value,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //kapott chart típus tárolása
    this.chartType = this.ChartType;
    //kapott intervallum tárolása
    this.interval = this.Interval;
    //intevallum beállítása
    this.setInterval();
    //adatok betöltése
    await this.getData();
  },
};
</script>

<style scoped>
@import "../../../styles/syncfusion-diagrams.css";
.chart-title {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: var(--eh-secondary-5);
  font-size: 23px;
}
</style>
