import Vue from "vue";
import moment from "moment";

Vue.prototype.$convertDateToString = function (date, format) {
  if (moment(date).isValid()) {
    return moment(date).format(format);
  } else {
    return null;
  }
};

//TODO: filterKeyList elemének kiegészítése (tömbben elemeken belül is lehessen szűrni) kereshető formátum: XX.YY[].ZZ
Vue.prototype.$filterList = function (
  inputs,
  list,
  filterKeyList,
  currentLang
) {
  var tempInputs = [];
  if (!Array.isArray(inputs)) {
    tempInputs = [inputs];
  } else {
    tempInputs = inputs;
  }
  var filteredList = list;
  var arrayFilterKeyList = [];
  tempInputs.forEach((input) => {
    if (input) {
      filteredList = filteredList.filter((listElement) => {
        const normalizedInput = input
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase();
        const filteredElement = filterKeyList.some((key) => {
          var normalizedListElement;
          if (key) {
            const pathArrays = key.split("[]");
            const pathKeys = pathArrays[0].split(".");
            arrayFilterKeyList = pathArrays[1];
            var value = listElement;
            pathKeys.forEach((pathKey) => {
              if (!Array.isArray(value) && value) {
                value = value[pathKey];
              }
            });
          }

          if (value && normalizedInput) {
            if (Array.isArray(value)) {
              const arrayHasValue = Vue.prototype.$filterList(
                inputs,
                value,
                [arrayFilterKeyList.substring(1)],
                currentLang
              );

              return arrayHasValue && arrayHasValue.length ? true : false;
            }
            if (typeof value == "object") {
              const langs = Object.keys(value);
              if (langs.includes(currentLang)) {
                value = value[currentLang];
              } else if (langs.includes("en")) {
                value = value.en;
              } else {
                value = value[langs[0]];
              }
            }
            normalizedListElement = value
              .toString()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .toLowerCase();
            return (
              normalizedInput && normalizedListElement.includes(normalizedInput)
            );
          } else if (normalizedInput == "" || !normalizedInput) {
            return true;
          }
          return false;
        });
        return filteredElement;
      });
    }
  });
  return filteredList;
};

Vue.prototype.$groupByMostEfficient = function (list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
};

Vue.prototype.$getChangedObjectElement = function (obj, path, value) {
  if (!obj) {
    obj = {};
  }
  var changeObject = obj;
  /* convert indexes to properties */
  path = path.replace(/\[(\w+)\]/g, ".$1");
  /* strip a leading dot */
  path = path.replace(/^\./, "");
  /* path feldarabolása listába */
  var pList = path.split(".");
  /* pathok száma */
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    /* a plist i-edik elemét kiemeljük az elem változóba */
    var elem = pList[i];
    /* ha nem létezik a changeObject[elem], akkor létrehozzuk egy üres elemként */
    if (!changeObject[elem]) {
      changeObject[elem] = {};
    }
    /* a changeObject-nak átadjuk a changeObject[elem] objektumát */
    changeObject = changeObject[elem];
  }
  /* amint végig mentünk a path paramétereken, akkor a kapott objektumnak átadjuk a kapott value-t */
  changeObject[pList[len - 1]] = value;
  return obj;
};

Vue.prototype.$setChangedObjectElement = function (obj, path, value) {
  console.log(obj, path, value);
  if (!obj) {
    obj = {};
  }
  var changeObject = obj;
  /* convert indexes to properties */
  path = path.replace(/\[(\w+)\]/g, ".$1");
  /* strip a leading dot */
  path = path.replace(/^\./, "");
  /* path feldarabolása listába */
  var pList = path.split(".");
  /* pathok száma */
  var len = pList.length;
  for (var i = 0; i < len - 1; i++) {
    /* a plist i-edik elemét kiemeljük az elem változóba */
    var elem = pList[i];
    console.log("elem", elem);
    /* ha nem létezik a changeObject[elem], akkor létrehozzuk egy üres elemként */
    if (!changeObject[elem]) {
      changeObject[elem] = {};
      console.log("changeObject", changeObject);
    }
    /* a changeObject-nak átadjuk a changeObject[elem] objektumát */
    changeObject = changeObject[elem];
    console.log("changeObject", changeObject);
  }
  /* amint végig mentünk a path paramétereken, akkor a kapott objektumnak átadjuk a kapott value-t */
  changeObject[pList[len - 1]] = value;
};

Vue.prototype.$deepObjectCompare = function (object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !Vue.prototype.$deepObjectCompare(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

function isObject(object) {
  return object != null && typeof object === "object";
}

export const HelperMethods = {
  groupBy(xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  },

  groupByArrayItems(list, keyGetterArray, arrayKey, unGroupedName) {
    const map = new Map();
    list.forEach((item) => {
      const array = keyGetterArray(item);
      if (array.length > 0) {
        array.forEach((e) => {
          const key = e[arrayKey];
          const collection = map.get(key);
          if (!collection) {
            map.set(key, [item]);
          } else {
            collection.push(item);
          }
        });
      } else {
        const collection = map.get(unGroupedName);
        if (!collection) {
          map.set(unGroupedName, [item]);
        } else {
          collection.push(item);
        }
      }
    });
    return map;
  },
};
