<template>
  <div class="eh-menu-content">
    <div>
      <b-row cols="1" cols-md="2">
        <!-- Résztvevő kezelő sáv -->
        <div class="col-xl-3 col-md-4" style="margin: 0px 0px 25px">
          <fp-participant-manager
            :project="project"
            :managerType="
              $enums.SystemParameters.ParticipantManagerType.Specialist
            "
            :refreshingList.sync="refreshingList"
            @changedSelectedParticipant="selectParticipant"
          />
        </div>
        <!-- Kiválasztott beteg adatai -->
        <div class="col-xl-9 col-md-8" v-if="selectedParticipant">
          <fp-tabs :tabs="patientCardComponents">
            <template #title="Component">{{ Component.Title }}</template>
            <template #content="Component">
              <component
                @refreshList="refreshList"
                :is="Component.ComponentName"
                :participant="selectedParticipant"
                :project="project"
              />
            </template>
          </fp-tabs>
        </div>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "PatientList",
  props: {
    project: Object,
  },
  data() {
    return {
      patientCardComponents: [
        {
          ComponentName: "fp-participant-personal-profile",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.personalData"
          ),
        },
        {
          ComponentName: "fp-participant-medical-profile",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.medicalData"
          ),
        },
        {
          ComponentName: "fp-participant-chart",
          Title: this.$t("healthProfessional.patientList.patientTabs.charts"),
        },
        {
          ComponentName: "fp-participant-workflow",
          Title: this.$t("healthProfessional.patientList.patientTabs.workflow"),
        },
        {
          ComponentName: "fp-participant-document",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.documents"
          ),
        },
        {
          ComponentName: "fp-participant-questionnaire",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.questionnaires"
          ),
        },
        {
          ComponentName: "fp-participant-form",
          Title: this.$t("healthProfessional.patientList.patientTabs.forms"),
        },
        {
          ComponentName: "fp-participant-communication",
          Title: this.$t(
            "healthProfessional.patientList.patientTabs.communication"
          ),
        },
      ],
      selectedParticipant: null,
      refreshingList: false,
    };
  },
  watch: {
    async project() {
      this.refreshingList = true;
    },
  },
  methods: {
    selectParticipant(newParticipant) {
      this.selectedParticipant = newParticipant;
    },
    refreshList() {
      this.refreshingList = true;
    },
  },
  //első betöltés kör fut le
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.patientList")
    );
  },
};
</script>
<style>
#new-patient-modal .modal-content {
  border-radius: 10px;
  margin: 100px 0px;
}
div#new-patient-modal div.modal-content header.modal-header,
div#new-patient-modal div.modal-content > header.modal-header {
  padding: 0px;
}
#patient-list .list-group-item.active {
  background: darkorange;
  border-color: darkorange;
  font-weight: 700;
}
</style>
