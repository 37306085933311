var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.siteTitle)?_c('div',{key:'components-header-footer-webappadminheader-' + _vm.forceUpdateKey,staticClass:"eh-header"},[_c('Header',{attrs:{"signedName":_vm.signedName,"siteTitle":_vm.siteTitle,"siteColor":_vm.siteColor,"ChangedProfileFillingRate":_vm.ChangedProfileFillingRate,"signedProfilePicture":_vm.UserPicture},on:{"forceUpdate":_vm.forceUpdate}}),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{attrs:{"align-h":"around","align-v":"center"}},[_c('div',[_c('router-link',{attrs:{"to":"/Partner/Home"}},[_c('button',{staticClass:"eh-header-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.menu.home"))+" ")])])],1),_c('div',{staticStyle:{"text-align":"center"}},[_c('router-link',{attrs:{"to":"RegistrationParticipant"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.menu.registrationParticipant"))+" ")])]),_c('router-link',{attrs:{"to":"ParticipantList"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.participantList.title"))+" ")])]),(
            _vm.$loggedUser.SelectedProject &&
              _vm.$loggedUser.SelectedProject.ProjectId
          )?_c('router-link',{attrs:{"to":"Projects"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.projects.title"))+" ")])]):_vm._e(),(_vm.visibleUiElement(_vm.$enums.UserInterfaceElement.Document.Value))?_c('router-link',{attrs:{"to":"Documents"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.menu.documents"))+" ")])]):_vm._e(),(_vm.visibleUiElement(_vm.$enums.UserInterfaceElement.UserTask.Value))?_c('router-link',{attrs:{"to":"Tasks"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.tasks.title"))+" ")])]):_vm._e(),(_vm.visibleUiElement(_vm.$enums.UserInterfaceElement.Workflow.Value))?_c('router-link',{attrs:{"to":"Workflows"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.workflows.title"))+" ")])]):_vm._e(),(
            _vm.visibleUiElement(
              _vm.$enums.UserInterfaceElement.NotificationTemplate.Value
            )
          )?_c('router-link',{attrs:{"to":"Templates"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("partner.menu.templates"))+" ")])]):_vm._e(),(
            _vm.visibleUiElement(_vm.$enums.UserInterfaceElement.Questionnaire.Value)
          )?_c('router-link',{attrs:{"to":"Questionnaires"}},[_c('button',{staticClass:"eh-header-view-button"},[_vm._v(" "+_vm._s(_vm.$t("healthProfessional.menu.questionnaires"))+" ")])]):_vm._e()],1),_c('div',[_c('router-link',{attrs:{"to":"/"}},[_c('button',{staticClass:"eh-header-button",on:{"click":_vm.close}},[_vm._v("Kilépés")])])],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }