import { EhssClient, RequestMethod } from '@/backend/ehss-client'
import { RequestResponseLogic } from '@/logic/ui/request-response'

const FORM_SCHEMA = '/form/schema'
const FORM_SCHEMA_HEADER = '/form/schema/header'
const FORM_INSTANCE = '/form/instance'
const FORM_INSTANCE_HEADER = '/form/instance/header'
const FORM_INSTANCE_SAVE = '/form/instance/save'
const FORM_INSTANCE_EVALUATION = "/form/instance/evaluation"

//TODO: questionnaire kiemelés?

export const FormLogic = {
    async createFormSchema(body, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.POST,
            FORM_INSTANCE,
            params,
            body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async getFormSchema(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            FORM_SCHEMA,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //kitölthető form lista header kérés
    async getFormSchemaHeader(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            FORM_SCHEMA_HEADER,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async setFormSchema(formId, body, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.PATCH,
            FORM_INSTANCE + '/' + formId,
            params,
            body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteFormSchema(formId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.DELETE,
            FORM_SCHEMA + '/' + formId,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async createFormInstance(body, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.POST,
            FORM_INSTANCE,
            params,
            body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //kiosztott form teljes lista
    async getFormInstance(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            FORM_INSTANCE,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //kiosztott form header lista
    async getFormInstanceHeader(params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.GET,
            FORM_INSTANCE_HEADER,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async setFormInstance(body, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.PATCH,
            FORM_INSTANCE,
            params,
            body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //kitöltés
    async saveFormInstance(body, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.POST,
            FORM_INSTANCE_SAVE,
            params,
            body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    async deleteFormInstance(instanceId, params) {
        let result = await EhssClient.sendRequest(
            RequestMethod.DELETE,
            FORM_INSTANCE + '/' + instanceId,
            params
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
    //kiértékelés
    async evaluationFormInstance(body, params) {
        let result = await EhssClient.sendRequestWithBody(
            RequestMethod.POST,
            FORM_INSTANCE_EVALUATION,
            params,
            body
        )
        return await RequestResponseLogic.checkRequestResponse(result)
    },
}