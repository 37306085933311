export default {
  Prescription: {
    EesztPatientTransfer: {
      Ssn: {
        UserProfileKey: "SocialSecurityNumber",
        EesztPatientKey: "PatientIdentifierTypeId",
        EesztPatientEntryId: 10,
      },
      EuSsn: {
        UserProfileKey: "EuSocialSecurityNumber",
        EesztPatientKey: "PatientIdentifierTypeId",
        EesztPatientEntryId: 16,
      },
      GenderFemale: {
        UserProfileKey: "Sex",
        UserProfileId: "Female",
        EesztPatientKey: "GenderId",
        EesztPatientEntryId: 6,
      },
      GenderMale: {
        UserProfileKey: "Sex",
        UserProfileId: "Male",
        EesztPatientKey: "GenderId",
        EesztPatientEntryId: 5,
      },
    },
    EesztDosageCondition: {
      ByPartOfDay: 1,
      ByFrequence: 2,
      ByText: 3,
    },
    LocalStatus: {
      Recorded: { Value: "Recorded", Text: "Rögzitett" },
      Booked: { Value: "Booked", Text: "Lefoglalt" },
      Dispensed: { Value: "Dispensed", Text: "Kiadott" },
      Reviewed: { Value: "Reviewed", Text: "Ellenőrzött" },
    },
    EesztStatus: {
      NotUploaded: { Value: "NotUploaded", Text: "Nem feltöltött" },
      Open: { Value: "Open", Text: "Nyitott" },
      Booked: { Value: "Booked", Text: "Lefoglalt" },
      Dispensed: { Value: "Dispensed", Text: "Kiadott" },
      Reviewed: { Value: "Reviewed", Text: "Ellenőrzött" },
      Withdrawn: { Value: "Withdrawn", Text: "Visszavont" },
      Expired: { Value: "Expired", Text: "Lejárt" },
      WaitingForCountersign: {
        Value: "WaitingForCountersign",
        Text: "Ellenjegyzésre vár",
      },
    },
    NeakReportStatus: {
      New: { Value: "New", Text: "Új" },
      WaitingForValidation: { Value: "WaitingForValidation", Text: "Érvényesítésre vár" },
      Submitted: { Value: "Submitted", Text: "Beküldve" },
      Processing: { Value: "Processing", Text: "Feldolgozása megkezdve" },
      Accepted: { Value: "Accepted", Text: "Elfogadva" },
      WrongAndMustBeChanged: { Value: "WrongAndMustBeChanged", Text: "Hibás (javítandó)" },
      WrongAndCanBeChanged: { Value: "WrongAndCanBeChanged", Text: "Hibás (javítható)" },
      Cancelled: { Value: "Cancelled", Text: "Visszavont (sztornózott)" },
      OutDated: { Value: "OutDated", Text: "Elavult" },
      Recalled: { Value: "Recalled", Text: "Visszahívott" },
      AcknowledgedWrong: { Value: "AcknowledgedWrong", Text: "Tudomásul vett (hibás)" },
      AcknowledgedGood: { Value: "AcknowledgedGood", Text: "Tudomásul vett (jó)" },
    },
  },
};
