<template>
  <div class="eh-base-textarea">
    <textarea
      class="eh-base-input"
      :class="setClass"
      @focus="isFocus = true"
      @blur="isFocus = false"
      :placeholder="placeholder ? placeholder : ' '"
      :disabled="disabled"
      v-model="tempValue"
    />
    <span v-if="label" class="eh-base-input-floating-label">
      {{ label }}
      <span v-if="required" class="text-danger">*</span>
    </span>
    <div class="eh-base-input-desc-error-box">
      <div v-if="description" class="eh-base-input-description">
        {{ description }}
      </div>
      <div v-if="!state && error" class="eh-base-input-error-text">
        {{ error }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "fp-input",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    label: String,
    disabled: Boolean,
    required: Boolean,
    placeholder: String,
    description: String,
    error: String,
    value: String,
    state: { type: Boolean, default: true },
  },
  data() {
    return {
      isFocus: false,
      tempValue: this.value,
    };
  },
  watch: {
    tempValue(input) {
      this.$emit("change", input);
    },
    value(input) {
      this.tempValue = input;
    },
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.label) {
        temp += " with-label ";
      } else {
        temp += " without-label ";
      }
      if (this.state) {
        temp += " valid-input ";
      } else {
        temp += " invalid-input ";
      }
      return temp;
    },
  },
  mounted() {
    this.$emit("change", this.value);
  },
};
</script>