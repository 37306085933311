<template>
  <div>Fejlesztés alatt</div>
</template>
<script>
export default {
  name: "Profile",
  mounted() {
    this.$store.dispatch(
      "setPageTitle","Profilom");
  },
};
</script>