<template>
  <div class="eh-menu-content">
    <button class="eh-action-button" @click="showRegistration = true">
      {{ $t("healthProfessional.patientManager.manager.newPatientSignUp") }}
    </button>
    <button class="eh-action-button" @click="showAddToProject = true">
      {{ $t("healthProfessional.patientManager.manager.userInvitation") }}
    </button>
    <div>
      <div style="text-align: center" class="h2">
        {{ $t("healthProfessional.patientManager.manager.patientInvitations") }}
      </div>
      <fp-table-list :items="invitations" :fields="invitationFields">
        <template #Timestamp="row">
          {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
        </template>
        <template #Status="row">
          {{ $t($enums.InvitationStatus[row.item.Status].Text) }}
        </template>
        <template #Parameters="row">
          {{
            $t(
              $enums.InvitationRelationshipType[
                row.item.Parameters.RelationshipType
              ].Text
            )
          }}
        </template>
        <template #Buttons="row">
          <b-icon
            class="h3"
            style="cursor: pointer"
            icon="trash"
            @click="deleteInvitation(row.item.InvitationId)"
          ></b-icon>
        </template>
      </fp-table-list>
    </div>
    <registration-patient
      v-model="showRegistration"
      @refreshList="getInvitations()"
      :project="project"
    />
    <user-invitation
      v-model="showAddToProject"
      @refreshList="getInvitations()"
    />
  </div>
</template>
<script>
import { InvitationLogic } from "@/logic/backend/invitation.js";
import RegistrationPatient from "../patient-manager/RegistrationPatient.vue";
import UserInvitation from "../patient-manager/UserInvitation.vue";
export default {
  components: { RegistrationPatient, UserInvitation },
  name: "PatientManager",
  props: {
    project: Object,
  },
  data() {
    return {
      showRegistration: null,
      showAddToProject: null,
      invitations: null,
      invitationFields: [
        {
          key: "Status",
          label: this.$t("healthProfessional.patientManager.manager.status"),
        },
        {
          key: "TargetName",
          label: this.$t(
            "healthProfessional.patientManager.manager.targetName"
          ),
        },
        {
          key: "Parameters",
          label: this.$t(
            "healthProfessional.patientManager.manager.parameters"
          ),
        },
        {
          key: "Timestamp",
          label: this.$t("healthProfessional.patientManager.manager.timestamp"),
        },
        { key: "Buttons", label: "" },
      ],
    };
  },
  methods: {
    async getInvitations() {
      const getInvitationsResponse = await InvitationLogic.getInvitations({
        Type: "Patient",
        RequestedById: this.$loggedUser.UserId,
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      if (!getInvitationsResponse.Code) {
        this.invitations = getInvitationsResponse;
      } else {
        this.$bvToast.toast(getInvitationsResponse.Message, {
          title: this.$t("requestResponse.invitation.errorGet"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async deleteInvitation(invitationId) {
      const deleteInvitationResposne = await InvitationLogic.deleteInvitation(
        invitationId
      );
      if (!deleteInvitationResposne.Code) {
        this.$bvToast.toast(
          this.$t("requestResponse.invitation.successInvitationDelete"),
          {
            title: this.$t(
              "requestResponse.invitation.successInvitationDeleteMessage"
            ),
            variant: "info",
            solid: true,
          }
        );
        await this.getInvitations();
      } else {
        this.$bvToast.toast(deleteInvitationResposne.Message, {
          title: this.$t("requestResponse.invitation.errorInvitationDelete"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  async mounted() {
    await this.getInvitations();
    this.$store.dispatch(
      "setPageTitle",
      this.$t("healthProfessional.menu.patientInvitation")
    );
  },
};
</script>
