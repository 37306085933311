<template>
  <div>
    <!-- TODO: alakítani a szélességet -->
    <div ref="textSelectInput" class="eh-select-input-box">
      <div
        @click="isFocus = disabled ? false : !isFocus"
        class="eh-select-input"
        :class="setClass"
      >
        <span style="visibility: hidden">{{
          selectedValue ? null : label
        }}</span>
        <span v-if="selectedValue" class="eh-select-input-value">
          <slot name="element" v-bind="selectedValue">
            {{ setSelectedElementLabel() }}
          </slot>
        </span>
        <span class="select-input-open-icon">
          <b-icon
            :icon="isFocus && !disabled ? 'chevron-up' : 'chevron-down'"
          ></b-icon>
        </span>
      </div>
      <span
        v-if="label"
        @click="isFocus = disabled ? false : !isFocus"
        class="eh-select-input-floating-label"
      >
        {{ label }}
        <span v-if="required && label" class="text-danger">*</span>
      </span>
      <span
        @click="isFocus = disabled ? false : !isFocus"
        class="eh-select-input-text-placeholder"
        v-if="!selectedValue && (isFocus || !label)"
      >
        {{ placeholder ? placeholder : $t("base.pleaseSelect") }}
      </span>
      <div
        v-if="isFocus && !disabled"
        class="select-options-box"
        :class="setClass"
      >
        <div v-if="searchable">
          <fp-input
            ref="fpSearchInputField"
            class="eh-select-search-input"
            :placeholder="$t('base.basic.search')"
            v-model="searchedText"
            @change="$emit('search', $event)"
          />
        </div>
        <div v-if="tempList && tempList.length > 0">
          <div
            :id="'option-' + index + '-' + id"
            v-for="(option, index) in tempList"
            :key="'option-' + index + '-' + id"
            @click="option && !option.options ? selectItem(option) : ''"
          >
            <div v-if="option && option.options && option.options.length > 0">
              <b style="padding: 5px">{{ option.label }}</b>
              <div
                :id="'option-' + index + 'sub-option-' + subIndex + '-' + id"
                v-for="(subOption, subIndex) in option.options"
                :key="'option-' + index + 'sub-option-' + subIndex + '-' + id"
                class="eh-select-input-option"
                :class="subOption.Selected ? ' active-option ' : ''"
                @click="selectItem(subOption)"
              >
                <slot name="element" v-bind="subOption">
                  {{ setListElementLabel(subOption) }}
                </slot>
              </div>
            </div>

            <div
              class="eh-select-input-option"
              :class="
                option && !option.options && option.Selected
                  ? ' active-option '
                  : ''
              "
              v-else
            >
              <slot name="element" v-bind="option">
                {{ setListElementLabel(option) }}
              </slot>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="eh-select-input-option" @click="selectItem(null)">
            {{ $t("base.noData") }}
          </div>
        </div>
      </div>
      <div class="eh-select-input-desc-error-box">
        <div v-if="description" class="eh-select-input-description">
          {{ description }}
        </div>
        <div v-if="!state && error" class="eh-select-input-error-text">
          {{ error }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  name: "SingleSelect",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    id: [Number, String, Boolean],
    value: [Object, String, Number, Boolean, Array],
    list: Array,
    label: String,
    valueKey: String,
    required: Boolean,
    disabled: Boolean,
    state: { type: Boolean, default: true },
    description: String,
    error: String,
    textKey: String,
    placeholder: String,
    searchable: Boolean,
    textStyle: String, //$enums.InputTextStyle
  },
  data() {
    return {
      isFocus: false,
      tempValue: this.value,
      tempList: this.checkListType(),
      //inputClass: "text-field-input",
      tabindex: 0,
      selectedValue: null,
      searchedText: null,
    };
  },
  watch: {
    selectedValue(input) {
      //console.log('SELECTED VALUE', input);
      if (input && this.valueKey) {
        this.$emit("change", input[this.valueKey]);
      } else if (input && input.SimpleArrayToObjectArrayValue) {
        this.$emit("change", input.SimpleArrayToObjectArrayValue);
      } else {
        this.$emit("change", input);
      }
    },
    value(input) {
      this.tempValue = input;
      this.setSelectedValue();
    },
    list() {
      this.tempList = this.checkListType();
      this.setSelectedValue();
    },
    isFocus(input) {
      if (!input && this.searchable) {
        this.searchedText = "";
        this.$emit("search", "");
      } else if (input && this.searchable) {
        this.focusMethod();
      }
    },
  },
  computed: {
    setClass() {
      var temp = "";
      if (this.label) {
        temp += " with-label ";
      } else {
        temp += " without-label ";
      }
      if (this.state) {
        temp += " valid-input ";
      } else {
        temp += " invalid-input ";
      }
      if (this.disabled) {
        temp += " disabled-input ";
      } else {
        temp += " not-disabled-input ";
      }
      if (this.isFocus) {
        temp += " focus-input ";
      } else {
        temp += " not-focus-input ";
      }
      if (this.selectedValue != null && this.selectedValue != undefined) {
        temp += " not-empty-input ";
      } else {
        temp += " empty-input ";
      }
      return temp;
    },
  },
  methods: {
    //TODO: komponens aktívitáskor kereső mező focusba hozatala
    focusMethod() {
      /* console.log(this.$refs)
    //console.log(this.$refs.fpSearchInputField); */
      //document.getElementById("fp-search-input-field").focus();
    },
    setSelectedValue() {
      if (
        this.tempValue != null &&
        this.tempValue != undefined &&
        this.valueKey &&
        this.tempList &&
        this.tempList.length > 0
      ) {
        this.tempList.forEach((x) => {
          if (x.options) {
            x.options.forEach((y) => {
              if (y[this.valueKey] == this.tempValue) {
                y.Selected = true;
                this.selectedValue = y;
              } else {
                y.Selected = false;
              }
            });
          } else {
            if (x[this.valueKey] == this.tempValue) {
              x.Selected = true;
              this.selectedValue = x;
            } else {
              x.Selected = false;
            }
          }
        });
      } else if (
        this.tempValue != null &&
        this.tempValue != undefined &&
        this.tempList &&
        this.tempList.length > 0
      ) {
        this.tempList.forEach((x) => {
          if (x && x.options) {
            x.options.forEach((y) => {
              if (y == this.tempValue) {
                y.Selected = true;
                this.selectedValue = y;
              } else if (y.SimpleArrayToObjectArrayValue == this.tempValue) {
                y.Selected = true;
                this.selectedValue = y;
              } else {
                y.Selected = false;
              }
            });
          } else if (x) {
            if (
              this.tempValue != null &&
              this.tempValue != undefined &&
              x == this.tempValue
            ) {
              x.Selected = true;
              this.selectedValue = x;
            } else if (
              this.tempValue != null &&
              this.tempValue != undefined &&
              x.SimpleArrayToObjectArrayValue == this.tempValue
            ) {
              x.Selected = true;
              this.selectedValue = x;
            } else {
              x.Selected = false;
            }
          } else {
            this.selectedValue = null;
          }
        });
      } else {
        if (this.selectedValue != null && this.selectedValue != undefined) {
          this.selectedValue.Selected = false;
        }
        this.selectedValue = null;
      }
    },
    selectItem(option) {
      if (this.selectedValue != null && this.selectedValue != undefined) {
        Vue.set(this.selectedValue, "Selected", false);
      }
      if (
        option &&
        option[this.valueKey] != null &&
        option[this.valueKey] != undefined
      ) {
        this.selectedValue = option;
        Vue.set(this.selectedValue, "Selected", true);
      } else if (option && !this.valueKey) {
        this.selectedValue = option;
        Vue.set(this.selectedValue, "Selected", true);
      } else {
        this.selectedValue = null;
      }
      this.isFocus = false;
    },
    setListElementLabel(option) {
      if (option) {
        if (this.textKey) {
          const textKeys = this.textKey.split(".");
          var element = option;
          textKeys.forEach((key) => {
            element = element[key];
          });
          return element;
        } else if (option.SimpleArrayToObjectArrayValue) {
          return option.SimpleArrayToObjectArrayValue;
        } else {
          return option;
        }
      }
      return null;
    },
    setSelectedElementLabel() {
      if (this.textKey && this.selectedValue) {
        const textKeys = this.textKey.split(".");
        var element = this.selectedValue;
        textKeys.forEach((key) => {
          element = element[key];
        });
        return element;
      } else if (this.selectedValue) {
        if (this.selectedValue.SimpleArrayToObjectArrayValue) {
          return this.selectedValue.SimpleArrayToObjectArrayValue;
        } else {
          return this.selectedValue;
        }
      } /* else if ((this.label && !this.isFocus) || this.placeholder) {
        return "";
      }  */ else {
        return "";
      }
    },
    clickOutOfBoxEvent(e) {
      if (e && this.$refs && this.$refs.textSelectInput) {
        if (!this.$refs.textSelectInput.contains(e.target)) {
          this.isFocus = false;
        }
      }
    },
    checkListType() {
      const temp = [];
      if (this.list) {
        this.list.forEach((x) => {
          if (typeof x != "object") {
            temp.push({ SimpleArrayToObjectArrayValue: x });
          } else {
            temp.push(x);
          }
        });
      }
      return temp;
    },
  },
  beforeMount() {
    this.tempValue = this.value;
    window.addEventListener("mousedown", this.clickOutOfBoxEvent);
    this.setSelectedValue();
  },
  beforeDestroy() {
    window.removeEventListener("mousedown", this.clickOutOfBoxEvent);
  },
};
</script>
<style scoped>
.input-icon {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 10px;
}
</style>
