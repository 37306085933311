<template>
  <div class="page-background">
    <!-- <div class="page-background-shape"> -->
    <div>
      <div>
        <ejs-schedule
          style="margin: 30px 0px"
          height="550px"
          width="100%"
          :selectedDate="selectedDate"
          :eventSettings="eventSettings"
          currentView="Month"
          :actionComplete="onActionComplete"
        >
          <e-views>
            <e-view option="Week" startHour="07:00" endHour="15:00"></e-view>
            <e-view
              option="WorkWeek"
              startHour="10:00"
              endHour="18:00"
            ></e-view>
            <e-view option="Month" showWeekend="false"></e-view>
            <e-view option="Agenda"></e-view>
          </e-views>
        </ejs-schedule>
        {{ eventSettings }}
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import localeText from "@/styles/locale.json";
import { L10n, loadCldr, setCulture } from "@syncfusion/ej2-base";
import {
  SchedulePlugin,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
} from "@syncfusion/ej2-vue-schedule";
import * as numberingSystems from "@/../node_modules/cldr-data/supplemental/numberingSystems.json";
import * as gregorian from "@/../node_modules/cldr-data/main/hu/ca-gregorian.json";
import * as numbers from "@/../node_modules/cldr-data/main/hu/numbers.json";
import * as timeZoneNames from "@/../node_modules/cldr-data/main/hu/timeZoneNames.json";

Vue.use(SchedulePlugin);
L10n.load(localeText);
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
setCulture("hu");

export default {
  data() {
    return {
      siteTitle: "Naptár",
      selectedDate: moment().format("YYYY-MM-DD"),
      locale: "hu",
      eventSettings: {
        dataSource: [],
      },
    };
  },
  provide: {
    schedule: [Day, Week, WorkWeek, Month, Agenda],
  },
  methods: {
    onActionComplete(args) {
      if (args.requestType == "eventCreated") {
        this.eventSettings.dataSource.push(args.data[0])
      }
    },
  },
  async mounted() {
    this.$store.dispatch(
      "setPageTitle",this.siteTitle);
  },
};
</script>
<style>
@import "../../../styles/syncfusion-schedule.css";
</style>