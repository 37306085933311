<template>
  <div class="profile page-background">
    <h1 style="margin-bottom: 400px; margin-top: 50px;" class="text-center">Ezen az oldalon keresztül lehet megjeleníteni a Virtuális Kutató Tér alkalmazás képernyőjét</h1>
  </div>
</template>

<script>
export default {
  name: "Research",
  mounted() {
    this.$emit('title-changed', this.$t("partner.research.title"))
  }
}
</script>

<style scoped>

</style>