<template>
  <div style="display: flex; margin-bottom: 10px">
    <div v-if="$productSettings[managerType].canCreateParticipant">
      <fp-svg-icon
        v-b-tooltip.hover="'Új beteg regisztrációja és beléptetése a projektbe'"
        @click="showRegistration = true"
        style="cursor: pointer"
        icon="register-user"
      />
      <fp-create-user-to-project
        v-model="showRegistration"
        @selectParticipant="$emit('selectOtherParticipant', $event)"
        :project="project"
      />
    </div>
    <div v-if="$productSettings[managerType].canAddParticipantToProject">
      <fp-svg-icon
        v-b-tooltip.hover="'Regisztrált beteg beléptetése a projektbe'"
        @click="showAddToProjectModal = true"
        style="cursor: pointer"
        icon="add-user-from-list"
      />
      <fp-create-project-participant
        v-model="showAddToProjectModal"
        @selectParticipant="$emit('selectOtherParticipant', $event)"
        :project="project"
        :baseRoles="baseRoles"
      />
    </div>
    <div v-if="$productSettings[managerType].canCreateProjectRelation">
      <fp-svg-icon
        v-b-tooltip.hover="
          'Regisztrált, projekten szereplő beteg kapcsolat felvétele'
        "
        @click="showCreateRelationModal = true"
        style="cursor: pointer"
        icon="add-user-relation"
      />
      <fp-create-project-relation
        v-model="showCreateRelationModal"
        :baseRoles="baseRoles"
        :project="project"
        @selectParticipant="$emit('selectOtherParticipant', $event)"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: "NewParticipantToList",
  props: {
    baseRoles: Array,
    project: Object,
    managerType: String,
  },
  data() {
    return {
      showCreateRelationModal: false,
      showAddToProjectModal: false,
      showRegistration: false,
    };
  },
};
</script>
