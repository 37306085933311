<!-- Version: 1.0.1 -->
<template>
  <fp-form-modal
    v-if="visible"
    :title="$t('healthProfessional.appointment.newParticipnat')"
    v-model="visible"
    @hide="visible = false"
  >
    <template #content>
      <fp-select
        style="margin: 0px 5px"
        :label="$t('healthProfessional.appointment.users')"
        :searchable="true"
        :items="userList"
        valueKey="UserId"
        v-model="selectedUserId"
        @search="searchUser"
      >
        <template #element="e">
          {{
            e.Name +
            " (TAJ: " +
            (e.Ssn ? e.Ssn : "NA") +
            ", Sz.dátum: " +
            (e.DateOfBirth
              ? $convertDateToString(e.DateOfBirth, "YYYY-MM-DD")
              : "NA") +
            ")"
          }}
        </template>
      </fp-select>
      <b-button @click="addVisitor(selectedUserId)">{{
        $t("base.basic.save")
      }}</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { AppointmentLogic } from "../../Logic/Backend/appointment";
import { UserLogic } from "../../Logic/Backend/user";
export default {
  name: "AddVisitorModal",
  model: {
    prop: "showModal",
    event: "change",
  },
  props: {
    showModal: Boolean,
    selectedAppointmentId: Number,
  },
  data() {
    return {
      visible: false,
      selectedUserId: null,
      userList: null,
    };
  },
  watch: {
    async showModal(input) {
      this.visible = input;
      if (input) {
        await this.getUserList({ "-orderby": "UserId", "-limit": 20 });
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
  },
  methods: {
    async getUserList(params) {
      const getUserResponse = await UserLogic.getUserData(params);
      if (!getUserResponse.Code) {
        this.userList = getUserResponse.filter((user) =>
          user.Roles.includes(this.$enums.UserRole.Client.Value)
        );
      } else {
        this.$bvToast.toast(getUserResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedData", {
            name: this.$t("healthProfessional.appointment.client"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async searchUser(input) {
      if (input) {
        if (input.length > 2) {
          await this.getUserList({ multiValue: input });
        } else {
          this.userList = null;
        }
      } else {
        await this.getUserList({ "-orderby": "UserId", "-limit": 20 });
      }
    },
    async addVisitor(selectedUserId) {
      const addVisitorResponse = await AppointmentLogic.addVisitorToAppointment(
        this.selectedAppointmentId,
        { VisitorId: selectedUserId }
      );
      if (!addVisitorResponse.Code) {
        this.$emit("refreshList");
        this.visible = false;
      } else {
        this.$bvToast.toast(addVisitorResponse.Message, {
          title: this.$t("requestResponse.basic.errorModifySave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
};
</script>
