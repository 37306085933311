<template>
  <div class="eh-menu-content">
    <b-container fluid="true">
      <b-row>
        <!-- Sablonok listája -->
        <b-col cols="12" md="3">
          <template-list
            :items="filteredTemplates"
            :selectedTemplate="selectedTemplate"
            :isNewTemplate.sync="isNewTemplate"
            @createTemplate="createNewTemplate"
            @selectingTemplate="selectTemplate"
          />
        </b-col>
        <!-- Kiválasztott sablon részletei -->
        <b-col cols="12" md="9">
          <div v-if="editableTemplate">
            <fp-panel-card>
              <template #content>
                <!-- Nyelvválasztás, sablon megnevezés -->
                <b-container fluid="true">
                  <b-row>
                    <!-- Nyelvválasztás -->
                    <b-col cols="12" lg="4">
                      <fp-select
                        type="multi"
                        :label="$t('components.templates.lang')"
                        :items="Object.values($enums.Languages)"
                        :disabled="!(isNewTemplate || isEditTemplate)"
                        valueKey="Value"
                        v-model="editableTemplate.SupportedLanguages"
                        @change="
                          storeChangedTemplateElements(
                            'SupportedLanguages',
                            $event
                          )
                        "
                        :textKeyConverter="(t) => t.Text"
                      >
                        <template #element="element">
                          <img
                            style="margin: 0px 5px 0px 10px"
                            width="20px"
                            :src="require('@/assets' + element.flag)"
                          />
                          {{ element.Text }}
                        </template>
                      </fp-select>
                    </b-col>
                    <!-- Sablon megnevezés -->
                    <b-col cols="12" lg="4">
                      <fp-input
                        :label="$t('components.templates.name')"
                        :disabled="!(isNewTemplate || isEditTemplate)"
                        v-model="editableTemplate.Name"
                        @change="storeChangedTemplateElements('Name', $event)"
                      />
                    </b-col>
                  </b-row>
                </b-container>
                <!-- Sablon hozzáadó gombok -->
                <div
                  v-if="
                    editableTemplate.SupportedLanguages &&
                    editableTemplate.SupportedLanguages.length
                  "
                >
                  <button
                    v-if="
                      !editableTemplate.EmailTemplate &&
                      (isNewTemplate || isEditTemplate)
                    "
                    @click="addEmailTemplate"
                    class="eh-action-button"
                  >
                    <b-icon
                      style="margin: 0px 5px"
                      icon="envelope-fill"
                    ></b-icon>
                    {{ $t("components.templates.addEmail") }}
                  </button>
                  <button
                    v-if="
                      !editableTemplate.SmsTemplate &&
                      (isNewTemplate || isEditTemplate)
                    "
                    @click="addSMSTemplate"
                    class="eh-action-button"
                  >
                    <b-icon
                      style="margin: 0px 5px"
                      icon="chat-square-text-fill"
                    ></b-icon>
                    {{ $t("components.templates.addSMS") }}
                  </button>
                  <button
                    v-if="
                      !editableTemplate.PushTemplate &&
                      (isNewTemplate || isEditTemplate)
                    "
                    @click="addPUSHTemplate"
                    class="eh-action-button"
                  >
                    <b-icon
                      style="margin: 0px 5px"
                      icon="phone-vibrate"
                    ></b-icon>
                    {{ $t("components.templates.addPush") }}
                  </button>
                </div>
                <!-- Sablonok nyelvek szerint -->
                <div
                  v-for="(lang, index) in editableTemplate.SupportedLanguages"
                  :key="'language' + index"
                >
                  <!-- Egyik nyelv sablonai -->
                  <fp-panel-card
                    :closedCard="isPanelDefaultClose"
                    style="margin: 10px 0px"
                    :title="$enums.Languages[lang].Text"
                  >
                    <!-- Sablonok egyik nyelv a panel címe -->
                    <template #title>
                      <img
                        style="
                          margin: 0px 5px 0px 10px;
                          border: solid 1px #00000044;
                        "
                        width="20px"
                        :src="require('@/assets' + $enums.Languages[lang].flag)"
                      />
                      {{ $enums.Languages[lang].Text }}
                    </template>
                    <!-- Sablonok egyik nyelv a panel törzse -->
                    <template #content>
                      <!-- Email sablon -->
                      <email-template
                        v-if="editableTemplate.EmailTemplate"
                        v-model="editableTemplate.EmailTemplate"
                        @changedValues="changedTemplate"
                        :editable="isNewTemplate || isEditTemplate"
                        :lang="lang"
                        :supportedLangs="editableTemplate.SupportedLanguages"
                      />
                      <!-- SMS sablon -->
                      <sms-template
                        v-if="editableTemplate.SmsTemplate"
                        v-model="editableTemplate.SmsTemplate"
                        @changedValues="changedTemplate"
                        :editable="isNewTemplate || isEditTemplate"
                        :lang="lang"
                        :supportedLangs="editableTemplate.SupportedLanguages"
                      />
                      <!-- PUSH sablon -->
                      <push-template
                        v-if="editableTemplate.PushTemplate"
                        v-model="editableTemplate.PushTemplate"
                        @changedValues="changedTemplate"
                        :editable="isNewTemplate || isEditTemplate"
                        :lang="lang"
                        :supportedLangs="editableTemplate.SupportedLanguages"
                      />
                    </template>
                  </fp-panel-card>
                </div>
                <!-- Sablon hozzárendelés projekthez -->
                <b-check
                  v-if="permissionAddTemplateToProject"
                  :disabled="
                    !(
                      isNewTemplate ||
                      (isEditTemplate && permissionRemoveTemplateToProject)
                    )
                  "
                  v-model="addTemplateToProject"
                  >{{ $t("components.templates.addToProject") }}</b-check
                >
              </template>
            </fp-panel-card>
            <!-- Szerkesztés -->
            <button
              class="eh-action-button"
              v-if="!isNewTemplate && !isEditTemplate"
              @click="isEditTemplate = true"
            >
              {{ $t("base.basic.edit") }}
            </button>
            <!-- Törlés -->
            <button
              class="eh-action-button"
              v-if="!isNewTemplate && !isEditTemplate"
              @click="removeTemplate"
            >
              {{ $t("base.basic.delete") }}
            </button>
            <!-- Mentés/Létrehozás -->
            <button
              class="eh-action-button"
              v-if="isEditTemplate || isNewTemplate"
              @click="
                isNewTemplate
                  ? createTemplate()
                  : isEditTemplate
                  ? setTemplate()
                  : null
              "
            >
              {{ $t("base.basic.save") }}
            </button>
            <!-- Mégsem -->
            <button
              class="eh-action-button"
              v-if="isEditTemplate || isNewTemplate"
              @click="cancelTemplate"
            >
              {{ $t("base.basic.cancel") }}
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { ProjectLogic } from "@/logic/backend/project";
import { TemplateLogic } from "@/logic/backend/template";
import TemplateList from "../../module/template/TemplateList.vue";
import EmailTemplate from "../../module/template/EmailTemplate.vue";
import PushTemplate from "../../module/template/PushTemplate.vue";
import SmsTemplate from "../../module/template/SmsTemplate.vue";

export default {
  components: { TemplateList, EmailTemplate, PushTemplate, SmsTemplate },
  name: "Templates",
  props: {
    projectId: Number,
  },
  data() {
    return {
      project: null,
      filteredTemplates: null,
      templates: null,
      selectedTemplate: null,
      editableTemplate: null,
      changeTemplateElements: null,
      isNewTemplate: false,
      isEditTemplate: false,
      isPanelDefaultClose: true,
      addTemplateToProject: false,
      addedTemplateToProject: false,
      permissionAddTemplateToProject: false,
      permissionRemoveTemplateToProject: false,
      defaultTemplate: {
        SupportedLanguages: null,
        Name: null,
        EmailTemplate: null,
        SmsTemplate: null,
        PushTemplate: null,
      },
    };
  },
  watch: {
    async projectId() {
      await this.getTemplates();
      this.checkPermission();
    },
  },
  computed: {
    isOwnTemplate() {
      return this.selectedTemplate.OwnerId == this.$loggedUser.UserId;
    },
  },
  methods: {
    changedTemplate(changedValues) {
      if (!this.changeTemplateElements) {
        this.changeTemplateElements = {};
      }
      if (
        Object.keys(changedValues).includes("EmailTemplate") &&
        !changedValues.EmailTemplate
      ) {
        Object.keys(this.changeTemplateElements).forEach((key) => {
          if (key.includes("EmailTemplate")) {
            delete this.changeTemplateElements[key];
          }
        });
      } else if (
        Object.keys(changedValues).includes("SmsTemplate") &&
        !changedValues.SmsTemplate
      ) {
        Object.keys(this.changeTemplateElements).forEach((key) => {
          if (key.includes("SmsTemplate")) {
            delete this.changeTemplateElements[key];
          }
        });
      } else if (
        Object.keys(changedValues).includes("PushTemplate") &&
        !changedValues.PushTemplate
      ) {
        Object.keys(this.changeTemplateElements).forEach((key) => {
          if (key.includes("PushTemplate")) {
            delete this.changeTemplateElements[key];
          }
        });
      }
      Object.assign(this.changeTemplateElements, changedValues);
    },
    //Új email sablon model beállítása
    createNewTemplate() {
      this.editableTemplate = JSON.parse(JSON.stringify(this.defaultTemplate));
      this.isNewTemplate = true;
      this.isPanelDefaultClose = false;
      this.addTemplateToProject = false;
    },
    //sablon kiválasztása
    //TODO: projekt lekérdezés változás miatt
    selectTemplate(template) {
      this.editableTemplate = JSON.parse(JSON.stringify(template));
      delete this.editableTemplate.IsActive;
      this.addTemplateToProject = this.addedTemplateToProject =
        this.project.Items.find(
          (item) =>
            item.EntityTypeName == "NotificationTemplate" &&
            item.EntityId == template.TemplateId
        )
          ? true
          : false;
      this.selectedTemplate = template;
      this.isNewTemplate = false;
      this.isEditTemplate = false;
      this.isPanelDefaultClose = true;
    },
    //sablonhoz email hozzáadása
    addEmailTemplate() {
      this.$set(this.editableTemplate, "EmailTemplate", {});
    },
    //sablonhoz sms hozzáadása
    addSMSTemplate() {
      this.$set(this.editableTemplate, "SmsTemplate", {});
    },
    //sablonhoz push hozzáadása
    addPUSHTemplate() {
      this.$set(this.editableTemplate, "PushTemplate", {});
    },
    //sablonlista betöltése
    async getTemplates() {
      const getTemplatesResponse = await TemplateLogic.getTemplates();
      if (!getTemplatesResponse.Code) {
        this.templates = this.filteredTemplates = getTemplatesResponse;
        await this.getProjectDetailsById();
      } else {
        this.$bvToast.toast(getTemplatesResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t("components.templates.errorName"),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    async getProjectDetailsById() {
      const getProjectResponse = await ProjectLogic.getProjectDetails({
        ProjectId: this.projectId,
      });
      if (!getProjectResponse.Code) {
        this.project = getProjectResponse[0];
      } else {
        //TODO: hiba
      }
    },
    //sablon létrehozása
    async createTemplate() {
      const createTemplateResponse = await TemplateLogic.createTemplate(
        this.editableTemplate
      );
      if (!createTemplateResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.basic.successSave"), {
          title: this.$t("base.basic.save"),
          variant: "success",
          solid: true,
        });
        if (
          !this.addedTemplateToProject &&
          this.addTemplateToProject &&
          this.permissionAddTemplateToProject
        ) {
          await this.addToProject(createTemplateResponse.TemplateId);
        }
        await this.getTemplates();
        this.createNewTemplate();
      } else {
        this.$bvToast.toast(createTemplateResponse.Message, {
          title: this.$t("requestResponse.basic.errorSave"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //sablon módosítás mentés
    async setTemplate() {
      if (this.changeTemplateElements) {
        if (this.changeTemplateElements) {
          const setTemplateResponse = await TemplateLogic.setTemplate(
            this.editableTemplate.TemplateId,
            this.changeTemplateElements
          );
          if (!setTemplateResponse.Code) {
            this.changeTemplateElements = null;
            this.$bvToast.toast(
              this.$t("requestResponse.basic.successModifySave"),
              {
                title: this.$t("base.basic.save"),
                variant: "success",
                solid: true,
              }
            );
          } else {
            this.$bvToast.toast(setTemplateResponse.Message, {
              title: this.$t("requestResponse.basic.errorModifySave"),
              variant: "danger",
              solid: true,
              AutoHideDelay: 10000,
            });
          }
        }
        if (this.addTemplateToProject && !this.addedTemplateToProject) {
          await this.addToProject(this.editableTemplate.TemplateId);
        } else if (
          !this.addTemplateToProject &&
          this.addedTemplateToProject &&
          this.permissionRemoveTemplateToProject
        ) {
          await this.removeFromProject(this.editableTemplate.TemplateId);
        }
        await this.getTemplates();
      }
      this.selectTemplate(
        this.templates.find(
          (t) => t.TemplateId == this.editableTemplate.TemplateId
        )
      );
    },
    //sablon hozzáadása a projekthez
    async addToProject(templateId) {
      const body = [
        {
          EntityTypeName: "NotificationTemplate",
          EntityId: templateId,
        },
      ];
      const itemId = this.project.Items.find(
        (item) => item.EntityId == templateId
      );
      if (!itemId) {
        const addToProjectResponse = await ProjectLogic.setItem(
          body,
          this.$loggedUser.SelectedProject.ProjectId
        );
        if (!addToProjectResponse.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.template.successAddToProject"),
            {
              title: this.$t("requestResponse.basic.successAssign"),
              variant: "success",
              solid: true,
            }
          );
        } else {
          this.$bvToast.toast(addToProjectResponse.Message, {
            title: this.$t("requestResponse.template.errorAddToProject"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    //sablon eltávolítása a projektből
    async removeFromProject(templateId) {
      const itemId = this.project.Items.find(
        (item) => item.EntityId == templateId
      );
      if (itemId) {
        const removeFormProjectResponse = await ProjectLogic.removeItem(
          this.$loggedUser.SelectedProject.ProjectId,
          itemId.ItemId
        );
        if (!removeFormProjectResponse.Code) {
          this.$bvToast.toast(
            this.$t("requestResponse.template.successRemoveFromProject"),
            {
              title: this.$t("requestResponse.basic.successRemove"),
              variant: "info",
              solid: true,
            }
          );
        } else {
          this.$bvToast.toast(removeFormProjectResponse.Message, {
            title: this.$t("requestResponse.template.errorRemoveFromProject"),
            variant: "danger",
            solid: true,
            AutoHideDelay: 10000,
          });
        }
      }
    },
    //sablon törlése
    async removeTemplate() {
      const removeTemplateResponse = await TemplateLogic.deleteTemplate(
        this.editableTemplate.TemplateId
      );
      if (!removeTemplateResponse.Code) {
        this.$bvToast.toast(this.$t("requestResponse.basic.successDelete"), {
          title: this.$t("base.basic.save"),
          variant: "info",
          solid: true,
        });
        this.removeFromProject(this.editableTemplate.TemplateId);
        await this.getTemplates();
        this.selectedTemplate = this.editableTemplate = null;
      } else {
        this.$bvToast.toast(removeTemplateResponse.Message, {
          title: this.$t("requestResponse.basic.errorDelete"),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
    //sablon módosítások elvetése
    cancelTemplate() {
      this.editableTemplate = JSON.parse(JSON.stringify(this.selectedTemplate));
      if (!this.isNewTemplate) {
        this.selectTemplate(this.selectedTemplate);
        this.isEditTemplate = false;
      }
      this.changeTemplateElements = null;
    },
    //projekt szerepkörhöz tartozó engedélyek csekkolása
    checkPermission() {
      if (this.project) {
        const rolesByPermissionAddToItem = this.project.Roles.filter((role) =>
          role.Permissions.includes("AddItem")
        );
        const rolesByPermissionRemoveToItem = this.project.Roles.filter(
          (role) => role.Permissions.includes("RemoveItem")
        );
        const ownParticipant = this.project.Participants.find(
          (part) => part.UserId == this.$loggedUser.UserId
        );
        this.permissionAddTemplateToProject = rolesByPermissionAddToItem.find(
          (role) => role.RoleName === ownParticipant.RoleName
        )
          ? true
          : false;
        this.permissionRemoveTemplateToProject =
          rolesByPermissionRemoveToItem.find(
            (role) => role.RoleName === ownParticipant.RoleName
          )
            ? true
            : false;
      }
    },
    //módosult elemek tárolása
    storeChangedTemplateElements(path, value) {
      if (!this.changeTemplateElements) {
        this.changeTemplateElements = {};
      }
      this.$set(this.changeTemplateElements, path, value);
    },
  },
  async mounted() {
    if (this.projectId) {
      await this.getTemplates();
      this.checkPermission();
    }
  },
};
</script>
