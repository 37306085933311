<template>
  <fp-form-modal
    size="xl"
    v-model="visible"
    :title="editable ? 'Kiadás szerkesztése' : 'Kiadás adatai'"
    :closable="!editable"
  >
    <template #content>
      <div v-if="dispense">
        <b-row cols="1" cols-md="2">
          <b-col>
            <fp-input
              label="EESZT azonosító"
              v-model="dispense.EesztId"
              :disabled="true"
            />
          </b-col>
          <b-col>
            <fp-input
              label="Az átadás-átvétel elektronikus bizonylat"
              v-model="dispense.HandoverReceipt"
              @change="changeValue('HandoverReceipt', $event)"
              :disabled="!editable"
            />
          </b-col>
          <b-col>
            <fp-date-time-picker
              label="Kiadás időpontja"
              description="EESZT felküldéshez kötelező mező!"
              v-model="dispense.DispenseTimestamp"
              @change="changeValue('DispenseTimestamp', $event)"
              :disabled="true"
            />
          </b-col>
        </b-row>
        <div style="margin-top: 10px">
          <fp-panel-card title="Ellátott személy adatai" :required="true">
            <template #content>
              <patient
                v-model="dispense.Patient"
                @changeValue="changeValue('Patient', $event)"
                :disabled="!editable"
                :prescriptionTimestamp="prescription.PrescriptionTimestamp"
              />
            </template>
          </fp-panel-card>
        </div>
        <div style="margin-top: 10px" v-if="dispense.Dispenser">
          <fp-panel-card title="Kiadó adatai">
            <template #content>
              <submitter
                v-model="dispense.Dispenser"
                @changeValue="changeValue('Dispenser', $event)"
                :disabled="!editable"
              />
            </template>
          </fp-panel-card>
        </div>
        <div style="margin-top: 10px" v-if="dispense.Products">
          <fp-panel-card title="Kiváltott termékek">
            <template #content>
              <fp-panel-card
                v-for="(product, index) in dispense.Products"
                :key="'dispense-details-product-' + productIds[index]"
                style="margin-top: 10px"
                :title="index + 1 + '. termék'"
                :isShowAddButton="false"
                :isShowDeleteButton="
                  !editable && dispense.Products.length > 1 && canDelete
                "
                @deleteElement="deleteProductFromDispense(index, product)"
                :error="errorProduct.includes(index)"
              >
                <template #content>
                  <product
                    ref="dispenseProducts"
                    v-model="dispense.Products[index]"
                    :index="index"
                    @changeValue="
                      changeValue('Products[' + index + ']', $event)
                    "
                    :editable="editable"
                    :prescriptionProducts="prescription.Products"
                  />
                </template>
              </fp-panel-card>
              <b-button v-if="editable" @click="addNewProductToDispense()">
                + Új termék kiadás
              </b-button>
            </template>
          </fp-panel-card>
        </div>
      </div>
      <b-button v-if="editable" @click="saveDispenseDetails">Mentés</b-button>
      <b-button v-if="editable" @click="visible = false">Mégsem</b-button>
    </template>
  </fp-form-modal>
</template>
<script>
import { MedicalDeviceLogic } from "../../../Logic/Backend/medical-device";
import Product from "../Components/form/DispenseProduct.vue";
import Patient from "../Components/form/Patient.vue";
import Submitter from "../Components/form/Submitter.vue";
export default {
  components: { Product, Patient, Submitter },
  name: "DispenseDetailsForm",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: Boolean,
    propDispense: Object,
    editable: Boolean,
    prescription: Object,
    prescriptionId: Number,
    patient: Object,
    patientId: Number,
    projectPatient: Array,
    canDelete: Boolean,
  },
  data() {
    return {
      visible: this.value,
      dispense: this.propDispense,
      productsCount: 0,
      productIds: [],
      changeDispense: null,
      defaultProject: {
        DispensedAt: null,
        DispenseTitleId: null,
        TttId: null,
        Amount: null,
        Lifespan: null,
        Size: null,
        ApplicationSide: null,
        ApplicationBodyArea: null,
        CustomMade: false,
        SerialNumber: null,
        WorksheetNumber: null,
        ExtendedGuarantee: null,
        SubstitutionAgreement: false,
        Comment: null,
        TotalPrice: null,
      },
      errorProduct: [],
      deletedProductUnits: null,
    };
  },
  watch: {
    value(input) {
      this.visible = input;
      if (input) {
        this.setDispenseDetailsData();
      } else {
        this.$emit("refreshList");
      }
    },
    visible(input) {
      this.$emit("change", input);
    },
    propDispense() {
      if (this.value) {
        this.setDispenseDetailsData();
      }
    },
  },
  methods: {
    addNewProductToDispense() {
      this.productsCount++;
      this.productIds.push(this.productsCount);
      this.dispense.Products.push(null);
    },
    async deleteProductFromDispense(index, product) {
      if (product) {
        if (confirm("Biztosan törli a terméket a kiadásról?")) {
          //const result = await this.deleteDispenseProduct(product.UniqueId);
          const deleteResponse = await MedicalDeviceLogic.deleteDispenseProduct(
            this.prescriptionId,
            product.UniqueId
          );
          if (!deleteResponse.Code || deleteResponse.Code == 0) {
            this.productIds.splice(index, 1);
            this.dispense.Products.splice(index, 1);
            this.$bvToast.toast(
              "Sikeresen eltávolította a terméket a kiadásról!",
              {
                title: "Sikeres törlés",
                variant: "info",
                solid: true,
              }
            );
          } else {
            this.$bvToast.toast(deleteResponse.Message, {
              title: "Hiba történt a törlés során!",
              variant: "danger",
              solid: true,
            });
          }
        }
      }
    },
    async checkValidation() {
      const productValidList = await Promise.all(
        this.$refs["dispenseProducts"].map((p) => {
          return p.checkValidation();
        })
      );
      this.errorProduct = [];
      productValidList.forEach((p, index) => {
        if (!p) {
          this.errorProduct.push(index);
        }
      });
      const productValid = !productValidList.some((x) => !x);

      return productValid;
    },
    async saveDispenseDetails() {
      const valid = await this.checkValidation();
      if (valid) {
        if (this.deletedProductUnits && this.deletedProductUnits.length) {
          await this.deleteDispenseProducts();
        }
        const body = this.getChangedPathValue();
        await this.editDispense(body);
        //TODO csekkolni a törlést hozzá lett e adva új termék?
      } else {
        this.$bvToast.toast(
          "Nézze át az űrlapot, hogy minden adat helyesen lett kitöltve!",
          {
            title: "Hibás mező kitöltés",
            variant: "danger",
            solid: true,
          }
        );
      }
    },
    async editDispense(editedDispense) {
      const saveDispenseResponse = await MedicalDeviceLogic.updateDispense(
        this.prescriptionId,
        editedDispense
      );
      if (!saveDispenseResponse.Code) {
        this.$bvToast.toast("Sikeresen módosította a kiadást!", {
          variant: "info",
          title: "Sikeres módosítás!",
          solid: true,
        });
        this.$emit("refreshList");
      } else {
        this.$bvToast.toast(saveDispenseResponse.Message, {
          variant: "danger",
          title: "Hiba történt a kiadás módosítása során!",
          solid: true,
          noAutoHide: true,
        });
      }
    },
    async deleteDispenseProduct(productUniqueId) {
      const deleteResponse = await MedicalDeviceLogic.deleteDispenseProduct(
        this.prescriptionId,
        productUniqueId
      );
      if (deleteResponse.Code == 0) {
        return true;
      } else {
        return false;
      }
    },
    async deleteDispenseProducts() {
      const prescId = this.prescriptionId;
      const responses = await Promise.all(
        this.deletedProductUnits.map(async (p) => {
          const deleteRepsonse = await MedicalDeviceLogic.deleteDispenseProduct(
            prescId,
            p
          );
          return { Response: deleteRepsonse, DispenseProduct: p };
        })
      );
      const success = responses.filter((s) => s.Response.Code == 0);
      const error = responses.filter((e) => e.Response.Code != 0);
      if (success && success.length) {
        this.$bvToast.toast(
          "Kiadáshoz tartozó " +
            success.length +
            " db termék eltávolítása sikeresen megtörtént!",
          {
            title: "Sikeres termék törlése!",
            variant: "info",
            solid: true,
          }
        );
      }
      if (error && error.length) {
        this.$bvToast.toast(
          "Hiba történt a kiadáshoz tartozó " +
            error.length +
            " db termék eltávolítása során!" +
            error.map((e) => e.Response.Message),
          {
            title: "Sikertelen termék törlés!",
            variant: "danger",
            solid: true,
            noAutoHide: true,
          }
        );
      }
      this.deletedProductUnits = null;
    },
    changeValue(key, value) {
      if (!this.changeDispense) {
        this.changeDispense = {};
      }
      this.changeDispense[key] = value;
    },
    getChangedPathValue() {
      var changedFields = {};
      Object.entries(this.changeDispense).forEach(([key, value]) => {
        const changed = this.getPath(value, key);
        if (changed) {
          Object.assign(changedFields, changed);
        }
      });
      return changedFields;
    },
    getPath(obj, path) {
      if (typeof obj == "object" && obj != null && obj !== undefined) {
        const result = {};
        Object.entries(obj).forEach(([key, value]) => {
          const r = this.getPath(value, path + "." + key);
          if (r) {
            Object.assign(result, r);
          }
        });
        return result;
      } else {
        return { [path]: obj };
      }
    },
    setDispenseDetailsData() {
      this.dispense = JSON.parse(JSON.stringify(this.propDispense));
      this.productsCount = this.dispense.Products
        ? this.dispense.Products.length
        : null;
      this.productIds = this.productsCount
        ? Array.from(Array(this.productsCount).keys())
        : null;
      this.changeDispense = null;
    },
  },
  mounted() {
    if (this.value) {
      this.setDispenseDetailsData();
    }
  },
  beforeDestroy() {
    this.$emit("refreshList");
  },
};
</script>
