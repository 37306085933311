import TagVue from "./Tag.vue";
import TagManager from "./TagManager.vue";
import TagSelectorVue from "./TagSelector.vue";

export default {
  install(Vue) {
    Vue.component("fp-tag-manager", TagManager);
    Vue.component("fp-tag", TagVue);
    Vue.component("fp-tag-selector", TagSelectorVue);
  },
};
